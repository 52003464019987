const url = {
    // 请求的host
    API_SERVER_HOST: "https://api.yunhaochefu.com",
    //请求的端口
    API_SERVER_PORT: "",
    //文件上传的地址
    fileUploadUrl: "https://api.yunhaochefu.com/ci-file-server/",
    //websoket
    WS_API_SERVER: "wss://websocket.yunhaochefu.com",
    //商城请求的域名
    SHOP_API_SERVE: "https://mall.seller.api.yunhaochefu.com",
    //商城文件上传
    SHOP_API_FILE_SERVE: "http://mall.base.api.yunhaochefu.com",
    SHOP_B_API_SERVE: "https://company.mall.seller.api.yunhaochefu.com",
    //资料管理请求的域名
    TDMS_API_SERVE: "https://api.yunhaochefu.com/",
    //右侧菜单栏不通过接口过滤
    debugMode: false,
    //门户的host
    PORTAL_API_SERVER_HOST:"https://api-2.yunhaogroup.com/",
    //chatGpt
    DIFY_SERVER: "https://dify.yunhaogroup.com",
}

export default url
