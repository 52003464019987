import React, { useState } from "react";
import { Button, Table, Input, message, Upload, Tooltip } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { formatInt, formatNum, getRandomStr,bigNumber,formatDiscount } from "@/erp_subpackage/utils/util";
import { useSaleDcDetailsModel } from "./saleDcDetailsModel";
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { ExclamationCircleOutlined } from '@ant-design/icons'

export const TableBox = ({ id, mode }) => {
    const { user: { shopId }, setMaterialInfo } = useGlobalModel();
    const { addGoodsList, addFormVal, editFormVal, editFormVal: { status },
        sDcDownload, setAddGoodsList, editGoodsList, setEditGoodsList, setAddGoodsModal, exportSDcdetail,setSaveBtn } = useSaleDcDetailsModel();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchParms, setSearchParms] = useState("");
    const [searchVal, setSearchVal] = useState({ data: [], isSearch: false });   //用于搜索展示的数据
    //删除商品
    const onRemove = () => {
        if (!selectedRowKeys.length) {
            message.warning("请选择你要删除的商品");
            return;
        }
        mode === "edit" ?
            setEditGoodsList(origin => origin.filter(item => selectedRowKeys.indexOf(item.key) < 0)) :
            setAddGoodsList(origin => origin.filter(item => selectedRowKeys.indexOf(item.key) < 0));

        let newSearchData = searchVal.data.filter(item => selectedRowKeys.indexOf(item.key) < 0)
        setSearchVal({ data: newSearchData, isSearch: searchVal.isSearch });

        setSelectedRowKeys([]);
        message.success("删除成功");
    }

    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource = null) => {
       // debugger
        const isEtit = mode === "edit";
        let goodsList = dataSource ? dataSource : [...(isEtit ? editGoodsList : addGoodsList)];
        let params = searchParms.trim()
        if (params) {
            let reg = new RegExp(params);
            let arr = [];
            goodsList.forEach(item => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })

            setSearchVal({ data: arr, isSearch: true });

            if (!arr.length) {
                message.warning("列表没有该商品！！")
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }

    //导入明细成功
    // const upLoadCallback = (list) => {
    //     const isEdit = mode === "edit";
    //     if (list && list.length) {
    //         let newGoodsList = list.map((item, index) => ({
    //             ...item,
    //             id: "",
    //             key: item.id ?? getRandomStr(),
    //             num: item.num ?? 1,
    //             //总计=单价*（1-下浮）*开单数量  云创没有整单下浮
    //             amount: +formatNum(Number(item.price ?? 0) * Number(item.num ?? 1)),
    //         }))
    //         isEdit ? setEditGoodsList(origin => [...origin, ...newGoodsList]) : setAddGoodsList(origin => [...origin, ...newGoodsList]);
    //     }
    // };


     //导入明细成功
     const upLoadCallback = (list) => {
        if (!list?.length) return message.warning('请导入正确格式的配件');
        const isEdit = mode === "edit";
        const goodsList = [...(isEdit ? editGoodsList : addGoodsList)]
        let newShelfRows = [];
        let str = "";
        list.forEach(item => {
            let num = item.num ?? 0;
            let price = item.price
            if (!item.success) {
                str += `【${item.msg}】`
            }
            if (item.inventoryDetailsList && item.inventoryDetailsList.length) {
                let data = item.inventoryDetailsList.map((item) => {
                    return {
                        ...item,
                        // createTime: Number(item.createTime.join(''))
                    }
                })
                const tempArr = data.sort((a, b) => a.createTime - b.createTime)
                for (let i = 0; i < tempArr.length; i++) {
                    let goods = tempArr[i];
                    // 可用库存 = 库存数量 - 占用库存
                    let kynum = goods.inventoryQuantity - goods.occupyNum;
                    console.log(kynum)
                    console.log(111)
                    // 公共字段
                    let publicData = {
                        id: "",
                        key: goods.id,
                        purchaseDetailsId: goods.id,
                        materialCode: goods.materialCode,
                        materialName: goods.materialName,  //商品名称
                        price: bigNumber.times(price,(1 - Number(item.discountRate ?? 0))).toFixed(2) ,
                        prePrice: price,
                        costPrice: goods.costPrice,
                        purchaseCode: goods.bussCode,
                        inventoryNum: goods.inventoryQuantity - goods.occupyNum,
                        typeName: goods.typeName,
                        typeCode: goods.typeCode,
                        outWarehouseCode: goods.warehouseCode,  //仓库编码
                        outWarehouseName: goods.warehouseName,  //仓库编码
                        outShelfCode: goods.shelfCode,
                        replaceCode: goods.replaceCode,
                        unitName: goods.unitName,
                        unitCode: goods.unitCode,
                        latestPurPrice: goods.latestPurPrice,
                        discountRate:item.discountRate
                    }
                    //当匹配到最后一个配件, 可用库存不满足用户填写数量, 那就设置该配件为异常配件, 数量设置为减完前面配件可用库存剩余的数量
                    if (i === tempArr.length - 1 && kynum < num) {
                        newShelfRows.push({
                            ...publicData,
                            isHave: true,
                            error: item.msg,
                            num, //开单数量
                            confirmNum: num ?? 1,
                            preAmount: +formatNum(Number(price ?? 0) * Number(num ?? 1)),
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            amount: +formatNum(Number(price ?? 0) * (1 - Number(item.discountRate ?? 0)) * (Number(num ?? 1))),
                        })
                        break
                    }
                    if (num <= 0) break;
                    if (kynum) {
                        num = num - kynum;
                        // 当 可用数量 小于
                        let tempNum = num < 0 ? kynum + num : kynum;
                        newShelfRows.push({
                            ...publicData,
                            isHave: item.success,
                            msg: item.msg,
                            num: tempNum, //开单数量
                            confirmNum: tempNum ?? 1,
                            preAmount: +formatNum(Number(price ?? 0) * Number(tempNum ?? 1)),
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            amount: +formatNum(Number(price ?? 0) * (1 - Number(item.discountRate ?? 0)) * (Number(tempNum ?? 1)))
                        })
                    }
                }
            }
        })
        isEdit ? setEditGoodsList(origin => [...origin, ...newShelfRows]) : setAddGoodsList(origin => [...origin, ...newShelfRows]);
        message.success(str ? str : "导入成功", 20, "info");
    }

    const {shopId:tranShopId,branchIn} = mode === "edit" ? editFormVal : addFormVal;

    const action = <div className="flex justify-between white-space">
        <div>
            <Button
                className="btn-item"
                shape="round"
                disabled={mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT}
                onClick={() => { setAddGoodsModal(true) }}
            >新增商品</Button>
            <Button
                className="btn-item"
                shape="round"
                disabled={mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT}
                onClick={() => onRemove()}
            >删除商品</Button>
            <Button
                className="btn-item"
                shape="round"
                disabled={mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT}
                onClick={() => {
                    sDcDownload()
                }}
            >下载模板</Button>
            {/* <Button
                className="btn-item"
                shape="round"
                hidden={mode !== "edit"}
                onClick={() => {
                    exportSDcdetail()
                }}
            >导出</Button> */}

            <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/ci-storage/stock/details/allocationImport/${shopId}/${tranShopId}/${branchIn}`, upLoadCallback)}>
                <Button className="btn-item" shape="round" >导入</Button>
            </Upload>
            {/* disabled={(mode === "edit" && status !== DictCodeEnmu.TRANSFER_STATUS_INIT) || !tranShopId} */}
        </div>
        <div className="flex">
            <Input
                allowClear
                datatype="round"
                className="border-r-16"
                placeholder="商品编号/商品名称"
                onChange={(e) => setSearchParms(e.target.value)}
                onPressEnter={() => goodsFilter()}
            />
            <Button style={{ margin: "0px 12px 0px 15px" }} shape="round" onClick={() => goodsFilter()}> 检索</Button>
        </div>
    </div>;


    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t, r, i) => i + 1
        }, {
            title: '提示',
            width: 60,
            fixed: 'left',
            align: 'center',
            dataIndex: '',
            render: (t, r) =>
                !r.isHave || r?.error ? (
                    <Tooltip
                        placement="topLeft"
                        title={!r.isHave ? r?.msg ?? '调入门店没有该商品, 保存时将自动添加该商品, 请及时维护对应的商品信息' : r.error}
                        arrowPointAtCenter
                    >
                        <ExclamationCircleOutlined style={{ color: '#FF4500', fontSize: '20px' }} />
                    </Tooltip>
                ) : (
                    ''
                ),
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
            // render: (text, record) => (
            //     <Tooltip placement="topLeft" title={text}>
            //         <p className="one-line click-span" onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })}>
            //             {text}
            //         </p>
            //     </Tooltip>
            // ),
            sorter: {
                compare: (a, b) => a.materialCode.charCodeAt(0) - b.materialCode.charCodeAt(0),
                multiple: 1,
            },
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
            sorter: {
                compare: (a, b) => a.materialName?.localeCompare(b.materialName, 'zh-Hans-CN', { sensitivity: 'accent' }),
                multiple: 2,
            },
        }, {
            title: '调拨数量',
            width: 100,
            dataIndex: 'num',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        }, {
            title: '折前单价',
            width: 120,
            dataIndex: 'prePrice',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
            render: (text) => `￥${formatNum(text)}`
        },  {
            title: '折前调拨金额',
            width: 120,
            dataIndex: 'preAmount',
            render: (text) => `￥${formatNum(text)}`
        },{
            title: '下浮',
            width: 100,
            dataIndex: 'discountRate',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        },{
            title: '单价',
            width: 130,
            dataIndex: 'price',
            // editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
            render: (text) => `￥${formatNum(text)}`
            // }, {
            //     title: '下浮',
            //     width: 100,
            //     dataIndex: 'discountRate',
            //     editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        }, {
            title: '调拨总计',
            width: 136,
            dataIndex: 'amount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '成本单价',
            width: 120,
            dataIndex: 'costPrice',
            render: (text) => `￥${formatNum(text ?? 0)}`
        }, {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryNum',
        }, {
            title: '采购单号',
            width: 100,
            dataIndex: 'purchaseCode',
        }, {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'outShelfCode',
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'outWarehouseName',
        }, {
            title: '商品类型',
            width: 120,
            dataIndex: 'typeName',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        }
    ];

    const newSelectColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = (row) => {
        // row.num = +formatInt(row.num);
        row.num = +formatInt(row.num, +(row?.inventoryNum ?? 0), "调拨数量不可以大于库存数量");
        // row.price = +formatNum(row.price);
        // if (row.price < +formatNum(row.costPrice)) {
        //     row.price = +formatNum(row.costPrice);
        //     message.warning("您填写的单价不能小于进货价,已为您重置为进货价");
        // }
        // 云创没有整单下浮
        // row.discountRate = +formatDiscount(row.discountRate);
        // //总计=单价*（1-下浮）*开单数量
        row.prePrice = +formatNum(row.prePrice);
        row.preAmount = +bigNumber.times(row.prePrice,row.num).toFixed(2);
        row.discountRate = +formatDiscount(row.discountRate);
        row.price = +bigNumber.times(row.prePrice,(1 - row.discountRate)).toFixed(2);
        // //总计=单价*（1-下浮）*开单数量
        // row.amount = +formatNum(row.price * (1 - row.discountRate) * row.num);
        row.amount = +bigNumber.times(bigNumber.times(row.prePrice,(1 - row.discountRate)).toFixed(2),row.num).toFixed(2);
        let dataSource = mode === "edit" ? editGoodsList : addGoodsList
        const index = dataSource.findIndex((item) => row.key === item.key);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        mode === "edit" ? setEditGoodsList([...dataSource]) : setAddGoodsList([...dataSource]);
        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(dataSource)
        }
        setSaveBtn(true)
    }

    return (
        <YhBox title="" action={action} style={{ marginBottom: "75px", paddingTop: "15px" }}>
            <Table
                rowKey={record => record.key}
                rowClassName={(record) => {
                    if (+Number(record.num) > +(record.inventoryNum ?? 0)) return "editable-row yh-edit-table-tr background-FFB4B4"
                    return "editable-row yh-edit-table-tr"
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                    }
                }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns}
                dataSource={searchVal.isSearch ? searchVal.data : mode === "edit" ? editGoodsList : addGoodsList}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}