import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import {  Descriptions,  Button, message } from "antd"
import { getOrderDetailsList } from "@/service/orderSettlement"


// 账单管理
const OrderTDetails = ({ selectInfo }) => {
    console.log(selectInfo)
    const { dispatch, state } = useContext(MyContext)

    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [loading, setLoading] = useState(false) //表格 loading
    const [data, setData] = useState([]) //默认展示数据

    const [searchValue, setSearchValue] = useState({}) //搜索

    useEffect(() => {
        if (state.activeMenu === "orderManageSettleds/look" || state.activeMenu === "orderManageUnsettleds/look") {
            loadData(pagination.current, searchValue)
        }
        return () => setData([])
    }, [state.activeMenu])

    //默认加载数据列表
    const loadData = (current, search = {}) => {
        setLoading(true)

        getOrderDetailsList(selectInfo.rowInfo.id).then((res) => {
            // console.log(res);
            if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })

                setData(res.retData)
                setLoading(false)
            } else {
                // message.error(res.retMsg)
                message.error("请求异常")
            }
        })
    }

    //分页事件 置空当前工单
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //table配置
    const columns = [
        {
            title: "工单编号",
            align: "center",
            width: 130,
            dataIndex: "orderCode",
        },
        {
            title: "工单类型",
            width: 130,
            align: "center",
            dataIndex: "orderType",
        },
        {
            title: "车辆牌照",
            width: 130,
            dataIndex: "customerVehicleCode",
        },
        {
            title: "联系人",
            align: "center",
            width: 130,
            dataIndex: "customerCorporation",
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "customerCorporationPhone",
        },
        {
            title: "服务地点",
            align: "center",
            dataIndex: "repairAddress",
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "itemActualAmount",
        },
        {
            title: "配件费",
            align: "center",
            dataIndex: "partActualAmount",
        },
        // {
        //     title: "工单状态",
        //     align: "center",
        //     dataIndex: "orderStatus",
        //     render: (text) => <>{dictObj.orderStatus[text]}</>,
        // },

        {
            title: "应收金额",
            align: "center",
            dataIndex: "orderTotalAmount",
        },

        {
            title: "实收金额",
            align: "center",
            dataIndex: "orderActualAmount",
        },

        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
        },
    ]

    //列表button
    const topButtons = [
        // {
        //     click: () => handleDetails("settle"),
        //     content: "结算",
        // },
        // {
        //     click: () => handleDetails("look"),
        //     content: "账单明细",
        // },
    ]

    return (
        <>
            <div className="list-container" style={{ padding: "10px" }}>
                <div className="list-header">
                    <div className="list-but">
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                dispatch({
                                    type: "removeTabs",
                                    targetKey: "orderManageUnsettleds/look",
                                    activeMenu: "orderManageUnsettleds",
                                })
                            }}
                        >
                            返回
                        </Button>
                    </div>
                </div>
                <Descriptions>
                    <Descriptions.Item label="账单编号">{selectInfo.rowInfo.settlementNo}</Descriptions.Item>
                    <Descriptions.Item label="客户名称">{selectInfo.rowInfo.customerName}</Descriptions.Item>
                    <br></br>
                    <Descriptions.Item label="账单金额">{selectInfo.rowInfo.totalAmount}</Descriptions.Item>
                    <Descriptions.Item label="已结算">{selectInfo.rowInfo.settledAmount}</Descriptions.Item>
                    <Descriptions.Item label="未结算">
                        <span style={{ color: "red" }}>{selectInfo.rowInfo.unsettledAmount}</span>
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <ListComponent buttons={topButtons} loading={loading} columns={columns} dataSource={data} onTableChange={onTableChange} scroll={{ x: 3300 }} />
        </>
    )
}
export default OrderTDetails
