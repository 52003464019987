import React, { useEffect } from "react"
import { Button, Form, Input, Col, Row, Select, message, Switch } from "antd"
import { insertMaintenance, editMaintenance, getMaintenanceOne } from "@/service/maintenanceService"
import { getPcodeDict } from "@/utils/dataStorage"
import { ItemAttr } from "@/utils/enum"
const ItemAttraArr = Object.entries(ItemAttr)
const { Option } = Select
const MaterialDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()

    useEffect(() => {
        resetFormItem()
    }, [])
    //移动车表单提交
    const materialSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                disableFlag: values.disableFlag ? 0 : 1,
            }
            //新增
            if (mode === "insert") {
                insertMaintenance(data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("配件新增成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //     }
                    // })
                //编辑
            } else if (mode === "edit") {
                editMaintenance({ ...data, id: rowInfo.id })
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("配件编辑成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //     }
                    // })
            }
        })
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit" || mode === "look") {
            getMaintenanceOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                    let ret = res.retData
                    form.setFieldsValue({
                        ...ret,
                        disableFlag: ret.disableFlag === 0 ? true : false,
                    })
                // }
            })
        } else {
            form.setFieldsValue({
                disableFlag: true,
            })
        }
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "materialManage",
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">数据管理</span>
            </div>

            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>配件信息</span>
                    </div>
                    <div className="list-but">
                        {!isLook(mode) && (
                            <Button type="primary" shape="round" size="small" className="but-left" onClick={materialSubmit}>
                                保存
                            </Button>
                        )}
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={2}>
                                <span className="form-title">基本信息</span>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="商品编号" name="partCode"  rules={[{ required: true, message: "请输入商品编号" }]}>
                                    <Input placeholder="请输入商品编号" disabled={isLook(mode)}  />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="商品品牌" name="partBrand">
                                    <Input placeholder="请输入商品品牌" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="产地" name="partOrigin">
                                    <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择产地" style={{ width: "100%" }}>
                                        {getPcodeDict("108").children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="商品名称" name="partName">
                                    <Input placeholder="请输入商品名称" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="规格" name="partSpec">
                                    <Input placeholder="请输入规格" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="公交商品名称" name="partNameBus">
                                    <Input placeholder="请输入公交商品名称" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="公交编号" name="partCodeBus">
                                    <Input placeholder="请输入公交编号" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="单位" name="partUnit">
                                    <Input placeholder="请输入单位" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="分组代码" name="partGroupCode">
                                    <Input placeholder="请输入分组代码" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="分类代码" name="partClassificationCode">
                                    <Input placeholder="请输入分类代码" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="适用车型" name="partApplicableModel">
                                    <Input placeholder="请输入适用车型" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="项目属性" name="partAttr">
                                    <Select disabled={isLook(mode)} allowClear placeholder="请选择项目属性" style={{ width: "100%" }}>
                                        {ItemAttraArr.map((item) => (
                                            <Select.Option value={item[0]} key={item[0]}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item name="disableFlag" label="配件状态" valuePropName="checked">
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="请输入备注" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="from-line" />
                        <Row>
                            <Col span={2}>
                                <span className="form-title">价格信息</span>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="进货价格"
                                    name="partPurchasePrice"
                                    rules={[
                                        {
                                            pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                            message: "请输入数字且可保留两位小数",
                                        },
                                    ]}
                                >
                                    <Input suffix="￥" placeholder="请输入进货价格" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={9}>
                                <Form.Item
                                    label="零售价格"
                                    name="partRetailPrice"
                                    rules={[
                                        {
                                            pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                            message: "请输入数字且可保留两位小数",
                                        },
                                    ]}
                                >
                                    <Input suffix="￥" placeholder="请输入零售价格" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item
                                    label="公交价格"
                                    name="partRetailPriceBus"
                                    rules={[
                                        {
                                            pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                            message: "请输入数字且可保留两位小数",
                                        },
                                    ]}
                                >
                                    <Input suffix="￥" placeholder="请输入公交价格" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default MaterialDetails
