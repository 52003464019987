import React, { useContext, useState, useEffect, useRef } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { repairReportStatistics, repairReportStatisticsExport, repairReportStatisticsTotal } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message, Tooltip, Table, Button } from "antd"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { YhBox } from "@/erp_subpackage/components/YhBox";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { getUserInfo, getPcodeDict } from '@/utils/dataStorage'
import { getTableColumns, getAllTable } from "./table"
import SetTableSort from "@/components/SetTableSort/index"
const saveKey = 'repairReportStatisticsTableSort'

export default () => {
    const { state, Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [totalData, setotalData] = useState({}) //总计
    const [selectedRow, setSelectedRow] = useState([]);
    const { user: { shopId, shopCode, shopName, }, } = useGlobalModel()
    const [searchValue, setSearchValue] = useState({})
    const initDict = () => {
        let obj = {
            tradeType: {}, //单据类型
            orderType: {}, //工单类型
            settlementAccountType: {}, //结算类型
            faultClassification: {}, //故障类型
            orderStatus: {}, //单据状态
            settlementStatus: {}, //支付状态
        }
        // getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).forEach((item) => {
        //     obj.orderType[item.value] = item.label
        // })
        obj.tradeType["trade-type.maintenance"] = "维修单";
        obj.tradeType["trade-type.maintenance-return"] = "维修退单";

        getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).forEach((item) => {
            obj.settlementAccountType[item.value] = item.label
        })
        getPcodeDict("011").children.forEach((item) => {
            obj.faultClassification[item.dictCode] = item.title
        })
        getSelectedDictOne(DictCodeEnmu.PURCHASE_RETURN).forEach((item) => {
            obj.orderStatus[item.value] = item.title
        })
        getPcodeDict("010").children.forEach((item) => {
            obj.settlementStatus[item.dictCode] = item.title
        })
        getPcodeDict("014").children.forEach((item) => {
            obj.orderType[item.dictCode] = item.title
        })
        // setDictObj(obj)
        console.log('字典获取值：obj')
        dictObj.current = obj
    }
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const dictObj = useRef({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    const [showSetTableSort, setShowSetTableSort] = useState(false)//控制table排序
    const [columns, setColumns] = useState([])
    //副标题计算
    useEffect(() => {
        initDict()
        console.log(state.activeMenu)
        // debugger
        if (state.activeMenu === "repairReportStatistics") {
            loadData(pagination.current)
            initTableColumns()
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //控制table排序
    const initTableColumns = async (changedColumns) => {
        if (dictObj.current) {
            let newColumns = await getTableColumns({ dictObj, saveKey }, changedColumns)
            setColumns(newColumns)
        } else {
            setTimeout(() => {
                initTableColumns()
            }, 500)
        }
    }

    //列表数据
    const loadData = (current, search = searchValue,) => {
        setLoading(true)
        let data = {
            searchItem: {
                ...search,
            },
            pageNum: current,
            pageSize: search?.pageSize ?? 10,
            endTime: searchValue.endTime,
            startTime: searchValue.startTime,
            shopCode, shopId,
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                }
            ],

        }
        repairReportStatistics(data).then(({ retData }) => {
            setpagination({
                current: current,
                total: retData.total,
                pageSize: retData.size,
            })
            setList(retData.list)
        })
        repairReportStatisticsTotal(data).then(({ retData }) => {
            // setotalData(retData)
        })
        setLoading(false)
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    const handleExport = () => {
        let data = {
            searchItem: { ...searchValue, },
            pageSize: -1,
            pageNum: 1,
            shopId,
            shopCode,
            startTime: searchValue.startTime,
            endTime: searchValue.endTime,
            orders: [
                // {
                //     column: "createTime",
                //     rule: "DESC",
                // },
            ],

        }
        repairReportStatisticsExport(data).then((res) => {
            message.success(res.retData)
        })
    }

    //点击行
    const onRow = (record) => {
        let findObj = selectedRow.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRows = selectedRow.filter(item => item.id !== record.id);
            setSelectedRow(newSelectedRows);
        } else {
            setSelectedRow(origin => [...origin, record]);
        }
    };

    //列表button
    const topButtons = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => handleExport()}
        >导出</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => setShowSetTableSort(true)}
        >设置table排序</Button>
    </>

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "单据编号",
                valueName: "orderCode",
            },
            {
                type: "input",
                placeholder: "车辆牌照",
                valueName: "vehicleCode",
            },
            {
                type: "input",
                placeholder: "商品编码",
                valueName: "materialCode",
            },
            {
                type: "input",
                placeholder: "商品名称",
                valueName: "materialName",
            },
            {
                type: "input",
                placeholder: "制单员",
                valueName: "orderCreateUser",
            },


        ],
        hideList: [
            {
                type: "select",
                placeholder: "单据类型",
                valueName: "tradeType",
                optionList: () => [
                    <Option key={'trade-type.maintenance'} value={'trade-type.maintenance'}>维修单</Option>,
                    <Option key={'trade-type.maintenance-return'} value={'trade-type.maintenance-return'}>维修退单</Option>,
                ]
            },

            {
                type: "select",
                placeholder: "结算类型",
                valueName: "settlementAccountType",
                optionList: () => [
                    <Option key={'account-type.cash'} value={'account-type.cash'}>现金</Option>,
                    <Option key={'account-type.credit-month'} value={'account-type.credit-month'}>月结</Option>,
                    <Option key={'account-type.credit'} value={'account-type.credit'}> 挂账</Option>,
                    <Option key={'account-type.tco'} value={'account-type.tco'}>大包</Option>
                ]
            },
            {
                type: "select",
                placeholder: "故障类型",
                valueName: "faultClassification",
                optionList: () =>
                    getPcodeDict('011').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: "select",
                placeholder: "单据状态",
                valueName: "orderStatus",
                optionList: () =>

                    getPcodeDict("order_status", "").children.map((item) => {
                        return (
                            <Option value={item.dictCode} key={item.id}>
                                {item.title}
                            </Option>
                        )
                    }).concat(<>
                        <Option value='pending_review' key='pending_review'>
                            {'待审核'}
                        </Option>
                        <Option value='billed' key='billed'>
                            {'已开单'}
                        </Option>
                        <Option value='in_stock' key='in_stock'>
                            {'已入库'}
                        </Option>
                        <Option value='settled' key='settled'>
                            {'已结算'}
                        </Option>
                    </>)
            },
            {
                type: "RangePicker",
                valueName: ["settlementStartTime", "settlementEndTime"],
                placeholder: ["结算开始", "结算结束"],
                span: 16,
                xxl: 8,
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                placeholder: ["工单开始", "工单结束"],
                span: 16,
                xxl: 8,
            },
            {
                type: 'select',
                placeholder: '工单类型',
                valueName: 'orderType',
                optionList: () =>
                    getPcodeDict('014').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
        ],
    }
    const desc = [
        {
            label: "材料数量",
            value: totalData.totalNum ?? 0,
            color: "green"
        },
        {
            label: "材料实收金额",
            value: "￥" + formatNum(totalData.payAmount ?? 0),
            color: "red"
        },
        {
            label: "材料成本合计",
            value: "￥" + formatNum(totalData.receivedAmount ?? 0),
            color: "red"
        },
        {
            label: "材料毛利合计",
            value: "￥" + formatNum(totalData.paidAmount ?? 0),
            color: "red"
        },


    ]

    return (
        <>
            {state.activeMenu === 'repairReportStatistics' && (
                <>
                    <TopSearch
                        title="维修单明细报表"
                        justify={"start"}
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <YhBox title="维修单明细报表" action={topButtons} descriptions={desc} style={{ marginTop: 20 }}>
                        <Table
                            loading={loading}
                            columns={columns}
                            owSelection={{
                                //全部选中时 (存row[])
                                onSelectAll: (selected, selectedRows, changeRows) => {
                                    selected ? setSelectedRow((origin) => [...origin, ...changeRows])
                                        : setSelectedRow((origin) => {
                                            let changeRowId = changeRows.map(item => item.id);
                                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                                        });
                                },
                                onSelect: (record, selected, selectedRows) => onRow(record),
                                selectedRowKeys: selectedRow.map(item => item.id)
                            }}
                            dataSource={list}
                            rowKey={record => record.id}
                            pagination={{
                                ...pagination,
                                position: ['bottomCenter'],
                                showTotal: () => `共${pagination.total}条`,
                                onChange: (current, serach = searchValue) => {
                                    setSearchValue({ ...searchValue, pageSize: serach })
                                    loadData(current, searchValue)
                                },
                            }}
                            scroll={{ x: 'max-content' }}
                            onRow={record => ({
                                onClick: () => onRow(record),
                                onContextMenu: (e) => {
                                    e.preventDefault();
                                    setSelectedRow([record]);
                                }

                            })}
                        />
                    </YhBox>
                    <SetTableSort
                        visible={showSetTableSort}
                        setShowTableSort={setShowSetTableSort}
                        saveKey={saveKey}
                        allData={getAllTable()}
                        handleChangeColumns={initTableColumns}
                    />
                </>
            )}
        </>
    )
}
