import React, { useEffect, useState } from "react"
import { Modal, Button, Input, Select, Radio, Col, Row, Form, Tag, Typography } from "antd"
import styled from "@emotion/styled"
import { getVehicleList } from '@/service/customerService'
import { vehicleBrandnamePage, vehicleBrandSegmentQuery } from "@/service/dataService"

const { Option } = Select
export default (props) => {
    const [form] = Form.useForm()
    const { visible, vehicleCode, brandName, isSpecial, customerName, onSuc, onClose } = props
    const [brandNameList, setBrandNameList] = useState([]);
    const [selectBrandId, setSelectBrandId] = useState("");
    const [radioDisabled, setRadioDisabled] = useState(false);
    //可能车型
    const [possible, setPossible] = useState(false);
    const [possibleList, setPossibleList] = useState([]);

    const onSubmit = () => {
        form.validateFields().then((res) => {
            onSuc(res)
        })
    }

    useEffect(() => {
        let D = {
            pageSize: 1,
            currentPage: 1,
            searchItem: {
                exactVehicleCode: vehicleCode,
            },
        };
        getVehicleList(D).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.vehicleCode,
                    value: item.id,
                    ...item,
                }))
                form.setFieldsValue({
                    existCustomerName: data[0].customerName,
                    customerChoose: 1
                })
            }
            else {
                setRadioDisabled(true);
                form.setFieldsValue({
                    customerChoose: 2
                })
            }
        });

        let data = {
            pageNum: 1,
            pageSize: 1000,
            searchItem: {},
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        vehicleBrandnamePage(data).then((res) => {
            let branNameList = res.retData.list;
            setBrandNameList(branNameList);

            let brand = branNameList.filter((e) => e.brandName == brandName || e.brandName == brandName.replace('牌', ''));
            if (brand.length > 0 && brand[0].brandLevel == 1) {
                form.setFieldsValue({
                    brandName: brand[0].brandName,
                    selectBrandId: brand[0].id,
                })
                setSelectBrandId(brand[0].id);
            }
            else if (brand.length > 0 && brand[0].brandLevel == 2) {
                let parent = branNameList.filter((e) => e.brandLevel == 1 && e.id == brand[0].parentId)
                form.setFieldsValue({
                    brandName: parent[0].brandName,
                    brandSecond: brand[0].brandName,
                    selectBrandId: brand[0].parentId,
                })
                setSelectBrandId(parent[0].id);
            }
            else {
                vehicleBrandSegmentQuery(brandName).then((res) => {
                    setPossible(true);
                    setPossibleList(res.retData);
                });
            }
        })
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            vehicleCode,
            customerName,
        })
    }, [vehicleCode])// eslint-disable-line react-hooks/exhaustive-deps


    const handleChangebrandName = (value, option) => {
        setSelectBrandId(option.info.id);
    }

    const handleChangePossible = async (brand) => {
        if (brand != null && brand.brandLevel == 1) {
            form.setFieldsValue({
                brandName: brand.brandName,
                selectBrandId: brand.id,
            })
            setSelectBrandId(brand.id);
        }
        else {
            form.setFieldsValue({
                brandName: brand.parentName,
                brandSecond: brand.brandName,
                selectBrandId: brand.parentId,
            })
            setSelectBrandId(brand.parentId);
        }
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    return (
        <Conetnt>
            <Modal
                title="确认信息"
                width={500}
                open={visible}
                visible={visible}
                onCancel={() => onClose(false)}
                footer={[
                    <Button type="primary" onClick={onSubmit} key="submit">
                        确认
                    </Button>,
                ]}
            >
                <Form form={form} {...formItemLayout}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                name="vehicleCode"
                                label="车牌牌照"
                                rules={
                                    isSpecial
                                        ? [
                                            {
                                                required: true,
                                                message: "请输入车辆牌照",
                                            },
                                        ]
                                        : [
                                            {
                                                required: true,
                                                message: "请输入车辆牌照",
                                            },
                                            {
                                                pattern: /^[\u4E00-\u9FA5][\da-zA-Z]{6,7}$/,
                                                message: "请输正确的车辆牌照",
                                            },
                                        ]
                                }
                            >
                                <Input placeholder="请输入车牌号" />
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                name="brandName"
                                label="车牌品牌"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择车牌品牌",
                                    },
                                ]}
                            >
                                <Select placeholder="请选择车牌品牌" onChange={handleChangebrandName} style={{ width: '100%' }}>
                                    {brandNameList.filter((e) => e.brandLevel == 1).map((item) => (
                                        <Option value={item.brandName} key={item.brandName} info={item}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                name="brandSecond"
                                label="车系"
                            >
                                <Select placeholder="请选择车系" style={{ width: '100%' }}>
                                    {brandNameList.filter((e) => e.brandLevel == 2 && e.parentId == selectBrandId).map((item) => (
                                        <Option value={item.brandName} key={item.brandName} info={item}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item hidden name="selectBrandId">
                                <Input />
                            </Form.Item>
                            {possible && possibleList.length > 0 && <div style={{ marginBottom: 15, padding: 10, border: '1px dashed #808080' }}>
                                <div style={{ height: 30 }}>
                                    <Typography.Text type="danger" style={{ marginBottom: 5 }}>无法匹配:{brandName}</Typography.Text>
                                    <Typography.Text type="success" style={{ marginLeft: 20, marginBottom: 5 }}>请选择可能车型:</Typography.Text>
                                </div>
                                {possibleList.map((item) => (
                                    <Tag style={{ marginBottom: 5 }} onClick={() => handleChangePossible(item)}>{item.brandLevel == 1 ? item.brandName : item.parentName + ' - ' + item.brandName}</Tag>
                                ))}
                            </div>
                            }
                            <Form.Item
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                name="existCustomerName"
                                label="客户名称">
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                name="customerName"
                                label="识别名称"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入识别名称",
                                    },
                                ]}>
                                <Input placeholder="请输入识别名称" />
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 4 }}
                                wrapperCol={{ span: 20 }}
                                name="customerChoose">
                                <Radio.Group disabled={radioDisabled}>
                                    <Radio value={1}>使用现有客户名称</Radio>
                                    <Radio value={2}>更新为识别的客户名称</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Conetnt>
    )
}

const Conetnt = styled.div`
    .ant-modal-close {
        display: none
    }
`