import React, { useContext, useState, useEffect } from "react"
import { MyContext } from "@/store/myContext"
import { Table, Space, Button, Tag, Popconfirm, message } from "antd"
import { tdmsCategoryRootlist, tdmsCategorySearchTree, tdmsCategoryDelete } from "@/service/tdmsService"
import EditModal from "./editModal"
import { menuStatusEnum, menuTypeEnum } from "@/config/Constants"
import moment from "moment"
/**
 * 菜单管理
 */
export default () => {
    const { state, message } = useContext(MyContext)
    const [editVisible, setEditVisible] = useState(false)
    const [datasource, setDataSource] = useState([])
    const [selectInfo, setSelectInfo] = useState({}) //记录列表当前行下标及当前行信息
    const [editId, setEditId] = useState(null)
    const [mode, setMode] = useState("")
    const columns = [
        {
            title: "分类名称",
            dataIndex: "name",
            key: "name",
            width: "20%",
        },
        {
            title: "分类状态",
            dataIndex: "status",
            key: "status",
            render: (tag) => <Tag key={tag}>{tag === 0 ? "正常" : "禁用"}</Tag>,
        },
        {
            title: "排序",
            dataIndex: "orderNum",
            key: "orderNum",
        },
        {
            title: "时间",
            dataIndex: "createTime",
            key: "createTime",
            render: (time) => moment(time).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "操作",
            width: 200,
            align: "center",
            render: (_, record) => (
                <>
                    <Button
                        shape="round"
                        className="yc-public-but"
                        onClick={() => {
                            setMode("edit")
                            setEditVisible(true)
                        }}
                    >
                        编辑
                    </Button>
                    <Popconfirm okText="确认" cancelText="关闭" title="删除操作将会删除本分类和分类下属分类！" onConfirm={(row) => onDelete(record)}>
                        <Button shape="round" className="yc-public-but">
                            删除
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
    ]

    useEffect(() => {
        if (state.activeMenu === "tdmsCategory") {
            loadData()
        }
    }, [state.activeMenu])

    const builderTree = (treeData) => {
        if (!treeData) {
            return
        }
        treeData.map((i) => {
            let children = i.children
            if (!children || children.length == 0) {
                delete i.children
            } else {
                builderTree(children)
            }
        })
    }

    //选择行
    const selectRow = async (record) => {
        await setSelectInfo({ ...record })
        await setEditId(record.id)
    }

    //列表数据
    const loadData = (search = {}) => {
        let data = { ...search }
        tdmsCategorySearchTree(data).then((res) => {
            // if (res && res.retCode === 200) {
            let treeData = res.retData
            builderTree(treeData)
            setDataSource(treeData)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    // rowSelection objects indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows)
        },
    }

    const onDelete = (record) => {
        tdmsCategoryDelete(record.id).then((res) => {
            message.success("删除成功！")
            loadData()
        })
    }

    return (
        <>
            <EditModal mode={mode} row={selectInfo} visible={editVisible} onLoadData={loadData} onCancel={(bool) => setEditVisible(bool)} />

            <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                    shape="round"
                    className="but-left"
                    onClick={() => {
                        setMode("add")
                        setEditId(null)
                        setEditVisible(true)
                    }}
                >
                    新增
                </Button>
                {/* <Button
                    shape="round"
                    className="but-left"
                    onClick={() => {
                        setMode("edit")
                        setEditVisible(true)
                    }}
                >
                    编辑
                </Button> */}
            </Space>

            <Table
                columns={columns}
                indentSize={30}
                rowKey={"id"}
                dataSource={datasource}
                onRow={(record, index) => ({
                    onClick: () => selectRow(record),
                })}
                rowClassName={(record, index) => {
                    let className = ""

                    if (record.id === selectInfo.id) className = "dark-row "
                    return className
                }}
                pagination={false}
            />
        </>
    )
}
