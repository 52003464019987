import React from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { AddGoodsModal } from "./AddGoodsModal";
import { SettleDrawer } from "./SettleDrawer";
import { WhConfirmDrawer } from "./WhConfirmDrawer";

export const SaleRoDetails = (props) => {
    return (
        <>
            <TopButs {...props} />
            <FormBox {...props} />
            <TableBox {...props} />
            <AddGoodsModal {...props} />
            <FixedBottom {...props} />
            <SettleDrawer {...props} />
            <WhConfirmDrawer  {...props} />
        </>
    )
}