import React from "react"
import { useStockEntryModel } from "../entryModel"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export function PageHeader() {
    const { searchParam, organType, loadData, updateSearchParam, resetSearchParam } = useStockEntryModel()
    const { getSelectedDictOne } = useDictModel(() => [])
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);

    const searchFields = [
        ...(organType ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        // {
        //     type: "Input",
        //     placeholder: "商品编号",
        //     fieldName: "materialCode",
        // },
        {
            type: "Select",
            placeholder: "单据类型",
            fieldName: "tradeTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE),
        },
        {
            type: "Input",
            placeholder: "单据编号",
            fieldName: "tradeCode",
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        }, {
            type: "Input",
            placeholder: "入库单位",
            fieldName: "userName",
        },
    ]

    return (
        <YhPageHeader
            title="入库管理"
            size="small"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => {
                loadData({ pageNum: 1 }, { ...searchParam, ...params })
            }}
            handleReset={resetSearchParam}
        />
    )
}
