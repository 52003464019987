import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import { message, Space, Button, Modal } from "antd"
import { organUpdate, organDelete, organPage } from "@/service/orgService"
import { Map, Polygon } from "react-amap"
import ManageList from "./manageList/user/index"
import MoveCarManage from "../moveCarManage"
import TeamManage from "../teamManage"
import ClientRecord from "../customerManage/clientRecord"
import AddOrg from "./add"
import EditOrg from "./eidt"
import AccountInfo from "./account"
//套餐模块
export default () => {
    const { dispatch, state } = useContext(MyContext)
    const [map2Visible, setmap2Visible] = useState(false) //预览图片使用的dialog
    const [path, setPath] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]) //默认展示数据
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [isShowAccount, setIsShowAccount] = useState(false)

    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "orgManage") {
            loadData(pagination.current)
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
        }
        return () => setData([])
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadData = (current) => {
        setLoading(true)
        let data = {
            searchItem: { orderFlag: 0, showPlatformOrgan: true },
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        organPage(data).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setData(res.retData.list)
            setLoading(false)
            // }
        })
    }
    const onTableChange = async (page) => {
        setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "新增机构",
            edit: "编辑机构",
            manage: "管理",
        }
        let modeKey = "orgManage/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择！")
                return
            } else {
                //每次查看把之前的删除， 重新生成
                await dispatch({
                    type: "removeTabs",
                    targetKey: modeKey,
                })
                await dispatch({
                    type: "changeTabs",
                    name: tabName[mode],
                    activeMenu: modeKey,
                    content: <EditOrg rowInfo={selectInfo.rowInfo} dispatch={dispatch} />,
                })
            }
        } else if (mode === "insert") {
            await dispatch({
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: <AddOrg dispatch={dispatch} />,
            })
        } else if (mode === "delete") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择！")
                return
            } else {
                Modal.confirm({
                    content: `确定要删除机构 - ${selectInfo.rowInfo.organName}?`,
                    okText: "确定",
                    cancelText: "取消",
                    centered: true,
                    onOk() {
                        organDelete(selectInfo.rowInfo.id).then((res) => {
                            // if (res && res.retCode === 200) {
                            message.success("删除成功")
                            loadData(pagination.current)
                            // } else {
                            //     message.error(res.retMsg)
                            // }
                        })
                    },
                })
            }
        } else if (mode === "manage") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择！")
                return
            } else {
                //每次查看把之前的删除， 重新生成
                await dispatch({
                    type: "removeTabs",
                    targetKey: modeKey,
                })
                await dispatch({
                    type: "changeTabs",
                    name: tabName[mode],
                    activeMenu: modeKey,
                    content: <ManageList dispatch={dispatch} rowInfo={selectInfo.rowInfo} />,
                })
            }
        }
    }

    const handleToManage = async (val) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择！")
            return
        }
        if (val === 1) {
            await dispatch({
                type: "removeTabs",
                targetKey: "personnelManagement",
            })
            dispatch({
                type: "changeTabs",
                name: "人员管理",
                activeMenu: "personnelManagement",
                content: <ManageList organId={selectInfo.rowInfo.id} />,
            })
        } else if (val === 2) {
            await dispatch({
                type: "removeTabs",
                targetKey: "moveCarManage",
            })
            dispatch({
                type: "changeTabs",
                name: "移动车管理",
                activeMenu: "moveCarManage",
                content: <MoveCarManage organId={selectInfo.rowInfo.id} />,
            })
        } else if (val === 3) {
            await dispatch({
                type: "removeTabs",
                targetKey: "teamManage",
            })
            await dispatch({
                type: "changeTabs",
                name: "团队管理",
                activeMenu: "teamManage",
                content: <TeamManage organId={selectInfo.rowInfo.id} />,
            })
        } else if (val === 4) {
            await dispatch({
                type: "removeTabs",
                targetKey: "clientRecord",
            })
            await dispatch({
                type: "changeTabs",
                name: "客户管理",
                activeMenu: "clientRecord",
                content: <ClientRecord organId={selectInfo.rowInfo.id} />,
            })
        }
    }
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDetails("delete"),
            content: "删除",
        },
        {
            click: () => handleToManage(1),
            content: "人员管理",
        },
        {
            click: () => handleToManage(2),
            content: "服务车管理",
        },
        {
            click: () => handleToManage(3),
            content: "团队管理",
        },
        {
            click: () => handleToManage(4),
            content: "客户管理",
        },
        {
            click: () => {
                if (!Object.keys(selectInfo.rowInfo).length) {
                    message.warning("请选择！")
                    return
                }
                setIsShowAccount(true)
            },
            content: "账户管理",
        },
        // {
        //     click: () => handleDetails('import'),
        //     content: "批量导入"
        // }
    ]
    const columns = [
        {
            title: "机构代码",
            algin: "center",
            dataIndex: "organCode",
        },
        {
            title: "机构名称",
            algin: "center",
            dataIndex: "organName",
        },
        {
            title: "机构属性",
            algin: "center",
            dataIndex: "organType", //////1-区域管理中心 2-区域实体店"

            render: (organType) => (
                <span>
                    {
                        {
                            1: "区域管理中心",
                            2: "区域实体店",
                        }[organType]
                    }
                </span>
            ),
        },
        {
            title: "机构性质",
            algin: "center",
            dataIndex: "organNature", ////1-自营 2-合伙 3-加盟"
            render: (organNature) => (
                <span>
                    {
                        {
                            1: "自营",
                            2: "合伙",
                            3: "加盟",
                        }[organNature]
                    }
                </span>
            ),
        },
        {
            title: "联系人",
            algin: "center",
            dataIndex: "organContactName",
        },
        {
            title: "联系电话",
            algin: "center",
            dataIndex: "organContactPhone",
        },
        {
            title: "地址",
            dataIndex: "organContactAddress",
        },
        {
            title: "服务区域",
            algin: "center",
            dataIndex: "serviceArea",
            render: (es) =>
                es ? (
                    <Button
                        size={"small"}
                        onClick={() => {
                            setmap2Visible(true)
                            setPath(JSON.parse(es))
                        }}
                    >
                        {" "}
                        查看
                    </Button>
                ) : null,
        },
        {
            title: "状态",
            algin: "center",
            dataIndex: "organStatus",
            render: (
                organStatus //1-可用 2-不可用
            ) => (
                <span>
                    {
                        {
                            1: "可用",
                            2: "不可用",
                        }[organStatus]
                    }
                </span>
            ),
        },
        {
            title: "操作",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <a
                        onClick={(e) => {
                            e.stopPropagation()
                            organUpdate({
                                ...record,
                                organStatus: record.organStatus === 1 ? 2 : 1,
                            }).then((res) => {
                                // if (res && res.retCode === 200) {
                                message.success("修改成功")
                                loadData(pagination.current)
                                // } else {
                                //     message.error(res.retMsg)
                                // }
                            })
                        }}
                    >
                        {record.organStatus === 1 ? "禁用" : "启用"}
                    </a>
                </Space>
            ),
        },
    ]
    return (
        <>
            <ListComponent
                title="机构管理"
                buttons={topButtons}
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={pagination}
                onTableChange={onTableChange}
                selectInfo={selectInfo}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />

            <Modal
                bodyStyle={{ height: "400px" }}
                visible={map2Visible}
                cancelText={"null"}
                onCancel={() => setmap2Visible(false)}
                footer={[
                    <Button
                        key="submit"
                        onClick={() => {
                            setmap2Visible(false)
                        }}
                    >
                        确定
                    </Button>,
                ]}
            >
                <Map
                    plugins={["ToolBar"]}
                    events={{
                        created: (ins) => {
                            console.log(ins)
                        },
                        click: (e) => {
                            // tool&&tool.close(true)
                        },
                    }}
                >
                    <Polygon
                        style={{
                            strokeColor: "#4ca3fc", //线颜色
                            strokeOpacity: 1, //线透明度
                            strokeWeight: 2, //线粗细度
                            fillColor: "#4ca3fc", //填充颜色
                            fillOpacity: 0.35, //填充透明度
                        }}
                        visible={true}
                        path={path}
                    />
                </Map>
            </Modal>

            {
                isShowAccount && <AccountInfo organInfo={selectInfo.rowInfo} visible={isShowAccount} setVisible={setIsShowAccount} />
            }
        </>
    )
}
