import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { HashRouter as Router } from "react-router-dom"
import history from "./utils/history"
import { ConfigProvider } from "antd"
// import zh_CN from 'antd/lib/locale-provider/zh_CN';
// import moment from 'moment';
// import 'moment/locale/zh-cn';
// moment.locale('zh-cn');

import "moment/locale/zh-cn"
import locale from "antd/es/locale/zh_CN"
import "./styles/index.less"
import {createQQmapScripit} from "@/utils/qqmap.js"

// useEffect(() => {
// //创建腾讯地图script
// 
// },[])
createQQmapScripit()

ReactDOM.render(
    <Router>
        <ConfigProvider locale={locale}>
            <App {...history} />
        </ConfigProvider>
    </Router>,
    document.getElementById("root")
)
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
        registration.unregister()
    })
}
