import React from "react";
import { useInternalModel } from "../internalModel";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";

export const internalStatusOption = [
    { label: "待申请", value: 0 },
    { label: "待对方确认", value: 1 },
    { label: "对方不认可", value: 2 },
    { label: "待财务确认", value: 3 },
    { label: "对方已驳回", value: 4 },
    { label: "待财务审核", value: 5 },
    { label: "财务已驳回", value: 6 },
    { label: "待最终审核", value: 7 },
    { label: "最终审核通过", value: 8 },
    { label: "最终已驳回", value: 9 },
]


export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useInternalModel();

    const searchFields = [
        {
            type: 'Input',
            placeholder: "对方单位",
            fieldName: "adverseAccountName",
        },
        {
            type: 'Select',
            placeholder: "状态",
            fieldName: "status",
            options: internalStatusOption
        },
    ]
    return (
        <>
            <YhPageHeader
                title="内部对账单"
                size="small"
                justify="end"
                searchValues={searchParam}
                searchFields={searchFields}
                onChange={updateSearchParam}
                triggerSearch
                handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
                handleReset={resetSearchParam}
            />
        </>
    );
}