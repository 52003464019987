import React, { useEffect } from "react";
import { Table } from "antd";
import { useWarehouseModel } from "../warehouseModel";
import YhTooltip from '@/components/YhTooltip'

const columns = [
    {
        title: '序号',
        width: 60,
        align: "center",
        fixed: "left",
        render: (t, r, i) => i + 1
    },
    {
        title: '所属机构',
        width: 160,
        align: "center",
        fixed: "left",
        dataIndex: 'shopName',
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '仓库编码',
        width: 160,
        align: "center",
        dataIndex: 'code',
        fixed: "left",
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '仓库类型',
        width: 160,
        align: "center",
        dataIndex: 'typeName',
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '仓库名称',
        width: 180,
        align: "center",
        dataIndex: 'name',
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '机构负责人',
        width: 180,
        align: "center",
        dataIndex: 'orgContact',
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '仓库负责人',
        width: 180,
        align: "center",
        dataIndex: 'contact',
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '联系电话',
        width: 180,
        align: "center",
        dataIndex: 'phone',
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '联系地址',
        width: 180,
        align: "center",
        dataIndex: 'address',
        render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
    },
    {
        title: '创建时间',
        width: 180,
        align: "center",
        dataIndex: 'createTime',
    },
    {
        title: '备注',
        width: 180,
        align: "center",
        dataIndex: 'note',
    }
];


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = useWarehouseModel();

    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={{ tip: "列表加载中...", spinning: loading }}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.id);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                },
                onSelect: (record) => onRow(record.id),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
