import React, { useContext } from "react";
import { Button, Modal, message } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useSaleDcModel } from "../seleDcModel";
import { MyContext } from "@/store/myContext"
import { formatNum } from '@/erp_subpackage/utils/util';

export const DataBox = () => {
    const { selectedRowKeys, statistics, exportSaleDc, exportSDcdetail,rowsDescTotal } = useSaleDcModel();
    const { state, dispatch, detailsPage } = useContext(MyContext)

    const goPageNav = (mode, rowCode) => {
        let tabName = {
            add: "调出单新增",
            edit: "调出单编辑",
            look: "调出单查看",
        }
        let modeKey = "saleDc/" + mode
        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.SaleDcDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: rowCode ?? selectedRowKeys[0]?.code
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find((item) => item.key === modeKey)
            if (findNav) {
                Modal.confirm({
                    title: "温馨提醒！",
                    content: "您还有调出单详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    cancelText: "打开新页签",
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    },
                })
                return
            }
            handleDispatch()
        } else if (mode === "add") {
            handleDispatch()
        } else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    const desc = [
        {
            label: "调出数量",
            value: !selectedRowKeys.length ? statistics?.totalNum: rowsDescTotal.totalNum,
            color: 'green'
        },
        {
            label: "应收金额",
            value: !selectedRowKeys.length ? `￥${formatNum(statistics?.totalAmount)}`: `￥${formatNum(rowsDescTotal.totalAmount)}`,
            color: 'red'
        },
    ]

    const action = <>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => goPageNav('add')}
        >新增</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return
                }
                goPageNav("edit")
            }}
        >编辑</Button>
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => exportSaleDc()}
        >导出</Button>
        {/* <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                // if (selectedRowKeys.length !== 1) {
                //     message.warning("请选择单条数据进行明细导出，点击鼠标右键可一键选中哦！")
                //     return;
                // }
                // exportSDcdetail(selectedRowKeys[0].code)
                exportSDcdetail()
            }}
        >导出明细</Button> */}
    </>;

    return (
        <YhBox title="调出单列表" descriptions={desc} action={action}>
            <DataTable goPageNav={goPageNav} />
        </YhBox>
    )
}