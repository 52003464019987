import React, { useContext } from "react"
import { Button, message, Modal, Upload, Spin } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useSupplierModel } from "../supplierModel"
import { DataTable } from "./DataTable"
import { MyContext } from "@/store/myContext"
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import moment from 'moment';

export const DataBox = () => {
    const { selectedRowKeys, searchParam, organType, exportSupplier, sync, downloadTpl, loadData, uploading, setUploading } = useSupplierModel()
    const { state, dispatch, detailsPage } = useContext(MyContext)
    const { user: { shopId, shopName, allowTransfer } } = useGlobalModel()

    //新增 查看 编辑
    const goPageNav = (mode) => {
        let tabName = {
            add: "供应商新增",
            edit: "供应商编辑",
            look: "供应商查看",
        }
        let modeKey = "baseSupplier/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.BaseSupplierDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: selectedRowKeys[0],
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find((item) => item.key === modeKey)
            if (findNav) {
                Modal.confirm({
                    title: "温馨提醒！",
                    content: "您还有供应商详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    cancelText: "打开新页签",
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    },
                })
                return
            }
            handleDispatch()
        } else if (mode === "add") {
            handleDispatch()
        } else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    const uploadCallback = (params) => {
        setUploading({ spinning: false })
        params && loadData()
    }
    const action = (
        <>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    goPageNav("add")
                }}
                //各机构有调拨权限才可以新增
                hidden={!allowTransfer}
            >

                新增
            </Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                        return
                    }
                    goPageNav("edit")
                }}
                disabled={searchParam.shopId}
            >
                编辑
            </Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                        return
                    }
                    goPageNav("look")
                }}
            >
                查看
            </Button>
            <Upload showUploadList={false}  {...importPropsConfig(`/ci-storage/base/supplier/import`, uploadCallback, { shopId, shopName }, () => setUploading({ spinning: true, tip: "加速上传中..." }))}>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    disabled={searchParam.shopId}
                    //各机构只有平台一个供应商，没有导入
                    hidden={!organType}
                >导入</Button>
            </Upload>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => exportSupplier()}
            >
                导出
            </Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => downloadTpl()}
                disabled={searchParam.shopId}
                hidden={!organType}
            >
                下载模板
            </Button>
            {/* <Button
                className="btn-item"
                shape="round"
                onClick={() => sync()}
            >
                同步
            </Button> */}
            {/* <Button
                className="btn-item"
                shape="round"
                onClick={() => sync({ createTime: moment(new Date()).format("YYYY-MM-DD") })}
            >
                增量同步
            </Button> */}
        </>
    )
    return (
        <Spin {...uploading}>
            <YhBox title="供应商列表" action={action}>
                <DataTable />
            </YhBox>
        </Spin>
    )
}
