import React, { useContext } from "react"
import { Button, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useProductModel } from "../productModel"
import { DataTable } from "./DataTable"
import { MyContext } from "@/store/myContext"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

export const DataBox = () => {
    const { selectedRowKeys } = useProductModel()
    const { dispatch, detailsPage } = useContext(MyContext)
    // 批量上架
    const onBatchUp = (mode) =>{
        if(selectedRowKeys.find(item => item.upStatus === DictCodeEnmu.DISPLAY_STATUS_ALREADY)){
            message.warning('所选数据有已上架的商品!')
            return
        }
        let tabName = {
            one: "商品上架",
        }
        let modeKey = "product/" + mode
        console.log("选中数据", selectedRowKeys);

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.ProductDetails({
                    dispatch,
                    modeKey,
                    mode,
                    // 选中的数据
                    goodList: selectedRowKeys
                    // type:"batch"
                }),
            })
        }
        handleDispatch()
    }

    const action = (
        <>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    onBatchUp("one")
                }}
            >
                批量上架
            </Button>
        </>
    )
    return (
        <YhBox title="商品列表" action={action}>
            <DataTable />
        </YhBox>
    )
}
