import { post } from "../utils/request"

/**
 * 百度识别接口
 */

//行驶证
function getvehicleLicenseInfo(fileMd5) {
    return post(`/ci/baidu_api/vehicle_license/${fileMd5}`, {}, {}, { timeout: 1000 * 120 })
}
//驾驶证
function getdrivingLicenseInfo(fileMd5) {
    return post(`/ci/baidu_api/driving_license/${fileMd5}`, {}, {}, { timeout: 1000 * 120 })
}

export { getvehicleLicenseInfo, getdrivingLicenseInfo }
