export const defFlagObj = {
    0: "正常",
    1: "默认",
}

export const templateTypeObj = {
    big_bag: "大包",
    ordinary: "普通",
}

export const attrFileTypeObj = {
    img:"图片",
    file:"文件"
}

export const requiredObj = {
    0: "正常",
    1: "必填",
}

export const disableFlagObj = {
    0: "正常",
    1: "禁用",
}