import React from "react";
import { Radio } from "antd";

export const SearchRadio = (props) => {

  const onChange = ({ target: { value } }) => {
    props.onChange && props.onChange({ [props.fieldName]: value })
    if (props.triggerSearch) {
      props.handleSearch && props.handleSearch({ [props.fieldName]: value });
    }
  };
  return <Radio.Group
    options={props?.plainOptions ?? []}
    onChange={onChange} disabled={props.disabled}
    value={props.searchValues?.[props.fieldName]}
  />

}