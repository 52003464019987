import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, Spin, message, Modal, Radio, DatePicker } from "antd"
import { getCustomerListNew, addReturnVisit, editReturnVisit, getReturnVisitOne, getVehicleList } from "@/service/customerService"
import { getReturnVisitList } from "@/service/customerService"
import debounce from "lodash/debounce"
import { getPcodeDict } from "@/utils/dataStorage"
import { timeShift } from "@/utils/util"
import { getOrderOne, getOrderList } from "@/service/ordersService"
import { GetChinese, RemoveChinese } from "@/utils/util"
import moment from "moment"

const { Option } = Select

export default (props) => {
    const { mode, rowInfo, orderId, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        data: [],
        fetching: false,
        id: "",
    })
    const [vehicleInfo, setVehicleInfo] = useState({
        //车辆列表
        data: [],
        fetching: false,
    })
    const [orderInfo, setorderInfo] = useState({
        //订单列表
        data: [],
        fetching: false,
    })
    useEffect(() => {
        queryMotorcade()
        if (orderId && mode === "insert") {
            resetFormFromOrderId(orderId)
        }
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            getReturnVisitOne(rowInfo.id).then((res) => {
                let results = res.retData
                console.log(results)
                setCustomerInfo({
                    data: [
                        {
                            text: results.customerName,
                            value: results.customerId,
                        },
                    ],
                    fetching: false,
                    id: results.customerId,
                })
                setVehicleInfo({
                    data: [
                        {
                            text: results.vehicleCode,
                            value: results.crmsVehicleId,
                        },
                    ],
                    fetching: false,
                })

                setorderInfo({
                    data: [
                        {
                            text: results.orderCode,
                            value: results.orderId,
                        },
                    ],
                    fetching: false,
                })
                form.setFieldsValue({
                    ...results,
                    orderCompletedTime: results.orderCompletedTime ? moment(results.orderCompletedTime) : null,
                    returnVisitTime: results.returnVisitTime ? moment(results.returnVisitTime) : null,
                    createTime: results.createTime ? moment(results.createTime) : null,
                })
            })
        } else {
            form.setFieldsValue({
                userStatus: true,
            })
        }
    }

    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            filterCustomer: 1,
            // customerName: value,
            searchItem:{customerName:value}
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                        ...item,
                    })
                }
                return arr
            }, [])
            setCustomerInfo({
                ...customerInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    //客户选择
    const handleChangeCustomer = (value, option) => {
        console.log(option)
        setCustomerInfo({
            ...customerInfo,
            id: option.value,
        })
        form.setFieldsValue({
            corporationName: option.info.customerCorporation,
            corporationPhone: option.info.customerCorporationPhone,
        })
        //当客户重新选择时  置空订单和 车辆
        form.resetFields(["orderId", "crmsVehicleId", "brandId", "customerVehicleModel"])
    }

    //车辆列表查询
    const vehicleQuery = (value) => {
        setVehicleInfo({
            data: [],
            fetching: true,
        })
        let D = {
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                vehicleCode: value,
                customerId: customerInfo.id || null,
            },
        }
        getVehicleList(D).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.vehicleCode,
                    value: item.id,
                    ...item,
                }))
                setVehicleInfo({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning("该客户下没有车辆")
            }
        })
    }
    //防抖
    const vehicleQuerys = debounce(vehicleQuery, 600)
    //车辆选中, 带出牌照
    const handleChangevehicle = (value, option) => {
        form.setFieldsValue({
            brandId: option.info.brandId,
            customerVehicleModel: option.info.customerVehicleModel,
        })
        //置空订单
        form.resetFields(["orderId"])
    }

    //订单查询
    const orderQuery = (value) => {
        setorderInfo({
            data: [],
            fetching: true,
        })
        let D = {
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                orderCode: value,
            },
        }
        getOrderList(D).then((res) => {
            let results = res.retData.list
            const data = results.map((item) => ({
                text: item.orderCode,
                value: item.id,
                ...item,
            }))
            setorderInfo({
                data: data,
                fetching: false,
            })
        })
    }

    //防抖
    const orderQuerys = debounce(orderQuery, 600)

    //订单查询, 带出数据
    const handleorderQuery = (value, option) => {
        resetFormFromOrderId(value)
    }

    const resetFormFromOrderId = (id) => {
        getOrderOne(id).then((res) => {
            let orderDetail = res.retData
            setCustomerInfo({
                data: [
                    {
                        text: orderDetail.customerName,
                        value: orderDetail.customerId,
                    },
                ],
                fetching: false,
                id: orderDetail.customerId,
            })
            setVehicleInfo({
                data: [
                    {
                        text: orderDetail.customerVehicleCode,
                        value: orderDetail.customerVehicleId,
                    },
                ],
                fetching: false,
            })

            setorderInfo({
                data: [
                    {
                        text: orderDetail.orderCode,
                        value: orderDetail.id,
                    },
                ],
                fetching: false,
            })

            form.setFieldsValue({
                customerId: orderDetail.customerId,
                corporationName: orderDetail.repairContactName,
                corporationPhone: orderDetail.repairContactPhone,
                orderId: orderDetail.id,
                crmsVehicleId: orderDetail.customerVehicleId,
                vehicleProblem: orderDetail.faultDesc,
                brandId: GetChinese(orderDetail.customerVehicleBrand),
                customerVehicleModel: RemoveChinese(orderDetail.customerVehicleBrand),
                orderCompletedTime: orderDetail.orderCompletedTime ? moment(orderDetail.orderCompletedTime) : null,
            })
        })
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                returnVisitTime: values.returnVisitTime.format("YYYY-MM-DDTHH:mm:ss"),
                ...values,
            }
            if (mode === "edit") {
                data.id = rowInfo.id
                editReturnVisit(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                addReturnVisit(data).then((res) => {
                    //查看要关闭的是否被关闭
                    message.success("新增成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <Modal forceRender visible={visible} title="客户回访" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1260}>
            <div className="form-details-warp">
                <Form form={form} {...formItemLayout} initialValues={{ troubleshootingStatus: 0, serviceSatisfactionStatus: 0 }} disabled={isLook(mode)}>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="客户名称"
                                name="customerId"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择客户",
                                    },
                                ]}
                            >
                                <Select
                                    disabled={isLook(mode)}
                                    showSearch
                                    placeholder="请选择客户"
                                    notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => customerQuery()}
                                    onSearch={customerQuerys}
                                    onChange={handleChangeCustomer}
                                    style={{ width: "100%" }}
                                >
                                    {customerInfo.data.map((item) => (
                                        <Option value={item.value} key={item.value} info={item}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="车辆牌照"
                                name="crmsVehicleId"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择车辆牌照",
                                    },
                                ]}
                            >
                                <Select
                                    disabled={isLook(mode)}
                                    showSearch
                                    allowClear
                                    placeholder="车辆牌照"
                                    notFoundContent={vehicleInfo.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => vehicleQuery("")}
                                    onSearch={vehicleQuerys}
                                    onChange={handleChangevehicle}
                                    style={{ width: "100%" }}
                                >
                                    {vehicleInfo.data.map((item) => (
                                        <Option value={item.value} key={item.value} info={item}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆品牌" name="brandId" >
                                <Input placeholder="车辆品牌" disabled/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆型号" name="customerVehicleModel" >
                                <Input placeholder="可通过车辆选择带出" disabled/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="联系人"
                                name="corporationName"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入联系人",
                                    },
                                ]}
                            >
                                <Input placeholder="联系人" disabled={isLook(mode)} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="联系电话"
                                name="corporationPhone"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入联系电话",
                                    },
                                ]}
                            >
                                <Input placeholder="联系电话" disabled={isLook(mode)} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="职务"
                                name="corporationJobTitle"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择职务",
                                    },
                                ]}
                            >
                                <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择职务" filterOption={false}>
                                    {getPcodeDict("021").children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="回访方式"
                                name="returnVisitMethod"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择回访方式",
                                    },
                                ]}
                            >
                                <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择回访方式" filterOption={false}>
                                    {getPcodeDict("037").children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="工单编号" name="orderId">
                                <Select
                                    disabled={isLook(mode)}
                                    showSearch
                                    allowClear
                                    placeholder="工单编号"
                                    notFoundContent={orderInfo.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => orderQuery("")}
                                    onSearch={orderQuerys}
                                    onChange={handleorderQuery}
                                    style={{ width: "100%" }}
                                >
                                    {orderInfo.data.map((item) => (
                                        <Option value={item.value} key={item.value} info={item}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="维修时间" name="orderCompletedTime">
                                <DatePicker disabled={isLook(mode)} className="pub-select" format="YYYY-MM-DD HH:mm" showTime={{ format: "HH:mm" }} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="回访时间"
                                name="returnVisitTime"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择回访时间",
                                    },
                                ]}
                            >
                                <DatePicker disabled={isLook(mode)} className="pub-select" format="YYYY-MM-DD HH:mm" showTime={{ format: "HH:mm" }} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="登记时间" name="createTime">
                                <DatePicker disabled={isLook(mode)} placeholder="自动生成" className="pub-select" format="YYYY-MM-DD HH:mm" showTime={{ format: "HH:mm" }} disabled />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="车辆问题" name="vehicleProblem" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                <Input.TextArea disabled={isLook(mode)} placeholder="车辆问题" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="故障是否解决" name="troubleshootingStatus">
                                <Radio.Group disabled={isLook(mode)}>
                                    <Radio value={0}>已解决</Radio>
                                    <Radio value={1}>未解决</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item label="未解决原因" name="notTroubleshootingReason" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                <Input.TextArea disabled={isLook(mode)} placeholder="未解决原因" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="本次服务是否满意" name="serviceSatisfactionStatus">
                                <Radio.Group disabled={isLook(mode)}>
                                    <Radio value={0}>满意</Radio>
                                    <Radio value={1}>不满意</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item label="不满意的地方有哪些" name="notServiceSatisfactionReason" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                <Input.TextArea  disabled={isLook(mode)} placeholder="不满意的地方有哪些" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="整体服务意见与建议" name="serviceOpinion" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                <Input.TextArea disabled={isLook(mode)}  placeholder="整体服务意见与建议" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="对于云豪车夫APP的意见和建议" name="appOpinion" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                <Input.TextArea disabled={isLook(mode)}  placeholder="对于云豪车夫APP的意见和建议" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="其他问题意见与建议" name="otherOpinion" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
                                <Input.TextArea disabled={isLook(mode)}  placeholder="其他问题意见与建议" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item hidden>
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}
