import React, { useEffect } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { useSaleRoDetailModel } from "./saleRoDetailModel";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { formatNum, formatIntPoint } from "@/erp_subpackage/utils/util";
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export const AddGoodsModal = ({ modeKey, mode, rowId, dispatch }) => {
    const { setMaterialInfo } = useGlobalModel();
    const { initPgCfg, addModalVisible: visible, workDataSource, workPgCfg,
        detailAddForm,detailLookForm, detailEditForm, addDataSource, searchWorkData, detailAddList, detailDataSource,
        setWorkDataSource, resetWorkloadData, setSearchWorkData, setAddDataSource, transformsToPoDetailList, workloadData, setAddModalVisible, } = useSaleRoDetailModel();
    const list = mode === "edit" ? detailDataSource : detailAddList;
    const formVal = mode === "edit" ? detailEditForm : detailAddForm;

    useEffect(() => {
        if (visible && mode === "add") {
            workloadData(formVal, initPgCfg, { customerCode: detailAddForm?.customerCode })
            setSearchWorkData({ customerCode: detailAddForm?.customerCode })
        } else if (visible && mode === "edit") {
            workloadData(formVal, initPgCfg, { customerCode: detailEditForm.customerCode })
            setSearchWorkData({ customerCode: detailEditForm.customerCode })
        }
    }, [detailAddForm?.customerCode, detailEditForm.customerCode, visible])

    const detailForm= mode === "add" ? detailAddForm :  mode === "edit" ?detailEditForm:detailLookForm
    const column = (optype) => [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName,customerName:detailForm.customerName,customerCode:detailForm.customerCode })} />,
            //render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.name }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
            //render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '销售单号',
            width: 120,
            dataIndex: 'saleCode',
        }, {
            title: '销售数量',
            width: 100,
            dataIndex: 'saleNum',
        }, {
            title: '销售单价',
            width: 100,
            dataIndex: 'salePrice',
        }, {
            title: '折扣',
            width: 100,
            dataIndex: 'discountRate',
            render: (text) => text + '%'
        }, {
            title: '单项优惠金额',
            width: 100,
            dataIndex: 'decreaseAmount',
        }, {
            title: '销售金额',
            width: 100,
            dataIndex: 'saleTotal',

        }, {
            title: '退货数量',
            width: 100,
            dataIndex: 'num',
            editable: true,
        }, {
            title: '退货单价',
            width: 100,
            dataIndex: 'price',
            // editable: true,
        }, {
            title: '退货折扣',
            width: 100,
            dataIndex: 'discountRate',
            render: text => text + '%'
        }, {
            title: '退货金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '已退数量',
            width: 100,
            dataIndex: 'sreturnNum',
        }, {
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text, record) => optype === 'add' ? (
                <YhBut
                    type="add"
                    txt="添加"
                    click={() => add(record)}
                    disabled={!!addDataSource.find(item => item.id === record.id) ||
                        (record.availableStock === 0) ||
                        record.saleNum == record.sreturnNum ||
                        !!list.find(item => item.detailsId === record.id)
                    }
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => remove(record)}
                />
            )
        }
    ];
    const newSelectColumns = (optType) =>
        column(optType).map((col, index) => {
            return {
                ...col,
                onCell: (record, rowIndex) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: optType === 'add' ? notHandleSave : handleSave,
                }),
            }
        })
    const notHandleSave = (row) => {
        let { num, price, sreturnNum, saleNum, discountRate } = row
        discountRate = +formatNum(discountRate)
        saleNum = + formatNum(saleNum)
        sreturnNum = + formatNum(sreturnNum)
        price = + formatNum(price)
        num = +formatIntPoint(num, saleNum - sreturnNum, "退货数量不能大于【销售数量-已退数量】")
        let newRow = {
            ...row,
            num,
            price,
            amount: +formatNum(num * price * discountRate / 100)
        }
        const index = workDataSource.findIndex((item) => row.id === item.id);
        const item = workDataSource[index];
        workDataSource.splice(index, 1, { ...item, ...newRow });
        setWorkDataSource([...workDataSource])
    }
    const handleSave = (row) => {
        let { num, price, sreturnNum, saleNum, discountRate } = row
        discountRate = +formatNum(discountRate)
        saleNum = + formatNum(saleNum)
        sreturnNum = + formatNum(sreturnNum)
        price = + formatNum(price)
        num = +formatIntPoint(num, saleNum - sreturnNum, "退货数量不能大于【销售数量-已退数量】")
        let newRow = {
            ...row,
            num,
            price,
            amount: +formatNum(num * price * discountRate / 100)
        }
        const index = addDataSource.findIndex((item) => row.id === item.id);
        const item = addDataSource[index];
        addDataSource.splice(index, 1, { ...item, ...newRow });
        setAddDataSource([...addDataSource])
    }
    const add = async (record) => {
        setAddDataSource(origin => [...origin, record])
    }
    const remove = async (record) => {
        const filterAddDataSource = addDataSource.filter(item => item.id !== record.id)
        setAddDataSource(filterAddDataSource)
    }
    const onOK = async () => {
        transformsToPoDetailList(mode)
        onCancel()
    }
    const onCancel = () => {
        setAddModalVisible(false);
        setAddDataSource([]);
        mode === "add" ? resetWorkloadData(formVal,{ customerCode: detailAddForm.customerCode }) : resetWorkloadData(formVal,{ customerCode: detailEditForm.customerCode })

    };
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialCode: e.target.value }) }}
            onPressEnter={() => workloadData(formVal,initPgCfg)}
            placeholder="商品编号"
        />
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={searchWorkData.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchWorkData({ ...searchWorkData, materialName: e.target.value }) }}
            onPressEnter={() => workloadData(formVal,initPgCfg)}
            placeholder="商品名称"
        />
        <Button onClick={() => workloadData(formVal)} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => mode === "add" ? resetWorkloadData(formVal,{ customerCode: detailAddForm.customerCode }) : resetWorkloadData(formVal,{ customerCode: detailEditForm.customerCode })} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        onOk={onOK}
        onCancel={onCancel}
        title="销售退货单-选择商品"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ marginBottom: "15px" }}>
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id}
                columns={newSelectColumns('add')}
                dataSource={workDataSource}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={{
                    ...workPgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        workloadData(formVal,{ pageNum, pageSize });
                    }
                }}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 206 }}
            />
        </YhBox>
        <YhBox title="已选商品列表">
            <Table
                rowClassName='editable-row yh-edit-table-tr'
                rowKey={record => record.id}
                columns={newSelectColumns('remove')}
                dataSource={[...addDataSource]}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                onRow={record => ({
                    onClick: () => { }
                })}
                scroll={{ x: 'max-content', y: 206 }}
            />
        </YhBox>
    </YHModal>
}