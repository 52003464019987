import { useState, useEffect } from "react";
import { createModel } from "hox";
import { useLockFn } from "ahooks";
import { purchasePlanService } from "../../purchasePlanService"
import useGlobalModel, { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { formatInt, formatNum } from "@/erp_subpackage/utils/util";
import { message } from "antd";
import { bigNumber } from "../../../../../utils/util";

const initDesc = {
    numAll: 0,  //数量
    unarrivedNumAll: 0,  //未到货数量
    itemNumAll: 0, //项数
    totalAll: 0 //总计
}

export const usePurchasePlanDetailsModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, userName, orgName, orgCode, bentityName, bentityCode, organType } } = useGlobalModel();

    const initFormVal = {
        shopName,
        createUser: userName,
        bentityName: bentityName,
        status: true
    }

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal);
    const [editList, setEditList] = useState([]);
    const [editDesc, setEditDesc] = useState(initDesc);
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    const [addList, setAddList] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState(initFormVal);
    const [lookList, setLookList] = useState([]);
    const [lookDesc, setLookDesc] = useState(initDesc);

    //添加商品弹框
    const [materialDialog, setMaterialDialog] = useState(false);

    //单据变化、按钮可操作控制
    const [controlled, setControlled] = useState(false);

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    //切换机构需重置的缓存
    useEffect(() => {
        setAddFormVal(initFormVal)
    }, [shopId])

    useEffect(() => {
        let newDesc = { ...initDesc };
        editList.forEach(item => {
            newDesc.numAll += Number(item.planNum);
            newDesc.unarrivedNumAll += Number(item.unarrivedNum ?? 0);
            // newDesc.totalAll += Number(item.price) * Number(item.planNum);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.planNum)).toFixed(2);
        });
        newDesc.itemNumAll = editList.length;
        setEditDesc(newDesc);
    }, [editList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        addList.forEach(item => {
            newDesc.numAll += Number(item.planNum);
            // newDesc.totalAll += Number(item.price) * Number(item.planNum);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.planNum)).toFixed(2);

        });
        newDesc.itemNumAll = addList.length;
        setAddDesc(newDesc);
    }, [addList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        lookList.forEach(item => {
            newDesc.numAll += Number(item.planNum);
            // newDesc.totalAll += Number(item.price) * Number(item.planNum);
            newDesc.totalAll += +bigNumber.times(Number(item.price),Number(item.planNum)).toFixed(2);
        });
        newDesc.itemNumAll = lookList.length;
        setLookDesc(newDesc);
    }, [lookList])

    //表单修改
    const updateFormVal = (mode, obj) => {
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "look" ? setLookFormVal : setAddFormVal;
        setFormVal(origin => obj ? { ...origin, ...obj } : initFormVal);
    }

    //商品子表修改
    const updateList = (mode, arr = []) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        setList([...arr]);
        if (mode === "edit") { setControlled(true) }
    };

    //获取一条数据
    const getPurchasePlanOne = async (id, mode) => {
        //debugger
        let { retData } = await purchasePlanService.one(id);
        const { details, ...from } = retData;
        updateFormVal(mode, from ?? {});
        updateList(mode, details ?? []);
        setControlled(false)
        return retData;
    }

    //校验商品信息的仓库和货位是否选中
    const verifyWhOrShelf = (list) => {
        if (!list || !list.length) {
            message.warning("请选择商品");
            return false;
        }
        let filterArr = list.filter(item => !item.warehouseCode || !item.shelfCode);
        if (filterArr.length) {
            message.warning(`【${filterArr[0].materialName}】请选择仓库和货位`);
            return false;
        }
        return true;
    }

    //新增采购单
    const insertDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        // if (!verifyWhOrShelf(addList)) return false;
        if(addList.length==0){
            message.warning(`未添加商品明细不可生成采购计划单`)
            return
        } 
        let newParams = {
            ...params,
            details: addList,
            // bentityName, bentityCode,
            createUser: userName,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        let { retData } = await purchasePlanService.insert(newParams);
        setSaveBtnLoading(false)
        message.success("新增成功")
        return retData;
    })

    //编辑采购单
    const editDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        // if (!verifyWhOrShelf(editList)) return false;
        if(editList.length==0){
            message.warning(`未添加商品明细不可生成采购计划单`)
            return
        } 
        let newParams = {
            ...params,
            id: editFormVal.id,
            // bentityName, bentityCode,
            details: editList,
            updateUser: userName,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        let { retData } = await purchasePlanService.update(newParams);
        setSaveBtnLoading(false)
        message.success("编辑成功")
        return retData;
    })

    //商品子表添加
    const insertMaterial = (mode, materialArr) => {
        const setList = mode === 'edit' ? setEditList : setAddList;
        const repetition = [];
        setList((origin) => {
            const newArr = [...origin];
            materialArr.forEach((material) => {
                //选中的是否存在列表
                let isExist = newArr.find((item) => item.materialCode === (material.code ?? material.materialCode));
                if (!isExist) {
                    let num = +formatInt(material.num);
                    let price = +formatNum(material.purchasePrice ?? material.price);
                    newArr.push({
                        ...material,
                        arrivedTotal: 0,  //到货数量
                        unarrivedNum: 0,  //未到货数量
                        planNum: num,     //采购数量
                        num: 0,           //到货数量
                        minNum: material.minOrderNum,
                        materialCode: material.code ?? material.materialCode,
                        materialName: material.name ?? material.materialName,
                        price,
                        id: "",
                        note: ""
                    });
                } else {
                    repetition.push(material.name ?? material.materialName);
                }
            });
            return newArr;
        });
        setMaterialDialog(false);
        !!repetition.length && message.warning(`商品名称：【${repetition.join(",")}】，重复的将不在添加。`);
        if (mode === "edit") { setControlled(true) }
    };

    //商品子表删除、编辑模式下，已有id的需要进行数据库删除 (单条及批量删除)
    const removeMaterial = useLockFn(async (mode, selectedRowKeys) => {
        if (mode === 'edit') {
            let ids = editList.filter(item => selectedRowKeys.indexOf(item.materialCode ?? '') >= 0 && item.id).map(item => ({ id: item.id }));
            //存在id在跑接口进行数据库删除
            if (ids.length) {
                let { retData } = await purchasePlanService.materialDelete({ ids, planCode: editFormVal.planCode });
                if (!retData) return retData;
            }
        }
        const setList = mode === 'edit' ? setEditList : setAddList;
        setList(origin => origin.filter((item) => selectedRowKeys.indexOf(item?.materialCode ?? '') < 0));
        message.success("删除成功");
        if (mode === "edit") { setControlled(true) }
    });

    //下载模板
    const downloadTpl = useLockFn(async () => {
        let result = await purchasePlanService.downloadTpl();
        convertRes2Blob(result)
    })

    //转采购单
    const TransferPo = useLockFn(async () => {
        //校验采购数量不等于已到货数量 并且 没有输入到货数量
        // let tempArr = editList.filter(item => +item.planNum !== +item.arrivedTotal && !item.num);
        // if (tempArr.length) {
        //     message.warning(`请输入【${tempArr[0].materialName}】商品的到货数量`);
        //     return;
        // }

        //总到货数量大于0可以多次转接
        if (editList.reduce((c, n) => { c = c + (n.num ?? 0); return c }, 0) === 0) {
            message.warning(`请输入商品的到货数量`);
            return;
        }
        let { retData } = await purchasePlanService.transfer(editFormVal.planCode);
        message.success("转接成功")
        return retData
    })

    return {
        controlled, organType,
        editFormVal,
        editList,
        addFormVal,
        addList,
        lookFormVal,
        lookList,
        addDesc, editDesc, lookDesc,
        materialDialog, setMaterialDialog,
        updateFormVal,
        updateList,
        getPurchasePlanOne,
        insertMaterial, removeMaterial,
        insertDetail,
        editDetail,
        downloadTpl,
        TransferPo,setSaveBtnLoading,saveBtnLoading
    }
})