import { getUserOrgan } from '@/utils/dataStorage'

/**
 * 判断是否是加盟商
 */
export const IsFranchisees = () => {
    /**
     * 机构性质:1-自营 2-合伙 3-加盟
     */
    const { organNature } = getUserOrgan()
    return organNature === 3
}
