import React, { useState, useEffect } from "react"
import { Input, Button, Table, message } from "antd"
import { getItemList } from "@/service/maintenanceService"
import PubDrawer from "@/components/Drawer"

export default ({ visible, onClose, onItemOk }) => {
    //检索条件
    const [searchName, setSearchName] = useState({
        itemCode: "",
        itemName: "",
    })
    const [selectMaintenanceItem, setSelectMaintenanceItem] = useState([]) //已经的维修项目
    const [addData, setAddData] = useState([]) //项目新增的表格数据

    useEffect(() => {
        if (!visible) {
            setSelectMaintenanceItem([])
            setAddData([])
        }
    }, [visible])
    //项目 新增数据列表
    const loadData = (page, search = {}) => {
        let data = {
            pageSize: 10,
            pageNum: page,
            searchItem: {
                itemCode: search.itemCode || null,
                itemName: search.itemName || null,
            },
        }
        getItemList(data).then((res) => {
            // if (res && res.retCode === 200) {
                let results = res.retData
                setAddData(results.list)
            // }
        })
    }
    //搜索
    const handleSearch = () => {
        loadData(1, searchName)
    }

    //新增多选
    const rowSelection = {
        columnTitle: " ", //去掉多选
        selectedRowKeys: selectMaintenanceItem.reduce((c, n) => {
            c.push(n.id)
            return c
        }, []), //是否选中
        onSelect: (record, selected, selectedRows) => {
            //当为选中的时候
            if (selected) {
                let arr = [...selectMaintenanceItem, ...selectedRows]
                let obj = {}
                let newArr = arr.reduce((cur, next) => {
                    if (next) {
                        obj[next.id] ? (obj[next.id] = "") : (obj[next.id] = true && cur.push(next))
                    }
                    return cur
                }, [])
                setSelectMaintenanceItem(newArr)
            } else {
                deleteItem(record.id)
            }
        },
    }
    //不为选中或者删除的时候
    const deleteItem = (id) => {
        let arr = [...selectMaintenanceItem]
        let newArr = arr.filter((item) => item.id !== id)
        setSelectMaintenanceItem(newArr)
    }

    //新增项目的table配置
    const addColumns = [
        {
            title: "项目编码",
            align: "center",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            align: "center",
            dataIndex: "itemName",
        },
        {
            title: "工时",
            align: "center",
            dataIndex: "itemWorkHours",
        },
        {
            title: "单价",
            align: "center",
            dataIndex: "workHoursPrice",
        },
        {
            title: "维修项目金额",
            align: "center",
            dataIndex: "itemAmount",
        },
    ]
    return (
        <PubDrawer visible={visible} onClose={onClose}>
            <div className="team-drawer-title">
                <span>数据采集项目选择</span>
            </div>
            <div className="team-drawer-content">
                <div className="drawer-search-box">
                    <span>项目编码:</span>
                    <Input
                        className="search-input1"
                        allowClear
                        placeholder="请输入项目编码"
                        onChange={(e) =>
                            setSearchName({
                                ...searchName,
                                itemCode: e.target.value,
                            })
                        }
                    />
                    <span>项目名称:</span>
                    <Input
                        className="search-input1"
                        allowClear
                        placeholder="请输入项目名称"
                        onChange={(e) =>
                            setSearchName({
                                ...searchName,
                                itemName: e.target.value,
                            })
                        }
                    />
                    <Button className="search-but1" onClick={handleSearch}>
                        查询
                    </Button>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={addColumns}
                    dataSource={addData}
                    rowSelection={rowSelection}
                    pagination={false}
                    scroll={{ y: 360 }}
                />
                <div className="team-form-footer">
                    <Button onClick={onClose} className="team-but-one">
                        取消
                    </Button>
                    <Button className="team-but-two" onClick={() => onItemOk(selectMaintenanceItem)}>
                        提交
                    </Button>
                </div>

                <div className="select-item-box">
                    <h4>已添加项目</h4>
                    <ul>
                        {selectMaintenanceItem &&
                            selectMaintenanceItem.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <span>{item.itemName}</span>
                                        <span onClick={() => deleteItem(item.id)}>删除</span>
                                    </li>
                                )
                            })}
                    </ul>
                </div>
            </div>
        </PubDrawer>
    )
}
