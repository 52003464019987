import React, { useContext, useState, useEffect, useRef } from 'react'
import ListComponent from '@/components/listComponent'
import TopSearch from '@/components/TopSearch'
import { getVehicleList2, deleteVehicleOne, vehicleEditBigBag } from '@/service/customerService'
import { MyContext } from '@/store/myContext'
import { Modal, Select } from 'antd'
import { organPage } from '@/service/orgService'
import { download, convertRes2Blob } from '@/utils/exportExcel'
import Loading from '@/components/Loading'
import { getPcodeDict } from '@/utils/dataStorage'
import YhTooltip from '@/components/YhTooltip'
import VehiclePopupDetail from './vehiclePopupDetail'
import BigFlagPlant from './bigFlagPlant'
import MoveVehicle from './moveVehicle'
import { vehicleDomestic } from '@/utils/enum'

const { Option } = Select

const VehicleRecord = () => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const [visible, setVisible] = useState(false)
    const [mode, setMode] = useState('')
    const { state, message } = useContext(MyContext)
    const [vehicleData, setVehicleData] = useState(null) //列表
    const order = useRef({ column: 'updateTime', rule: 'DESC' })
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState({}) //搜索
    //机构列表
    const [org, setOrg] = useState([])

    const [powerSourceType, setPowerSourceType] = useState({})

    const [customerData, setCustomerData] = useState({})
    const [moveVehicleData, setMoveVehicleData] = useState({})
    const [moveVehicleVisible, setMoveVehicleVisible] = useState(false)
    useEffect(() => {
        let obj = {}
        getPcodeDict('022007').children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setPowerSourceType(obj)
    }, [])

    useEffect(() => {
        if (state.activeMenu === 'vehicleRecord') {
            getOrganPage()
            loadData(pagination.current, { ...searchValue })
        }
    }, [state])

    useEffect(() => {
        loadData(pagination.current, { ...searchValue })
    }, [searchValue])

    //获取机构列表
    const getOrganPage = () => {
        organPage({
            searchItem: { orderFlag: 1 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }

    //列表加载
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            pageNum: current,
            pageSize: 10,
            searchItem: {
                ...search,
            },
            orders: [order.current],
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
        }
        getVehicleList2(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setLoading(false)
                setVehicleData(res.retData.list.map((item) => ({ ...item.basicInfo, id: item.vehicleId })))
            })
            .catch((err) => {
                setLoading(false)
                setVehicleData([])
            })
    }

    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let orderC = { column: 'updateTime', rule: 'DESC' }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === 'ascend' ? 'ASC' : 'DESC'
            orderC = {
                column: sorter.field,
                rule,
            }
        }
        order.current = orderC
        loadData(page.current, searchValue)
    }
    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要删除的车辆信息')
            return
        }
        Modal.confirm({
            content: `确定要删除车辆 - ${selectInfo.rowInfo.vehicleCode}?`,
            okText: '确定',
            cancelText: '取消',
            centered: true,
            onOk() {
                deleteVehicleOne(selectInfo.rowInfo.id).then((res) => {
                    // if (res && res.retCode === 200) {
                    message.success('删除成功')
                    loadData(pagination.current)
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === 'edit') {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning('请选择你要编辑的车辆信息')
                return
            }
        } else if (mode === 'look') {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning('请选择你要查看的车辆信息')
                return
            }
        }
        setMode(mode)
        setVisible(true)
    }

    //设为大包
    // const setBigFlag = () => {
    //     if (!Object.keys(selectInfo.rowInfo).length) {
    //         message.warning("请选择你要设置的车辆")
    //         return
    //     }
    //     vehicleEditBigBag({
    //         id: selectInfo.rowInfo.id,
    //         bigBagFlag: 1,
    //     }).then((res) => {
    //         loadData(pagination.current, { ...searchValue })
    //     })
    // }

    const [bigFlagPlantVisible, setBigFlagPlantVisible] = useState(false)

    const setBigFlagPlant = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要操作的车辆')
            return
        }
        if (selectInfo.rowInfo.bigBagFlag === 0) {
            message.warning('车辆还没有添加大包标记')
            return
        }
        setBigFlagPlantVisible(true)
    }

    //table配置
    const columns = [
        {
            title: '客户名称',
            align: 'center',
            dataIndex: 'customerName',
            width: 220,
            render: (text) => <YhTooltip maxWidth={220} text={text} />,
            sorter: true,
        },
        {
            title: '车辆牌照',
            align: 'center',
            dataIndex: 'vehicleCode',
            render: (text) => <YhTooltip text={text} />,
            sorter: true,
        },
        {
            title: '是否大包',
            align: 'center',
            dataIndex: 'bigBagFlag',
            render: (text) => <>{text === 1 ? '是' : '否'}</>,
        },
        {
            title: '所属机构',
            align: 'center',
            dataIndex: 'organName',
            render: (text) => <YhTooltip maxWidth={220} text={text} />,
        },
        {
            title: '云小牛设备编码',
            align: 'center',
            dataIndex: 'yxnEquipmentCode',
            render: (text) => <YhTooltip maxWidth={220} text={text} />,
        },
        {
            title: '车辆属性',
            align: 'center',
            dataIndex: 'domestic',
            render: (text) => <YhTooltip maxWidth={220} text={vehicleDomestic[text]} />,
        },
        {
            title: '车辆品牌',
            align: 'center',
            dataIndex: 'brandId',
            render: (text) => <YhTooltip maxWidth={220} text={text} />,
        },
        {
            title: '车型',
            align: 'center',
            dataIndex: 'vehicleModelId',
            render: (text) => <YhTooltip maxWidth={220} text={text} />,
        },
        {
            title: 'Vin码',
            align: 'center',
            dataIndex: 'vehicleVin',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: '发动机号',
            align: 'center',
            dataIndex: 'engineCode',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: '动力来源',
            align: 'center',
            dataIndex: 'powerSource',
            render: (text) => <YhTooltip text={powerSourceType[text]} />,
        },
        {
            title: '注册日期',
            align: 'center',
            dataIndex: 'drivingSignDate',
        },
        {
            title: '创建人',
            align: 'center',
            dataIndex: 'createUserName',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: '登记日期',
            align: 'center',
            dataIndex: 'createTime',
            sorter: true,
        },
        {
            title: '操作',
            align: 'center',
            render: (text, r) => (
                <span
                    style={{ color: '#2e3aeb', cursor: 'pointer', marginLeft: '10px' }}
                    onClick={() => {
                        const { customerId, customerName } = r
                        setCustomerData({ id: customerId, name: customerName })
                        setMoveVehicleData([r])
                        setMoveVehicleVisible(true)
                    }}
                >
                    车辆转移
                </span>
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'input',
                placeholder: '请输入客户名称',
                valueName: 'customerName',
            },
            {
                type: 'input',
                placeholder: '请输入车辆牌照',
                valueName: 'vehicleCode',
            },
            {
                type: 'input',
                placeholder: '请输入车辆品牌',
                valueName: 'brandId',
            },
            {
                type: 'input',
                placeholder: '请输入vin码',
                valueName: 'vehicleVin',
            },
            {
                type: 'select',
                placeholder: '请选择所属机构',
                valueName: 'organId',
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择动力来源',
                valueName: 'powerSource',
                optionList: () =>
                    getPcodeDict('022007').children.map((item) => (
                        <Option key={item.id} value={item.dictCode}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择是否大包',
                valueName: 'bigBagFlag',
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            否
                        </Option>
                        <Option value={1} key={1}>
                            是
                        </Option>
                    </>
                ),
            },
            {
                type: 'select',
                placeholder: '请选择车辆属性',
                valueName: 'domestic',
                optionList: () =>
                    Object.entries(vehicleDomestic).map((i) => (
                        <Option value={i[0]} key={i[0]}>
                            {i[1]}
                        </Option>
                    )),
            },
            {
                type: 'input',
                placeholder: '请输入云小牛编码',
                valueName: 'yxnCode',
            },
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                span: 16,
                xxl: 8,
            },
        ],
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails('insert'),
            content: '新增',
        },
        {
            click: () => handleDetails('look'),
            content: '查看',
        },
        {
            click: () => handleDetails('edit'),
            content: '编辑',
        },
        {
            click: () => handleDelete(),
            content: '删除',
        },
        {
            click: () => exportVehicle(),
            content: '导出',
        },
        // {
        //     click: () => setBigFlag(),
        //     content: "设为大包",
        // },
        {
            click: () => setBigFlagPlant(),
            content: '大包计划',
        },
        {
            click: () => setMoveVehicleVisible(true),
            content: '车辆转移',
        },
    ]

    const exportVehicle = async () => {
        setExportLoading(true)
        let data = {
            type: 'post',
            data: {
                pageSize: pagination.pageSize,
                pageNum: pagination.current,
                startTime: searchValue.startTime ? searchValue.startTime : null,
                endTime: searchValue.endTime ? searchValue.endTime : null,
                searchItem: {
                    ...searchValue,
                },
                orders: [order.current],
            },
        }
        let resFlie = await download('/ci-customer/customer/vehicle/export', data)
        if (resFlie && resFlie.status === 200) {
            message.success('已导出')
            convertRes2Blob(resFlie)
        } else {
            message.warning('导出异常，请重新尝试')
        }
        setExportLoading(false)
    }
    return (
        <>
            {state.activeMenu === 'vehicleRecord' && (
                <>
                    <TopSearch
                        title="车辆管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        defaultSearch={{ customerId: null }}
                    />
                    <ListComponent
                        title="车辆列表"
                        buttons={topButtons}
                        loading={loading}
                        columns={columns}
                        dataSource={vehicleData}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                    {exportLoading && <Loading />}
                    {visible && (
                        <VehiclePopupDetail
                            visible={visible}
                            setVisible={setVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                                setVisible(false)
                            }}
                            mode={mode}
                            rowInfo={selectInfo.rowInfo}
                        />
                    )}
                    {bigFlagPlantVisible && (
                        <BigFlagPlant
                            vehicleId={selectInfo.rowInfo.id}
                            // vehicleId={"0e72bdf16cbe5f5e2aa1fd9b829b8abe"}
                            onSuccess={() => {
                                loadData(1, searchValue)
                                setBigFlagPlantVisible(false)
                            }}
                            visible={bigFlagPlantVisible}
                            setVisible={setBigFlagPlantVisible}
                        />
                    )}
                    <MoveVehicle
                        visible={moveVehicleVisible}
                        setVisible={setMoveVehicleVisible}
                        customerData={customerData}
                        vehicleData={moveVehicleData}
                        onSuccess={() => {
                            loadData(1)
                        }}
                    />
                </>
            )}
        </>
    )
}
export default VehicleRecord
