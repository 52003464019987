import React, { useState, useEffect } from "react";
import { Table, Button, Input, Tooltip, message } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { EditableCell, EditableRow } from "@/erp_subpackage/components/YHEditTable/editTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useGlobalModel, transPgToPagination, defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { formatInt, formatNum } from "@/erp_subpackage/utils/util";
import { usePurchaseRoDetailsModel } from './purchaseRoDetailModel';
import { purchaseRoService } from "../../purchaseRoService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

const initPgCfg = { pageSize: 10, current: 1 };

export const MaterialDialog = ({ modeKey, mode, rowCode, dispatch }) => {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, orgName, orgCode }, whDs, setMaterialInfo } = useGlobalModel(({ user }) => [user]);
    const { materialDialog: visible, setMaterialDialog, insertMaterial, addFormVal, editFormVal, addList, editList } = usePurchaseRoDetailsModel(({ materialDialog }) => [materialDialog]);
    const formVal = mode === "edit" ? editFormVal : addFormVal;
    const list = mode === "edit" ? editList : addList;
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [selectMaterial, setSelectMaterial] = useState([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [loading, setLoadding] = useState(false);

    useEffect(() => {
        if (visible) {
            loadData()
        } else {
            setSelectMaterial([]);
        };
    }, [visible])

    const onCancel = () => {
        setMaterialDialog(false);
    };

    //加载商品数据
    const loadData = async (page, searchValue = searchParam) => {
        setLoadding(true);
        try {
            const param = { ...searchParam, materialCode: searchParam.materialCode?.trim(), materialName: searchParam.materialName?.trim() }
            setSearchParam(param) 
            const {documentTypeCode, bentityCode, supplierCode} = formVal
            const searchParams = {
                ...param,
                shopId, shopName, shopCode, sourceApp, branch,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            }
            if(documentTypeCode === 'preturn-type.general'){//单据类型是普通要加上实体和供应商
                searchParams.supplierCode = supplierCode
                searchParams.bentityCode = bentityCode
            }
            let { retData } = await purchaseRoService.details(searchParams);
            const { records, ...pg } = retData;
            setDataSource(records.map(item => ({
                ...item,
                returnNum: 1,
                preturnPrice: item.price,
                amount: Number(item.price) * 1,
            })));
            setPgCfg(transPgToPagination(pg));
            setLoadding(false);
        } catch (err) { setLoadding(false) }
    }

    const column = (optype) => [
        {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            align: "left",
            fixed: "left",
            // render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 120,
            fixed: "left",
            dataIndex: 'materialName',
            align: "left",
            render: (text) => <YhTooltip text={text} />,
           // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text} {record.icon === 1 && <span style={{ background: "orange", color: "white" }}>多</span>} </p></Tooltip>,
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '采购单号',
            width: 120,
            dataIndex: 'purchaseCode',
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '可用库存',
            width: 100,
            dataIndex: 'availableStock',
            align: "left",
        }, {
            title: '采购数量',
            width: 100,
            dataIndex: 'num',
            align: "left",
        }, {
            title: '已退数量',
            width: 100,
            dataIndex: 'preturnNum',
        }, {
            title: '退货数量',
            width: 100,
            dataIndex: 'returnNum',
            editable: true,
        }, {
            title: '退货单价',
            width: 100,
            dataIndex: 'preturnPrice',
            align: "left",
            editable: true,
        }, {
            title: '金额',
            width: 100,
            dataIndex: 'amount',
            align: "left",
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
            align: "left",
        }, {
            title: '货位',
            width: 100,
            dataIndex: 'shelfCode',
            align: "left",
        }, {
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text, record) => optype === "add" ? (
                <YhBut
                    type="add"
                    txt="添加"
                    disabled={!!selectMaterial.find(item => item.id === record.id) || !!list.find(item => item.detailsId === record.id) || record.availableStock == 0}
                    click={() => {
                        //添加商品
                        setSelectMaterial(origin => [...origin, record])
                    }}
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => {
                        //删除对应商品
                        setSelectMaterial(origin => origin.filter(item => item.id !== record.id))
                    }}
                />
            )
        }
    ];

    const newSelectColumns = (type) => column(type).map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index,
                rowIndex,
                record,
                rules: col.rules,
                options: col.options,
                editType: col.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: (row) => handleSave(row, type),
            }),
        }
    })

    //内联编辑
    const handleSave = (row, type) => {
        row.returnNum = +formatNum(row.returnNum, (+row.num - +row.preturnNum), "退货数量>=采购数量-已退数量");
        row.price = +formatNum(row.price);
        let preturnPrice = +formatNum(row.preturnPrice);
        if (preturnPrice > row.price) {
            message.warning("退货价格不能高于采购价格");
            row.preturnPrice = row.price;
        } else {
            row.preturnPrice = preturnPrice;
        }
        //退货金额 = 退货数量 * 单价
        row.amount = +formatNum(row.returnNum * row.preturnPrice);

        (type === "add" ? setDataSource : setSelectMaterial)(origin => {
            const newDataSource = [...origin];
            const index = newDataSource.findIndex((item) => row.id === item.id);
            const item = newDataSource[index];
            newDataSource.splice(index, 1, { ...item, ...row });
            return newDataSource;
        });
    }

    const rightChildren = <div className="flex">
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, materialCode: e.target.value }) }}
            placeholder="商品编号"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, materialName: e.target.value }) }}
            placeholder="商品名称"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Button onClick={() => loadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => {
            loadData(initPgCfg, {});
            setSearchParam({})
        }} shape="round">重置</Button>
    </div>

    return <YHModal
        visible={visible}
        okText="确认添加"
        onOk={() => insertMaterial(mode, selectMaterial)}
        onCancel={onCancel}
        title="采购退货--选择商品"
        width={1000}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ margin: "0" }}>
            <Table
                loading={loading}
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns("add")}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content', y: 210 }}
            />
        </YhBox>
        <YhBox title="已选商品列表" style={{ margin: "5px 0 0" }}>
            <Table
                rowKey={record => record.id}
                rowClassName='editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns("delete")}
                dataSource={selectMaterial}
                pagination={false}
                scroll={{ x: 'max-content', y: 220 }}
            />
        </YhBox>
    </YHModal>
}