import {
    post,
    get
} from "../utils/request"

/**
 * 品牌管理
 */
//列表
export const vehicleBrandList = (data) => {
    return post(`/ci-platform/vehicle_brand/page`, data)
}

//禁用
export const vehicleBrandDisable = (data) => {
    return post(`/ci-platform/vehicle_brand/disable`, data)
}

//获取一条数据
export const vehicleBrandOne = (id) => {
    return get(`/ci-platform/vehicle_brand/one/${id}`)
}

//新增
export const vehicleBrandInsert = (data) => {
    return post(`/ci-platform/vehicle_brand/insert`, data)
}

//编辑
export const vehicleBrandUpdate = (data) => {
    return post(`/ci-platform/vehicle_brand/update`, data)
}

//删除
export const vehicleBrandDelete = (id) => {
    return get(`/ci-platform/vehicle_brand/delete/${id}`)
}