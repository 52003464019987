import {
    httpRequest
} from "@/erp_subpackage/utils/HttpRequest";

class ContractService {
    //合约管理分页查询
    async page(searchVo){
        const apiUrl = "ci-customer/sign_info/signInfoList";
        return await httpRequest.post(apiUrl,searchVo)
    }
    //新增
    async insert(searchVo){
        const apiUrl = "ci-customer/sign_info/insert";
        return await httpRequest.post(apiUrl,searchVo)
    }
     //修改
     async update(searchVo){
        const apiUrl = "ci-customer/sign_info/update";
        return await httpRequest.post(apiUrl,searchVo)
    }
    //单条查询
    async one(id){
        const apiUrl = `ci-customer/sign_info/one/${id}`;
        return await httpRequest.get(apiUrl)
    }
    //根据车辆id获取数据
    async vehicleOne(vehicleId){
        const apiUrl = `ci-customer/sign_info/one/vehicle/${vehicleId}`;
        return await httpRequest.get(apiUrl)
    }
    //和月对账单明细查询
    async conpage(searchVo){
        const apiUrl = "/ci-customer/sign_bill/page";
        return await httpRequest.post(apiUrl,searchVo)
    }
    //和月对账单明细查询2
    async conpageT(searchVo){
        const apiUrl = "/ci-customer/sign_bill/orderList";
        return await httpRequest.post(apiUrl,searchVo)
    }
    //统计数据
    async total(searchVo){
        const apiUrl = "ci-customer/sign_bill/total";
        return await httpRequest.post(apiUrl,searchVo)
    }
    //统计明细数据
    async totalDetails(searchVo){
        const apiUrl = "ci-customer/sign_bill/orderTotal";
        return await httpRequest.post(apiUrl,searchVo)
    }

    //合约导出
    async export(obj) {
        const apiUrl = `/ci-analyze/sign/info/export`;
        return await httpRequest.postFile(apiUrl,obj);
    }
    //对账单导出(非里程)
    async exportOrder(obj) {
        const apiUrl = `/ci-analyze/sign/bill/export`;
        return await httpRequest.postFile(apiUrl,obj);
    }
     //对账单导出(里程)
     async exportmeilage(obj) {
        const apiUrl = `/ci-analyze/sign/bill/mileage/export`;
        return await httpRequest.postFile(apiUrl,obj);
    }
     //工单信息
     async exportWorker(obj) {
        const apiUrl = `/ci-analyze/sign/bill/mileage/order/export`;
        return await httpRequest.postFile(apiUrl,obj);
    }
    //
    async billOne(id){
        const apiUrl = `ci-customer/sign_bill/one/${id}`;
        return await httpRequest.get(apiUrl)
    }

     //下载模板
     async downloadTpl() {
        const appPageUrl = "/ci-customer/sign_info/detail/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
     //客户车辆列表
     async custmerVechiel(customerId){
        const apiUrl = `ci-customer/sign_info/vehicleList/${customerId}`;
        return await httpRequest.get(apiUrl)
    }
     //客户车辆是否可以删除
     async deleteVechiel(vehicleId){
        const apiUrl = `/ci-customer/sign_info/deleteVehicleFlag/${vehicleId}`;
        return await httpRequest.get(apiUrl)
    }
    //操作日志
    async mileageLog(searchVo){
        const apiUrl = "/ci-customer/sign_vehicle_mileage/log";
        return await httpRequest.post(apiUrl,searchVo)
    }

    //里程对账单-工单列表
    async workOrder(searchVo){
        const apiUrl = "ci-customer/sign_bill_mileage/orderList";
        return await httpRequest.post(apiUrl,searchVo)
    }

    //里程对账单-工单列表统计
    async workOrderTotal(searchVo){
        const apiUrl = "/ci-customer/sign_bill_mileage/orderTotal";
        return await httpRequest.post(apiUrl,searchVo)
    }

    //里程流水 新增
    async mileageaddone(searchVo){
        const apiUrl = "ci-customer/sign_vehicle_mileage/insert";
        return await httpRequest.post(apiUrl,searchVo)
    }

    //里程流水 批量新增
    async mileageadd(searchVo){
        const apiUrl = "/ci-customer/sign_vehicle_mileage/insertBatch";
        return await httpRequest.post(apiUrl,searchVo)
    }

    //里程对账单信息
     async mileageorder(searchVo){
        const apiUrl = "/ci-customer/sign_bill_mileage/page";
        return await httpRequest.post(apiUrl,searchVo)
    }

     //里程对账单信息统计
     async mileagetotal(searchVo){
        const apiUrl = "/ci-customer/sign_bill_mileage/total";
        return await httpRequest.post(apiUrl,searchVo)
    }

    //手动生成对账单
   
    async createBill(searchVo){
        const apiUrl = "/ci-customer/sign_bill_mileage/createBill";
        return await httpRequest.post(apiUrl,searchVo)
    }
    async createBilladd(searchVo){
        const apiUrl = "/ci-customer/sign_bill_mileage/createBillBatch";
        return await httpRequest.post(apiUrl,searchVo)
    }



}

export const contractService = new ContractService