import { useState, useEffect, useDebugValue } from "react";
import { createModel } from "hox";
import { useLockFn } from "ahooks";
import { stockCheckService } from "../../stockCheckService"
import useGlobalModel, { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { formatInt, formatNum, momentFormat } from "@/erp_subpackage/utils/util";
import { message } from "antd";
import moment from "moment";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useStockCheckModel } from "../../stockCheckModel";
import { bigNumber } from "../../../../../utils/util";

const initDesc = {
    stockAll: 0,   //库存数量
    checkAll: 0,   //盘点数量
    SurplusNumAll: 0,   //盘盈项数
    deficitNumAll: 0,   //盘亏项数
    differenceNumAll: 0,   //差异数量
    differenceMoneyAll: 0,   //差异金额
    differenceRateAll: 0,   //差异率
    differenceRateNumAll: 0, //数量差异率
    totalAll: 0, //系统库存总金额
}
export const useStockCheckDetailsModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, userName, orgName, orgCode } } = useGlobalModel();
    const { formVal } = useStockCheckModel();
    const initFormVal = {
        shopName,
        createUser: userName,
        status: true
    }
    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState(initFormVal);
    const [editList, setEditList] = useState([]);
    const [editDesc, setEditDesc] = useState(initDesc);
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    const [addList, setAddList] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState(initFormVal);
    const [lookList, setLookList] = useState([]);
    const [lookDesc, setLookDesc] = useState(initDesc);

    //添加商品弹框
    const [materialDialog, setMaterialDialog] = useState(false);

    //单据变化、按钮可操作控制
    const [controlled, setControlled] = useState(false);

    //出入库记录弹框
    const [stockRecordDialog, setStockRecordDialog] = useState({ visible: false, materialCode: "" });

    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮

    //数量差异率=差异件号个数/库存件号个数*100%
    //金额差异率=（盘点总金额-系统总金额）/系统总金额*100%
    useEffect(() => {
        let newDesc = { ...initDesc };
        editList.forEach(item => {
            newDesc.stockAll += Number(item.inventoryNum);
            newDesc.checkAll += Number(item.realNum);
            newDesc.SurplusNumAll += item.result === "盘盈" ? 1 : 0;
            newDesc.deficitNumAll += item.result === "盘亏" ? -1 : 0;
            newDesc.differenceNumAll += Number(item.differenceNum);
            newDesc.differenceMoneyAll += Number(item.differenceAmount);
            // newDesc.totalAll += Number(item.inventoryNum) * Number(item.averagePurchasePrice);
            newDesc.totalAll += +bigNumber.times(Number(item.inventoryNum),Number(item.averagePurchasePrice)).toFixed(2);
        });
        newDesc.differenceRateAll = (newDesc.differenceMoneyAll / newDesc.totalAll) * 100;
        newDesc.differenceRateNumAll = (newDesc.differenceNumAll / newDesc.stockAll) * 100;
        setEditDesc(newDesc);
    }, [editList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        addList.forEach(item => {
            newDesc.stockAll += Number(item.inventoryNum);
            newDesc.checkAll += Number(item.realNum);
            newDesc.SurplusNumAll += item.result === "盘盈" ? 1 : 0;
            newDesc.deficitNumAll += item.result === "盘亏" ? -1 : 0;
            newDesc.differenceNumAll += Number(item.differenceNum);
            newDesc.differenceMoneyAll += Number(item.differenceAmount);
            // newDesc.totalAll += Number(item.inventoryNum) * Number(item.averagePurchasePrice);
            newDesc.totalAll += +bigNumber.times(Number(item.inventoryNum),Number(item.averagePurchasePrice)).toFixed(2);
        });
        newDesc.differenceRateAll = (newDesc.differenceMoneyAll / newDesc.totalAll) * 100;
        newDesc.differenceRateNumAll = (newDesc.differenceNumAll / newDesc.stockAll) * 100;
        setAddDesc(newDesc);
    }, [addList])

    useEffect(() => {
        let newDesc = { ...initDesc };
        lookList.forEach(item => {
            newDesc.stockAll += Number(item.inventoryNum);
            newDesc.checkAll += Number(item.realNum);
            newDesc.SurplusNumAll += item.result === "盘盈" ? 1 : 0;
            newDesc.deficitNumAll += item.result === "盘亏" ? -1 : 0;
            newDesc.differenceNumAll += Number(item.differenceNum);
            newDesc.differenceMoneyAll += Number(item.differenceAmount);
            // newDesc.totalAll += Number(item.inventoryNum) * Number(item.averagePurchasePrice);
            newDesc.totalAll += +bigNumber.times(Number(item.inventoryNum),Number(item.averagePurchasePrice)).toFixed(2);

        });
        newDesc.differenceRateAll = (newDesc.differenceMoneyAll / newDesc.totalAll) * 100;
        newDesc.differenceRateNumAll = (newDesc.differenceNumAll / newDesc.stockAll) * 100;
        setLookDesc(newDesc);
    }, [lookList])

    const getResult = (differ) => {
        if (differ === 0) return "相符";
        if (differ > 0) return "盘盈";
        if (differ < 0) return "盘亏";
    }


    //表单修改
    const updateFormVal = (mode, obj) => {
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "look" ? setLookFormVal : setAddFormVal;
        // setFormVal(origin => obj ? { ...origin, ...obj } : initFormVal);
        setFormVal(origin => {
            let tempObj = obj ? { ...origin, ...obj } : initFormVal;
            //处理盘点时长
            if (tempObj.startTime && tempObj.endTime) {
                tempObj.duration = moment.duration(tempObj.endTime.diff(tempObj.startTime)).days() + "天"
            }else{
                tempObj.duration = undefined;
            }
            return tempObj;
        });
    }

    //商品子表修改
    const updateList = (mode, arr = []) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        setList([...arr]);
        if (mode === "edit") { setControlled(true) }
    };

    //获取一条数据
    const getStockCheckOne = useLockFn(async (id, mode) => {
        let { retData } = await stockCheckService.one(id);
        const { details, startTime, endTime, shelfCode, ...from } = retData;
        if (from) {
            // from.result = getResult(from.differenceNumAll);
            updateFormVal(mode, {
                ...from,
                // result: getResult(from.differenceNumAll),
                startTime: startTime ? moment(startTime) : null,
                endTime: endTime ? moment(endTime) : null,
                shelfCode: shelfCode?.split(",") ?? []
            });
        }
        updateList(mode, (details ?? []).map(item => ({ ...item, key: item.id })));
        return retData;
    })

    //商品子表添加
    const insertMaterial = (mode, materialArr) => {
        const setList = mode === 'edit' ? setEditList : mode === "look" ? setLookList : setAddList;
        const repetition = [];
        setList((origin) => {
            const newArr = [...origin];
            materialArr.forEach((material) => {
                //选中的是否存在列表
                let isExist = origin.find((item) => item.materialCode === material.materialCode);
                if (!isExist) {
                    let { inventoryNum, num, averagePurchasePrice, realNum, differenceNum, differenceAmount, result } = material;
                    newArr.push({
                        ...material,
                        realNum: realNum ?? num,
                        differenceNum: differenceNum ?? num - Number(inventoryNum),
                        result: result ?? getResult(num - Number(inventoryNum)),
                        detailsId: material.id,
                        key: material.id,
                        stockId: material.id,
                        id: "",
                        differenceAmount: differenceAmount ?? formatNum((num - Number(inventoryNum)) * Number(averagePurchasePrice)),
                        scrapNum: 0,
                        scrapAmount: 0,
                    });
                } else {
                    repetition.push(material.name);
                }
            });
            return newArr;
        });
        setMaterialDialog(false);
        !!repetition.length && message.warning(`商品名称：${repetition.join(",")}，重复的将不在添加。`);
        if (mode === "edit") { setControlled(true) }
    };

    //商品子表删除 (单条及批量删除)
    const removeMaterial = useLockFn(async (mode, selectedRowKeys) => {
        if (mode === 'edit') {
            let ids = editList.filter(item => selectedRowKeys.indexOf(item.key ?? '') >= 0 && item.id).map(item => ({ id: item.id }));
            //存在id在跑接口进行数据库删除
            if (ids.length) {
                let { retData } = await stockCheckService.materialDelete({ ids,stocktakingCode:editFormVal.stocktakingCode });
                if (!retData) return retData;
            }
        }
        const setList = mode === 'edit' ? setEditList : setAddList;
        setList((origin) => origin.filter((item) => selectedRowKeys.indexOf(item?.key ?? '') < 0));
        if (mode === "edit") { setControlled(true) }
    });

    //新增采购单
    const insertDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        let newParams = {
            ...params,
            details: addList,
            createUser: userName,
            differenceRateAll: formatNum(addDesc.differenceRateAll),
            startTime: params.startTime ? momentFormat(params.startTime) : null,
            endTime: params.endTime ? momentFormat(params.endTime) : null,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        let { retData } = await stockCheckService.insert(newParams);
        setSaveBtnLoading(false)
        message.success("新增成功")
        return retData;
    })

    //编辑采购单
    const editDetail = useLockFn(async (params) => {
        setSaveBtnLoading(true)
        let newParams = {
            ...params,
            id: editFormVal.id,
            details: editList,
            updateUser: userName,
            differenceRateAll: formatNum(editDesc.differenceRateAll),
            startTime: params.startTime ? momentFormat(params.startTime) : null,
            endTime: params.endTime ? momentFormat(params.endTime) : null,
            sourceApp, branch,
            shopId, shopName, shopCode, userName, orgName, orgCode,
        }
        let { retData } = await stockCheckService.update(newParams);
        setSaveBtnLoading(false)
        message.success("编辑成功")
        return retData;
    })

    //下载模板
    const downloadTpl = useLockFn(async () => {
        let result = await stockCheckService.downloadTpl();
        convertRes2Blob(result);
    })

    //盘点完成
    const onComplete = useLockFn(async () => {
        let { stocktakingCode, id, status } = editFormVal;
        // if (status === DictCodeEnmu.STOCKTAKING_COMPLETE) return message.error("单据已完成，无法再次盘点")
        // if (status !== DictCodeEnmu.STOCKTAKING_CREATE) return message.error("单据不是进行中状态，无法再次盘点")
        let { retData } = await stockCheckService.complete({ stocktakingCode, id });
        message.success("盘点成功");
        getStockCheckOne(stocktakingCode, "edit")
        return retData
    })

    //审核完成
    const onFinalAudit = useLockFn(async (params) => {
        let { stocktakingCode, id, status } = editFormVal;
        // if (status === DictCodeEnmu.STOCKTAKING_COMPLETE) return message.error("单据已完成，无法再次审核")
        // if (status !== DictCodeEnmu.STOCKTAKING_AUDIT) return message.error("单据不是待审核状态，无法审核")
        let { retData } = await stockCheckService.finalAudit({ stocktakingCode, id });
        message.success("审核成功");
        getStockCheckOne(stocktakingCode, "edit")
        return retData
    })

    return {
        shopName, shopId,
        controlled,
        editFormVal,
        editList,
        addFormVal,
        addList,
        lookFormVal,
        lookList,
        addDesc, editDesc, lookDesc,
        materialDialog, setMaterialDialog,
        updateFormVal,
        updateList,
        getStockCheckOne,
        insertMaterial,
        removeMaterial,
        insertDetail,
        editDetail,
        downloadTpl,
        onComplete,
        onFinalAudit,
        getResult,
        stockRecordDialog, setStockRecordDialog,setSaveBtnLoading,saveBtnLoading
    }
})