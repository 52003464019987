import React, { useContext, useState, useEffect } from "react"
import {Table,Button} from 'antd'
import TopSearch from "@/components/TopSearch"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
//import ListComponent from "@/components/listComponent"
import {billMonitorExport,billMonitorTotal,billMonitor } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message } from "antd"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel,{ transPgToPagination } from "@/erp_subpackage/model/globalModel";
import {YhBox} from '@//erp_subpackage/components/YhBox'



export default () => {
    const { state,Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName },fetchFmsShopList } = useGlobalModel()
    const [searchValue, setSearchValue] = useState({
        // startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        // endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss"),
    })
    const [statusList,setStatusList]=useState([])//单据状态列表·
    const [shopList, setShopList] = useState([])
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const initPgCfg = { pageSize: 10, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg)
    const initdesc = {
        diffAmount:0,
        diffNum:0,
        diffStatus:0,
       
    }
    const [order, setOrder] = useState({})
    const [descTotal,setDescTotal,] =useState(initdesc)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "billMonitor") {
            loadData()
            getOrderType()
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchFmsShopList('').then(arr => {
            setShopList(arr)  
        })
    }, [])

    //列表数据
    const loadData = async( page,search = searchValue, ) => {
        // setLoading(true)
        let data = {
             ...search,
             pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
             pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            shopCode,
            shopId
        }
        let {retData} =  await billMonitor(data)
        let {records,...pg} =retData
        setList(records)
        setLoading(false)
        setPgCfg(transPgToPagination(pg));
        billMonitorTotal(data).then(({retData:totalVo})=>{
            setDescTotal(totalVo)  
        })
    }
    //导出
    const handleExport = (search=searchValue) => {
 
        const searchParams = {
            searchItem:{
                ...search,
                shopId: search.shopId ?? shopId,
                pageSize: 1,
                pageNum:15,shopCode
            }
        }
        billMonitorExport(searchParams).then((res) => {
            message.success(res.retData)
        })
        
    }


    // 过滤掉状态为空的单据类型
    const getOrderType =async()=>{
        let list= getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).filter(item=>
         (
            item.value==='trade-type.sale'||
            item.value==='trade-type.sale-return'   ||
            item.value==='trade-type.maintenance'   ||
            item.value==='trade-type.purchase'      ||
            item.value==='trade-type.purchase-return'||
            item.value==='trade-type.maintenance-return'  ) 
        )
       await setStatusList(list)
       
     }  
     //列表button
           const topButtons = <>
           <Button
                 className="btn-item"
                 shape="round"
                 onClick={() => handleExport()}
           >导出</Button>
     </>

    //table配置
    const columns = [
        {
            align: "center",
            width: 60,
            ellipsis: true,
            title: '序号',
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '门店名称',
            dataIndex: 'shopName',
            render:(text)=><YhTooltip text={text}/>
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据编号',
            dataIndex: 'tradeCode',
            render:(text)=><YhTooltip text={text}/>
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单位名称',
            dataIndex: 'partnerName',
            render:(text)=><YhTooltip text={text}/>
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据类型',
            dataIndex: 'tradeTypeName',
            render:(text)=><YhTooltip text={text}/>
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '单据状态',
            dataIndex: 'status',
            render:(text)=><YhTooltip text={getTreeNodeName(text)}/>
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '业务支付状态',
            dataIndex: 'payStatus',
            render: text => getTreeNodeName(text)
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '业务单据金额',
            dataIndex: 'totalAmount',
            render: text => `￥${formatNum(text)}`
        },

        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '财务收款状态',
            dataIndex: 'billStatus',
            render: text => text === '0' ? '初始' : text === '1' ? '已记账' : text === '2' ? '已核销' : text
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '账单金额',
            dataIndex: 'billAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '差异金额',
            dataIndex: 'diffAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            title: '状态差异',
            dataIndex: 'payStatus',
            render: (text, row) => ((row.billStatus === '0' && getTreeNodeName(text) === '初始') || (row.billStatus === '1' && getTreeNodeName(text) === '已记账') || (row.billStatus === '2' && getTreeNodeName(text) === '已核销')) ? '否' : '是'
        },
    ]
       //搜索JSON
       const searchList = {
        normalList: [
            
            {
                type: 'input',
                placeholder: '单据编号',
                valueName: 'tradeCode',
            },
            {
                type: 'input',
                placeholder: '单位名称',
                valueName: 'partnerName',
            },
            {
                type: 'select',
                placeholder: '单据类型',
                valueName: 'tradeTypeCode',
                optionList: () =>  
                statusList.map((item) =>
                    (<Option key={item.key} value={item.value}>{item.label}</Option>) 
                )
                   
                
            },
            {
                type:'select',
                placeholder: "状态(请先选择单据类型)",
                valueName: "status",
                mode: "multiple",
                optionList:()=>{
                    if(searchValue.tradeTypeCode=='trade-type.sale'){
                        return getSelectedDictOne(DictCodeEnmu.SALE).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.sale-return'){
                        return getSelectedDictOne(DictCodeEnmu.SALE_RETURN_STATUS).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.maintenance-return'){
                        return getSelectedDictOne(DictCodeEnmu.MAINTENANCE_RETURN).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.purchase-return'){
                        return getSelectedDictOne(DictCodeEnmu.PURCHASE_RETURN).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.purchase'){
                        return getSelectedDictOne(DictCodeEnmu.PURCHASE).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    if(searchValue.tradeTypeCode=='trade-type.maintenance'){
                        return getSelectedDictOne(DictCodeEnmu.MAINTENANCE).map((item) =>(<Option key={item.key} value={item.value}>{item.label}</Option>)) 
                    }
                    else{
                        return []
                    }
                }
                
            },

        ],
        
    }
    const desc = [
        
        {
            label: "单数差异",
            value: descTotal.diffNum,
            color: descTotal.diffNum == 0 ?'green' : 'red',
            // animation: descTotal.diffNum !== 0
        },
        {
            label: "状态差异",
            value: descTotal.diffStatus ?? " ",
            color: descTotal.diffStatus == 0 ? 'green' : 'red',
           // animation: descTotal.diffStatus !== 0
        },
        {
            label: "金额差异",
            value: descTotal.diffAmount,
            color: descTotal.diffAmount == 0 ? 'green' : 'red',
            //animation: descTotal.diffAmount !== 0
        },
        
    ]

    return (
        <>
            {state.activeMenu === "billMonitor" && (
                <>
                    <TopSearch
                        title="账单监测"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        // defaultSearch={{
                        // }}
                    />
                    <YhBox  title="账单监测" descriptions={desc}   style={{marginTop:20}} action={topButtons}>
                    <Table
                  
                        columns={columns}
                        dataSource={list}
                        pagination={ 
                            
                            {   ...pgCfg,
                                showSizeChanger:true,
                                position: ["bottomCenter"],
                                showTotal: () => `共${pgCfg.total}条`,
                                onChange: (pageNum,pageSize) => {
                                    loadData( {pageNum,pageSize})
                                }
                            }
                        }
                       
                        scroll={{ x: "max-content" }}
                        loading={loading}
                    />
                    </YhBox>
                </>
            )}
        </>
    )
}
