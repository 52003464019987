import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, message, Modal, Upload, Button } from "antd"
import { addTeamMember, EditTeamMember, getTeamMember } from "@/service/teamService"
import { getPcodeDict } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getToken } from "@/utils/dataStorage"
import "../teamEdit.less"
import { organPage } from "@/service/orgService"
import { createPassword } from "@/utils/util"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [idFileList, setIDFileList] = useState([]) //图片list
    const [headFileList, setheadFileList] = useState([]) //图片list
    const [org, setOrg] = useState([])

    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })

    useEffect(() => {
        queryMotorcade()
        organPage({
            searchItem: { orderFlag: 0 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            getTeamMember(rowInfo.id).then((res) => {
                let results = res.retData
                let headImgFileList, idImgFileList
                if (results.headImg) {
                    //回显图片， 有的话在做回显
                    headImgFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${results.headImg}`,
                            response: {
                                retData: results.headImg,
                            },
                        },
                    ]
                    setheadFileList(headImgFileList)
                }
                if (results.idCardImg) {
                    //回显图片， 有的话在做回显
                    idImgFileList = [
                        {
                            uid: "-2",
                            status: "done",
                            url: `${fileLookUrl().image}${results.idCardImg}`,
                            response: {
                                retData: results.idCardImg,
                            },
                        },
                    ]
                    setIDFileList(idImgFileList)
                }
                form.setFieldsValue({
                    ...results,
                    headImg: headImgFileList,
                    idCardImg: idImgFileList,
                    disableFlag: results.disableFlag === 0 ? true : false,
                })
            })
        } else {
            form.setFieldsValue({
                mallDisplay: "display",
                userPassword: createPassword()
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            console.log(headFileList)
            console.log(idFileList)
            let data = {
                ...values,
                disableFlag: 0,
                headImg: headFileList.length > 0 ? headFileList[0].response.retData : null,
                idCardImg: idFileList.length > 0 ? idFileList[0].response.retData : null,
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                EditTeamMember(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                addTeamMember(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const setTeamOnes = (results) => {
        console.log(results)
        form.setFieldsValue({
            ...results,
            disableFlag: results.disableFlag === 0 ? true : false,
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    // 图片上传
    const handleImgChange = async (info) => {
        setheadFileList([])
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage({ visible: false, md5: md5 })
            console.log(info.fileList)
            setheadFileList(info.fileList)
        }
    }
    const handleIDChange = (info) => {
        console.log(info)
        setIDFileList([])
        console.log(idFileList)
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage({ visible: false, md5: md5 })
            setIDFileList(info.fileList)
            console.log(idFileList)
        }
    }

    return (
        <>
            <Modal visible={previewImage.visible} title="头像查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage.md5}`} />
            </Modal>
            <Modal forceRender visible={visible} title="团队成员" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="姓名" name="userName" rules={[{ required: true, message: "请输入姓名" }]}>
                                    <Input placeholder="请输入姓名" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="手机号"
                                    name="userPhone"
                                    rules={[
                                        { required: true, message: "请输入手机号" },
                                        {
                                            pattern: /^1\d{10}$/,
                                            message: "请输入正确手机号格式",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入手机号" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="职务" name="userJobTitle" rules={[{ required: true, message: "请选择职务" }]}>
                                    <Select showSearch allowClear placeholder="请选择职务" style={{ width: "100%" }}>
                                        {getPcodeDict("101").children.map((item) => (
                                            <Select.Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item
                                    label="分成比例"
                                    name="userDistributionPoints"
                                    rules={[
                                        {
                                            pattern: /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^0$|^1$|^1\.(0{1,2})$/,
                                            message: "请输入正确分成比例",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入分成比例" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="个人点位"
                                    name="allocationPoint"
                                    rules={[
                                        {
                                            pattern: /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^1$|^1\.(0{1,2})$/,
                                            message: "请输入正确个人点位",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入个人点位" />
                                </Form.Item>
                            </Col> */}

                            <Col span={24}>
                                <Form.Item
                                    label="登录账户"
                                    name="userAccount"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入登录账户",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入登录账户" />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="密码"
                                    name="userPassword"
                                    rules={[
                                        {
                                            required: mode === "insert" ? true : false,
                                            message: "请输入密码",
                                        },
                                    ]}
                                >
                                    <Input.Password placeholder="请输入密码" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="所属机构"
                                    name="organId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择所属机构",
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择所属机构" style={{ width: "100%" }}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.organName}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="服务端显示商城" name="mallDisplay">
                                    <Select placeholder="请选择服务端显示商城" style={{ width: "100%" }}>
                                        <Select.Option value="display" key="display">
                                            显示
                                        </Select.Option>
                                        <Select.Option value="none" key="none">
                                            不显示
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item name="disableFlag" label="成员状态" valuePropName="checked">
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row> */}
                            <Col span={24}>
                                <Form.Item label="实名认证" name="idCardImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={idFileList}
                                        onPreview={(file) => {
                                            const md5 = file.response.retData
                                            setpreviewImage({ visible: true, md5 })
                                        }}
                                        onChange={handleIDChange}
                                    >
                                        {idFileList.length >= 1 ? null : <Button size="small">上传身份证</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="成员头像" name="headImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={headFileList}
                                        onPreview={(file) => {
                                            const md5 = file.response.retData
                                            setpreviewImage({ visible: true, md5 })
                                        }}
                                        onChange={handleImgChange}
                                    >
                                        {headFileList.length >= 1 ? null : <Button size="small">上传成员头像</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
