import React, { useEffect, useState } from 'react'
import { Form, Col, Row, Select, message, Modal, Spin, Upload, Button } from 'antd'
import { getPcodeDict } from '@/utils/dataStorage'
import { API_SERVER_HOST } from "@/config/app"
import { getToken } from "@/utils/dataStorage"
import Loading from "@/components/Loading"
export default (props) => {
    const { visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [threeGuaranteesBrand, setThreeGuaranteesBrand] = useState('10601')

  

    useEffect(() => {
        if (visible) {
            form.resetFields()
            form.setFieldsValue({threeGuaranteesBrand})
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    const handleCancel = () => {
        setVisible(false)
    }


     //导入数据
     const onChangeUpload = (info) => {
        setLoading(true)
        console.log(info)
        if (info.file.status === "done") {
            const { retCode, retData, retMsg } = info.file.response
            if (retCode === 200) {
                message.success(retData)
                setVisible(false)
                onSuccess && onSuccess()
            }else{
                message.error(retMsg)
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name}导入异常，请尝试重新导入`)
        }
        setLoading(false)
    }

    return (
        <>
            <Modal
                forceRender
                visible={visible}
                title="厂家明细导入"
                centered={true}
                onCancel={() => handleCancel()}
                width={600}
                footer={[
                    // <Button type="primary" loading={uploading} onClick={handlToChooseT}>
                    //     导入
                    // </Button>,
                    <Upload
                        name="file"
                        method="post"
                        showUploadList={false}
                        headers={{ authorization: getToken() }}
                        action={`${API_SERVER_HOST}/ci/factory/three-guarantees/import`}
                        onChange={onChangeUpload}
                        data={{threeGuaranteesBrand}}
                    >
                        <Button shape="round" type="primary" className="but-left" style={{ marginRight: "20px" }} >
                            导入明细
                        </Button>
                    </Upload>
                ]}
            >
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item
                                    label="请选择要导入的三包品牌"
                                    name="threeGuaranteesBrand"
                                    rules={[{ required: true, message: '请选择要导入的三包品牌' }]}
                                >
                                    <Select placeholder="请选择要导入的三包品牌" style={{ width: '100%' }} onChange={setThreeGuaranteesBrand}>
                                        {getPcodeDict('106').children.map((item) => (
                                            <Select.Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
            {loading && (
                <Loading
                    style={{
                        background: "rgba(0,0,0,0.5)",
                        height: "100vh",
                        with: "100vh",
                        position: "fixed",
                        left: "0",
                        top: "0",
                        zIndex: 9999,
                    }}
                />
            )}
        </>
    )
}
