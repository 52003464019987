import React, { useState, useEffect } from "react"
import { Button, Table, message, Modal, Input, Select, Switch } from "antd"
import { newGetList } from "@/service/customerService"
import { vehicleBrandList, vehicleBrandDisable, vehicleBrandDelete } from "@/service/brandManage"
import ModelList from "./modelDetail"

const { Option } = Select
export default (props) => {
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [dictObj, setDictObj] = useState(null)
    const { brandInfo } = props
    const [userData, setUserData] = useState(null) //列表
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    useEffect(() => {
        loadData(1)
    }, [])
    //table配置
    //新增，编辑
    const handleDetails = (detailsmode) => {
        if (detailsmode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看或编辑的账户信息")
                return
            }
        }
    }

    //默认加载
    const loadData = (current) => {
        let data = {
            searchItem: {
                parentId: brandInfo.id,
                type: "model",
            },
            pageNum: current,
            pageSize: 10,
        }
        vehicleBrandList(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setUserData(res.retData.list)
            })
            .catch((err) => {
                setUserData([])
            })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的品牌信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除型号 - ${selectInfo.rowInfo.name}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                vehicleBrandDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current)
                })
            },
        })
    }

    const handleUserStatus = (mode) => {
        if (mode !== "insert") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看或编辑的型号")
                return
            }
        }
        setShowVisible(true)
        setShowMode(mode)
    }

    //配置开关
    const sweitchChange = (checked, e, id) => {
        e.stopPropagation()
        vehicleBrandDisable({ id: id, disableFlag: checked ? 0 : 1 }).then((res) => {
            message.success("操作成功")
        })
    }

    const columns = [
        {
            title: "名字",
            align: "center",
            dataIndex: "name",
        },
        {
            title: "状态",
            align: "center",
            width: "10%",
            dataIndex: "disableFlag",
            render: (text, record) => (
                <Switch
                    size="small"
                    defaultChecked={text === 0 ? true : false}
                    checkedChildren="开启"
                    unCheckedChildren="关闭"
                    onChange={(checked, e) => sweitchChange(checked, e, record.id)}
                />
            ),
        },
    ]

    return (
        <>
            <div>
                <div className="list-header" style={{ marginTop: "-35px" }}>
                    <div className="list-but">
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus("insert")}>
                            新增
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus("edit")}>
                            {" "}
                            编辑
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleDelete()}>
                            {" "}
                            删除
                        </Button>
                    </div>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={userData}
                    // loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${pagination.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                    onRow={(record, index) => ({
                        onClick: () => setSelectInfo({ rowInfo: record, rowIndex: index }),
                    })}
                    rowClassName={(record, index) => (index === selectInfo.rowIndex ? "dark-row" : "")}
                />
            </div>
            {showVisible && (
                <ModelList
                    barndId={brandInfo.id}
                    rowInfo={selectInfo.rowInfo}
                    visible={showVisible}
                    mode={showMode}
                    setVisible={setShowVisible}
                    onSuccess={() => {
                        loadData(1)
                    }}
                />
            )}
        </>
    )
}
