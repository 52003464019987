import React from "react"
import { useSaleDcModel } from "../seleDcModel"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export function PageHeader() {
    const { getSelectedDictOne } = useDictModel(() => [])
    const { searchParam, updateSearchParam, resetSearchParam, loadData, organType, organTypeCode } = useSaleDcModel()
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs])
    const searchFields = [
        ...(organTypeCode === 3 ? [
            {
                type: "Select",
                placeholder: "所属机构",
                fieldName: "outShopId",
                options: orgDs,
            },
        ]
      : []),
        {
            type: "Input",
            placeholder: "调拨单号",
            fieldName: "code",
        }, {
            type: "Input",
            placeholder: "调出单号",
            fieldName: "transferOutCode"
        }, 
        {
            type: "Select",
            placeholder: "状态",
            fieldName: "status",
            mode:"multiple",
            options: getSelectedDictOne(DictCodeEnmu.TRANSFER_STATUS),
        },
        {
            type: "Input",
            placeholder: "调入机构",
            fieldName: "shopName",
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: "RangePicker",
            fieldName: "",
            rangePickerFieldName: ["outStartTime", "outEndTime"],
            placeholder: ["调出起始日期", "调出结束日期"],
            format: "YYYY-MM-DD",
            alloc: true,
            span: 16,
            xxl: 8,
        },
        {
            type: "Input",
            placeholder: "备注",
            fieldName: "note",
        },
    ]

    return (
        <YhPageHeader
            title="调出单"
            size="small"
            justify="end"
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    )
}
