import React, { useState, useEffect, useContext } from "react"
import { Tree, Button, Form, Input, message } from "antd"
import TopSearch from "@/components/TopSearch"
import { getWvSetting, wvSettingUpdate, wvSettingInsert, wvSettingDisable } from "@/service/allCarService"
import { MyContext } from "@/store/myContext"
import "./dictStyle.less"

const settingTypeObj = {
    brand: "品牌",
    series: "车系",
    model: "车型",
}
const settingTypeArr = Object.keys(settingTypeObj)
let pid = ""

const AllCarTypeManage = () => {
    const { state } = useContext(MyContext)
    const [form] = Form.useForm()
    const [treeList, setTreeList] = useState(null) //树结构列表
    const [selectTreeObj, setSelectTreeObj] = useState({}) //选择的字典数据
    const [mode, setMode] = useState("edit") //默认展示编辑
    const [settingType, setSettingType] = useState("") //操作的类型
    const [initTree, setInitTree] = useState(null)
    useEffect(() => {
        if (state.activeMenu === "allCarTypeManage") {
            loadData()
        }
    }, [state])

    //遍历树换title
    const mapTree = (arr) => {
        return arr.forEach((item) => {
            item.title = <span style={item.disableFlag ? { color: "red" } : {}}>{item.settingCode + " " + item.settingName}</span>
            // item.disabled = item.disableFlag === 1 ? true : false;
            item.children && mapTree(item.children)
        })
    }
    //字典树加载。
    const loadData = () => {
        getWvSetting().then((res) => {
            // if (res && res.retCode === 200) {
                let result = JSON.parse(
                    JSON.stringify(res.retData)
                        .replace(/id/g, "key")
                        .replace(/subItem/g, "children")
                )
                mapTree(result)
                setInitTree(res.retData)
                setTreeList(result)
            // }
        })
    }

    //查找父级id，返回父级。
    const forFind = (array, key) => {
        for (const item of array) {
            if (item.id === key) {
                return item
            }
            if (item.subItem && item.subItem.length > 0) {
                let forFindRet = forFind(item.subItem, key)
                if (forFindRet) {
                    return forFindRet
                }
            }
        }
    }

    //选中当前行 根据当前行拿到信息
    const onSelect = (selectedKeys, info) => {
        setMode("edit")
        if (selectedKeys.length) {
            let selectObj = forFind(initTree, selectedKeys[0])
            setSelectTreeObj(selectObj)
            setSettingType(selectObj.settingType)

            let addFormData = {}

            //选择的车系需要找到品牌的名字
            if (selectObj.settingType === settingTypeArr[1]) {
                let brandObj = forFind(initTree, selectObj.pid)
                addFormData = {
                    brandName: brandObj.settingName,
                }
            }

            //选择的车型需要找到品牌和车系的名字
            if (selectObj.settingType === settingTypeArr[2]) {
                let seriesObj = forFind(initTree, selectObj.pid)
                let brandObj = forFind(initTree, seriesObj.pid)
                addFormData = {
                    brandName: brandObj.settingName,
                    seriesName: seriesObj.settingName,
                }
            }

            //编辑回显
            form.setFieldsValue({
                ...selectObj,
                ...addFormData,
            })
        } else {
            setSelectTreeObj({})
            form.resetFields()
        }
    }
    //新增模式
    const handleInsert = (str) => {
        let data = {}
        //新增品牌
        if (str === settingTypeArr[0]) {
            //品牌新增
            message.success("品牌新增模式开启")
            data = {
                brandFactory: "",
                brandType: "",
                pid: "",
            }
            //设置该项为了展示
            setSelectTreeObj({ barndInsert: true })
        } else if (str === settingTypeArr[1]) {
            //车系新增
            if (selectTreeObj.settingType !== settingTypeArr[0] && selectTreeObj.settingType !== settingTypeArr[1]) {
                message.warning("请选择正确的添加位置")
                return
            }
            message.success("车系新增模式开启")
            //选择的是品牌新增车系 设置品牌信息直接取selectTreeObj
            if (selectTreeObj.settingType === settingTypeArr[0]) {
                pid = selectTreeObj.id
                data.brandName = selectTreeObj.settingName
            } else {
                //选择的是车系新增车系 找到品牌信息设置
                let brandObj = forFind(initTree, selectTreeObj.pid)
                pid = brandObj.id
                data.brandName = brandObj.settingName
            }
        } else if (str === settingTypeArr[2]) {
            //新增车型
            if (selectTreeObj.settingType !== settingTypeArr[1] && selectTreeObj.settingType !== settingTypeArr[2]) {
                message.warning("请选择正确的添加位置")
                return
            }
            message.success("车系新增模式开启")
            //选择的是车系，找到车系和品牌信息
            if (selectTreeObj.settingType === settingTypeArr[1]) {
                let brandObj = forFind(initTree, selectTreeObj.pid)
                pid = selectTreeObj.id
                data.seriesName = selectTreeObj.settingName
                data.brandName = brandObj.settingName
            } else {
                //选择的是车型，新增同级 找到品牌和车系
                let seriesObj = forFind(initTree, selectTreeObj.pid)
                let brandObj = forFind(initTree, seriesObj.pid)
                pid = seriesObj.id
                data.brandName = brandObj.settingName
                data.seriesName = seriesObj.settingName
            }
        }
        form.setFieldsValue({
            ...{
                settingCode: "",
                settingName: "",
                remark: "",
            },
            ...data,
        })
        setMode("insert")
        setSettingType(str)
    }

    //禁用
    const deleteWv = () => {
        if (!Object.keys(selectTreeObj).length) {
            message.warning("请选择你要操作的选项")
            return
        }
        // if (selectTreeObj.subItem && selectTreeObj.subItem.length > 0) {
        //     message.warning("请先禁用完子节点才可以禁用父节点");
        //     return;
        // }
        wvSettingDisable({ id: selectTreeObj.id, disableFlag: selectTreeObj.disableFlag === 0 ? 1 : 0 }).then((res) => {
            // if (res && res.retCode === 200) {
                loadData()
                setSelectTreeObj({})
                message.success(`${selectTreeObj.settingName}:操作成功`)
            // }
        })
    }
    //字典新增或编辑
    const wvSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                settingName: values.settingName,
                remark: values.remark,
                brandFactory: settingType === settingTypeArr[0] ? values.brandFactory : "",
                brandType: settingType === settingTypeArr[0] ? values.brandType : "",
            }
            //新增
            if (mode === "insert") {
                let sendData = {
                    ...data,
                    pid,
                    disableFlag: 0,
                    settingType: settingType,
                }
                wvSettingInsert(sendData)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            message.success("增加成功")
                            setMode("edit")
                            loadData()
                        // } else if (res && res.retCode === 500) {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //     }
                    // })
            } else {
                //编辑
                let sendData = {
                    ...data,
                    id: selectTreeObj.id,
                }
                wvSettingUpdate(sendData)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            message.success("编辑成功")
                            loadData()
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //     }
                    // })
            }
            setSelectTreeObj({})
        })
    }

    //表单项 布局
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    }

    return (
        <>
            {state.activeMenu === "allCarTypeManage" && (
                <>
                    <TopSearch title="车型管理" />
                    <div className="dict-container">
                        <div className="dict-list-box">
                            <div className="dict-list-header">
                                <div className="dict-list-name">
                                    <span>车型列表</span>
                                </div>
                                <div className="dict-list-but">
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert(settingTypeArr[0])}>
                                        品牌新增
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert(settingTypeArr[1])}>
                                        车系新增
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert(settingTypeArr[2])}>
                                        车型新增
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={deleteWv}>
                                        {selectTreeObj.disableFlag ? "启用" : "禁用"}
                                    </Button>
                                </div>
                            </div>
                            <div className="dict-table-box">
                                {treeList && <Tree selectedKeys={[selectTreeObj.id || ""]} onSelect={onSelect} treeData={treeList} />}
                            </div>
                        </div>
                        <div className="dict-from-box">
                            {Object.keys(selectTreeObj).length ? (
                                <>
                                    <div className="dict-list-header">
                                        <div className="dict-list-but">
                                            <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={wvSubmit}>
                                                保存
                                            </Button>
                                            <Button
                                                type="primary"
                                                shape="round"
                                                size="small"
                                                className="dict-but-left"
                                                onClick={() => {
                                                    setSelectTreeObj({})
                                                    setMode("edit")
                                                }}
                                            >
                                                取消
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="dict-from-content">
                                        <Form form={form} {...formItemLayout}>
                                            {settingType === settingTypeArr[1] || settingType === settingTypeArr[2] ? (
                                                <Form.Item name="brandName" label="品牌">
                                                    <Input placeholder="自动" disabled />
                                                </Form.Item>
                                            ) : (
                                                ""
                                            )}

                                            {settingType === settingTypeArr[2] ? (
                                                <Form.Item name="seriesName" label="车系">
                                                    <Input placeholder="自动" disabled />
                                                </Form.Item>
                                            ) : (
                                                ""
                                            )}

                                            <Form.Item name="settingCode" label={`${settingTypeObj[settingType]}编号`}>
                                                <Input placeholder="自动生成" disabled />
                                            </Form.Item>
                                            <Form.Item
                                                name="settingName"
                                                label={`${settingTypeObj[settingType]}名称`}
                                                rules={[{ required: true, message: "名称不可为空" }]}
                                            >
                                                <Input placeholder="名称" />
                                            </Form.Item>
                                            {settingType === settingTypeArr[0] ? (
                                                <span>
                                                    <Form.Item name="brandType" label="品牌类型">
                                                        <Input placeholder="品牌类型" />
                                                    </Form.Item>
                                                    <Form.Item name="brandFactory" label="品牌厂家">
                                                        <Input placeholder="品牌厂家" />
                                                    </Form.Item>
                                                </span>
                                            ) : (
                                                ""
                                            )}

                                            <Form.Item name="remark" label="备注">
                                                <Input placeholder="备注" />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </>
                            ) : (
                                <div className="dict-init-box">
                                    <span>车型列表</span>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default AllCarTypeManage
