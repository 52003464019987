import axios from "axios"
import https from "https"
import { message } from "antd"
import { SHOP_API_SERVE } from "../config/app"
import { shopLogin } from "@/utils/util"
import { getShopToken } from "@/utils/dataStorage"
const qs = require("qs")

// 创建axios实例
const service = axios.create({
    baseURL: SHOP_API_SERVE, // 基础api
    timeout: 5000, // 请求超时时间
    httpsAgent: new https.Agent({
        rejectUnauthorized: false,
    }),
    paramsSerializer: (params) =>
        qs.stringify(params, {
            arrayFormat: "repeat",
        }),
})

// request拦截器
service.interceptors.request.use(
    (config) => {
        if (/seller\/trade\/orders\/export/.test(config.url)) {
            config.timeout = 60000
        }

        const shopToken = getShopToken()

        // uuid
        config.headers["uuid"] = shopToken && shopToken.uid

        // 获取访问Token
        let accessToken = shopToken && shopToken.access_token
        if (accessToken) {
            config.headers["Authorization"] = accessToken
        }
        /** 进行参数序列化 */
        if ((config.method === "put" || config.method === "post") && config.headers["Content-Type"] !== "application/json") {
            config.data = qs.stringify(config.data, {
                arrayFormat: "repeat",
            })
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

// respone拦截器
service.interceptors.response.use(
    async (response) => {
        return response.data
    },
    async (error) => {
        const error_response = error.response || {}
        const error_data = error_response.data || {}
        if (error_response.status === 403) {
            await shopLogin()
            return Promise.reject(403)
        }
        if (error.config && error.config.message !== false) {
            let _message = error.code === "ECONNABORTED" ? "连接超时，请稍候再试！" : "网络错误，请稍后再试！"
            message.error(error_data.message || _message)
        }
        return Promise.reject(error)
    }
)

export default service
