import React, { useState, useEffect } from 'react'
import { useSaleSoModel } from "../saleSoModel";
import { YhOfferPrint } from "@/erp_subpackage/components/YhOfferPrint/offerPrint"
import { saleSoService } from '../saleSoService';
import { numberParseChina, formatNum, fmoney, momentFormat } from '@/erp_subpackage/utils/util';
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel';
import moment from 'moment';
import useGlobalModel from '@/erp_subpackage/model/globalModel';
export function SalePrint() {
    const initDesc = { total: 0, discount: 0 }
    const totalDesc = { total: 0, num: 0 }
    const { user: { shopName, shopId, shopCode } } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState("")
    const {
        print: visible,
        selectedRow,
        setPrint,
        loadData
    } = useSaleSoModel();
    const [formVal, setFormVal] = useState({});
    const [maintenanceList, setMaintenanceList] = useState([]);
    const [maintenanceTotal, setMaintenanceTotal] = useState(initDesc);
    const [itemTotal, setItemTotal] = useState(initDesc);
    const [total, setTotal] = useState(totalDesc);
    const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    useEffect(() => {
        visible && getPoDetailOne()
    }, [visible])
    //获取一条数据
    const getPoDetailOne = async () => {
        let { retData } = await saleSoService.one(selectedRow.saleCode);
        let { details, settlementPartyCode, customerName, ...formVal } = retData;
        let newMaintenanceTotal = { ...maintenanceTotal }
        let newItemTotal = { ...itemTotal }
        let newTotal = { ...total }
        //看是否有折扣
        let isRate = details?.some(item => item.discountRate !== 100);
        let newdetails = details?.sort((a, b) => a.materialCode?.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' })).sort((a, b) => a.shelfCode?.localeCompare(b.shelfCode, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => {
            newMaintenanceTotal.total += Number(item.num) * Number(item.price)
            newMaintenanceTotal.discount = item.discountRate
            newTotal.num += Number(item.num)
            let { price, discountRate, amount } = item
            return {
                ...item,
                No: index + 1,
                notAmount: Number(item.num) * Number(item.price),
                price: fmoney(price, 2),
                isRate,  //看是否有折扣
                taxPrice: fmoney(formatNum(Number(price) * Number(discountRate) / 100), 2),
                amount: fmoney(amount, 2)
            }
        })
        // if (settlementPartyCode === "settle-party.internal") {
        //     setSettlementPartyName(shopName)
        // } else if (settlementPartyCode && (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1)) {
        //     let { value: customerCode } = getTreeNodeOne(settlementPartyCode)[0]
        //     setSettlementPartyName(getFindItem(customerCode, CustomerData).name)
        // } else {
        //     setSettlementPartyName(customerName)
        // }
        if (retData) {
            setMaintenanceList(newdetails ?? []);
            setFormVal({ ...formVal, isRate, settlementPartyCode, customerName, })
            setMaintenanceTotal(newMaintenanceTotal)
            setItemTotal(newItemTotal)
        }
    };
    const printNum = async () => {
        await saleSoService.printNum(selectedRow.id);
        await loadData()
    }
    const onCancel = () => {
        setPrint(false)
        setMaintenanceList([]);
        setFormVal({})
        setMaintenanceTotal(initDesc)
        setItemTotal(initDesc)
    }
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        ...(formVal.isRate ? [{
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text, record) => record.isRate ? text + "%" : ""
        }] : []),
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
        },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
       
    ]
    const XJHeader = [
        {
            fieldLabel: "车牌号：",
            fieldValue: formVal?.licensePlate
        },
        {
            fieldLabel: "底盘号：",
            fieldValue: formVal?.vin
        },

        {
            fieldLabel: "结算方：",
            className: "li-right",
            fieldValue: settlementPartyName,
        },
    ]
    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "销售单号：",
                fieldValue: formVal?.saleCode,
                // className: "w30",
            },
            {
                fieldLabel: "客户名称：",
                fieldValue: formVal?.customerName,
            },
            {
                fieldLabel: "联系人：",
                fieldValue: formVal?.contact,
                // className: "w30"
            },
            {
                fieldLabel: "电话：",
                fieldValue: formVal?.phone
            },
            {
                fieldLabel: "结算方式：",
                fieldValue: getTreeNodeName(formVal.payType)
            },
            {
                fieldLabel: "销售日期：",
                fieldValue: momentFormat(formVal?.createTime),
                // className: "li-right"
            },
            {
                fieldLabel: "打印日期：",
                fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                // className: "li-right"
            },

        ],
        footerPeople: [
            {
                fieldLabel: "制表人：",
                fieldValue: formVal?.createUser
            },
            {
                fieldLabel: "",
                fieldValue: ""
            },
            {
                fieldLabel: "",
                fieldValue: ""
            },
            // {
            //     fieldLabel: "仓库：",
            //     fieldValue: maintenanceList[0]?.warehouseName
            // },
            {
                fieldLabel: "客户签字：",
                fieldValue: ""
            },
        ],
        note: {
            fieldLabel: "备注：",
            fieldValue: formVal?.note
        },
        ReceivedTotal: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.receivableAmount),
            receivedAmount: formVal?.receivableAmount ? fmoney(formVal?.receivableAmount, 2) : "0.00"
        },
        SaleTotal: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.totalAmount),
            receivedAmount: formVal?.totalAmount ? fmoney(formVal?.totalAmount, 2) : "0.00"
        },
        DiscountTotal: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.discountTotalAmount),
            receivedAmount: formVal?.discountTotalAmount ? fmoney(formVal?.discountTotalAmount, 2) : "0.00"
        },
        Total: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.receivedAmount),
            receivedAmount: formVal?.receivedAmount ? fmoney(formVal?.receivedAmount, 2) : "0.00"
        },
    }
    const headerTitle = {
        // title: "天津欧豪富港汽车贸易有限公司销售单"
        title: `${formVal.bentityName}销售单`

    }
    return (
        <YhOfferPrint
            title="销售单打印详情"
            type=""
            visible={visible}
            onClose={onCancel}
            columns={columns}
            printNum={printNum}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printShow={printShow}
            headerTitle={headerTitle}
        />
    )
}