import { httpRequest } from '@/erp_subpackage/utils/HttpRequest'
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from '@/config/app'

class BacklogService {
    //积压明细
    async pageDetail(searchVo) {
        const apiUrl = '/ci-storage/inventory/overstock/details'
        return await httpRequest.post(apiUrl, searchVo, { baseUrl: API_SERVER_HOST })
    }
    //积压汇总
    async pageAll(searchVo) {
        const apiUrl = '/ci-storage/inventory/overstock/summary'
        return await httpRequest.post(apiUrl, searchVo, { baseUrl: API_SERVER_HOST })
    }
    //明细导出
    async backlogDetailService(searchVo) {
        const apiUrl = '/ci-analyze/inventory/overstock/details/export'
        return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000, baseUrl: API_SERVER_HOST })
    }
    //汇总导出
    async backlogAllService(searchVo) {
        const apiUrl = '/ci-analyze/inventory/overstock/summary/export'
        return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000, baseUrl: API_SERVER_HOST })
    }
}

export const backlogService = new BacklogService()
