import React, { useEffect, useState, useContext } from "react"
import { Button, Form, Input, Col, Row, Select, Spin, Switch, message, Upload, Image, Modal, Radio } from "antd"
import { getCustomerListNew, insertUser, editUser, getUserOne, getVehicleList } from "@/service/customerService"
import debounce from "lodash/debounce"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

const { Option } = Select

const UserDetails = (props) => {
    const { dispatch, state } = useContext(MyContext)
    const { modeKey, mode, rowInfo, customerOrder } = props
    const [form] = Form.useForm()
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        data: [],
        fetching: false,
        id: "",
    })
    const [idNoList, setIdNoList] = useState({
        //身份证图片list
        file: [],
        md5: "",
    })
    const [licenseFileList, setLicenseFileList] = useState({
        //驾驶证图片list
        file: [],
        md5: "",
    })
    const [imgLookVisible, setImgLookVisible] = useState({
        visible: false,
        md5: "",
    })
    const [vehicleInfo, setVehicleInfo] = useState({
        //车辆列表
        data: [],
        fetching: false,
    })
    useEffect(() => {
        queryMotorcade()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    //订单新增， 带过来客户信息就设置上
    useEffect(() => {
        if (customerOrder && Object.keys(customerOrder).length > 0) {
            setCustomerInfo({
                data: [
                    {
                        text: customerOrder.customerName,
                        value: customerOrder.customerId,
                    },
                ],
                fetching: false,
                id: customerOrder.customerId,
            })
            form.setFieldsValue({
                customerId: customerOrder.customerId,
            })
        }
    }, [customerOrder]) // eslint-disable-line react-hooks/exhaustive-deps

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            getUserOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                let results = res.retData
                let imgFileList = []
                if (results.driverCardImg) {
                    imgFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${results.driverCardImg}`,
                        },
                    ]
                    //回显行驶证图片
                    setLicenseFileList({
                        file: imgFileList,
                        md5: results.driverCardImg,
                    })
                }
                let idNoFileList = []
                if (results.idCardAddress) {
                    idNoFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${results.idCardAddress}`,
                        },
                    ]
                    //回显驾驶证图片
                    setIdNoList({
                        file: idNoFileList,
                        md5: results.idCardAddress,
                    })
                }
                console.log(results)

                form.setFieldsValue({
                    ...results,
                    userPassword:"",
                    userStatus: Number(results.userStatus) === 0 ? true : false,
                    driverCardImg: imgFileList,
                    idCardAddress: idNoFileList,
                    defaultVehicle: results.defaultVehicle ? results.defaultVehicle : undefined,
                    vehicleId: results.vehicleId ? results.vehicleId : undefined,
                })
                results.customerId &&
                    setCustomerInfo({
                        data: [
                            {
                                text: results.customerName,
                                value: results.customerId,
                            },
                        ],
                        fetching: false,
                        id: results.customerId,
                    }) //客户列表回显
                results.vehicleId &&
                    setVehicleInfo({
                        data: [
                            {
                                text: results.defaultVehicle,
                                value: results.vehicleId,
                            },
                        ],
                        fetching: false,
                    })
                // }
            })
        } else {
            form.setFieldsValue({
                userStatus: true,
            })
        }
    }

    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            filterCustomer: 1,
            // customerName: value,
            searchItem:{customerName:value}
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                    })
                }
                return arr
            }, [])
            setCustomerInfo({
                ...customerInfo,
                data: data,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    //客户选择
    const handleChangeCustomer = (value, option) => {
        console.log(option)
        setCustomerInfo({
            ...customerInfo,
            id: option.value,
        })
        //当客户重新选择时  置空车辆和拍照
        form.resetFields(["defaultVehicle", "vehicleId"])
    }

    //车辆列表查询
    const vehicleQuery = (value) => {
        setVehicleInfo({
            data: [],
            fetching: true,
        })
        let D = {
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                vehicleCode: value,
                customerId: customerInfo.id || null,
            },
        }
        getVehicleList(D).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.vehicleCode,
                    value: item.id,
                }))
                setVehicleInfo({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning("该客户下没有车辆")
            }
            // }
        })
        // .catch((err) => {
        //     if (err) {
        //         message.warning("该客户下没有车辆")
        //     }
        // })
    }
    //防抖
    const vehicleQuerys = debounce(vehicleQuery, 600)
    //车辆选中, 带出牌照
    const handleChangevehicle = (value, option) => {
        // console.log(value)
        form.setFieldsValue({
            defaultVehicle: value ? option.children : undefined,
        })
    }
    //行驶证图片上传
    const handleVeichleChange = (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setLicenseFileList({
                file: info.fileList,
                md5: md5,
            })
        } else if (info.file.status === "removed") {
            setLicenseFileList({
                file: [],
                md5: "",
            })
        }
    }
    //身份证图片上传
    const handleIdNoChange = (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setIdNoList({
                file: info.fileList,
                md5: md5,
            })
        } else if (info.file.status === "removed") {
            setIdNoList({
                file: [],
                md5: "",
            })
        }
    }
    //行驶证图片查看
    const handlePreview = (md5) => {
        setImgLookVisible({
            visible: true,
            md5: md5,
        })
    }
    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                driverCardImg: licenseFileList.md5,
                idCardAddress: idNoList.md5,
                userStatus: values.userStatus ? 0 : 1,
                defaultVehicle: values.defaultVehicle ? values.defaultVehicle : "",
                vehicleId: values.vehicleId ? values.vehicleId : "",
            }
            delete data.createTime
            if (mode === "edit") {
                data.id = rowInfo.id
                editUser(data).then((res) => {
                    // if (res && res.retCode === 200) {
                    message.success("编辑成功")
                    handleCancel()
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //         return
                //     }
                // })
            } else {
                insertUser(data).then(async (res) => {
                    // if (res && res.retCode === 200) {
                    //查看要关闭的是否被关闭
                    let isOrderKey = state.panes.find((i) => i.key === rowInfo.orderEditModeKey)
                    if (isOrderKey && rowInfo.orderEditModeKey) {
                        dispatch({
                            type: "changeTabs",
                            activeMenu: rowInfo.orderEditModeKey,
                            name: rowInfo.orderEditModeKey.length > 30 ? "订单编辑" : "订单新增",
                        })
                        dispatch({
                            type: "removeTabs",
                            targetKey: modeKey,
                        })
                    } else {
                        dispatch({
                            type: "removeTabs",
                            targetKey: modeKey,
                            activeMenu: "ordersManage",
                        })
                    }
                    message.success("新增成功")
                    !rowInfo.orderEditModeKey && handleCancel()
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //         return
                //     }
                // })
            }
        })
    }

    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "userRecord",
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    return (
        <>
            <div className="toolbar">
                <span className="modules-name">用户详情</span>
            </div>

            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>用户信息</span>
                    </div>
                    <div className="list-but">
                        {!isLook(mode) && (
                            <Button shape="round" className="but-left" onClick={onUserSubmit}>
                                保存
                            </Button>
                        )}
                        <Button shape="round" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="客户名称"
                                    name="customerId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择客户",
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        placeholder="请选择客户"
                                        notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => customerQuery()}
                                        onSearch={customerQuerys}
                                        onChange={handleChangeCustomer}
                                        style={{ width: "100%" }}
                                    >
                                        {customerInfo.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="真实姓名"
                                    name="realName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入用户名称",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入用户姓名" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="用户手机"
                                    name="userMobile"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入手机",
                                        },
                                        {
                                            pattern: /^1\d{10}$/,
                                            message: "请输入正确的手机号",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入用户手机" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="用户角色" name="userRole">
                                    <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择用户角色" style={{ width: "100%" }}>
                                        {getPcodeDict("021").children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="用户名"
                                    name="userName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入账户",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入登录账号" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="用户密码" name="userPassword">
                                    <Input placeholder="请输入用户密码" disabled={isLook(mode)}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="下属车辆"
                                    name="vehicleId"
                                    // rules={[{ required: true, message: "请选择默认车辆" }]}
                                >
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        allowClear
                                        placeholder="请选择车辆"
                                        notFoundContent={vehicleInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => vehicleQuery("")}
                                        onSearch={vehicleQuerys}
                                        onChange={handleChangevehicle}
                                        style={{ width: "100%" }}
                                    >
                                        {vehicleInfo.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="登记日期" name="createTime">
                                    <Input placeholder="自动生成" disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="身份证图片"
                                    name="idCardAddress"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    // rules={[{ required: true, message: "请上传行驶证" }]}
                                >
                                    <Upload
                                        disabled={isLook(mode)}
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={idNoList.file}
                                        onPreview={() => handlePreview(idNoList.md5)}
                                        onChange={handleIdNoChange}
                                    >
                                        {idNoList.file.length >= 1 ? null : <Button>上传身份证</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="驾驶证图片" name="driverCardImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        disabled={isLook(mode)}
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={licenseFileList.file}
                                        onPreview={() => handlePreview(licenseFileList.md5)}
                                        onChange={handleVeichleChange}
                                    >
                                        {licenseFileList.file.length >= 1 ? null : <Button>上传驾驶证</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            {/* <Col offset={3} span={9}>
                                <Form.Item
                                    label="身份证号"
                                    name="idNo"
                                // rules={[{ required: true, message: "请输入身份证号" }]}
                                >
                                    <Input placeholder="请输入身份证号" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col> */}
                            <Col offset={3} span={9}>
                                <Form.Item name="userStatus" label="用户状态" valuePropName="checked">
                                    <Switch disabled={isLook(mode)} checkedChildren="开启" unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item name="loginEnable" label="车队系统登录">
                                    <Radio.Group name="radiogroup">
                                        <Radio value={0}>否</Radio>
                                        <Radio value={1}>是</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item hidden name="defaultVehicle">
                            <Input />
                        </Form.Item>
                    </Form>
                </div>
            </div>
            {imgLookVisible.visible && (
                <Modal
                    width={550}
                    onCancel={() => setImgLookVisible({ visible: false, md5: "" })}
                    visible={imgLookVisible.visible}
                    title="图片查看"
                    footer={null}
                >
                    <Image src={`${fileLookUrl().image}${imgLookVisible.md5}`} style={{ padding: "3px" }} />
                </Modal>
            )}
        </>
    )
}
export default UserDetails
