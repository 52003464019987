import { useState, Key, useEffect } from "react";
import { message } from "antd"
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { stockDetailedService } from "@/erp_subpackage/pages/stock/detailed/stockDetailedService";
import { saleOrderService } from "../../saleOrderService"
import { formatNum } from "@/erp_subpackage/utils/util";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { useLockFn } from "ahooks";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";

export const useSaleOrderDetailModel = createModel(function () {
    const { user: { shopId, shopName, shopCode, userName, orgName, orgCode, bentityName, bentityCode } } = useGlobalModel()
    const [addModalVisible, setAddModalVisible] = useState(false)  //新增商品弹框
    const [vehicleEditAll, setVehicleEditAll] = useState([])//实时切换车辆 编辑
    const [vinEditAll, setVinEditAll] = useState([])  // 实时切换vin编辑
    const [vinAddAll, setVinAddAll] = useState([]) // 实时切换vin  新增
    const [vehicleAddAll, setVehicleAddAll] = useState([])//实时切换车辆 新增
    const [checkModal, setCheckModal] = useState(false) // 审核弹窗
    const { getTreeNodeName } = useDictModel();
    const initDesc = {
        numAll: 0,  //数量
        itemNumAll: 0, //项数
        totalAll: 0,//总计
        discountAll: 0,//折扣
        notTotalAll: 0,//折前总计
    }
    const initForm = {
        shopId,
        shopName,
        shopCode,
        createUser: userName,
        bentityName: bentityName,
        favorableAmount: 0,
        salesTypeName: "正常销售",
        salesTypeCode: DictCodeEnmu.SALE_TYPE_NORMAL,
        priceTypeName: "零售价",
        priceTypeCode: DictCodeEnmu.PRICE_SELECTION_RETAIL,
        orderSourceCode: DictCodeEnmu.SOURCE_ADD,
        orderSourceName: "新增",
    }
    const initPgCfg = { pageSize: 15, current: 1 }
    const initSearchParam = {};
    const [settleVisible, setSettleVisible] = useState(false);   //结算弹框
    //新增数据
    //新增详情form
    const [detailAddForm, setDetailAddForm] = useState(initForm)
    //新增详情列表
    const [detailAddList, setDetailAddList] = useState([])
    //新增详情列表选中行
    const [detaiAddDataRowKeys, setDetaiAddDataRowKeys] = useState([]);
    const [detaiAddDataRow, setDetaiAddDataRow] = useState([]);
    const [addDesc, setAddDesc] = useState(initDesc)
    const [addSaleType, setAddSaleType] = useState()
    //查看数据
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState([])
    const [lookDesc, setLookDesc] = useState(initDesc)

    //详情数据
    const [detailEditForm, setDetailEditForm] = useState({})
    //详情列表参数
    const [detailDataSource, setDetailDataSource] = useState([]);

    //详情表格选中行
    const [detailDataRowKeys, setDetailDataRowKeys] = useState([]);
    const [detailDataRow, setDetailDataRow] = useState([]);
    const [editDesc, setEditDesc] = useState(initDesc)

    //商品列表配置
    const [workDataSource, setWorkDataSource] = useState([])
    const [searchWorkData, setSearchWorkData] = useState(initSearchParam)
    const [workPgCfg, setWorkPgCfg] = useState(initPgCfg);
    //已选商品列表
    const [addDataSource, setAddDataSource] = useState([]);
    //表单改变或表格数据发生改变时，控制功能按钮的启用禁用
    const [topButsControl, setTopButsControl] = useState(false)
    //实时详情列表货位数据
    const [shelfAllList, setShelfAllList] = useState([]);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮
    //设置底部总数展示（查看|编辑|查看）
    useEffect(() => {
        let newDesc = { ...initDesc };
        detailDataSource && detailDataSource.forEach(item => {
            newDesc.numAll += Number(item.num);
            newDesc.totalAll += Number(item.amount);
            newDesc.notTotalAll += Number(item.notAmount);
            newDesc.discountAll += Number(item.amount) - Number(item.notAmount)
        });
        if ("favorableAmount" in detailEditForm) {
            newDesc.totalAll = newDesc.totalAll - Number(detailEditForm.favorableAmount);
            newDesc.discountAll = newDesc.discountAll + Number(detailEditForm.favorableAmount)
        }
        newDesc.itemNumAll = detailDataSource && detailDataSource.length
        setEditDesc(newDesc);
    }, [detailDataSource, detailEditForm])

    useEffect(() => {
        let newDesc = { ...initDesc };
        detailAddList && detailAddList.forEach(item => {
            newDesc.numAll += Number(item.num);
            newDesc.totalAll += Number(item.amount);
            newDesc.notTotalAll += Number(item.notAmount);
            newDesc.discountAll += Number(item.notAmount) - Number(item.amount)
        });
        if ("favorableAmount" in detailAddForm) {
            newDesc.totalAll = newDesc.totalAll - Number(detailAddForm.favorableAmount);
            newDesc.discountAll = newDesc.discountAll + Number(detailAddForm.favorableAmount)
        }
        newDesc.itemNumAll = detailAddList && detailAddList.length
        setAddDesc(newDesc);
    }, [detailAddList, detailAddForm])

    useEffect(() => {
        let newDesc = { ...initDesc };
        detailLookList && detailLookList.forEach(item => {
            newDesc.numAll += Number(item.num);
            newDesc.totalAll += Number(item.amount);
            newDesc.notTotalAll += Number(item.notAmount);
            newDesc.discountAll += Number(item.amount) - Number(item.notAmount)
        });
        if ("favorableAmount" in detailLookForm) {
            newDesc.totalAll = newDesc.totalAll - Number(detailLookForm.favorableAmount);
            newDesc.discountAll = newDesc.discountAll + Number(detailLookForm.favorableAmount)
        }
        newDesc.itemNumAll = detailLookList && detailLookList.length
        setLookDesc(newDesc);
    }, [detailLookList, detailLookForm])


        
    const modelScreenMethod = async (modelList, detailList) => {
        const repetition = []
        const newDetailData = []
        console.log(modelList)
        modelList.forEach((Item) => {
            let isSelect = detailList && detailList.find((item) => item.detailId === Item.id)
            if (isSelect === undefined) {
                newDetailData.push({
                    ...Item,
                    detailId: Item.id,
                    id: "",
                    amount: +formatNum(Number(Item.amount) - Number(Item.decreaseAmount)),  //退货金额
                    discountAmount: +formatNum(Number(Item.num) * Number(Item.price) * (Number(Item.discountRate) / 100)),  //退货折扣金额
                    rdecreaseAmount: +formatNum(Item.decreaseAmount) ?? 0  //单向优惠金额
                })
            } else {
                repetition.push(Item.materialName)
            }
        })
        return { newDetailData, repetition }
    }
    
    //新增表单缓存
    const updateAddFormVal = (val) => {
        let newVal = val
        setDetailAddForm({ ...detailAddForm, ...newVal })
    }
    //编辑表单缓存
    const updatEditFormVal = (val) => {
        let newVal = val
        setDetailEditForm({ ...detailEditForm, ...newVal })
    }
    //获取一条编辑或查看数据
    const getDetailEditOne = async (id, pattern) => {
        let { retData } = await saleOrderService.one(id);
        // return console.log(retData)
        const { details, ...from } = retData
        const newDetails = details?.map((item) => {
            return {
                ...item,
                notAmount: +formatNum(Number(item.num) * Number(item.price)),
                termNum: Number(item.num) + Number(item.inventoryNum), //判断编辑数量的条件
                detailsId: item.id,
            }
        })
        if (retData && pattern == "edit") {
            setDetailDataSource(newDetails ?? [])
            setDetailEditForm(from)
            setTopButsControl(false)
        } else if (retData && pattern == "look") {
            setDetailLookForm(from);
            setDetailLookList(newDetails ?? [])
        }
        return retData
    }
    //关闭弹框
    const resetWorkloadData = async () => {
        setSearchWorkData({});
        workloadData(initPgCfg, {});
    }

    //加载商品数据(获取的是库存明细的数据)
    const workloadData = async (page, searchValue = searchWorkData) => {
        //let param = {...searchWorkData,materialCode:searchWorkData.materialCode?.trim(),materialName:searchWorkData.materialName?.trim()}
        const searchParams = {
            ...searchValue,
            shopId,
            shopName,
            shopCode,
            pageSize: page?.pageSize ?? workPgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? workPgCfg.current ?? initPgCfg.current,
            status: "purchase-status.bill,purchase.bill,purchase.completely-io,purchase.allocation"
        }
        let { retData } = await stockDetailedService.page(searchParams);
        const { records, ...pg } = retData;
        const newRecords = records.map(item => {
            return {
                ...item,
                num: 1,
                amount: Number(item.lateSalePrice) * 1,
            }
        })
        setWorkPgCfg(transPgToPagination(pg));
        setWorkDataSource(newRecords);
    }

    //添加到详情列表 包含导入
    const transformsToPoDetailList = (mode, selectedList = addDataSource) => {
        let detailList = mode === "edit" ? detailDataSource : detailAddList;
        const repetition = [];
        const newDetailData = []
        selectedList.forEach(Item => {
            let isSelect = detailList?.find(item => item.purchaseId === Item.id);
            if (!isSelect) {
                let { id, materialCode, materialName, inventoryNum, availableStock, num, amount, price, discountRate, ...params } = Item;
                price = +(price ?? 0);
                discountRate = +(discountRate ?? 100);
                newDetailData.push({
                    ...Item,
                    inventoryNum: availableStock,
                    price,
                    discountRate,
                    notAmount: +formatNum(num * price),
                    amount: +formatNum((num * price * discountRate) / 100),
                    id: "",
                    stockId: Item.id,
                    detailsId: id,
                    purchaseId: id
                });
            } else {
                repetition.push(Item.materialName);
            }
        });

        (mode === "edit" ? setDetailDataSource : setDetailAddList)([...detailList, ...newDetailData]);
        repetition.length != 0 && message.warning(`商品名称：【${repetition.join(",")}】，采购单号相同，重复的将不在添加。`);
    }

    //删除详情列表数据
    const removeDetailList = async (mode, Material) => {
        if (mode == "add") {
            const filterDetailList = Material ? detailAddList.filter(el => !!!(Material.detailsId === el.detailsId)) : detailAddList.filter(el => !!!detaiAddDataRowKeys.find(ele => ele === el.detailsId));
            setDetailAddList(filterDetailList)
            message.success("删除成功!")
            setDetaiAddDataRowKeys([]);
            setDetaiAddDataRow([]);
        } else if (mode == "edit") {
            const backendDetailList = Material ? Material.id ? [{ id: Material.id }] : [] : detailDataRow.reduce((all, next) => {  //前端页面维护的需要删除的id
                if (next.id) {
                    all.push({ id: next.id });
                }
                return all;
            }, []);

            //删除详情列表调用的方法
            const deleteData = () => {
                const filterDetailList = Material ? detailDataSource.filter(el => !!!(Material.detailsId === el.detailsId)) : detailDataSource.filter(el => !!!detailDataRowKeys.find(ele => ele === el.detailsId));
                setDetailDataSource(filterDetailList)
                message.success("删除成功!")
                setDetailDataRowKeys([]);
                setDetailDataRow([]);
            }

            if (backendDetailList.length > 0) {
                await saleOrderService.materialDelete({ ids: backendDetailList, saleOrderCode: detailEditForm.saleOrderCode }).then(res => {
                    if (res.retData) {
                        deleteData()
                    } else {
                        message.error("删除失败!")
                    }
                })
            } else {
                deleteData()
            }
        }
    }
    //新增采购单
    const insertDetail = useLockFn(async (params) => {
        //判断每个商品的销售价格不能低于采购价
        let filterGoods = detailAddList.filter(item => +item.price < +(item.latestPurPrice > 0 ? item.latestPurPrice : item.purchasePrice))
        if (filterGoods.length > 0) {
            message.warning(`商品【${filterGoods.map(item => item.materialName).join()}】的价格低于采购价，请修改后保存`)
            return false
        }
        setSaveBtnLoading(true)
        let newParams = {
            ...params,
            details: detailAddList,
            createUser: userName,
            shopId,
            shopName,
            shopCode,
            orgName,
            orgCode,
            orderTypeCode: params.orderSourceCode === DictCodeEnmu.SOURCE_ADD ? DictCodeEnmu.TRADE_TYPE_SALE_ORDER : DictCodeEnmu.TRADE_TYPE_MALL,
            orderTypeName: params.orderSourceCode === DictCodeEnmu.SOURCE_ADD ? getTreeNodeName(DictCodeEnmu.TRADE_TYPE_SALE_ORDER) : getTreeNodeName(DictCodeEnmu.TRADE_TYPE_MALL)
        }
        let { retData } = await saleOrderService.insert(newParams);
        setSaveBtnLoading(false)
        return retData;
    })
    //编辑采购单
    const editDetail = useLockFn(async (params) => {
        //判断每个商品的销售价格不能低于采购价
        let filterGoods = detailDataSource.filter(item => +item.price < +(item.latestPurPrice > 0 ? item.latestPurPrice : item.purchasePrice))
        if (filterGoods.length > 0) {
            message.warning(`商品【${filterGoods.map(item => item.materialName).join()}】的价格低于采购价，请修改后保存`)
            return false
        }
        setSaveBtnLoading(true)
        let { retData } = await saleOrderService.update({
            ...params,
            details: detailDataSource,
            updateUser: userName,
            shopId,
            shopName,
            shopCode,
            orgName,
            orgCode,
        });
        setSaveBtnLoading(false)
        return retData;
       
    }
    
    )
    //关闭页签清不同状态缓存
    // 新增
    const resetAddCatch = async () => {
        setDetailAddList([]);
        setDetailAddForm({})
    }

    // 编辑
    const resetEditCatch = async () => {
        setDetailEditForm({});
        setDetailDataSource([]);
    }

    // 详情页 下载模板
    const downloadTpl = useLockFn(async () => {
        let result = await saleOrderService.downloadTpl();
        convertRes2Blob(result)
    })

    // 转销售单(编辑模式有)
    const onTransfer = useLockFn(async (planCode) => {
        let { retData } = await saleOrderService.transfer(planCode);
        return retData

    })
    const onSettle = useLockFn(async (paramas) => {
        let { retData } = await saleOrderService.settle(paramas);
        return retData
    })

    // 商城审核 status 0 拒绝 1 通过
    const onExamine = useLockFn(async (params) => {
        let { retData } = await saleOrderService.examine(params);
        return retData
    })

    return {
        // data
        vehicleEditAll,
        vehicleAddAll,
        vinEditAll,
        vinAddAll,
        addModalVisible,
        checkModal,
        initPgCfg,
        detailAddForm,
        detailAddList,
        detaiAddDataRow,
        addDesc,
        detailLookForm,
        detailLookList,
        lookDesc,
        detailDataSource,
        detailDataRowKeys,
        detailDataRow,
        detailEditForm,
        editDesc,
        workDataSource,
        searchWorkData,
        workPgCfg,
        addDataSource,
        shelfAllList,
        detaiAddDataRowKeys,
        initForm,
        topButsControl,
        addSaleType,
        settleVisible,
        modelScreenMethod,
        // model
        setVehicleEditAll,
        setVinEditAll,
        setVehicleAddAll,
        setVinAddAll,
        setCheckModal,
        setAddModalVisible,
        setDetailAddForm,
        setDetailAddList,
        setDetaiAddDataRow,
        setAddDesc,
        setDetailLookForm,
        setDetailLookList,
        setLookDesc,
        setDetailDataSource,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailEditForm,
        setEditDesc,
        setWorkDataSource,
        setSearchWorkData,
        setWorkPgCfg,
        setAddDataSource,
        updateAddFormVal,
        updatEditFormVal,
        getDetailEditOne,
        resetWorkloadData,
        workloadData,
        transformsToPoDetailList,
        removeDetailList,
        insertDetail,
        editDetail,
        resetAddCatch,
        resetEditCatch,
        setShelfAllList,
        setDetaiAddDataRowKeys,
        downloadTpl,
        setTopButsControl,
        onTransfer,
        setAddSaleType,
        setSettleVisible,
        onSettle,
        onExamine,saveBtnLoading,setSaveBtnLoading
    }
});