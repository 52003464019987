import {
    httpRequest
} from "@/erp_subpackage/utils/HttpRequest";

class PrebookService {
    async page(searchVo){
        const apiUrl = "/ci/order_prebook/page";
        return await httpRequest.post(apiUrl,searchVo)
    }

    async prebookStatic(searchVo){
        const apiUrl = "/ci/order_prebook/prebookStatic";
        return await httpRequest.post(apiUrl,searchVo)
    }

    async one(id){
        const apiUrl = `/ci/order_prebook/one/${id}`;
        return await httpRequest.get(apiUrl)
    }

    async cancel(param){
        const apiUrl = `/ci/order_prebook/cancel`;
        return await httpRequest.post(apiUrl, param)
    }

    async insert(insertVo){
        const apiUrl = "/ci/order_prebook/insert";
        return await httpRequest.post(apiUrl, insertVo)
    }

    async update(updateVo){
        const apiUrl = "/ci/order_prebook/update";
        return await httpRequest.post(apiUrl, updateVo)
    }

    async convertOrder(param){
        const apiUrl = "/ci/order_prebook/convertOrder";
        return await httpRequest.post(apiUrl, param)
    }

}

export const prebookService = new PrebookService