import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getRoleList, deleteRole, listAllNotice, listRoleBindNoticeTag, roleJoinNotice } from "@/service/permissin"
import { MyContext } from "@/store/myContext"
import { Button, Modal, Col, Row, Tree } from "antd"

//维修配件
const MaterialManage = () => {
    const { dispatch, state, message, detailsPage } = useContext(MyContext)
    const [materialList, setMaterialList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [visible, setVisible] = useState(false) //模态框
    const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([])
    const [treeData, setTreeData] = useState([])
    const [checkedKeysResult, setCheckedKeysResult] = useState([])
    const [showTree, setShowTree] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "roleManage") {
            loadData(pagination.current)
        }
        return () => setMaterialList([])
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (visible) {
            initNoticeTree()
        } else {
            setCheckedKeysResult([])
            setTreeData([])
            setDefaultCheckedKeys([])
        }
    }, [visible])// eslint-disable-line react-hooks/exhaustive-deps

    const initNoticeTree = async () => {
        setShowTree(false)
        await loadNoticeAll()
        await loadDefaultKeys(selectInfo.rowInfo.id)
    }

    useEffect(() => {
        if (treeData.length > 0) {
            setShowTree(true)
        }
    }, [treeData, defaultCheckedKeys])
    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getRoleList(data).then((res) => {
            // if (res && res.retCode === 200) {
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setMaterialList(res.retData.list)
            // }
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }
    //新增
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "角色新增",
            edit: "角色编辑",
            look: "角色查看",
        }
        let modeKey = "roleManage/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的角色信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的角色信息")
                return
            }

            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.RoleManageDetail({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //删除开关
    const roleDelete = (id) => {
        deleteRole(id)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                // } else {
                //     message.error(res.retMsg)
                // }
            })
            // .catch(() => {
            //     message.error("服务器错误")
            // })
    }

    /**
     *
     * @returns 为选中的角色分配通知
     *
     */
    const assignmentNotice = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要分配通知的角色信息")
            return
        }
        setVisible(true)
    }

    const convertTreeData = (list) => {
        return list.map((i) => {
            let result = {
                key: i.noticeCode,
                title: i.noticeName,
                // disabled: true
            }
            let templates = i.templates
            if (templates) {
                let temp = templates.map((v) => {
                    return {
                        key: v.uniqueEnumTag,
                        title: v.noticeMethodDesc,
                    }
                })
                result.children = temp
            }
            return result
        })
    }

    // 获取通知列表
    const loadNoticeAll = () => {
        listAllNotice()
            .then((res) => {
                // if (res && res.retCode === 200) {
                    let list = res.retData
                    if (list) {
                        list = convertTreeData(list)
                        console.log(list)
                        setTreeData(list)
                    }
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    //获取默认的节点
    const loadDefaultKeys = (roleId) => {
        listRoleBindNoticeTag(roleId)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    let list = res.retData
                    setDefaultCheckedKeys(list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    const assignmentNoticeSubmit = () => {
        let data = {
            roleId: selectInfo.rowInfo.id,
            templateTags: checkedKeysResult,
        }
        roleJoinNotice(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    message.success(res.retMsg)
                    setVisible(false)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    const onCheck = (checkedKeysValue) => {
        setCheckedKeysResult(checkedKeysValue)
    }

    const onCancel = (visible) => {
        setVisible(visible)
    }

    //table配置
    const columns = [
        {
            title: "角色名称",
            align: "center",
            dataIndex: "roleName",
        },
        {
            title: "角色标识",
            align: "center",
            dataIndex: "roleCode",
        },
        {
            title: "角色描述",
            align: "center",
            dataIndex: "roleDesc",
        },
        {
            title: "创建人",
            align: "center",
            dataIndex: "createUser",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "deleteFlag",
            render: (text, record) => (
                <Button
                    size={"small"}
                    onClick={() => {
                        roleDelete(record.id)
                    }}
                >
                    删除
                </Button>
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入角色名称",
                valueName: "roleName",
            },
        ],
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => assignmentNotice(),
            content: "分配通知",
        },
    ]
    return (
        <>
            {state.activeMenu === "roleManage" && (
                <>
                    <TopSearch
                        title="角色管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="角色列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={materialList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                    <Modal
                        visible={visible}
                        title="分配通知"
                        // centered={true}
                        onOk={() => assignmentNoticeSubmit()}
                        onCancel={() => onCancel(false)}
                        width={1260}
                    >
                        <Row>
                            <Col offset={2} span={9}>
                                {showTree ? (
                                    <Tree
                                        defaultExpandAll={true}
                                        defaultCheckedKeys={defaultCheckedKeys}
                                        onCheck={onCheck}
                                        checkable
                                        treeData={treeData}
                                    ></Tree>
                                ) : (
                                    <></>
                                )}
                            </Col>
                        </Row>
                    </Modal>
                </>
            )}
        </>
    )
}
export default MaterialManage
