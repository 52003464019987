import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { transferMonitor, transferMonitorExport,transferMonitorTotal } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message,Tooltip,Table,Button } from "antd"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { YhBox } from "@/erp_subpackage/components/YhBox";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";

export default () => {
    const { state,Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [totalData, setotalData] = useState({}) //总计
    const [shopList, setShopList] = useState([])
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName },fetchFmsShopList,setLoadingInfo } = useGlobalModel()
    const [searchValue, setSearchValue] = useState({})
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    //副标题计算
    useEffect(() => {
        if (state.activeMenu === "transferMonitor") {
            loadData(pagination.current)
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchFmsShopList('').then(arr => {
            setShopList(arr)  
        })
    }, [])
    //列表数据
    const loadData = (current, search = searchValue) => {
        setLoading(true)
        let data = {
             ...search,
            pageNum: current,
            pageSize: search?.pageSize??10,
            shopCode,
            shopId,
            branchIn: "ci",
            branchOut: "ci"
            }
        transferMonitor(data).then(({retData}) => {
            setpagination({
                current: current,
                total: retData.total,
                pageSize: retData.size,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(retData.records)
        })
        transferMonitorTotal(data).then(({retData}) => {
            setotalData(retData)
        })
        setLoading(false)
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    const handleExport = async () => {
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
        try {
            const searchParams = {
                shopId,
                shopCode,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
                branchIn: "ci",
                branchOut: "ci"
            }
            let result = await transferMonitorExport(searchParams);
            result && setLoadingInfo({ loadingVisible: false, txt: "" });
            convertRes2Blob(result);
        } catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
    }

    //列表button
    // const topButtons = [
    //     {
    //         click: () => handleExport(),
    //         content: "导出",
    //     },
    // ]

    //列表button
    const topButtons = <>
    <Button
        className="btn-item"
        shape="round"
        onClick={() => handleExport()}
    >导出</Button>
</>
    //table配置
    const columns = [
        {
            align: "center",
            width: 60,
            ellipsis: true,
            title: '序号',
            render: (v, r, i) => i + 1
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调入单号',
            dataIndex: 'transferInCode',
        },
        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '调入单状态?',
        //     dataIndex: '',
        //     render: text => getTreeNodeName(text)
        // },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调入门店',
            dataIndex: 'shopName',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调入数量',
            dataIndex: 'inTotalNum',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调入金额',
            dataIndex: 'inTotalAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调入成本金额',
            dataIndex: 'inCostAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调入毛利金额',
            dataIndex: 'inProfit',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调出单号',
            dataIndex: 'transferOutCode',
        },

        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '调出单状态?',
        //     dataIndex: '',
        //     render: text => getTreeNodeName(text)
        // },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调出门店',
            dataIndex: 'outShopName',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调出数量',
            dataIndex: 'outTotalNum',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '调出金额',
            dataIndex: 'outTotalAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调出成本金额',
            dataIndex: 'costAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 200,
            ellipsis: true,
            title: '调出毛利金额',
            dataIndex: 'grossProfit',
            render: text => `￥${formatNum(text)}`
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '数量差异',
            dataIndex: 'numDiff',
        },
        {
            align: "center",
            width: 100,
            ellipsis: true,
            title: '金额差异',
            dataIndex: 'amountDiff',
            render: text => `￥${formatNum(text)}`
        },
        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '成本差异?',
        //     dataIndex: 'saleProfit',
        //     render: text => `￥${formatNum(text)}`
        // },
        // {
        //     align: "center",
        //     width: 100,
        //     ellipsis: true,
        //     title: '毛利差异?',
        //     dataIndex: '',
        //     render: text => `￥${formatNum(text)}`
        // },
        // {
        //     align: "center",
        //     width: 100,
        //     title: '调入制单日期',
        //     dataIndex: 'createTime',
        // },

    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "调拨单号",
                valueName: "code",
            },
            // {
            //     type: "input",
            //     placeholder: "业务单据编号",
            //     valueName: "saleCode",
            // },
            // {
            //     type: "select",
            //     placeholder: "调出门店",
            //     valueName: "outShopCode",
            //     optionList: () =>
            //     shopList.map((item) =>
            //         ( 
            //         <Option key={item.id} value={item.code}>
            //             {item.name}
            //         </Option>
            //     )),
            // },
            {
                type: "input",
                placeholder: "调出门店",
                valueName: "outShopName",
            },
        ],
    }
    const desc = [
        {
            label: "数量差异",
            value: totalData.num??0,
            color: "green"
        },
        {
            label: "金额差异",
            value: "￥" + formatNum(totalData.amount?? 0),
            color: "red"
        },
        // {
        //     label: "成本差异",
        //     value: "￥" + formatNum(totalData.costAmount?? 0),
        //     color: "red"
        // },
        // {
        //     label: "毛利差异",
        //     value: "￥" + formatNum(totalData.profit?? 0),
        //     color: "red"
        // },

    ]

    return (
        <>
            {state.activeMenu === 'transferMonitor' && (
                <>
                    <TopSearch
                        title="调入调出监测"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <YhBox title="调入调出监测" action={topButtons} descriptions={desc} style={{marginTop:20}}>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={list}
                            pagination={{
                                ...pagination,
                                position: ['bottomCenter'],
                                showTotal: () => `共${pagination.total}条`,
                                onChange: (current, serach = searchValue) => {
                                    setSearchValue({...searchValue,pageSize:serach})
                                    loadData(current, searchValue)
                                },
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </YhBox>
                </>
            )}
        </>
    )
}
