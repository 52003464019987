import React, { useEffect } from "react";
import { Table, } from "antd";
import { useAllocModel } from "../inventoryStatisticsModel";
// import { useRecordGoodsModel } from '@/erp_subpackage/views/public/GoodsRecordsModal/recordGoodsModel';
import { formatNum } from '@/erp_subpackage/utils/util'
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";

export function DataTable() {
    const { setMaterialInfo } = useGlobalModel(() => []);
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = useAllocModel();
    // const { setGoodsRecordModal } = useRecordGoodsModel(() => []);

    const detailColumns = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            fixed: "left",
            width: 160,
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            fixed: "left",
            width: 180,
            dataIndex: 'materialName',
        },
        {
            title: '仓库',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 160,
            dataIndex: 'shelfCode',
        },
        {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryQuantity',
        },
        {
            title: '占用库存',
            width: 100,
            dataIndex: 'occupyNum',
        },
        {
            title: '可用库存',
            width: 100,
            dataIndex: 'num',
            render: (text, record) => +(record?.inventoryQuantity ?? 0) - +(record?.occupyNum ?? 0)
        },
        {
            title: '最新进价',
            width: 100,
            dataIndex: 'lastPurchasePrice',
            render: text => `￥${formatNum(text)}`
        },
        {
            title: '库存成本',
            width: 100,
            dataIndex: 'inventoryAmount',
            render: text => `￥${formatNum(text)}`
        },
        {
            title: '最初入库日期',
            width: 200,
            dataIndex: 'lastStockIoTime',
        },
        {
            title: '是否存在积压',
            width: 100,
            dataIndex: 'overstockFlag',
            render: (text) => text ? '是' : '否'
        },
        {
            title: '库龄',
            width: 100,
            dataIndex: 'overstock',
        },
        {
            title: '最近一月是否销售',
            width: 130,
            dataIndex: 'saleRecent',
            render: (text) => text ? '是' : '否'
        },
        {
            title: '最近一年是否销售',
            width: 130,
            dataIndex: 'saleRecentYear',
            render: (text) => text ? '是' : '否'
        },
        {
            title: '年周转率',
            width: 100,
            dataIndex: 'turnoverRate',
        },
        {
            title: '积压率',
            width: 100,
            dataIndex: 'overstockRate',
        },
        {
            title: '销存率',
            width: 100,
            dataIndex: 'saleOverstockRate',
        }
    ];
    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            loading={loading}
            rowKey={(record, i) => record.id || record.materialCode + i}
            // rowSelection={{
            //     //全部选中时  (存string[])
            //     onSelectAll: (selected, selectedRows, changeRows) => {
            //         let changeRowId = changeRows.map(item => item.id);
            //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
            //             : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
            //     },
            //     onSelect: (record) => onRow(record.id),
            //     selectedRowKeys: selectedRowKeys
            // }}
            columns={detailColumns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                }
            })}
            scroll={{ x: 'max-content', y: "calc(100vh - 350px)" }}
        />
    )
}
