import React, { useEffect, useState, useRef } from "react"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { TableBox } from './tableBox'
import { usecontractDetailsModel } from './detailsModel'
import { getCustomerOne, getCustomerListNew } from '@/service/customerService'
import { contractService } from '../contractParticularsServer'
import { getPcodeDict } from "@/utils/dataStorage"
import { Button, message, Radio } from "antd"
import { debounce } from "lodash"
import moment from "moment";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { platformUserList } from "@/service/customerService"
import { getBussinessEntityList } from '@/service/dataService'
import { commonService } from "@/erp_subpackage/pages/common/commonService";
import { WorkOrderInfoTable } from './WorkOrderTable'
import { MileorderTable } from './mileageorderTable'
import { useContractModel } from '../contractParticularsModel';


export const ContractlDetails = ({ modeKey, mode, rowCode, dispatch, prop }) => {

    const { editFormVal, setCustomerid, addFormVal, lookFormVal, updateFormVal, saveBtnLoading, setSaveBtnLoading,
        getdedtailsOne, editSelect, addSelect, setLookSelect, setAddSelect, setPgCfgwork,
        setEditSelect, setAddFormVal, setDescTotalwork, setworkOrderData,
        serachworke, mileageOrrder, contractPage, billmileageData, onExportMeilage, onExportWorker, onExportOrder,
    } = usecontractDetailsModel()

    const { formRef } = useContractModel()


    const formVal = mode === 'add' ? addFormVal : mode === 'edit' ? editFormVal : lookFormVal

    const selectList = mode === 'edit' ? editSelect : addSelect

    const { user: { organId, BusinessEntityId } } = useGlobalModel();
    //签约类型
    const [newList, setnewList] = useState([]);
    //大包模式
    const [bagMode, setBagMode] = useState([])
    //计算方式
    const [chargeMode, setChargeMode] = useState([])
    //签约经理
    const [managerlist, setMangerlist] = useState([])
    //签约机构
    const [signOrganlist, setSignOrganlist] = useState([])
    //默认机构
    const [defaultOrang, setDefaultOrang] = useState([])
    //签约实体
    const [signBusineslist, setSignBusineslist] = useState([])
    const [size, setSize] = useState('基础信息');



    useEffect(() => {
        signTypeList()
        bigBagModeList()
        chargeModeList()
        managerList('')
        signBusinessLsts('')
        signOrganLists('')
        if ((mode == 'edit' || mode === 'look') && !editFormVal.id) {
            getdedtailsOne(rowCode, mode)
        } else if (mode == 'add') {
            setAddFormVal({ ...addFormVal, signOrganId: organId, signBusinessEntityId: '61ad0aefb1f86fdc23253ba5bf77aaf0' })  //所属实体默认云豪车服
        }
        return () => {
            updateFormVal(mode)
            if (mode === 'edit') {
                setEditSelect([])
            } else if (mode === 'add') {
                setAddSelect([])
            } else if (mode === 'look') {
                setLookSelect([])
            }
        }
    }, [mode])


    //切换页签
    const ChangeList = (value) => {
        setSize(value);
    };


    //取消
    const goBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "contractParticulars",
        })
    }
    //重置
    const reset = () => {
        if (size === '基础信息') {
            formRef.current.resetFields()
            if (mode === "add") {
                updateFormVal(mode)
                message.success("重置成功")
            }
            else if (mode === "edit") {
                getdedtailsOne(rowCode, mode).then(res => {
                    message.success("重置成功")
                })
            }
        } else {
            if (mode === "add") {
                updateFormVal(mode)
                message.success("重置成功")
            }
            else if (mode === "edit") {
                getdedtailsOne(rowCode, mode).then(res => {
                    message.success("重置成功")
                })
            }
        }

    }


    //表单提交
    const onSubmit = debounce(async () => {
        try {
            if (!formVal.customerId) {
                message.warning('请输入客户名称')
                return false
            }
            if (!formVal.signStartTime) {
                message.warning('请输入签约日期')
                return false
            }
            if (!formVal.signEndTime) {
                message.warning('请输入截至日期')
                return false
            }
            setSaveBtnLoading(true);

            let vechieldata = moment(formVal.signStartTime).format("YYYY-MM-DD HH:mm:ss")
            let { signCode, remark, signManager, contractCode, billMethod, billDate, signMileage, bigBagMode, customerId, signType, signBusinessEntityId, signOrganId } = formVal
            let data = {
                organId,
                remark,
                signBusinessEntityId, signOrganId,
                id: mode === 'add' ? '' : rowCode,
                signType, contractCode, billMethod, billDate, signMileage, bigBagMode, customerId,
                signStartTime: moment(formVal.signStartTime).format("YYYY-MM-DD 00:00:00"),
                signEndTime: moment(formVal.signEndTime).format("YYYY-MM-DD 23:59:59"),
                attachment: formVal.attachment.map(item => item.md5).join(),
                signCode,
                signManager,
                billDate: 25,

                //车辆信息取自车辆列表
                vehicleList: mode === 'edit' ? editSelect.map(({ vehicleId, signStartTime, billPrice, signMileage, mileageCurrent, mileageLast }) => ({
                    vehicleId,
                    billPrice, signMileage, mileageCurrent, mileageLast,
                    signStartTime: signStartTime === null ? vechieldata : moment(signStartTime).format("YYYY-MM-DD HH:mm:ss"),
                })) : addSelect.map(({ vehicleId, signStartTime, billPrice, signMileage, mileageCurrent, mileageLast }) => ({
                    vehicleId, billPrice, signMileage, mileageCurrent, mileageLast,
                    signStartTime: signStartTime === null ? vechieldata : moment(signStartTime).format("YYYY-MM-DD HH:mm:ss")
                }))

            }
            if (data.vehicleList.length === 0) {
                message.warning('请到车辆信息添加车辆在进行保存')
                setSaveBtnLoading(false);
                return false
            }
            if (mode === 'add') {
                contractService.insert(data).then((res) => {
                    if (res.retMsg === '操作成功') {
                        message.success('新增成功') && goBack()
                        setSaveBtnLoading(false);
                    }
                })
            }
            if (mode === 'edit')
                contractService.update(data).then((res) => {
                    if (res.retMsg === '操作成功') {

                        message.success('编辑成功') && goBack()
                        setSaveBtnLoading(false);
                    }

                })
        }
        catch (error) {
            setSaveBtnLoading(false);
            console.log(error)
        }
    }, 500)


    const onChange = (changedValues) => {
        let newChangedValues = { ...changedValues };
        if (changedValues.signType === '046001') {
            updateFormVal(mode, { ...newChangedValues, billDate: '每月25号' });
        } else if (changedValues.signType === '046002') {
            newChangedValues.billMethod = '';
            newChangedValues.bigBagMode = '';
            newChangedValues.billDate = '每月25号'
        }
        else if (changedValues.bigBagMode === '047002' || changedValues.bigBagMode === '047001') {
            newChangedValues.billMethod = '048001';
        } else if (changedValues.bigBagMode === '047005') {
            newChangedValues.billMethod = '048005';
        } else if (changedValues.bigBagMode === '047003' || changedValues.bigBagMode === '047004') {
            newChangedValues.billMethod = '';
        }
        if (changedValues.signEndTime) {
            const signStartTime = moment(formVal.signStartTime);
            const signEndTime = moment(changedValues.signEndTime);
            if (signEndTime.isBefore(signStartTime)) {
                // 截止时间小于签约时间，提示重新填写并置空截止时间
                message.warning('截止时间不能早于签约时间，请重新填写');
                newChangedValues.signEndTime = undefined;
            }

        }

        updateFormVal(mode, newChangedValues);
    };

    const customerList = async (value) => {
        let data = { pageSize: 15, currentPage: 1, searchItem: { customerName: value } }
        return getCustomerListNew(data).then(res => {
            const { retData: { list } } = res;
            return list ? list.map(item => ({
                key: item.id,
                id: item.id,
                label: item.customerName,
                value: item.customerName,
                customerId: item.id,
                customerName: item.customerName,
                customerCorporation: item.customerCorporation,//联系人
                customerCorporationPhone: item.customerCorporationPhone,//联系电话
                organName: item.organId,//所属机构
                ciBusinessEntityName: item.ciBusinessEntityName,   //所属实体
                teamId: item.teamName,  //所属团队
                statistician: item.statistician,   //所属统计
                customerAddress: item.customerAddress,   //联系地址
                businessManager: item.businessManager,  //业务经理
                referrerType: item.referrerType,  //推荐人类型
                referrerName: item.referrerName,  //推荐人
            })) : [];

        })
    }
    //签约经理
    const managerList = async (value) => {
        let data = { pageNum: 1, pageSize: 15, searchItem: { nickname: value, } }
        return platformUserList(data).then((res) => {
            const { retData: { list } } = res;
            setMangerlist(list ? list.map(item => {
                return {
                    key: item.id,
                    label: item.nickname,
                    value: item.id,
                }
            }) : []
            )
        })
    }

    //签约实体
    const signBusinessLsts = async (value) => {
        let data = { searchItem: { entityName: value, }, pageNum: 1, pageSize: 10 }
        return getBussinessEntityList(data).then(res => {
            const { retData: { list } } = res
            setSignBusineslist(list ? list.map(item => {
                return {
                    key: item.id,
                    label: item.entityName,
                    value: item.id,
                }
            }) : [])
        })
    }
    //签约机构
    const signOrganLists = async (value) => {
        return commonService.orgPageList({}).then(res => {
            const { retData } = res
            setDefaultOrang(retData)
            setSignOrganlist(retData ? retData.map(item => {
                return {
                    key: item.id,
                    label: item.organName,
                    value: item.id,
                    ciBusinessEntityName: item.ciBusinessEntityName
                }
            }) : [])
        })
    }


    //客户选中  回显联系人、电话
    const customerChange = (value, option) => {
        if (option && option.id) {
            let { id, customerId, businessManager, referrerType, referrerName, customerCorporation, customerCorporationPhone, organId, ciBusinessEntityName, teamId, statistician, customerAddress } = option
            setCustomerid(customerId)
            getCustomerOne(id).then(res => {
                let { retData } = res
                updateFormVal(mode, {
                    customerId: retData.id,
                    referrerName: retData.referrerName,
                    referrerType: retData.referrerType,
                    businessManager: retData.businessManagerName,
                    customerCorporation,//联系人
                    customerCorporationPhone,//联系电话
                    customerAddress,
                    statistician: retData.statisticianName,
                    teamId: retData.teamName,
                    ciBusinessEntityName: retData.ciBusinessEntityName,
                    organName: retData.organName,

                })
            })

        }
    }

    //签约经理选中  
    const managerrChange = (value, option) => {
        if (option && option.id) {
            let { label } = option
            updateFormVal(mode, {
                signManager: label
            })
        }
    }
    //签约实体选中  
    const signBusinessChange = (value, option) => {
        if (option && option.id) {
            let { label } = option
            updateFormVal(mode, {
                signManager: label
            })
        }
    }
    //签约机构选中  
    const signOrganChange = (value, option) => {
        if (option && option.id) {
            let { label } = option
            updateFormVal(mode, {
                signManager: label
            })
        }
    }

    //签约类型
    const signTypeList = async () => {
        await setnewList(getPcodeDict('046').children.map(item => {
            return ({ label: item.title, value: item.dictCode, key: item.id })
        }))
    }
    //大包模式
    const bigBagModeList = async () => {
        await setBagMode(getPcodeDict('047').children.map(item => {
            return ({ label: item.title, value: item.dictCode, key: item.id })
        }))
    }
    //计算里程
    const chargeModeList = async () => {
        await setChargeMode(getPcodeDict('048').children.map(item => {
            return ({ label: item.title, value: item.dictCode, key: item.id })
        }))
    }

    //里程对账单-工单列表
    const openWorkerOrder = async (serachVal = serachworke) => {
        try {
            let data = {
                pageSize: 10,
                pageNum: 1,
                endTime: '',
                startTime: '',
                orders: [{
                    signId: rowCode,
                    customerId: editFormVal.id ?? lookFormVal.id,
                }],
                searchItem: {
                    signId: rowCode,
                    customerId: editFormVal.id ?? lookFormVal.id,
                    ...serachVal
                }
            }
            contractService.workOrder(data).then(res => {
                let { retData: { list, ...pg } } = res
                setworkOrderData(list)
                setPgCfgwork({
                    total: pg.total,
                    pageSize: pg.pageSize,
                    current: pg.pageNum
                })
            })
            contractService.workOrderTotal(data).then(res => {
                let { retData } = res
                setDescTotalwork(retData)
            })
        } catch (error) {
            console.log(error)
        }

    }





    const action = (
        <>
            {mode !== "look" && (
                <Button
                    className="btn-item"
                    shape="round"
                    onClick={onSubmit}
                    loading={saveBtnLoading}
                >
                    {saveBtnLoading ? '保存中' : '保存'}
                </Button>
            )}
            {mode !== "look" && (
                <Button className="btn-item" shape="round" onClick={() => { reset() }}
                >
                    重置
                </Button>
            )}
            <Button className="btn-item" shape="round" onClick={
                () => {
                    goBack();
                    updateFormVal(mode,);
                    if (mode === 'add') {
                        setAddSelect([])
                    } if (mode === 'edit') {
                        setEditSelect([])
                    } else { setLookSelect([]) }
                }}>
                返回
            </Button>
        </>
    )
    const baseFormItem = {
        title: <div><span>基础信息</span></div>,
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [

            {
                type: "Input",
                fieldLabel: "合约单号",
                fieldName: "signCode",
                disable: true,
                placeholder: "自动生成",
            },
            {
                type: "Input",
                fieldLabel: "客户id",
                fieldName: "customerId",
                disable: true,
                placeholder: "自动生成",
                hidden: true
            },
            {
                type: 'AsyncSelect',
                fieldLabel: '客户名称',
                fieldName: 'customerName',
                allowClear: true,
                showSearch: true,
                onSelectChange: customerChange,
                placeholder: '客户名称',
                rules: [{ required: true, message: '请选择客户' }],
                selectfetchList: customerList,
                defaultQuery: formVal.customerName,
                disable: mode === 'look'
            },
            {
                type: "Input",
                fieldLabel: "联系人",
                fieldName: "customerCorporation",
                disable: true,
                placeholder: "自动带出",
            },
            {
                type: "Input",
                fieldLabel: "联系电话",
                fieldName: "customerCorporationPhone",
                disable: true,
                placeholder: "自动带出",
            }, {
                type: "Select",
                fieldLabel: "所属机构",
                fieldName: "organName",
                disable: true,
                placeholder: "自动带出",
            }, {
                type: "Select",
                fieldLabel: "所属实体",
                fieldName: "ciBusinessEntityName",
                disable: true,
                placeholder: "自动带出",
            }, {
                type: "Input",
                fieldLabel: "所属团队",
                fieldName: "teamId",
                disable: true,
                placeholder: "自动带出",

            }, {
                type: "Select",
                fieldLabel: "所属统计",
                fieldName: "statistician",
                disable: true,
                placeholder: "自动带出",
            },

            {
                type: "Input",
                fieldLabel: "联系地址",
                fieldName: "customerAddress",
                disable: true,
                placeholder: "自动带出",
            }, {
                type: "Input",
                fieldLabel: "业务经理",
                fieldName: "businessManager",
                disable: true,
                placeholder: "自动带出",
            }, {
                type: "Input",
                fieldLabel: "推荐人类型",
                fieldName: "referrerType",
                disable: true,
                placeholder: "自动带出",
            }, {
                type: "Input",
                fieldLabel: "推荐人",
                fieldName: "referrerName",
                disable: true,
                placeholder: "自动带出",
            },

        ]
    }

    const signFormItem = {
        title: <div><span>签约信息</span> </div>,
        style: { padding: "0 20px", marginBottom: "10px" },
        disabled: mode == 'edit' && editFormVal.signOrganId !== organId && editFormVal.signOrganId !== '' || mode === 'look',
        items: [
            {
                type: "Select",
                fieldLabel: "签约类型",
                fieldName: "signType",
                placeholder: "签约类型",
                options: newList,
            },
            {
                type: "Select",
                fieldLabel: "大包模式",
                fieldName: "bigBagMode",
                placeholder: "大包模式",
                disable: formVal.signType === '046002' || mode === 'look' || (mode == 'edit' && editFormVal.signOrganId !== organId && editFormVal.signOrganId !== ''),
                options: bagMode,
                onSelectChange: (value, option) => {
                    if (option && option.value) {
                        updateFormVal(mode, { signType: '046001' })
                    }
                }
            },
            {
                type: "Select",
                fieldLabel: "计费方式",
                fieldName: "billMethod",
                placeholder: "计费方式",
                options: chargeMode,
                disable: formVal.signType === '046002' || mode === 'look' || (mode == 'edit' && editFormVal.signOrganId !== organId && editFormVal.signOrganId !== ''),
            },

            {
                type: "Input",
                fieldLabel: "出账日期",
                fieldName: "billDate",
                placeholder: "出账日期",
                disable: true
            },
            {
                type: "Datepicker",
                fieldLabel: "签约日期",
                fieldName: "signStartTime",
                rules: [{ required: true, message: '请选择签约日期·' }],
                placeholder: "签约日期",
                format: "YYYY-MM-DD",
                showTime: false,
            },
            {
                type: "Datepicker",
                fieldLabel: "截止日期",
                fieldName: "signEndTime",
                rules: [{ required: true, message: '请选择截止日期' }],
                placeholder: "截止日期",
                format: "YYYY-MM-DD",
                showTime: false,
            },
            {
                type: "Input",
                fieldLabel: "签约经理",
                fieldName: "signManager",
                placeholder: "签约经理",
                // options:managerlist,
                // allowClear: true,
                // showSearch: true,
                // onSelectChange: managerrChange,
            },
            {
                type: "Select",
                fieldLabel: "签约机构",
                fieldName: "signOrganId",
                placeholder: "签约机构",
                options: signOrganlist,
                allowClear: true,
                showSearch: true,
                onSelectChange: signOrganChange,
                disable: mode === 'look'
            },
            {
                type: "Select",
                fieldLabel: "签约实体",
                fieldName: "signBusinessEntityId",
                placeholder: "签约实体",
                options: signBusineslist,
                allowClear: true,
                showSearch: true,
                onSelectChange: signBusinessChange,
                rules: [{ required: true, message: '请选择签约实体' }],
            },
            {
                type: "Input",
                fieldLabel: "协议单号",
                fieldName: "contractCode",
                placeholder: "协议单号",
            },
            {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "remark",
                placeholder: "备注",
            },
            {
                type: 'FormUploads',
                fieldLabel: '附件',
                fieldName: 'attachment',
                fieldMd5: 'md5',
                md5List: formVal.attachment ?? [],
                onSetFieldsValue: onChange,
            }



        ]

    }

    const contractFromConfig = {
        formRef: formRef,
        labelCol: { span: 6 },
        disabled: mode === "look",
        labelAlign: "right",
        formValues: { ...formVal },
        onChange,
        boxs: [baseFormItem, signFormItem],
    }

    return (
        <>
            <YhBox title={<div>
                <span style={{ marginRight: '40px' }}>{`合同单号:${mode === 'edit' || mode === 'look' ? formVal.signCode : ''}`}</span>
                <span style={{ marginRight: '40px' }}>{`客户名称：${mode === 'edit' || mode === 'look' ? formVal.customerName : ''}`}</span>
                <span>{`签约类型：${formVal.signType === '046001' ? '大包' : formVal.signType === '046002' ? '月结' : ''}`}</span>
            </div>}
                action={action} style={{ padding: "0 20px", marginBottom: "10px" }}>
            </YhBox>
            <YhBox >
                <div style={{ display: 'flex', paddingTop: '10px', paddingLeft: '12px' }}>
                    <div style={{ flexGrow: ' 1' }}>
                        <Radio.Group value={size} style={{ marginBottom: 16 }} onChange={(e) => { ChangeList(e.target.value) }}>
                            <Radio.Button value="基础信息">基础信息</Radio.Button>
                            <Radio.Button value="车辆信息">车辆信息</Radio.Button>
                            {(formVal.billMethod === '048001') && mode !== 'add' && <Radio.Button value="工单信息" onClick={() => { openWorkerOrder() }}>工单信息</Radio.Button>}
                            {mode !== 'add' && <Radio.Button value="对账单信息" onClick={() => {
                                if (formVal.billMethod === '048001') {
                                    mileageOrrder()
                                } else {
                                    contractPage()
                                }
                            }
                            }
                            >对账单信息</Radio.Button>}
                        </Radio.Group>
                    </div>
                    {(formVal.billMethod === '048001') && size === '对账单信息' && <div style={{ flexShrink: '0' }}><Button className="btn-item" shape="round" onClick={() => { onExportMeilage() }} disabled={billmileageData.length == 0 || mode == 'add'}>导出</Button></div>}
                    {size === '工单信息' && <div style={{ flexShrink: '0' }}><Button className="btn-item" shape="round" onClick={() => { onExportWorker() }} disabled={mode == 'add'}>导出</Button></div>}
                    {!(formVal.billMethod === '048001') && size === '对账单信息' && <div style={{ flexShrink: '0' }}><Button className="btn-item" shape="round" onClick={() => { onExportOrder() }} disabled={mode == 'add'}>导出</Button></div>}
                </div>

                <div>
                    {size === '基础信息' &&
                        <YhFormBox {...contractFromConfig} />
                    }
                    {size === '车辆信息' && <TableBox mode={mode} rowCode={rowCode} />}
                    {size === '工单信息' && <WorkOrderInfoTable />}
                    {size === '对账单信息' && <MileorderTable mode={mode} />}
                </div>
            </YhBox>



        </>
    )
}