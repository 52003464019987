import React, { useState, useEffect } from "react"
import { Table, Modal } from "antd"
import { customerVehicleOrder } from "@/service/customerService"
import OrderDateil from "./orderDetail"
import moment from "moment"

const VehicleList = (props) => {
    const { vehicleMoadl, setVehicleMoadl, param } = props
    const [loading, setLoading] = useState(false)
    const [detailVisible, setDetailVisible] = useState(false)
    const [orderId, setOrderId] = useState("")
    const [data, setData] = useState({}) //列表
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    useEffect(() => {
        loadData(1)
    }, [])
    //table配置

    //默认加载
    const loadData = (current) => {
        setLoading(true)
        let data = {
            searchItem: {
                vehicleCode: vehicleMoadl.vehicle.vehicleCode,
            },
            pageNum: current,
            pageSize: 10,
            startTime: moment(param.startTime).format("YYYY-MM-DD") + "T00:00:00",
            endTime: moment(param.endTime).format("YYYY-MM-DD") + "T23:59:59",
        }
        customerVehicleOrder(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setData(res.retData)
            })
            .catch((err) => {
                setData([])
            })
            .finally(() => {
                setLoading(false)
            })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    const columns = [
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
            render: (text, record) => (
                <div
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        setOrderId(record.orderId)
                        setDetailVisible(true)
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: "工单类型",
            align: "center",
            dataIndex: "orderType",
        },
        {
            title: "行驶里程",
            align: "center",
            dataIndex: "mileage",
        },
        {
            title: "故障描述",
            align: "center",
            dataIndex: "faultDesc",
            width: "220px"
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "workHoursAmount",
        },
        {
            title: "材料费",
            align: "center",
            dataIndex: "partAmount",
        },
        {
            title: "材料成本",
            align: "center",
            dataIndex: "materialCost",
        },
        {
            title: "工单实收金额",
            align: "center",
            dataIndex: "actualAmount",
        },
        {
            title: "完工日期",
            align: "center",
            dataIndex: "orderCompletedTime",
        },
    ]

    return (
        <Modal title="维修历史记录" visible={vehicleMoadl.visible} width={1200} footer={null} onCancel={() => setVehicleMoadl({ vehicle: {}, visible: false })}>
            <div style={{ fontSize: "16px", fontWeight: "600" }}>车辆牌照：{vehicleMoadl.vehicle.vehicleCode}</div>
            <Table
                style={{ marginTop: "30px" }}
                size="small"
                columns={columns}
                dataSource={data.list}
                rowKey={(record) => record.orderCode}
                loading={loading}
                onChange={onTableChange}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: false,
                    showTotal: () => `共${data.total}条`,
                    position: ["bottomCenter"],
                    ...pagination,
                }}
            />
            {detailVisible && <OrderDateil orderId={orderId} visible={detailVisible} setVisible={setDetailVisible} />}
        </Modal>
    )
}
export default VehicleList
