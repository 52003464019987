import React, { useEffect } from "react";
import { Table, Button } from "antd";
import { useJxcModel } from "../jxcModel";


export function DataTable1() {
    const {
        purchasePinData, selectedRow, pinPgCfg,
        loadMaterialData, exportJxcPin
    } = useJxcModel();

    useEffect(() => {
        loadMaterialData();
    }, [selectedRow])

    const columns = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '从属门店',
            fixed: "left",
            width: 160,
            dataIndex: 'shopName',
        }, {
            title: '仓库',
            fixed: "left",
            width: 140,
            dataIndex: 'warehouseName',
        }, {
            title: '出入库日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'tradeTypeName',
        }, {
            title: '对方单位',
            width: 230,
            dataIndex: 'name',
        }, {
            title: '单号',
            width: 180,
            dataIndex: 'tradeCode',
        }, {
            title: '货位',
            width: 140,
            dataIndex: 'shelfCode',
        }, {
            title: '数量',
            width: 100,
            align: "right",
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            align: "right",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '金额',
            width: 120,
            dataIndex: 'amount',
            align: "right",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '成本',
            width: 120,
            dataIndex: 'cost',
            align: "right",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '毛利',
            width: 120,
            dataIndex: 'profit',
            align: "right",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '数量余额',
            width: 120,
            dataIndex: 'numQuantity',
            align: "right",
        }, {
            title: '金额余额',
            width: 100,
            dataIndex: 'priceQuantity',
            align: "right",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            //     title: '优惠类型',
            //     width: 120,
            //     dataIndex: 'preferentialTypeName',
            // }, {
            title: '业务员',
            width: 120,
            dataIndex: 'createUser',
        }
    ];

    return (
        <>
            <Button
                className="btn-item"
                style={{ margin: "0px 0px 5px 15px" }}
                type="link"
                shape="round"
                onClick={() => exportJxcPin()}
            >导出详情</Button>
            <div className="record-warp">
                <div className="record-item">
                    <label>商品编号:</label>
                    <div className="f1">
                        <span className="text">{selectedRow[0]?.materialCode}</span>
                    </div>
                </div>
                <div className="record-item">
                    <label>商品名称:</label>
                    <div className="f1">
                        <span className="text">{selectedRow[0]?.materialName}</span>
                    </div>
                </div>
                <div className="record-item">
                    <label>商品类型:</label>
                    <div className="f1">
                        <span className="text">{selectedRow[0]?.typeName}</span>
                    </div>
                </div>
                {/* <div className="record-item">
                    <label style={{ width: "90px" }}>发动机型号:</label>
                    <div className="f1">
                        <span className="text">{}</span>
                    </div>
                </div> */}
                <div className="record-item">
                    <label>替换编号:</label>
                    <div className="f1">
                        <span className="text">{selectedRow[0]?.replaceCode}</span>
                    </div>
                </div>
            </div>
            <Table
                // rowKey={record => record.id}
                columns={columns}
                dataSource={purchasePinData}
                pagination={false}
                scroll={purchasePinData.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
            />
        </>

    )
}