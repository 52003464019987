import React, { useState, useEffect } from "react"
import { Button, Rate } from "antd"
import PubDrawer from "@/components/Drawer"
import { orderLookComment, orderLookPayment } from "@/service/ordersService"
import { fileLookUrl } from "@/config/app.js"
const payTypeObj = { auth_quota: "授权额度", cash: "现金", three_guarantees: "三包", wechart_pay: "微信支付" }
const LookCommentAndPayment = (props) => {
    const { lookVisible, onLookClose, orderInfo } = props
    const [commentInfo, setCommentInfo] = useState(null) //用户评价信息
    const [paymengtInfo, setPaymentInfo] = useState(null) //支付信息
    useEffect(() => {
        if (lookVisible.visible) {
            isMode()
        } else {
            setCommentInfo(null)
            setPaymentInfo(null)
        }
    }, [lookVisible])
    //是评价还是支付信息
    const isMode = () => {
        let data = {
            pageSize: 100,
            searchItem: {
                orderId: orderInfo.id,
            },
        }
        if (lookVisible.taskMode === "comment") {
            orderLookComment(data).then((res) => {
                // if (res && res.retCode === 200) {
                let results = res.retData.list.length > 0 ? res.retData.list[0] : null
                setCommentInfo(results)
                // }
            })
            // .catch((err) => {})
        } else {
            orderLookPayment(data).then((res) => {
                // if (res && res.retCode === 200) {
                let results = res.retData.list.length > 0 ? res.retData.list : null
                setPaymentInfo(results)
                // }
            })
            // .catch((err) => {})
        }
    }
    //随机长生个颜色

    const getColor = () => {
        //随机产生一个十六进制的值
        return (
            "#" +
            "0123456789abcdef"
                .split("")
                .map(function (v, i, a) {
                    return i > 5 ? null : a[Math.floor(Math.random() * 16)]
                })
                .join("")
        )
    }
    return (
        <PubDrawer visible={lookVisible.visible} onClose={onLookClose} width="400px">
            <div className="team-drawer-title">
                <span>{lookVisible.taskMode === "comment" ? "客户评价" : "支付信息"}</span>
            </div>
            <div className="team-drawer-content">
                <div className="team-drawer-header">
                    {/* <span style={{marginRight:"12px"}}>客户名称：{orderInfo.customerName}</span> */}
                    <span>用户名称:{orderInfo.customerUserName}</span>
                </div>
                {lookVisible.taskMode === "comment" ? (
                    <>
                        {commentInfo ? (
                            <div style={{ padding: "20px 15px" }}>
                                <span>用户评分：</span>
                                <Rate disabled allowHalf defaultValue={commentInfo.commentScore / 2} />
                                <div>评价时间：{commentInfo.createTime}</div>
                                <div>
                                    已选标签：
                                    {commentInfo.commentTags &&
                                        commentInfo.commentTags.split(",").map((item) => (
                                            <span
                                                key={item}
                                                className="comment-tags"
                                                style={{
                                                    background: getColor(),
                                                }}
                                            >
                                                {item}
                                            </span>
                                        ))}
                                </div>
                                <div>
                                    问题描述：
                                    {commentInfo.problemDesc ? commentInfo.problemDesc : "暂无描述"}
                                </div>
                                <div>
                                    <div>评价图片：</div>
                                    {commentInfo.commentImgs ? (
                                        commentInfo.commentImgs.split(",").map((item) => {
                                            return (
                                                <div className="lookImg">
                                                    <img
                                                        src={`${fileLookUrl().image}${item}`}
                                                        alt=""
                                                        key={item}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <>{"暂无图片"}</>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>{"暂无评价"}</>
                        )}
                    </>
                ) : (
                    <>
                        {paymengtInfo ? (
                            paymengtInfo.map((item) => {
                                return ( <div style={{ padding: "20px 15px" }}>
                                <div style={{fontSize: '16px', fontWeight:"bold", marginTop:"20rpx"}}>{item.payOrderType === "prepay" ? "预支付" : "尾款"}</div>
                                <div>支付时间：{item.createTime}</div>
                                <div>支付方式：{item.payType ? payTypeObj[item.payType] : ""}</div>
                                <div>
                                    支付状态：
                                    {item.payData === "unpaid" ? "未支付" : "已支付"}
                                </div>
                                <div>
                                    支付金额：
                                    {item.payAmount ? item.payAmount : "无"}
                                </div>
                                <div>备注：{item.remark}</div>
                            </div>)
                            })
                        ) : (
                            <div>暂无支付信息</div>
                        )}
                    </>
                )}
                <div className="team-form-footer">
                    <Button onClick={onLookClose} className="team-but-one">
                        确定
                    </Button>
                </div>
            </div>
        </PubDrawer>
    )
}
export default LookCommentAndPayment
