import {
    post,
    get
} from "../utils/request" // 分类列表
export const operaLogPage = (data) => {
    return post("/ci-platform/opera-log/page", data)
}
// 分类获取一条数据
export const operaLogOne = (id) => {
    return get(`/ci-platform/opera-log/one/${id}`)
}