import React from "react";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailModel";
import { formatNum } from "@/erp_subpackage/utils/util";

export const FixedBottom = ({ modeKey, mode, rowCode, dispatch }) => {
    const { collapsed } = useGlobalModel(({ collapsed }) => [collapsed]);
    const { addDesc, editDesc, lookDesc } = usePurchaseRoDetailsModel();
    const typeDesc = {
        add: addDesc,
        edit: editDesc,
        look: lookDesc
    }
    const orderStatistics = [
        {
            label: "退货总量",
            value: typeDesc[mode].numAll,
            color: "red",
            status: true
        },
        {
            label: "退货总金额",
            value: formatNum(typeDesc[mode].totalAll),
            color: "red",
            status: true
        },
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${collapsed ? '80px' : '200px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}