import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { vehicleBrandnamePage, vehicleBrandnameDelete, vehicleBrandnameDeleteSecond } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import Detail2 from "./detail2"
import { Modal, Badge, Popconfirm } from "antd"
import { CloseCircleOutlined } from '@ant-design/icons'

export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showVisible2, setShowVisible2] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "vehicleBrandname") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search, "brandLevel": 1 },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        vehicleBrandnamePage(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
        {
            click: () => handleDetails2("insert"),
            content: "添加二级车系",
        },
    ]

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的车辆品牌信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除该车辆品牌?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                vehicleBrandnameDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的车辆品牌")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的车辆品牌")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //新增二级品牌
    const handleDetails2 = async (mode) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择记录")
            return
        }
        setShowVisible2(true)
    }

    //确认删除二级车辆品牌
    const deleteChild = async (parentId, brandName) => {
        Modal.confirm({
            title: '确定要删除该二级车辆品牌?',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                vehicleBrandnameDeleteSecond(parentId, brandName).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
            onCancel: () => { }
        })
    }

    //table配置
    const columns = [
        {
            title: '序号',
            width: 50,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "品牌名称",
            width: 120,
            align: "center",
            dataIndex: "brandName",
        },
        {
            title: "二级车系",
            align: "left",
            dataIndex: "childrens",
            render: (t, r, i) => {
                if (t != null) {
                    let arr = t.split(",");
                    return arr.map(item => {
                        return <Badge count={<CloseCircleOutlined style={{ color: '#f5222d' }} onClick={(e) => deleteChild(r.id, item)} />}>
                            <span style={{ paddingRight: "6px", marginLeft: "12px" }}>{item}</span>
                        </Badge>
                    });
                }
                else {
                    return "";
                }
            }
        },
        {
            title: "添加时间",
            width: 160,
            align: "center",
            dataIndex: "createTime",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "品牌名称",
                valueName: "brandName",
            },
        ],
    }

    return (
        <>
            <TopSearch
                title=""
                searchListData={searchList}
                searchValue={searchValue}
                setSearchValue={(obj) => setSearchValue(obj)}
                handleSearch={loadData}
            />
            <ListComponent
                title="车辆品牌"
                buttons={topButtons}
                selectInfo={selectInfo}
                columns={columns}
                scroll={1500}
                dataSource={list}
                pagination={pagination}
                onTableChange={onTableChange}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />

            {showVisible && (
                <Detail
                    rowInfo={selectInfo.rowInfo}
                    visible={showVisible}
                    mode={showMode}
                    setVisible={setShowVisible}
                    onSuccess={() => {
                        loadData(1, searchValue)
                    }}
                />
            )}

            {showVisible2 && (
                <Detail2
                    rowInfo={selectInfo.rowInfo}
                    visible={showVisible2}
                    mode={showMode}
                    setVisible={setShowVisible2}
                    onSuccess={() => {
                        loadData(1, searchValue)
                    }}
                />
            )}
        </>
    )
}
