import React from "react";
import { DatePicker } from "antd";
import moment from "moment";


const { RangePicker } = DatePicker;
export const SearchRangePicker = ({ searchValues, rangePickerFieldName, ...props }) => {

    const fieldName = rangePickerFieldName ?? ["startTime", "endTime"];

    const handleStrLength = (str) => {
        if (!str) return false;
        return str.length > 10 ? false : true;
    }

    return <RangePicker
        size={props.size}
        showTime={props.showTime}
        placeholder={props.placeholder}
        className="yh-search-input"
        format={props.format || "YYYY-MM-DD HH:mm:ss"}
        value={
            !searchValues?.[fieldName[0]] || !searchValues?.[fieldName[1]] || handleStrLength(searchValues?.[fieldName[0]]) ?
                null :
                [moment(searchValues?.[fieldName[0]]), moment(searchValues?.[fieldName[1]])]
        }
        onChange={(value, timeStr) => {
            let obj = {
                [fieldName[0]]: props.format ? timeStr[0].length === 10 ? timeStr[0] + ' 00:00:00' : "" : timeStr[0],
                [fieldName[1]]: props.format ? timeStr[1].length === 10 ? timeStr[1] + ' 23:59:59' : "" : timeStr[1]
            }
            props.onChange && props.onChange(obj);
            if (props.triggerSearch) {
                props.handleSearch && props.handleSearch(obj);
            }
        }}
        disabled={props.disabled} />;
}