import React, { useEffect, useState } from "react"
import { Button, Input, Modal, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useSaleRoDetailModel } from "./saleRoDetailModel"
import { Description } from "@/erp_subpackage/components/YhBox/Description"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
const { TextArea } = Input

export const TopButs = ({ modeKey, mode, rowId, dispatch }) => {
    const {
        setSettleVisible,
        setWsConfirmModal,
        onExamine,
        getDetailEditOne,
        warehousing,
        detailDataSource,
        detailLookList,
        detailAddForm,
        detailEditForm,
        detailLookForm,
        topButsControl,
    } = useSaleRoDetailModel()
    const { getTreeNodeName } = useDictModel()
    const [checkFlag, setCheckFlag] = useState(false)

    useEffect(() => {
        if (detailEditForm.orderTypeCode === DictCodeEnmu.SALE_ORDER_TYPE_TRANSFER) {
            setCheckFlag(true)
        } else if (detailEditForm.orderTypeCode === DictCodeEnmu.SALE_TYPE_TRANSFER) {
            if (detailEditForm.stockIoStatus === "1") {
                setCheckFlag(false)
            } else {
                setCheckFlag(true)
            }
        } else {
            setCheckFlag(false)
        }
    }, [])

    const onWhConfirm = () => {
        if (detailDataSource.length !== -1 && mode === "edit") {
            setWsConfirmModal(true)
        } else if (detailLookList.length !== -1 && mode === "look") {
            setWsConfirmModal(true)
        } else {
            message.error("请添加商品详情")
        }
    }
    const onSettle = () => {

        setSettleVisible(true)
    }

    // 验收
    const orderExamin = (data) => {
        onExamine(data).then((res) => {
            getDetailEditOne(rowId, mode)
        })
    }

    const onCheck = () => {
        let advise = ""
        Modal.confirm({
            title: "验收审核",
            content: (
                <div style={{ display: "flex" }}>
                    <div style={{ width: "75px" }}>审核备注</div>
                    <TextArea
                        onChange={(e) => {
                            advise = e.target.value
                        }}
                    />
                </div>
            ),
            okText: "验收",
            keyboard: false,
            onOk: () => {
                orderExamin({
                    advise,
                    status: "0",
                    id: detailEditForm.id,
                })
            },
            cancelText: "驳回",
            onCancel: () => {
                orderExamin({
                    advise,
                    status: "1",
                    id: detailEditForm.id,
                })
            },
        })
    }
    const action =
        mode === "edit" ? (
            <>
                <Button className="btn-item" type="link" shape="round" onClick={onCheck} disabled={topButsControl || checkFlag || detailEditForm.status !== DictCodeEnmu.SALE_RETURN_STATUS_CREATE}>
                    验收
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={() => {
                        onWhConfirm()
                    }}
                    disabled={topButsControl || checkFlag || detailEditForm.status !== DictCodeEnmu.SALE_RETURN_STATUS_EXAMINE}
                >
                    库房入库
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={() => {
                        // if (!checkFlag && detailEditForm.status != DictCodeEnmu.SALE_RETURN_STATUS_STOCK_IO) {
                        //     message.warning("请先入库再进行结算 ")
                        //     return
                        // }
                        onSettle()
                    }}
                    disabled={topButsControl || checkFlag || (detailEditForm.status != DictCodeEnmu.SALE_RETURN_STATUS_STOCK_IO && detailEditForm.status != DictCodeEnmu.SALE_RETURN_STATUS_PAYING)}
                >
                    确认结算
                </Button>
            </>
        ) : mode === "look" ? (
            <>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    // disabled={topButsControl}
                    onClick={onSettle}
                >
                    结算查看
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    // disabled={topButsControl}
                    onClick={onWhConfirm}
                >
                    入库查看
                </Button>
            </>
        ) : (
            false
        )

    const typeTitle = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const orderStatu = [
        {
            label: "销售退货单",
            value: typeTitle[mode]?.returnCode ?? "暂无",
            color: "blue",
            status: true,
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status)) ?? "暂无",
            color: "green",
            status: true,
        },
    ]

    return (
        <YhBox
            title={
                <>
                    {orderStatu.map((item, index) => (
                        <Description key={index} {...item} />
                    ))}
                </>
            }
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}
