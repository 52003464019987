import React from "react"
import { TopButs } from "./TopButs"
import { FormBox } from "./FormBox"
import { TableBox } from "./TableBox"

export const StockOutputDetails = ( props ) => {
    return (
        <>
            <TopButs />
            <FormBox {...props} />
            <TableBox />
        </>
    )
}
