import React, { useEffect } from "react"
import { Form, Input, Col, Row, message, Modal, Typography } from "antd"
import { vehicleBrandnameInsert, vehicleBrandnameOne } from "@/service/dataService"

const { Text } = Typography;
export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue({
            brand: rowInfo.brandName
        })
        queryOne()
    }, [])

    //查看编辑回显
    const queryOne = () => {
        if (mode === "look" || mode === "edit") {
            vehicleBrandnameOne(rowInfo.id).then((res) => {
                let results = res.retData
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                "parentId": rowInfo.id,
                "brandLevel": 2,
            }
            vehicleBrandnameInsert(data).then((res) => {
                message.success("新增成功")
                onSuccess && onSuccess()
                handleCancel()
            })
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender open={visible} title="添加二级车系" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="品牌名称">
                                    <Text level={1}>{rowInfo.brandName}</Text>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="二级车系" name="brandName" rules={[{ required: true, message: "请输入品牌名称" }]}>
                                    <Input placeholder="二级车系" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
