import React, { useEffect, useRef, useState } from "react"
import { Button, Form, Input, Col, Row, Select, message, Spin, TreeSelect, Tree } from "antd"
import { getMenuAll } from "@/service/menuService.js"
import debounce from "lodash/debounce"
import { organPage } from "@/service/orgService"
import { roleInsert, roleUpdate, getRoleOne } from "@/service/permissin"

const { Option } = Select
const Detail = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [organList, setOrganList] = useState({ data: [], fetching: false })
    const [treeData, setTreeData] = useState([])
    const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([])
    const [showTree, setShowTree] = useState(false)
    const treeList = useRef(null)
    const choosedIds = useRef(null)
    useEffect(() => {
        resetFormItem()
        loadParentSelectData()
    }, [])
    //提交
    const materialSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                menuIds: choosedIds.current,
            }
            //新增
            if (mode === "insert") {
                roleInsert(data).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleCancel()
                    message.success("角色新增成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //     }
                // })
                //编辑
            } else if (mode === "edit") {
                roleUpdate({ ...data, id: rowInfo.id }).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleCancel()
                    message.success("角色编辑成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //     }
                // })
            }
        })
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit" || mode === "look") {
            getRoleOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                let ret = res.retData
                form.setFieldsValue({
                    ...ret,
                })
                choosedIds.current = ret.joinMenuIds
                getDefaultKeys(ret.joinMenuIds)
                setOrganList({
                    data: [
                        {
                            text: ret.organName,
                            value: ret.organId,
                        },
                    ],
                    fetching: false,
                })
                // }
            })
        } else {
            setShowTree(true)
        }
    }

    //获取默认的节点
    const getDefaultKeys = (choosedIds) => {
        if (treeList && treeList.current && treeList.current.length > 0) {
            let defaultKeys = []
            const getIds = (data, choosedid) => {
                console.log(data)
                for (var i in data) {
                    if (data[i].id === choosedid) {
                        if (!data[i].children) {
                            defaultKeys.push(choosedid)
                        } else {
                            getIds(data[i].children)
                        }
                    } else if (data[i].children) {
                        getIds(data[i].children, choosedid)
                    }
                }
            }
            choosedIds.forEach((item) => {
                getIds(treeList.current, item)
            })
            setDefaultCheckedKeys(defaultKeys)
            setShowTree(true)
        } else {
            setTimeout(() => {
                getDefaultKeys(choosedIds)
            }, 200)
        }
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "roleManage",
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    //查询机构
    const organQuery = (value) => {
        setOrganList({
            data: [],
            fetching: true,
        })
        let data = {
            searchItem: { organName: value, orderFlag: 0 },
            pageNum: 1,
            pageSize: 10,
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        organPage(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.organName,
                    value: item.id,
                }))
                setOrganList({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning("没有查询到机构")
            }
            // }
        })
        // .catch((err) => {
        //     if (err) {
        //         message.warning("没有查询到机构")
        //     }
        // })
    }
    //防抖
    const organQuerys = debounce(organQuery, 600)

    //回显
    const handleChangeOrgan = (value, option) => {
        if (option) {
            form.setFieldsValue({
                organId: value,
                organName: option.children,
            })
        }
    }

    const toTree = (data, parent) => {
        var tree = []
        var temp
        for (var i = 0; i < data.length; i++) {
            data[i].title = data[i].name
            data[i].key = data[i].id
            if (data[i].parentId == parent) {
                var obj = data[i]
                temp = toTree(data, data[i].id)
                if (temp.length > 0) {
                    obj.children = temp
                }
                tree.push(obj)
            }
        }
        return tree
    }

    // 获取菜单列表
    const loadParentSelectData = () => {
        getMenuAll().then((res) => {
            // if (res && res.retCode === 200) {
            let list = res.retData.list
            list.map((item, index) => {
                if (item.name === "统计分析") {
                    console.log(item)
                    console.log(111)
                }
            })
            if (list) {
                list = toTree(list, null)
                treeList.current = list
                setTreeData(list)
            }
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    //选中树节点
    const onCheck = (checkedKeys, e) => {
        const checkedKeysResult = [...checkedKeys, ...e.halfCheckedKeys]
        choosedIds.current = checkedKeysResult
    }

    return (
        <>
            <div className="toolbar">
                <span className="modules-name">数据管理</span>
            </div>

            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>角色信息</span>
                    </div>
                    <div className="list-but">
                        {!isLook(mode) && (
                            <Button type="primary" shape="round" size="small" className="but-left" onClick={materialSubmit}>
                                保存
                            </Button>
                        )}
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item
                                    label="角色名称"
                                    name="roleName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入角色名称",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入角色名称" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={2} span={9}>
                                <Form.Item label="角色标识" name="roleCode">
                                    <Input placeholder="请输入角色标识" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                <Form.Item label="角色描述" name="roleDesc">
                                    <Input placeholder="请输入角色描述" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col offset={2} span={9}>
                                <Form.Item label="所属机构" name="organId">
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        placeholder="请选择所属机构"
                                        notFoundContent={organList.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => organQuery("")}
                                        onSearch={organQuerys}
                                        onSelect={handleChangeOrgan}
                                        style={{ width: "100%" }}
                                    >
                                        {organList.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={2} span={9}>
                                {showTree ? (
                                    <Tree
                                        defaultCheckedKeys={defaultCheckedKeys}
                                        onCheck={onCheck}
                                        disabled={isLook(mode)}
                                        checkable
                                        treeData={treeData}
                                    ></Tree>
                                ) : (
                                    <></>
                                )}
                            </Col>
                            <Col offset={2} span={9}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="请输入备注" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default Detail
