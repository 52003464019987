import React, { useEffect, useState } from 'react'
import { Modal, Button, Image, Divider, Upload, message } from 'antd'
import { fileLookUrl, fileUploadUrl } from '@/config/app.js'
import { getImageClassifyList } from '@/service/maintenanceService'
import { getOrderImg, OrderDeleteFile, OrderUploadImages } from '@/service/ordersService'
import { getToken } from '@/utils/dataStorage'
import moment from 'moment'
import {  toZip } from '@/utils/util'
import Loading from "@/components/Loading"

export default ({ modalData, setModalData, orderId }) => {
    const [imageClassifyList, setImageClassifyList] = useState([])
    const [refreshImg, setRefreshImg] = useState(false)

    const [imgs, setImageList] = useState('')
    const [loading, setLoading] = useState(false)

    const [preview, setPreview] = useState("")
    const [currentImageIndex, setCurrentImageIndex] = useState()
    const [imgIndex, setImgIndex] = useState()

const handleNextImage = () => { 
    const fileListValues = imageClassifyList
    .map(item => item.fileList)
    .filter(fileList => fileList !== null && fileList !== undefined && fileList.length > 0)
    .flat();
    const img =fileListValues.find((item,index)=>{
        return index=== currentImageIndex +1
    })
    if(currentImageIndex +1 ===fileListValues.length){
        message.info('已经是最后一张图片了')
        return false
    }
    onPreview(img);
  }

  const handlePreviewImage = () => { 
    if(currentImageIndex ===0){
        message.info('已经是第一张图片了')
        return false
    }
    const fileListValues = imageClassifyList
    .map(item => item.fileList)
    .filter(fileList => fileList !== null && fileList !== undefined && fileList.length > 0)
    .flat();
    const img =fileListValues.find((item,index)=>{
        return index===currentImageIndex-1
    })
    onPreview(img);
  }



    const handleImgChange = (info, item) => {
        setLoading(true)
        const { file, fileList } = info
        let imageClass = [...imageClassifyList]
        let index = imageClass.findIndex((i) => i.id === item.id)
        imageClass[index] = { ...item, fileList: fileList }
        setImageClassifyList(imageClass)
        let allUpload = fileList.every((value) => {
            if (value.status !== 'done') {
                return false
            }
            return true //不可省略，否则默认为return false，相当于break
        })
        if (allUpload) {
            const newImgs = fileList.filter((item) => !item.isHas)
            if (newImgs.length > 0) {
                const attachmentMd5s = newImgs.map((i) => i.response.retData)
                OrderUploadImages({ orderId, attachmentType: item.imageCode, attachmentMd5s }).then((res) => {
                    setRefreshImg(true)
                })
            }
        }
        const { isHas, id: fileId, status } = file
        if (status === 'removed' && isHas) {
            OrderDeleteFile(fileId).then((res) => {
                message.success('删除成功')
                setRefreshImg(true)
            })
        }
    }

    const onPreview = (file) => {
        const fileListValues = imageClassifyList
        .map(item => item.fileList)
        .filter(fileList => fileList !== null && fileList !== undefined && fileList.length > 0)
        .flat();
        const imageIndex = fileListValues.findIndex((item)=>item.id===file.id)
        setCurrentImageIndex(imageIndex)
        const { url } = file
        setPreview(url)
        // Modal.success({
        //     title: '图片查看',
        //     content: <img style={{ width: '100%' }} src={url} />,
        //     centered: true,
        //     width: 600,
        //     closable: true,
        //     footer: null
        // })
    }

    useEffect(() => {
        if (modalData.visible) {
            initClassifyData()
        }
    }, [modalData])

    /**
     * 请求图片列表
     */
    const initData = () => {
        let data = {
            pageSize: 100,
            searchItem: {
                orderId,
                imageGroup: modalData.repairMode,
            },
            orders: [],
        }
        getOrderImg(data)
            .then((res) => {
                let imageList = []
                imageClassifyList.forEach((item) => {
                    let images = res.retData.list.filter((i) => i.attachmentType === item.imageCode)
                    let fileList = []
                    if (images.length > 0) {
                        item.file = images.map((ele) => {
                            const { attachmentMd5: MD5 } = ele
                            const url = `${fileLookUrl().image}${MD5}`
                            imageList.push(url)
                            fileList.push({
                                uid: MD5,
                                id: ele.id,
                                status: 'done',
                                url,
                                isHas: true,
                            })
                            return {
                                url,
                                MD5,
                                ...ele,
                            }
                        })
                    }
                    item.fileList = fileList
                })
                setImageClassifyList([...imageClassifyList])
                setImageList(imageList)
                setLoading(false)
            })
            .catch((error) => {
                console.log('error: ', error)
            })
    }

    useEffect(() => {
        if (refreshImg) {
            initData()
            setRefreshImg(false)
        }
    }, [refreshImg])

    const initClassifyData = () => {
        let data = {
            searchItem: {
                imageGroup: modalData.repairMode,
            },
            pageNum: 1,
            pageSize: 100,
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                },
            ],
        }
        getImageClassifyList(data)
            .then((res) => {
                let imageClassifyList = res.retData.list.sort(function (a, b) {
                    return b.required - a.required
                })
                setImageClassifyList(imageClassifyList)
                setRefreshImg(true)
            })
            .catch((error) => {
                console.log('error: ', error)
            })
    }

    const handleDownloadImgs = () => {
        setLoading(true)
        const fileName =  modalData.repairMode === 'repair_img' ? '故障图片' : '维修团队现场图片'
        toZip(imgs,fileName, () => {setLoading(false)})
    }
    const beforeUpload = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const img = document.createElement('img')
                img.src = reader.result
                img.onload = () => {
                    const date = moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
                    const canvas = document.createElement('canvas')
                    canvas.width = img.naturalWidth
                    canvas.height = img.naturalHeight
                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0)
                    ctx.font = '33px Arial'
                    ctx.fillStyle = '#d82007'
                    ctx.textAlign = 'right'
                    ctx.fillText(date, img.naturalWidth - 10, img.naturalHeight - 10)
                    ctx.strokeText(date, img.naturalWidth - 10, img.naturalHeight - 10)
                    canvas.toBlob((result) => resolve(result))
                }
            }
        })
    }

    const onRemove = () => {
        return new Promise((resolve,reject)=>{
            Modal.confirm({
              width:300,
              centered:true,
              title:'',
              content:'确认删除该图片吗',
              onOk:()=>{
                return resolve(true)
              },
              oncancel:()=>{
                return false
              }
            })
          })
    }

    return (
        <Modal
            width={1000}
            bodyStyle={{ height: 660, overflowY: 'auto' }}
            onCancel={() => {
                setModalData({ visible: false, repairMode: '' })
            }}
            visible={modalData.visible}
            title={modalData.repairMode === 'customer' ? '故障图片查看' : '维修团队现场图片查看'}
            footer={null}
        >
            <Button type="default" shape="round" style={{ marginBottom: '10px' }} className="common-btn" onClick={handleDownloadImgs}>
                打包下载
            </Button>
            {imageClassifyList.map((item) => {
                return (
                    <div key={item.id}> 
                        <div>
                            <div>{item.imageName}</div>
                            <Upload
                                action={`${fileUploadUrl}upload/image_old`}
                                headers={{ authorization: getToken() }}
                                listType="picture-card"
                                fileList={item.fileList}
                                onChange={(info) => {
                                    handleImgChange(info, item)
                                }}
                                onPreview={onPreview}
                                beforeUpload={beforeUpload}
                                multiple={true}
                                onRemove={onRemove}
                            >
                                <Button>上传图片</Button>
                            </Upload>
                        </div>
                        <Divider />
                    </div>
                )
            })}
           
           {loading && <Loading />}
           <Modal
                width={1000}
                height={1000}
                onCancel={() => {
                    setPreview('')
                }}
                visible={preview}
                title="图片查看"
                footer={[
                    <Button key="previous" onClick={()=>handlePreviewImage()}>
                      上一张
                    </Button>,
                    <Button key="next" onClick={()=>handleNextImage()} >
                      下一张
                    </Button>,
                  ]}
            > 
                <div style={{display: 'flex',alignItems: 'center',justifyContent:'center'}}>
                <Image style={{height: '850px', maxWidth:'800px',margin:'auto'}} src={preview} />
                </div>
           </Modal>
        </Modal>
    )
}
