import React, { useEffect, useState } from 'react'
import { Modal, Button, Input, message, Col, Row, Form, Select, Table, Checkbox, DatePicker, Tabs } from 'antd'
import { syncOrderBjsgl, getBjsglQalInfo, repairBjsglInfo, getBisglOrderInfo } from '@/service/bjsglService'
import ItemDrawer from './itemDrawer' //项目选择弹框
import moment from 'moment'
import YhTooltip from '@/components/YhTooltip'
const { Option } = Select
const ParintMadal = (props) => {
    const [form] = Form.useForm()
    const { visible, onClose, orderInfo } = props
    const [repairInfo, setRepairInfo] = useState(null) //质保参数查询
    const [readStaffs, setReadStaffs] = useState([]) //修理厂选完后读取维修人员信息
    const [itemVisible, setItemVisible] = useState(false) //项目新增弹框
    const [data, setData] = useState([])
    useEffect(() => {
        console.log(orderInfo)
        //订单信息
        getBisglOrderInfo(orderInfo.id).then((res) => {
            // console.log(res)
            // if (res && res.retCode === 200) {
            let itemName = res.retData.itemNames //项目信息
            let carInfo = res.retData.vehicle //车辆信息
            form.setFieldsValue({
                vehicle_first: carInfo.vehicleCode.charAt(0),
                vehicleid: carInfo.vehicleCode.substring(1, carInfo.vehicleCode.length),
                frame_code: carInfo.vehicleVin,
                engn_code: carInfo.engineCode,
                car_body_color: carInfo.carColor,
                vehicle_num: orderInfo.customerVehicleBrand,
                into_value: orderInfo.customerVehicleMileage,
                out_value: orderInfo.customerVehicleMileage,
                out_date: orderInfo.orderCompletedTime ? moment(orderInfo.orderCompletedTime) : null,
                send_pers: orderInfo.customerUserName, //送修人
                send_phone: orderInfo.repairContactPhone, //送修电话
                in_date: moment(orderInfo.createTime), //进厂时间
                entp_workno: orderInfo.orderCode,
            })
            if (itemName) {
                let newData = itemName.map((item, index) => {
                    return {
                        id: index,
                        itemName: item,
                        p1: '',
                        p2: '',
                        p3: '',
                        p4: '',
                    }
                })
                setData(newData)
            }
            // }
        })
        //质保参数查询
        getBjsglQalInfo().then((res) => {
            // if (res.retCode === 200 && res.retData) {
            setRepairInfo(res.retData)
            // }
        })
    }, [])

    const [savePermitNo, setSavePermitNo] = useState(null) //保存对应质保单号的信息
    //修理厂选择
    const handleRepairChange = (permitNo) => {
        //读取维修人员信息
        repairBjsglInfo(permitNo).then((res) => {
            // console.log(res);
            // if (res.retCode === 200 && res.retData) {
            let findPermitNo = repairInfo.find((item) => item.permitNo === permitNo)
            // console.log(findPermitNo)
            setSavePermitNo(findPermitNo)
            setReadStaffs(res.retData)
            //换修理厂时重置下表单。
            form.resetFields(['qa_name', 'recv_name', 'qal_project', 'recv_name', 'qa_name'])
            // }
        })
        // .catch((err) => {
        //     message.error(err.retMsg)
        // })
    }

    //质保方案选择   回显质保期  和质保里程,  质保截至日期：  进厂日期 + 质保期，   质保截至里程：出厂里程 +质保里程
    const handleQal = (val, option) => {
        if (!form.getFieldValue('out_value')) {
            message.warning('请先输入出厂里程')
            form.resetFields(['qal_time', 'qal_value', 'qal_end_time', 'qal_end_value', 'qal_project'])
            return
        }
        if (val) {
            form.setFieldsValue({
                qal_time: option.time,
                qal_value: option.value,
                qal_end_time: moment(form.getFieldValue('out_date')).add(option.time, 'd'),
                qal_end_value: Number(form.getFieldValue('out_value')) + Number(option.value),
            })
        }
    }
    //表单提交
    const onSubmit = () => {
        if (data.length <= 0) {
            message.warning('维修项目不能为空')
            return
        }
        console.log(data)
        // let itemStr = data.reduce((a, b) => {
        //     a += `|${b['itemName'].replaceAll(',', '-')},${b['p1'] ? `${b['p1']},` : ''}${b['p2'] ? `${b['p2']},` : ''}${b['p3'] ? `${b['p3']},` : ''}`
        //     return a
        // }, '')

        let itemStr = data.map((item) => {
            const { p1, p2, p3, p4, hour, itemName } = item
            return {
                constructionPersonnel: [p1, p2, p3, p4].filter(Boolean).join(','),
                hour: hour,
                serviceName: itemName,
            }
        })
        form.validateFields().then((res) => {
            let data = {
                ...res,
                orderId: orderInfo.id, //订单id
                repair_no: orderInfo.orderCode, //工单号
                // arritems: itemStr.substr(1), //项目和维修人员
                arritems: JSON.stringify(itemStr), //项目和维修人员
                accountSubject: savePermitNo.name, //账户主体。对应[qal_info]中的name
                vehicleid: res.vehicle_first + res.vehicleid, //车头和车号拼接
                in_date: res.in_date?.format('YYYY-MM-DD') || null,
                out_date: res.out_date?.format('YYYY-MM-DD') || null,
                qal_end_time: res.qal_end_time?.format('YYYY-MM-DD') || null,
            }
            syncOrderBjsgl(data).then((res) => {
                // if (res && res.retCode === 200 && res.retData) {
                if (res.retData.sRslt === '0') {
                    message.success(res.retData.sMsg)
                    onClose(false)
                } else {
                    message.error(res.retData.sMsg)
                }
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => {
            //     message.error(err.retMsg)
            // })
        })
    }

    //车牌简称
    const paiZhao = [
        '京',
        '沪',
        '津',
        '渝',
        '鲁',
        '冀',
        '晋',
        '蒙',
        '辽',
        '吉',
        '黑',
        '苏',
        '浙',
        '皖',
        '闽',
        '赣',
        '豫',
        '湘',
        '鄂',
        '粤',
        '桂',
        '琼',
        '川',
        '贵',
        '云',
        '藏',
        '陕',
        '甘',
        '青',
        '宁',
        '新',
        '港',
        '澳',
        '台',
    ]
    //车牌省份 前缀
    const prefixSelector = (
        <Form.Item name="vehicle_first" noStyle>
            <Select
                style={{
                    width: 60,
                }}
            >
                {paiZhao.map((item, index) => (
                    <Option key={index} value={item}>
                        {item}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    )

    //车牌颜色 后缀
    const vehicleColor = (
        <Form.Item name="vehidcolor" noStyle>
            <Select
                style={{
                    width: 110,
                }}
            >
                <Option value="蓝">蓝</Option>
                <Option value="黑">黑</Option>
                <Option value="黄">黄</Option>
                <Option value="白">白</Option>
                <Option value="渐变绿">渐变绿</Option>
                <Option value="黄绿相间">黄绿相间</Option>
            </Select>
        </Form.Item>
    )
    //项目确定
    const onItemOk = (arr) => {
        // console.log(arr)
        let newArr = []
        arr.forEach((item) => {
            let unselect = item
            let isSelect = data.find((item) => item.id === unselect.id)
            if (isSelect === undefined) {
                newArr.push({
                    id: unselect.id,
                    itemName: unselect.itemName,
                    hour: unselect.itemWorkHours,
                    p1: '',
                    p2: '',
                    p3: '',
                    p4: '',
                })
            }
        })
        setData([...data, ...newArr])
        setItemVisible(false)
    }

    //维修项目删除一条数据
    const deleteMaintenanceOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex = newData.findIndex((item) => item.id === row.id)
        newData.splice(delIndex, 1)
        setData(newData)
    }
    //选择维修工
    const selectP = () => {
        let selectArr = null
        const handleChange = (val) => {
            selectArr = val
        }
        Modal.confirm({
            title: '请选择维修人员',
            content: (
                <>
                    <Select
                        mode="multiple"
                        notFoundContent={<p style={{ textAlign: 'center', color: '#aaa' }}>请先选择修理厂</p>}
                        style={{ width: '100%' }}
                        placeholder="请选择维修人员"
                        onChange={handleChange}
                    >
                        {readStaffs &&
                            readStaffs.map(
                                (item) =>
                                    item.position_post !== '维修接待员（维修顾问）' &&
                                    item.position_post !== '质量检验员' &&
                                    item.position_post !== '总经理、厂长、店长等' &&
                                    item.position_post !== '服务总监' &&
                                    item.position_post !== '车间主任（主管、经理）' && (
                                        <Option key={item.oid} value={item.name}>{`${item.name} 岗位：${item.position_post}`}</Option>
                                    )
                            )}
                    </Select>
                    <p>
                        <span style={{ color: '#f00' }}>提示：</span>
                        默认只取前四位维修技师
                    </p>
                </>
            ),
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                // console.log(selectArr)
                if (selectArr) {
                    let newData = data.map((item) => {
                        return {
                            ...item,
                            p1: selectArr[0],
                            p2: selectArr[1],
                            p3: selectArr[2],
                            p4: selectArr[3],
                        }
                    })
                    setData(newData)
                }
            },
            onCancel: () => {},
        })
    }

    const columns = [
        {
            title: '维修项目',
            align: 'center',
            dataIndex: 'itemName',
        },
        {
            title: '修理工1',
            align: 'center',
            dataIndex: 'p1',
        },
        {
            title: '修理工2',
            align: 'center',
            dataIndex: 'p2',
        },
        {
            title: '修理工3',
            align: 'center',
            dataIndex: 'p3',
        },
        {
            title: '修理工4',
            align: 'center',
            dataIndex: 'p4',
        },
        {
            title: '操作',
            align: 'center',
            width: 140,
            dataIndex: 'p6',
            render: (_, record) => (
                <>
                    <Button type="primary" shape="round" size="small" style={{ marginRigth: '4px' }} onClick={() => deleteMaintenanceOne(record)}>
                        删除
                    </Button>
                    {/* <Button type="primary" shape="round" size="small">选择</Button> */}
                </>
            ),
        },
    ]

    // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    // const onSelectChange = selectedRowKeys => {
    //     console.log('selectedRowKeys changed: ', selectedRowKeys);
    //     setSelectedRowKeys(selectedRowKeys);
    // };
    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    // };

    //维修类型
    const plainOptions = ['小修保养', '总成大修', '整车维修', '事故车维修', '二级维护']

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    return (
        <>
            <Modal
                title="同步车管所"
                width={1300}
                visible={visible}
                onCancel={() => onClose(false)}
                footer={[
                    <Button onClick={() => onClose(false)} key="back">
                        {' '}
                        取消
                    </Button>,
                    <Button type="primary" onClick={onSubmit} key="submit">
                        确认
                    </Button>,
                ]}
            >
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="车辆维修数据采集单" key="1">
                        <Form form={form} {...formItemLayout}>
                            <Row>
                                <Col span={8}>
                                    <Form.Item label="修理厂选择" name="accountSubject" rules={[{ required: true, message: '修理厂选择' }]}>
                                        <Select showSearch placeholder="修理厂选择" onChange={handleRepairChange} style={{ width: '100%' }}>
                                            {repairInfo &&
                                                repairInfo.map((item) => (
                                                    <Option key={item.permitNo} value={item.permitNo}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="车牌号码"
                                        name="vehicleid"
                                        rules={[
                                            {
                                                required: true,
                                                message: '车牌号码不可为空',
                                            },
                                        ]}
                                    >
                                        <Input addonBefore={prefixSelector} addonAfter={vehicleColor} placeholder="车牌号码" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="送修人或车主姓名" name="send_pers" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Input placeholder="送修人或车主姓名" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="手机号码" name="send_phone" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Input placeholder="手机号码" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="厂牌型号" name="vehicle_num" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Input placeholder="厂牌型号" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="车辆识别代号" name="frame_code" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Input placeholder="车辆识别代号" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="发动机号" name="engn_code" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Input placeholder="发动机号" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="车身颜色" name="car_body_color" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Input placeholder="车身颜色" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="进厂日期" name="in_date">
                                        <DatePicker style={{ width: '100%' }} allowClear={false} rules={[{ required: true, message: '该项为必填项' }]} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="进厂里程"
                                        name="into_value"
                                        rules={[
                                            { required: true, message: '该项为必填项' },
                                            {
                                                pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                                message: '请输入数字且可保留两位小数',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="进厂里程" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="维修接待员" name="recv_name" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Select
                                            showSearch
                                            notFoundContent={
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        color: '#aaa',
                                                    }}
                                                >
                                                    请先选择修理厂
                                                </p>
                                            }
                                            placeholder="维修接待员"
                                            style={{ width: '100%' }}
                                        >
                                            {readStaffs &&
                                                readStaffs.map(
                                                    (item) =>
                                                        item.position_post === '维修接待员（维修顾问）' && (
                                                            <Option key={item.oid} value={item.name}>
                                                                {item.name}
                                                            </Option>
                                                        )
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="事故车维修" name="accident_repair" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Select showSearch placeholder="事故车维修" style={{ width: '100%' }}>
                                            <Option key="Y" value="Y">
                                                是
                                            </Option>
                                            <Option key="N" value="N">
                                                否
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="车辆使用性质" name="vehicle_use_type" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Select showSearch placeholder="是否返修" style={{ width: '100%' }}>
                                            <Option key={1} value={1}>
                                                营运
                                            </Option>
                                            <Option key={2} value={2}>
                                                非营运
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="是否返修" name="is_repair">
                                        <Select showSearch placeholder="是否返修" style={{ width: '100%' }}>
                                            <Option key="Y" value="Y">
                                                是
                                            </Option>
                                            <Option key="N" value="N">
                                                否
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Button type="primary" onClick={() => setItemVisible(true)} style={{ marginRight: '10px' }}>
                                    批量新增维修项目
                                </Button>
                                <Button onClick={selectP}>选择维修工</Button>
                            </Row>
                            <div
                                style={{
                                    border: '1px solid #ccc',
                                    margin: '6px 0 12px 0',
                                }}
                            >
                                <Table
                                    size="small"
                                    rowKey={(record) => record.id}
                                    columns={columns}
                                    dataSource={data}
                                    // rowSelection={rowSelection}
                                    scroll={{ y: 300 }}
                                    pagination={false}
                                />
                            </div>
                            {/* <Row>
                        <Col span={24}>
                            <Form.Item
                                label="维修类型"
                                labelCol={{ span: 2 }}
                                wrapperCol={{ span: 22 }}
                            >
                                <Checkbox.Group
                                    options={plainOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}
                            <Row>
                                <Col span={6}>
                                    <Form.Item label="竣工出厂日期" name="out_date" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="出厂里程"
                                        name="out_value"
                                        rules={[
                                            { required: true, message: '该项为必填项' },
                                            {
                                                pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                                message: '请输入数字且可保留两位小数',
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="出厂里程"
                                            onChange={() => {
                                                form.resetFields(['qal_time', 'qal_value', 'qal_end_time', 'qal_end_value', 'qal_project'])
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="检验员" name="qa_name" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Select
                                            showSearch
                                            notFoundContent={
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        color: '#aaa',
                                                    }}
                                                >
                                                    请先选择修理厂
                                                </p>
                                            }
                                            placeholder="检验员"
                                            style={{ width: '100%' }}
                                        >
                                            {readStaffs &&
                                                readStaffs.map(
                                                    (item) =>
                                                        item.position_post === '质量检验员' && (
                                                            <Option key={item.oid} value={item.name}>
                                                                {item.name}
                                                            </Option>
                                                        )
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="质保方案" name="qal_project" rules={[{ required: true, message: '该项为必填项' }]}>
                                        <Select
                                            showSearch
                                            notFoundContent={
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        color: '#aaa',
                                                    }}
                                                >
                                                    请先选择修理厂
                                                </p>
                                            }
                                            allowClear
                                            placeholder="质保方案"
                                            onChange={handleQal}
                                            style={{ width: '100%' }}
                                        >
                                            {savePermitNo &&
                                                savePermitNo.qalInfo.map((item) => (
                                                    <Option key={item.name} time={item.qal_time} value={item.qal_value}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="质保期" name="qal_time">
                                        <Input placeholder="质保期" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="质保截止日期" name="qal_end_time">
                                        <DatePicker style={{ width: '100%' }} disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="质保里程" name="qal_value">
                                        <Input placeholder="质保里程" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="质保截止里程" name="qal_end_value">
                                        <Input placeholder="质保截止里程" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="工单号" name="entp_workno">
                                        <Input placeholder="工单号" disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        {itemVisible && <ItemDrawer visible={itemVisible} onClose={() => setItemVisible(false)} onItemOk={(arr) => onItemOk(arr)} />}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="状态查询" key="2">
                        <p style={{display:"flex"}}>车牌号码： <YhTooltip text={orderInfo.customerVehicleCode} /></p>
                        <p style={{display:"flex"}}>发动机号码： <YhTooltip  text={orderInfo.customerVehicleVin} /></p>
                        <iframe
                            id={'iframe_bjysgl'}
                            width={'100%'}
                            height={'800px'}
                            src={'https://weixiu.bjysgl.yunhaochefu.com/bjvmpsf/f/maintainRecord'}
                            sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"
                        ></iframe>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        </>
    )
}

export default ParintMadal
