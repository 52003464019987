import React, { useEffect, useRef, useState } from "react"
import { Button, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { useCustomerModel } from "../customerModel"
import useGlobalModel from "@/erp_subpackage/model/globalModel"
// import useNavModel, { RouterParamType } from "@/model/navModel";
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { debounce } from "lodash"

export const BaseCustomerDetails = ({ modeKey, mode, rowId, dispatch }) => {
    const {
        addFormVal,
        editFormVal,
        lookFormVal,
        updateAddFormVal,
        updateEditFormVal,
        insertCustomer,
        editCustomer,
        getCustomerOne,
        setAddFormVal,
        setSelectedRowKeys,
        saveBtnLoading,setSaveBtnLoading
    } = useCustomerModel()
    const {
        user: { shopName, userName },
    } = useGlobalModel()
    const { getTreeSelectOne } = useDictModel()
    const formRef = useRef()
    const updateFormVal = mode === 'add' ? updateAddFormVal : updateEditFormVal

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (mode === "edit" || mode === "look") {
            getCustomerOne(rowId, mode)
        }
        return () => {
            updateFormVal(null)
        }
    }, [rowId, mode])

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "baseCustomer",
        })
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current
            .validateFields()
            .then((res) => {
                let data = {
                    ...res,
                }
                if (mode === "edit") {
                    editCustomer({ ...data, id: rowId, updateUser: userName }).then((res) => {
                        res && onClose()
                    })
                } else {
                    insertCustomer(data).then((res) => {
                        setSelectedRowKeys([res])
                        res && onClose()
                    })
                }
            })
            .catch((err) => {
                setSaveBtnLoading(false)
                message.warning(getFormValidatorErrText(err))
            })
    }

    // 基础信息
    const baseFormItem = {
        title: "基础信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "客户编码",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成",
            },
            {
                type: "Input",
                fieldLabel: "客户名称",
                fieldName: "name",
                // rules: [{ required: true, message: "请输入客户名称" }, { pattern: /^[a-zA-Z\u4e00-\u9fa5\-\(\)\（\）]+$/, message: '请输入规范名称' }],
                rules: [{ required: true, message: "请输入客户名称" }],
            },
            {
                type: "Input",
                fieldLabel: "传真号码",
                fieldName: "fax",
            },
            {
                type: "TreeSelect",
                fieldLabel: "客户类型",
                fieldName: "categoryCode",
                treeData: getTreeSelectOne(DictCodeEnmu.CUSTOMER_TYPE),
                rules: [{ required: true, message: "请输入客户名称" }],
            },
            {
                type: "Input",
                fieldLabel: "电子邮箱",
                fieldName: "email",
            },
            {
                type: "Input",
                fieldLabel: "联系人",
                fieldName: "contact",
                rules: [{ required: true, message: "联系人" }],
            },
            {
                type: "Input",
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "联系电话" }],
            },
            {
                type: "Input",
                fieldLabel: "办公地址",
                fieldName: "address",
                rules: [{ required: true, message: "办公地址" }],
            },
            {
                type: "Input",
                fieldLabel: "登记日期",
                fieldName: "createTime",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "办公电话",
                fieldName: "tel",
            },
            {
                type: "Input",
                fieldLabel: "邮编",
                fieldName: "postCode",
            },
            {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
                span: 12,
                labelCol: { span: 3 },
            },
            {
                type: "Switch",
                fieldLabel: "客户状态",
                fieldName: "enable",
            },
            {
                type: "Checkbox",
                fieldLabel: "供应商",
                fieldName: "isTransfer",
                hidden: mode !== "add"
            },
        ],
    }

    // 开票信息
    const makeFormItem = {
        title: "开票信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "发票抬头",
                fieldName: "invoiceTitle",
            },
            {
                type: "Input",
                fieldLabel: "营业执照",
                fieldName: "licenseNo",
            },
            {
                type: "Input",
                fieldLabel: "开户银行",
                fieldName: "bank",
            },
            {
                type: "Input",
                fieldLabel: "银行账号",
                fieldName: "bankAcct",
            },
            {
                type: "Input",
                fieldLabel: "邮寄地址",
                fieldName: "postAddress",
            },
            {
                type: "Input",
                fieldLabel: "公司法人",
                fieldName: "corporation",
            },
            {
                type: "Input",
                fieldLabel: "经营范围",
                fieldName: "businessScope",
            },
        ],
    }

    // 其他信息
    const otherFormItem = {
        title: "其它信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        items: [
            {
                type: "Input",
                fieldLabel: "创建人",
                fieldName: "createUser",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "创建时间",
                fieldName: "createTime",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "修改人",
                fieldName: "updateUser",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "修改时间",
                fieldName: "updateTime",
                disable: true,
            },
        ],
    }

    const onChange = (changedValues) => {
        // console.log(changedValues);
        mode === "edit" ? updateEditFormVal(changedValues) : updateAddFormVal(changedValues)
    }
    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal,
    }

    const typeTitle = {
        add: "新增",
        edit: "编辑",
        look: "查看",
    }
    const customerFromConfig = {
        formRef,
        labelCol: { span: 6 },
        disabled: mode === "look",
        labelAlign: "right",
        formValues: { ...typeForm[mode], shopName },
        onChange,
        boxs: [baseFormItem, makeFormItem, otherFormItem],
    }
    const reset = () => {
        formRef.current.resetFields()
        if (mode === "add") {
            setAddFormVal({})
        } else if (mode === "edit") {
            getCustomerOne(rowId, mode)
        }
        message.success("重置成功")
    }
    const action = (
        <>
            {mode !== "look" && (
                <Button
                    className="btn-item"
                    shape="round"
                    loading={saveBtnLoading}
                    onClick={debounce(() => {
                        onSubmit()
                    }, 900)}
                >
                    {saveBtnLoading ? '保存中' : '保存'}
                </Button>
            )}
            {mode !== "look" && (
                <Button className="btn-item" shape="round" onClick={() => reset()}>
                    重置
                </Button>
            )}
            <Button className="btn-item" shape="round" onClick={onClose}>
                取消
            </Button>
        </>
    )

    return (
        <>
            <YhBox title={`客户${typeTitle[mode]}`} action={action} style={{ padding: "0 20px", marginBottom: "10px" }}></YhBox>
            <YhFormBox {...customerFromConfig} />
        </>
    )
}
