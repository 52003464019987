import React, {useEffect, FC } from 'react'
import { Button, Table, Upload, Tooltip, message, notification } from 'antd'
import { YhBox } from '@/erp_subpackage/components/YhBox'
import { useSaleSoDetailModel } from './saleSoDetailModal'
import { DetailType, ISalesTypeEnum, IPriceTypeEnum } from '@/erp_subpackage/utils/ErpEnum'
import { formatNum, formatIntPoint } from '@/erp_subpackage/utils/util'
import { EditableRow, EditableCell } from '@/erp_subpackage/components/YHEditTable/editTable'
import { importPropsConfig } from '@/erp_subpackage/utils/importFile'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { YhBut } from '@/erp_subpackage/components/YhButton'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { stockDetailedService } from '@/erp_subpackage/pages/stock/detailed/stockDetailedService'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import YhTooltip from '@/erp_subpackage/components/YhTooltip'
import { IVRMSettleTypeEnum } from '@/erp_subpackage/utils/enum'
import { debounce } from "lodash";
import { globalPrompt } from "@/erp_subpackage/components/message";

export const TableBox = ({ modeKey, mode, rowId, dispatch }) => {
    const {
        user: { shopId },
        setMaterialInfo,
        getSettlementParty
    } = useGlobalModel(({ user }) => [user])
    const { getTreeNodeOne } = useDictModel(() => [])
    const {
        detailAddForm,
        setAddModalVisible,
        detailAddList,
        detailLookForm,
        detailDataSource,
        detailLookList,
        detaiAddDataRowKeys,
        detailDataRowKeys,
        detailEditForm,
        setDetaiAddDataRow,
        setDetaiAddDataRowKeys,
        setDetailDataRowKeys,
        setDetailDataRow,
        setDetailAddList,
        setDetailDataSource,
        removeDetailList,
        downloadTpl,
        transformsToPoDetailList,
        setTopButsControl,
        modelScreenMethod,
    } = useSaleSoDetailModel()

    const typeList = {
        add: detailAddList,
        edit: detailDataSource,
        look: detailLookList,
    }
    let { priceTypeCode, salesTypeCode, customerCode, customerName } = mode === 'add' ? detailAddForm : detailEditForm

    const importCallback = async (list) => {
        // const { repetition, newDetailData } = await modelScreenMethod(list, detailAddList)
        // if (mode === "add") {
        //     setDetailAddList([...detailAddList, ...newDetailData])
        //     repetition.length !== 0 && message.warning(`商品名称：${repetition.join(",")}，重复的将不在添加。`)
        // } else if (mode === "edit") {
        //     setDetailDataSource([...detailDataSource, ...newDetailData])
        //     repetition.length !== 0 && message.warning(`商品名称：${repetition.join(",")}，重复的将不在添加。`)
        // }
        if (!list) return;
        let newImportList = await Promise.all(
            list.list.map(async (item) => {
                let { stockId, latestPurPrice, materialCode, lateSalePrice, discountRate, purchasePrice, retailPrice, num, petroleumPrice, ...params } =
                    item
                let newPrice = latestPurPrice ?? purchasePrice
                if (salesTypeCode === ISalesTypeEnum.NORMAL_SALES) {
                    switch (priceTypeCode) {
                        //售价选择 客户最近销价 (需要通过接口查询，客户最近一次的销售价)
                        case IPriceTypeEnum.CUSTOMER_LATEST:
                            let {
                                retData: { price },
                            } = await stockDetailedService.recentSalesPrice(shopId, materialCode, customerCode)
                            newPrice = +price
                            break
                        //售价选择 最近销价
                        case IPriceTypeEnum.RECENT:
                            newPrice = lateSalePrice ?? retailPrice
                            break
                        //售价选择 零售价
                        case IPriceTypeEnum.RETAIL:
                            newPrice = retailPrice
                            break
                        //售价选择 采购成本价
                        case IPriceTypeEnum.PUR_COST:
                            newPrice = latestPurPrice ?? purchasePrice
                            break
                        //售价选择 档案进价
                        case IPriceTypeEnum.BUYING_PRICE:
                            newPrice = purchasePrice ?? latestPurPrice
                            break
                        //售价选择 石油价格
                        case IPriceTypeEnum.PETROLEUM:
                            newPrice = petroleumPrice ?? purchasePrice
                            break
                    }
                }
                console.log(111)
                console.log({
                    ...item,
                    id: stockId,
                    num,
                    price: newPrice,
                })
                return {
                    ...item,
                    id: stockId,
                    num,
                    price: newPrice,
                }
            })
        )
        transformsToPoDetailList(mode, newImportList)
    }

    const isEditable = (status) => {
        if (mode === 'look') return false
        if (mode === 'add') return true

        if ((mode === 'edit' && status === DictCodeEnmu.SALE_STOCK_IO) || status === DictCodeEnmu.SALE_SETTLE || status === DictCodeEnmu.SALE_REVERSED) {
            return false
        }
        return true
    }

    const materialTips = (info, visible) => {
        globalPrompt("modal", {
            title: "新增商品",
            type: "warning",
            text: info,
            okText: "确定",
            onOk: debounce(async () => { visible && await setAddModalVisible(visible) }, 500),
            cancelText: "取消",
            onCancel: () => { }
        })
    }

    const action = (
        <>
            {isEditable(detailEditForm.status != DictCodeEnmu.SALE_CREATE) && (
                <>
                    <Button
                        className="btn-item"
                        type="link"
                        shape="round"
                        onClick={async () => {
                            // if (!salesTypeCode || !customerName) {
                            //     message.warning('请补全客户名称和销售类型')
                            //     return false
                            // }
                            // setAddModalVisible(true)


                            // let { salesTypeCode, customerCode } = mode === "add" ? detailAddForm : detailEditForm;
                            if (!salesTypeCode || !customerCode) {
                                message.warning("请补全客户名称和销售类型");
                                return false
                            }
                            if (mode === "add") {
                                let { payType, settlementPartyCode, bentityCode } = detailAddForm
                                if (!(customerCode && customerName && payType && settlementPartyCode)) {
                                    message.warning("请补全客户名称或结算类型或结算方")
                                    return false
                                }
                                // //数据字典取value == '' 结算方没有绑定客户编码 返回
                                const settlementsettlementPartyParams = getTreeNodeOne(settlementPartyCode)[0]
                                // const {value ,name} = settlementsettlementPartyParams
                                // if(!value){
                                //     return message.error(`${name}没有绑定客户编码`)
                                // }
                                let { accountName, availableAmount, accountCustomerName } = await getSettlementParty({ settlementPartyCode, customerName, customerCode, payType, settlementsettlementPartyParams, bentityCode });
                                if (payType !== IVRMSettleTypeEnum.SETTLE_CASH) {
                                    if(accountName && availableAmount < 0){
                                        message.warning(`当前${accountName},授信额度为${availableAmount},请处理完账户的负数余额后，在进行开单操作！！！`)
                                        return;
                                    }
                                    if (accountName && availableAmount > 0) {//有账户，但是额度大于开单金额的
                                        materialTips(`当前${accountName},授信额度为${availableAmount},是否确认？？`, true)
                                    } else if (accountName && availableAmount === 0) {
                                        materialTips(`当前${accountName},授信额度为${availableAmount}，不可开单，是否确认？？`, false)
                                    } else if (!accountName && accountCustomerName) {
                                        materialTips(`当前${accountCustomerName}，无对应账户，无法开单，是否确认？？`, false)
                                    }
                                } else {
                                    setAddModalVisible(true) 
                                }
                            } else {
                                setAddModalVisible(true)
                            }
                        }}
                    >
                        新增商品
                    </Button>
                    <Button
                        className="btn-item"
                        type="link"
                        shape="round"
                        onClick={() => {
                            removeDetailList(mode)
                        }}
                    >
                        删除商品
                    </Button>
                    <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/ci-storage/sale/details/import/${shopId}`, importCallback)}>
                        <Button disabled={!(salesTypeCode && customerName)} className="btn-item" type="link" shape="round">
                            导入
                        </Button>
                    </Upload>
                    <Button className="btn-item" type="link" shape="round" onClick={downloadTpl}>
                        下载模板
                    </Button>
                </>
            )}
        </>
    )
    const detailForm= mode === "add" ? detailAddForm :  mode === "edit" ?detailEditForm:detailLookForm
    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: 'left',
            render: (t, r, i) => i + 1,
        },
        {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: 'left',
            render: (text, record) => (
                <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName,customerName:detailForm.customerName,customerCode:detailForm.customerCode })} />
            ),
            sorter: {
                compare: (a, b) => a.materialCode.charCodeAt(0) - b.materialCode.charCodeAt(0),
                multiple: 1,
            },
        },
        {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: 'left',
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
            sorter: {
                compare: (a, b) => a.materialName?.localeCompare(b.materialName, 'zh-Hans-CN', { sensitivity: 'accent' }),
                multiple: 2,
            },
        },
        {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        },
        {
            title: '仓库',
            width: 140,
            dataIndex: 'warehouseName',
        },
        // {
        //     title: '仓库类型',
        //     width: 140,
        //     dataIndex: 'warehouseTypeName',
        // },
        {
            title: '销售数量',
            width: 100,
            dataIndex: 'num',
            editable: isEditable(detailEditForm.status),
        },
        {
            title: '单价',
            width: 100,
            dataIndex: 'price',
            editable: isEditable(detailEditForm.status),
            render: (text) => formatNum(text),
        },
        {
            title: '销售金额',
            width: 100,
            dataIndex: 'totalAmount',
            render: (text) => formatNum(text),
        },
        {
            title: '折扣',
            width: 100,
            dataIndex: 'discountRate',
            editable: isEditable(detailEditForm.status),
            suffix: '%',
            render: (text) => text + '%',
        },
        {
            title: '折后金额',
            width: 100,
            dataIndex: 'discountedAmount',
            render: (text) => formatNum(text),
        },
        {
            title: '单项优惠金额',
            width: 100,
            dataIndex: 'decreaseAmount',
            editable: isEditable(detailEditForm.status),
            render: (text) => `￥${formatNum(text)}`,
        },
        {
            title: '小计',
            width: 100,
            dataIndex: 'receivableAmount',
            render: (text) => `￥${formatNum(text)}`,
        },
        {
            title: '货位',
            width: 140,
            dataIndex: 'shelfCode',
        },
        {
            title: '可用库存',
            width: 100,
            dataIndex: 'inventoryNum',
        },
        {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        },
        {
            title: '备注',
            width: 160,
            dataIndex: 'note',
            editable: isEditable(detailEditForm.status),
        },
        {
            title: '操作',
            width: 60,
            fixed: 'right',
            align: 'center',
            render: (text, record) => isEditable(detailEditForm.status) && <YhBut type="delete" txt="删除" click={() => removeDetailList(mode, record)} />,
        },
    ]

    const newSelectColumns = () => {
        return columns.map((col, index) => {
            return {
                ...col,
                onCell: (record, rowIndex) => ({
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: col?.editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    }

    const handleSave = (row) => {
        let { num, price, discountRate, inventoryNum, purchasePrice, latestPurPrice, termNum, decreaseAmount, receivableAmount, discountedAmount } = row
        let newPurPrice = +formatNum(latestPurPrice !== 0 ? latestPurPrice : purchasePrice)
        discountRate = +formatNum(discountRate)
        discountRate = discountRate > 100 ? 100 : discountRate
        num =
            mode === 'add'
                ? dispatch
                    ? +formatIntPoint(num)
                    : +formatIntPoint(num, inventoryNum, '选择数量>=可用库存数量')
                : dispatch
                    ? +formatIntPoint(num)
                    : +formatIntPoint(num, termNum ? termNum : inventoryNum, '选择数量>=可用库存数量') //数量
        price = +formatNum(price)
        if (price < newPurPrice) {
            price = newPurPrice
            message.warning(`修改价格不应小于进货价${newPurPrice}`)
        }
        decreaseAmount = +formatIntPoint(decreaseAmount, 1, '单项优惠金额不能大于1元')
        discountedAmount = +formatNum((num * price * discountRate) / 100)
        // num = +formatInt(num, inventoryNum, "销售数量>=可用库存数量")
        let newRow = {
            ...row,
            price,
            discountRate,
            num,
            totalAmount: +formatNum(num * price),
            discountedAmount,
            decreaseAmount,
            receivableAmount: +formatNum(discountedAmount - decreaseAmount),
        }
        let newData = mode === 'edit' ? detailDataSource : detailAddList
        const index = newData.findIndex((item) => row.detailsId === item.detailsId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...newRow })
        mode === 'edit' ? setDetailDataSource([...newData]) : setDetailAddList([...newData])
        setTopButsControl(true)
    }

    const setTypeDetailDataRowKeysAndRow = async (selectedRowKeys, mode) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRowKeys(selectedRowKeys)
        } else if (mode === DetailType.edit) {
            setDetailDataRowKeys(selectedRowKeys)
        }
    }
    const setTypeDetailDataRow = async (selectedRows, mode) => {
        if (mode === DetailType.add) {
            setDetaiAddDataRow(selectedRows)
        } else if (mode === DetailType.edit) {
            setDetailDataRow(selectedRows)
        }
    }

    return (
        <YhBox title="商品信息" rightChildren={action} style={{ marginBottom: '75px' }}>
            <Table
                rowClassName="editable-row yh-edit-table-tr"
                rowKey={(record) => record.detailsId}
                rowSelection={
                    mode === DetailType.look
                        ? undefined
                        : {
                            selectedRowKeys: mode == DetailType.add ? detaiAddDataRowKeys : detailDataRowKeys, //记录选中
                            onChange: (selectedRowKeys, selectedRows) => {
                                setTypeDetailDataRowKeysAndRow(selectedRowKeys, mode)
                                setTypeDetailDataRow(selectedRows, mode)
                            },
                            onSelectAll: (selected, selectedRows, changeRows) => {
                                setTypeDetailDataRow(selectedRows, mode)
                            },
                        }
                }
                columns={newSelectColumns()}
                dataSource={typeList[mode]}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}
