import React, { useState, useEffect } from "react"
import { Table, message } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import { usePrebookModel } from "../prebookModel";
import YhIcon from "@/components/pubIcon"
import SanbaoPopup from "./SanbaoPopup"

//物料
export default ({ mode }) => {

    const {
        getThreeGuaranteesList, setThreeGuaranteesList,
        getMaterialList, setMaterialList,
        getItemList, setItemList,
        getInfo,
    } = usePrebookModel();

    const [visible, setVisible] = useState(false) //弹框
    const [popupMode, setPopupMode] = useState("add")
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [dictObj, setDictObj] = useState({ threeGuaranteesUnit: {} })
    const [threeGuaranteesAmount, setThreeGuaranteesAmount] = useState(0)

    //点开弹框初始下页面需要的字典数据
    const initDict = () => {
        let threeGuaranteesUnit = {}
        //三包品牌
        getPcodeDict("106").children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
        setDictObj({
            threeGuaranteesUnit: threeGuaranteesUnit,
        })
    }

    useEffect(() => {
        let amount = 0;
        getThreeGuaranteesList(mode) && getThreeGuaranteesList(mode).forEach(e => {
            amount = (Number(amount) + Number(e.totalHourlyWage ?? 0)).toFixed(2);
        });
        setThreeGuaranteesAmount(amount);
    }, [getThreeGuaranteesList(mode)])

    useEffect(() => {
        initDict()
    }, [])

    //table配置
    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "三包品牌",
            dataIndex: "threeGuaranteesBrand",
            width: 100,
            render: (text) => (
                <span
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        setPopupMode("edit")
                        setVisible(true)
                    }}>
                    {dictObj.threeGuaranteesUnit[text]}
                </span>
            ),
        },
        {
            title: "索赔单号",
            dataIndex: "claimNumber",
            width: 100,
        },
        {
            title: "故障处理",
            dataIndex: "faultHandle",
            width: 200,
        },
        {
            title: "材料费",
            dataIndex: "materialAmount",
            width: 100,
        },
        {
            title: "出库金额",
            dataIndex: "outboundAmount",
            width: 100,
        },
        {
            title: "工时费",
            dataIndex: "hourlyWage",
            width: 100,
        },
        {
            title: "外出费",
            dataIndex: "travelExpenses",
            width: 100,
        },
        {
            title: "餐补费",
            dataIndex: "mealSupplement",
            width: 100,
        },
        {
            title: "其他费用",
            dataIndex: "otherAmount",
            width: 100,
        },
        {
            title: "索赔总额",
            dataIndex: "totalClaim",
            width: 100,
        },
        {
            title: "结算单号",
            dataIndex: "statementNumber",
            width: 100,
        },
        {
            title: "三包工时费汇总",
            dataIndex: "totalHourlyWage",
            width: 100,
        },
    ]

    const onRow = (record) => {
        let findIndex = selectedRowKeys.indexOf(record.id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(record.id);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const deleteBatch = () => {
        let list = getThreeGuaranteesList(mode);
        let list2 = getItemList(mode);
        let list3 = getMaterialList(mode);
        selectedRowKeys.forEach((e) => {
            list = list.filter((item) => item.id !== e)
            list2 = list2.filter((item) => item.threeGuaranteeId !== e);
            list3 = list3.filter((item) => item.threeGuaranteeId !== e);
        })
        setThreeGuaranteesList(mode, list);
        setItemList(mode, list2);
        setMaterialList(mode, list3);
        setSelectedRowKeys([]);
    };

    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>三包信息</span>
                    {/* <span className="total-price">
                        {threeGuaranteesAmount}&nbsp;元&nbsp;({getThreeGuaranteesList(mode).length}项){" "}
                    </span> */}
                    <div className="list-name-but">
                        {(getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                            <>
                                <YhIcon
                                    type="icon-zengjia"
                                    style={{ color: "#37B36F", fontSize: "26px" }}
                                    onClick={() => {
                                        setPopupMode("add")
                                        setVisible(true)
                                    }}
                                />
                                <span className="list-delete" onClick={() => deleteBatch()}>
                                    批量删除
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={getThreeGuaranteesList(mode)}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={{
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let changeRowId = changeRows.map(item => item.id);
                        if (selected) {
                            setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        }
                        else {
                            setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                        }
                    },
                    onSelect: (record, selected, selectedRows) => onRow(record),
                    selectedRowKeys: selectedRowKeys
                }}
                onRow={(record, index) => ({
                    onClick: () =>
                        setSelectInfo({
                            rowInfo: record,
                            rowIndex: index,
                        }),
                })}
            />
            {visible && (
                <SanbaoPopup
                    rowInfo={selectInfo.rowInfo}
                    visible={visible}
                    setVisible={setVisible}
                    mode={mode}
                    popupMode={popupMode}
                    onSuccess={() => { }}
                />
            )}
        </div>
    )
}
