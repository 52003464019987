import React from "react";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useStockEntryModel } from "../../entryModel";

export const TopButs = () => {
    const { detailLookForm } = useStockEntryModel(({ detailLookForm }) => [detailLookForm]);

    const orderStatu = [
        {
            label: "单据编号",
            value: detailLookForm.tradeCode,
            color: "blue",
            status: true
        },
    ]

    return (
        <YhBox
            title={orderStatu.map((item, index) => <Description key={index} {...item} />)}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}