import React, { useState } from "react"
import { getVehicleOne, getOrderTeamList } from "@/service/homeService"
import close from "@/assets/images/close.png"
import open from "@/assets/images/open.png"
import styled from "@emotion/styled"
import { message } from "antd"

export default ({ vehicleList,  openVehicleWindowInfo, setCenterSite, setInfoWindowObj, setCheckedVehicle }) => {
    const [isShowList, setIsShowList] = useState(false)

    const handleLook = async (item) => {
        if (!item.lng || !item.lat) {
            return message.error("没有经纬度")
        }
        item.position = {latitude: item.lat, longitude: item.lng}
        openVehicleWindowInfo(item)
    }
    return (
        <>
            <OperImg
                style={{}}
                src={isShowList ? close : open}
                onClick={() => {
                    setIsShowList(!isShowList)
                }}
            />
            <Conetnt className="window-info-carlist" hidden={vehicleList.length === 0 || !isShowList}>
                {vehicleList.map((item) => (
                    <div className="car-item" key={item.vehicleId}>
                        <span className="quan"></span>
                        <span className="vehicleCode">{item.vehicleCode}</span>
                        <span className="contactName">{item.teamName}</span>
                        <RunStatus className="juli" status={item.status}>{item.status}</RunStatus>
                        <span
                            className="car-item-btn"
                            onClick={() => {
                                handleLook(item)
                            }}
                        >
                            查看
                        </span>
                    </div>
                ))}
            </Conetnt>
        </>
    )
}

const OperImg = styled.img`
    width: 60px;
    height: 60px;
    position: absolute;
    z-index: 99999;
    top: 10px;
    right: 20px;
    cursor: pointer;
`

const Conetnt = styled.div`
    width: 400px;
    overflow: auto;
    background: white;
    padding: 20px 10px;
    boxsizing: border-box;
    position: absolute;
    z-index: 99999;
    top: 100px;
    right: 90px;
    height: auto;
    max-height: 500px;
    border-radius: 10px;
    .contactName {
        width: 140px !important;
    }
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

const RunStatus = styled.span`
    color: ${(props) => {
        if(props.status === "行驶"){
            return "#19be6b"
        }else if(props.status === "等待"){
            return "#ff9900"
        }else if(props.status === "离线"){
            return "#ed4014"
        }
    }} !important;
`
