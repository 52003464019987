import React from "react";
import { message, notification, Modal } from "antd";
import { ModalProps } from "antd/lib/modal";
import { ButtonProps } from "antd/lib/button"

import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';



export function globalPrompt(
    elType,
    {
        text,
        type = "info",
        icon = "",
        title = "标题",
        duration = 3,
        placement = "topRight",
        onClick = () => null,
        onClose = () => null,
        cancelShow = false,
        cancelButtonProps = {},
        btn = null,
        ...params
    },
) {
    if (elType === "message") {
        message.open({
            content: text,
            icon,
            type,
            duration,
            onClose
        })
    } else if (elType === "notification") {
        notification[type]({
            description: text,
            message: title,
            icon,
            duration,
            placement,
            btn,
            onClick,
            onClose
        });
    } else if (elType === "modal") {
        let { style = {}, ...cancelButton } = cancelButtonProps;

        Modal.confirm({
            content: text,
            icon: modalIconType(type, icon),
            title,
            width:600,
            // cancelButtonProps: { ...cancelButton, style: cancelShow ? { ...style } : { ...style, display: "none" } },
            ...params
        });
    }

    function modalIconType(type, icon) {
        if (icon) return icon;
        switch (type) {
            case "success":
                return <CheckCircleOutlined style={{ color: "#52c41a" }} />;
            case "error":
                return <CloseCircleOutlined style={{ color: "#ff4d4f" }} />;
            case "info":
                return <InfoCircleOutlined style={{ color: "#1890ff" }} />;
            default:
                return <ExclamationCircleOutlined />
        }
    }
};