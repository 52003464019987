import React, { useEffect, useState,useContext } from "react";
import { Table } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import {useContractModel} from '../contractParticularsModel'
import {usecontractDetailsModel} from './detailsModel'
import { formatNum } from '@/erp_subpackage/utils/util';



export const MileageOneTable = () => {
    const {setOpen}=useContractModel()
    const  {loading,billdetailData,mileageOrderdetailes,serachmdetail,descTotalt,updateSearchParamm,resetSearchParamm
    } =usecontractDetailsModel()


    useEffect(()=>{
        setOpen(false)
    })
const column2=[
    {
        title: '对账单单号',
        width: 140,
        dataIndex: 'billCode',
        fixed: "left",
    },
    {
        title: '车辆牌照',
        width: 100,
        dataIndex: 'vehicleCode',
        
    },
    {
        title: '上月账单里程',
        width: 120,
        dataIndex: 'mileageLast',
        
        title: '当前里程',
        width: 120,
        dataIndex: 'mileageCurrent',
       
    },
    {
        title: '运行里程',
        width: 150,
        dataIndex: 'threeGuaranteesBrand',
    }, {
        title: '计费价格',
        width: 100,
        dataIndex: 'billPrice',
        render:(text)=>text.toFixed(3)
    }, {
        title: '账单金额',
        width: 100,
        dataIndex: 'hourlyWage',   
    }, {
        title: '已收金额',
        width: 100,
        dataIndex: 'paidAmount',
      
    },{
        title: '应收金额',
        width: 100,
        dataIndex: 'payableAmount',
       
        
    },
    {
        title: '开始日期',
        width: 100,
        dataIndex: 'mealSupplement',
        
    },
    {
        title: '结束日期',
        width: 100,
        dataIndex: 'mealSupplement',
       
        
    },
   
]

const searchFields1 = [ 
    {
        type: "Input",
        placeholder: "车辆牌照",
        fieldName: "vehicleCode"
    }, 
]




const desc1 = [
    
    {
        label: "  费用金额",
       value: "￥" + (descTotalt.totalAmount?? 0).toFixed(2),
        color: "red"
    },
    {
        label: "  已收金额", 
        value: "￥" + (descTotalt.paidAmount?? 0).toFixed(2),
        color: "red"
    }, {
        label: "  应收金额",
        
     value: "￥" + (descTotalt.payableAmount?? 0).toFixed(2),
        color: "red"
    },
    
]

return (
    <>
         
            <YhBox descriptions={desc1} title={<span style={{fontSize:'14px'}}>对账单里程明细</span>}>
            <YhPageHeader
               size="small"
               justify="start"
               triggerSearch
               searchValues={serachmdetail}
               searchFields={searchFields1}
               onChange={updateSearchParamm}
               handleSearch={() => { mileageOrderdetailes({ ...serachmdetail,}) }}
               handleReset={resetSearchParamm}
            />
            <div>
            <Table
                columns={column2}  
                loading={loading} 
                dataSource={billdetailData}
                // pagination={{
                //     ...pgCfgt,
                //     position: ["bottomCenter"],
                //     showTotal: () => `共${pgCfgt.total}条`,
                //     onChange: (pageNum, pageSize) => {
                //         contractPageT({ pageNum, pageSize });
                //     }
                // }}
                scroll={{ y:800 }} 
            />
            </div>
            </YhBox>
          
    </>    
 
)

}