import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { faultCodePage, faultCodeDelete } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Detail from "./detail"
import { Modal, Select } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import Loading from "@/components/Loading"
const { Option } = Select

export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [order, setOrder] = useState({column: "updateTime", rule: "DESC"})
    

    useEffect(() => {
        if (state.activeMenu === "faultCode") {
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}, order = { column: "updateTime", rule: "DESC" }) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                order
            ],
        }
        faultCodePage(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    // const onTableChange = async (page) => {
    //     await setpagination({
    //         ...pagination,
    //         current: page.current,
    //     })
    //     loadData(page.current, searchValue)
    // }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
        {
            click: () => handleExport(),
            content: "导出",
        },
        {
            content: "导入",
            upload: true,
            actionUrl: "ci-platform/fault_code/import",
            onChange(info) {
                console.log(info)
                console.log(info.file.status)
                if(info.file.status === "uploading"){
                    setExportLoading(true)
                }else{
                    setExportLoading(false)
                }
                if (info.file.status === "done" && info.file?.response?.retCode === 200) {
                    message.success({
                        content: `${info?.file?.response?.retData}`,
                        duration: 8,
                    })
                    loadData(pagination.current, searchValue)
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name}导入异常，请尝试重新导入`)
                }
            },
        },
        {
            click: () => handleExportTemplete(),
            content: "下载模版",
        },
    ]

    //导出故障码
    const handleExport = async () => {
        setExportLoading(true)
        let data = {
            type: "post",
            data: {
                searchItem: searchValue,
                orders: [order],
            },
        }
        let resFlie = await download("/ci-platform/fault_code/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    //导出模版
    const handleExportTemplete = async () => {
        setExportLoading(true)
        const data = { type: "get" }
        let resFlie = await download("/ci-platform/fault_code/export/template", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的故障码信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除故障码--${selectInfo.rowInfo.faultCode}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                faultCodeDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的故障码")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的故障码")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let order = { column: "updateTime", rule: "DESC" }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === "ascend" ? "ASC" : "DESC"
            order = {
                column: sorter.field,
                rule,
            }
        }
        setOrder(order)
        loadData(page.current, searchValue, order)
    }

    //table配置
    const columns = [
        {
            title: "序号",
            align: "center",
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1,
        },
        {
            title: "故障码",
            align: "center",
            dataIndex: "faultCode",
            render: (text, record) => (
                <div
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        setSelectInfo({ rowInfo: record, rowIndex: list.find((item) => item.id === record.id) })
                        setShowMode("edit")
                        setShowVisible(true)
                    }}
                >
                    {text}
                </div>
            ),
            sorter: true,
        },
        {
            title: "故障描述",
            align: "center",
            dataIndex: "faultDescribe",
        },
        {
            title: "系统",
            align: "center",
            dataIndex: "faultSource",
        },
        {
            title: "SPN",
            align: "center",
            dataIndex: "spn",
        },
        {
            title: "FMI",
            align: "center",
            dataIndex: "fmi",
        },
        {
            title: "车辆品牌",
            align: "center",
            dataIndex: "vehicleBrandName",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入故障码描述",
                valueName: "faultDescribe",
            },
            {
                type: "input",
                placeholder: "请输入故障码",
                valueName: "faultCode",
            },
            {
                type: "select",
                placeholder: "请选择车辆品牌",
                valueName: "vehicleBrand",
                optionList: () =>
                    getPcodeDict("015").children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "faultCode" && (
                <>
                    <TopSearch
                        title="故障码"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="故障码"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                     {exportLoading && <Loading />}
                </>
            )}
        </>
    )
}
