import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useStockCheckModel } from "../stockCheckModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export function PageHeader() {
    const { getSelectedDictOne } = useDictModel(() => []);
    const { whDs, orgDs } = useGlobalModel(({ whDs, orgDs }) => [whDs, orgDs]);

    const {
        searchParam, organType,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useStockCheckModel();

    const searchFields = [
        ...(organType ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        {
            type: "Input",
            placeholder: "盘库单号",
            fieldName: "stocktakingCode",
        },
        {
            type: "Select",
            placeholder: "盘点状态",
            fieldName: "status",
            mode: "multiple",
            options: getSelectedDictOne(DictCodeEnmu.STOCKTAKING)
        },
        {
            type: "Select",
            placeholder: "盘点仓库",
            fieldName: "warehouseCode",
            options: whDs,
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: "Select",
            placeholder: "盘点结果",
            fieldName: "result",
            options: [{ label: "盘盈", value: "盘盈" }, { label: "盘亏", value: "盘亏" }, { label: "相符", value: "相符" }],
        },
    ]

    return (
        <YhPageHeader
            title="库存盘点"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}