import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import { EditableRow, EditableCell } from "./editble"
import ItemDrawer from "./ItemDrawer"

//其他项目
export default ({ materialRef, list }) => {
    const [data, setData] = useState([]) //表格数据
    const [visible, setVisible] = useState(false) //弹框
    useEffect(() => {
        setData(list)
    }, [list])

    //暴露给父组件的方法
    useImperativeHandle(materialRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return data
        },
    }))

    /**
     * 以下是页面展示
     */
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning("请选择你要删除的项目")
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.id) < 0 && MaterialKeys.indexOf(item.temporaryId))
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success("批量删除成功")
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex
        if (row.id) {
            delIndex = newData.findIndex((item) => item.id === row.id)
        } else {
            delIndex = newData.findIndex((item) => item.temporaryId === row.temporaryId)
        }
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
    }

    //table配置
    //table配置
    const columns = [
        {
            title: "序号",
            width: 40,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1,
        },
        {
            title: "商品编号",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "materialCode",
            sorter: (a, b) => a.materialCode.localeCompare(b.materialCode),
        },
        {
            title: "商品名称",
            align: "center",
            width: 200,
            fixed: "left",
            dataIndex: "materialName",
            sorter: (a, b) => a.materialName.localeCompare(b.materialName),
        },
        {
            title: "数量",
            align: "center",
            width: "120px",
            dataIndex: "materialNumber",
            editable: true,
        },
        {
            title: "品牌",
            align: "center",
            width: "120px",
            dataIndex: "brandName",
        },
        {
            title: "备注",
            align: "center",
            width: "120px",
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            dataIndex: "operation",
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaterialOne(record)} />
            },
        },
    ]

    //新增确定
    const setPartList = (selectPart) => {
        if (selectPart.length) {
            let newArr = []
            selectPart.forEach((item) => {
                if (data.findIndex((i) => i.materialId === item.id) === -1) {
                    let itemData = {
                        temporaryId: new Date().getTime() + parseInt(Math.random() * 100000),
                        materialId: item.id,
                        id: null,
                        materialNumber: 1,
                        materialCode: item.code,
                        materialName: item.name,
                        brandName: item.brandName
                    }
                    newArr.push(itemData)
                }
            })
            setData([...data, ...newArr])
        }
        setVisible(false)
    }

    //单元格编辑保存
    const materialHandleSave = (row, status) => {
        const newData = [...data]
        const index = newData.findIndex((item) => row.id === item.id && item.temporaryId === row.temporaryId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>维修用料</span>
                    <div className="list-name-but">
                        <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={() => setVisible(true)} />
                        {!deleteAll ? (
                            <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                批量删除
                            </span>
                        ) : (
                            <>
                                <span className="list-delete" onClick={deleteTemaAll}>
                                    确认删除
                                </span>
                                <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                    取消
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.temporaryId}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
            <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setPartList} drawerMode="part" data={data} />
        </div>
    )
}
