import React, { useEffect, useState, useContext } from "react"
import { Button, Form, Input, Col, Row, Select, Spin, Switch, message, Upload, Image, Modal, Radio } from "antd"
import { getCustomerListNew, insertUser, editUser, getUserOne, getVehicleList } from "@/service/customerService"
import debounce from "lodash/debounce"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

const { Option } = Select

const UserPopupDetail = (props) => {
    const { mode, rowInfo, customerData, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        data: [],
        fetching: false,
        id: "",
    })
    const [idNoList, setIdNoList] = useState({
        //身份证图片list
        file: [],
        md5: "",
    })
    const [licenseFileList, setLicenseFileList] = useState({
        //驾驶证图片list
        file: [],
        md5: "",
    })
    const [imgLookVisible, setImgLookVisible] = useState({
        visible: false,
        md5: "",
    })
    const [vehicleInfo, setVehicleInfo] = useState({
        //车辆列表
        data: [],
        fetching: false,
    })
    useEffect(() => {
        queryMotorcade()
    }, [])

    useEffect(() => {
        console.log(visible)
    }, [visible])
    //订单新增， 带过来客户信息就设置上
    useEffect(() => {
        if (customerData && Object.keys(customerData).length > 0) {
            setCustomerInfo({
                data: [
                    {
                        text: customerData.customerName,
                        value: customerData.customerId,
                    },
                ],
                fetching: false,
                id: customerData.customerId,
            })
            form.setFieldsValue({
                customerId: customerData.customerId,
            })
        }
    }, [customerData])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            getUserOne(rowInfo.id).then((res) => {
                let results = res.retData
                let imgFileList = []
                if (results.driverCardImg) {
                    imgFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${results.driverCardImg}`,
                        },
                    ]
                    //回显行驶证图片
                    setLicenseFileList({
                        file: imgFileList,
                        md5: results.driverCardImg,
                    })
                }
                let idNoFileList = []
                if (results.idCardAddress) {
                    idNoFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${results.idCardAddress}`,
                        },
                    ]
                    //回显驾驶证图片
                    setIdNoList({
                        file: idNoFileList,
                        md5: results.idCardAddress,
                    })
                }

                form.setFieldsValue({
                    ...results,
                    userPassword:"",
                    userStatus: results.userStatus == 0 ? true : false,
                    driverCardImg: imgFileList,
                    idCardAddress: idNoFileList,
                    defaultVehicle: results.defaultVehicle ? results.defaultVehicle : undefined,
                    vehicleId: results.vehicleId ? results.vehicleId : undefined,
                })
                results.customerId &&
                    setCustomerInfo({
                        data: [
                            {
                                text: results.customerName,
                                value: results.customerId,
                            },
                        ],
                        fetching: false,
                        id: results.customerId,
                    }) //客户列表回显
                results.vehicleId &&
                    setVehicleInfo({
                        data: [
                            {
                                text: results.defaultVehicle,
                                value: results.vehicleId,
                            },
                        ],
                        fetching: false,
                    })
            })
        } else {
            form.setFieldsValue({
                userStatus: true,
            })
        }
    }

    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            filterCustomer: 1,
            // customerName: value,
            searchItem:{customerName:value}
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                    })
                }
                return arr
            }, [])
            setCustomerInfo({
                ...customerInfo,
                data: data,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    //客户选择
    const handleChangeCustomer = (value, option) => {
        console.log(option)
        setCustomerInfo({
            ...customerInfo,
            id: option.value,
        })
        //当客户重新选择时  置空车辆和拍照
        form.resetFields(["defaultVehicle", "vehicleId"])
    }

    //车辆列表查询
    const vehicleQuery = (value) => {
        setVehicleInfo({
            data: [],
            fetching: true,
        })
        let D = {
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                vehicleCode: value,
                customerId: customerInfo.id || null,
            },
        }
        getVehicleList(D).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.vehicleCode,
                    value: item.id,
                }))
                setVehicleInfo({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning("该客户下没有车辆")
            }
        })
    }
    //防抖
    const vehicleQuerys = debounce(vehicleQuery, 600)
    //车辆选中, 带出牌照
    const handleChangevehicle = (value, option) => {
        // console.log(value)
        form.setFieldsValue({
            defaultVehicle: value ? option.children : undefined,
        })
    }
    //行驶证图片上传
    const handleVeichleChange = (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setLicenseFileList({
                file: info.fileList,
                md5: md5,
            })
        } else if (info.file.status === "removed") {
            setLicenseFileList({
                file: [],
                md5: "",
            })
        }
    }
    //身份证图片上传
    const handleIdNoChange = (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setIdNoList({
                file: info.fileList,
                md5: md5,
            })
        } else if (info.file.status === "removed") {
            setIdNoList({
                file: [],
                md5: "",
            })
        }
    }
    //行驶证图片查看
    const handlePreview = (md5) => {
        setImgLookVisible({
            visible: true,
            md5: md5,
        })
    }
    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                driverCardImg: licenseFileList.md5,
                idCardAddress: idNoList.md5,
                userStatus: values.userStatus ? 0 : 1,
                defaultVehicle: values.defaultVehicle ? values.defaultVehicle : "",
                vehicleId: values.vehicleId ? values.vehicleId : "",
            }
            delete data.createTime
            if (mode === "edit") {
                data.id = rowInfo.id
                editUser(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                insertUser(data).then((res) => {
                    //查看要关闭的是否被关闭
                    message.success("新增成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    //取消
    const handleCancel = () => {
        setVisible("")
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    return (
        <Modal forceRender visible={visible} title="用户信息" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1260}>
            <div className="form-details-warp">
                <Form form={form} {...formItemLayout}>
                    <Row gutter={100}>
                        <Col offset={3} span={9}>
                            <Form.Item
                                label="客户名称"
                                name="customerId"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择客户",
                                    },
                                ]}
                            >
                                <Select
                                    disabled={isLook(mode)}
                                    showSearch
                                    placeholder="请选择客户"
                                    notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => customerQuery()}
                                    onSearch={customerQuerys}
                                    onChange={handleChangeCustomer}
                                    style={{ width: "100%" }}
                                >
                                    {customerInfo.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                label="真实姓名"
                                name="realName"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入真实姓名",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入真实姓名" disabled={isLook(mode)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={100}>
                        <Col offset={3} span={9}>
                            <Form.Item
                                label="用户手机"
                                name="userMobile"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入手机",
                                    },
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: "请输入正确的手机号",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入用户手机" disabled={isLook(mode)} />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="用户角色" name="userRole">
                                <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择用户角色" style={{ width: "100%" }}>
                                    {getPcodeDict("021").children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={100}>
                        <Col offset={3} span={9}>
                            <Form.Item
                                label="用户名"
                                name="userName"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入账户",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入登录账号" />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="用户密码" name="userPassword">
                                <Input placeholder="请输入用户密码"  disabled={isLook(mode)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={100}>
                        <Col offset={3} span={9}>
                            <Form.Item
                                label="下属车辆"
                                name="vehicleId"
                                // rules={[{ required: true, message: "请选择默认车辆" }]}
                            >
                                <Select
                                    disabled={isLook(mode)}
                                    showSearch
                                    allowClear
                                    placeholder="请选择车辆"
                                    notFoundContent={vehicleInfo.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => vehicleQuery("")}
                                    onSearch={vehicleQuerys}
                                    onChange={handleChangevehicle}
                                    style={{ width: "100%" }}
                                >
                                    {vehicleInfo.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="登记日期" name="createTime">
                                <Input placeholder="自动生成" disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={100}>
                        <Col offset={3} span={9}>
                            <Form.Item
                                label="身份证图片"
                                name="idCardAddress"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                // rules={[{ required: true, message: "请上传行驶证" }]}
                            >
                                <Upload
                                    disabled={isLook(mode)}
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={idNoList.file}
                                    onPreview={() => handlePreview(idNoList.md5)}
                                    onChange={handleIdNoChange}
                                >
                                    {idNoList.file.length >= 1 ? null : <Button>上传身份证</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="驾驶证图片" name="driverCardImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                <Upload
                                    disabled={isLook(mode)}
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={licenseFileList.file}
                                    onPreview={() => handlePreview(licenseFileList.md5)}
                                    onChange={handleVeichleChange}
                                >
                                    {licenseFileList.file.length >= 1 ? null : <Button>上传驾驶证</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={100}>
                        {/* <Col offset={3} span={9}>
                                <Form.Item
                                    label="身份证号"
                                    name="idNo"
                                // rules={[{ required: true, message: "请输入身份证号" }]}
                                >
                                    <Input placeholder="请输入身份证号" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col> */}
                        <Col offset={3} span={9}>
                            <Form.Item name="userStatus" label="用户状态" valuePropName="checked">
                                <Switch disabled={isLook(mode)} checkedChildren="开启" unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item name="loginEnable" label="车队系统登录">
                                <Radio.Group name="radiogroup">
                                    <Radio value={0}>否</Radio>
                                    <Radio value={1}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item hidden name="defaultVehicle">
                        <Input />
                    </Form.Item>
                </Form>
            </div>
            {imgLookVisible.visible && (
                <Modal
                    width={550}
                    onCancel={() => setImgLookVisible({ visible: false, md5: "" })}
                    visible={imgLookVisible.visible}
                    title="图片查看"
                    footer={null}
                >
                    <Image src={`${fileLookUrl().image}${imgLookVisible.md5}`} style={{ padding: "3px" }} />
                </Modal>
            )}
        </Modal>
    )
}
export default UserPopupDetail
