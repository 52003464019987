import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"
import { orderVCancelReviewPage, paymentRefundSync } from "@/service/ordersService"
import TopSearch from "@/components/TopSearch"
import { Select } from "antd"
import { getCustomerListNew, platformUserList } from "@/service/customerService"
import { getPcodeDict } from "@/utils/dataStorage"
import { organPage } from "@/service/orgService"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import ReviewOrder from "./reviewModal"
import { getOrderOne } from "@/service/ordersService"
import YhTooltip from "@/components/YhTooltip"
const { Option } = Select
const ArrUrlStatus = ["examineOrderWait", "examineOrderAdopt", "examineOrderReject"]
const TitleFont = {
    wait: "待审批",
    adopt: "已作废",
    reject: "已驳回",
}
//访问列表
export default ({ status }) => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { state, message, showAllOrgan, dispatch, detailsPage } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    //机构列表
    const [org, setOrg] = useState([])
    const [reviewVisible, setReviewVisible] = useState(false)

    const [dictObj, setDictObj] = useState({})

    useEffect(() => {
        if (ArrUrlStatus.includes(state.activeMenu)) {
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getOrganPage()
        initDict()
    }, [])

    //初始下工单用到的字典
    const initDict = () => {
        let obj = {
            orderStatus: {}, //工单状态
            cancelReason: {}, //工单类型
        }

        getPcodeDict("041").children.forEach((item) => {
            obj.cancelReason[item.dictCode] = item.title
        })
        getPcodeDict("order_status").children.forEach((item) => {
            obj.orderStatus[item.dictCode] = item.title
        })

        setDictObj(obj)
    }

    //获取机构列表
    const getOrganPage = () => {
        organPage({
            searchItem: { orderFlag: 1 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }

    //列表数据
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: { ...search, reviewerStatus: status },
            pageNum: current,
            pageSize: 10,
            // orders: [
            //     {
            //         column: "updateTime",
            //         rule: "DESC",
            //     },
            // ],
        }
        orderVCancelReviewPage(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setList(res.retData.list)
            })
            .finally((res) => {
                setLoading(false)
            })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    const review = () => {
        if (!selectInfo.rowInfo.id) {
            message.warning("请选择你要审批的工单")
            return
        }
        setReviewVisible(true)
    }
    //列表button
    const topButtons = [
        {
            click: () => exportData(),
            content: "导出",
        },
        status === "wait"
            ? {
                  click: () => review(),
                  content: "审批",
              }
            : {},
    ]

    //导出
    const exportData = async () => {
        setExportLoading(true)
        let data = {
            type: "post",
            data: {
                searchItem: { ...searchValue, reviewerStatus: status },
                // orders: [{ column: "updateTime", rule: "DESC" }],
            },
        }
        let resFlie = await download("/ci/order_cancel_review/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    const toOrderDetail = async (id) => {
        const orderData = await getOrderOne(id)
        const mode = "look"
        let tabName = {
            look: "工单查看",
        }
        let modeKey = "ordersManage/" + mode

        await dispatch({
            type: "removeTabs",
            targetKey: modeKey,
        })
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.OrderDetails({
                modeKey,
                mode,
                rowInfo: orderData.retData,
                setPrintVisible: (bool) => {
                    console.log(bool)
                },
                setOrderPayInfo: (obj) => {
                    console.log(obj)
                },
                orderStatus: dictObj.orderStatus,
                parentModeKey: state.activeMenu,
                isCancelReviewStatus: status,
            }),
        })
    }

    //table配置
    const columns = [
        {
            title: "序号",
            width: 40,
            fixed: "left",
            align: "center",
            render: (t, r, i) => i + 1,
        },
        {
            title: "工单编号",
            align: "center",
            dataIndex: "orderCode",
            width: 160,
            render: (text, record) => (
                <div style={{ color: "#2e3aeb", cursor: "pointer" }}>
                    <YhTooltip text={text}  onClick={() => toOrderDetail(record.orderId)}/>
                </div>
            ),
        },
        {
            title: "审批状态",
            align: "center",
            dataIndex: "reviewerStatus",
            render: (text) =>   <YhTooltip text={TitleFont[text]} />,
            width: 90,
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            width: 220,
            render: (text) => <YhTooltip maxWidth={220} text={text} />,
        },
        {
            title: "作废原因",
            align: "center",
            dataIndex: "submitReason",
            width: 160,
            render: (text) =>  <YhTooltip maxWidth={220} text={dictObj.cancelReason[text]} />,
        },
        {
            title: "作废操作人",
            align: "center",
            dataIndex: "submitUserName",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "作废操作时间",
            align: "center",
            dataIndex: "submitTime",
            width: 200,
        },
        {
            title: "审批人",
            align: "center",
            dataIndex: "reviewerName",
            width: 90,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "审批时间",
            align: "center",
            dataIndex: "reviewerTime",
            width: 200,
        },
        {
            title: "驳回原因",
            align: "center",
            dataIndex: "rejectInfo",
            width: 200,
        },
        {
            title: "车辆牌照",
            align: "center",
            dataIndex: "vehicleCode",
            width: 120,
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "工单类型",
            align: "center",
            dataIndex: "orderType",
            width: 90,
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
            width: 140,
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "开单员",
            align: "center",
            dataIndex: "billName",
            width: 90,
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
            width: 120,
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "orderCreateTime",
            width: 200,
        },
    ]
    // 搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入工单编号",
                valueName: "orderCode",
            },
            {
                type: "searchSelect",
                placeholder: "请选择客户名称",
                valueName: "customerId",
                optionName: "customerName",
                optionId: "id",
                searchApi: getCustomerListNew,
                searchItemName: 'customerName'
            },
            {
                type: "input",
                placeholder: "请输入车辆牌照",
                valueName: "vehicleCode",
            },
            {
                type: "select",
                placeholder: "请选择工单类型",
                valueName: "orderType",
                optionList: () =>
                    getPcodeDict("014").children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择客户所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: "searchSelect",
                placeholder: "请选择审批人",
                valueName: "reviewerId",
                optionName: "nickname",
                optionId: "id",
                searchItemName: "nickname",
                searchApi: platformUserList,
            },
            {
                type: "select",
                placeholder: "请选择工单状态",
                valueName: "orderStatus",
                optionList: () =>
                    getPcodeDict("order_status").children.map((item) => {
                        if (item.dictCode !== "completed" && item.dictCode !== "cancelled" && item.dictCode !== "pending_order")
                            return (
                                <Option value={item.dictCode} key={item.id}>
                                    {item.title}
                                </Option>
                            )
                    }),
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
        hideList: [],
    }

    return (
        <>
            <TopSearch
                title={`${TitleFont[status]}工单`}
                searchListData={searchList}
                searchValue={searchValue}
                setSearchValue={(obj) => setSearchValue(obj)}
                handleSearch={loadData}
            />
            <ListComponent
                title={`${TitleFont[status]}工单`}
                buttons={topButtons}
                selectInfo={selectInfo}
                columns={columns}
                dataSource={list}
                pagination={pagination}
                onTableChange={onTableChange}
                setSelectInfo={(obj) => setSelectInfo(obj)}
                scroll={{ x: "max-content" }}
                loading={loading}
            />
            {exportLoading && <Loading />}
            {reviewVisible && (
                <ReviewOrder
                    visible={reviewVisible}
                    setVisible={setReviewVisible}
                    onSuccess={() => {
                        loadData(1, searchValue)
                    }}
                    reviewId={selectInfo.rowInfo.id}
                />
            )}
        </>
    )
}
