import React, { useState, useEffect } from "react";
import { Table, Button, message, Upload } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { useStockDetailedModel } from "../stockDetailedModel";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { importFileConfig, messageConfig } from "@/erp_subpackage/utils/importFileConfig";
import { getRandomStr } from "@/erp_subpackage/utils/util";

export const ShiftShelfModal = () => {
    const { user: { shopId, shopName }, whDs,setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const {
        shiftShelfModal: visible, setShiftShelfModal, onShiftShelf, shelfLoading, setShelfLoading, shelftDownloadTpl
    } = useStockDetailedModel();
    const [selectedRows, setSelectedRows] = useState([]);
    const [shelfDs, setShelfDs] = useState();  // 实时查询货位

    useEffect(() => {
        if (visible) {
            setSelectedRows([]);
            setShelfDs([])
        }
    }, [visible])

    const onCancel = () => {
        setShiftShelfModal(false);
    };

    //货位根据仓库code查询
    const queryShelf = async (wsCode) => {
        const searchParams = {
            shopId,
            warehouseCode: wsCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData: records } = await warehouseService.shelfAllPage(searchParams);

        setShelfDs(records.map((item) => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    //仓库选择  (置空货位)
    const whChange = (value, options, record) => {
        if (options && options.id) {
            queryShelf(options.value)
        }
    }

    //货位触焦
    const shelfFocus = (record) => {
        if (record["warehouseCode1"]) {
            queryShelf(record["warehouseCode1"])
        } else {
            setShelfDs([]);
        }
    }
    const columnsSelect = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            // render: (text) => <YhTooltip text={text} />,
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '原仓库',
            width: 160,
            dataIndex: 'warehouseName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '原货位',
            width: 160,
            dataIndex: 'shelfCode',
        }, {
            title: '原库存',
            width: 160,
            dataIndex: 'inventoryNum',
        }, {
            title: '仓库',
            width: 150,
            dataIndex: "warehouseName1",
        }, {
            title: '货位',
            width: 150,
            dataIndex: 'shelfCode1',
        }, {
            title: '转移库存',
            width: 160,
            dataIndex: 'moveNum',
        }, {
            title: '操作',
            width: 80,
            align: "center",
            fixed: "right",
            className: "td-wrap",
            render: (text, record) => <YhBut
                type="delete"
                txt="移除"
                click={() => {
                    const newData = selectedRows.filter(item => item.key !== record.key);
                    setSelectedRows(newData);
                }}
            />
        }
    ];


    const newSelectColumns = columnsSelect.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row) => {
        let dataSource = [...selectedRows];
        const index = dataSource.findIndex((item) => row.id === item.id);
        //选中货位的话， 赋值货位code
        if (row.shelfCode1) {
            row.shelfId1 = shelfDs?.find(item => item.value === row.shelfCode1)?.id;
        }
        //设置仓库名称
        if (row.warehouseCode1) {
            row.warehouseName1 = whDs.find((item) => item.value === row.warehouseCode1)?.label
        }
        //判断仓库变化时，  重置货位
        if (row.warehouseCode1 !== dataSource[index].warehouseCode1) {
            row.shelfCode1 = undefined
        }

        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });
        setSelectedRows([...dataSource]);
    }

    const upLoadCallback = (retData) => {
        if (!retData) {
            setShelfLoading({ spinning: false });
            return message.warning("数据异常，请尝试重新导入")
        }
        let newShelfRows = [];
        let str = "";
        retData.forEach(item => {
            if (!item.status) {
                str += `【${item.msg}】`
            }
            if (item.list && item.list.length) {
                for (let i = 0; i < item.list.length; i++) {
                    let goods = item.list[i];
                    let findObj = selectedRows.find(originGoods => originGoods.id === goods.id);
                    //过滤掉列表存在的 
                    if (!findObj) {
                        newShelfRows.push({
                            key: getRandomStr(),
                            ...goods,
                            inventoryNum: goods.originalInventoryNum ?? 0,
                            moveNum: goods.originalInventoryNum ?? 0,
                            warehouseCode: goods.originalWarehouseCode,// string,    //"3",
                            warehouseName: goods.originalWarehouseName,// string,    //"3",
                            shelfId: goods.originalShelfId,// string,    //"3",
                            shelfCode: goods.originalShelfCode,// string,    //"3",
                            warehouseCode1: goods.warehouseCode,    //转移仓库,
                            warehouseName1: goods.warehouseName,    //"3",
                            shelfId1: goods.shelfId,    //转移货位,
                            shelfCode1: goods.shelfCode,    //"3",
                            moveType: item.moveType
                        })
                    }
                }
            }
        })
        setShelfLoading({ spinning: false });
        if (!newShelfRows.length) {
            messageConfig("没有匹配到可正常转移的配件" + str, 3, "info");
            return;
        } else {
            messageConfig(str ? "以下配件导入异常：" + str : "导入成功", 20, "info");
        }
        setSelectedRows(origin => [...origin, ...newShelfRows])
    }

    const rightChildren = <div className="flex">
        <Button
            className="btn-item"
            shape="round"
            onClick={() => shelftDownloadTpl()}
        >下载模板</Button>
        <Upload showUploadList={false} className="mr-10 ml-10" {...importFileConfig({
            url: `/ci-storage/stock/details/move/import`,
            callback: upLoadCallback,
            loadingCallback: () => setShelfLoading({ spinning: true, tip: "正在导入中" }),
            isMessage: false,
            params: { shopId, shopName }
        })}>
            <Button className="btn-item" shape="round">导入</Button>
        </Upload>
    </div>
    return <YHModal
        visible={visible}
        onOk={() => {
            let filterPayData = selectedRows.filter(item => (item.shelfCode1));
            if (filterPayData.length !== selectedRows.length) {
                message.warning("请选择要转移的货位");
                return;
            }
            onShiftShelf(selectedRows)
        }}
        onCancel={onCancel}
        title="库存明细-转移货位"
        width={1200}
        bodyHeight={700}
        showFooter={true}
    >
        <YhBox title="已选商品列表" style={{ margin: "0" }} rightChildren={rightChildren}>
            <Table
                loading={shelfLoading}
                rowKey={record => record.key}
                rowClassName='yh-editable-row yh-edit-table-tr'
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns}
                dataSource={selectedRows}
                pagination={false}
                scroll={{ x: 'max-content', y: 450 }}
            />
        </YhBox>
    </YHModal>
}