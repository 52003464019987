import React, { useContext, useState, useEffect } from "react"
import { Button, message } from "antd"
import { DataTable } from "./DataTable"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useStockEntryModel } from "../entryModel"
import { MyContext } from "@/store/myContext"
import { formatNum } from '@/erp_subpackage/utils/util';
import { getOrderOne } from "@/service/ordersService"
import { getPcodeDict } from "@/utils/dataStorage"
export const DataBox = () => {
    const { selectedRowKeys, descTotal, rowsDescTotal, exportEntry } = useStockEntryModel()
    const { dispatch, detailsPage } = useContext(MyContext)

    const desc = [
        {
            label: "入库项数",
            value: !selectedRowKeys.length ? descTotal?.stockNum : rowsDescTotal?.stockNum,
            color: 'red',
        },
        {
            label: "入库总计",
            value: formatNum(!selectedRowKeys.length ? descTotal?.stockAmount : rowsDescTotal?.stockAmount),
            color: 'red'
        },
    ]

    const [dictObj, setDictObj] = useState({})
    useEffect(() => {
        initDict()
    }, [])

     //初始下工单用到的字典
     const initDict = () => {
        let obj = {
            orderStatus: {}, //工单状态
            cancelReason: {}, //工单类型
        }

        getPcodeDict("order_status").children.forEach((item) => {
            obj.orderStatus[item.dictCode] = item.title
        })

        setDictObj(obj)
    }

    const onLook = async (mode, record) => {
        const {tradeCode, tradeTypeCode, id} = record
        let tabName = '', modeKey = ''
        if(tradeTypeCode === 'trade-type.purchase'){// 采购
            tabName = '采购查看'
            modeKey = "purchasePo/" + 'look'
            dispatch({
                deleteKey: modeKey,
                type: "changeTabs",
                name: tabName,
                activeMenu: modeKey,
                content: detailsPage.PurchasePoDetails({
                    modeKey,
                    mode: 'look',
                    dispatch,
                    rowCode: tradeCode
                }),
            })
        }else if(tradeTypeCode === 'trade-type.sale-return'){// 销售退货
            tabName = '销售退货查看'
            modeKey = "saleRo/" + 'look'
            dispatch({
                deleteKey: modeKey,
                type: "changeTabs",
                name: tabName,
                activeMenu: modeKey,
                content: detailsPage.SaleRoDetails({
                    dispatch,
                    modeKey,
                    mode: 'look',
                    rowId: tradeCode,
                }),
            })
        }else  if(tradeTypeCode === 'trade-type.maintenance-return'){// 维修
            const orderData = await getOrderOne(id)
            tabName = '订单查看'
            modeKey = "allOrder/" + 'look'
            dispatch({
                deleteKey: modeKey,
                type: 'changeTabs',
                name: tabName,
                activeMenu: modeKey,
                content: detailsPage.OrderDetails({
                    modeKey,
                    mode,
                    rowInfo: orderData.retData,
                    setPrintVisible: (bool) => {
                        console.log(bool)
                    },
                    setOrderPayInfo: (obj) => {
                        console.log(obj)
                    },
                    orderStatus: dictObj.orderStatus,
                    parentModeKey: 'allOrder',
                }),
            })
        }else{
            message.error('不正确的类型')
        }


        // let modeKey = "stockEntry/" + mode
        // dispatch({
        //     deleteKey: modeKey,
        //     type: "changeTabs",
        //     name: "入库单查看",
        //     activeMenu: modeKey,
        //     content: detailsPage.StockEntryDetails({
        //         dispatch,
        //         modeKey,
        //         mode,
        //         rowId: rowId ?? selectedRowKeys[0].id,
        //     }),
        // })
    }

    const action = (
        <>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行查看，点击鼠标右键可一键选中哦！")
                        return
                    }
                    onLook("look")
                }}
            >查看</Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    exportEntry()
                }}
            >导出</Button>
        </>
    )

    return (
        <YhBox title="入库单列表" descriptions={desc} action={action}>
            <DataTable onLook={onLook} />
        </YhBox>
    )
}
