import React, { useState,useEffect } from "react"
import { Row, Col, DatePicker, Button, Select, Divider, message, Radio, Table, Spin, Progress, Modal } from "antd"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import { teamStatic, getTeamList, createQuotaSettle } from "@/service/teamService"
import moment from "moment"
import debounce from "lodash/debounce"
import styled from "@emotion/styled"
import "./teamStatic.less"
const { Option } = Select
const TeamStatic = ({ teamName, teamId, defaultStartTime, defaultEndTime, teamSetSettlementMethod }) => {
    const [settlementMethod, setSettlementMethod] = useState("point")
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const [teamVal, setTeamVal] = useState(teamId)
    // const [teamData, setTeamData] = useState([]) //团队列表数据
    const [teamData, setTeamData] = useState({
        //团队信息
        data: [],
        fetching: false,
    })
    const [loading, setLoading] = useState(false) //列表loading
    const [startTime, setStartTime] = useState(
        defaultStartTime ? defaultStartTime : moment().startOf('month').format("YYYY-MM-DD")
    )
    const [endTime, setEndTime] = useState(defaultEndTime ? defaultEndTime: moment().startOf('day').format("YYYY-MM-DD"))
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [teamStaticVal, setteamStaticVal] = useState({
        summary: {
            totalWorkHoursAmount: 0,
            totalOrderNum: 0,
            threeGuaranteesOrderNum: 0,
            chargePartAmount: 0,
            chargeWorkHoursAmount: 0,
            chargeOrderNum: 0,
            threeGuaranteesWorkHoursAmount: 0,
            threeGuaranteesPartAmount: 0,
        },
        detail: { records: [] },
        pencent: {},
    })
    const [orderType, setOrderType] = useState("")
    useEffect(() => {
        if (teamId && teamName) {
            setSettlementMethod(teamSetSettlementMethod)
            setTeamData({
                data: [{ text: teamName, value: teamId, settlementMethod: teamSetSettlementMethod }],
                fetching: false,
            })
            setTeamVal(teamId)
        }
    }, [teamId]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (teamVal) {
            getTeamStatic()
        }
    }, [teamVal, orderType]) // eslint-disable-line react-hooks/exhaustive-deps
    const fetchTeam = (value) => {
        setTeamData({
            data: [],
            fetching: true,
        })
        let data = {
            searchItem: { disableFlag: 0, teamName: value },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getTeamList(data).then((res) => {
            let results = res.retData.list
            let dataMap = results.map((team) => ({
                text: team.teamName,
                value: team.id,
                ...team,
            }))

            setTeamData({
                data: dataMap,
                fetching: false,
            })
        })
    }

    //防抖
    const fetchTeams = debounce(fetchTeam, 1000)
    const getTeamStatic = (pageNum = 1) => {
        setLoading(true)
        teamStatic({
            startTime:startTime+'T00:00:00',
            endTime:endTime+'T23:59:59',
            pageNum: pageNum,
            pageSize: 10,
            searchItem: {
                orderType,
                teamId: teamVal,
            },
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        })
            .then((res) => {
                setteamStaticVal(res.retData)
                setpagination({
                    current: pageNum,
                    total: res.retData.detail.total,
                    pageSize: 10,
                })
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }
    const setOrder = async (type) => {
        setOrderType(type)
    }

    //分页事件 置空当前行信息  翻页时置空成员列表
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        getTeamStatic(page.current)
    }

    const pointColumns = [
        {
            title: "工单编号",
            dataIndex: "orderCode",
            key: "orderCode",
            align: "center",
        },
        {
            title: "公司名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "center",
        },
        {
            title: "总工时费",
            dataIndex: "totalWorkHoursAmount",
            key: "totalWorkHoursAmount",
            align: "center",
        },
        {
            title: "团队收益",
            dataIndex: "teamIncome",
            key: "teamIncome",
            align: "center",
        },
        {
            title: "成员收益",
            dataIndex: "teamUserIncome",
            key: "teamUserIncome",
            align: "center",
        },
        {
            title: "竣工日期",
            dataIndex: "finishTime",
            key: "finishTime",
            align: "center",
            
        },
    ]

    const shareRatioColumns = [
        {
            title: "工单编号",
            dataIndex: "orderCode",
            key: "orderCode",
            align: "center",
        },
        {
            title: "公司名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "center",
        },
        {
            title: "总工时费",
            dataIndex: "totalWorkHoursAmount",
            key: "totalWorkHoursAmount",
            align: "center",
        },
        {
            title: "税费",
            dataIndex: "taxFee",
            key: "taxFee",
            align: "center",
        },
        {
            title: "平台服务费",
            dataIndex: "platServiceFee",
            key: "platServiceFee",
            align: "center",
        },
        {
            title: "维修配件收益",
            dataIndex: "repairPartIncome",
            key: "platIncome",
            align: "center",
        },
        // {
        //     title: "团队分成",
        //     dataIndex: "teamIncome",
        //     key: "teamIncome",
        //     align: "center",
        // },
        {
            title: "车服收益",
            dataIndex: "platIncome",
            key: "platIncome",
            align: "center",
        },
        {
            title: "团队收益",
            dataIndex: "teamIncome",
            key: "teamIncome",
            align: "center",
        },
        {
            title: "成员收益",
            dataIndex: "teamUserIncome",
            key: "teamUserIncome",
            align: "center",
        },
        {
            title: "竣工日期",
            dataIndex: "finishTime",
            key: "finishTime",
            align: "center",
        },
    ]

    const quotaColumns = [
        {
            title: "工单编号",
            dataIndex: "orderCode",
            key: "orderCode",
            align: "center",
        },
        {
            title: "公司名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "center",
        },
        {
            title: "总工时费",
            dataIndex: "totalWorkHoursAmount",
            key: "totalWorkHoursAmount",
            align: "center",
        },
        {
            title: "团队收益",
            dataIndex: "teamIncome",
            key: "teamIncome",
            align: "center",
        },
        {
            title: "竣工日期",
            dataIndex: "finishTime",
            key: "finishTime",
            align: "center",
        },
    ]

    // const now = new Date()
    // const initStartTime = new Date(now.getTime() - 1000 * 60 * 60 * 24 * 30)
    const ProgressBox = ({ val }) => {
        if (!val || val === "---") {
            val = "+0.00%"
        }
        const status = val.substring(0, 1),
            num = val.substring(1, val.length - 1)
        return (
            <div style={{ width: "80%", margin: " 20px 5% 0 10%" }}>
                <Progress
                    percent={num}
                    format={(percent) => (
                        <span style={{ color: status === "+" ? "red" : "green" }}>{Number(percent) === 0 ? "--" : `${status}${percent}%`}</span>
                    )}
                    status="active"
                    trailColor="#999"
                    strokeColor={status === "+" ? "red" : "green"}
                />
            </div>
        )
    }


    //生成结算单
    // const handleCreatQuotaSettlement = () => {
    //     Modal.confirm({
    //         title: "提示",
    //         content: (
    //             <p>确定生成{startTime.replace("T"," ")} 至 {endTime.replace("T"," ")}全部工单的结算单吗？</p>
    //         ),
    //         okText: "确定",
    //         cancelText: "取消",
    //         onOk: () => {
    //             createQuotaSettle({teamId:teamVal, settlementStartTime:startTime,settlementEndTime:endTime}).then(res=>{
    //                 message.success("结算单生成成功！")
    //             })
    //         },
    //         onCancel: () => {},
    //     })
    // }
    return (
        <>
            <div className="list-container">
                <div className="list-header">
                    <Row align="middle " justify="space-between">
                        <Col>
                            <Row align="middle">
                                <Col style={{ textAlign: "right" }}>统计时间：</Col>
                                <Col>
                                    <DatePicker.RangePicker
                                        defaultValue={[moment(startTime),moment(endTime)]}
                                        format={"YYYY-MM-DD"}
                                        onChange={(date, str) => {
                                            setStartTime(str[0])
                                            setEndTime(str[1])
                                        }}
                                    />
                                </Col>
                                <Col
                                    style={{
                                        textAlign: "right",
                                        marginLeft: "15px",
                                    }}
                                >
                                    团队：
                                </Col>
                                <Col>
                                    {/* <Select
                                        defaultValue={teamId}
                                        style={{ width: 120 }}
                                        onChange={(v) => {
                                            console.log(v)
                                            setTeamVal(v)
                                        }}
                                    >
                                        {teamData.map((team) => {
                                            return (
                                                <Option value={team.id} key={team.id}>
                                                    {team.teamName}
                                                </Option>
                                            )
                                        })}
                                    </Select> */}
                                    <Select
                                        value={teamVal}
                                        showSearch
                                        placeholder="请选择团队"
                                        notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        onFocus={() => fetchTeam()}
                                        onChange={(v) => {
                                            setSettlementMethod(teamData.data.find((item) => item.id === v).settlementMethod)
                                            setTeamVal(v)
                                        }}
                                        style={{ width: 200 }}
                                        filterOption={false}
                                        onSearch={fetchTeams}
                                    >
                                        {teamData.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Button
                                shape="round"
                                size="small"
                                className="team-but-left"
                                onClick={() => {
                                    getTeamStatic()
                                }}
                            >
                                查询
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Divider style={{ margin: "16px 0" }} />
                <Contentdiv>
                    <ContentItem>
                        <span>总收入</span>
                        <p>{teamStaticVal.summary.totalAmount || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.totalAmount} />
                    </ContentItem>
                    <ContentItem>
                        <span>总工单</span>
                        <p>{teamStaticVal.summary.totalOrderNum || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.totalOrderNum} />
                    </ContentItem>
                    <ContentItem>
                        <span>三包工单</span>
                        <p>{teamStaticVal.summary.threeGuaranteesOrderNum || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.threeGuaranteesOrderNum} />
                    </ContentItem>
                    <ContentItem>
                        <span>收费工单</span>
                        <p>{teamStaticVal.summary.chargeOrderNum || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.chargeOrderNum} />
                    </ContentItem>
                    {settlementMethod === "share_ratio" && (
                        <ContentItem>
                            <span>经营成本</span>
                            <p>{teamStaticVal.summary.bussinessCost || 0}</p>
                            <ProgressBox val={teamStaticVal.pencent.bussinessCost} />
                        </ContentItem>
                    )}
                </Contentdiv>
                <Contentdiv>
                    <ContentItem>
                        <span>三包工时</span>
                        <p>{teamStaticVal.summary.threeGuaranteesWorkHoursAmount || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.threeGuaranteesWorkHoursAmount} />
                    </ContentItem>
                    <ContentItem>
                        <span>三包配件</span>
                        <p>{teamStaticVal.summary.threeGuaranteesPartAmount || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.threeGuaranteesPartAmount} />
                    </ContentItem>
                    <ContentItem>
                        <span>收费工时</span>
                        <p>{teamStaticVal.summary.chargeWorkHoursAmount || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.chargeWorkHoursAmount} />
                    </ContentItem>{" "}
                    <ContentItem>
                        <span>维修配件</span>
                        <p>{teamStaticVal.summary.chargePartAmount || 0}</p>
                        <ProgressBox val={teamStaticVal.pencent.chargePartAmount} />
                    </ContentItem>
                    {settlementMethod === "share_ratio" && (
                        <ContentItem>
                            <span>配件提成</span>
                            <p>{teamStaticVal.summary.sparePartsSalesAmount || 0}</p>
                            <ProgressBox val={teamStaticVal.pencent.sparePartsSalesAmount} />
                        </ContentItem>
                    )}
                </Contentdiv>
                <Divider style={{ margin: "16px 0" }} />
            </div>
            <div className="list-container">
                <div>
                    <div
                        className="list-header"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Radio.Group defaultValue="d">
                            <Radio.Button value="d" onClick={() => setOrder("")}>
                                全部
                            </Radio.Button>
                            <Radio.Button value="014002" onClick={() => setOrder("014002")}>
                                三包
                            </Radio.Button>
                            <Radio.Button value="014001" onClick={() => setOrder("014001")}>
                                收费
                            </Radio.Button>
                        </Radio.Group>
                        <div>
                            {/* {
                                settlementMethod === "quota" &&  <Button
                                shape="round"
                                size="small"
                                className="team-but-left"
                                onClick={handleCreatQuotaSettlement}
                            >
                                生成结算单
                            </Button>
                            } */}
                           
                            <Button
                                shape="round"
                                size="small"
                                className="team-but-left"
                                onClick={async () => {
                                    if (teamStaticVal.detail.records.length === 0) {
                                        message.warning("无可导出数据")
                                        return
                                    }
                                    setExportLoading(true)
                                    let data = {
                                        type: "post",
                                        data: {
                                            startTime:startTime+'T00:00:00',
                                            endTime:endTime+'T23:59:59',
                                            searchItem: {
                                                // teamAttribute,
                                                startTime:startTime+'T00:00:00',
                                                endTime:endTime+'T23:59:59',
                                                orderType,
                                                teamId: teamVal,
                                            },
                                        },
                                    }
                                    let resFlie = await download("/ci/team/teamStaticExport", data)
                                    if (resFlie && resFlie.status === 200) {
                                        message.success("已导出")
                                        convertRes2Blob(resFlie)
                                    } else {
                                        message.warning("导出异常，请重新尝试")
                                    }
                                    setExportLoading(false)
                                }}
                            >
                                导出
                            </Button>
                        </div>
                    </div>
                    {settlementMethod === "point" && (
                        <Table
                            loading={loading}
                            dataSource={teamStaticVal.detail.records.map((item) => {
                                return {
                                    key: item.orderCode,
                                    ...item,
                                }
                            })}
                            onChange={onTableChange}
                            pagination={{
                                showQuickJumper: true,
                                showSizeChanger: false,
                                showTotal: () => `共${pagination.total}条`,
                                position: ["bottomCenter"],
                                ...pagination,
                            }}
                            columns={pointColumns}
                            size="small"
                        />
                    )}

                    {settlementMethod === "share_ratio" && (
                        <Table
                            loading={loading}
                            dataSource={teamStaticVal.detail.records.map((item) => {
                                return {
                                    key: item.orderCode,
                                    ...item,
                                }
                            })}
                            onChange={onTableChange}
                            pagination={{
                                showQuickJumper: true,
                                showSizeChanger: false,
                                showTotal: () => `共${pagination.total}条`,
                                position: ["bottomCenter"],
                                ...pagination,
                            }}
                            columns={shareRatioColumns}
                            size="small"
                        />
                    )}

                    {settlementMethod === "quota" && (
                        <Table
                            loading={loading}
                            dataSource={teamStaticVal.detail.records.map((item) => {
                                return {
                                    key: item.orderCode,
                                    ...item,
                                }
                            })}
                            onChange={onTableChange}
                            pagination={{
                                showQuickJumper: true,
                                showSizeChanger: false,
                                showTotal: () => `共${pagination.total}条`,
                                position: ["bottomCenter"],
                                ...pagination,
                            }}
                            columns={quotaColumns}
                            size="small"
                        />
                    )}
                </div>
            </div>
            {exportLoading && <Loading />}
        </>
    )
}
const Contentdiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const ContentItem = styled.div`
    flex: 1;
    border: 1px solid rgba(98, 98, 98, 0.2);
    text-align: center;
    align-self: center;
    padding: 20px 0;
    margin: 10px 2%;

    span {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        font-size: 14px;
        margin-bottom: 4px;
    }

    p {
        color: rgba(0, 0, 0, 0.65);
        font-size: 24px;
        margin: 0;
    }
`

export default TeamStatic
