import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";

//进销存报表
class AllocService {
    async page(searchVo) {
        const apiUrl = "/sms/transfer/to/managePage";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async export(searchVo) {
        const apiUrl = "/sms/transfer/to/statisticsExport";
        return await httpRequest.postFile(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken(), timeout: 300000 });
    }
}

export const allocService = new AllocService();