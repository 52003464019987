// 商品属性
export const ItemAttr = {
    import: "进口",
    domestic: "国产"
}

//成员类型
export const OperatorUserType = {
    1: "内部员工",
    2: "团队",
    3: "客户",
}

//车载设备类型
export const VehicleEquipmentType = {
    wangwei: "万位",
    "streaming-vip": "雄聚科技"
}

//账目分类属性

export const AccountClassStatus = {
    1:"支出",
    2:"收入",
    3:"成本"
}

//结算方式
export const settlementMethodEmun = {
    point:"点位结算",
    share_ratio:"分成比例结算",
    quota:"定额结算",
    work_hour:"工时结算"
}

//团队结算开票状态
export const teamInvoiceStatus = {
    1: "未开票",
    2: "开票中",
    3: "已开票"
}

//团队结算开票状态
export const teamSettlementStatus = {
    1: "未结算",
    2: "已结算"
}

//维修物料商品状态
export const MaintainOrdeStatus = {
    tobe_picked: "待领取",
    picked: "已领料",
    returning: "退料中",
    returned: "已退料",
}

//维修物料商品状态
export const MaintainOrdeReturnStatus = {
    tobe_review: "待审核",
    reject: "驳回",
    pending_storage: "待入库",
    success: "已入库",
}


//维修物料商品状态
export const MaintainOrdeReturnListStatus = {
    processing: "进行中",
    completed: "已完成"
}


//订单退单状态
export const OrderReturnStatus = {
    pending_review:"待审核",
    billed:"已开单",
    in_stock:"已入库",
    settled:"已结算"
}

//订单退单状态
export const BigBagBillingMethod = {
    mileage:"里程",
    tonnage:"吨位",
    cube:"立方",
    kwh:"千瓦时"
}

// 预约单单据类型
export const appointmentSourceType = {
    zf:"采埃孚工单"
}

// 预约单单据状态
export const appointmentOrderStatus  = {
    tobe_transferred: "待转接",
    transferred: "已转接",
    cancelled: "已取消",
}


// 车型属性
export const vehicleDomestic = {
    0: "进口",
    1: "国产",
}

