import { post, get } from "../utils/request"

// 分类列表
export const instrumentLightLlassifyPage = (data) => {
    return post("/ci-customer/instrument_light_classify/page", data)
}

// 分类新增
export const   insertInstrumentLightLlassify = (data) => {
    return post("/ci-customer/instrument_light_classify/insert", data)
}

// 分类修改
export const   updateInstrumentLightLlassify = (data) => {
    return post("/ci-customer/instrument_light_classify/update", data)
}

// 分类获取一条数据
export const   getInstrumentLightLlassifyOne = (id) => {
    return get(`/ci-customer/instrument_light_classify/one/${id}`)
}


// 仪表灯列表
export const instrumentLightPage = (data) => {
    return post("/ci-customer/instrument_light/page", data)
}

// 仪表灯新增
export const   insertInstrumentLight = (data) => {
    return post("/ci-customer/instrument_light/insert", data)
}

// 仪表灯修改
export const   updateInstrumentLight = (data) => {
    return post("/ci-customer/instrument_light/update", data)
}

// 仪表灯获取一条数据
export const   getInstrumentLightOne = (id) => {
    return get(`/ci-customer/instrument_light/one/${id}`)
}



export const disableSystem = {}