import React from "react"
import { Tooltip, message, Switch } from "antd"
// import { getStorage } from "@/utils/dataStorage"
import { getUserCustomizeConfigOne } from "@/service/settings"
import { getPcodeDict } from "@/utils/dataStorage"
import { OrderReturnStatus } from "@/utils/enum.js"
import YhTooltip from "@/components/YhTooltip"
// import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
export const saveKey = "newRepairListStatisticsTableSort"
// const { getTreeNodeName } = useDictModel(() => []);
export const getAllTable = (props) => {
    let commonData = [
        {
            title: "单据编号",
            key: "orderCode",
        },
        {
            title: "客户名称",
            key: "customerName",
        },
        {
            title: "车辆牌照",
            key: "vehicleCode",
        },
        {
            title: "单据类型",
            key: "tradeType",
        },
        {
            title: "工单类型",
            key: "orderType",
        },
        {
            title: "故障类型",
            key: "faultClassification",
        },
        {
            title: "结算类型",
            key: "settlementAccountType",
        },
        {
            title: "单据状态",
            key: "orderStatus",
        },
        {
            title: "支付状态",
            key: "settlementStatus",
        },
        {
            title: "工时费实收",
            key: "orderItemActualAmount",
        },
        {
            title: "材料费实收",
            key: "orderMaintainMaterialActualAmount",
        },
        {
            title: "材料成本合计",
            key: "materialCostAmount",
        },
        // {
        //     title: "订单毛利合计",
        //     key: "orderGrossProfitAmount",
        // },
        {
            title: "订单实收金额",
            key: "orderActualAmount",
        },
        {
            title: "制单员",
            key: "orderCreateUser",
        },
        {
            title: "制单日期",
            key: "orderCreateTime",
        },
        {
            title: "结算员",
            key: "settlementUser",
        },

        {
            title: "结算日期",
            key: "settlementTime",
        },

    ]
    return commonData
}

export const getTableColumns = async (props, columnsData) => {
    let columns = []
    if (columnsData) {
        columns = columnsData
    } else {
        try {
            // let storageColumns = await getStorage(props.saveKey || saveKey)
            // if (storageColumns) {
            //     columns = storageColumns
            // } else {
            let customizeConfig = await getUserCustomizeConfigOne(props.saveKey || saveKey)
            // debugger
            if (customizeConfig && customizeConfig.retCode == 200) {
                columns =
                    // customizeConfig.retData
                    //     ? JSON.parse(customizeConfig.retData.val)
                    //     : 
                    getAllTable({ isAll: true }).map((item) => {
                        return { ...item, isShow: true }
                    })
            } else {
                message.warning("获取table排序失败，使用默认排序")
                columns = getAllTable({ isAll: true }).map((item) => {
                    return { ...item, isShow: true }
                })
                return
            }
            // }
        } catch (error) {
            message.warning("获取table排序失败，使用默认排序")
            columns = getAllTable({ isAll: true }).map((item) => {
                return { ...item, isShow: true }
            })
        }
    }

    columns = columns.filter((item) => item.isShow)

    columns = columns.map((item) => {
        switch (item.key) {
            case "orderCode":
                return {
                    title: "单据编号",
                    width: 140,
                    align: "center",
                    dataIndex: "orderCode",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "customerName":
                return {
                    title: "客户名称",
                    align: "center",
                    width: 200,
                    dataIndex: "customerName",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "vehicleCode":
                return {
                    title: "车辆牌照",
                    align: "center",
                    dataIndex: "vehicleCode",
                    width: 140,
                    render: (text) => <YhTooltip text={text} />,
                    // render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.orderStatus[text]}/>,
                }
            case "tradeType":
                return {
                    title: "单据类型",
                    align: "center",
                    dataIndex: "tradeType",
                    render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.tradeType[text]} />,
                }
            case "orderType":
                return {
                    title: "工单类型",
                    align: "center",
                    dataIndex: "orderType",
                    render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.orderType[text]} />,
                }
            case "faultClassification":
                return {
                    title: "故障类型",
                    align: "center",
                    dataIndex: "faultClassification",
                    render: (text) => <YhTooltip text={text} />,
                    //render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.faultClassification[text]}/>,
                }
            case "settlementAccountType":
                return {
                    title: "结算类型",
                    align: "center",
                    width: 100,
                    dataIndex: "settlementAccountType",
                    render: (text) => <YhTooltip text={text} />,
                    //render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.settlementAccountType[text]}/>,
                }
            case "orderStatus":
                return {
                    title: "单据状态",
                    align: "center",
                    width: 200,
                    dataIndex: "orderStatusDesc",
                    render: (text) => <YhTooltip text={text} />,
                    //render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.orderStatus[text]}/>,
                }
            case "settlementStatus":
                return {
                    title: "支付状态",
                    align: "center",
                    width: 200,
                    dataIndex: "settlementStatus",
                    render: (text) => <YhTooltip text={text} />,
                    //render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.settlementStatus[text]}/>,
                }
            case "orderItemActualAmount":
                return {
                    title: "工时费实收",
                    align: "center",
                    width: 180,
                    dataIndex: "orderItemActualAmount",
                    // render: (text) => <YhTooltip  title={text} />,
                }
            case "orderMaintainMaterialActualAmount":
                return {
                    title: "材料费实收",
                    align: "center",
                    dataIndex: "orderMaintainMaterialActualAmount",
                    width: 120,
                    //render: (text) => <YhTooltip maxWidth={220} text={text} />,
                }
            case "materialCostAmount":
                return {
                    title: "材料成本合计",
                    align: "center",
                    dataIndex: "materialCostAmount",
                    // render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.serviceMethod[text]}/>,
                    width: 80,
                }
            // case "orderGrossProfitAmount":
            //     return {
            //         title: "订单毛利合计",
            //         align: "center",
            //         dataIndex: "orderGrossProfitAmount",
            //         //render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.tradeType[text]}/>,
            //         width: 80,
            //     }
            case "orderActualAmount":
                return {
                    title: "订单实收金额",
                    align: "center",
                    dataIndex: "orderActualAmount",
                    width: 100,
                    //render: (text) => <YhTooltip maxWidth={220} text={text}/>,
                }
            case "orderCreateUser":
                return {
                    title: "制单员",
                    align: "center",
                    dataIndex: "orderCreateUser",
                    width: 100,
                    render: (text) => <YhTooltip text={text} />,
                }
            case "orderCreateTime":
                return {
                    title: "制单日期",
                    align: "center",
                    dataIndex: "orderCreateTime",
                    width: 180,
                }
            case "settlementUser":
                return {
                    title: "结算员",
                    align: "center",
                    dataIndex: "settlementUser",
                    render: (text) => <YhTooltip text={text} />,
                    //render: (text) => <>{props.dictObj.current.orderSource[text]}</>,
                    width: 90,
                    //render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.orderSource[text]}/>
                }
            case "settlementTime":
                return {
                    title: "结算日期",
                    align: "center",
                    dataIndex: "settlementTime",
                    width: 180,
                }
            default:
                console.log("没有对应的key")
                break
        }
    })
    return columns
}
