import React, { useState, useEffect, useImperativeHandle } from "react"
import debounce from "lodash/debounce"
import { Input, Select, Form, Col, Row, Spin, DatePicker, Radio } from "antd"
import styled from "@emotion/styled"
import { getCustomerListNew } from "@/service/customerService"
import { vehicleBrandnamePage } from "@/service/dataService"
import { getPcodeDict } from "@/utils/dataStorage"
import moment from "moment"

const { Option } = Select
export default (props) => {
    const { detail, mode, basicInfoRef, driverImgInfo, customerData } = props
    const [form] = Form.useForm()
    const [brandNameList, setBrandNameList] = useState([]);
    const [selectBrandId, setSelectBrandId] = useState("");
    const [customerInfo, setCustomerInfo] = useState({
        id: "", //客户列表
        data: [],
        fetching: false,
    })

    useEffect(() => {
        if (detail && detail.basicInfo) {
            const { drivingSignDate, drivingDistributeDate } = detail.basicInfo
            form.setFieldsValue({
                ...detail.basicInfo,
                drivingSignDate: drivingSignDate ? moment(drivingSignDate) : null,
                drivingDistributeDate: drivingDistributeDate ? moment(drivingDistributeDate) : null,
            })
            setCustomerInfo({
                data: [
                    {
                        text: detail.basicInfo.customerName,
                        value: detail.basicInfo.customerId,
                    },
                ],
                fetching: false,
            })


        }
        let data = {
            pageNum: 1,
            pageSize: 1000,
            searchItem: {},
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        vehicleBrandnamePage(data).then((res) => {
            setBrandNameList(res.retData.list);
            let brandId = detail.basicInfo?.brandId == null ? "" : detail.basicInfo?.brandId.replace('牌', '');
            let brand = res.retData.list.filter((e) => e.brandLevel == 1 && e.brandName.replace('牌', '') == brandId);
            if (brand != null && brand.length > 0) {
                setSelectBrandId(brand[0].id);
            }

        })
    }, [detail])

    useEffect(() => {
        if (driverImgInfo) {
            form.setFieldsValue(driverImgInfo)
        }
    }, [driverImgInfo])

    useEffect(() => {
        if (customerData && Object.keys(customerData).length > 0) {
            setCustomerInfo({
                data: [
                    {
                        text: customerData.customerName,
                        value: customerData.customerId,
                    },
                ],
                fetching: false,
            })
            form.setFieldsValue({
                customerId: customerData.customerId,
                customerName: customerData.customerName
            })
        }
    }, [customerData])

    //暴露给父组件的方法
    useImperativeHandle(basicInfoRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return form
        },
    }))

    //是否是特种车
    const [isSpecial, setIsSpecial] = useState(0)
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            ...customerInfo,
            data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            filterCustomer: 1,
            // customerName: value,
            searchItem: { customerName: value }
        }).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                if (item.customerStatus === 0) {
                    arr.push({
                        text: item.customerName,
                        value: item.id,
                    })
                }
                return arr
            }, [])
            setCustomerInfo({
                ...customerInfo,
                data: data,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)
    const handleChangeCustomer = (value, option) => {
        form.setFieldsValue({ motorcadeId: "" })
        if (value) {
            form.setFieldsValue({
                customerName: option.children,
            })
            setCustomerInfo({
                ...customerInfo,
                id: value,
            })
        } else {
            setCustomerInfo({
                ...customerInfo,
                id: "",
            })
        }
    }

    const handleChangebrandName = (value, option) => {
        setSelectBrandId(option.info.id);
    }


    const handleVehicleVinChange = (e) => {
        let vin = e.target.value
        let domestic = form.getFieldValue('domestic')
        if(vin && !domestic){
            let first = vin[0]
            let newDomestic =  first.toLocaleUpperCase() === 'L' ? 1 : 0
            form.setFieldsValue({domestic: newDomestic})
        }
    }

    return (
        <Box>
            <Form form={form} {...formItemLayout}>
                <div className="type">基本信息</div>
                <Row gutter={20}>
                    <Col span={6}>
                        <Form.Item label="客户名称" name="customerId" rules={[{ required: true, message: "请选择客户" }]}>
                            <Select
                                showSearch
                                allowClear
                                placeholder="请选择客户"
                                disabled={isLook(mode)}
                                notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onChange={handleChangeCustomer}
                                onFocus={() => customerQuery()}
                                onSearch={customerQuerys}
                                style={{ width: "100%" }}
                            >
                                {customerInfo.data.map((item) => (
                                    <Option value={item.value} key={item.value}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="车辆牌照"
                            name="vehicleCode"
                            rules={
                                Number(isSpecial) === 1
                                    ? [
                                        {
                                            required: true,
                                            message: "请输入车辆牌照",
                                        },
                                        // {
                                        //     pattern: /^[\u4E00-\u9FA5 | \da-zA-Z]{1,100}$/,
                                        //     message: "请输正确的车辆牌照",
                                        // },
                                    ]
                                    : [
                                        {
                                            required: true,
                                            message: "请输入车辆牌照",
                                        },
                                        {
                                            pattern: /^[\u4E00-\u9FA5][\da-zA-Z]{5,100}$/,
                                            message: "请输正确的车辆牌照",
                                        },
                                    ]
                            }
                        >
                            <Input placeholder="请输入车辆牌照" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆品牌" name="brandId">
                            <Select placeholder="请选择车牌品牌" disabled={isLook(mode)} onChange={handleChangebrandName} style={{ width: '100%' }}>
                                {brandNameList.filter((e) => e.brandLevel == 1).map((item) => (
                                    <Option value={item.brandName} key={item.brandName} info={item}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车系" name="brandSecond">
                            <Select placeholder="请选择车系" disabled={isLook(mode)} style={{ width: '100%' }}>
                                {brandNameList.filter((e) => e.brandLevel == 2 && e.parentId == selectBrandId).map((item) => (
                                    <Option value={item.brandName} key={item.brandName} info={item}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆型号" name="vehicleModelId">
                            <Input placeholder="请输入车辆型号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="动力来源" name="powerSource">
                            <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择动力来源" style={{ width: "100%" }}>
                                {getPcodeDict("022007").children.map((item) => (
                                    <Option value={item.dictCode} key={item.id}>
                                        {item.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="驱动形式" name="driveForm">
                            <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择驱动形式" style={{ width: "100%" }}>
                                {getPcodeDict("050").children.map((item) => (
                                    <Option value={item.dictCode} key={item.id}>
                                        {item.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="使用性质" name="vehicleNature">
                            <Input placeholder="请输入使用性质" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Vin码" name="vehicleVin" rules={[{ required: true, message: "请输入vin" }]}>
                            <Input placeholder="请输入Vin码" disabled={isLook(mode)} onChange={handleVehicleVinChange}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="发动机号" name="engineCode">
                            <Input placeholder="请输入发动机号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆类型" name="vehicleType">
                            <Input placeholder="请输入车辆类型" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆排放" name="vehicleEmission">
                            <Select showSearch allowClear placeholder="请选择车辆排放" style={{ width: "100%" }} disabled={isLook(mode)}>
                                {getPcodeDict("022003").children.map((item) => (
                                    <Option value={item.dictCode} key={item.id}>
                                        {item.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="注册日期" name="drivingSignDate">
                            <DatePicker
                                style={{ width: "100%" }}
                                showTime={{
                                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                                }}
                                disabled={isLook(mode)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="发证日期" name="drivingDistributeDate">
                            <DatePicker
                                style={{ width: "100%" }}
                                showTime={{
                                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                                }}
                                disabled={isLook(mode)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆颜色" name="carColor">
                            <Input placeholder="请输入车辆颜色" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="司机姓名" name="driverName">
                            <Input placeholder="请输入司机姓名" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="司机电话" name="driverPhone">
                            <Input placeholder="请输入司机电话" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="特殊车辆"
                            name="specialVehicle"
                            onChange={(e) => {
                                setIsSpecial(e.target.value)
                                form.validateFields()
                            }}
                        >
                            <Radio.Group>
                                <Radio value={0}>否</Radio>
                                <Radio value={1}>是</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="国产/进口" name="domestic"  rules={[{ required: true, message: "请选择国产/进口" }]}>
                            <Select placeholder="请选择国产/进口" style={{ width: "100%" }}>
                                <Select.Option value={1}>国产</Select.Option>
                                <Select.Option value={0}>进口</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin: 20px 0;
    .type {
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
`
const Lablediv = styled.div`
    text-align: center;
    margin: 10px;
`
