import React, { useState, useEffect } from 'react'
import { numberParseChina, formatNum } from '../../utils/util'
import { Modal, Button, Input, message, Select } from 'antd'
import moment from 'moment'
import './scaniaPrint.css'
import skny from '../../../assets/images/skny.png'
import ouman from '../../../assets/images/ouman.png'
import zf from '../../../assets/images/zf.png'
import iveco from '../../../assets/images/iveco.png'
import { useDictModel } from '../../pages/base/dict/dictModel'
import { getUserCustomizeConfigOne, saveUserCustomizeConfig } from '@/service/settings'
import { globalPrompt } from '../message'
import { type } from 'os'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { orderPrintInfo, printNum, getOrderOne } from "@/service/ordersService"

//打印logo设置
let IIMgEmum = {
    SKNY: 'skny',
    OUMAN: 'ouman',
    ZF: 'zf',
    IVECO: 'iveco',
}
let orderStatusEmum = {
    pending_order: '01', //待派单
    in_maintenance: '02', //待处理（维修中）
    pending_payment: '03', //待付款
    pending_system_review: '04', //待审核（待系统审核）
    completed: '05', //已完成
}
let logoOptions = [
    { label: '斯堪尼亚logo', value: IIMgEmum.SKNY },
    { label: '依维柯logo', value: IIMgEmum.IVECO },
    { label: '曼恩logo', value: IIMgEmum.OUMAN },
]
// let companyOptions = [
//     { label: "北京綦齿机电有限公司", value: "北京綦齿机电有限公司",num:'1' },
//     { label: "北京欧豪汽车贸易有限公司", value: "北京欧豪汽车贸易有限公司",num:'2' },
//     { label: "云豪车服（北京）科技服务有限公司", value: "云豪车服（北京）科技服务有限公司",num:'3' },
//     { label: "天津欧豪富港汽车贸易有限公司", value: "天津欧豪富港汽车贸易有限公司",num:'4' },
// ]
// export let shopObject = {
//     "北京綦齿机电有限公司": {
//         "img": skny,
//         "address": "北京市大兴区长子营镇长通路4号",
//         "phone": "010-81280170",
//         "Bank": "工行北京沙河支行",
//         "BankAccount": "0200011709006770729",
//         "Fax": "010-81280170"
//     },
//     "北京欧豪汽车贸易有限公司": {
//         "img": skny,
//         "address": "北京市大兴区长子营镇长通路4号",
//         "phone": "010-81280170",
//         "Bank": "工行沙河支行",
//         "BankAccount": "0200011719200045213",
//         "Fax": "010-81280170",
//     },
//     "云豪车服（北京）科技服务有限公司": {
//         "img": skny,
//         "address": "北京市大兴区长子营镇长通路4号",
//         "phone": "010-81280170",
//         "Bank": "中国工商银行股份有限公司北京工行沙河支行",
//         "BankAccount": "0200011709200163449",
//         "Fax": "010-81280170"
//     },
//     "天津欧豪富港汽车贸易有限公司": {
//         "img": skny,
//         "address": "天津市津南经济开发区《西区)旺港路15号",
//         "phone": "28577391",
//         "Bank": "中国工商银行股份有限公司天津金地支行",
//         "BankAccount": "0302061609100023871",
//         "Fax": "022-28577398"
//     },
// }
let shopObject = {
    'SHOP-M-00000017': {
        //新疆新陆王
        img: skny,
        address: '新疆乌鲁木齐市米东区金汇西路2877号',
        phone: '0991-5310666     18016861905',
        Bank: '招商银行乌鲁木齐分行解放北路支行',
        BankAccount: '8530 8058 0110 001',
        Fax: '0991-5310666     18016861905',
    },
    'SHOP-M-00000018': {
        //新疆云河汇
        img: ouman,
        phone: '0991-5310666     13209963230',
        Bank: '新疆天山农村银行股份有限公司米东区支行',
        BankAccount: '8070 1001 2010 1140 42179',
        Fax: '0991-5310666     13209963230',
    },
    'SHOP-M-00000020': {
        //天津欧豪富港
        img: skny,
        address: '天津津南经济开发区 旺港路15号',
        phone: '022-28577398',
        Bank: '中国工商银行股份有限公司天津金地支行',
        BankAccount: '0302061609100023871',
        Fax: '022-28577392',
    },
    'SHOP-M-00000019': {
        //沈阳斯堪尼亚
        img: skny,
        address: '辽宁省沈阳市于洪区旺港街201号13门',
        phone: '18600830027',
        Bank: '中国工商银行股份有限公司沈阳于洪支行',
        BankAccount: '3301009209200071238',
        Fax: '',
    },
    'SHOP-M-00000016': {
        //石家庄斯堪尼亚
        img: skny,
        address: '河北省石家庄市无极县经济技术开发区西区泰智产业园14幢3-4座',
        phone: '0311-88036200  ',
        Bank: '中国工商银行股份有限公司栾城支行',
        BankAccount: '0402020709300237638',
        Fax: '',
    },
    'SHOP-M-00000021': {
        //斯堪尼亚北京公司
        img: skny,
        address: '北京市大兴区长子营镇长通路4号',
        phone: '010-81280170',
        Bank: '工行北京沙河支行',
        BankAccount: '0200 0117 0900 6770 729',
        Fax: '010-81280170',
    },
    'SHOP-M-00000029': {
        //内蒙斯堪尼亚
        img: skny,
        address: '北京市大兴区长子营镇长通路4号',
        phone: '010-81280170',
        Bank: '工行北京沙河支行',
        BankAccount: '0200 0117 0900 6770 729',
        Fax: '010-81280170',
    },
}
export const ScaniaPrint = ({
    title,
    printTitle = '维修工单',
    visible,
    onClose,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    formVal,
    price,
    otherClumns,
    otherDataSource,
    type = 1,
    // printNum,
}) => {
    const [imgVal, setImgVal] = useState(skny)
    // const [companyVal, setCompanyVal] = useState(companyOptions.find((item)=>item.num==printNum).label)
    // const [printNumber, setPrintNumber] = useState(printNum)
    const {
        user: { shopCode, orgShopCode },
        setMaterialInfo,
    } = useGlobalModel()

    const print = async () => {
        const el = document.getElementById('scania-print-container')
        const iframe = document.createElement('IFRAME')
        let doc = null
        document.body.appendChild(iframe)
        doc = iframe.contentWindow.document
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./scaniaPrint.css">`)
        doc.write(el?.innerHTML)
        doc.close()
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus()
        iframe.onload = () => {
            iframe.contentWindow.print()
            document.body.removeChild(iframe)
        }
        if (navigator.userAgent.indexOf('MSIE') > 0) {
            document.body.removeChild(iframe)
        }
        await printNum({orderId:visible.orderId,printType:'normal'})
        // saveUserCustomizeConfig({ key: 'printModel', val: printNumber }).then((res) => {
        //     message.success("门店记录成功")
        // })
    }
    // 遍历商品td
    const mapGoods = (goods) => {
        return columns
            .filter((item) => item)
            .map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods) => {
        return itemClumns
            ?.filter((item) => item)
            .map((item, index) => (
                <td colSpan={item.colSpan ?? 1} key={index}>
                    {item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}
                </td>
            ))
    }
    //遍其他项目td
    const otherGoods = (goods) => {
        return otherClumns
            ?.filter((item) => item)
            .map((item, index) => (
                <td colSpan={item.colSpan ?? 1} key={index}>
                    {item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}
                </td>
            ))
    }
    const setLogo = () => {
        let logoVal = IIMgEmum.SKNY
        globalPrompt('modal', {
            width: 380,
            title: '设置logo',
            type: 'info',
            // text: "",
            text: (
                <>
                    <span>请选择Logo类型</span>
                    <Select showSearch={true} defaultValue={IIMgEmum.SKNY} options={logoOptions} onChange={(value, option) => (logoVal = value)} />
                </>
            ),
            okText: '确定',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    console.log(logoVal, 'aaaaaa')
                    resolve(logoVal)
                }).then((res) => {
                    switch (logoVal) {
                        case IIMgEmum.SKNY:
                            return setImgVal(skny)
                        case IIMgEmum.OUMAN:
                            return setImgVal(ouman)
                        case IIMgEmum.ZF:
                            return setImgVal(zf)
                        case IIMgEmum.IVECO:
                            return setImgVal(iveco)
                        default:
                            return setImgVal(skny)
                    }
                    // setImgVal(logoVal)
                })
            },
            cancelText: '取消',
            onCancel: () => {},
        })
    }
    // const setCompany = () => {
    //     let companyValue = ''
    //     globalPrompt("modal", {
    //         width: 570,
    //         title: "设置公司",
    //         type: "info",
    //         // text: "",
    //         text: <>
    //             <span style={{ marginRight: '30px' }}>请选择公司信息</span>
    //             <Select
    //                 showSearch={true}
    //                 defaultValue={"北京綦齿机电有限公司"}
    //                 options={companyOptions}
    //                 onChange={(value, option) => companyValue = value}
    //             />
    //         </>,
    //         okText: "确定",
    //         onOk: () => {
    //             return new Promise((resolve, reject) => {
    //                 console.log(companyValue, "aaaaaa")
    //                 resolve(companyValue)
    //             }).then(res => {
    //                 switch (companyValue) {
    //                     case '北京綦齿机电有限公司':
    //                         setPrintNumber('1')
    //                         return setCompanyVal('北京綦齿机电有限公司')
    //                     case '北京欧豪汽车贸易有限公司':
    //                         setPrintNumber('2')
    //                         return setCompanyVal('北京欧豪汽车贸易有限公司')
    //                     case '云豪车服（北京）科技服务有限公司':
    //                         setPrintNumber('3')
    //                         return setCompanyVal('云豪车服（北京）科技服务有限公司')
    //                     case '天津欧豪富港汽车贸易有限公司':
    //                         setPrintNumber('4')
    //                         return setCompanyVal('天津欧豪富港汽车贸易有限公司')
    //                     default:
    //                         setPrintNumber('1')
    //                         return setCompanyVal('北京綦齿机电有限公司')
    //                 }
    //             })
    //         },
    //         cancelText: "取消",
    //         onCancel: () => {
    //         }
    //     })
    // }
    const imgText = (type) => {
        let text = null
        switch (type) {
            case skny:
                return (text = '斯堪尼亚')
            case ouman:
                return (text = '曼恩')
            case zf:
                return (text = '采埃孚')
            case iveco:
                return (text = '依维柯')
            default:
                return (text = '斯堪尼亚')
        }
    }

    return (
        <Modal
            title="打印详情"
            width={800}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button type="primary" onClick={onClose} key="back">
                    {' '}
                    取消
                </Button>,
                <Button type="primary" onClick={print} key="submit">
                    确认打印
                </Button>,
                <Button type="primary" onClick={setLogo} key="logo">
                    设置Logo
                </Button>,
                //<Button type="primary" onClick={setCompany} key="company">门店设置</Button>,
            ]}
        >
            <div id="scania-print-container">
                <div className="scaina-contaier">
                    <div className="scaina-header">
                        <div className="header-left">
                            <div className="logo">
                                <img src={imgVal} alt="斯堪尼亚" />
                            </div>
                            <h1>{printTitle}</h1>
                            <table>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            委托客户：<span>{formVal.customerName}</span>
                                        </td>
                                    </tr>
                                    <tr className="tableft">
                                        <td>
                                            制造厂商：<span>{imgText(imgVal)}</span>
                                        </td>
                                        {/* <td>车辆类型：{formVal.vehicleTypeName}</td> */}
                                    </tr>
                                    <tr className="tableft">
                                        <td>
                                            车牌号：<span>{formVal.licensePlate}</span>
                                        </td>
                                        <td>
                                            底盘号：<span>{formVal.vin}</span>
                                        </td>
                                    </tr>
                                    <tr className="tableft">
                                        <td>
                                            送修人：<span>{formVal.contact}</span>
                                        </td>
                                        <td>
                                            联系电话：<span>{formVal.phone}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            修理类型：<span>{formVal.serviceTypeName}</span>
                                        </td>
                                        <td>
                                            车辆类型：<span>{formVal.vehicleTypeName}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            客户地址：<span>{formVal.customerAddress}</span>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                    <td colSpan={2}>结算方式：<span>{getTreeNodeName(formVal.payType)}</span></td>
                                </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <div className="header-rights">
                            <h2 style={{ fontWeight: '600' }}>{title}</h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>服务厂地址：{shopObject[shopCode] ? shopObject[shopCode]['address'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td>电话：{shopObject[shopCode] ? shopObject[shopCode]['phone'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td>传真：{shopObject[shopCode] ? shopObject[shopCode]['Fax'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td>开户银行：{shopObject[shopCode] ? shopObject[shopCode]['Bank'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td>开户行账号：{shopObject[shopCode] ? shopObject[shopCode]['BankAccount'] : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: '5px' }}></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            工单号：<span>{formVal.maintenanceCode ?? formVal.appointmentCode}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            预约进厂时间：<span>{formVal.enterTime}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            预约出厂时间：<span>{formVal.deliveryTime}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>接单员：{formVal.createUserName}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            行驶里程：{formVal.mileage}
                                            <span style={{ marginLeft: '15px' }}>公里/每小时</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table className="mingxi">
                        <tbody>
                            <tr>
                                <td colSpan={columns.length}>
                                    故障描述：<span>{formVal.faultDesc}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={columns.length}>
                                    原因分析和处理意见：<span>{formVal.faultResult}</span>
                                </td>
                            </tr>
                            <tr className="print-center">
                                {itemClumns &&
                                    itemDataSource &&
                                    itemDataSource.length > 0 &&
                                    itemClumns
                                        .filter((item) => item)
                                        .map((item, index) => (
                                            <td colSpan={item.colSpan ?? 1} key={index}>
                                                {item.title}
                                            </td>
                                        ))}
                            </tr>
                            {itemDataSource &&
                                itemDataSource.length > 0 &&
                                itemDataSource.map((item, index) => (
                                    <tr key={index} className="print-center">
                                        {itemGoods(item)}
                                    </tr>
                                ))}
                            <tr>
                                <td style={{ textAlign: 'right', paddingRight: '15px' }} colSpan={columns.length}>
                                    工时小计：<span>{type === 1 ? price.workingHoursAmount : 0}</span>
                                </td>
                            </tr>
                            <tr className="print-center">
                                {columns &&
                                    dataSource &&
                                    dataSource.length > 0 &&
                                    columns
                                        .filter((item) => item)
                                        .map((item, index) => (
                                            <td colSpan={item.colSpan ?? 1} key={index}>
                                                {item.title}
                                            </td>
                                        ))}
                            </tr>
                            {dataSource &&
                                dataSource.length > 0 &&
                                dataSource.map((item, index) => (
                                    <tr key={index} className="print-center">
                                        {mapGoods(item)}
                                    </tr>
                                ))}
                            <tr>
                                <td style={{ textAlign: 'right', paddingRight: '15px' }} colSpan={columns.length}>
                                    零件小计：<span>{type === 1 ? price.materialScienceAmount : 0}</span>
                                </td>
                            </tr>

                            <tr className="print-center">
                                {otherClumns &&
                                    otherDataSource &&
                                    otherDataSource.length > 0 &&
                                    otherClumns
                                        .filter((item) => item)
                                        .map((item, index) => (
                                            <td colSpan={item.colSpan ?? 1} key={index}>
                                                {item.title}
                                            </td>
                                        ))}
                            </tr>
                            {otherDataSource &&
                                otherDataSource.length > 0 &&
                                <>
                                {otherDataSource.map((item, index) => (
                                    <tr key={index} className="print-center">
                                        {otherGoods(item)}
                                    </tr>
                                ))}
                            <tr>
                                <td style={{ textAlign: 'right', paddingRight: '15px' }} colSpan={9}>
                                    其他费用：<span>{type === 1 ? price.otherItemActualAmount : 0}</span>
                                </td>
                            </tr>
                            </>
}
                            <tr>
                                <td style={{ textAlign: 'right', paddingRight: '15px' }} colSpan={columns.length / 2}>
                                优惠金额：<span>{type === 1 ? price.orderPreferentialAmount : 0}</span>
                                </td>
                                <td style={{ textAlign: 'right', paddingRight: '15px' }} colSpan={columns.length / 2 + 1}>
                                    总合计：<span>{type === 1 ? price.totalAmount : 0}</span>
                                </td>
                            </tr>
                            <tr className="re">
                                <td colSpan={columns.length}>
                                    <p>备注：{formVal.note}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="footer">
                        <p>
                            制单员：<span>{formVal.createUserName}</span>
                            <>
                                <span style={{ marginLeft: '120px' }}>质检：</span>
                                <span style={{ marginLeft: '120px' }}>领料：</span>
                            </>
                        </p>
                        <p>声明：</p>
                        {/* {(title === '新疆新陆王汽车贸易有限公司' || title === '新疆云河汇机电设备有限责任公司') &&
                        <>
                            <p>1.如需预留或订购配件请预交定金至我司指定账户；</p>
                            <p>2.所列维修项目及零件以现场实际产生为准，本人同意进行修理并支付由此产生的零件及修理费用；</p>
                            <p>3.车辆驾驶室翻转前请收拾好驾驶室内物品，如由此导致的挡风玻璃破裂损坏，我司不予赔偿；</p>
                            <p>4.车主提车时，请对我司维修/保养项目及车况进行检查验收并签字确认；</p>
                            <p>5.我司仅对本次发生维修的斯堪尼亚零件进行质保(质保范围请查阅斯堪尼亚保修手册)；</p>
                            <p>6.该报价单从出单时间起24小时内有效。  </p>
                        </>
                    } */}
                        {/* {title !== '新疆新陆王汽车贸易有限公司' && title !== '新疆云河汇机电设备有限责任公司' && */}
                        <>
                            <p>1.本人同意按贵厂工单所列的修理项目修理，愿意支付有关项目需要更换的零件款及修理费；</p>
                            <p>2.车辆翻新，拆装前后挡风玻璃，如有损坏，我厂概不赔偿；</p>
                            <p>3.车主提车时，请对我厂修理项目，车况检查清楚，出厂后概不负责。</p>
                        </>
                        {/* } */}
                        <p style={{ marginTop: '5px' }} className={`weituo`}>
                            委托人：<span>&nbsp;</span>
                        </p>
                        <p style={{ textAlign: 'right' }}>打印日期:&nbsp;&nbsp;&nbsp;{moment(new Date()).format('YYYY-MM-DD HH:mm')}</p>
                        <p style={{ marginTop: '5px', fontWeight: '600' }}>{orderStatusEmum[formVal.orderStatus]}</p>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
