import React, { useContext, useEffect, useState } from "react"
import { Button, Table, Image, Upload, message } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useProductModel } from "../../productModel"
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable"
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import { YhTag } from "@/erp_subpackage/components/YhTag"
import { MyContext } from "@/store/myContext"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { formatInt, formatNum } from "@/erp_subpackage/utils/util"
import { shopLogin } from "@/utils/util"
import { shopCatsDatas } from "@/service/shop"
import { SHOP_API_FILE_SERVE } from "@/config/app"
import { productService } from "../../productService"

function forFind(array, value) {
    if (!value) return ""
    for (const item of array) {
        if (item.value === value) {
            return item
        }
        if (item.children && item.children.length > 0) {
            let forFindRet = forFind(item.children, value)
            if (forFindRet) {
                return forFindRet
            }
        }
    }
}

export const ProductDetails = ({ modeKey, mode, goodList, dispatch }) => {
    const {
        user: { shopId },
    } = useGlobalModel()
    const { upDataList, setUpDataList, productUpDown } = useProductModel()
    const { detailsPage } = useContext(MyContext)
    const { getTreeNodeName } = useDictModel()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [timer, setTimer] = useState()
    const [typeCodeDs, setTypeCodeDs] = useState([])

    // 获取商城分类
    const getShopCatsList = async (id = "0") => {
        let res = await shopCatsDatas(id)
        let tempArr = res.map((item) => ({
            label: item.name,
            value: item.category_id,
            isLeaf: false,
        }))
        return tempArr
    }

    //动态加载所属分类
    const cascaderLoadData = (selectedOptions) => {
        console.log("000", selectedOptions)
        const targetOption = selectedOptions[selectedOptions.length - 1]
        targetOption.loading = true
        getShopCatsList(targetOption.value).then((res) => {
            targetOption.loading = false
            targetOption.children = [...res]
            setTypeCodeDs([...typeCodeDs])
        })
    }

    const goBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "product",
        })
    }
    const getShopToken = () => {
        shopLogin()
        if (timer) {
            clearTimeout(timer)
            setTimer(undefined)
        }
        const time = setTimeout(() => {
            getShopCatsList(0).then((res) => {
                res && setTypeCodeDs(res)
            })
        }, 1000)
        setTimer(time)
    }

    useEffect(() => {
        let newList = goodList.map((item) => {
            return {
                ...item,
                typeCode: item.typeCodeList.length > 0 ? item.typeCodeList : [],
                typeCodeList: [],
            }
        })
        setUpDataList(newList)
        getShopToken()
    }, [])

    const action = (
        <>
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() =>
                    productUpDown({
                        upStatus: DictCodeEnmu.DISPLAY_STATUS_ALREADY,
                        reason: "",
                    }).then((res) => {
                        res && goBack()
                    })
                }
            >
                保存
            </Button>
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() => {
                    goBack()
                }}
            >
                取消
            </Button>
        </>
    )

    const onLook = (mode, code) => {
        let tabName = {
            look: "明细查看",
        }
        let modeKeys = "product/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKeys,
                content: detailsPage.ProductGoodsDetails({
                    dispatch,
                    modeKey: modeKeys,
                    mode,
                    rowId: code,
                    type: "down",
                    goodList: upDataList,
                    parentModeKey: modeKey,
                }),
            })
        }
        if (mode === "look") {
            handleDispatch()
        }
    }

    // Handle
    const onCascaderFocus = (row) => {
        console.log(row)
    }

    // 图片上传服务
    const uploadInsert = async (code, file) => {
        const data = {
            shopCode: shopId,
            code,
            photoMd5: file.response.url,
        }
        const { retData } = await productService.uploadImg(data)
        return retData
    }

    const columns = [
        {
            title: "序号",
            width: 80,
            align: "center",
            dataIndex: "branch",
            fixed: "left",
            render: (text, record, index) => index + 1,
        },
        {
            title: "上架状态",
            width: 100,
            dataIndex: "upStatus",
            render: (text) => <YhTag status={text} text={getTreeNodeName(text)} />,
        },
        {
            title: "商品图片",
            width: 160,
            dataIndex: "photo",
            render: (text, records, index) => {
                return <>
                    {
                        text.length > 0 && text.map(item => <Image src={item.photoMd5} style={{ width: "40px", height: "40px", marginRight: "10px" }} key={item.id} />)
                    }
                    <Upload
                        showUploadList={false}
                        beforeUpload={(file ) => {
                            let fileEnd = file.name?.substring(file.name.lastIndexOf(".")) ?? ""
                            let filetypes = [".jpg", ".jpeg", ".png", ".gif", ".svg"]
                            let isType = filetypes?.includes(fileEnd)
                            if (!isType) {
                                message.error("不支持该格式的上传")
                                return false
                            }
                            const isLt2M = file.size / 1024 / 1024 < 2
                            if (!isLt2M) {
                                message.error("上传到文件不能超过2M")
                                return false
                            }
                            return true
                        }}
                        {...{
                            name: "file",
                            action: `${SHOP_API_FILE_SERVE}/uploaders?scene=goods`,
                            timeout: 300000,
                            headers: {
                                timeout: 300000,
                            },
                            onChange(info) {
                                try {
                                    if (info.file.status === "done" && info.file.response && info.file.response.url) {
                                        message.info({
                                            content: `${info.file.name}上传成功`,
                                            duration: 10,
                                        })
                                        uploadInsert(records.code, info.file).then((res) => {
                                            // let filterData = upDataList.filter((item, i) => i !== index)
                                            let dataSource = [...upDataList]
                                            const item = dataSource[index]
                                            item.photo = [...res]
                                            dataSource.splice(index, 1, item)
                                            setUpDataList(dataSource)
                                        })
                                    } else if (info.file.status === "error" && info.file.response) {
                                        message.error(`${info.file.name}:${info.file.response.message}导入异常，请尝试重新导入`)
                                    } else if (info.file.status === "error") {
                                        message.error("导入错误")
                                    }
                                } catch (error) {
                                    message.warning("导出异常，请重新尝试")
                                }
                            },
                        }}
                    >
                        <Button className="btn-item" shape="round">
                            上传
                        </Button>
                    </Upload>
                </>
            }
        },
        {
            title: "商品编号",
            width: 120,
            dataIndex: "code",
            render: (text, record) => <p className="one-line click-span">{text}</p>,
        },
        {
            title: "商品名称",
            width: 120,
            dataIndex: "name",
        },
        {
            title: "品牌",
            width: 80,
            dataIndex: "brandName",
        },
        {
            title: "所属分类",
            width: 160,
            dataIndex: "typeCodeList",
            editable: true,
            editType: "cascader",
            cascaderOptions: typeCodeDs,
            cascaderLoadData: cascaderLoadData,
            cascaderFocus: onCascaderFocus,
            render: (text, record) => record.typeName ?? "请选择所属分类",
        },
        {
            title: "上架价格",
            width: 140,
            dataIndex: "upPrice",
            editable: true,
        },
        {
            title: "上架库存",
            width: 120,
            dataIndex: "upNum",
            editable: true,
        },
        {
            title: "可用库存",
            width: 100,
            dataIndex: "availableStock",
        },
        {
            title: "商城价格",
            width: 120,
            dataIndex: "shopPrice",
        },
        {
            title: "最新进价",
            width: 120,
            dataIndex: "latestPrice",
        },
        {
            title: "操作",
            width: 160,
            fixed: "right",
            align: "center",
            render: (text, record) => <YhTag onClick={() => onLook("look", record.code)} color="red" text="查看明细"></YhTag>,
        },
    ]

    const newColumn = columns.map((col, index) => {
        let disaBled = col.dataIndex === "upNum"
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                treeList: col?.treeList,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                onTreeSelectChange: col?.onTreeSelectChange,
                cascaderOptions: col?.cascaderOptions,
                cascaderLoadData: col?.cascaderLoadData,
                cascaderChange: col?.cascaderChange,
                cascaderFocus: col?.cascaderFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row) => {
        let { upPrice, upNum, code, availableStock, typeCodeList } = row
        upPrice = +formatNum(upPrice)
        // upNum = +formatInt(upNum, availableStock, "上架库存 > 可用库存")
        upNum = +formatInt(upNum)
        let newRow = {
            ...row,
            upPrice,
            upNum,
            typeCodeList,
            typeName: typeCodeList?.map((item) => forFind(typeCodeDs, item)?.label ?? "").join("/"),
        }
        let newData = [...upDataList]
        const index = newData.findIndex((item) => code === item.code)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...newRow })
        setUpDataList([...newData])
    }
    return (
        <YhBox title="商品上架" rightChildren={action} style={{ marginBottom: "75px" }}>
            <Table
                rowClassName="yh-editable-row yh-edit-table-tr"
                rowKey={(record) => record.code}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys)
                    },
                }}
                columns={newColumn}
                dataSource={upDataList}
                pagination={false}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                scroll={{ x: "max-content" }}
            />
        </YhBox>
    )
}
