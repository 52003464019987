import { useState } from "react";
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination, defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { warehouseService } from "./warehouseService";
import { message } from "antd";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";

export const useWarehouseModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam;
    const { user: { shopId, shopName, shopCode, userName, orgCode, orgName, organType }, loadWarehouseAllData } = useGlobalModel()
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading(true);
        const searchParams = {
            ...searchValue,
            //查询仓库类型是查询全部机构
            shopId,
            shopName, shopCode,
            typeCode: searchValue.typeCode === "全部仓库" ? undefined : searchValue.typeCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await warehouseService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading(false);
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    /*
    详情 
    */
    const [addWareVisible, setAddWaerVisible] = useState(false);  //仓库新增

    //仓库新增
    const insertWarehouse = async (data) => {
        let params = {
            ...data,
            shopId, shopName, shopCode,
            sourceApp, branch, orgCode, orgName,
            createUser: userName,
        }
        let { retData } = await warehouseService.insert(params);
        loadData();
        loadWarehouseAllData()
        message.success("仓库新增成功")
        return retData;
    }

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState({});
    //表单修改
    const updateFormVal = (mode, val) => {
        setEditFormVal(origin => val ? { ...origin, ...val } : {});
    }

    const getWarehouseOne = async (id) => {
        let { retData } = await warehouseService.one(id);
        retData && setEditFormVal(retData);
        return retData;
    }

    //表单编辑
    const updateWarehouse = async (data) => {
        let params = {
            ...editFormVal,
            ...data,
            sourceApp,
        }
        let { retData } = await warehouseService.update(params);
        getWarehouseOne(editFormVal.id);
        loadWarehouseAllData()
        message.success("仓库编辑成功")
        return retData;
    }


    /**
     * 仓库 货位
     */
    const initShelfDrawer = { visible: false, mode: "add" };
    const [shelfDrawer, setShelfDrawer] = useState(initShelfDrawer); //货位详情弹框
    const setIShelfDrawer = (mode) => setShelfDrawer(mode ? { visible: true, mode } : initShelfDrawer);

    const [shelfDataSource, setShelfDataSource] = useState([]);
    const [shelfPgCfg, setShelfPgCfg] = useState(initPgCfg);  //分页参数
    const [selectedShelfRows, setSelectedShelfRows] = useState([]); //多选Shelf行信息
    const [shelfSearch, setShelfSearch] = useState({});

    //货位列表
    const shelfLoadData = async (page, searchValue = shelfSearch) => {
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            shopName, shopCode,
            warehouseId: searchValue.warehouseId ?? editFormVal.id,
            warehouseCode: searchValue.warehouseCode ?? editFormVal.code,
            pageSize: page?.pageSize ?? shelfPgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? shelfPgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        setShelfPgCfg(transPgToPagination(pg));
        setShelfDataSource(records);
    }

    //货位新增   isBatch区分是否是批量新增、进行不同api调用
    const insertWarehouseShelf = async (fields) => {
        let { isBatch, num, prefix, startCode, ...val } = fields;
        let data = {
            ...val,
            shopId, shopName, shopCode,
            sourceApp, branch, orgCode, orgName,
            warehouseId: editFormVal.id,
            warehouseCode: editFormVal.code,
        }
        let { retData } = await isBatch ?
            warehouseService.shelfBatchInsert({
                ...data,
                num, prefix, startCode,
                code: ""
            })
            : warehouseService.shelfInsert(data);
        setIShelfDrawer();
        message.success("货位新增成功");
        setTimeout(() => { shelfLoadData() }, 800)
        return retData;
    }

    //货位编辑
    const updateWarehouseShelf = async (fields) => {
        let { isBatch, ...val } = fields;
        let data = {
            ...val,
            id: selectedShelfRows[0].id,
            shopId, shopName, shopCode,
            orgCode, orgName,
            warehouseId: editFormVal.id,
            warehouseCode: editFormVal.code,
        }
        let { retData } = await warehouseService.shelfUpdate(data);
        message.success("货位编辑成功");
        setIShelfDrawer();
        setTimeout(() => { shelfLoadData() }, 800)
    }
    //货位状态
    const onDisabled = async (row, checked) => {
        let { retData } = await warehouseService.shelfUpdate({ ...row, status: checked ? 1 : 0 });
        message.success("操作成功");
        setTimeout(() => { shelfLoadData() }, 800)
        return retData;
    }
    const downloadTplShelf = async () => {
        let result = await warehouseService.shelfDownloadTpl();
        convertRes2Blob(result)
    }

    return {
        /***列表***/
        searchParam, organType, shopId,
        dataSource, setDataSource,
        selectedRowKeys,
        pgCfg,
        loading,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        /*详情 */
        shopName,
        addWareVisible, setAddWaerVisible,
        insertWarehouse,
        updateWarehouse,
        editFormVal, updateFormVal,
        getWarehouseOne,
        /*货位 */
        shelfDrawer,
        setIShelfDrawer,
        shelfDataSource,
        shelfPgCfg, setShelfPgCfg,
        shelfLoadData,
        selectedShelfRows, setSelectedShelfRows,
        shelfSearch, setShelfSearch,
        insertWarehouseShelf, updateWarehouseShelf,
        downloadTplShelf, onDisabled
    }
});