import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { RemainNumModal } from "./RemainNumModal/index";
import { OccupyNumModal } from "./OccupyNumModal";
import { CompensateNumModal } from "./CompensateNumModal";
import { StartNumModal } from "./StartNumModal";
import { DetailsDrawer } from "./DetailDrawer";

//新 psi进销存报表
export default () => {
    return (
        <>
            <PageHeader />
            <DataBox />
            <RemainNumModal />
            <OccupyNumModal />
            <StartNumModal />
            <CompensateNumModal />
            <DetailsDrawer />
        </>
    )
}