import React from "react";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { Table } from "antd";
import { useStockOutputModel } from "../../outputModel";
import { useGlobalModel, transPgToPagination } from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"


export const TableBox = () => {
    const { detailLookList } = useStockOutputModel(({ detailLookList }) => [detailLookList]);
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const colums = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            fixed: "left",
        }, {
            title: '所属库房',
            width: 160,
            dataIndex: 'warehouseName',
        }, {
            title: '所属货位',
            width: 160,
            dataIndex: 'shelfCode',
        }, {
            title: '数量',
            width: 160,
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 120,
            dataIndex: 'price',
        }, {
            title: '折扣',
            width: 120,
            dataIndex: 'discountRate',
        }, {
            title: '总计',
            width: 120,
            dataIndex: 'amount',
        }
    ]
    return (
        <YhBox title="商品信息" >
            <Table
                rowKey={record => record.id}
                columns={colums}
                dataSource={detailLookList}
                pagination={false}
                scroll={{ x: 'max-content', y: 400 }}
            >
            </Table>
        </YhBox>
    )
}