import React, { useState, useEffect } from 'react'
import { Input, Button, Table, message, Select, Tooltip } from 'antd'
import Drawer from '@/components/Drawer'
import { getStockDetailsFindPage } from '@/service/ordersService'
import { objectValueAllEmpty } from '@/utils/util'
import { ItemAttr } from '@/utils/enum'
import { getUserOrgan } from '@/utils/dataStorage'
import { YhBut } from '@/erp_subpackage/components/YhButton'
import YhTooltip from '@/erp_subpackage/components/YhTooltip'
import { useGlobalModel, transPgToPagination } from '@/erp_subpackage/model/globalModel'
import { EditableRow, EditableCell } from "./editable"

const ItemAttraArr = Object.entries(ItemAttr)
export default ({ visible, onClose, onItemOk }) => {
    const {
        user: { shopId, shopName, shopCode },
        whDs,
        setMaterialInfo,
    } = useGlobalModel(({ user, whDs }) => [user, whDs])
    const [searchValue, setSearchValue] = useState({})
    const [addData, setAddData] = useState([]) //搜索数据
    const [selectItem, setSelectItem] = useState([]) //选中的项目 或者 物料
    const [loading, setLoading] = useState(false)

    //回车事件
    const handEnter = (e) => {
        if (e.nativeEvent.keyCode === 13) {
            if (objectValueAllEmpty(searchValue)) {
                message.warning(`请输入你要搜索的维修配件`)
                return
            }
            loadData(30)
        }
    }

    //弹框关闭 清空数据
    useEffect(() => {
        if (!visible) {
            setSelectItem([])
            setAddData([])
            setSearchValue({})
            loadData(30)
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    //项目 新增数据列表
    const loadData = (pageSize = 30, search = searchValue) => {
        setLoading(true)
        let api = getStockDetailsFindPage
        const { shopId, organName } = getUserOrgan()
        let data = {
            pageSize: pageSize,
            pageNum: 1,
            shopId,
            shopName: organName,
            ...search,
            age:1,
            status: 'purchase-status.bill,purchase.bill,purchase.completely-io,purchase.allocation',
        }
        api(data)
            .then((res) => {
                if (res && res.retCode === 200) {
                    let results = res.retData
                    if (results?.records?.length > 0) {
                        setAddData(results.records.map(item => ({...item, inventoryDetailsId: item.id})))
                    } else {
                        message.warning(`该商品没有库存！`)
                    }
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    //不为选中或者删除的时候
    const deleteItem = (id) => {
        let arr = [...selectItem]
        let newArr = arr.filter((item) => item.id !== id)
        setSelectItem(newArr)
    }

    //新增项目的table配置
    const addColumns = [
        {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: 'left',
            // render: (text) => (
            //     <Tooltip placement="topLeft" title={text}>
            //         <p className="one-line">{text}</p>
            //     </Tooltip>
            // ),
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        },
        {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: 'left',
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        },
        {
            title: '采购单价',
            width: 150,
            dataIndex: 'price',
        },
        {
            title: "是否平台",
            width: 120,
            dataIndex: "isPlatform",
            render: (text) => (text ? "是" : "否"),
        },
        {
            title: '可用库存',
            width: 100,
            dataIndex: 'availableStock',
        },
        {
            title: '库房',
            width: 140,
            dataIndex: 'warehouseName',
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'availableStock',
        },
        
        {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
        },
        {
            title: '采购单号',
            width: 160,
            dataIndex: 'purchaseCode',
        },
        {
            title: '操作',
            width: 60,
            fixed: 'right',
            align: 'center',
            render: (text, record) => {
                return <YhBut type="add" txt="添加" click={() => add(record)} disabled={record.availableStock === 0} />
            },
        },
    ]

    const add = (record) => {
        let findArr = selectItem.find((item) => item.id === record.id)
        if (findArr) {
            let filterArr = selectItem.filter((item) => item.id !== findArr.id)
            setSelectItem(filterArr)
        } else {
            setSelectItem((origin) => [...origin, {...record, num: 1}])
        }
    }

    const column = [
        {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: 'left',
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.name })} />,
        },
        {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: 'left',
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        },
        {
            title: "是否平台",
            width: 120,
            dataIndex: "isPlatform",
            render: (text) => (text ? "是" : "否"),
        },
        {
            title: '可用库存',
            width: 100,
            dataIndex: 'availableStock',
        },
        // {
        //     title: '库房',
        //     width: 140,
        //     dataIndex: 'warehouseName',
        //     render: (text) => (
        //         <Tooltip placement="topLeft" title={text}>
        //             <p className="one-line">{text}</p>
        //         </Tooltip>
        //     ),
        // },
        {
            title: "领取数量",
            width: 100,
            align: "center",
            dataIndex: "num",
            editable: true,
        },
        {
            title: "采购单价",
            width: 100,
            align: "center",
            dataIndex: "price",
            editable: true,
        },
        {
            title: "销售单价",
            width: 100,
            align: "center",
            dataIndex: "lateSalePrice",
            editable: true,
        },
        {
            title: '操作',
            width: 60,
            fixed: 'right',
            align: 'center',
            render: (text, record) => {
                return <YhBut type="delete" txt="删除" click={() => {handleDeleteOne(record)}} />
            },
        },
    ]
    //删除
    const handleDeleteOne = (record) => {
        const { inventoryDetailsId } = record
            let arr = [...selectItem]
            let newArr = arr.filter((item) => item.inventoryDetailsId !== inventoryDetailsId)
            setSelectItem(newArr)
        
    }

    const newSelectColumns = () =>
        column.map((col, index) => {
            return {
                ...col,
                onCell: (record, rowIndex) => ({
                    colIndex: index,
                    rowIndex,
                    record,
                    rules: col.rules,
                    options: col.options,
                    editType: col.editType,
                    editable: col?.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }),
            }
        })
    //单元格编辑保存
    const handleSave = (row) => {
        const newData = [...selectItem]
        let index = newData.findIndex((item) => row.inventoryDetailsId === item.inventoryDetailsId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setSelectItem(newData)
    }
    return (
        <Drawer visible={visible} onClose={() => onClose(false)} zIndex={1001}>
            <div className="team-drawer-title">
                <span>维修配件新增</span>
            </div>
            <div className="team-drawer-content">
                <div className="drawer-search-box">
                    <span>配件属性:</span>
                    <Select
                        allowClear
                        placeholder="请选择项目属性"
                        style={{ width: '160px', textAlign: 'left' }}
                        onChange={(attr) =>
                            setSearchValue({
                                ...searchValue,
                                itemAttr: attr,
                            })
                        }
                    >
                        {ItemAttraArr.map((item) => (
                            <Select.Option value={item[0]} key={item[0]}>
                                {item[1]}
                            </Select.Option>
                        ))}
                    </Select>
                    <span>商品编号:</span>
                    <Input
                        className="search-input1"
                        allowClear
                        placeholder="请输入商品编号"
                        value={searchValue.materialCode}
                        onChange={(e) =>
                            setSearchValue({
                                ...searchValue,
                                materialCode: e.target.value,
                            })
                        }
                        onKeyPress={handEnter}
                    />
                    <span>商品名称:</span>
                    <Input
                        className="search-input1"
                        allowClear
                        placeholder="请输入商品名称"
                        value={searchValue.materialName}
                        onChange={(e) =>
                            setSearchValue({
                                ...searchValue,
                                materialName: e.target.value,
                            })
                        }
                        onKeyPress={handEnter}
                    />
                    <Button className="search-but1" onClick={() => loadData(30)}>
                        查询
                    </Button>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={addColumns}
                    dataSource={addData}
                    pagination={false}
                    scroll={{ y: 360 }}
                    loading={loading}
                />
                <div className="team-form-footer">
                    <Button onClick={() => onClose(false)} className="team-but-one">
                        取消
                    </Button>
                    <Button
                        className="team-but-two"
                        onClick={() => {
                            onItemOk(selectItem.map((item) => ({ ...item, lateSalePrice: item.lateSalePrice || item.retailPrice })))
                        }}
                    >
                        提交
                    </Button>
                </div>
                <div className="select-item-box">
                    <h4>已添加维修配件</h4>
                    {/* <ul>
                        {selectItem &&
                            selectItem.map((item) => {
                                return (
                                    <li key={item.id}>
                                        <span>{item.materialName}</span>
                                        <span onClick={() => deleteItem(item.id)}>删除</span>
                                    </li>
                                )
                            })}
                    </ul> */}
                    <Table
                        rowClassName="editable-row yh-edit-table-tr"
                        rowKey={(record) => record.id}
                        columns={newSelectColumns()}
                        dataSource={selectItem}
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            },
                        }}
                        pagination={false}
                        scroll={{ x: 'max-content', y: 206 }}
                    />
                </div>
            </div>
        </Drawer>
    )
}
