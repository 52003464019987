import React from "react";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useStockDetailedModel } from "../stockDetailedModel";
import { DataTable } from "./DataTable";
import { formatNum } from '@/erp_subpackage/utils/util';
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Tooltip } from "antd";

export const DataBox = () => {
    const { descTotal, searchParam, shopId, exportList, setShiftShelfModal, setRecordsVisible, setSectionShelfModal ,rowsDescTotal,selectedRowKeys} = useStockDetailedModel();

    const shelfMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={() => setShiftShelfModal(true)}>全部转移</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => setSectionShelfModal(true)}>部分转移</span>
                </Menu.Item>
            </Menu>
        )
    }

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => exportList()}
        >导出</Button>
        {/* <Button
            className="btn-item"
            shape="round"
            onClick={() => setShiftShelfModal(true)}
            disabled={searchParam.shopId === "" || (searchParam.shopId && searchParam.shopId !== shopId)}
        >转移货位</Button> */}
        <Dropdown overlay={shelfMenu}>
                <Button
                    className="mr-5 btn-item"
                    type="link"
                    shape="round"
                >货位转移<DownOutlined /></Button>
            </Dropdown>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => setRecordsVisible(true)}
            disabled={searchParam.shopId === "" || (searchParam.shopId && searchParam.shopId !== shopId)}
        >移库记录</Button>
    </>;

    const desc = [
        {
            label: "库存成本",
            value: !selectedRowKeys.length ? `￥${formatNum(descTotal?.stockAmount)}`: `￥${formatNum(rowsDescTotal.stockAmount)}`,
            color: "red"
        },
        {
            label: "库存数量",
            value: !selectedRowKeys.length ? descTotal?.stockNum: rowsDescTotal.stockNum,
            color: "red"
        },
    ]

    return (
        <YhBox title="库存明细列表" action={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}

