import React, { useEffect, useRef } from 'react'
import { Table } from 'antd'
import YhTootip from '@/erp_subpackage/components/YhTooltip'
import FontIcon from '@/erp_subpackage/components/YhIcon'
import { taskBookModel } from "./taskBookModel"
import { taskBookService } from "@/service/taskBookService"

export const OnemaintainBox = ({ }) => {
    const {
        tcoDict,
        onemaintainData,
        updataOnemaintainData,
        setChoosedJobData, setItemSearchVal,
        setChooseItemVisible, setChooseItemType
    } = taskBookModel()

    const dataSource = onemaintainData
    const formRef = useRef()

    const loadItem = async (search, r) => {
        //查询只有一条
        const searchParams = {
            pageSize: 1,
            pageNum: 1,
            codeOrName: search,
        }
        let { retData } = await taskBookService.serviceItemPage(searchParams)
        const { records, total } = retData
        setChoosedJobData(r)
        if (total === 1) {
            updataOnemaintainData('updataOnemaintainData', records)
        } else {
            setItemSearchVal(search)
            setChooseItemVisible(true)
        }
    }

    useEffect(() => {
        const dataList = onemaintainData
        formRef.current = dataList
    }, [onemaintainData])

    const defaultColumn = [
        {
            title: (
                <>
                    <span></span>
                    <span>序号</span>
                </>
            ),
            width: 80,
            fixed: 'left',
            render: (t, r, i) => i + 1,
            align: 'center',
        },
        {
            title: (
                <span>
                    作业部位<FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            copyTitle: '作业部位',
            editType: 'select',
            width: 120,
            dataIndex: 'position',
            align: "center",
            render: (text) => {
                let dict = tcoDict.filter((item) => item.pcode == 'system-type' && item.code == text)[0];
                if (dict != null && dict != undefined) {
                    return dict.name;
                }
                else {
                    return text;
                }
            },
        },
        {
            title: '项目编码',
            width: 150,
            dataIndex: 'itemCode',
            align: "center",

            onInputEnter(e, r) {
                setChooseItemType('change')
                loadItem(e.value, r)
            },
            render: (t, r) =>
                <span>{t}</span>
        },
        {
            title: <span>项目名称</span>,
            copyTitle: '项目名称',
            width: 200,
            dataIndex: 'maintenanceLogBook',
            align: "center",
            render: (text) => text
        },
        {
            title: <span>保养作业项目</span>,
            copyTitle: '保养作业项目',
            width: 500,
            dataIndex: 'itemNote',
            align: "left",
            render: (text) => <YhTootip text={text} maxWidth={500} />,
        },
        {
            title: (
                <span>
                    处理措施
                    <FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            copyTitle: '处理措施',
            width: 120,
            dataIndex: 'treatmentMeasure',
            align: "center",
            isSet: true,
        },
        {
            title: (
                <span>
                    更换间隔
                    <FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            width: 100,
            dataIndex: 'intervalMileage',
            align: "center",
            copyTitle: ' 工时成本单价(¥)',
        },
        {
            title: (
                <span>
                    已完成次数
                    <FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            width: 130,
            dataIndex: 'finishesNum',
            align: "center",
            copyTitle: ' 工时成本单价(¥)',
        },
        {
            title: (
                <span>
                    上次保养里程
                    <FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            width: 130,
            dataIndex: 'lastMaintenanceMileage',
            align: "center",
            copyTitle: ' 工时成本单价(¥)',
        },
        {
            title: (
                <span>
                    下次保养里程
                    <FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            width: 130,
            dataIndex: 'nextMaintenanceMileage',
            align: "center",
            copyTitle: ' 工时成本单价(¥)',
        },
    ]

    return (
        <>
            <Table
                style={{ marginBottom: '20px', border: '1px solid #f0f0f0' }}
                rowClassName="editable-row"
                rowKey={(record) => record.temporaryId || record.id}
                bordered
                columns={defaultColumn}
                dataSource={dataSource}
                scroll={{ x: 'max-content', y: '600px' }}
                pagination={false}
            />
        </>
    )
}
