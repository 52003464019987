import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useAllocModel } from "../inventoryStatisticsModel";


export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useAllocModel();
    const searchFields = [
        {
            type:'Select',
            placeholder: "是否积压",
            fieldName: "overstockFlag",
            options: [{ label: "是", value: 1 }, { label: "否", value: "0" }],
        },
        {
            type:'Input',
            placeholder: "商品编码",
            fieldName: "materialCode"
        },
        {
            type:'Input',
            placeholder: "商品名称",
            fieldName: "materialName"
        },
        {
            type:'Input',
            placeholder: "替换编号",
            fieldName: "replaceCode"
        },
        {
            type:'Input',
            placeholder: "货位",
            fieldName: "shelfCode",
        },
    ]


    return (
        <YhPageHeader
            title="销存率统计"
            size="small"
            justify="end"
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData( undefined, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}