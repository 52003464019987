
import React, { useEffect, useState,useContext, createContext } from "react";
import {Table, Modal, Button, } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import {useContractModel} from '../contractParticularsModel'
import {usecontractDetailsModel} from './detailsModel'
import { formatNum } from '@/erp_subpackage/utils/util';


export const  BillModal =({mode,})=>{

    const  {meilageoneTotal,loading,serachmdetail,updateSearchParamm,descTotalt,billdetailData,mileageOrderdetailes,resetSearchParamm,addFormVal,lookFormVal,editFormVal,
    } =usecontractDetailsModel()
    const {open,setOpen}=useContractModel()
    const formval = mode==='edit'?editFormVal:mode==='look'?lookFormVal:addFormVal
   


    const column = [
        {
            title: '对账单单号',
            width: 120,
            dataIndex: 'billCode',
            fixed: "left"
        },
        {
            title: '车辆牌照',
            width: 100,
            dataIndex: 'vehicleCode',
            
        },
        {
            title: '上月账单里程',
            width: 100,
            dataIndex: 'mileageLast',
        }, {
            title: '当前里程',
            width: 100,
            dataIndex: 'mileageCurrent',
        },
        {
            title: '运行里程',
            width: 100,
            dataIndex: 'mileageDiff',
        }, {
            title: '计费价格',
            width: 100,
            dataIndex: 'billPrice',
            render:(text)=>text.toFixed(3)
        }, {
            title: '账单金额',
            width: 100,
            dataIndex: 'totalAmount',   
        }, {
            title: '已收金额',
            width: 100,
            dataIndex: 'paidAmount',
          
        },{
            title: '应收金额',
            width: 100,
            dataIndex: 'payableAmount',
           
            
        },
        {
            title: '开始日期',
            width: 150,
            dataIndex: 'billStartTime',
            
        },
        {
            title: '结束日期',
            width: 150,
            dataIndex: 'billEndTime',
           
            
        },
       
    ];
    const desc = [
    
        {
            label: "  费用金额",
           value: "￥" + formatNum(meilageoneTotal.totalAmount?? 0),
            color: "red"
        },
        {
            label: "  已收金额", 
            value: "￥" + formatNum(meilageoneTotal.paidAmount?? 0),
            color: "red"
        }, {
            label: "  应收金额",
            
         value: "￥" + formatNum(meilageoneTotal.payableAmount?? 0),
            color: "red"
        },
        
    ]
    

        const searchFields1 = [ 
            {
                type: "Input",
                placeholder: "车辆牌照",
                fieldName: "vehicleCode"
            }, 
        ]
        
    return(
        <>
        <Modal
         title="对账单信息"
         open={open}
         onCancel={() => setOpen(false)}
         width={1700}
         footer={[]}
        >
       <YhBox descriptions={desc}>
        <div style={{marginLeft:'28px',marginTop:'-44px'}}>    
          <YhPageHeader
               size="small"
               justify="end"
               triggerSearch
               searchValues={serachmdetail}
               searchFields={searchFields1}
               onChange={updateSearchParamm}
               handleSearch={() => { mileageOrderdetailes({ ...serachmdetail,}) }}
               handleReset={resetSearchParamm}
            /> 
         </div>
            <Table
             rowKey={record => record.id}
               columns={column}  
               loading={loading}  
               scroll={{ x: 'max-content' }}
               dataSource={billdetailData}
            />
       
         </YhBox>
        </Modal>
        </>
    )
}
