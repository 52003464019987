import React, { useEffect } from "react";
import { Table } from "antd";
import { useRecordModel } from "../recordModel";
import { timeShift } from "@/utils/util"

export function DataTable() {
    const {
        dataSource, pgCfg, loading, loadData,
    } = useRecordModel();

    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (text, record, index) => index + 1
        }, {
            title: '创业班组',
            width: 160,
            dataIndex: 'team',
        }, {
            title: '所属机构',
            width: 160,
            dataIndex: "org",
        }, {
            title: '答题时间',
            width: 160,
            dataIndex: "createTime",
            render: (text) => text ? timeShift(text) : "",

        }, {
            title: '成绩',
            width: 160,
            dataIndex: 'score',
        }
    ];


    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
