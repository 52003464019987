import React, { useState, useEffect, useImperativeHandle } from "react"

import { Modal, Form, Col, Row, message, Button, Upload, Spin } from "antd"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getvehicleLicenseInfo } from "@/service/baiduAiService"
import { GetChinese, RemoveChinese } from "@/utils/util"
import styled from "@emotion/styled"
import moment from "moment"

export default (props) => {
    const { detail, mode, imgsRef, setDriverImgInfo } = props
    const [form] = Form.useForm()

    const [driverCardImgList, setDriverCardImgList] = useState([])
    const [driverCardBackImgList, setDriverCardBackImgList] = useState([])
    const [driverNameplateImgList, setDriverNameplateImgList] = useState([])
    const [driverExteriorImgList, setDriverExteriorImgList] = useState([])

    const [scanningLoading, setScanningLoading] = useState(false)

    //暴露给父组件的方法
    useImperativeHandle(imgsRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return form
        },
    }))

    useEffect(() => {
        if (detail && detail.basicInfo) {
            const { driverCardImg, driverCardBackImg, driverNameplateImg, driverExteriorImg } = detail.basicInfo
            let driverCardImgArr = [],
                driverCardBackImgArr = [],
                driverNameplateImgArr = [],
                driverExteriorImgArr = []
            if (driverCardImg) {
                driverCardImgArr = [
                    {
                        uid: "-1",
                        status: "done",
                        url: `${fileLookUrl().image}${driverCardImg}`,
                        response: {
                            retData: driverCardImg,
                        },
                    },
                ]
                setDriverCardImgList(driverCardImgArr)
            }
            if (driverCardBackImg) {
                driverCardBackImgArr = [
                    {
                        uid: "-1",
                        status: "done",
                        url: `${fileLookUrl().image}${driverCardBackImg}`,
                        response: {
                            retData: driverCardBackImg,
                        },
                    },
                ]
                setDriverCardBackImgList(driverCardBackImgArr)
            }
            if (driverNameplateImg) {
                driverNameplateImgArr = [
                    {
                        uid: "-1",
                        status: "done",
                        url: `${fileLookUrl().image}${driverNameplateImg}`,
                        response: {
                            retData: driverNameplateImg,
                        },
                    },
                ]
                setDriverNameplateImgList(driverNameplateImgArr)
            }
            if (driverExteriorImg) {
                driverExteriorImgArr = [
                    {
                        uid: "-1",
                        status: "done",
                        url: `${fileLookUrl().image}${driverExteriorImg}`,
                        response: {
                            retData: driverExteriorImg,
                        },
                    },
                ]
                setDriverExteriorImgList(driverExteriorImgArr)
            }
            form.setFieldsValue({
                driverCardImg: driverCardImgArr,
                driverCardBackImg: driverCardBackImgArr,
                driverNameplateImg: driverNameplateImgArr,
                driverExteriorImg: driverExteriorImgArr,
            })
        }
    }, [detail])

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    //图片查看
    const handlePreview = async (file) => {
        const {retData} = file.response
        Modal.info({
            title: `图片查看`,
            content: <img alt="" style={{ width: "100%" }} src={ fileLookUrl().image + retData} />,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            icon: <></>,
            width: 1000
        })
    }

    const driverCardImgChange = async (info) => {
        setDriverCardImgList([])
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setScanningLoading(true)
            getImgInfo(md5)
            setDriverCardImgList(info.fileList)
        } else if (info.file.status === "removed") {
            setDriverCardImgList([])
        }
    }

    //图像识别
    const getImgInfo = (fileMd5) => {
        getvehicleLicenseInfo(fileMd5)
            .then((res) => {
                if (res && res.retCode === 200) {
                    let { words_result, error_msg } = res.retData
                    if (error_msg) {
                        message.error(error_msg)
                        setDriverCardImgList([])
                        form.resetFields(["driverCardImg"])
                    } else {
                        setDriverImgInfo({
                            vehicleCode: words_result["号牌号码"].words,
                            vehicleType: words_result["车辆类型"].words,
                            vehicleNature: words_result["使用性质"].words,
                            brandId: GetChinese(words_result["品牌型号"].words),
                            vehicleModelId: RemoveChinese(words_result["品牌型号"].words),
                            vehicleVin: words_result["车辆识别代号"].words,
                            engineCode: words_result["发动机号码"].words,
                            drivingSignDate: moment(words_result["注册日期"].words),
                        })
                    }
                } else {
                    message.error("请上传清晰的行驶证图片")
                    setDriverCardImgList([])
                }
                setScanningLoading(false)
            })
            .catch((err) => {
                form.resetFields(["driverCardImg"])
                setScanningLoading(false)
            })
    }

    const driverCardBackImgChange = async (info) => {
        setDriverCardBackImgList([])
        if (info.file.status === "done") {
            setDriverCardBackImgList(info.fileList)
        } else if (info.file.status === "removed") {
            setDriverCardBackImgList([])
        }
    }

    const driverNameplateImgChange = async (info) => {
        setDriverNameplateImgList([])
        if (info.file.status === "done") {
            setDriverNameplateImgList(info.fileList)
        } else if (info.file.status === "removed") {
            setDriverNameplateImgList([])
        }
    }

    const driverExteriorImgChange = async (info) => {
        setDriverExteriorImgList([])
        if (info.file.status === "done") {
            setDriverExteriorImgList(info.fileList)
        } else if (info.file.status === "removed") {
            setDriverExteriorImgList([])
        }
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    return (
        <Box>
            <Spin spinning={scanningLoading} tip="图片快速识别中">
                <Form form={form} {...formItemLayout}>
                    <div className="type">相关图片</div>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item label="行驶证正本" name="driverCardImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={driverCardImgList}
                                    onPreview={handlePreview}
                                    onChange={driverCardImgChange}
                                    disabled={isLook(mode)}
                                    style={{ width: "200px" }}
                                >
                                    {driverCardImgList.length >= 1 ? null : <Button size="small">上传行驶证正本</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="行驶证副本" name="driverCardBackImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={driverCardBackImgList}
                                    onPreview={handlePreview}
                                    onChange={driverCardBackImgChange}
                                    disabled={isLook(mode)}
                                >
                                    {driverCardBackImgList.length >= 1 ? null : <Button size="small">上传行驶证副本</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆铭牌" name="driverNameplateImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={driverNameplateImgList}
                                    onPreview={handlePreview}
                                    onChange={driverNameplateImgChange}
                                    disabled={isLook(mode)}
                                >
                                    {driverNameplateImgList.length >= 1 ? null : <Button size="small">上传车辆铭牌</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车辆外观" name="driverExteriorImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={driverExteriorImgList}
                                    onPreview={handlePreview}
                                    onChange={driverExteriorImgChange}
                                    disabled={isLook(mode)}
                                >
                                    {driverExteriorImgList.length >= 1 ? null : <Button size="small">上传车辆外观</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin-bottom: 20px;
    .type {
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        width: 180px;
        height: 120px;
    }
`
const Lablediv = styled.div`
    text-align: center;
    margin: 10px;
`
