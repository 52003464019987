import React, { useEffect, useRef } from "react"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { Button } from "antd"
import { useStockEntryModel } from "../../entryModel";

export const FormBox = ({ modeKey, mode, rowId, dispatch }) => {
    const { detailLookForm, getDetailLookOne } = useStockEntryModel(({ detailLookForm }) => [detailLookForm])
    const formRef = useRef()

    useEffect(() => {
        mode === "look" && getDetailLookOne(rowId, mode)
    }, [rowId])

    const goBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "stockEntry",
        })
    }

    const rightChildren = <>
        <Button className="btn-item" type="link" shape="round" onClick={goBack}>
            返回
        </Button>
    </>

    const baseFormItem = {
        title: "基础信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: "Input",
                fieldLabel: "单据编号",
                fieldName: "tradeCode",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "入库单位",
                fieldName: "userName",
            },
            {
                type: "Input",
                fieldLabel: "单据类型",
                fieldName: "tradeTypeName",
            },
            {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
            },
            {
                type: "Input",
                fieldLabel: "制单人",
                fieldName: "billingClerk",
            },
            {
                type: "Input",
                fieldLabel: "制单时间",
                fieldName: "billingTime",
            },
            {
                type: "Input",
                fieldLabel: "库管员",
                fieldName: "createUser",
            },
            {
                type: "Input",
                fieldLabel: "入库时间",
                fieldName: "createTime",
            },
        ],
    }

    const poFromConfig = {
        formRef: formRef,
        labelCol: { span: 6 },
        disabled: true,
        labelAlign: "right",
        formValues: detailLookForm,
        boxs: [baseFormItem],
    }

    return <YhFormBox {...poFromConfig} />
}
