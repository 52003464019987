import { message, notification } from "antd";
import { getToken } from "../../utils/dataStorage";
import { API_SERVER_HOST } from "../../config/app";
import { PORTAL_API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
// interface FilrResponse {
//     retCode: number,
//     retData: string,
//     retMsg: string,
//     success: boolean
// }
// interface File {
//     uid?: string,
//     name?: string,
//     status?: string,
//     response: FilrResponse,
//     linkProps?: any
// }
// interface Upload {
//     file: File,
//     fileList: any,
//     event: any
// }

export const messageConfig = (content, duration, type) => {
    notification.open({
        description: "",
        message: content,
        duration: duration,
        type: type,
        style: {
            width: 900,
        },
        placement: "topRight"
    })
}

export function importFileConfig({
    url,
    params = {},
    callback = () => { },  //成功回调
    loadingCallback = () => { }, //是否加载loading
    type,  //导出的类型
    isMessage = true  //导入成功是否需要消息提示

}) {
    let cfg = {
        name: 'file',
        action: ((url.indexOf("/ci-storage/") > -1 || url.indexOf("exam") > -1) ? API_SERVER_HOST : PORTAL_API_SERVER_HOST) + url,
        timeout: 600000,
        headers: {
            authorization: (url.indexOf("/ci-storage/") > -1 || url.indexOf("exam") > -1) ? getToken() : getPortalToken(),
            timeout: 600000,
        },
        data: {
            ...params
        },
        onChange({ file }) {
            loadingCallback()
            try {
                if (type === "zip" && file.status === 'done' && file.response) {
                    isMessage && message.success(file.response);
                    callback && callback(file)
                    return;
                }
                if (file.status === 'done' && file?.response?.retCode === 200) {
                    isMessage && message.success({ content: `${file.name}导入${file.response.retMsg ?? file.response.retData}`, duration: 8 });
                    callback && callback(file.response.retData ?? file)
                } else if (file.status === 'done' && file.response.list) {
                    isMessage && message.success({ content: `${file.name}导入${file.response.msg}`, duration: 8 });
                    file.response.list.length && callback && callback(file.response.list)
                } else if (file.status === 'error') {
                    message.error(`${file.response.retData}：${file.name}导入异常，请尝试重新导入`);
                    callback && callback(undefined);
                }
            } catch (error) {
                message.warning("导出异常，请重新尝试");
                callback && callback(undefined);
            }
        },
    }
    return cfg
}