import { message } from "antd";
import { getToken } from "../../utils/dataStorage";
import { API_SERVER_HOST } from "../../config/app";
import { PORTAL_API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
// interface FilrResponse {
//     retCode: number,
//     retData: string,
//     retMsg: string,
//     success: boolean
// }
// interface File {
//     uid?: string,
//     name?: string,
//     status?: string,
//     response: FilrResponse,
//     linkProps?: any
// }
// interface Upload {
//     file: File,
//     fileList: any,
//     event: any
// }

export function importPropsConfig(url, callback, params = {}, loadingCallback, type) {

    let cfg = {
        name: 'file',
        // action: url.indexOf("exam") > -1 ? API_SERVER_HOST + url : "http://82.156.12.88:9527" + url,
        action: ((url.indexOf("/ci-storage/") > -1 || url.indexOf("exam") > -1) ? API_SERVER_HOST : PORTAL_API_SERVER_HOST) + url,
        timeout: 600000,
        headers: {
            authorization: (url.indexOf("/ci-storage/") > -1 || url.indexOf("exam") > -1) ? getToken() : getPortalToken(),
            timeout: 600000,
        },
        data: {
            ...params
        },
        onChange({ file }) {
            //触发loadding
            if (typeof loadingCallback === "function") {
                loadingCallback()
            }
            try {
                console.log(file)
                console.log(file)
                if (type === "zip" && file.status === 'done' && file.response) {
                    message.success(file.response);
                    callback && callback(file)
                    return;
                }
                if (file.status === 'done' && file?.response?.retCode === 200) {
                    //返回的数据可能retData是一个字符串
                    //返回的数据可能retData是{list:[], msg:''}
                    const {retData,retMsg} = file.response
                    let str = ''
                    if(typeof retData === 'string'){
                        str = retData || retMsg
                    }else{
                        str = retData.msg
                    }
                    message.success({ content: `${file.name}导入${str}`, duration: 8 });
                    callback && callback(file.response.retData ?? file)
                } else if (file.status === 'done' && file.response.list) {
                    message.success({ content: `${file.name}导入${file.response.msg}`, duration: 8 });
                    file.response.list.length && callback && callback(file.response.list)
                } else if (file.status === 'error') {
                    message.error(`${file.response.retData}：${file.name}导入异常，请尝试重新导入`);
                    callback && callback(undefined);
                }
            } catch (error) {
                message.warning("导出异常，请重新尝试");
                callback && callback(undefined);
            }
        },
    }
    return cfg
}


export function importPropsConfigc(url, callback, loadingCallback, type) {

    let cfg = {
        name: 'file',
        // action: url.indexOf("exam") > -1 ? API_SERVER_HOST + url : "http://82.156.12.88:9527" + url,
        action: ((url.indexOf("/ci-customer/") > -1 || url.indexOf("exam") > -1) ? API_SERVER_HOST : PORTAL_API_SERVER_HOST) + url,
        timeout: 600000,
        headers: {
            authorization: (url.indexOf("/ci-customer/") > -1 || url.indexOf("exam") > -1) ? getToken() : getPortalToken(),
            timeout: 600000,
        },
        onChange({ file }) {
            //触发loadding
            if (typeof loadingCallback === "function") {
                loadingCallback()
            }
            try {
                if (type === "zip" && file.status === 'done' && file.response) {
                    message.success(file.response);
                    callback && callback(file)
                    return;
                }
                if (file.status === 'done' ) {
                    //返回的数据可能retData是一个字符串
                    //返回的数据可能retData是{list:[], msg:''}
                    const {retData,retMsg} = file.response
                    message.warning({ content: `${file.name}${file.response.retMsg}`, duration: 8 });
                    callback && callback(file.response.retData ?? file)
                } else if (file.status === 'done' && file.response.list) {
                    message.success({ content: `${file.name}导入${file.response.retMsg}`, duration: 8 });
                    file.response.list.length && callback && callback(file.response.list)
                } else if (file.status === 'error') {
                    message.error(`${file.response.retData}：${file.name}导入异常，请尝试重新导入`);
                    callback && callback(undefined);
                }
            } catch (error) {
                message.warning("导出异常，请重新尝试");
                callback && callback(undefined);
            }
        },
    }
    return cfg
}