import React, { useState, useEffect, } from "react"
import {Modal, Row, Col, Radio, Input, message } from "antd"
import { orderReview } from "@/service/ordersService"


 export default  (props) => {
    const { visible, setVisible, onSuccess, orderId } = props //拍单信息
    const [ passed,setPassd] = useState(true)
    const [ str, setStr] = useState("")
    const [confirmLoading, setConfirmLoading]= useState(false)
    useEffect(() => {

    }, [visible])
    //机构列表
    useEffect(() => {
       console.log(orderId)
    }, [])


    const onOk = () => {
        if (passed || (!passed && str)) {
            setConfirmLoading(true)
            orderReview({
                orderId: orderId,
                passed: passed,
                rejectInfo: passed ? "" : str,
            }).then((res) => {
                onSuccess && onSuccess()
                message.success("审核成功")
                setVisible(false)
            }).finally(() => {
                setConfirmLoading(false)
            })
        } else {
            message.warning("请输入工单驳回的原因")
        }
       
    }

    return (
        <Modal forceRender visible={visible} title="订单审核" centered={true} onOk={() => onOk()} onCancel={() => setVisible(false)} width={400} confirmLoading={confirmLoading}>
            <Row style={{ marginBottom: "10px" }}>
                                <Col span="6">审核状态</Col>
                                <Col span="16">
                                    {/* <Switch
                                        checkedChildren="通过"
                                        unCheckedChildren="驳回"
                                        defaultChecked={passed}
                                        onChange={(checked) => {
                                            passed = checked
                                            setIsPass(checked)
                                        }}
                                    /> */}
                                    <Radio.Group
                                        onChange={(e) => {
                                            setPassd(e.target.value)
                                        }}
                                        value={passed}
                                    >
                                        <Radio value={true}>通过</Radio>
                                        <Radio value={false}>驳回</Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>

                            {!passed && (
                                <Row>
                                    <Col span="6">驳回原因</Col>
                                    <Col span="16">
                                        <Input
                                            placeholder="请输入工单驳回的原因"
                                            onChange={(e) => {
                                                setStr(e.target.value) 
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}
        </Modal>
    )
}

