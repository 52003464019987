import React, { useEffect } from "react"
import { Button, Form, Input, Col, Row, InputNumber, message, Select, Switch } from "antd"
import { insertImageClassify, editImageClassify, getImageClassifyOne } from "@/service/maintenanceService"
import { getPcodeDict } from "@/utils/dataStorage"

const { Option } = Select

const SystemDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()

    useEffect(() => {
        resetFormItem()
    }, [])
    //移动车表单提交
    const systemSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                disableFlag: values.disableFlag ? 0 : 1,
                required: values.required ? 1 : 0,
            }
            console.table(data)

            //新增
            if (mode === "insert") {
                insertImageClassify(data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("新增成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     message.error(err.retMsg)
                    // })
                //编辑
            } else if (mode === "edit") {
                editImageClassify({ ...data, id: rowInfo.id })
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("编辑成功")
                        // } else {
                        //     message.error(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     message.error(err.retMsg)
                    // })
            }
        })
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit") {
            getImageClassifyOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                    form.setFieldsValue({
                        ...res.retData,
                        disableFlag: res.retData.disableFlag === 0 ? true : false,
                    })
                // }
            })
        } else {
            //新增默认推荐展示
            form.setFieldsValue({
                disableFlag: true,
            })
        }
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "imageClassify",
        })
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">图片分类配置</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>图片分类配置信息</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={systemSubmit}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={100}>
                            <Col span={6}>
                                <Form.Item
                                    label="分组"
                                    name="imageGroup"
                                    rules={[
                                        {
                                            required: true,
                                            message: "分组不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入分组" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="图片名称"
                                    name="imageName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "图片名称不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入图片名称" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="图片编码"
                                    name="imageCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: "图片编码不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入图片编码" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="图片说明" name="imageDesc">
                                    <Input placeholder="请输入图片说明" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col span={6}>
                                <Form.Item label="排序字段" name="componentSort">
                                    <Input type="number" placeholder="请输入排序字段" />
                                    {/* <InputNumber type="number" placeholder="请输入排序字段" /> */}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="请输入备注" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="required" label="是否必填" valuePropName="checked">
                                    <Switch checkedChildren="必填" unCheckedChildren="正常" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="disableFlag" label="使用状态" valuePropName="checked">
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default SystemDetails
