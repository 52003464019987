import React, { useState, useEffect } from "react"
import { Input, Modal, Form, Col, Row, Select, Switch, Spin, message } from "antd"
import { getTeamList } from "@/service/teamService"
import { insertCustomer, platformUserList } from "@/service/customerService"
import { getOrganCurrentUser } from "@/service/orgService"
import { getPcodeDict } from "@/utils/dataStorage"
import { organPage } from "@/service/orgService"
import debounce from "lodash/debounce"
const { Option } = Select
const OrderCustomerAdd = (props) => {
    const { visible, onCancel } = props
    const [organList, setOrganList] = useState({ data: [], fetching: false })
    const [form] = Form.useForm()
    const [teamData, setTeamData] = useState({
        //团队信息
        data: [],
        fetching: false,
    })

    //所属经理
    const [businessManager, setBusinessManager] = useState({
        data: [],
        fetching: false,
    })

    useEffect(() => {
        resetFormItem()
    }, [])
    //回显表单
    const resetFormItem = async() => {
        let defaultbusiness = await setDefultbusinessManager()
        setBusinessManager({
            data:defaultbusiness ? [defaultbusiness] : [],
            fetching: false,
        })
        form.setFieldsValue({
            businessManager: defaultbusiness ? defaultbusiness.value : null,
            customerStatus: true,
        })
    }


    //团队派单信息查询
    const fetchTeam = (value) => {
        setTeamData({
            data: [],
            fetching: true,
        })
        let data = {
            teamName: value ? value : null,
            searchItem: {},
            pageSize: 50,
            pageNum: 1,
        }
        getTeamList(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.teamName,
                value: team.id,
            }))
            setTeamData({
                data: dataMap,
                fetching: false,
            })
            // }
        })
    }
    const handleChangeTeam = (value, option) => {
        form.setFieldsValue({
            teamName: option.children,
        })
    }

    // 设置默认业务经理
    const setDefultbusinessManager = async () => {
        const data = await getOrganCurrentUser()
        const { defBusinessManager, defBusinessManagerName } = data.retData
        if (defBusinessManager) {
            return { text: defBusinessManagerName, value: defBusinessManager }
        } else {
            return null
        }
    }

    //查询机构
    const organQuery = (value) => {
        setOrganList({
            data: [],
            fetching: true,
        })
        let data = {
            searchItem: { organName: value, orderFlag: 0 },
            pageNum: 1,
            pageSize: 10,
            orders: [{ column: "createTime", rule: "DESC" }],
        }
        organPage(data).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.map((item) => ({
                    text: item.organName,
                    value: item.id,
                }))
                setOrganList({
                    data: data,
                    fetching: false,
                })
            } else {
                message.warning("没有查询到机构")
            }
        })
       
    }
    //回显
    const handleChangeOrgan = (value, option) => {
        if (option) {
            form.setFieldsValue({
                organId: value,
                organName: option.children,
            })
        }
    }

    //防抖
    const organQuerys = debounce(organQuery, 600)

    //保存
    const onCustomerSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                customerStatus: values.customerStatus ? 0 : 1,
            }
            insertCustomer(data).then((res) => {
                // if (res && res.retCode === 200) {
                //查找带过的工单要是被关闭， 就回到列表
                // let isOrderKey = state.panes.find(i => i.key === rowInfo.orderEditModeKey);
                // if (isOrderKey && rowInfo.orderEditModeKey) {
                //     dispatch({
                //         type: "changeTabs",
                //         activeMenu: rowInfo.orderEditModeKey,
                //         name: "订单新增"
                //     });
                //     dispatch({
                //         type: "removeTabs",
                //         targetKey: modeKey
                //     })
                // } else {
                //     dispatch({
                //         type: "removeTabs",
                //         targetKey: modeKey,
                //         activeMenu: "ordersManage"
                //     })
                // }
                // !rowInfo.orderEditModeKey &&
                onCancel()
                message.success("客户新增成功")
                // } else {
                //     message.error(res.retMsg)
                // }
            })
            // .catch((err) => {
            //     if (err && err.retCode === 500) {
            //         message.error(err.retMsg)
            //         return
            //     }
            // })
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const businessManagerQuery = (value, key) => {
        setBusinessManager({
            ...businessManager,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])
                setBusinessManager({
                    data: data,
                    fetching: false,
                })
            } else {
                console.log("没有查询到")
            }
        })
    }
    //防抖
    const businessManagerQuerys = debounce(businessManagerQuery, 1000)

    return (
        <Modal visible={visible} title="客户信息新增" centered={true} onOk={() => onCustomerSubmit()} onCancel={() => onCancel(false)} width={1260}>
            <div>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={6}>
                            <Form.Item
                                label="客户名称"
                                name="customerName"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入客户名称",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入客户名称" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="传真" name="customerFax">
                                <Input placeholder="请输入联系人" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="客户类型"
                                name="customerType"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择客户类型",
                                    },
                                ]}
                            >
                                <Select placeholder="请选择客户类型">
                                    {getPcodeDict("017").children.map((item) => (
                                        <Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="电子邮箱"
                                name="customerEmail"
                                rules={[
                                    {
                                        pattern: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
                                        message: "请输入正确的电子邮箱格式",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入电子邮箱" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="联系人"
                                name="customerCorporation"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入客户联系人",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入客户联系人" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="联系地址" name="customerAddress">
                                <Input placeholder="请输入联系地址" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="联系电话"
                                name="customerCorporationPhone"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入联系电话",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入联系电话" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="登记日期" name="createTime">
                                <Input placeholder="自动生成" disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="办公电话" name="customerPhone">
                                <Input placeholder="请输入办公电话" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="所属机构"
                                name="organId"
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择所属机构",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择所属机构"
                                    notFoundContent={organList.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => organQuery("")}
                                    onSearch={organQuerys}
                                    onSelect={handleChangeOrgan}
                                    style={{ width: "100%" }}
                                >
                                    {organList.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="邮编" name="customerZipcode">
                                <Input placeholder="请输入邮编" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属团队" name="teamId">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择创业团队"
                                    notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    onChange={handleChangeTeam}
                                    onFocus={() => fetchTeam()}
                                    style={{ width: "100%" }}
                                    // onSearch={fetchTeams}
                                    // filterOption={false}
                                >
                                    {teamData.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="业务经理" name="businessManager">
                                <Select
                                    showSearch
                                    placeholder="请选择业务经理"
                                    notFoundContent={businessManager.fetching ? <Spin size="small" /> : null}
                                    filterOption={false}
                                    onFocus={() => businessManagerQuerys("")}
                                    onSearch={businessManagerQuerys}
                                    style={{ width: "100%" }}
                                >
                                    {businessManager.data.map((item) => (
                                        <Option value={item.value} key={item.value}>
                                            {item.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="备注" name="customerDesc" labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                                <Input placeholder="请输入备注" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户状态" name="customerStatus" valuePropName="checked">
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
export default OrderCustomerAdd
