import React, { useContext } from "react";
import { Button, Steps, message, Modal } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useInternalModel } from "../internalModel";
import { MyContext } from "@/store/myContext"

export const DataBox = () => {
    const { selectedRowKeys, selectedData } = useInternalModel();
    const { state, dispatch, detailsPage } = useContext(MyContext)

    const goPageNav = (mode) => {
        let tabName = {
            add: "内部调拨单新增",
            edit: "内部调拨单编辑",
            look: "内部调拨单查看",
        }
        let modeKey = "accountInternal/" + mode
        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.InternalDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: selectedRowKeys[0],
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find((item) => item.key === modeKey)
            if (findNav) {
                Modal.confirm({
                    title: "温馨提醒！",
                    content: "您还有内部调拨单详情未保存，是否跳转",
                    okText: "跳转未保存页",
                    cancelText: "打开新页签",
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    },
                })
                return
            }
            handleDispatch()
        } else if (mode === "add") {
            handleDispatch()
        } else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }


    const title = (current) => <>
        <span>审核进度： </span>

        <Steps current={current} style={{ margin: "10px 0 " }}>
            <Steps.Step title="待申请" status={current === 0 ? "process" : current > 0 ? "finish" : "wait"} />
            <Steps.Step title="待对方确认" status={current === 1 ? "process" : current > 1 ? "finish" : "wait"} />

            {
                current === 2 ? <Steps.Step title="对方不认可" status="error" /> : <Steps.Step title="待财务确认" status={current === 3 ? "process" : current > 3 ? "finish" : "wait"} />
            }
            {
                current === 4 ? <Steps.Step title="对方已驳回" status="error" /> : <Steps.Step title="待财务审核" status={current === 5 ? "process" : current > 5 ? "finish" : "wait"} />
            }
            {
                current === 6 ? <Steps.Step title="财务已驳回" status="error" /> : <Steps.Step title="待最终审核" status={current === 7 ? "process" : current > 7 ? "finish" : "wait"} />
            }
            {
                current === 9 ? <Steps.Step title="最终已驳回" status="error" /> : <Steps.Step title="最终审核通过" status={current === 8 ? "process" : current > 8 ? "finish" : "wait"} />
            }

        </Steps>
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() => goPageNav('add')}
            >申请</Button>
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onClick={() => {
                    if (selectedRowKeys.length !== 1) {
                        message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                        return
                    }
                    goPageNav("edit")
                }}
            >查看</Button>
    </>;

    return (
        <YhBox title={title(selectedData?.status ?? 0)}>
            <DataTable />
        </YhBox>
    )
}