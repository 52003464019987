import { DictCodeEnmu } from './DictCodeEnum'
export const MdEnableStatusEnum = {
    // DISABLE,
    // ENABLE
}

export const PartnerTypeEnum = {
    CUSTOMER: 'PARTNER-TYPE.CUS',
    SUPPLIER: 'PARTNER-TYPE.SUP',
}
export const UserTypeEnum = {
    EMPLOYEE: 'user-type.employee', // "内部员工"
    MICRO_BOSS: 'user-type.micro-boss', // "微老板"
    DRIVER: 'user-type.driver', //"司机"
    HEADMAN: 'user-type.headman', //"队长"
    BOSS: 'user-type.boss', // "老板"
    SUPPLIER: 'user-type.supplier', //"供应商"
    CUSTOMER: 'user-type.customer', //"客户"
}
export const TradeTypeEnum = {
    PURCHASE_ORDER: 'TRADE-TYPE.PURCHASE-ORDER',
    PURCHASE: 'TRADE-TYPE.PURCHASE',
    PURCHASE_RETURN: 'TRADE-TYPE.PURCHASE-RETURN',
    SALE_ORDER: 'TRADE-TYPE.SALE-ORDER',
    SALE: 'TRADE-TYPE.SALE',
    SALE_RETURN: 'TRADE-TYPE.SALE-RETURN',
    REPAIR_YX: 'TRADE-TYPE.YX-REPAIR',
    REPAIR_YC: 'TRADE-TYPE.YC-REPAIR',
    LOSS: 'TRADE-TYPE.LOSS',
    REDUCTION: 'TRADE-TYPE.REDUCTION',
}

export const ReviewStatusEnum = {
    // INIT,
    // REVIEWING,
    // REVIEWED,
    // REJECTED : 9,
}

export const AccountStatusEnum = {
    // INIT,
    // DISABLED : 9
}

export const CreditFlagEnum = {
    // NON_CREDIT,
    // CREDIT,
    // CARRY_FORWARD
}

export const AdjustStatusEnum = {
    //     INIT,
    //     REVIEWING,
    //     ADJUSTED,
    //     REJECTED : 8,
    //     FAIL,
}

export const RechargeStatusEnum = {
    // INIT,
    // RECHARGED,
    // FAIL : 9,
}

export const TransferStatusEnum = {
    // INIT,
    // RECHARGED,
    // FAIL : 9
}

export const AccountLogTypeEnum = {
    OPEN: 'OPEN',
    DISABLE: 'DISABLE',
    ENABLE: 'ENABLE',
    ADJUST: 'ADJUST',
    ADJUST_APPROVAL: 'ADJUST_APPROVAL',
    RECHARGE: 'RECHARGE',
    TRANSFER_IN: 'TRANSFER_IN',
    TRANSFER_OUT: 'TRANSFER_OUT',
    BALANCE: 'BALANCE',
    UPDATE: 'UPDATE',
}

export const BillIoFlagEnum = {
    // IN : 1,
    // OUT
}

export const BillPayTypeEnum = {
    // CASH : 1,
    // CREDIT_ACCOUNT
}

export const BillStatusEnum = {
    // INIT,
    // BOOKED,
    // BALANCED
}

export const BillCashStatusEnum = {
    // INIT,
    // CASHED,
    // RECHARGED,
    // BALANCED,
    // FAIL : 9,
}

export const BillCancelStatusEnum = {
    // INIT,
    // REVIEWING,
    // CANCELLED,
    // FAIL,
}

export const BillInvoiceStatusEnum = {
    // INIT,
    // APPLYING,
    // PARTIAL,
    // INVOICED,
}

export const BillBalanceStatusEnum = {
    // INIT,
    // BALANCED,
    // FAIL : 9,
}

export const BillLogTypeEnum = {
    INSERT: 'INSERT',
    APPROVAL: 'DISABLE',
    CREDIT_BOOK: 'CREDIT_BOOK',
    CASH_BOOK: 'CASH_BOOK',
    BALANCE: 'BALANCE',
    INVOICE: 'INVOICE',
    INVOICE_CANCEL: 'INVOICE',
    INVOICE_APPROVAL: 'INVOICE_APPROVAL',
    CANCEL: 'CANCEL',
    CANCEL_APPROVAL: 'CANCEL_APPROVAL',
}
export const DetailType = {
    add: 'add',
    edit: 'edit',
    look: 'look',
}

export const titleType = {
    add: '新增',
    edit: '编辑',
    look: '查看',
}
//打印logo设置
export const IIMgEmum = {
    SKNY: 'skny',
    OUMAN: 'ouman',
    ZF: 'zf',
    IVECO: 'iveco',
}
export const logoOptions = [
    //新路王
    { label: '斯堪尼亚logo', value: IIMgEmum.SKNY },
    { label: '依维柯logo', value: IIMgEmum.IVECO },
    // { label: "曼恩logo", value: IIMgEmum.OUMAN },
    // { label: "采埃孚logo", value: IIMgEmum.ZF }
]
export const logoYhhOptions = [
    //云和汇
    { label: '曼恩logo', value: IIMgEmum.OUMAN },
    { label: '采埃孚logo', value: IIMgEmum.ZF },
]
export const IPayTypeEnum = {
    SELFPAY: 'settle-party.self-pay', //客户结算
    MANMAINTAIN: 'settle-party.man-maintain', //MAN-保养合约
    MANCLAIM: 'settle-party.man-claim', //MAN-索赔
    MANGOODWILL: 'settle-party.man-goodwill', //MAN-Goodwill
    JFCLAIM: 'settle-party.jf-claim', //JF-索赔
    SYCLAIM: 'settle-party.sy-claim', //SY-索赔
    XGCLAIM: 'settle-party.xg-claim', //XG-索赔
    OMCLAIM: 'settle-party.om-claim', //OM-索赔
    ZQCLAIM: 'settle-party.zq-claim', //ZQ-索赔
    SQCLAIM: 'settle-party.sq-claim', //SQ-索赔
    HYCLAIM: 'settle-party.hy-claim', //HY-索赔
    SCNSCANIA: 'settle-party.scn-scania', //SCN-斯堪尼亚牵引车部门
    CHASSIS: 'settle-party.chassis', //专用车底盘部门
    INTERNAL: 'settle-party.internal', //部门内部费用
    INSURANCE: 'settle-party.insurance', //保险公司
    SCNCLAIM: 'settle-party.scn-claim', //SCN-索赔
    SCNEPS: 'settle-party.scn-eps', //SCN-EPS
    SCNGOODWILL: 'settle-party.scn-goodwill', //SCN-GoodWill
    SCNMAINTAIN: 'settle-party.scn-maintain', //SCN-保养合约
}
//维修类型
export const IServiceNatureCodeType = {
    REPAIR_TYPE_CHAE: 'repair-type.chae', //差额
    REPAIR_TYPE_OTHER: 'repair-type.other', //其他
    REPAIR_TYPE_CLAIM: 'repair-type.claim', //索赔服务
    REPAIR_TYPE_TRAILER_MAINTENANCE: 'repair-type.trailer maintenance', //挂车维保
    REPAIR_TYPE_ACCIDENT_VEHICLE: 'repair-type.accident-vehicle', //事故车维修
    REPAIR_TYPE_REPAIR: 'repair-type.repair', //维修维修
    REPAIR_TYPE_MAINTENANCE: 'repair-type.maintenance', //保养服务
    REPAIR_TYPE_MAINTAIN: 'repair-type.maintain', //保养合约
}
//销售类型
export const ISalesTypeEnum = {
    WEICHAI: 'sales-type.weichai', //潍柴三包
    GIVE: 'sales-type.give', //赠送服务
    NORMAL_SALES: 'sales-type.normal-sales', //正常销售
}
//售价选择
export const IPriceTypeEnum = {
    BUYING_PRICE: 'price-selection.buying-price', //档案进价
    PUR_COST: 'price-selection.pur-cost', //采购成本价
    CUSTOMER_LATEST: 'price-selection.customer-latest', //客户最近销价
    RECENT: 'price-selection.recent', //最近销价
    RETAIL: 'price-selection.retail', //零售价
    PETROLEUM: 'price-selection.petroleum-price', //中石油销价
}

// 订单来源为app和小程序的时候 结算类型为现结  支付方式没有额度
export const IPayTypeDs = [
    {
        label: '现结',
        value: 'settle-type.cash',
    },
]

export const IPayMethodDs = [
    {
        label: '支付宝',
        value: DictCodeEnmu.PAY_METHOD_ALIPAY,
    },
    {
        label: '微信',
        value: DictCodeEnmu.PAY_METHOD_WECHAT,
    },
    {
        label: '现金',
        value: DictCodeEnmu.PAY_METHOD_CASH,
    },
]

export const IPayMethodCreditDs = [
    {
        label: '额度',
        value: DictCodeEnmu.PAY_METHOD_LIMIT,
    },
]

/**
 * 订单收款状态
 */
export const billStatusDs = [
    {
        label: '初始',
        value: 0,
    },
    {
        label: '已记账',
        value: 1,
    },
    {
        label: '已核销',
        value: 2,
    },
]

/**
 * 结算类型
 */
export const ISettleTypeEnum = {
    SETTLE_MONTHLY: 'settle-type.monthly', //月结
    SETTLE_CREDIT: 'settle-type.credit', //挂账
    SETTLE_CASH: 'settle-type.cash', //现结
    SETTLE_BALANCE: 'settle-type.balance', //结转
}
/**
 * 结算类型
 */
export const advancedStatusEnum = {
    'advanced.create': '已开单', 
    'advanced.submit': '已提交', 
    'advanced.wait.transfer': '待转接',
    'advanced.transfer': '已转接', 
    'advanced.wait.audit': '待审核', 
    'advanced.wait.confirm': '待确认', 
}
export const advancedStatusDs = [
    {
        label: '已开单',
        value: DictCodeEnmu.ADVANCED_CREATE,
    },
    {
        label: '已提交',
        value: DictCodeEnmu.ADVANCED_SUBMIT,
    },
    {
        label: '待转接',
        value: DictCodeEnmu.ADVANCED_WAIT_TRANSFER,
    },
    {
        label: '已转接',
        value: DictCodeEnmu.ADVANCED_TRANSFER,
    },
]
export const settleTypeDs = [
    {
        label: '挂账',
        value: ISettleTypeEnum.SETTLE_CREDIT,
    },
    {
        label: '现结',
        value: ISettleTypeEnum.SETTLE_CASH,
    },
    {
        label: '结转',
        value: ISettleTypeEnum.SETTLE_BALANCE,
    },
]

export const bentityTypeDs = [
    {
        label: '云豪车服（北京）科技服务有限公司',
        value: 'bentity.bj-yh-auto-service',
    },
    {
        value: 'bentity.bj-ouhao',
        label: '北京欧豪汽车贸易有限公司',
    },
    {
        label: '北京六环天力汽车修理厂',
        value: 'bentity.bj-sixth-ring-tianli',
    },

    {
        label: '北京綦齿机电有限公司',
        value: 'bentity.bj-qichi',
    },

    {
        label: '北京神州恒宇汽车服务有限公司 ',
        value: 'bentity.bj-shenzhou-hengyu',
    },
    {
        label: '天津欧豪富港汽车贸易有限公司 ',
        value: 'bentity.tj-ouhao',
    },
]

export const creditFlagDs = [
    {
        label: '记账',
        value: 1,
    },
    {
        label: '现结',
        value: 0,
    },
    {
        label: '结转',
        value: 2,
    },
]
