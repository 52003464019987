import { useState } from "react";
import { createModel } from "hox";
import { allocService } from "./allocService";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";

export const useAllocModel = createModel(function () {
    const { user: { shopId, shopCode } } = useGlobalModel();
    const initPgCfg = { pageSize: 15, current: 1 }

    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });

    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            // shopCode,
            shopId,
            ...searchValue,
            stockInStatus: searchValue.stockInStatus?.substring(3),
            stockOutStatus: searchValue.stockOutStatus?.substring(3),
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await allocService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(origin => records?.map((item, index) => ({ ...item, key: index + "1" }) ?? []));
        setLoading({ spinning: false });
    }

    //导出
    const exportAlloc = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        try {
            const searchParams = {
                shopId,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
            }
            let result = await allocService.export(searchParams);
            result && setLoading({ spinning: false });
            convertRes2Blob(result);
        } catch { setLoading({ spinning: false }); }
    }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        loading, setLoading,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData, exportAlloc
    }
});


