import React, { useState, useEffect, useImperativeHandle } from "react"
import { Input, Select, Form, Col, Row, message } from "antd"
import styled from "@emotion/styled"
import { getPcodeDict } from "@/utils/dataStorage"

const { Option } = Select
export default (props) => {
    const { mode, detail, otherInfoRef } = props
    const [form] = Form.useForm()

    const [show, setShow] = useState(false)

    useEffect(() => {
        if (detail && detail.basicInfo && detail.otherInfo) {
            const { yxnEquipmentCode, yxnSn } = detail.basicInfo
            form.setFieldsValue({ ...detail.otherInfo, yxnEquipmentCode, yxnSn })
        }
    }, [detail])

    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    //暴露给父组件的方法
    useImperativeHandle(otherInfoRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return form
        },
    }))

    return (
        <Box>
            <div className="type">
                其他信息{" "}
                <span
                    onClick={() => {
                        setShow(!show)
                    }}
                >
                    {show ? "收起" : "展开"}
                </span>
            </div>
            <Form form={form} {...formItemLayout} style={{ display: show ? "block" : "none" }}>
                <Row gutter={20}>
                    <Col span={6}>
                        <Form.Item label="发动机品牌" name="engineBrand">
                            <Select showSearch allowClear placeholder="请选择发动机品牌" disabled={isLook(mode)}>
                                {getPcodeDict("022005").children.map((item) => (
                                    <Option value={item.dictCode} key={item.id}>
                                        {item.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="发动机型号" name="engineModel">
                            <Select showSearch allowClear placeholder="请选择发动机型号" style={{ width: "100%" }} disabled={isLook(mode)}>
                                {getPcodeDict("022004").children.map((item) => (
                                    <Option value={item.dictCode} key={item.id}>
                                        {item.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="发动机订货号" name="engineOrderNumber">
                            <Input placeholder="请输入发动机订货号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="用户编码" name="engineOrderUserNumber">
                            <Input placeholder="请输入用户编码" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱品牌" name="gearboxBrand">
                            <Input placeholder="请输入变速箱品牌" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱号" name="gearboxModel">
                            <Input placeholder="请输入变速箱号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱编号" name="gearboxNumber">
                            <Input placeholder="请输入变速箱编" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱类型" name="gearboxType">
                            <Input placeholder="请输入变速箱类型" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱订货号" name="gearboxOrderNumber">
                            <Input placeholder="请输入变速箱订货号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车桥品牌" name="axleBrand">
                            <Input placeholder="请输入车桥品牌" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="平衡轴型号" name="balanceShaftModel">
                            <Input placeholder="请输入平衡轴型号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="平衡轴总成号" name="balanceShaftAssemblyNumber">
                            <Input placeholder="请输入平衡轴总成号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第一车桥" name="firstAxle">
                            <Input placeholder="请输入第一车桥" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第一车桥总成编号" name="firstAxleAssemblyNumber">
                            <Input placeholder="请输入第一车桥总成编号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="云小牛编号" name="yxnEquipmentCode">
                            <Input placeholder="请输入云小牛设备编号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="云小牛sn码" name="yxnSn">
                            <Input placeholder="云小牛设备sn码" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第二车桥" name="secondAxle">
                            <Input placeholder="请输入第二车桥" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第二车桥总成编号" name="secondAxleAssemblyNumber">
                            <Input placeholder="请输入第二车桥总成编号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第二车桥速比" name="secondAxleSpeedRatio">
                            <Input placeholder="请输入第二车桥速比" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                    <Col span={6}>
                        <Form.Item label="第三车桥" name="thirdAxle">
                            <Input placeholder="请输入第三车桥" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第三车桥总成编号" name="thirdAxleAssemblyNumber">
                            <Input placeholder="请输入第三车桥总成编号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第三车桥速比" name="thirdAxleSpeedRatio">
                            <Input placeholder="请输入第三车桥速比" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>

                    <Col span={6}>
                        <Form.Item label="第四车桥" name="fourthAxle">
                            <Input placeholder="请输入第四车桥" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第四车桥总成编号" name="fourthAxleAssemblyNumber">
                            <Input placeholder="请输入第四车桥总成编号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第四车桥速比" name="fourthAxleSpeedRatio">
                            <Input placeholder="请输入第四车桥速比" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>

                    <Col span={6}>
                        <Form.Item label="第五车桥" name="fifthAxle">
                            <Input placeholder="请输入第五车桥" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第五车桥总成编号" name="fifthAxleAssemblyNumber">
                            <Input placeholder="请输入第五车桥总成编号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="第五车桥速比" name="fifthAxleSpeedRatio">
                            <Input placeholder="请输入第五车桥速比" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Form>
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin: 20px 0;
    .type {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        span {
            float: right;
            cursor: pointer;
        }
    }
`
