import React, { useEffect, useState, useRef } from "react"
import { Modal, Button, message } from "antd"
import styled from "@emotion/styled"
import BigFlagPlantvVehicleInfo from "./bigFlagPlantvVehicleInfo"
import BigFlagPlantvVehicleTable from "./bigFlagPlantvVehicleTable"
import { combinationOlanOnevehicle, combinationPlanInsert, combinationPlanUpdate, combinationPlanDelete } from "@/service/dataService"

const VehiclePopupDetail = (props) => {
    const { visible, setVisible, vehicleId } = props
    const [plan, setPlan] = useState(null)

    const [startMileage, setStartMileage] = useState(0)
    const [earlyWarningMileage, setEarlyWarningMileage] = useState("")
    const [agreementNumber, setAgreementNumber] = useState("")

    const dataRef = useRef(null)

    useEffect(() => {
        if (vehicleId) {
            combinationOlanOnevehicle(vehicleId).then((res) => {
                if (res.retData) {
                    setPlan(res.retData)
                    setStartMileage(res.retData?.vehicleInfo?.bigBagSignContractMileage)
                    setEarlyWarningMileage(res.retData?.planInfo?.earlyWarningMileage)
                    setAgreementNumber(res.retData?.planInfo?.agreementNumber)
                }
            })
        }
    }, [vehicleId]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = () => {
        if (!startMileage) {
            return message.error("请在车辆详情添加大包签约里程")
        }
        if (!agreementNumber) {
            return message.error("请输入协议编号")
        }
        const { combinationList, mileageInfoListList } = dataRef.current.upData()
        let mileageList = plan?.mileageList
        const combinationInfoList = combinationList.map((item, index) => ({
            combinationId: item.combinationId,
            combinationName: item.combinationName,
            sort: index,
        }))
        const mileageInfoList = mileageInfoListList.map((item, index) => {
            let combinationIds = []
            Object.entries(item).forEach((v) => {
                if (v[1] === true) {
                    combinationIds.push(v[0])
                }
            })
            return { id: mileageList ? mileageList[index].id : null, mileage: item.mileage, combinationIds }
        })
        const sendData = { vehicleId, startMileage, agreementNumber, combinationInfoList, mileageInfoList , earlyWarningMileage}
        if (plan?.planInfo?.id) {
            sendData.id = plan.planInfo.id
            combinationPlanUpdate(sendData).then((res) => {
                message.success("编辑成功")
                setVisible(false)
            })
        } else {
            combinationPlanInsert(sendData).then((res) => {
                message.success("新增成功")
                setVisible(false)
            })
        }
    }

    const onDelete = () => {
        Modal.confirm({
            content: `确定要删除该大包计划?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                if (plan?.planInfo?.id) {
                    combinationPlanDelete(plan?.planInfo?.id).then((res) => {
                        message.success(`删除成功`)
                        setVisible(false)
                    })
                } else {
                    setVisible(false)
                }
            },
        })
    }

    return (
        <Modal
            visible={visible}
            title="云豪车服（A类）大包服务全寿命周期维护保养计划书"
            centered={true}
            onOk={() => onSubmit()}
            onCancel={() => setVisible(false)}
            width={1260}
            footer={[
                <Button onClick={() => setVisible(false)} key="back">
                    取消
                </Button>,
                <Button onClick={onDelete} danger key="delete">
                    删除
                </Button>,
                <Button onClick={onSubmit} type="primary" key="save">
                    保存
                </Button>,
            ]}
        >
            <BigFlagPlantvVehicleInfo plan={plan} setStartMileage={setStartMileage} setAgreementNumber={setAgreementNumber} earlyWarningMileage={1} setEarlyWarningMileage= {setEarlyWarningMileage}/>
            <BigFlagPlantvVehicleTable plan={plan} startMileage={startMileage} dataRef={dataRef} setVisible={setVisible}/>
        </Modal>
    )
}

const Box = styled.div`
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
`
export default VehiclePopupDetail
