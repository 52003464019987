import { get } from "@/utils/request"

// 全国地理区分
export const allAreas = () => {
    return get("/ci-platform/geo/allAreas")
}

export const geoJson = (adcode) => {
    return get(`/ci-platform/geo/area/${adcode}`)
}
