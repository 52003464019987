import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getMaintenanceList, disableMaintenance, getPartStatistical } from "@/service/maintenanceService"
import { MyContext } from "@/store/myContext"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import { getPcodeDict } from "@/utils/dataStorage"
import { ItemAttr } from "@/utils/enum"
import { Select } from "antd"

//维修配件
const MaterialManage = () => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [materialList, setMaterialList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [subTitle, setSubTitle] = useState([])
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [partOrigins, setPartOrigins] = useState(null) //产地 字典

    useEffect(() => {
        if (state.activeMenu === "materialManage") {
            loadData(pagination.current)
            // console.log(Switch)
            getPartStatistical()
            .then((res) => {
                let subTitle = []
                    res.retData.forEach(item => {
                        if(item.brand && item.count){
                            subTitle.push(`${item.brand}:${item.count}条`)
                        }
                        if(!item.brand && item.count){
                            subTitle.push(`其他:${item.count}条`)
                        }
                    })
                    setSubTitle(subTitle)
            })
        }
        return () => setMaterialList([])
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let obj = { partOrigin: {} } //工单来源
        getPcodeDict("108").children.forEach((item) => {
            obj.partOrigin[item.dictCode] = item.title
        })
        setPartOrigins(obj)
    }, [])
    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getMaintenanceList(data).then((res) => {
            // if (res && res.retCode === 200) {
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setMaterialList(res.retData.list)
            // }
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }
    //新增
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "配件新增",
            edit: "配件编辑",
            look: "配件查看",
        }
        let modeKey = "materialManage/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的配件信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的配件信息")
                return
            }

            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.MaterialDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //配件开关
    const materialSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        disableMaintenance({ id: id, disableFlag: checked ? 0 : 1 }).then((res) => {
            // if (res && res.retCode === 200) {
                message.success("操作成功")
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //导出
    const exportProt = async () => {
        setExportLoading(true)
        let data = {
            type: "post",
            data: {
                searchItem: searchValue,
                orders: [{ column: "updateTime", rule: "DESC" }],
            },
        }
        let resFlie = await download("/ci/maintenance_part/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }
    //table配置
    const columns = [
        {
            title: "商品编号",
            align: "center",
            dataIndex: "partCode",
        },
        {
            title: "商品名称",
            align: "center",
            dataIndex: "partName",
        },
        {
            title: "配件属性",
            align: "center",
            dataIndex: "partAttr",
            render: (text) => <p>{ItemAttr[text]}</p>,
        },
        {
            title: "单位",
            align: "center",
            dataIndex: "partUnit",
        },
        {
            title: "规格",
            align: "center",
            dataIndex: "partSpec",
        },
        {
            title: "商品品牌",
            align: "center",
            dataIndex: "partBrand",
        },
        {
            title: "适用车型",
            align: "center",
            dataIndex: "partApplicableModel",
        },
        {
            title: "产地",
            align: "center",
            dataIndex: "partOrigin",
            render: (text) => <>{partOrigins.partOrigin[text]}</>,
        },
        {
            title: "分组代码",
            align: "center",
            dataIndex: "partGroupCode",
        },
        {
            title: "分类代码",
            align: "center",
            dataIndex: "partClassificationCode",
        },
        {
            title: "进货价",
            align: "center",
            dataIndex: "partPurchasePrice",
        },
        {
            title: "零售价",
            align: "center",
            dataIndex: "partRetailPrice",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "disableFlag",
            render: (text, record) => (
                <Switch size="small" defaultChecked={text === 0 ? true : false} onChange={(checked, e) => materialSweitchChange(checked, e, record.id)} />
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "select",
                placeholder: "请选择配件属性",
                valueName: "partAttr",
                optionList: () =>
                Object.entries(ItemAttr).map((item) => (
                        <Select.Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Select.Option>
                    )),
            },
            {
                type: "input",
                placeholder: "请输入商品编号",
                valueName: "partCode",
            },
            {
                type: "input",
                placeholder: "请输入商品名称",
                valueName: "partName",
            },
            {
                type: "input",
                placeholder: "请输入商品品牌",
                valueName: "partBrand",
            },
        ],
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            content: "导入",
            upload: true,
            actionUrl: "ci/maintenance_part/import",
            onChange(info) {
                if (info.file.status === "done" && info.file?.response?.retCode === 200) {
                    message.success({
                        content: `${info.file.name}导入${info?.file?.response?.retData}`,
                        duration: 8,
                    })
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name}导入异常，请尝试重新导入`)
                }
            },
        },
        {
            click: () => exportProt(),
            content: "导出",
        },
    ]
    return (
        <>
            {state.activeMenu === "materialManage" && (
                <>
                    <TopSearch
                        title="配件管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="配件列表"
                        subTitle={subTitle}
                        buttons={topButtons}
                        columns={columns}
                        dataSource={materialList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                     {
                        exportLoading && <Loading />
                    }
                </>
            )}
        </>
    )
}
export default MaterialManage
