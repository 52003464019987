import request from "../utils/tdmsAxios"

/******************  分类  *****************/

/**分类-修改 */
export function tdmsCategoryUpdate(params) {
    return request({
        url: `/tdms/category/update`,
        method: "post",
        data: params,
    })
}

/**分类-删除 */
export function tdmsCategoryDelete(id) {
    return request({
        url: `/tdms/category/delete/${id}`,
        method: "get",
    })
}

/**分类-新增 */
export function tdmsCategoryInsert(params) {
    return request({
        url: `/tdms/category/insert`,
        method: "post",
        data: params,
    })
}

/**分类-查询 */
export function tdmsCategorySearchTree() {
    return request({
        url: `/tdms/category/listTree`,
        method: "get",
    })
}

/**分类-查询某一级分类下的二级分类 */
export function tdmsCategoryList(id) {
    return request({
        url: `/tdms/category/listByPid/${id}`,
        method: "get",
    })
}

/**分类-查询根分类 */
export function tdmsCategoryRootlist(id) {
    return request({
        url: `/tdms/category/rootList`,
        method: "get",
    })
}

/******************  审核  *****************/

/**审核-删除 */
export function tdmsApprovalDelete(id) {
    return request({
        url: `/tdms/approval/delete/${id}`,
        method: "get",
    })
}

/**审核-查看 */
// export function tdmsApprovalSearch(id) {
//     return request({
//         url: `/tdms/approval/search/${id}`,
//         method: 'get'
//     })
// }

/**审核-查询 */
export function tdmsApprovalQuery(params) {
    return request({
        url: `/tdms/approval/search/pg`,
        method: "post",
        data: params,
    })
}

/**审核-通过 */
export function tdmsApprovalPass(params) {
    return request({
        url: `/tdms/approval/updateStatus`,
        method: "post",
        data: params,
    })
}

/**审核-驳回 */
export function tdmsApprovalreject(params) {
    return request({
        url: `/tdms/approval/reject`,
        method: "post",
        data: params,
    })
}

/******************  搜索记录 ***************/

/**搜索-新增 */
export function tdmsSearchInsert(params) {
    return request({
        url: `/tdms/search/insert`,
        method: "post",
        data: params,
    })
}

/**搜索-查询 */
// export function tdmsSearchSelect(id) {
//     return request({
//         url: `/tdms/search/select/${id}`,
//         method: 'get'
//     })
// }

/******************  收藏  *****************/

/**收藏-新增 */
export function tdmsFavorInsert(params) {
    return request({
        url: `/tdms/favor/insert`,
        method: "post",
        data: params,
    })
}

/**收藏 - 删除 */
export function tdmsFavorDelete(params) {
    return request({
        url: `/tdms/favor/delete`,
        method: "post",
        data: params,
    })
}

/******************  文件服务  **************/

/******************  浏览记录  **************/

/**
 * 浏览历史-新增
 * @param {*} params
 * @returns
 */
export function tdmsHistoryInsert(params) {
    return request({
        url: `/tdms/history/insert`,
        method: "post",
        data: params,
    })
}

/**浏览历史-查询 */
// export function tdmsHistoryQuery(id) {
//     return request({
//         url: `/tdms/history/query/${id}`,
//         method: 'get',
//     })
// }

/******************  消息  *****************/

/**消息-新增 */
export function tdmsMsgInsert(params) {
    return request({
        url: `/tdms/msg/insert`,
        method: "post",
        data: params,
    })
}

/**消息-查询 */
export function tdmsMsgSearch(params) {
    return request({
        url: `/tdms/msg/search/pg`,
        method: "post",
        data: params,
    })
}

/**消息-查询未读消息数量 */
export function tdmsMsgCount() {
    return request({
        url: `/tdms/msg/count`,
        method: "get",
    })
}

/**消息-修改状态 */
export function tdmsMsgChange(id) {
    return request({
        url: `/tdms/msg/status/${id}`,
        method: "get",
    })
}

/******************  点赞  *****************/

/**点赞-新增 */
export function tdmsLikeInsert(params) {
    return request({
        url: `/tdms/like/insert`,
        method: "post",
        data: params,
    })
}

/**点赞-删除 */
export function tdmsLikeDelete(params) {
    return request({
        url: `/tdms/like/delete`,
        method: "post",
        data: params,
    })
}

/******************  焦点信息  **************/

/**焦点信息-新增 */
export function tdmsPictureInsert(params) {
    return request({
        url: `/tdms/picture/insert`,
        method: "post",
        data: params,
    })
}

/**焦点信息-查询 */
export function tdmsPictureSearch(params) {
    return request({
        url: `/tdms/picture/search/pg`,
        method: "post",
        data: params,
    })
}

/**焦点信息-修改 */
export function tdmsPictureUpdate(params) {
    return request({
        url: `/tdms/picture/update`,
        method: "post",
        data: params,
    })
}

/**焦点信息-修改 */
export function tdmsPictureMove(params) {
    return request({
        url: `/tdms/picture/move`,
        method: "post",
        data: params,
    })
}

/**焦点信息-删除 */
export function tdmsPictureDelete(id) {
    return request({
        url: `/tdms/picture/delete/${id}`,
        method: "get",
    })
}

/******************  资料  *****************/

/**资料-新增 */
export function tdmsDataInsert(params) {
    return request({
        url: `/tdms/data/insert`,
        method: "post",
        data: params,
    })
}

/**资料-修改 */
export function tdmsDataUpdate(params) {
    return request({
        url: `/tdms/data/update`,
        method: "post",
        data: params,
    })
}

/**资料-查询 */
export function tdmsDataSearch(params) {
    return request({
        url: `/tdms/data/search/pg`,
        method: "post",
        data: params,
    })
}

/**资料-查看 */
export function tdmsDataSelect(id) {
    return request({
        url: `/tdms/data/select/${id}`,
        method: "get",
    })
}

/**资料-导入 */
export function tdmsDataUpload(params) {
    return request({
        url: `/tdms/file/upload`,
        method: "post",
        data: params,
    })
}

/**资料-导出 */
export function tdmsDataDownload(params) {
    return request({
        url: `/tdms/data/download`,
        method: "post",
        data: params,
    })
}

/******************  资料文件  **************/

/**资料文件-新增 */

/**资料文件-删除*/
export function tdmsDeleteDataFile(id) {
    return request({
        url: `/tdms/dataFile/delete/${id}`,
        method: "get",
    })
}

/**资料-删除*/
export function tdmsDeleteData(id) {
    return request({
        url: `/tdms/data/delete/${id}`,
        method: "get",
    })
}

/**资料文件-查询 */
export function tdmsSelectDataFile(id) {
    return request({
        url: `/tdms/dataFile/select/${id}`,
        method: "get",
    })
}
