import React, { useContext, useState, useEffect } from 'react'
import TopSearch from '@/components/TopSearch'
import ListComponent from '@/components/listComponent'
import {
    factoryThreeGuaranteesRecordList,
    factoryThreeGuaranteesExport
} from '@/service/ordersService'
import { MyContext } from '@/store/myContext'
import { Select, Modal, message } from 'antd'
import { getPcodeDict } from '@/utils/dataStorage'
import { fileLookUrl } from '@/config/app.js'
import ImportFile from './importFile'
import Detail from './detail'
const { Option } = Select

export default () => {
    const { state, } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [dictObj, setDictObj] = useState(null)
    const [importVisible, setImportVisible] = useState(false)
    const [detailVisible, setDetailVisible] = useState(false)

    useEffect(() => {
        if (state.activeMenu === 'factorySettle') {
            initDict()
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps
    const initDict = () => {
        let obj = {
            threeGuaranteesBrand: {}, //三包
        }
        getPcodeDict('106').children.forEach((item) => {
            obj.threeGuaranteesBrand[item.dictCode] = item.title
        })
        setDictObj(obj)
    }

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                },
            ],
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
        }
        factoryThreeGuaranteesRecordList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }

    const handleSToDetail = () => {
        if(!Object.keys(selectInfo.rowInfo).length){
            return message.error("请选择需要查看的记录")
        }
        setDetailVisible(true)
    }


    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleSToDetail(),
            content: '查看',
        },
        {
            click: () => setImportVisible(true),
            content: '导入厂家明细',
        },
    ]

    const onExport = (record) => {
        factoryThreeGuaranteesExport(record.id).then(res => {
            message.success("导出成功")
        })
    }

    //table配置
    const columns = [
        {
            title: '厂家品牌',
            width: 100,
            align: 'center',
            dataIndex: 'threeGuaranteesBrand',
            render: (text) => <>{dictObj.threeGuaranteesBrand[text]}</>,
        },

        {
            title: '登记日期',
            width: 200,
            align: 'center',
            dataIndex: 'createTime',
        },
        {
            title: '所属机构',
            width: 200,
            align: 'center',
            dataIndex: 'organName',
        },
        {
            title: '操作人员',
            width: 100,
            align: 'center',
            dataIndex: 'operatorUserName',
        },

        {
            title: '操作',
            width: 120,
            align: 'center',
            dataIndex: 'downloadUrl',
            fixed: 'right',
            render: (text, record) => (
                <>
                    {/* */}
                    <span style={{ color: 'blue', marginRight: '10px' }} onClick={() => onExport(record)}>导出</span>
                    <a href={text}>下载</a>
                </>
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'select',
                placeholder: '请选择三包品牌',
                valueName: 'threeGuaranteesBrand',
                optionList: () =>
                    getPcodeDict('106').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'input',
                placeholder: '请输入操作员',
                valueName: 'operatorUserName',
            },
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                placeholder: ['开始时间', '结束时间'],
                span: 16,
                xxl: 8,
            },
            // {
            //     type: "searchSelect",
            //     placeholder: "请选择审批人",
            //     valueName: "reviewerId",
            //     optionName: "nickname",
            //     optionId: "id",
            //     searchItemName: "nickname",
            //     searchApi: platformUserList,
            // },
        ],
    }

    return (
        <>
            {state.activeMenu === 'factorySettle' && (
                <>
                    <TopSearch
                        title="厂家结算单"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="厂家结算单"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: 'max-content' }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                    <ImportFile visible={importVisible} setVisible={setImportVisible} onSuccess={()=> {loadData(1)}}/>
                    <Detail visible={detailVisible} setVisible={setDetailVisible}  rowInfo={selectInfo.rowInfo}/>
                </>
            )}
        </>
    )
}
