import React, { useState, useContext, useEffect } from "react"
import { MyContext } from "@/store/myContext"
import { tdmsApprovalQuery } from "../../../../src/service/tdmsService"
import { message, Divider, Table, Space, Button, Input, Tabs, Tag } from "antd"
import ListComponent from "@/components/listComponent"
import moment from "moment"
import TopSearch from "@/components/TopSearch"

const { TabPane } = Tabs
const { Search } = Input

export default () => {
    const [listType, setListType] = useState("unreviewed")
    const [loading, setLoading] = useState(false) // 审核列表loading
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [selectInfo, setSelectInfo] = useState({}) //记录列表当前行下标及当前行信息
    const [editId, setEditId] = useState(null)
    const [status, setStatus] = useState("")
    const [newStatic, setNewStatic] = useState({
        unreviewedCount: 0, //"待审核数量",
        rejectCount: 0, //"本周驳回数量",
        passCount: 0, // "本周通过数量",
    })
    const { dispatch, detailsPage, state, Option } = useContext(MyContext)
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })
    // 待审核列表数据
    const [approvalList, setApprovalList] = useState([])
    // 驳回列表数据
    const [rejectList, setRejectList] = useState([])
    // 通过列表数据
    const [passList, setPassList] = useState([])

    const onUnapprovalTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, { approvalStatus: 1 })
    }

    const onRejectTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, { approvalStatus: 3 })
    }

    const onPassTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, { approvalStatus: 2 })
    }

    useEffect(() => {
        if (state.activeMenu === "tdmsApproval") {
            unreviewedCountSearch()
        }
    }, [state.activeMenu])

    // 审核列表数据
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            ...search,
            page: {
                pageNum: current,
                pageSize: 10,
            },
        }
        tdmsApprovalQuery(data).then((res) => {
            if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.page.total,
                    pageSize: 10,
                })
                setApprovalList(res.retData.page.records)
                setNewStatic({
                    unreviewedCount: res.retData.unreviewedCount,
                    rejectCount: res.retData.rejectCount,
                    passCount: res.retData.passCount,
                })
                setLoading(false)
            } else {
                message.warning(res.retMsg)
            }
        })
    }

    //新增 编辑
    const handleDetails = async (mode, params) => {
        let tabName = {
            edit: "资料审核-编辑",
            look: "资料审核-查看",
            approval: "资料审核-审核",
        }
        let modeKey = "tdmsApproval/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的资料")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的资料")
                return
            }

            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
            // modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.ApprovalDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }

    // 审核
    const goToDetail = async (mode, params) => {
        let tabName = {
            edit: "资料审核-编辑",
            look: "资料审核-查看",
            approval: "资料审核-审核",
        }
        let modeKey = "tdmsApproval/" + mode
        modeKey += "/" + params.id
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.ApprovalDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: params,
            }),
        })
    }
    // 审核列表table配置
    const columns = [
        {
            title: "名称",
            dataIndex: "title",
            align: "center",
        },
        {
            title: "上传者",
            dataIndex: "createUser",
            align: "center",
        },
        {
            title: "审核者",
            dataIndex: "approvalUser",
            align: "center",
        },
        {
            title: "更新时间",
            dataIndex: "updateTime",
            align: "center",
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
            title: "状态",
            align: "center",
            dataIndex: "approvalStatus",
            render: (text, records) =>
                text == 1 ? (
                    <Button
                        style={{ backgroundColor: "orange", color: "#FFFFFF" }}
                        onClick={() => {
                            goToDetail("approval", records)
                        }}
                    >
                        待审核
                    </Button>
                ) : text == 2 ? (
                    <Button style={{ backgroundColor: "green", color: "#FFFFFF" }}>审核通过</Button>
                ) : (
                    <Button style={{ backgroundColor: "red", color: "#FFFFFF" }}>审核驳回</Button>
                ),
        },
    ]

    //列表button
    const topButtons = [
        {
            // click: () => handleDetails("look"),
            content: "查看",
        },
        {
            // click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            // click: () => handleDetails("edit"),
            content: "删除",
        },
    ]

    // 待审核数量
    const unreviewedCountSearch = () => {
        setListType("unreviewed")
        // console.log(listType)
        loadData(1, { approvalStatus: 1 })
    }

    // 驳回数量
    const rejectCountSearch = () => {
        // debugger
        setListType("reject")
        // console.log(listType)
        loadData(1, { approvalStatus: 3 })
    }

    // 通过数量
    const passCountSearch = () => {
        setListType("pass")
        // console.log(listType)
        loadData(1, { approvalStatus: 2 })
    }
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "名称",
                valueName: "title",
            },
            {
                type: "input",
                placeholder: "上传者",
                valueName: "createUser",
            },
            {
                type: "input",
                placeholder: "审核者",
                valueName: "approvalUser",
            },
        ],
    }
    return (
        <>
            <div className="list-container">
                <Divider style={{ margin: "16px 0" }} />
                <div className="statistics">
                    <div
                        style={{ cursor: "pointer" }}
                        className="item"
                        onClick={() => {
                            unreviewedCountSearch()
                        }}
                    >
                        <span>待审核数量</span>
                        <p>{newStatic.unreviewedCount}</p>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        className="item"
                        onClick={() => {
                            rejectCountSearch()
                        }}
                    >
                        <span>本周驳回数量</span>
                        <p>{newStatic.rejectCount}</p>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        className="item"
                        onClick={() => {
                            passCountSearch()
                        }}
                    >
                        <span>本周通过数量</span>
                        <p>{newStatic.passCount}</p>
                    </div>
                </div>
                <Divider style={{ margin: "16px 0" }} />
            </div>
            <TopSearch searchListData={searchList} searchValue={searchValue} setSearchValue={(obj) => setSearchValue(obj)} handleSearch={loadData} />
            {listType === "unreviewed" ? (
                <>
                    <Space align="center" style={{ marginBottom: 16 }}>
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                handleDetails("look")
                                setEditId(null)
                            }}
                        >
                            查看
                        </Button>
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                handleDetails("edit")
                            }}
                        >
                            编辑
                        </Button>
                        {/* <Button
                    shape="round"
                    className="but-left"
                    onClick={() => {
                        setStatus("edit")
                    }}
                >
                    删除
                </Button> */}
                        {/* <Search placeholder="请输入名称" allowClear onSearch={onSearch} style={{ width: 250, marginLeft: 1200 }} /> */}
                    </Space>
                    <ListComponent
                        title="待审列表"
                        // buttons={topButtons}
                        columns={columns}
                        dataSource={approvalList}
                        pagination={pagination}
                        onTableChange={onUnapprovalTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "100%" }}
                    />
                </>
            ) : listType === "reject" ? (
                <>
                    <Space align="center" style={{ marginBottom: 16 }}>
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                handleDetails("look")
                                setEditId(null)
                            }}
                        >
                            查看
                        </Button>
                        {/* <Search placeholder="请输入名称" allowClear onSearch={onSearch} style={{ width: 250, marginLeft: 1200 }} /> */}
                    </Space>
                    <ListComponent
                        title="驳回列表"
                        // buttons={topButtons}
                        columns={columns}
                        dataSource={approvalList}
                        pagination={pagination}
                        onTableChange={onRejectTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "100%" }}
                    />
                </>
            ) : (
                <>
                    <Space align="center" style={{ marginBottom: 16 }}>
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                handleDetails("look")
                                setEditId(null)
                            }}
                        >
                            查看
                        </Button>
                        {/* <Search placeholder="请输入名称" allowClear onSearch={onSearch} style={{ width: 250, marginLeft: 1200 }} /> */}
                    </Space>
                    <ListComponent
                        title="通过列表"
                        // buttons={topButtons}
                        columns={columns}
                        dataSource={approvalList}
                        pagination={pagination}
                        onTableChange={onPassTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "100%" }}
                    />
                </>
            )}
        </>
    )
}
