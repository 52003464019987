import axios from "axios"
import { getToken } from "./dataStorage"
import { message } from "antd"
import { API_SERVER_HOST, API_SERVER_PORT, TDMS_API_SERVE, SHOP_API_SERVE, SHOP_B_API_SERVE } from "../config/app"
import { getShopToken } from "@/utils/dataStorage"
/*
 * @params {string} url 请求地址
 * @params {object} resOpts 请求配置参数
 */
const download = (url, resOpts = {}) => {
    const { type = "post", data = "" } = resOpts
    const queryArgs = {
        url: `${API_SERVER_HOST}:${API_SERVER_PORT}${url}`,
        method: type,
        data,
        headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json; charset=utf-8',
            "Content-Type": "application/json",
            Authorization: getToken(),
            withCredentials: true,
        },
        responseType: "blob",
        timeout: 1000 * 60 * 5,
    }
    // tips: 这里直接返回的是response整体!
    return axios.request(queryArgs).catch((err) => message.error("导出错误，请尝试重新导出"))
}

const tdmsDownLoad = (url, resOpts = {}) => {
    const { type = "get", data = "" } = resOpts
    const queryArgs = {
        url: `${TDMS_API_SERVE}${url}`,
        method: type,
        data,
        headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json; charset=utf-8',
            "Content-Type": "application/json",
            Authorization: getToken(),
            withCredentials: true,
        },
        responseType: "blob",
    }
    // tips: 这里直接返回的是response整体!
    return axios.request(queryArgs).catch((err) => message.error("导出错误，请尝试重新导出", err))
}

const shopDownLoad = (url, resOpts = {}) => {
    const shopToken = getShopToken()
    const { type = "get", data = "" } = resOpts
    const queryArgs = {
        url: `${SHOP_API_SERVE}${url}`,
        method: type,
        data,
        headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json; charset=utf-8',
            "Content-Type": "application/json",
            Authorization: shopToken && shopToken.access_token,
            withCredentials: true,
        },
        responseType: "blob",
    }
    // tips: 这里直接返回的是response整体!
    return axios.request(queryArgs).catch((err) => message.error("导出错误，请尝试重新导出", err))
}

const shopBDownLoad = (url, resOpts = {}) => {
    const shopToken = getShopToken()
    const { type = "get", data = "" } = resOpts
    const queryArgs = {
        url: `${SHOP_B_API_SERVE}${url}`,
        method: type,
        data,
        headers: {
            // Accept: 'application/json',
            // 'Content-Type': 'application/json; charset=utf-8',
            "Content-Type": "application/json",
            Authorization: shopToken && shopToken.access_token,
            withCredentials: true,
        },
        responseType: "blob",
    }
    // tips: 这里直接返回的是response整体!
    return axios.request(queryArgs).catch((err) => message.error("导出错误，请尝试重新导出", err))
}

function convertRes2Blob(response) {
    // debugger
    console.log(response)
    console.log(response.headers["content-disposition"])
    // 提取文件名
    const fileName = response.headers["content-disposition"]?.match(/filename=(.*)/)[1]
    // 将二进制流转为blob
    // const blob = new Blob([response.data], { type: response.type })
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
        window.navigator.msSaveBlob(response.data)
    } else {
        // 创建新的URL并指向File对象或者Blob对象的地址
        const blobURL = window.URL.createObjectURL(response.data)
        // 创建a标签，用于跳转至下载链接
        const tempLink = document.createElement("a")
        tempLink.style.display = "none"
        tempLink.href = blobURL
        tempLink.setAttribute("download", decodeURI(fileName))
        // 兼容：某些浏览器不支持HTML5的download属性
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank")
        }
        // 挂载a标签
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        // 释放blob URL地址
        window.URL.revokeObjectURL(blobURL)
    }
}
export { download, convertRes2Blob, tdmsDownLoad, shopDownLoad, shopBDownLoad }
