import { useState, useEffect } from 'react'
import { createModel } from 'hox'
import { Form } from 'antd'
import { getTeamList, getTeamMemberList } from '@/service/teamService'
import { organPage } from '@/service/orgService'
import debounce from 'lodash/debounce'
import { getUserInfo, getUserOrgan } from '@/utils/dataStorage'
// import {useHomePengddingOrderModel} from "./homePengddingOrder"

export const useHomeDispatchInfoModel = createModel(function () {
    // const {dispatchPopupVisible} = useHomePengddingOrderModel()
    const [form] = Form.useForm()
    const [organType, setOrganType] = useState(0)
    //机构列表
    const [org, setOrg] = useState([])
    //团队列表
    const [teamInfo, setTeamInfo] = useState({
        id: '', //列表
        data: [],
        fetching: false,
    })

    const handleChangeOrg = () => {
        form.resetFields(['teamId', 'majorUserId', 'teamUserIds'])
    }

    //成员列表
    const [memberInfo, setMemberInfo] = useState({
        id: '', //列表
        data: [],
        fetching: false,
    })
    //选中添加的成员
    const [mainMemberList, setMainMemberList] = useState([])


    const dispatchInit = () => {
        organPage({
            searchItem: { showPlatformOrgan: true },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
        const { organType } = getUserOrgan()
        setOrganType(organType)
    }


   

    //团队列表查询
    const teamQuery = (value) => {
        setMemberInfo({
            ...teamInfo,
            data: [],
            fetching: true,
        })
        let organId = form.getFieldValue('organId')
        getTeamList({
            searchItem: { disableFlag: 0, teamName: value, organId },
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.teamName,
                    value: item.id,
                })
                return arr
            }, [])
            setTeamInfo({
                ...teamInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const teamQuerys = debounce(teamQuery, 1000)
    const handleChangeTeam = () => {
        form.resetFields(['majorUserId', 'teamUserIds'])
    }

    //成员列表查询
    const memberQuery = (value) => {
        setMemberInfo({
            ...memberInfo,
            data: [],
            fetching: true,
        })
        let teamId = form.getFieldValue('teamId')
        getTeamMemberList({
            pageNum: 1,
            pageSize: 10000,
            searchItem: { disableFlag: 0, userName: value, teamId },
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.userName,
                    value: item.id,
                })
                return arr
            }, [])
            setMemberInfo({
                ...memberInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const memberQuerys = debounce(memberQuery, 1000)
    const handleChangeMember = (val, option) => {
        let list = option.map((item) => {
            return {
                text: item.children,
                value: item.value,
            }
        })
        setMainMemberList(list)
        let majorUserId = form.getFieldValue('majorUserId')
        if (majorUserId) {
            if (val.indexOf(majorUserId) === -1) {
                form.resetFields(['majorUserId'])
            }
        }
    }
    return {
        form,
        org,
        handleChangeOrg,
        organType,
        teamInfo,
        handleChangeTeam,
        teamQuery,
        teamQuerys,
        memberInfo,
        handleChangeMember,
        memberQuery,
        memberQuerys,
        mainMemberList,
        dispatchInit
    }
})
