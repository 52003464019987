import React, { useEffect, useState, useContext } from "react"
import { Form, Input, Row, Col, message, Button, Select, Spin, DatePicker, Upload } from "antd"
import Modal from "@/components/Modal"
import { addMoveCar, getMoveCarOne, editMoveCar } from "@/service/moveCarService"
import { getvehicleLicenseInfo } from "@/service/baiduAiService"
import { getTeamList } from "@/service/teamService"
import { getPcodeDict } from "@/utils/dataStorage"
// import debounce from 'lodash/debounce';
import moment from "moment"
import { MyContext } from "@/store/myContext"
import { organPage } from "@/service/orgService"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { VehicleEquipmentType } from "@/utils/enum"
const { Option } = Select

const MoveCarDetails = (props) => {
    const { showAllOrgan, message } = useContext(MyContext)
    const { modeKey, dispatch, mode, rowInfo } = props //父组件传过来的方法。
    const [form] = Form.useForm()
    const [teamInfo, setTeamInfo] = useState({
        //团队列表
        data: [],
        fetching: false,
    })
    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [previewImage, setpreviewImage] = useState("") //预览展示的行驶证
    const [licenseFileList, setLicenseFileList] = useState([]) //图片list
    const [scanningLoading, setScanningLoading] = useState(false)
    const [org, setOrg] = useState([])
    useEffect(() => {
        resetFormItem()
        if (showAllOrgan) {
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
    }, [])

    //团队列表查询
    const teamQuery = (value) => {
        let organId = form.getFieldValue("organId")
        if (!organId && showAllOrgan) {
            message.error("请先选择机构")
            return
        }
        setTeamInfo({
            ...teamInfo,
            fetching: true,
        })
        let data = {
            searchItem: { organId },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getTeamList(data).then((res) => {
            if (res && res.retCode === 200) {
                let results = res.retData.list
                const data = results.map((item) => ({
                    text: item.teamName,
                    value: item.id,
                }))
                setTeamInfo({
                    data: data,
                    fetching: false,
                })
            }
        })
    }
    //防抖
    // const teamQuerys = debounce(teamQuery, 1000);

    //返回按钮
    const handleBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "moveCarManage",
        })
    }
    //移动车表单提交
    const carSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                registeredDate: values.registeredDate ? values.registeredDate.format("YYYY-MM-DDTHH:mm:ss") : null,
                lastMaintenanceDate: values.lastMaintenanceDate ? values.lastMaintenanceDate.format("YYYY-MM-DDTHH:mm:ss") : null,
                nextMaintenanceDate: values.nextMaintenanceDate ? values.nextMaintenanceDate.format("YYYY-MM-DDTHH:mm:ss") : null,
                drivingLicenseImg: previewImage,
            }
            //新增
            if (mode === "insert") {
                addMoveCar(data).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleBack()
                    message.success("车辆新增成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //     }
                // })
                //编辑   团队id  编辑改过就用编辑的， 要不父组件带过来的
            } else if (mode === "edit") {
                let teamId = values.teamId.length === 32 ? values.teamId : rowInfo.teamId
                editMoveCar({ ...data, id: rowInfo.id, teamId: teamId }).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleBack()
                    message.success("车辆信息编辑成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.error(err.retMsg)
                //     }
                // })
            }
        })
    }
    //行驶证图片查看
    const handlePreview = async (file) => {
        setpreviewVisible(true)
    }
    //图像识别
    const getImgInfo = (fileMd5) => {
        getvehicleLicenseInfo(fileMd5)
            .then((res) => {
                let { words_result } = res.retData
                if (words_result && words_result.words === 200) {
                form.setFieldsValue({
                    vehicleCode: words_result["号牌号码"].words,
                    vehicleType: words_result["车辆类型"].words,
                    vehicleNature: words_result["使用性质"].words,
                    vehicleBrand: words_result["品牌型号"].words,
                    vehicleVin: words_result["车辆识别代号"].words,
                    engineCode: words_result["发动机号码"].words,
                    registeredDate: moment(words_result["注册日期"].words),
                })
                } else {
                    message.error("请上传清晰的行驶证图片")
                    setLicenseFileList([])
                    form.resetFields(["drivingLicenseImg"])
                }
                setScanningLoading(false)
            })
            .catch((err) => {
                form.resetFields(["drivingLicenseImg"])
                setScanningLoading(false)
            })
    }
    // 图片上传
    const handleImgChange = async (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setScanningLoading(true)
            getImgInfo(md5)
            setpreviewImage(md5)
            setLicenseFileList(info.fileList)
        } else if (info.file.status === "removed") {
            setLicenseFileList([])
            setpreviewImage("")
        }
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit" || mode === "look") {
            getMoveCarOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                let retData = res.retData
                let imgFileList = []
                if (retData.drivingLicenseImg) {
                    //回显图片， 有的话在做回显
                    imgFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${retData.drivingLicenseImg}`,
                        },
                    ]
                    //回显图片
                    setLicenseFileList(imgFileList)
                    setpreviewImage(retData.drivingLicenseImg)
                }
                form.setFieldsValue({
                    ...retData,
                    teamId: retData.teamName,
                    drivingLicenseImg: imgFileList,
                    registeredDate: retData.registeredDate ? moment(retData.registeredDate) : null,
                    lastMaintenanceDate: retData.lastMaintenanceDate ? moment(retData.lastMaintenanceDate) : null,
                    nextMaintenanceDate: retData.nextMaintenanceDate ? moment(retData.nextMaintenanceDate) : null,
                })
                // }
            })
        }
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    //控制时间
    const disabledDate = (current) => {
        return current && current < moment().startOf("day")
    }
    //控制时间 当天之后
    const disabledDates = (current) => {
        return current && current > moment().startOf("day")
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">移动车管理</span>
            </div>
            <Spin spinning={scanningLoading} tip="图片快速识别中">
                <div className="list-container">
                    <div className="list-header">
                        <div className="list-name">
                            <span>移动车详情</span>
                        </div>
                        <div className="list-but">
                            {!isLook(mode) && (
                                <Button shape="round" className="but-left" onClick={carSubmit}>
                                    保存
                                </Button>
                            )}
                            <Button shape="round" className="but-left" onClick={handleBack}>
                                取消{" "}
                            </Button>
                        </div>
                    </div>
                    <div className="form-details-warp">
                        <Form form={form} {...formItemLayout}>
                            <Row>
                                <Col span={2}>
                                    <span className="form-title">基本信息</span>
                                </Col>
                                <Col span={9}>
                                    {showAllOrgan ? (
                                        <Form.Item
                                            label="所属机构"
                                            name="organId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择所属机构",
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择所属机构" style={{ width: "100%" }}>
                                                {org.map((item) => (
                                                    <Select.Option value={item.id} key={item.id}>
                                                        {item.organName}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="团队名称"
                                        name="teamId"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择团队",
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={isLook(mode)}
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="请选择团队"
                                            notFoundContent={teamInfo.fetching ? <Spin size="small" /> : null}
                                            onFocus={() => teamQuery()}
                                            style={{ width: "100%" }}
                                            // filterOption={false}
                                            // onSearch={teamQuerys}
                                        >
                                            {teamInfo.data.map((item) => (
                                                <Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item
                                        label="车辆牌照"
                                        name="vehicleCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: "请输入车辆牌照",
                                            },
                                            {
                                                pattern: /^[\u4E00-\u9FA5][\da-zA-Z]{6,7}$/,
                                                message: "请输正确的车辆牌照",
                                            },
                                        ]}
                                    >
                                        <Input placeholder="请输入车辆牌照" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="发动机号" name="engineCode">
                                        <Input placeholder="请输入发动机号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item label="使用性质" name="vehicleNature">
                                        <Input placeholder="请输入使用性质" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="变速箱号" name="gearboxCode">
                                        <Input placeholder="请输入变速箱号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item label="车辆品牌" name="vehicleBrand">
                                        <Input placeholder="请输入车辆品牌" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车桥" name="vehicleAxle">
                                        <Input placeholder="请输入车桥" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item label="车系" name="vehicleSeries">
                                        <Input placeholder="请输入车系" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆颜色" name="vehicleColor">
                                        <Input placeholder="请输入车辆颜色" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item label="车型" name="vehicleModel">
                                        <Input placeholder="请输入车型" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="三包厂商" name="threeGuaranteesManufacturer">
                                        <Select disabled={isLook(mode)} showSearch allowClear placeholder="请选择三包厂商" style={{ width: "100%" }}>
                                            {getPcodeDict("107").children.map((item) => (
                                                <Option value={item.dictCode} key={item.id}>
                                                    {item.title}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item
                                        label="Vin码"
                                        name="vehicleVin"
                                        // rules={[
                                        //     { required: true, message: '请输入车辆识别号' },
                                        //     { pattern: /^(?!(?:\d+|[a-zA-Z]+)$)[\da-zA-Z]{17}$/, message: '请输17位字母加数字格式' }]}
                                    >
                                        <Input placeholder="请输入Vin码" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="注册日期" name="registeredDate">
                                        <DatePicker
                                            disabled={isLook(mode)}
                                            style={{ width: "100%" }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            // disabledDate={disabledDates}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item label="车载设备编号" name="vehicleEquipmentCode">
                                        <Input placeholder="请输入车载设备编号" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item label="车载设备类型" name="vehicleEquipmentType">
                                        <Select placeholder="请选择车载设备类型" style={{ width: "100%" }}>
                                                {Object.entries(VehicleEquipmentType).map((item) => (
                                                    <Select.Option value={item[0]} key={item[0]}>
                                                        {item[1]}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <Col offset={2} span={9}>
                                    <Form.Item label="登记日期" name="createTime">
                                        <Input placeholder="自动获取" disabled />
                                    </Form.Item>
                                </Col>
                            
                                <Col offset={1} span={9}>
                                    <Form.Item label="车辆类型" name="vehicleType">
                                        <Input placeholder="请输入车辆类型" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                                <Col offset={2} span={9}>
                                    <Form.Item label="行驶证" name="drivingLicenseImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                        <Upload
                                            disabled={isLook(mode)}
                                            action={`${fileUploadUrl}upload/image_old`}
                                            headers={{ authorization: getToken() }}
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            fileList={licenseFileList}
                                            onPreview={handlePreview}
                                            onChange={handleImgChange}
                                        >
                                            {licenseFileList.length >= 1 ? null : <Button>上传行驶证</Button>}
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div className="from-line" />
                            <Row>
                                <Col span={2}>
                                    <span className="form-title">价格信息</span>
                                </Col>
                                <Col span={9}>
                                    <Form.Item label="上次保养日期" name="lastMaintenanceDate">
                                        <DatePicker
                                            disabled={isLook(mode)}
                                            style={{ width: "100%" }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={disabledDates}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="上次保养里程"
                                        name="lastMaintenanceMileage"
                                        rules={[
                                            {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: "请输入数字格式",
                                            },
                                        ]}
                                    >
                                        <Input suffix="KM" placeholder="请输入上次保养里程" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item label="下次保养日期" name="nextMaintenanceDate">
                                        <DatePicker
                                            disabled={isLook(mode)}
                                            style={{ width: "100%" }}
                                            format="YYYY-MM-DD HH:mm:ss"
                                            showTime={{
                                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                            }}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col offset={1} span={9}>
                                    <Form.Item
                                        label="下次保养里程"
                                        name="nextMaintenanceMileage"
                                        rules={[
                                            {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: "请输入数字格式",
                                            },
                                        ]}
                                    >
                                        <Input suffix="KM" placeholder="请输入下次保养里程" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={2} span={9}>
                                    <Form.Item
                                        label="最新里程"
                                        name="latestMileage"
                                        rules={[
                                            {
                                                pattern: /^(0|[1-9][0-9]*)$/,
                                                message: "请输入数字格式",
                                            },
                                        ]}
                                    >
                                        <Input suffix="KM" placeholder="请输入最新里程" disabled={isLook(mode)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Spin>
            <Modal
                visible={previewVisible}
                title="行驶图片查看"
                // onCancel={() => setpreviewVisible(false)}
                onOk={() => setpreviewVisible(false)}
            >
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage}`} />
            </Modal>
        </>
    )
}

export default MoveCarDetails
