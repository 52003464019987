import React, { useEffect, useState, useRef, useImperativeHandle } from 'react'
import { Form, Row, Col, Input, message, Table, Select } from 'antd'
import styled from '@emotion/styled'
import { getSystemOneByKey } from '@/service/maintenanceService'
import { EditableRow, EditableCell } from '@/erp_subpackage/components/YHEditTable/editTable'
import { bignumber } from '@/utils/util'
import { formatNum } from '@/erp_subpackage/utils/util'
import { zfDict } from '@/service/ordersService'
const { Option } = Select
const { TextArea } = Input
const keyArr = ['user1', 'user2', 'user3', 'user4', 'user5', 'user6']
const zfVehicleTypeList = ['挂车/Trailer', '牵引车/Truck', '客车/Bus', '工程机械车/Off-high way', ' 乘用车/Car', ' 特种车', ' 船', ' 农机', ' 车床']
export default ({ mode, caiAiFuRef }) => {
    const [form] = Form.useForm()
    const [isChange, setIsChange] = useState(false)
    // 故障判定
    const [faultDecideOption, setFaultDecideOption] = useState([])
    // 解决方案类型
    const [solutionsTypeOption, setSolutionsTypeOption] = useState([])
    // 车辆类型
    const [truckTypeOption, setTruckTypeOption] = useState([])
    // 故障点
    const [faultPointOption, setFaultPointOption] = useState([])
    // 产品类型
    const [productTypeOption, setProductTypeOption] = useState([])
    // 产品子类型
    const [productType2Option, setProductType2Option] = useState([])

    useEffect(() => {
        // getItems()
        getZfInfo()
    }, [])
    const fixedItem = useRef({})
    const init = (orderInfoOne) => {
        if (fixedItem.current && fixedItem.current.length > 0) {
            getDefaultData(orderInfoOne)
        } else {
            setTimeout(() => {
                init(orderInfoOne)
            }, 100)
        }
    }

    const getZfInfo = () => {
        zfDict().then(({ retData }) => {
            const { faultdecide, faultpoint, producttype, serviceItem, solutionstype, trucktype } = retData
            setTruckTypeOption(Object.entries(trucktype).map((i) => ({ val: i[1], key: i[0] })))
            setFaultDecideOption(Object.entries(faultdecide).map((i) => ({ val: i[1], key: i[0] })))
            setSolutionsTypeOption(Object.entries(solutionstype).map((i) => ({ val: i[1], key: i[0] })))
            setFaultPointOption(Object.entries(faultpoint).map((i) => ({ val: i[1], key: i[0] })))
            setProductTypeOption(producttype.map((i) => ({ val: i.name, key: i.code, childen: i.sub })))
            fixedItem.current = serviceItem
            if (mode === 'insert') {
                getDefaultData({ orderZfInfo: { orderZfItemList: [] } })
            }
        })
    }

    const [list, setList] = useState([])
    const getDefaultData = (orderInfoOne={}) => {
        const orderZfInfo = orderInfoOne?.orderZfInfo || {}
        let { orderZfItemList, ...formVal } = orderZfInfo
        orderZfItemList = orderZfItemList ? orderZfItemList : []
        formVal = formVal ? formVal : {}
        form.setFieldsValue(formVal)
        //更具产品类型获取产品子类型的option
        try {
            const val = formVal.productType
            setProductType2Option(productTypeOption.filter((i) => i.key === val)[0].childen.map((i) => ({ key: i, value: i })))
        } catch {
            setProductType2Option([])
        }
        setIsChange(Object.values(formVal).findIndex((i) => i) > -1)
        //计算数据
        let firstRow = { itemName: '服务人员', itemUnit: '--', all: '合计', user1: '', user2: '', user3: '', user4: '', user5: '', user6: '' }
        let arr = [firstRow, ...fixedItem.current]
        let users = {}
        orderZfItemList.forEach((item, index) => {
            if (users[item.servicePersonnelName]) {
                users[item.servicePersonnelName].val.push(item)
            } else {
                users[item.servicePersonnelName] = {
                    key: keyArr[index],
                    val: [item],
                }
            }
        })
        for (const k in users) {
            let user = users[k]
            arr = arr.map((item) => {
                if (item.itemName === '服务人员') {
                    //服务人员
                    item[user.key] = user.val[0].servicePersonnelName
                } else {
                    item[user.key] = user.val.find((i) => i.itemName === item.itemName).itemVal || ''
                }
                return { ...item }
            })
        }
        arr = arr.map((item) => {
            if (item.itemName !== '服务人员') {
                //服务人员
                item.all =
                    item.all === '合计'
                        ? '合计'
                        : bignumber.multiAdd(item.user1 || 0, item.user2 || 0, item.user3 || 0, item.user4 || 0, item.user5 || 0, item.user6 || 0)
            }
            return item
        })
        setList([...arr])
    }

    // const getItems = () => {
    //     getSystemOneByKey('zf_service_item').then((res) => {
    //         if (res && res.retData && res.retData.confVal) {
    //             fixedItem.current = JSON.parse(res.retData.confVal)
    //             console.log(fixedItem.current)
    //             console.log(222)
    //         }
    //         if (mode === 'insert') {
    //             getDefaultData({ orderZfInfo: { orderZfItemList: [] } })
    //         }
    //     })
    // }

    //查看模式
    const isLook = (str) => {
        return str === 'look' ? true : false
    }
    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    }

    //暴露给子组件的方法
    useImperativeHandle(caiAiFuRef, () => ({
        async getSaveData() {
            try {
                let formVal = await form.validateFields()
                const orderZfItemList = []
                keyArr.forEach((k) => {
                    if (list[0][k]) {
                        list.forEach((item, index) => {
                            index > 0 &&
                                orderZfItemList.push({
                                    servicePersonnelName: list[0][k],
                                    itemName: item.itemName,
                                    itemUnit: item.itemUnit,
                                    itemVal: item[k] || '',
                                })
                        })
                    }
                })
                return isChange ? { ...formVal, orderZfItemList } : null
            } catch (err) {
                console.log(999)
                console.log(err)
                const { errorFields } = err
                if (errorFields && errorFields.length > 0) {
                    message.error(errorFields[0].errors[0])
                }
                return false
            }
        },
        caiAiFuinit: init,
    }))

    const columns = [
        {
            title: '序号',
            align: 'center',
            width: '100px',
            render: (text, record, index) => index + 1,
        },
        {
            title: '项目',
            dataIndex: 'itemName',
            align: 'center',
            width: '120px',
        },
        {
            title: '单位',
            dataIndex: 'itemUnit',
            align: 'center',
            width: '100px',
        },
        {
            title: '服务数据', //服务人员1
            colSpan: 7,
            dataIndex: 'user1',
            align: 'center',
            width: '100px',
            editable: true,
        },
        {
            title: '服务人员2',
            colSpan: 0,
            dataIndex: 'user2',
            align: 'center',
            width: '100px',
            editable: true,
        },
        {
            title: '服务人员3',
            colSpan: 0,
            dataIndex: 'user3',
            align: 'center',
            width: '10%',
            editable: true,
        },
        {
            title: '服务人员4',
            colSpan: 0,
            dataIndex: 'user4',
            align: 'center',
            width: '10%',
            editable: true,
        },
        {
            title: '服务人员5',
            colSpan: 0,
            dataIndex: 'user5',
            align: 'center',
            width: '10%',
            editable: true,
        },
        {
            title: '服务人员6',
            colSpan: 0,
            dataIndex: 'user6',
            align: 'center',
            width: '10%',
            editable: true,
        },
        {
            title: '合计',
            colSpan: 0,
            dataIndex: 'all',
            align: 'center',
            width: '20%',
        },
    ]

    //内联编辑   (计算价格)
    const handleSave = (row) => {
        const { user1, user2, user3, user4, user5, user6, itemName } = row
        let newRow = {}
        if (itemName === '服务人员') {
            newRow = {
                ...row,
            }
        } else {
            newRow = {
                ...row,
                user1: +formatNum(user1),
                user2: +formatNum(user2),
                user3: +formatNum(user3),
                user4: +formatNum(user4),
                user5: +formatNum(user5),
                user6: +formatNum(user6),
            }
        }

        let arr = [...list]
        const index = arr.findIndex((item) => row.itemName === item.itemName)
        const item = arr[index]
        arr.splice(index, 1, { ...item, ...newRow })

        //如果没有服务人员，置空数据
        keyArr.forEach((k) => {
            if (!arr[0][k]) {
                arr = arr.map((item) => ({ ...item, [k]: '' }))
            }
        })

        arr = arr.map((item) => ({
            ...item,
            all:
                item.all === '合计'
                    ? '合计'
                    : bignumber.multiAdd(item.user1 || 0, item.user2 || 0, item.user3 || 0, item.user4 || 0, item.user5 || 0, item.user6 || 0),
        }))
        setList(arr)
    }

    const newSelectColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => {
                let editable = col?.editable
                const { dataIndex } = col
                if (rowIndex !== 0) {
                    editable = !!list[0][dataIndex]
                }
                if (dataIndex === 'itemName' || dataIndex === 'all' || dataIndex === 'itemUnit') {
                    editable = false
                }

                return {
                    colIndex: index + 1,
                    rowIndex,
                    record,
                    rules: col?.rules,
                    options: col?.options,
                    editType: col?.editType,
                    editable: editable,
                    onSelectChange: col?.onSelectChange,
                    onSelectFocus: col?.onSelectFocus,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave,
                }
            },
        }
    })

    const onValuesChange = (changedValues, allValues) => {
        if (!isChange && Object.values(allValues).findIndex((i) => i) > -1) {
            setIsChange(true)
        }
    }

    const onProductTypeChange = (val) => {
        form.setFieldsValue({ productType2: '' })
        try {
            setProductType2Option(productTypeOption.filter((i) => i.key === val)[0].childen.map((i) => ({ key: i, value: i })))
        } catch {
            setProductType2Option([])
        }
    }

    return (
        <CaiAiFuBox>
            <Form form={form} {...formLayout} onValuesChange={onValuesChange} style={{ paddingLeft: '20px' }}>
                <Row>
                    <Col span={24}>
                        <Title>基础信息</Title>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆类型" name="truckType" rules={[{ required: isChange, message: '请选择车辆类型' }]}>
                            <Select disabled={isLook(mode)} placeholder="请选择车辆类型" style={{ width: '100%' }} allowClear>
                                {truckTypeOption.map((item) => (
                                    <Option value={item.key} key={item.key}>
                                        {item.val}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="车辆故障码" name="faultCode" rules={[{ required: isChange, message: '请输入车辆故障码' }]}>
                            <Input placeholder="车辆故障码" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="故障判定" name="faultDecide" rules={[{ required: isChange, message: '请选择故障判定' }]}>
                            <Select disabled={isLook(mode)} placeholder="请选择故障判定" style={{ width: '100%' }} allowClear>
                                {faultDecideOption.map((item) => (
                                    <Option value={item.key} key={item.key}>
                                        {item.val}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="解决方案类型" name="solutionsType" rules={[{ required: isChange, message: '请选择解决方案类型' }]}>
                            <Select disabled={isLook(mode)} placeholder="请选择解决方案类型" style={{ width: '100%' }} allowClear>
                                {solutionsTypeOption.map((item) => (
                                    <Option value={item.key} key={item.key}>
                                        {item.val}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="故障点" name="faultPoint" rules={[{ required: isChange, message: '请选择故障点' }]}>
                            <Select disabled={isLook(mode)} placeholder="请选择故障点" style={{ width: '100%' }} allowClear>
                                {faultPointOption.map((item) => (
                                    <Option value={item.key} key={item.key}>
                                        {item.val}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="产品类型" name="productType" rules={[{ required: isChange, message: '请选择产品类型' }]}>
                            <Select disabled={isLook(mode)} placeholder="请选择产品类型" style={{ width: '100%' }} allowClear onChange={onProductTypeChange}>
                                {productTypeOption.map((item) => (
                                    <Option value={item.key} key={item.key}>
                                        {item.val}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="产品子类型" name="productType2" rules={[{ required: isChange, message: '请选择产品子类型' }]}>
                            <Select disabled={isLook(mode)} placeholder="请选择产品子类型" style={{ width: '100%' }} allowClear>
                                {productType2Option.map((item) => (
                                    <Option value={item.key} key={item.key}>
                                        {item.val}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱型号" name="vehicleGearboxModel">
                            <Input placeholder="变速箱型号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱号" name="vehicleGearboxCode">
                            <Input placeholder="变速箱号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱排列号" name="vehicleGearboxSerialNumber">
                            <Input placeholder="变速箱排列号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="故障主要责任方" name="faultResponsibility">
                            <Input placeholder="故障主要责任方" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱用油状态" name="vehicleGearboxOilStatus">
                            <Input placeholder="变速箱用油状态" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱用油油量" name="vehicleGearboxOilQuantity">
                            <Input placeholder="变速箱用油油量" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="变速箱用油型号" name="vehicleGearboxOilModel">
                            <Input placeholder="变速箱用油型号" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="旧件处理" name="oldPartsDisposition">
                            <Input placeholder="旧件处理" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="现场试车" name="testResultOnSite">
                            <Input placeholder="现场试车" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="台架试验" name="testResultBench">
                            <Input placeholder="台架试验" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Title>故障信息</Title>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="实际故障现象" name="faultPhenomenon" labelCol={{ span: 6 }}>
                            <TextArea rows={2} disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="实际损坏状态" name="damageStatus" labelCol={{ span: 6 }}>
                            <TextArea rows={2} disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="主要更换零件" name="replacedParts" labelCol={{ span: 6 }}>
                            <TextArea rows={2} disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="故障原因分析" name="faultAnalysis" labelCol={{ span: 6 }}>
                            <TextArea rows={2} disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="实际维修过程" name="repairProcess" labelCol={{ span: 6 }}>
                            <TextArea rows={2} disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="维修结论" name="repairConclusion" labelCol={{ span: 6 }}>
                            <TextArea rows={2} disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="row-label">
                        <Form.Item label="未试车/未做台架试验的原因" name="noTestReason" labelCol={{ span: 6 }}>
                            <TextArea rows={2} disabled={isLook(mode)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Title>服务项目</Title>
            <Table
                dataSource={list}
                bordered
                pagination={false}
                rowKey={(record) => record.itemName}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newSelectColumns}
                style={{ marginBottom: '40px' }}
            />
        </CaiAiFuBox>
    )
}

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
    line-height: 40px;
`

const CaiAiFuBox = styled.div``
