import React, { useEffect, useState, useRef } from "react"
import { Form, message, Modal } from "antd"
import { getOrderOne, orderCombinationOne, orderCombinationUpdate } from "@/service/ordersService"

import Maintenance from "./maintenance" //维修项目
import Material from "./material" //物料
export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess, orderId } = props
    const [orderDetail, setOrderDetail] = useState(null)
    const [detail, setDetail] = useState({ materials: [], items: [] })
    const [form] = Form.useForm()
    const maintenanceRef = useRef() //维修项目 子组件
    const materialRef = useRef() //维修物料  子组件

    useEffect(() => {
        if (visible) {
            queryMotorcade()
            getOrderDetail()
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    const onReceivables = (obj) => {
        //配件总金额变动
        if (obj.material) {
            form.setFieldsValue({
                outboundAmount: Math.round(obj.material * 100) / 100,
            })
        }
    }

    const getOrderDetail = () => {
        getOrderOne(orderId).then((res) => {
            setOrderDetail(res.retData)
        })
    }

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            orderCombinationOne(rowInfo.id).then((res) => {
                let results = res.retData
                setDetail(results)
            })
        }
    }

    //表单提交
    const onUserSubmit = async () => {
        let maintainMaterialsMap, maintenanceMap
        let materialData = await materialRef.current.upMaterialData()
        maintainMaterialsMap = await materialData.map((item, index) => {
            return {
                id: item.id || null,
                maintainOrderId: item.maintainOrderId,
                maintainOrderMaterialId: item.maintainOrderMaterialId,
                // threeGuaranteesUnit: item.threeGuaranteesUnit, //三包状态
                // claimNumber: item.claimNumber,
                serviceNature: item.serviceNature, //服务性质
                preferentialAmount: item.preferentialAmount, //优惠
                partUnit: item.partUnit, //单位
                materialDiscount: item.materialDiscount ? item.materialDiscount : null, //折扣
                unitPrice: item.unitPrice,
                materialNumber: item.materialNumber, //物料数量
                giftFlag: item.giftFlag, //是否赠送
                maintainOrderCode: item.maintainOrderCode,
                serialNumber: index + 1,
                materialName: item.materialName,
            }
        })
        //项目数据
        let maintenanceData = await maintenanceRef.current.upMaintenancelData()
        maintenanceMap = await maintenanceData.map((item) => {
            return {
                id: item.id || null, //编辑带过来的id
                itemId: item.itemId, //维修项目id
                preferentialAmount: item.preferentialAmount, //维修项优惠
                itemName: item.itemName, //项目名称
                itemCode: item.itemCode, //项目编码
                itemWorkHours: item.workHour, //项目工时
                workHoursPrice: item.hourPrice, //项目单价
                itemDiscount: item.discount ? item.discount : null, //折扣
                serviceNature: item.serviceNature, //服务性质
                remark: item.remark, //备注
                giftFlag: item.giftFlag, //是否赠送
            }
        })
        let data = {
            maintainMaterials: maintainMaterialsMap,
            items: maintenanceMap,
            orderId,
            id: rowInfo.id,
            combinationId: rowInfo.combinationId
        }
        console.log(rowInfo)

        orderCombinationUpdate(data).then((res) => {
            message.success("编辑成功")
            onSuccess && onSuccess()
            handleCancel()
        })
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <Modal forceRender visible={visible} title="维保组合信息" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1200}>
            <div className="form-details-warp">
                {orderDetail && detail.id && (
                    <>
                        {" "}
                        <Maintenance
                            maintenanceRef={maintenanceRef}
                            orderId={orderId}
                            mode={mode}
                            onReceivables={onReceivables}
                            parentOrderType={orderDetail.orderType}
                            dabaoData={detail.items}
                            dataType="dabao"
                        />
                        <Material
                            materialRef={materialRef}
                            orderId={orderId}
                            mode={mode}
                            onReceivables={onReceivables}
                            parentOrderType={orderDetail.orderType}
                            teamId={orderDetail.teamId}
                            dabaoData={detail.materials}
                            dataType="dabao"
                            orderStatus={orderDetail.orderStatus}
                        />
                    </>
                )}
            </div>
        </Modal>
    )
}
