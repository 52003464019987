import React, { useEffect, useState } from 'react'
import { Form, Button, Space, message, Select, TreeSelect, Table } from 'antd'
import YHDrawer from '@/erp_subpackage/components/YhDrawer'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useLockFn } from 'ahooks'
import { creditFlagDs } from '@/erp_subpackage/utils/ErpEnum'
import { getBentityListOption } from '@/utils/dataStorage'
import { commonService } from '@/erp_subpackage/pages/common/commonService'
import { getUserOrgan } from '@/utils/dataStorage'

export const YhFmsAddAccount = ({ visible, setVisible, customerList = [], beCode, beName, formRef, onSucess, type = '0' }) => {
    const [form] = Form.useForm()

    const [accountTypeDs, setAccountType] = useState([])
    const [orgDs, setOrgDs] = useState([])
    

    //默认的一条数据
    const [defalutItem, setDefalutItem] = useState({
        branch: 'ci',
        accountTypeCode: undefined,
        accountTypeName: undefined,
        shopCode: undefined,
        shopName: undefined,
        beCode: beCode ?? undefined,
        beName: beName ?? undefined,
        creditFlag: undefined,
    })

    useEffect(() => {
        getAccountTypeDs()
        getDefaultItem()
    }, [])

    const getDefaultItem = () => {
        let creditFlag
        let payType = formRef.current.getFieldsValue().payType
        if (payType) {
            if (payType === 'settle-type.monthly' || payType === 'settle-type.credit') {
                creditFlag = 1
            } else if (payType === 'settle-type.cash') {
                creditFlag = 0
            } else if (payType === 'settle-type.balance') {
                creditFlag = 2
            }
        }

        let accountTypeCode, accountTypeName
        if(payType === "settle-type.monthly"){
            accountTypeCode = "account-type.credit-month"
            accountTypeName = "月结账户"
        }else if(payType === "settle-type.credit"){
            accountTypeCode = "account-type.credit"
            accountTypeName = "临时授信账户"
        }else if(payType === "settle-type.cash"){
            accountTypeCode = "account-type.cash"
            accountTypeName = "现金账户"
        }


       

        const userOrgan = getUserOrgan()
        const {shopCode, shopName} = userOrgan
        setOrgDs([{ label: shopName, value: shopCode }])
        let val = {
            ...defalutItem,
            beCode: beCode ?? undefined,
            beName: beName ?? undefined,
            creditFlag,
            shopCode: shopCode ?? undefined,
            shopName: shopName ?? undefined,
            accountTypeCode: accountTypeCode ?? undefined,
            accountTypeName: accountTypeName ?? undefined,
        }

        setDefalutItem(val)
        let fromVal = form.getFieldsValue()
        fromVal.fmsAccounts[0] = val
        form.setFieldsValue(fromVal)
    }

    const getAccountTypeDs = async () => {
        let { retData } = await commonService.fmsDictFind({ pcode: 'account-type' })
        setAccountType(
            retData
                ?.filter((item) => item.enable)
                .map((el) => ({
                    label: el.name ?? '',
                    value: el.code ?? '',
                    type: el.value ?? '',
                }))
        )
    }

    //设置对应字段的名称
    const setFormFmsAccounts = (index, fieldName, fieldVal) => {
        let fromVal = form.getFieldsValue()
        let newVal = fromVal.fmsAccounts[index]

        //判断当 更改授信类型时， 重置账户类型重新选择
        if (fieldName === 'creditFlag') {
            fromVal.fmsAccounts.splice(index, 1, { ...newVal, ['accountTypeCode']: undefined })
        } else {
            fromVal.fmsAccounts.splice(index, 1, { ...newVal, [fieldName]: fieldVal.label })
        }
        form.setFieldsValue(fromVal)
    }

    const columns = [
        {
            title: '序号',
            width: 66,
            align: 'center',
            fixed: 'left',
            render: (t, r, i) => i + 1,
        },
        {
            align: 'center',
            title: '客户名称',
            dataIndex: 'name',
        },
        {
            align: 'center',
            title: '客户编码',
            dataIndex: 'code',
        },
    ]

    const onOk = useLockFn(async () => {
        await form.validateFields().then(async (res) => {
            let result = res
            let mdCustomers = customerList.map((item) => ({
                code: item.code ?? '',
                name: item.name ?? '',
            }))
            await commonService.fmsInitAccount({
                    type,
                    fmsAccounts: result?.fmsAccounts ?? [],
                    mdCustomers,
                })
                .then((res) => {
                    if (res) {
                       onClose()
                       message.info(res.retData)
                       onSucess && onSucess()
                    }
                })
        })
    })

    const onClose = () => {
        setVisible(false)
    }
    return (
        <YHDrawer
            {...{
                title: '账户初始化',
                width: 900,
                visible,
                closable: true,
                onOk,
                onClose,
                showFooter: true,
                style: { top: 0 },
            }}
        >
            <div style={{ padding: '20px' }}>
                <Form
                    form={form}
                    autoComplete="off"
                    initialValues={{
                        fmsAccounts: [
                            {
                                ...defalutItem,
                            },
                        ],
                    }}
                >
                    <Form.List name="fmsAccounts">
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.map((field, index) => (
                                        <Space key={field.key} style={{ display: 'flex' }} align="baseline">
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'branch']}
                                                fieldKey={[field.fieldKey, 'branch']}
                                                rules={[{ required: true, message: '请选择分支' }]}
                                            >
                                                <Select style={{ width: 160 }} placeholder="系统分支" options={[{ label: '云创系统', value: 'ci' }]} disabled />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'creditFlag']}
                                                fieldKey={[field.fieldKey, 'creditFlag']}
                                                rules={[{ required: true, message: '请选择授信类型' }]}
                                            >
                                                <Select
                                                    style={{ width: 160 }}
                                                    placeholder="授信类型"
                                                    options={creditFlagDs}
                                                    onChange={(value, option) => {
                                                        option && setFormFmsAccounts(index, 'creditFlag', option)
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'accountTypeCode']}
                                                fieldKey={[field.fieldKey, 'accountTypeCode']}
                                                rules={[{ required: true, message: '请选择账户类型' }]}
                                            >
                                                <Select
                                                    style={{ width: 160 }}
                                                    placeholder="账户类型"
                                                    options={(() => {
                                                        let fromVal = form.getFieldsValue()
                                                        if (!fromVal.fmsAccounts) return []
                                                        let newVal = fromVal.fmsAccounts[index]?.creditFlag ?? 1 //记账1   现金 0  结转 2
                                                        // console.log(fromVal, index, newVal, accountTypeDs)
                                                        //在选择授信账户为记账时， 只展示 授信及月结，  否则返回其它
                                                        return accountTypeDs.filter((item) =>
                                                            newVal === 1
                                                                ? item?.type === 'settle-type.credit'
                                                                : newVal === 2
                                                                ? item?.type === 'settle-type.balance'
                                                                : item?.type === 'settle-type.cash'
                                                        )
                                                        // return accountTypeDs.filter(item => newVal ? (item?.type === DictCodeEnmu.SETTLE_TYPE_CREDIT) : (item?.type === DictCodeEnmu.SETTLE_TYPE_CASH));
                                                        // return accountTypeDs.filter(item => newVal ?
                                                        // 	(item.value === AccountTypeEnum.CREDIT || item.value === AccountTypeEnum.CREDIT_MONTH || item.value === AccountTypeEnum.REBATE || item.value === AccountTypeEnum.REBATE_OIL) :
                                                        // 	(item.value !== AccountTypeEnum.CREDIT && item.value !== AccountTypeEnum.CREDIT_MONTH && item.value !== AccountTypeEnum.REBATE && item.value !== AccountTypeEnum.REBATE_OIL));
                                                    })()}
                                                    onChange={(value, option) => {
                                                        option && setFormFmsAccounts(index, 'accountTypeName', option)
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'beCode']}
                                                fieldKey={[field.fieldKey, 'beCode']}
                                                rules={[{ required: true, message: '请选择账户实体' }]}
                                            >
                                                <Select
                                                    style={{ width: 160 }}
                                                    placeholder="账户实体"
                                                    options={getBentityListOption()}
                                                    onChange={(value, option) => {
                                                        option && setFormFmsAccounts(index, 'beName', option)
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'shopCode']}
                                                fieldKey={[field.fieldKey, 'shopCode']}
                                                rules={[{ required: true, message: '请选择组织机构' }]}
                                            >
                                                <TreeSelect
                                                    style={{ width: 160 }}
                                                    placeholder="组织机构"
                                                    treeData={orgDs}
                                                    onChange={(value, option) => {
                                                        option && setFormFmsAccounts(index, 'shopName', { label: option[0], value: '' })
                                                    }}
                                                />
                                            </Form.Item>

                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} />
                                            ) : null}
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add({ ...defalutItem })} block icon={<PlusOutlined />}>
                                            增加
                                        </Button>
                                    </Form.Item>
                                </>
                            )
                        }}
                    </Form.List>
                </Form>
                <Table columns={columns} dataSource={customerList} pagination={false} />
            </div>
        </YHDrawer>
    )
}
