import {
    post,
    get
} from "../utils/request"
import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
/**
 * 客户接口
 */
//分页
function getCustomerList(data) {
    return post("/ci-crms/customer/list", data)
}

//分页
function getCustomerListNew(data) {
    return post("/ci-customer/customer/list", data)
}

//新增
function insertCustomer(data) {
    return post("/ci-customer/customer/insert", data)
}


//删除
function deleteCustomer(id) {
    return get(`/ci-customer/customer/delete/${id}`)
}

//编辑
function editCustomer(id, data) {
    return post(`/ci-crms/customer/edit/${id}`, data)
}

//编辑
function editCustomerNew(id, data) {
    return post(`/ci-customer/customer/update/${id}`, data)
}

//编辑
function editCustomerNewNew(data) {
    return post(`/ci-customer/customer/update`, data)
}

//财务客户同步
async function customerSync(syncVo) {
    const appPageUrl = "/fms/customer/sync";
    syncVo.sourceApp = "fms";
    return await httpRequest.post(appPageUrl, syncVo,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken()});
}

//获取一条数据
function getCustomerOne(id) {
    return get(`/ci-customer/customer/one/${id}`)
}

//获取一条数据
function getCustomerOneNew(id) {
    return get(`/ci-customer/customer/one/${id}`)
}

//客户禁用启用
function disableCustomer(id, status) {
    return get(`/ci-customer/customer/status/${id}/${status}`)
}

/**
 * 
 * 客户回访
 */

//分页
function getReturnVisitList(data) {
    return post("/ci-customer/return_visit/list", data)
}

//编辑
function editReturnVisit(data) {
    return post(`/ci-customer/return_visit/update`, data)
}

//新增
function addReturnVisit(data) {
    return post(`/ci-customer/return_visit/insert`, data)
}

//获取一条数据
function getReturnVisitOne(id) {
    return get(`/ci-customer/return_visit/one/${id}`)
}

//删除
function deleteReturnVisit(id) {
    return get(`/ci-customer/customer/return_visit/${id}`)
}

/**
 * 用户接口
 */
function getUserList(data) {
    return post("/ci-crms/user/list", data)
}

//新的用户列表接口
function newGetList(data) {
    return post("/ci-customer/customer/user/list", data)
}

//平台用户的接口列表
function platformUserList(data) {
    return post("/ci-platform/user/list", data)
}


//新增
function insertUser(data) {
    return post("/ci-customer/customer/user/insert", data)
}

//新增
function deleteUser(id) {
    return get(`/ci-customer/customer/user/delete/${id}`)
}

//编辑
function editUser(data) {
    return post(`/ci-customer/customer/user/update`, data)
}

//获取一条数据
function getUserOne(id) {
    return get(`/ci-customer/customer/user/one/${id}`)
}

//设为默认送修人
function editCommonlyUsedSenderRepairUser(data) {
    return post(`/ci-customer/customer/user/editCommonlyUsedSenderRepairUser`, data)
}


/**
 * 车辆接口
 */
//分页
function getVehicleList(data) {
    return post("/ci-customer/customer/vehicle/list", data)
}

//分页
function getVehicleList2(data) {
    return post("/ci-customer/customer/vehicle/list2", data)
}

//新增
function insertVehicle(data) {
    return post("/ci-customer/customer/vehicle/insert", data)
}

//编辑
function editVehicle(data) {
    return post("/ci-customer/customer/vehicle/update", data)
}

//获取一条数据
function getVehicleOne(id) {
    return get(`/ci-customer/customer/vehicle/one/${id}`)
}
//获取一条车辆签约数据
function getOne(vehicleId) {
    return get(`/ci-customer/sign_info/one/vehicle/${vehicleId}`)
}

//删除一条数据
function deleteVehicleOne(id) {
    return get(`/ci-customer/customer/vehicle/delete/${id}`)
}

//修改车辆大包
function vehicleEditBigBag(data) {
    return post(`/ci-customer/customer/vehicle/edit/big-bag`, data)
}


/**
 * 车辆附件接口
 */
//分页
function getVehicleAttrList(vehicleId) {
    return get(`/ci-customer/vehicle/attr/list-vehicle/${vehicleId}`)
}

//新增
function insertVehicleeAttr(data) {
    return post("/ci-customer/vehicle/attr/insert", data)
}

//删除一条数据
function deleteVehicleeAttrOne(id) {
    return get(`/ci-customer/vehicle/attr/delete/${id}`)
}


//新增
function editVehicleeAttrName(data) {
    return post("/ci-customer/vehicle/attr/edit/file-name", data)
}

//车辆转移
function vehicleTransfer(data) {
    return post("/ci-customer/customer/vehicle/transfer", data)
}



/**
 * 字典
 */
//客户字典
function getCustomerDict() {
    return get(`/ci-crms/dict/queue`)
}

//客户字典新增
function insertCustomerDict(data) {
    return post(`/ci-crms/dict/add`, data)
}

/**
 * 账单接口
 */
function getBillList(data) {
    return post(`/ci-crms/bill/list`, data)
}

/**
 * 根据客户id、账号类型查询
 */
function getShowBalance(data) {
    return post(`/ci-crms//account/showBalance`, data)
}

function getRedEnvelopeList(data) {
    return post(`/ci/official/read_pack_record/list`, data)
}

function getRedEnvelopeOneByOrderId(orderId) {
    return post(`/ci/official/read_pack_record/query_order/${orderId}`)
}

function syncRedEnvelopeStatus() {
    return get(`/ci/official/read_pack_record/sync_status`)
}

//修改用户名字
function editUserName(data) {
    return post(`/ci-customer/customer/user/edit_user_name`, data)
}

//用户转移
function userTransfer(data) {
    return post("/ci-customer/customer/user/transfer", data)
}

function customerVehicleOrder(data) {
    return post(`/ci-customer/customer/vehicleOrder`, data)
}

//合约查询
function customerContract(data) {
    return post(`/ci-customer/sign_info/page`, data)
}

export {
    getCustomerList,
    insertCustomer,
    editCustomer,
    getCustomerOne,
    getCustomerDict,
    getVehicleList,
    deleteVehicleOne,
    vehicleEditBigBag,
    insertVehicle,
    editVehicle,
    getVehicleOne,
    getOne,
    getUserList,
    newGetList,
    insertUser,
    editUser,
    getUserOne,
    insertCustomerDict,
    disableCustomer,
    getBillList,
    getShowBalance,
    getRedEnvelopeList,
    editUserName,
    editCustomerNew,
    editCustomerNewNew,
    getCustomerOneNew,
    getCustomerListNew,
    getRedEnvelopeOneByOrderId,
    syncRedEnvelopeStatus,
    platformUserList,
    deleteCustomer,
    deleteUser,
    getReturnVisitList,
    editReturnVisit,
    addReturnVisit,
    getReturnVisitOne,
    deleteReturnVisit,
    customerVehicleOrder,
    getVehicleAttrList,
    insertVehicleeAttr,
    deleteVehicleeAttrOne,
    editVehicleeAttrName,
    getVehicleList2,
    customerSync,customerContract,
    editCommonlyUsedSenderRepairUser,
    vehicleTransfer,
    userTransfer
}