import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useQuestionModel } from "../questionModel";
import { getPcodeDict } from "@/utils/dataStorage"


export function PageHeader() {
    const options = getPcodeDict("038").children.map(item => ({ label: item.title, value: item.title, key: item.dictCode }));
    const searchFields = [
        {
            type: "Input",
            placeholder: "问题名称",
            fieldName: "name"
        },
        {
            type: "Select",
            placeholder: "题库类型",
            fieldName: "typeName",
            options: options
        }
    ]

    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useQuestionModel();

    return (
        <YhPageHeader
            title="问题题库"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}