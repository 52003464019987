import React, { useEffect } from "react";
import { Table, Tooltip, Switch, Image } from "antd";
import { useGoodsModel } from "../goodsModel";
import { momentFormat } from "@/erp_subpackage/utils/util"
import YhTooltip from "@/components/YhTooltip"
import { useGlobalModel, transPgToPagination } from "@/erp_subpackage/model/globalModel";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading, searchParam,
        loadData, setSelectedRowKeys, onDisabled
    } = useGoodsModel();
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            dataIndex: 'branch',
            fixed: "left",
            render: (text, record, index) => index + 1
        }, {
            title: '商品图片',
            width: 120,
            fixed: "left",
            dataIndex: 'photo',
            render: text => text?.length ? <Image src={text[0]?.photoMd5} style={{ width: "40px", height: "40px", marginRight: "10px" }} /> : ""
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'code',
            fixed: "left",
            // render:  (text) => <YhTooltip text={text} />,
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.name })} />,
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'name',
            render: (text) => <YhTooltip text={text} />,

        }, {
            title: '所属机构',
            width: 160,
            dataIndex: 'shopName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '商城价格',
            width: 160,
            dataIndex: "shopPrice",
        }, {
            title: '所属分类',
            width: 160,
            dataIndex: "categoryName",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '进货价格',
            width: 160,
            dataIndex: 'purchasePrice',
            // render: (text, record) => (record?.materialDetal?.[0]?.purchasePrice) ?? text
        }, {
            title: '零售价格',
            width: 120,
            dataIndex: 'retailPrice',
            // render: (text, record) => (record?.materialDetal?.[0].repairPrice) ?? text
        }, {
            title: '批发价格',
            width: 160,
            dataIndex: 'tradePrice',
        }, {
            title: '优惠价格',
            width: 160,
            dataIndex: 'discountPrice',
        }, {
            title: '加盟商价格',
            width: 160,
            dataIndex: 'franchiseePrice',
        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
            render: (text) => <YhTooltip text={text} />,
        }, 
        // {
        //     title: '商品编号',
        //     width: 160,
        //     dataIndex: 'itemCode',
        // }, 
        // {
        //     title: '商品名称',
        //     width: 160,
        //     dataIndex: 'itemName',
        // }, 
        {
            title: '发动机型号',
            width: 160,
            dataIndex: 'engineType',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: 'VIN',
            width: 160,
            dataIndex: 'vin',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '品牌',
            width: 160,
            dataIndex: 'brandName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '规格型号',
            width: 160,
            dataIndex: 'model',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '创建日期',
            width: 160,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: '备注',
            width: 160,
            dataIndex: "note"
        }, {
            title: '操作',
            width: 60,
            align: "center",
            dataIndex: 'enable',
            fixed: "right",
            render: (text, record) => (
                <Switch size="small" disabled={searchParam.shopId} checked={record.enable === 1} onChange={(checked, e) => {
                    e.stopPropagation()
                    onDisabled(record.id, checked);
                }} />
            ),
        }
    ];

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.id);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                },
                onSelect: (record) => onRow(record.id),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
