import React, { useContext, useState, useEffect } from "react"

import { Input, Modal, Form, Col, Row, Select, message, Switch, Button, TreeSelect, Upload } from "antd"

import { addMenu, editMenu, getMenuAll, getMenuOne } from "@/service/menuService.js"
import { tdmsCategoryRootlist, tdmsCategoryUpdate, tdmsCategoryInsert,tdmsCategorySearchTree } from "@/service/tdmsService.js"
import { menuStatusEnum, menuTypeEnum } from "@/config/Constants.js"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

const defaultData = {
    name: "", // 类别名称
    path: "", // 类别路径
    pid: "", //父级分类id
    thumbnail: "", //缩略图
    status: 0, //状态
    note: "", //备注
    orderNum: 1, //顺位
}

const EditModal = (props) => {
    const { row, visible, onCancel, onLoadData, mode } = props
    const [form] = Form.useForm()
    const [treeData, setTreeData] = useState(null)
    const [editInfo, setEditInfo] = useState({})
    const [previewThumbnailImage, setpreviewThumbnailImage] = useState("") //预览展示的图片
    const [ilImageThumbnailList, setIlImageThumbnailList] = useState([]) //缩略图list

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    useEffect(() => {
        if (visible) {
            loadParentSelectData()
            if (row && mode === "edit") {
                //初始化
                if (row.thumbnail) {
                    setpreviewThumbnailImage(row.thumbnail)
                    setIlImageThumbnailList([
                        {
                            url: `${fileLookUrl().image}${row.thumbnail}`,
                        },
                    ])
                }
                form.setFieldsValue({
                    ...row,
                    ilImageThumbnail: row.thumbnail
                        ? [
                              {
                                  url: `${fileLookUrl().image}${row.thumbnail}`,
                              },
                          ]
                        : [],
                })
                setEditInfo(row)
            } else {
                form.setFieldsValue(defaultData)
                setEditInfo(defaultData)
            }
        } else {
            setpreviewThumbnailImage("")
            setIlImageThumbnailList([])
            setEditInfo({})
            setTreeData()
            form.resetFields()
        }
    }, [visible])

    //过滤后端传来的动态路由 重新生成规范路由
    function mapTree(resourceArr) {
        return resourceArr.map(item => {
            let { id, pid, name, children } = item;
            const haveChildren = children && children.length > 0;
            return {
                id: id,
                pId: pid,
                value: id,
                title: name,
                children: haveChildren ? mapTree(children) : []
            };
        })
    }
    // 获取技术分类
    const loadParentSelectData = () => {
        // tdmsCategoryRootlist().then((res) => {
        //     let list = res.retData
        //     if (list) {
        //         let result = list.map((i) => {
        //             return {
        //                 id: i.id,
        //                 pId: i.pid,
        //                 value: i.id,
        //                 title: i.name,
        //             }
        //         })
        //         setTreeData(result)
        //     }
        // })
        tdmsCategorySearchTree().then((res) => {
            let list = res.retData
            if (list) {
                setTreeData(mapTree(list))
            }
        })
    }

    const onCustomerSubmit = () => {
        form.validateFields()
            .then((fieldsValue) => {
                let data = {
                    ...fieldsValue,
                    pid: fieldsValue.pid ? fieldsValue.pid : null,
                    thumbnail: previewThumbnailImage,
                }
                console.log("fieldsValue:", data)
                //修改操作
                if (mode === "edit") {
                    tdmsCategoryUpdate({ ...data, id: row.id }).then((res) => {
                        onLoadData()
                        form.resetFields()
                        onCancel(false)
                        message.success("修改成功")
                    })
                    return
                } else {
                    tdmsCategoryInsert({ ...data }).then((res) => {
                        onLoadData()
                        form.resetFields()
                        onCancel(false)
                        message.success("新增成功")
                    })
                }
            })
            .catch((err) => {
                message.error(err.retMsg)
            })
    }
    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    // 图片上传
    const handleImgChange = async (info, type) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setIlImageThumbnailList(info.fileList)
            setpreviewThumbnailImage(md5)
        } else if (info.file.status === "removed") {
            setIlImageThumbnailList([])
            setpreviewThumbnailImage("")
        }
    }
    //行驶证图片查看
    const handlePreview = async (file) => {
        Modal.info({
            title: `图片查看`,
            content: <img alt="" style={{ width: "100%" }} src={file.url || file.thumbUrl} />,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            icon: <></>,
        })
    }

    return (
        <Modal visible={visible} title={mode === "add" ? "添加分类" : "分类详情"} onOk={() => onCustomerSubmit()} onCancel={() => onCancel(false)} width={1260}>
            <div>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="分类名称"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "分类名称是必填项",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入菜单名称" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="父菜单" name="pid" initialValue={null}>
                                <TreeSelect
                                    style={{ width: "100%" }}
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: "auto",
                                    }}
                                    // disabled={mode !== "add"}
                                    treeData={treeData}
                                    placeholder="Please select"
                                    treeDefaultExpandAll
                                    treeDataSimpleMode
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="排序"
                                name="orderNum"
                                initialValue={1}
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入排序",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入排序" />
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            <Form.Item label="是否为外链" name="isFrame" valuePropName="checked">
                                <Switch checkedChildren="外链" unCheckedChildren="内部链接" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="菜单类型" name="type" required initialValue={menuTypeEnumArray[0].val}>
                                <Select
                                    placeholder="请选择菜单类型"
                                    style={{ width: "100%" }}
                                    onClick={(e) => {
                                        console.log(e)
                                    }}
                                >
                                    {menuTypeEnumArray.map((item) => (
                                        <Select.Option value={item.val} key={item.val}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={12}>
                            <Form.Item
                                label="分类状态"
                                name="status"
                                initialValue={0}
                                rules={[
                                    {
                                        required: true,
                                        message: "请选择分类状态",
                                    },
                                ]}
                            >
                                <Select placeholder="请选择分类状态" style={{ width: "100%" }}>
                                    <Select.Option value={0}>正常</Select.Option>
                                    <Select.Option value={1}>禁用</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="缩略图"
                                name="ilImageThumbnail"
                                valuePropName="fileList"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: "请上传图片",
                                //     },
                                // ]}
                                getValueFromEvent={normFile}
                            >
                                <Upload
                                    disabled={isLook(mode)}
                                    accept="image/*"
                                    headers={{ Authorization: getToken() }}
                                    action={`${fileUploadUrl}upload/image_old`}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={ilImageThumbnailList}
                                    onPreview={handlePreview}
                                    onChange={(info) => {
                                        handleImgChange(info)
                                    }}
                                >
                                    {ilImageThumbnailList.length >= 1 ? null : <Button>上传图片</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
export default EditModal
