import React, { useEffect, useState } from "react"
import { Button, Form, Input, Col, Row, message, Select, Spin, Switch } from "antd"
import { insertFleet, getFleetOne, editCustomer } from "@/service/fleetService"
import { getCustomerListNew } from "@/service/customerService"
import debounce from "lodash/debounce"

const { Option } = Select
const MotorcadeDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [customerInfo, setCustomerInfo] = useState({
        //客户列表
        data: [],
        fetching: false,
    })

    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            getFleetOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                    let results = res.retData
                    form.setFieldsValue({
                        ...results,
                        motorcadeStatus: results.motorcadeStatus == 0 ? true : false,
                    })
                    setCustomerInfo({
                        data: [
                            {
                                text: rowInfo.customerName,
                                value: results.customerId,
                            },
                        ],
                        fetching: false,
                    }) //客户列表回显
                // }
            })
        } else {
            form.setFieldsValue({
                motorcadeStatus: true,
            })
        }
    }
    //客户列表查询
    const customerQuery = (value) => {
        setCustomerInfo({
            data: [],
            fetching: true,
        })
        getCustomerListNew({
            pageSize: 15,
            currentPage: 1,
            filterCustomer: 1,
            // customerName: value,
            searchItem:{customerName:value}
        }).then((res) => {
            // if (res && res.retCode === 200) {
                let results = res.retData.list
                const data = results.reduce((arr, item) => {
                    if (item.customerStatus === 0) {
                        arr.push({
                            text: item.customerName,
                            value: item.id,
                        })
                    }
                    return arr
                }, [])
                setCustomerInfo({
                    data: data,
                    fetching: false,
                })
            // }
        })
    }
    //防抖
    const customerQuerys = debounce(customerQuery, 1000)

    //表单提交
    const onFleetSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                userId: "",
                motorcadeStatus: values.motorcadeStatus ? 0 : 1,
            }
            if (mode === "edit") {
                editCustomer(rowInfo.id, data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("编辑成功")
                        // } else {
                        //     message.warning("网络异常")
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //         return
                    //     }
                    // })
            } else {
                insertFleet(data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("新增成功")
                        // } else {
                        //     message.warning("新增失败")
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //         return
                    //     }
                    // })
            }
        })
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "motorcadeRecord",
        })
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">车队详情</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>车队信息</span>
                    </div>
                    <div className="list-but">
                        {!isLook(mode) && (
                            <Button shape="round" className="but-left" onClick={onFleetSubmit}>
                                保存
                            </Button>
                        )}
                        <Button shape="round" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                        {mode !== "insert" && (
                            <Button
                                shape="round"
                                size="small"
                                className="but-right"
                                onClick={() =>
                                    dispatch({
                                        type: "changeTabs",
                                        activeMenu: "vehicleRecord",
                                        name: "车辆管理",
                                        vehicleQuery: rowInfo.customerId,
                                    })
                                }
                            >
                                下属车辆
                            </Button>
                        )}
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="客户名称"
                                    name="customerId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择客户",
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={isLook(mode)}
                                        showSearch
                                        allowClear
                                        placeholder="请选择客户"
                                        notFoundContent={customerInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => customerQuery()}
                                        onSearch={customerQuerys}
                                        style={{ width: "100%" }}
                                    >
                                        {customerInfo.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="队长姓名"
                                    name="captainName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入队长姓名",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入队长姓名" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="车队名称"
                                    name="motorcadeName"
                                    rules={[
                                        {
                                            required: true,
                                            message: `请输入车队名称!`,
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入车队名称" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="队长电话"
                                    name="captainPhone"
                                    rules={[
                                        {
                                            required: true,
                                            message: `请输入手机号码!`,
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入队长电话" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="办公电话"
                                    name="captainLandline"
                                    // rules={[{ required: true, message: `请输入办公电话!` }]}
                                >
                                    <Input placeholder="请输入办公电话" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item name="motorcadeStatus" label="车队状态" valuePropName="checked">
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" disabled={isLook(mode)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default MotorcadeDetails
