import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useAllocModel } from "../allocModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useAllocModel();
    const { getSelectedDictOne, getTreeSelectOne } = useDictModel(() => []);

    const searchFields = [
        {
            type: 'Input',
            placeholder: "商品编号",
            fieldName: "materialCode"
        },
        {
            type: 'Input',
            placeholder: "商品名称",
            fieldName: "materialName"
        },
        {
            type: 'Input',
            placeholder: "调拨单号",
            fieldName: "code"
        },
        {
            type: 'Input',
            placeholder: "调出机构",
            fieldName: "outShopName"
        },
        {
            type: 'Input',
            placeholder: "调入机构",
            fieldName: "shopName"
        },
        {
            type: 'Select',
            placeholder: "出库状态",
            fieldName: "stockOutStatus",
            options: getSelectedDictOne(DictCodeEnmu.SALE_STOCK_IO_STATUS),
        },
    ]
    const searchMoreFields = [
        {
            type: 'Select',
            placeholder: "入库状态",
            fieldName: "stockInStatus",
            options: getSelectedDictOne(DictCodeEnmu.PUR_STOCK_IO_STATUS),
        },
    ]
    return (
        <YhPageHeader
            title="调拨管理"
            size="small"
            justify="end"
            searchValues={searchParam}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            triggerSearch
            handleSearch={(val = {}) => loadData({ pageNum: 1 }, { ...searchParam, ...val })}
            handleReset={resetSearchParam}
        />
    );
}