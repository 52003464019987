import React from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { AddGoodsModal } from "./AddGoodsModal";
import { Print } from "./Print";

export const SaleDcDetails = (props) => {

    return (
        <>
            {props && <div className="yh-detail-box">
                <TopButs {...props} />
                <FormBox {...props} />
                <TableBox {...props} />
                <FixedBottom {...props} />
                <AddGoodsModal {...props} />
                <Print />
            </div>
            }
        </>
    )
}