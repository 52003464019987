import React, { useState, useEffect, useRef } from 'react'
import { Button, Table, message, Modal } from 'antd'
import { getVehicleList, deleteVehicleOne, vehicleEditBigBag } from '@/service/customerService'
import VehiclePopupDetail from '@/pages/customerManage/vehicleRecord/vehiclePopupDetail'
import { download, convertRes2Blob } from '@/utils/exportExcel'
import Loading from '@/components/Loading'
import MoveVehicle from '@/pages/customerManage/vehicleRecord/moveVehicle'

const VehicleList = (props) => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState('')
    const { customerInfo, setVehicleNum } = props
    const [userData, setUserData] = useState(null) //列表
    const order = useRef({ column: 'updateTime', rule: 'DESC' })
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    useEffect(() => {
        loadData(1)
    }, [])
    //table配置

    const [customerData, setCustomerData] = useState({})
    const [vehicleData, setVehicleData] = useState({})

    const [moveVehicleVisible, setMoveVehicleVisible] = useState(false)

    //默认加载
    const loadData = (current) => {
        let data = {
            searchItem: {
                customerId: customerInfo.id,
            },
            pageNum: current,
            pageSize: 10,
            orders: [order.current],
        }
        getVehicleList(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setUserData(res.retData.list)
                setVehicleNum(res.retData.total)
            })
            .catch((err) => {
                setUserData([])
            })
    }
    //分页事件
    // const onTableChange = async (page) => {
    //     await setpagination({
    //         ...pagination,
    //         current: page.current,
    //     })
    //     loadData(page.current)
    // }

    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let orderC = { column: 'updateTime', rule: 'DESC' }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === 'ascend' ? 'ASC' : 'DESC'
            orderC = {
                column: sorter.field,
                rule,
            }
        }
        order.current = orderC
        loadData(page.current)
    }

    const deleteVehicle = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要删除的车辆信息')
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除车辆 - ${selectInfo.rowInfo.vehicleCode}?`,
            okText: '确定',
            cancelText: '取消',
            centered: true,
            onOk() {
                deleteVehicleOne(selectInfo.rowInfo.id).then((res) => {
                    // if (res && res.retCode === 200) {
                    message.success('删除成功')
                    loadData(pagination.current)
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
            },
        })
    }

    const handleUserStatus = (mode) => {
        if (mode !== 'insert') {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning('请选择你要查看或编辑的账户信息')
                return
            }
        }
        setShowVisible(true)
        setShowMode(mode)
    }

    const columns = [
        {
            title: '车辆牌照',
            align: 'center',
            dataIndex: 'vehicleCode',
            sorter: true,
        },
        {
            title: '车辆品牌',
            align: 'center',
            dataIndex: 'brandId',
        },
        {
            title: '车系',
            align: 'center',
            dataIndex: 'vehicleSeriesId',
        },
        {
            title: '车型',
            align: 'center',
            dataIndex: 'vehicleModelId',
        },
        {
            title: '是否大包',
            align: 'center',
            dataIndex: 'bigBagFlag',
            render: (text) => <>{text === 1 ? '是' : '否'}</>,
        },
        {
            title: 'Vin码',
            align: 'center',
            dataIndex: 'vehicleVin',
        },
        {
            title: '发动机号',
            align: 'center',
            dataIndex: 'engineCode',
        },
        {
            title: '云小牛设备编号',
            align: 'center',
            dataIndex: 'yxnEquipmentCode',
        },
        {
            title: '云小牛SN码',
            align: 'center',
            dataIndex: 'yxnSn',
        },
        {
            title: '注册日期',
            align: 'center',
            dataIndex: 'drivingSignDate',
        },
        {
            title: '登记日期',
            align: 'center',
            dataIndex: 'createTime',
            sorter: true,
        },
        {
            title: '是否签约(月结)',
            align: 'center',
            dataIndex: 'signFlag2',
            render: (text) => {
                if (text === 0) {
                    return (text = '否')
                } else {
                    return (text = '是')
                }
            },
        },
        {
            title: '是否签约(大包)',
            align: 'center',
            dataIndex: 'signFlag1',
            render: (text) => {
                if (text === 0) {
                    return (text = '否')
                } else {
                    return (text = '是')
                }
            },
        },
        {
            title: '操作',
            align: 'center',
            render: (text, r) => (
                <span
                    style={{ color: '#2e3aeb', cursor: 'pointer', marginLeft: '10px' }}
                    onClick={() => {
                        setCustomerData({ id: customerInfo.id, name: customerInfo.customerName })
                        setVehicleData([r])
                        setMoveVehicleVisible(true)
                    }}
                >
                    车辆转移
                </span>
            ),
        },
    ]

    const exportVehicle = async () => {
        setExportLoading(true)
        let data = {
            type: 'post',
            data: {
                pageSize: pagination.pageSize,
                pageNum: pagination.current,
                searchItem: {
                    customerId: customerInfo.id,
                },
                orders: [order.current],
            },
        }
        let resFlie = await download('/ci-customer/customer/vehicle/export', data)
        if (resFlie && resFlie.status === 200) {
            message.success('已导出')
            convertRes2Blob(resFlie)
        } else {
            message.warning('导出异常，请重新尝试')
        }
        setExportLoading(false)
    }

    //设为大包
    const setBigFlag = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning('请选择你要设置的车辆')
            return
        }
        console.log(selectInfo.rowInfo)
        vehicleEditBigBag({
            id: selectInfo.rowInfo.id,
            bigBagFlag: 1,
        }).then((res) => {
            loadData(pagination.current)
        })
    }
    return (
        <>
            <div>
                <div className="list-header" style={{ marginTop: '-10px' }}>
                    <div className="list-but">
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus('insert')}>
                            新增
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus('look')}>
                            查看
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus('edit')}>
                            编辑
                        </Button>
                        <Button shape="round" className="but-left" onClick={deleteVehicle}>
                            删除
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => exportVehicle('edit')}>
                            导出
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => setBigFlag('edit')}>
                            设为大包
                        </Button>
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                setCustomerData({ id: customerInfo.id, name: customerInfo.customerName })
                                setMoveVehicleVisible(true)
                            }}
                        >
                            车辆转移
                        </Button>
                    </div>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={userData}
                    // loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${pagination.total}条`,
                        position: ['bottomCenter'],
                        ...pagination,
                    }}
                    onRow={(record, index) => ({
                        onClick: () => setSelectInfo({ rowInfo: record, rowIndex: index }),
                    })}
                    rowClassName={(record, index) => (index === selectInfo.rowIndex ? 'dark-row' : '')}
                />
            </div>
            {showVisible && (
                <VehiclePopupDetail
                    rowInfo={selectInfo.rowInfo}
                    visible={showVisible}
                    mode={showMode}
                    setVisible={setShowVisible}
                    customerData={{ customerId: customerInfo.id, customerName: customerInfo.customerName }}
                    onSuccess={() => {
                        loadData(1)
                        setShowVisible(false)
                    }}
                />
            )}
            {exportLoading && <Loading />}
            <MoveVehicle
                visible={moveVehicleVisible}
                setVisible={setMoveVehicleVisible}
                customerData={customerData}
                vehicleData={vehicleData}
                onSuccess={() => {
                    loadData(pagination.current)
                }}
            />
        </>
    )
}
export default VehicleList
