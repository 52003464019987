import React from "react"
import { useCustomerModel } from "../customerModel"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import useGlobalModel from "@/erp_subpackage/model/globalModel";


export function PageHeader() {
    const { searchParam, organType, loadData, updateSearchParam, resetSearchParam } = useCustomerModel()
    const { getSelectedDictOne } = useDictModel();
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);

    const searchFields = [
        ...(organType ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        {
            type: "Select",
            placeholder: "客户类型",
            fieldName: "typeCode",
            options: getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE),
        },
        {
            type: "Select",
            placeholder: "客户状态",
            fieldName: "enable",
            options: [
                { label: "启用", value: 1 },
                { label: "禁用", value: 0 },
            ],
        },
        {
            type: "Input",
            placeholder: "客户编码",
            fieldName: "code",
        },
        {
            type: "Input",
            placeholder: "客户名称",
            fieldName: "customerName",
        },
        {
            type: "Input",
            placeholder: "联系人",
            fieldName: "contact",
        },
    ]

    const searchMoreFields = [
        {
            type: "Input",
            placeholder: "联系电话",
            fieldName: "phone",
        },
        {
            type: "Datepicker",
            placeholder: "登记日期",
            fieldName: "createTime",
        },
    ]

    return (
        <YhPageHeader
            title="客户管理"
            size="small"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    )
}
