import Storage from "good-storage"
import { TOKEN_KEY, USER_INFO, DATA_DICT, USER_MENU, APP_CODE, USER_BTNS, USER_ORGAN, SHOP_TOKEN, PORTAL_TOKEN_KEY, SHOP_TOKEN_B, BENTITY_LIST } from "../config/app.js"
//获取token
export function getToken() {
    return Storage.session.get(TOKEN_KEY)
}
export function setToken(token) {
    return Storage.session.set(TOKEN_KEY, token)
}
export function removeToken() {
    return Storage.session.remove(TOKEN_KEY)
}
//获取token
export function getPortalToken() {
    return Storage.session.get(PORTAL_TOKEN_KEY)
}
export function setPortalToken(token) {
    return Storage.session.set(PORTAL_TOKEN_KEY, token)
}
export function isLogin() {
    return (getToken() || "").length > 5
}
//获取用户信息
export function getUserInfo() {
    return Storage.session.get(USER_INFO)
}
export function setUserInfo(UserInfo) {
    return Storage.session.set(USER_INFO, UserInfo)
}
export function removeUserInfo() {
    return Storage.session.remove(USER_INFO)
}

//商城用户token
export function getShopToken() {
    return Storage.session.get(SHOP_TOKEN)
}
export function setShopToken(data) {
    return Storage.session.set(SHOP_TOKEN, data)
}
export function removeShopToke() {
    return Storage.session.remove(USER_INFO)
}

//B端商城用户token
export function getShopBToken() {
    return Storage.session.get(SHOP_TOKEN_B)
}
export function setShopBToken(data) {
    return Storage.session.set(SHOP_TOKEN_B, data)
}
export function removeShopBToke() {
    return Storage.session.remove(SHOP_TOKEN_B)
}

//获取所属实体
export function getBentityList() {
    return Storage.session.get(BENTITY_LIST)
}
export function getBentityListOption() {
    let list =  Storage.session.get(BENTITY_LIST)
    return list?.map(i => ({text:i.name,label:i.name,value:i.code}))
}
export function setBentityList(BentityList) {
    return Storage.session.set(BENTITY_LIST, BentityList)
}
export function removeBentityList() {
    return Storage.session.remove(BENTITY_LIST)
}


/**
 * 初始化数据字典
 * @param {*} dict
 */
export function setDataDict(dict) {
    Storage.set(DATA_DICT, dict)
    return dict
}
/**
 * 查询数据字典
 * @param {*} key
 */
// 根据code 返回这一项
function forFind(array, pcode) {
    for (const item of array) {
        if (item.dictCode === pcode) {
            return item
        }
        if (item.children && item.children.length > 0) {
            let forFindRet = forFind(item.children, pcode)
            if (forFindRet) {
                return forFindRet
            }
        }
    }
}
export function getPcodeDict(pcode = null) {
    let dataDict = Storage.get(DATA_DICT) ?? []
    if (pcode) {
        let findDict = forFind(dataDict, pcode)
        return findDict ?? { children: [] }
    }
    return dataDict
}

//获取菜单信息
export function setUserMenu(menuArr) {
    return Storage.session.set(USER_MENU, menuArr)
}
export function getUserMenuAll() {
    return Storage.session.get(USER_MENU)
}
export function getUserMenu(path) {
    let userMenu = Storage.session.get(USER_MENU)
    return userMenu.find((item) => item.menuUrl === path)?.resourceDetails
}
export function removeUserMenu() {
    return Storage.session.remove(USER_MENU)
}
//用户的按钮
export function setUserBtn(menuArr) {
    menuArr = JSON.stringify(menuArr)
    return Storage.session.set(USER_BTNS, menuArr)
}
export function getUserBtn(path) {
    let userBtns = Storage.session.get(USER_BTNS)
    userBtns = JSON.parse(userBtns)
    return userBtns.find((item) => item.path === path)
}
export function removeUserBtn() {
    return Storage.session.remove(USER_BTNS)
}

//应用的code码。
export function setAppCode(code) {
    return Storage.session.set(APP_CODE, code)
}
export function getAppCode() {
    return Storage.session.get(APP_CODE)
}
export function rmAppCode() {
    return Storage.session.remove(APP_CODE)
}

//应用的code码。
export function setUserOrgan(organ) {
    return Storage.session.set(USER_ORGAN, organ)
}
export function getUserOrgan() {
    return Storage.session.get(USER_ORGAN)
}
export function rmUserOrgan() {
    return Storage.session.remove(USER_ORGAN)
}


//公共方法
export function setStorage(key, val) {
    return Storage.session.set(key, val)
}
export function getStorage(key) {
    return Storage.session.get(key)
}

export function removeStorage(key) {
    return Storage.session.remove(key)
}