import React, { useState } from "react"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useSaleSoModel } from "../saleSoModel"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
export function PageHeader() {
    const { searchParam, initPgCfg, organTypeCode, updateSearchParam, resetSearchParam, loadData } = useSaleSoModel()
    const { getSelectedDictOne } = useDictModel(() => [])
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);
    const searchFields = [
        ...(organTypeCode === 3 ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs,
            xxl: 3,
        }] : []),
        {
            type: "Input",
            placeholder: "销售单号",
            fieldName: "saleCode",
            xxl: 3,
        },
        {
            type: "Input",
            placeholder: "客户名称",
            fieldName: "customerName",
            xxl: 3,
        },
        {
            type: "Select",
            placeholder: "订单来源",
            fieldName: "orderSourceCode",
            options: getSelectedDictOne(DictCodeEnmu.ORDER_SOURCE),
            xxl: 3,
        },
        {
            type: "Select",
            placeholder: "单据状态",
            fieldName: "status",
            mode: "multiple",
            options: getSelectedDictOne(DictCodeEnmu.SALE, ["ci", "vrm", "main"]),
            xxl: 3,
        },
        {
            type: "Input",
            placeholder: "车牌号",
            fieldName: "licensePlate",
            xxl: 3,
        },
        {
            type: "Select",
            placeholder: "退货状态",
            fieldName: "returnStatus",
            options: [{label:'部分退货',value:'sale.part-io'},{label:'全部退货',value:'sale.whole-io'}],
            xxl: 3,
        },
    ]
    const searchMoreFields = [
        {
            type: "Input",
            placeholder: "调出单号",
            fieldName: "allocationCode"
        },
        {
            type: "Input",
            placeholder: "调拨单号",
            fieldName: "transferOrder"
        },
        {
            type: "Input",
            placeholder: "VIN码",
            fieldName: "vin",
        },
        {
            type: "Input",
            placeholder: "车辆型号",
            fieldName: "vehicleModel",
        },
        {
            type: "Select",
            placeholder: "车辆类型",
            fieldName: "vehicleTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
        },
        {
            type: "Select",
            placeholder: "结算方",
            fieldName: "settlementPartyCode",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
        },
        {
            type: "Select",
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
        },
        {
            type: "Select",
            placeholder: "单据类型",
            fieldName: "orderTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE),
        },
        {
            type: "Select",
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS),
        },
        {
            type: "Input",
            placeholder: "制单人",
            fieldName: "createUser",
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["结算日期起始", "结算日期结束"],
            rangePickerFieldName: ["settlementStartTime", "settlementEndTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
        {
            type: "RangePicker",
            fieldName: "",
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]

    return (
        <YhPageHeader
            title="销售单"
            size="small"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => {
                loadData(initPgCfg, { ...searchParam, ...params })
            }}
            handleReset={resetSearchParam}
        />
    )
}
