import React from "react";
import { SearchInput } from "./SearchInput";
import { SearchDatepicker } from "./SearchDatepicker";
import { SearchRangePicker } from "./SearchRangePicker";
import { SearchSelect } from "./SearchSelect";
import { SearchCheckBox } from "./SearchCheckBox";
import { SearchTreeSelect } from "./SearchTreeSelect";
import { SearchRadio } from "./SearchRadio";
import { Col } from "antd";

export const SearchItem = (props) => {

    return <Col span={props.span ?? 8} xxl={props.xxl ?? 4} key={props.fieldName} className="ant-col-h-auto">
        {
            (() => {
                switch (props.type) {
                    case "Input":
                        return <SearchInput {...props} />;
                    case "Datepicker":
                        return <SearchDatepicker {...props} />;
                    case "RangePicker":
                        return <SearchRangePicker {...props} />;
                    case "Select":
                        return <SearchSelect {...props} />;
                    case "CheckBox":
                        return <SearchCheckBox {...props} />;
                    case "TreeSelect":
                        return <SearchTreeSelect {...props} />;
                    case "Radio":
                        return <SearchRadio {...props} />;
                    default:
                        return <></>;
                }
            })()
        }
    </Col>
}