import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getMaintenancePackageList, deleteMaintenancePackageOne, copyMaintenancePackageOne } from "@/service/setMealService"
import { MyContext } from "@/store/myContext"
import { Modal } from "antd"

//套餐模块
export default () => {
    const { dispatch, state, message, detailsPage } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "setMealManage") {
            loadData(pagination.current)
        }
        return () => setList([])
    }, [state.activeMenu])

    //列表数据
    const loadData = (current) => {
        let data = {
            searchItem: {},
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getMaintenancePackageList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setList(res.retData.list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "维修套餐新增",
            edit: "维修套餐编辑",
            module: "模块维护",
            carType: "车型匹配",
        }
        let modeKey = "setMealManage/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的维保套餐")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content:
                mode === "module"
                    ? detailsPage.SetMealModule({ modeKey, dispatch })
                    : mode === "carType"
                    ? detailsPage.SetMealCarType({ modeKey, dispatch })
                    : detailsPage.SetMealDetail({
                          modeKey,
                          dispatch,
                          mode,
                          rowInfo: selectInfo.rowInfo,
                      }),
        })
    }

    //删除
    const handledeleteOne = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的维保套餐")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除维保套餐${selectInfo.rowInfo.packageName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                deleteMaintenancePackageOne(selectInfo.rowInfo.id)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            loadData(1)
                            message.success(`${selectInfo.rowInfo.packageName}删除成功`)
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                    // .catch((err) => message.error(err.retMsg))
            },
        })
    }
    //table配置
    const columns = [
        {
            title: "套餐名称",
            align: "center",
            dataIndex: "packageName",
        },
        {
            title: "套餐说明",
            align: "center",
            dataIndex: "packageExplain",
        },
        {
            title: "套餐价格",
            align: "center",
            dataIndex: "packageActualAmount",
        },
        {
            title: "所属模块",
            align: "center",
            dataIndex: "modelName",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handledeleteOne(),
            content: "删除",
        },
        {
            click: () => handleDetails("module"),
            content: "模块维护",
        },
        {
            click: () => {
                if (!Object.keys(selectInfo.rowInfo).length) {
                    message.warning("请选择你要复制的维修套餐")
                    return
                }
                copyMaintenancePackageOne(selectInfo.rowInfo.id)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            loadData(1)
                            message.success(`${selectInfo.rowInfo.packageName}复制成功`)
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                    // .catch((err) => message.error(err.retMsg))
            },
            content: "复制",
        },
        {
            click: () => handleDetails("carType"),
            content: "车型匹配",
        },
    ]
    return (
        <>
            {state.activeMenu === "setMealManage" && (
                <>
                    <TopSearch title="维修套餐" />
                    <ListComponent
                        title="维修套餐列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
