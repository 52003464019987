import React from "react";
import { Col } from "antd";
import { __FormInput as FormInput } from "./FormInput";
import { __FormDatePicker as FormDatePicker } from "./FormDatePicker";
import { __FormSwitch as FormSwitch } from "./FormSwitch";
import { __FormSelect as FormSelect } from "./FormSelect";
import { __FormCheckbox as FormCheckbox } from "./FormCheckbox";
import { __FormFileImage as FormFileImage } from "./FormFileImage";
import { __FormButton as FormButton } from "./FormButton";
import { __FormTreeSelect as FormTreeSelect } from "./FormTreeSelect";
import { __FormAsyncSelect as FormAsyncSelect } from "./FormAsyncSelect";
import { __FormRadio as FormRadio } from "./FormRadio";
import { __FormUpload as FormUpload } from "./FormUpload";
import { __FormUploads as FormUploads } from "./FormUploads";
import {__FormInputNumber as FormInputNumber} from './FormNumber'


export const __FormItem = (props) => {

    return <Col span={props.span ?? 6} key={props.fieldName} className={props.colClassName} hidden={props.hidden}>
        {
            (() => {
                switch (props.type) {
                    case "Input":
                        return <FormInput {...props} />;
                    case "Datepicker":
                        return <FormDatePicker {...props} />;
                    case "Switch":
                        return <FormSwitch {...props} />;
                    case "Select":
                        return <FormSelect {...props} />;
                    case "Checkbox":
                        return <FormCheckbox {...props} />;
                    case "Radio":
                        return <FormRadio {...props} />;
                    case "FileImage":
                        return <FormFileImage {...props} />;
                    case "Button":
                        return <FormButton {...props} />;
                    case "TreeSelect":
                        return <FormTreeSelect {...props} />;
                    case "AsyncSelect":
                        return <FormAsyncSelect {...props} />;
                    case "FormUpload":
                            return <FormUpload {...props} />;
                    case "FormUploads":
                        return <FormUploads {...props} />;
                    case "InputNumber":
                        return <FormInputNumber {...props} />;
                    case "Other":
                            return props.formItem
                    default:
                        return <></>;
                }
            })()
        }
    </Col>
}