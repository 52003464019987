import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";

//调拨管理
export default () => {
    return (
        <>
            <PageHeader />
            <DataBox />
        </>
    )
}