import React from "react";
import { Button } from "antd";
import { DataTable } from "./DataTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { usePsiModel } from "../psiModel";

export const DataBox = () => {
    const { exportPsi, descTotal } = usePsiModel();

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                exportPsi()
            }}
        >
            导出
        </Button>
    </>;
    const desc = [

        {
            label: "库存差异数量",
            value: descTotal.differenceNumTotal,
            color: descTotal.differenceNumTotal != 0 ? "green" : "red",
            animation: descTotal.differenceNumTotal != 0
        },
        {
            label: "占用差异数量",
            value: descTotal.occupyDifferenceTotal,
            color: descTotal.occupyDifferenceTotal != 0 ? "green" : "red",
            animation: descTotal.occupyDifferenceTotal != 0
        },
        {
            label: "库存数量",
            value: descTotal.currentNumTotal ?? 0,
            color: "red"
        },
    ]
    return (
        <YhBox title={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}