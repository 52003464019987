import React, { useState, useEffect } from "react"
import { MyContext } from "@/store/myContext"
import { maintainOrderReturnOne, maintainOrderReturnReview, maintainOrderReturnConfirm } from "@/service/ordersService"
import { MaintainOrdeReturnStatus } from "@/utils/enum"
import { Button, Modal, message, Table, Input } from "antd"
import { OperatorUserType } from "@/utils/enum.js"
export default ({ visible, setVisible, rowInfo }) => {
    const [list, setList] = useState([]) //列表

    useEffect(() => {
        if (visible) {
            loadData()
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = () => {
        maintainOrderReturnOne(rowInfo.id).then((res) => {
            setList(res.retData.returnList)
        })
    }

    //table配置
    const columns = [
        {
            title: "商品编号",
            align: "center",
            dataIndex: "materialCode",
        },
        {
            title: "商品名称",
            align: "center",
            dataIndex: "materialName",
        },
        {
            title: "退回量",
            align: "center",
            dataIndex: "returnNum",
        },
        {
            title: "申请人",
            align: "center",
            dataIndex: "applicantUserName",
            render: (text, record) => {
                return text && `${OperatorUserType[record.applicantUserType]}--${text}`
            },
        },
        {
            title: "申请时间",
            align: "center",
            dataIndex: "applicantTime",
        },
        {
            title: "审核人",
            align: "center",
            dataIndex: "reviewerUserName",
            render: (text, record) => {
                return text && `${OperatorUserType[record.reviewerUserType]}--${text}`
            },
        },        {
            title: "审核时间",
            align: "center",
            dataIndex: "reviewerTime",
        },
        {
            title: "入库人",
            align: "center",
            dataIndex: "confirmUserName",
            render: (text, record) => {
                return text && `${OperatorUserType[record.confirmUserType]}--${text}`
            },
        },
        {
            title: "入库时间 ",
            align: "center",
            dataIndex: "confirmTime",
        },
        {
            title: "状态",
            align: "center",
            dataIndex: "status",
            render: (text, record) => {
                if (text === "reject") {
                    return `驳回:${record.rejectInfo}`
                }
                return MaintainOrdeReturnStatus[text]
            },
        },
        {
            title: "操作",
            align: "center",
            width: "240px",
            dataIndex: "id",
            render: (text, record) => {
                if (record.status === "tobe_review" && record.applicantUserType !== 1) {
                    return (
                        <>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    handleReview(text, record, true)
                                }}
                                style={{ margin: "0 10px" }}
                            >
                                通过
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                danger
                                onClick={() => {
                                    handleReview(text, record, false)
                                }}
                                style={{ margin: "0 10px" }}
                            >
                                驳回
                            </Button>
                        </>
                    )
                } else if (record.status === "pending_storage") {
                    return (
                        <Button
                            type="primary"
                            size="small"
                            style={{ margin: "0 10px" }}
                            onClick={() => {
                                handleConfirmReTurn(text, record)
                            }}
                        >
                            确认接收
                        </Button>
                    )
                }
            },
        },
    ]

    const handleReview = (id, record, passed) => {
        let rejectInfo = ""
        Modal.confirm({
            title: `${record.materialName}`,
            content: (
                <>
                    {passed && <span>审核通过</span>}
                    {!passed && (
                        <Input
                            placeholder="请输入驳回原因"
                            onChange={(e) => {
                                rejectInfo = e.target.value
                            }}
                        />
                    )}
                </>
            ),
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                if (!passed && !rejectInfo) {
                    return message.error("请输入驳回原因")
                }
                maintainOrderReturnReview({ returnId:id, passed, rejectInfo }).then((res) => {
                    message.success(`已${passed ? "通过" : "驳回"}`)
                    loadData()
                })
            },
        })
    }

    //确定退料
    const handleConfirmReTurn = (id, record) => {
        Modal.confirm({
            content: `确定入库--${record.materialName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                maintainOrderReturnConfirm(id).then((res) => {
                    message.success("入库成功")
                    loadData()
                })
            },
        })
    }

    return (
        <>
            <Modal width={1200} visible={visible} title="详情" onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
                <Table size="small" rowKey={(record) => record.id} columns={columns} dataSource={list} pagination={false} scroll={{ x: 1100, y: 660 }} />
            </Modal>
        </>
    )
}
