import React, { useEffect, useContext, useState, useMemo } from "react";
import { Button, Tree, message, Input } from "antd";
import { FormBox } from "./FormBox";
import { useDictModel } from "../dictModel";
import { MyContext } from "@/store/myContext";
const { Search } = Input;

export const DataBox = () => {
    const { treeSource, loadData, setMode, setSelectedNodes, selectedNodes, setFormVal, sync, originDictList } = useDictModel();
    const { state } = useContext(MyContext);

    useEffect(() => {
        if (state.activeMenu === "baseDict") {
            loadData();
        }
    }, [state])


    //字典选中事件
    const onSelect = (selectedKeys, info) => {
        let selectNode = info.selectedNodes;
        setMode(info.selected ? "edit" : "");
        setSelectedNodes(selectNode);
        setFormVal({ ...selectNode[0], status: selectNode[0]?.status === 0 });
    };


    const action = <>
        <Button
            className="dict-but-left"
            type="primary"
            shape="round"
            size="small"
            onClick={() => {
                if (selectedNodes.length <= 0) {
                    message.warning("请选择要增加的父级");
                    return;
                }
                message.success("增加下级模式开启");
                setMode("nextInsert");
            }}
        >增加下级</Button>
        <Button
            className="dict-but-left"
            type="primary"
            shape="round"
            size="small"
            onClick={() => {
                if (selectedNodes.length <= 0) {
                    message.warning("请选择要增加的同级");
                    return;
                }
                message.success("增加同级模式开启");
                setMode("add");
            }}
        >增加同级</Button>
        <Button
            className="dict-but-left"
            type="primary"
            shape="round"
            size="small"
            onClick={() => sync()}
        >同步</Button>
    </>;


    ///////////////////
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [searchValue, setSearchValue] = useState('');

    const getParentKey = (key, tree) => {
        let parentKey;

        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];

            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }

        return parentKey;
    };

    const onChange = (e) => {
        const { value } = e.target;
        const newExpandedKeys = []
        originDictList.forEach(item => {
            if (item.name.indexOf(value) > -1) {
                newExpandedKeys.push(item.key)
            }
        })
        console.log(newExpandedKeys)
        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true);
    };

    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };


    const treeData = useMemo(() => {
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.name || '';
                const index = strTitle.indexOf(searchValue);
                const beforeStr = strTitle.substring(0, index);
                const afterStr = strTitle.slice(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className="site-tree-search-value">{searchValue}</span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{strTitle}</span>
                    );

                if (item.children) {
                    return {
                        ...item,
                        title,
                        children: loop(item.children),
                    };
                }

                return {
                    ...item,
                    title,
                    key: item.key,
                };
            });

        return loop(treeSource);
    }, [searchValue]);

    return (
        <>
            {state.activeMenu === "baseDict" && <div className="base-dict-container">
                <div className="dict-list-box">
                    <div className="dict-list-header">
                        <div className="dict-list-name">
                            <span>字典列表</span>
                        </div>
                        <div className="dict-list-but">
                            {action}
                        </div>
                    </div>
                    <div className="dict-table-box">
                        {/* <Search
                            style={{
                                marginBottom: 8,
                            }}
                            placeholder="关键字"
                            onChange={onChange}
                        /> */}
                        <Tree
                            selectedKeys={[selectedNodes[0]?.key]}
                            titleRender={(node) => `${node.name}`}
                            onSelect={onSelect}
                            treeData={treeSource}
                            /////////
                            // treeData={treeData}
                            // onExpand={onExpand}
                            // expandedKeys={expandedKeys}
                            // autoExpandParent={autoExpandParent}
                        />
                    </div>
                </div>
                <div className="dict-from-box">
                    {selectedNodes.length > 0 ?
                        <FormBox />
                        :
                        <div className="dict-init-box"><span>数据字典</span></div>
                    }
                </div>
            </div>}
        </>
    )
}