import { useState, useEffect } from "react";
import { createModel } from "hox";
import { productService } from "./productService";
import { message } from "antd"
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { useDictModel } from "../../base/dict/dictModel";
import { useLockFn } from "ahooks";
import { shopCatsList, shopCatsDatas } from "@/service/shop"

function listChangeTree(list) {
    let menuObj = {}
    list.forEach(item => {
        item.children = []
        menuObj[item.value] = item
    })
    console.log(menuObj)
    return list.filter(item => {
        if (item.parent_id !== null && item.parent_id !== "") {
            menuObj[item.parent_id] && menuObj[item.parent_id].children.push(item)
            return false
        }
        return true
    })
}

export const useProductModel = createModel(function () {
    const { user:{shopId,shopCode,shopName,userName}} = useGlobalModel()
    const { getTreeNodeName } = useDictModel();
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false)
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // 选中行的值
    const [selectedRow, setSelectedRow] = useState({})

    // 明细列表
    const [detailsData, setDetailsData] = useState([]);
    // 明细弹窗
    const [detailModal, setDetailModal] = useState(false)
    const [searchDetailData, setSearchDetailData] = useState({})
    // 明细分页
    const [detailPgCfg, setDetailPgCfg] = useState(initPgCfg);
    // 明细查询条件
    const [detailSearchParam, setDetailSearchParam] = useState({})

    // 选中上架商品列表
    const [upDataList, setUpDataList] = useState([])
    const [upSelectedRowKeys, setUpSelectedRowKeys] = useState([]);
    // 选中行的值
    const [upSelectedRow, setUpSelectedRow] = useState({})
    // 所属机构
    const [upOrgDs, setUpOrgDs] = useState([]);

    // 商品分类
    const [shopCats, setShopCats] = useState([]);
    const initDesc = {
        availableStock: 0,  // 可用库存
    }
    // 可用库存统计
    const [stockDesc, setStockDesc] = useState(initDesc)

    useEffect(() => {
        let newDesc = { ...initDesc };
        detailsData && detailsData.forEach(item => {
            newDesc.availableStock += Number(item.availableStock);
        });
        setStockDesc(newDesc);
    }, [detailsData])

    //所有列表查询
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.id === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData)
    }, [selectedRowKeys])
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await productService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource( records );
        setLoading(false);
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({
            ...searchParam,
            ...updateParam
        })
    }
    //重置搜索参数
    const resetSearchParam = async () => {
        await setSearchParam({});
        await setSelectedRowKeys([]);
        await loadData(initPgCfg, {});
    }

    //设置搜索value(明细)
    const updateDetailSearchParam = (updateParam) => {
        setDetailSearchParam({
            ...detailSearchParam,
            ...updateParam
        })
    }
    //重置搜索参数(明细)
    const resetDetailSearchParam = async (code) => {
        await setDetailSearchParam({});
        await getProductDetail(initPgCfg, {code});
    }

    // 上架
    const productUpDown = useLockFn(async (params) => {
        let list = upDataList.map((item) =>{
            return {
                ...item,
                typeCode:'',
                upStatus:params.upStatus,
                reason:params.reason,
                typeCodeList:item.typeCodeList.length > 0 ? item.typeCodeList : item.typeCode
            }
        })
        let {retCode, retData } = await productService.upDown(list)
        if(retCode === 200){
            message.success(retData)
        }else if (retCode === 500){
            message.error(retData)
        }
        return retCode
    })

    // 下架
    const productDown = useLockFn(async (downList) => {
        let { retCode, retData } = await productService.upDown(downList)
        if(retCode === 200){
            loadData(initPgCfg, {})
            message.success(retData)
        }else if(retCode === 500){
            message.error(retData)
        }
        return retCode
    })

    // 查看明细
    const getProductDetail =async (page, searchValue = detailSearchParam)=>{
        const searchParams = {
            ...searchValue,
            pageSize: page?.pageSize ?? detailPgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? detailPgCfg.current ?? initPgCfg.current,
        }
        let {retData} = await productService.getDetails(searchParams)
        let { records, ...pg } = retData
        setDetailsData(records)
        setDetailPgCfg(transPgToPagination(pg));
    }

    // 关闭弹窗
    const resetDetailModal =async () => {
        setSearchDetailData({})
    }

    // 批量上架
    const productBatcnUp = useLockFn(async (status) => {
        let params = {
            status,
            id: selectedRowKeys.join(),
        }
        let { retData } = await productService.batchUp(params)
        retData ? loadData() && message.success("批量上架成功") : message.error("批量上架失败");
        return retData
    })

    // 获取所属机构
    const getOrg =async () =>{
        let {retData} = await productService.getOrg()
        let newData = retData.map((item) =>{
            return {
                label:item,
                value:item
            }
        })
        setUpOrgDs(newData)
    }

     // 获取商城分类
     const getShopCatsList = async (id) => {
        shopCatsDatas(id).then(res =>{
            let result = JSON.parse(JSON.stringify(res).replace(/category_id/g, "value"))
            let result1 = JSON.parse(JSON.stringify(result).replace(/name/g, "title"));
            setShopCats(result1)
            // let catsData = [...shopCats, ...result1]
            // console.log('子集数据',catsData);
            // setShopCats(listChangeTree(catsData))
        })
    }

     //根据code 返回对应的node节点名称
    const getTreeName = (list,id) =>{
       let res = ''
       list.forEach(item => {
           if(item.value === id){
               res = item.name
           }
       })
        return res
     }

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        selectedRow,
        pgCfg,
        loading,
        detailsData,
        detailModal,
        searchDetailData,
        detailPgCfg,
        upDataList,
        upSelectedRowKeys,
        upSelectedRow,
        upOrgDs,
        detailSearchParam,
        shopCats,
        stockDesc,
        /***method***/
        setLoading,
        setPgCfg,
        setDataSource,
        setDetailsData,
        setSelectedRowKeys,
        setSelectedRow,
        resetSearchParam,
        resetDetailSearchParam,
        updateSearchParam,
        updateDetailSearchParam,
        loadData,
        productUpDown,
        getProductDetail,
        productBatcnUp,
        setDetailModal,
        resetDetailModal,
        setSearchDetailData,
        setDetailPgCfg,
        setUpDataList,
        setUpSelectedRowKeys,
        setUpSelectedRow,
        getOrg,
        setUpOrgDs,
        setDetailSearchParam,
        productDown,
        getShopCatsList,
        setShopCats,
        getTreeName
    }
});