import React from "react";
import { Form } from "antd";

export const __FormButton = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            rules={props.rules}
            labelCol={props.labelCol}
            hidden={props.hidden}
            colon={false}
        >
        </Form.Item>
    )
}