import React, { useEffect } from "react";
import { Table, Button, Tooltip } from "antd";
import { useJxcModel } from "../jxcModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { useGlobalModel, transPgToPagination } from "@/erp_subpackage/model/globalModel";

export function DataTable() {
    const {
        dataSource, selectedRow, pgCfg, loading,
        loadData, setSelectedRow, exportJxc
    } = useJxcModel();
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    useEffect(() => {
        loadData();
    }, [])

    const columns = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            fixed: "left",
            width: 180,
            dataIndex: 'materialCode',
            // render: (text) => <YhTooltip text={text} />,
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            fixed: "left",
            width: 160,
            dataIndex: 'materialName',
            render: (text) => <YhTooltip text={text} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '商品类型',
            width: 120,
            dataIndex: 'typeName',
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
            render: (text) => <YhTooltip text={text} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '最新进价',
            width: 120,
            dataIndex: 'purchasePrice',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '库存数量',
            width: 120,
            dataIndex: 'inventory',
            align: "center",
        }, {
            title: '库存成本',
            width: 120,
            dataIndex: 'inventoryAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '入库数量',
            width: 120,
            dataIndex: 'putStorageNum',
            align: "center",
        }, {
            title: '入库金额',
            width: 120,
            dataIndex: 'putStorageAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '出库数量',
            width: 120,
            dataIndex: 'outStorageNum',
            align: "center",
        }, {
            title: '出库金额',
            width: 120,
            dataIndex: 'outStorageAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '采购数量',
            width: 120,
            dataIndex: 'pTj',
            align: "center",
        }, {
            title: '采购金额',
            width: 120,
            dataIndex: 'purchaseAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '采退数量',
            width: 120,
            dataIndex: 'ctJ',
            align: "center",
        }, {
            title: '采退金额',
            width: 120,
            dataIndex: 'preturnAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销售数量（含赠品）',
            width: 140,
            dataIndex: 'sTj',
            align: "center",
        }, {
            title: '销售金额',
            width: 120,
            dataIndex: 'saleAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销售成本',
            width: 120,
            dataIndex: 'saleCastAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销售毛利',
            width: 120,
            dataIndex: 'saleGrossAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销退数量（含赠品）',
            width: 150,
            dataIndex: 'xtJ',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销退金额',
            width: 120,
            dataIndex: 'sreturnAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销退成本',
            width: 120,
            dataIndex: 'saleReturnCastAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销退毛利',
            width: 120,
            dataIndex: 'sreturnGrossAmount',
            align: "center",
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '盘盈数量',
            width: 120,
            dataIndex: 'differenceNum',
            align: "center",
            render: (text, r) => r.result === '盘盈' ? text : ""
        }, {
            title: '盘盈金额',
            width: 120,
            dataIndex: 'differenceAmount',
            align: "center",
            render: (text, r) => r.result === '盘盈' ? text ? '￥' + text : '￥0' : ""
        }, {
            title: '盘亏数量',
            width: 120,
            dataIndex: 'differenceNum',
            align: "center",
            render: (text, r) => r.result === '盘亏' ? text : ""
        }, {
            title: '盘亏金额',
            width: 120,
            dataIndex: 'differenceAmount',
            align: "center",
            render: (text, r) => r.result === '盘亏' ? text ? '￥' + text : '￥0' : ""
        }, {
            title: '仓库',
            width: 120,
            dataIndex: 'warehouseName',
        }, {
            title: '货位',
            width: 120,
            dataIndex: 'shelfCode',
            // }, {
            //     title: '季销存率*',
            //     width: 120,
            //     dataIndex: '',
            //     align: "center",
            // }, {
            //     title: '年销存率*',
            //     width: 120,
            //     dataIndex: '',
            //     align: "center",
            // }, {
            //     title: '积压率*',
            //     width: 120,
            //     dataIndex: '',
            //     align: "center",
            // }, {
            //     title: '年周转率*',
            //     width: 120,
            //     dataIndex: '',
            //     align: "center",
        }
    ];

    //点击行选高亮
    const onRow = (record) => {
        let findObj = selectedRow.find(item => item.key === record.key)
        if (findObj) {
            let newSelectedRowKeys = selectedRow.filter(item => item.key !== record.key);
            setSelectedRow(newSelectedRowKeys);
        } else {
            setSelectedRow(origin => [...origin, record]);
        }
    };

    return (
        <>
            <Button
                className="btn-item"
                style={{ margin: "0px 0px 5px 15px" }}
                type="link"
                shape="round"
                onClick={() => exportJxc()}
            >
                导出列表
            </Button>
            <Table
                loading={loading}
                rowSelection={{
                    //全部选中时 (存row[])
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        selected ? setSelectedRow((origin) => [...origin, ...changeRows])
                            : setSelectedRow((origin) => {
                                let changeRowKey = changeRows.map(item => item.key);
                                return origin.filter(item => changeRowKey.indexOf(item.key) < 0)
                            });
                    },
                    onSelect: (record, selected, selectedRows) => onRow(record),
                    selectedRowKeys: selectedRow.map(item => item.key)
                }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        loadData({ pageNum, pageSize })
                    }
                }}
                onRow={record => ({
                    onClick: () => onRow(record),
                    onContextMenu: (e) => {
                        e.preventDefault();
                        setSelectedRow([record]);
                    }
                })}
                scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
            />
        </>
    )
}