import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { useStockPoDetailModel } from "../backlogModel";

export default () => {
  const { } =
    useStockPoDetailModel();
  return (
    <>
      <PageHeader />
      <DataBox />
    </>
  );
};
