import { useState, useEffect} from "react";
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { stockDetailedService } from "./stockDetailedService";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { useLockFn } from "ahooks";
import { message } from "antd";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import { formatNum } from "@/erp_subpackage/utils/util";

const initDesc = { stockAmount: 0, stockNum: 0 };

export const useStockDetailedModel = createModel(function () {
    const { user: { shopId, organType } } = useGlobalModel()
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initDesc);
    const [descTotal, setDescTotal] = useState(initDesc);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //表格多选id值
    const [selectedKeys,setSelectedKeys] = useState([]);
    useEffect(() => {
        let newRowsDescTotal = { ...initDesc };
        selectedKeys.forEach((item) => {
                let { inventoryNum, totalAmount } = item
                newRowsDescTotal.stockAmount += +formatNum(totalAmount)
                newRowsDescTotal.stockNum += +formatNum(inventoryNum)
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [selectedKeys])
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            status: "purchase-status.bill,purchase.bill,purchase.completely-io,purchase.allocation"
        }
        let { retData } = await stockDetailedService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading(false)
        setSelectedRowKeys([]);
        setSelectedKeys([]);
        let { retData: statistics } = await stockDetailedService.amount(searchParams);
        setDescTotal(statistics);
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        setSelectedKeys([]);
        loadData(initPgCfg, {});
    }
    //导出
    const exportList = async (searchValue = searchParam) => {
        // setLoading({ tip: "文件导出中", spinning: true });
        const searchParams = {
            searchItem:{
                ...searchValue,
                shopId: searchValue.shopId ?? shopId,
                pageSize: -1,
                pageNum: 1,
                status: "purchase-status.bill,purchase.bill,purchase.completely-io,purchase.allocation",
                id: selectedRowKeys.join(",")
            }
        }
        let {retData} = await stockDetailedService.export(searchParams);
        message.success(retData)
        // convertRes2Blob(result);
        // setLoading({ spinning: false });
        // return result;
    }

    //转移货位
    const [shiftShelfModal, setShiftShelfModal] = useState(false);
    const [sectionShelfModal, setSectionShelfModal] = useState(false);
    const [shelfLoading, setShelfLoading] = useState({ spinning: false, tip: "转移中" });

    const onShiftShelf = useLockFn(async (selectedRows) => {
        if (!selectedRows.length) {
            message.warning("请选择你要转移的货位")
            return;
        }
        let filterPayData = selectedRows.filter(item => item.shelfCode1);
        if (filterPayData.length !== selectedRows.length) {
            message.warning("请选择要转移的货位");
            return;
        }
        setShelfLoading({ tip: "转移中", spinning: true });
        let arr = selectedRows.map(item => ({
            id: item.id,
            warehouseCode: item.warehouseCode1,
            warehouseName: item.warehouseName1,
            shelfId: item.shelfId1,
            shelfCode: item.shelfCode1,
            moveNum: item.moveNum,
        }))
        let { retData } = await stockDetailedService.shift(arr);
        if (retData) {
            setShiftShelfModal(false);
            loadData();
            message.success("货位转移成功")
        }
        setShelfLoading({ spinning: false });
    });

    const shelftDownloadTpl = async () => {
        try {
            setShelfLoading({ spinning: true, tip: '正在下载...' });
            let result = await stockDetailedService.downloadTpl();
            convertRes2Blob(result);
            setShelfLoading({ spinning: false, tip: '' });
        } catch {
            setShelfLoading({ spinning: false, tip: '' });
        }
    };

    //移库记录弹窗
    const [recordsVisible, setRecordsVisible] = useState(false);


    /**
     *  //转移货位
     */
     //查询条件
     const [searchParamShelf, setSearchParamShelf] = useState({});
     //列表数据源
     const [dataSourceShelf, setDataSourceShelf] = useState([]);
     //列表数据源分页参数
     const [pgCfgShelf, setPgCfgShelf] = useState(initPgCfg);
     //已选中的
     const [selectedShelfRows, setSelectedShelfRows] = useState([]);
     const loadDataShelf = async (page, searchValue = searchParamShelf) => {
         setShelfLoading(true)
         try {
            const searchParams = {
                ...searchValue,
                shopId: searchValue.shopId ?? shopId,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
                status: "purchase-status.bill,purchase.bill,purchase.completely-io,purchase.allocation"
            }
            let { retData } = await stockDetailedService.page(searchParams);
            const { records, ...pg } = retData;
             setPgCfgShelf(transPgToPagination(pg));
             setDataSourceShelf(records);
             setShelfLoading(false)
         } catch (err) {
             setShelfLoading(false)
         }
     }
    //  const shiftShelfApi = useLockFn(async () => {
    //      if (!selectedShelfRows.length) {
    //          globalPrompt("message", { text: "请选择你要转移的货位", type: "warning" });
    //          return;
    //      }
    //      try {
    //          setLoadingInfo({ loadingVisible: true, txt: '正在转移货位' });;
    //          let arr = selectedShelfRows.map(item => ({
    //              id: item.id,
    //              warehouseCode: item.warehouseCode1,
    //              warehouseName: item.warehouseName1,
    //              shelfId: item.shelfId1,
    //              shelfCode: item.shelfCode1,
    //              moveNum: item.moveNum,
 
    //          }))
    //          let { retData } = await purchaseDetailsService.shift(arr);
    //          setShiftShelfModal(false);
    //          setSelectedShelfRows([]);
    //          loadData();
    //          setLoadingInfo({ loadingVisible: false, txt: "" });
    //          globalPrompt("message", { text: "货位转移成功", type: "success" })
    //      } catch {
    //          setLoadingInfo({ loadingVisible: false, txt: '' });
    //      }
    //  })
     const shiftShelfOldApi = useLockFn(async () => {
         if (!selectedShelfRows.length) {
             message.warning("请选择你要转移的货位")
             return;
         }
         
         try {
            setShelfLoading({ loadingVisible: true, txt: '正在转移货位' });;
             let arr = selectedShelfRows.map(item => ({
                 id: item.id,
                 warehouseCode: item.warehouseCode1,
                 warehouseName: item.warehouseName1,
                 shelfId: item.shelfId1,
                 shelfCode: item.shelfCode1,
                 moveNum: item.moveNum,
 
             }))
             let { retData } = await stockDetailedService.shiftOld(arr);
             setSectionShelfModal(false);
             setSelectedShelfRows([]);
             loadData();
             setShelfLoading({ loadingVisible: false, txt: "" });
             message.success("货位转移成功")
         } catch {
            setShelfLoading({ loadingVisible: false, txt: '' });
         }
     })
 
 

    return {
        /***列表***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg, initPgCfg, shopId, organType,
        loading, descTotal,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportList,
        shiftShelfModal, setShiftShelfModal,
        onShiftShelf,
        recordsVisible, setRecordsVisible,
        shelfLoading, setShelfLoading,
        sectionShelfModal, setSectionShelfModal,
        shelftDownloadTpl,
        loadDataShelf,
        searchParamShelf, setSearchParamShelf,
        dataSourceShelf,
        selectedShelfRows, setSelectedShelfRows,
        pgCfgShelf, setPgCfgShelf,
        loadDataShelf,
        //  shiftShelfApi,
        shiftShelfOldApi,
        shelftDownloadTpl,
        sectionShelfModal, setSectionShelfModal,
        shelfLoading,
        setShelfLoading,
        rowsDescTotal, setRowsDescTotal,
        selectedKeys,setSelectedKeys
    }
});