import React, { useEffect,useState } from "react"
import { Modal, Button, } from "antd"
import { quotaOrderDetailList } from "@/service/teamService"
import "./orderPrint.css"
const PrintMadal = (props) => {
    const { visible, onClose, rowInfo } = props
    const [data, setData] = useState([])
    useEffect(() => {
        quotaOrderDetailList(rowInfo.id).then((res) => {
            setData(res.retData)
        })
    }, [])
   
    //打印
    const print = () => {
        const el = document.getElementById("print-container")
        const iframe = document.createElement("IFRAME")
        let doc = null
        // iframe.setAttribute('style', 'position:absolute;top:0px;left:0px;width:100%;height:100%',);
        document.body.appendChild(iframe)
        doc = iframe.contentWindow.document
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./orderPrint.css">`)
        doc.write(el.innerHTML)
        doc.close()
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus()
        iframe.onload = () => {
            iframe.contentWindow.print()
            document.body.removeChild(iframe)
        }
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe)
        }
        const old = window.document.body.innerHTML
        const printPart = document.getElementById("print-container")
        window.document.body.innerHTML = ""
        window.document.body.appendChild(printPart)
        window.print()
        window.document.body.innerHTML = old
        window.location.reload()
    }
    //转换number，保留两个0
    const switchNum = (num, n = 2) => {
        return Number(num).toFixed(n)
    }
    return (
        <Modal
            title="结算单打印"
            width={1120}
            visible={visible}
            onCancel={() => onClose(false)}
            footer={[
                <Button onClick={() => onClose(false)} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={print} key="submit">
                    确认打印
                </Button>,
            ]}
        >
            <div id="print-container">
                <div className="print-container">
                    <p className="title">结算单</p>
                    <div className="print-name">
                        <span>账单账号：{rowInfo.settlementNo}</span>
                        <span>团队名称：{rowInfo.teamName}</span>
                        <span>账单金额：{rowInfo.totalAmount}</span>
                        <span>已结算：{Number(rowInfo.settlementStatus) === 1 ? 0 : rowInfo.totalAmount}</span>
                        <span>未结算：{Number(rowInfo.settlementStatus) === 2 ? 0 : rowInfo.totalAmount}</span>
                    </div>
                    <table className="print-table" style={{borderBottom:"1px solid #333"}}>
                        <tbody>
                            <tr className="table-item">
                                <td>工单编号</td>
                                <td>工单类型</td>
                                <td>工时费</td>
                                <td>应收金额</td>
                                <td>实收金额</td>
                                <td>登记日期</td>
                            </tr>
                            {data.length > 0 ? (
                                data.map((item) => (
                                    <tr className="table-item" key={item.orderCode}>
                                        <td>{item.orderCode}</td>
                                        <td>{item.orderType}</td>
                                        <td>{switchNum(item.itemActualAmount)}</td>
                                        <td>{switchNum(item.orderTotalAmount)}</td>
                                        <td>{switchNum(item.orderActualAmount)}</td>
                                        <td>{item.createTime}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="table-item">
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                    <td>无</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal>
    )
}

export default PrintMadal
