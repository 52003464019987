import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { JXCStatistics, JXCStatisticsExport,JXCStatisticsTotal,JXCgoodsStatistics,JXCgoodsStatisticsExport } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message,Tooltip,Table,Button } from "antd"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { YhBox } from "@/erp_subpackage/components/YhBox";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { globalPrompt } from "../../../erp_subpackage/components/message";

export default () => {
    const { state,Option } = useContext(MyContext)
    const [list, setList] = useState([]) //进销存报表
    //商品明细
    const [purchasePinData, setPurchasePinData] = useState([]);
    const [totalData, setotalData] = useState({}) //总计
    // const [shopList, setShopList] = useState([])
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName },fetchFmsShopList,setLoadingInfo } = useGlobalModel()
    const [searchValue, setSearchValue] = useState({})
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    //进销存报表 和 商品明细账 (默认进销存)
    const [jxcOrGoodsDetail, setJxcOrGoodsDetail] = useState("jxcRecord");
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [goodPagination, setgoodPagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //表格选中行Key值
    const [selectedRow, setSelectedRow] = useState({});
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    const goodsDetail = () => {
        if (selectedRowKeys.length !== 1) {
            globalPrompt("message", { text: "请选择单条数据进行编辑，点击鼠标右键可一键选中哦！", type: "warning" });
            return;
        }
        loadMaterialData();
    }   
    useEffect(() => {
        let filters = list.filter(el => selectedRowKeys.length > 0 ? el.materialCode === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedRow(selectedData)
    }, [selectedRowKeys])
    //副标题计算
    useEffect(() => {
        if (state.activeMenu === "JXCStatistics") {
            loadData(pagination.current)
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     fetchFmsShopList('').then(arr => {
    //         setShopList(arr)  
    //     })
    // }, [])
    //列表数据
    const loadData = (current, search = searchValue) => {
        setJxcOrGoodsDetail("jxcRecord")
        setLoading(true)
        let data = {
             ...search,
            pageNum: current,
            pageSize: search?.pageSize??10,
            shopCode,
            shopId,
            startTime:searchValue?.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            endTime:searchValue?.endTime?  moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            }
        JXCStatistics(data).then(({retData}) => {
            setpagination({
                current: current,
                total: retData.total,
                pageSize: retData.size,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(retData.records)
        })
        JXCStatisticsTotal(data).then(({retData}) => {
            setotalData(retData)
        })
        setLoading(false)
    }
    const loadMaterialData = async (current, searchValue) => {
        setJxcOrGoodsDetail("goodsDetail")
        let { materialCode, materialName } = selectedRow
        // const { startTime,endTime}=searchValue
        const searchParams = {
            shopId,
            startTime:searchValue?.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            endTime:searchValue?.endTime?  moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            materialCode, materialName,
            pageSize: searchValue?.pageSize??10,
            pageNum: current,
        }
        let { retData } = await JXCgoodsStatistics(searchParams);
        const { records, ...pg } = retData;
        setPurchasePinData(records);
        setgoodPagination({
            current: current,
            total: retData.total,
            pageSize: retData.size,
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    const handleExport = () => {
        let data = {
            searchItem:{
                ...searchValue,
                startTime:searchValue.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
                endTime:searchValue.endTime?  moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
                shopId,
                shopCode,
            },  
            pageSize:-1,
            pageNum:1,   
            startTime:searchValue.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            endTime:searchValue.endTime?  moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
        }
        JXCStatisticsExport(data).then((res) => {
            message.success(res.retData)
        })
    }

    //列表button
    // const topButtons = [
    //     {
    //         click: () => handleExport(),
    //         content: "导出",
    //     },
    // ]

    //列表button
    const topButtons = <>
    <Button
        className={`mr-5 btn-item ${jxcOrGoodsDetail === "jxcRecord" ? "tab-buts-active" : ""}`}
        type="link"
        shape="round"
        onClick={() =>{loadData(pagination.current, searchValue)}}
    >进销存统计报表</Button>
    <Button
        className={`mr-5 btn-item ${jxcOrGoodsDetail === "goodsDetail" ? "tab-buts-active" : ""}`}
        type="link"
        shape="round"
        onClick={() => goodsDetail()}
    >商品明细账</Button>
    <Button
        className="btn-item"
        shape="round"
        onClick={() => handleExport()}
    >导出</Button>
</>
    //table配置
    const columns = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            fixed: "left",
            width: 200,
            dataIndex: 'materialCode',
            //render: (text, record) => <YhTootip text={text} onClick={() => onHistoryModel(record)} />,
        }, {
            title: '商品名称',
            fixed: "left",
            width: 200,
            dataIndex: 'materialName',
        }, {
            title: '商品类型',
            width: 120,
            dataIndex: 'typeName',
        }, 
        {
            title: '期末数量',
            width: 120,
            dataIndex: 'inventoryNum',
        }, {
            title: '期末成本',
            width: 120,
            dataIndex: 'lastestPriceAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        }, {
            title: '本期入数',
            width: 120,
            dataIndex: 'warehousingInNum',
        }, {
            title: '本期入金额',
            width: 120,
            dataIndex: 'warehousingInAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        },  {
            title: '本期出数',
            width: 120,
            dataIndex: 'warehouseOutNum',
        }, {
            title: '本期出金额',
            width: 120,
            dataIndex: 'warehouseOutAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        },{
            title: '采购数量',
            width: 120,
            dataIndex: 'purchaseNum',
        }, {
            title: '采购金额',
            width: 120,
            dataIndex: 'purchaseAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        }, {
            title: '采退数量',
            width: 120,
            dataIndex: 'preturnNum',
        }, {
            title: '采退金额',
            width: 120,
            dataIndex: 'preturnAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        }, {
            title: '销售数量',
            width: 120,
            dataIndex: 'saleNum',
        }, {
            title: '销售金额',
            width: 120,
            dataIndex: 'saleAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        },
        {
            title: '销退数量（含赠品）',
            width: 140,
            dataIndex: 'sreturnNum',
        }, {
            title: '销退金额',
            width: 120,
            dataIndex: 'sreturnAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        },
        {
            title: '维修数量',
            width: 120,
            dataIndex: 'maintenanceNum',
        }, 
        {
            title: '维修金额',
            width: 120,
            dataIndex: 'maintenanceAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        }, 
        {
            title: '维退数量',
            width: 120,
            dataIndex: 'maintenanceReturnNum',
            render: (text) => `￥${formatNum(text ?? 0)}`
        },
        {
            title: '维退金额',
            width: 120,
            dataIndex: 'maintenanceReturnAmount',
            render: (text) => `￥${formatNum(text ?? 0)}`
        }, 
    ]
    const nextColumns = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '从属门店',
            fixed: "left",
            width: 160,
            dataIndex: 'shopName',
        }, {
            title: '仓库',
            fixed: "left",
            width: 140,
            dataIndex: 'warehouseName',
        }, {
            title: '出入库日期',
            width: 160,
            dataIndex: 'stockIoTime',
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'tradeTypeName',
        }, {
            title: '对方单位',
            width: 200,
            dataIndex: 'userName',
        }, {
            title: '单号',
            width: 180,
            dataIndex: 'code',
        }, {
            title: '货位',
            width: 140,
            dataIndex: 'shelfCode',
        }, {
            title: '数量',
            width: 100,
            dataIndex: 'num',
        }, {
            title: '单价',
            width: 100,
            dataIndex: 'price',
        }, {
            title: '金额',
            width: 100,
            dataIndex: 'amount',
        }, {
            title: '成本',
            width: 100,
            dataIndex: 'costAmount',
        }, {
            title: '毛利',
            width: 100,
            dataIndex: 'profit',
        }, {
            title: '数量余额',
            width: 100,
            dataIndex: 'surplusNum',
        }, {
            title: '金额余额',
            width: 100,
            dataIndex: 'surplusAmount',
        }, {
            title: '业务员',
            width: 120,
            dataIndex: 'createUser',
        }
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "商品编号",
                valueName: "materialCode",
            },
            {
                type: "input",
                placeholder: "商品名称",
                valueName: "materialName",
            },
            {
                type: "select",
                placeholder: "商品类型",
                valueName: "outShopCode",
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE).map((item) =>
                ( 
                    <Option key={item.key} value={item.value}>
                        {item.label}
                    </Option>
                )),
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                placeholder: ['开单开始时间', '开单结束时间'],
                span: 16,
                xxl: 8,
            },
        ],
    }
    const desc = [
        // {
        //     label: "初始数量",
        //     value: totalData.num??0,
        //     color: "green"
        // },
        // {
        //     label: "初始金额",
        //     value: "￥" + formatNum(totalData.amount?? 0),
        //     color: "red"
        // },
        // {
        //     label: "期末数量",
        //     value: totalData.num??0,
        //     color: "green"
        // },
        // {
        //     label: "期末金额",
        //     value: "￥" + formatNum(totalData.amount?? 0),
        //     color: "red"
        // },
        // {
        //     label: "入库数",
        //     value: totalData.num??0,
        //     color: "green"
        // },
        // {
        //     label: "入库金额",
        //     value: "￥" + formatNum(totalData.amount?? 0),
        //     color: "red"
        // },
        // {
        //     label: "出库数",
        //     value: totalData.num??0,
        //     color: "green"
        // },
        // {
        //     label: "出库金额",
        //     value: "￥" + formatNum(totalData.amount?? 0),
        //     color: "red"
        // },
    ]
    //点击行选高亮
    const onRow = (materialCode) => {
        // setSelectedRow(record)
        // console.log(materialCode)
        let findIndex = selectedRowKeys.indexOf(materialCode);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(materialCode);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    const exportJxcPin = async () => {
        let data = {
            pageSize:-1,
            pageNum:1,   
            startTime:searchValue?.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            endTime:searchValue?.endTime?  moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            searchItem:{
                shopId,
                shopCode,
                materialCode: selectedRow.materialCode,
                materialName: selectedRow.materialName,
                startTime:searchValue?.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
                endTime:searchValue?.endTime?  moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):undefined,
            },  
        }
        JXCgoodsStatisticsExport(data).then((res) => {
            message.success(res.retData)
        })
    }
    return (
        <>
            {state.activeMenu === 'JXCStatistics' && (
                <>
                    <TopSearch
                        title="进销存统计表"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <YhBox title="进销存统计表" action={topButtons} descriptions={desc} style={{marginTop:20}}>
                    {jxcOrGoodsDetail === "jxcRecord" ?
                        <Table
                            rowKey={record => record.materialCode}
                            rowSelection={{
                                selectedRowKeys: selectedRowKeys, //记录选中
                                //全部选中时  (存string[])
                                onSelectAll: (selected, selectedRows, changeRows) => {
                                    let changeRowId = changeRows.map(item => item.materialCode);
                                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                                },
                                onSelect: (record) => onRow(record.materialCode),
                            }}
                            loading={loading}
                            columns={columns}
                            dataSource={list}
                            pagination={{
                                ...pagination,
                                position: ['bottomCenter'],
                                showTotal: () => `共${pagination.total}条`,
                                onChange: (current, serach = searchValue) => {
                                    setSearchValue({...searchValue,pageSize:serach})
                                    loadData(current, searchValue)
                                },
                            }}
                            scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
                            onRow={record => ({
                                onClick: () => {
                                    onRow(record.materialCode)},
                                onContextMenu: (e) => {
                                    e.preventDefault();
                                    setSelectedRowKeys([record.materialCode]);
                                }
                            })}
                        />:<>
                        <div style={{margin:'0 20px'}}>
                        <Button
                        className={`btn-item`}
                        type="link"
                        shape="round"
                        onClick={() => exportJxcPin()}
                    >导出</Button>
                    <div className="record-warp">
                        <div className="record-item">
                            <label>商品编号:</label>
                            <div className="f1">
                                <span className="text">{selectedRow.materialCode}</span>
                            </div>
                        </div>
                        <div className="record-item">
                            <label>商品名称:</label>
                            <div className="f1">
                                <span className="text">{selectedRow.materialName}</span>
                            </div>
                        </div>
                        <div className="record-item">
                            <label>商品类型:</label>
                            <div className="f1">
                                <span className="text">{selectedRow.typeName}</span>
                            </div>
                        </div>
                        <div className="record-item">
                            <label style={{ width: "90px" }}>发动机型号:</label>
                            <div className="f1">
                                <span className="text">{}</span>
                            </div>
                        </div>
                        <div className="record-item">
                            <label>替换编号:</label>
                            <div className="f1">
                                <span className="text">{selectedRow.replaceCode}</span>
                            </div>
                        </div>
                    </div>
                    <Table
                        rowKey={record => record.materialCode}
                        columns={nextColumns}
                        dataSource={purchasePinData}
                        pagination={{
                            ...goodPagination,
                            position: ['bottomCenter'],
                            showTotal: () => `共${goodPagination.total}条`,
                            onChange: (current, serach = searchValue) => {
                                setSearchValue({...searchValue,pageSize:serach})
                                loadMaterialData(current, searchValue)
                            },
                        }}
                        // onRow={record => ({
                        //     onClick: () => onRow(record.id)
                        // })}
                        scroll={{ x: 'max-content', y: "calc(100vh - 446px)" }}
                    />
                    </div>
                    </>
                    }
                    </YhBox>
                </>
            )}
        </>
    )
}
