import React, { FC } from "react";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { useSaleDcDetailsModel } from "./saleDcDetailsModel";

export const TopButs = ({ id, mode }) => {
    const { getTreeNodeName } = useDictModel(() => []);
    const { editFormVal: { code, status, transferOutCode } } = useSaleDcDetailsModel();


    const orderStatu = [
        {
            label: "调拨单号",
            value: code ?? "",
            color: 'blue',
            status: true
        },
        {
            label: "状态",
            value: getTreeNodeName(status ?? ""),
            color: 'green',
            status: true
        },
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            hidden={mode === "edit" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}