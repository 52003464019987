import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
import { defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
class CommonService {
    async userPage(searchVo) {
        const apiUrl = `/ci-storage/stockIo/user/findPage`;
        return await httpRequest.post(apiUrl, searchVo);
    }
    async supperPage(searchVo) {
        const apiUrl = `/ci-storage/stockIo/supper/findPage`;
        return await httpRequest.post(apiUrl, searchVo);
    }
    async stockIoExport(searchVo) {
        const apiUrl = `/ci-storage/stockIo/export`;
        return await httpRequest.postFile(apiUrl, searchVo);
    }
    async recordItem(searchVo) {
        const apiUrl = `/ci-storage/maintenance/recordItem`
        return await httpRequest.post(apiUrl, searchVo);
    }
    async recordMater(searchVo) {
        const apiUrl = `/ci-storage/maintenance/recordMater`
        return await httpRequest.post(apiUrl, searchVo);
    }
    //出入库记录查询
    async detailsFindPage(params) {
        const apiUrl = `/ci-storage/stock/details/details/findPage`;
        return await httpRequest.post(apiUrl, params);
    }
    // 采购历史
    async purchaseHistory(params) {
        const apiUrl = `/ci-storage/purchase/purchaseHistory`;
        return await httpRequest.post(apiUrl, params);
    }
    //销售历史
    async saleHistory(params) {
        const apiUrl = `/ci-storage/sale/saleHistory`;
        return await httpRequest.post(apiUrl, params);
    }
    // 云修采购历史
    async vrmPurchaseHistory(params) {
        const apiUrl = `vrm2/purchase/purchaseHistory`;
        return await httpRequest.post(apiUrl, params,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //云修销售历史
    async vrmSaleHistory(params) {
        const apiUrl = `/vrm2/sale/saleHistory`;
        return await httpRequest.post(apiUrl, params,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //云修分店库存
    async vrm2Inventory(materialCode) {
        const apiUrl = `/vrm2/stock/store/inventory/${materialCode}`;
        return await httpRequest.get(apiUrl, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //供应链分店库存
    async scm2Inventory(materialCode) {
        const apiUrl = `/scm2/inventory/summary/report/selectShopInventorySummary`;
        return await httpRequest.post(apiUrl, {materialCode}, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //客车分店库存
    async bcmInventory(materialCode) {
        const apiUrl = `/bcm/stock/store/inventory`;
        return await httpRequest.post(apiUrl, {materialCode}, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
     //ai分店库存
     async aiInventory(materialCode) {
        const apiUrl = `/ci-storage/stock/store/inventory`;
        return await httpRequest.post(apiUrl, {materialCode},  { baseURL: API_SERVER_HOST });
    }
    //查询云创当前账号下面的所属机构
    async orgPage(searchVo) {
        const apiUrl = "/ci-platform/organ/page";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //查询云创所有的机构
    async orgPageList(searchVo) {
        const apiUrl = "/ci-platform/organ/list";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: API_SERVER_HOST });
    }
    //查询财务财务所有的门店
    async pageFms(searchVo) {
        const apiUrl = "/fms/shop/page";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //查询财务财务所有的门店
    async newPageFms(searchVo) {
        const apiUrl = "/fms/shop/find";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //账号密码登录  返回token
    async Login(user, config) {
        const apiUrl = "/portal/auth/login/login";
        return await httpRequest
            .post(apiUrl, user, { ...config, baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async Logout() {
        const apiUrl = "/portal/auth/login/logout";
        return await httpRequest
            .post(apiUrl);
    }

    //经营实体list
    async getBenetity() {
        const apiUrl = `/ci-storage/base/be/findBenetityList`;
        return await httpRequest.get(apiUrl);
    }

    async getBenetityFms(data) {
        const apiUrl = `/fms/be/page`;
        return await httpRequest.post(apiUrl, data, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //门店列表
    async getShop() {
        const apiUrl = `/ci-storage/base/be/findList`;
        return await httpRequest.get(apiUrl);
    }

    //门店列表
    async getShopFms(data) {
        const apiUrl = `/fms/shop/page`;
        return await httpRequest.post(apiUrl, data, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    async accountUnitList(searchVo) {
        const apiUrl = "/fms/accountUnit/listPage";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //占用明细查看
    async occupy(searchVo) {
        const apiUrl = `/ci-storage/statistics/purchaseSaleStorageDeatil`;
        return await httpRequest.post(apiUrl, searchVo);
    }

    //供应链库存统计列表
    async scmStockpage(searchVo) {
        const apiUrl = "/scm2/inventory/summary/report/page";
        // const apiUrl = "/scm2/stock/statistic/listPage";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    // 供应链库存统计总
    async scmStatistics(searchVo) {
        const apiUrl = "/scm2/stock/statistic/from/total";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    // 供应链库存统导出
    async scmStockExport(searchVo) {
        const appPageUrl = "/scm2/stock/statistic/export";
        return await httpRequest.postFile(appPageUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken(), timeout: 300000 });
    }

    //获取主数据门店  （scm）
    async getMdShop(searchVo) {
        const apiUrl = "/md/shop/page";
        return await httpRequest.post(apiUrl, {
            ...searchVo,
            page: { pageSize: 1000, pageNum: 1 }
        }, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //供应链占用库存可查明细
    async scmStockDetail(obj) {
        const apiUrl = `/scm2/stock/statistic/holdDetails`;
        return await httpRequest.post(apiUrl, obj, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken(), timeout: 300000 });
    }
    //调拨系统待入库库存
    async inventoryWaitPage(obj) {
        const apiUrl = `/sms/transfer/to/wait/for/listPage`;
        return await httpRequest.post(apiUrl, obj, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //查询特定得收款账户
    async accountFind(params) {
        const apiUrl = "/fms/account/find";
        return httpRequest
            // .post<IPage<IAccountType>>(apiUrl, params);
            .post(apiUrl, params,  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

     //账单查询(分页)
     bilPage(param) {
        const apiUrl = "/fms/bill/page";
        return httpRequest
            .post(apiUrl, param, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() ,timeout: 60000 });
    }

    //查询财务账户类型数据字典
    async fmsDictFind(params) {
        const apiUrl = "/fms/dict/find";
        return httpRequest
            .post(apiUrl, {sourceApp:'fms',...params},  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

     //查询财务账户类型数据字典
     async fmsDictAllTree(params) {
        const apiUrl = "/fms/dict/allTree";
        params.branchList = ["main", "fms"];
        return httpRequest
            .post(apiUrl, {sourceApp:'fms',...params},  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }


     //账户初始化
     async fmsInitAccount(param) {
        const apiUrl = "/fms/account/summary/customer/customerAccount";
        return await httpRequest
        .post(apiUrl, param,  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //调额
    async fmsAdjust(param) {
        const apiUrl = "/fms/account/adjust";
        return await httpRequest
        .post(apiUrl, param,  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //调额列表
    async fmsAdjustList(param) {
        const apiUrl = "/fms/account/adjust/page";
        return await httpRequest
        .post(apiUrl, param,  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

     //充值
     async fmsRecharge(param) {
        const apiUrl = "/fms/account/recharge";
        return await httpRequest
        .post(apiUrl, param,  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }

    //充值列表
    async fmsRechargeList(param) {
        const apiUrl = "/fms/account/recharge/page";
        return await httpRequest
        .post(apiUrl, param,  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }


    // fms数据字典
    async fmsAllTree(vo) {
        const apiUrl = "/fms/org/allTree";
        vo.branchList = ["main", "fms"];
        return await httpRequest.post(apiUrl, vo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() })
    }
    

    //供应商开户
    async fmAccountOpen(param) {
        const apiUrl = "/fms/account/open";
        return await httpRequest
        .post(apiUrl, param,  { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }





    
}
export const commonService = new CommonService();
