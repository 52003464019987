import React, { useEffect, useRef, useState } from 'react'
import { Table } from 'antd'
import FontIcon from '@/erp_subpackage/components/YhIcon'
import { taskBookModel } from "./taskBookModel"
import { taskBookService } from "@/service/taskBookService"
import { getToken } from "@/utils/dataStorage"

const sortKey = 'baseFileDetailJobBox'

export const JobBox = ({ value }) => {
    const { jobData, setJobData, pdfData } = taskBookModel()

    const newDataSource = (jobData).filter((item) => item.maintainLevel == value)

    const formRef = useRef()

    useEffect(() => {
        const dataList = jobData
        formRef.current = dataList
    }, [jobData])

    useEffect(() => {
        //getColumns()
    }, [])

    const [columns, setColumns] = useState([])

    const getColumns = () => {
        taskBookService.userCustomizeConfigOne(sortKey).then((res) => {
            if (!res.retData) {
                setColumns([...getRegularColumns(), ...defaultColumns])
            } else {
                let newColumns = [...getRegularColumns()]
                let data = JSON.parse(res.retData.val)
                data.forEach((element) => {
                    if (element.isShow) {
                        newColumns.push({ ...defaultColumns.find((i) => i.dataIndex === element.key), sorter: element.isSort })
                    }
                })
                setColumns(newColumns)
            }
        })
    }

    const getRegularColumns = () => {
        return [
            {
                title: (
                    <>
                        <span>序号</span>
                    </>
                ),
                width: 80,
                fixed: 'left',
                render: (t, r, i) => i + 1,
                align: 'center',
            }
        ]
    }

    const defaultColumns = [
        {
            title: (
                <span>
                    作业区域<FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            copyTitle: '作业区域',
            width: 160,
            dataIndex: 'workingArea',
            align: "center",
            isSet: true,

        },
        {
            title: (
                <span>
                    作业部位<FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            copyTitle: '作业部位',
            width: 150,
            dataIndex: 'itemClassification',
            align: "center",
            render: (text) => text,
            isSet: true,
        },
        {
            title: '保养作业项目',
            copyTitle: '保养作业项目',
            width: 500,
            dataIndex: 'itemNote',
            align: "center",
            isSet: true,
        },
        {
            title: (
                <span>
                    处理措施
                    <FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            copyTitle: '处理措施',
            width: 200,
            dataIndex: 'treatmentMeasure',
            align: "center",
            isSet: true,
        },
        {
            title: (
                <span>
                    备注
                    <FontIcon type="icon-liebiaokebianji" style={{ fontSize: '14px', margin: '0 4px', cursor: 'pointer', color: '#8a8a8a' }}></FontIcon>
                </span>
            ),
            copyTitle: '备注',
            width: 150,
            dataIndex: 'remark',
            align: "center",
            isSet: true,
        },
    ]

    const onChange = (pagination, filters, sorter, extra) => {
        if (sorter) {
            const { field, order } = sorter
            let dataList = jobData
            dataList = dataList.sort((a, b) => {
                let aVal = a[field] || ''
                let bVal = b[field] || ''
                if (order === 'ascend') {
                    return aVal.localeCompare(bVal)
                } else {
                    return bVal.localeCompare(aVal)
                }
            })

            setJobData([...dataList])
        }
    }

    return (
        <>
            {/* <Table
                style={{ marginBottom: '20px', border: '1px solid #f0f0f0' }}
                rowClassName="editable-row"
                rowKey={(record) => record.temporaryId || record.id}
                bordered
                columns={columns}
                dataSource={newDataSource}
                scroll={{ x: 'max-content', y: '600px' }}
                pagination={false}
                onChange={onChange}
            /> */}
            {pdfData && pdfData.filter(item => item.maintainLevel == value)[0] &&
                <embed src={"http://file-server.yunhaogroup.com/fsrv/fetch/" + pdfData.filter(item => item.maintainLevel == value)[0].md5 + "?Authorization=" + getToken()}
                    type="application/pdf"
                    style={{ width: "100%", height: 'calc(100vh - 300px)', minHeight: '700px' }}
                />
            }
        </>
    )
}
