import React, { useState, useEffect } from "react"
import Drawer from "@/components/Drawer"
import { Form, Button, Switch, Table, message } from "antd"
import { pointChangeList } from "@/service/pointService.js"

//用户积分消费查看
export default ({
    visible,
    onClose,
    rowInfo, //查看的行信息
    pointSweitchChange, //启用 禁用
}) => {
    const [form] = Form.useForm()
    const [data, setData] = useState([]) //积分消费详情

    useEffect(() => {
        if (visible) {
            let data = {
                pageSize: 1000,
                searchItem: {
                    pointsId: rowInfo.id,
                    userId: rowInfo.userId,
                    operational: rowInfo.operational,
                },
                pageNum: 1,
            }
            pointChangeList(data)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        setData(res.retData.list)
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => message.error(err.retMsg))
        }
    }, [visible])
    const formItemLayout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 14 },
    }
    //新增项目的table配置
    const columns = [
        {
            title: "时间",
            align: "center",
            dataIndex: "createTime",
        },
        {
            title: "积分变动",
            align: "center",
            dataIndex: "changeType",
        },
        {
            title: "积分变更途径",
            align: "center",
            dataIndex: "changeWay",
        },
        {
            title: "变更原因",
            align: "center",
            dataIndex: "changeDesc",
        },
        {
            title: "对应单号",
            align: "center",
            dataIndex: "orderCode",
        },
    ]
    return (
        <Drawer visible={visible} onClose={() => onClose(false)}>
            <div className="team-drawer-title">
                <span>用户积分详情</span>
            </div>
            <div className="team-drawer-content" style={{ padding: "10px" }}>
                <Form form={form} {...formItemLayout}>
                    <Form.Item label="用户昵称">
                        <span className="ant-form-text">{rowInfo.userName}</span>
                    </Form.Item>
                    <Form.Item label="手机号">
                        <span className="ant-form-text">{rowInfo.userPhone}</span>
                    </Form.Item>
                    <Form.Item label="积分状态">
                        <Switch
                            checkedChildren="正常"
                            unCheckedChildren="冻结"
                            defaultChecked={rowInfo.operational === 0 ? true : false}
                            onChange={(checked, e) => pointSweitchChange(checked, e, rowInfo.id)}
                        />
                    </Form.Item>
                </Form>
                {data && <Table size="small" rowKey={(record) => record.id} columns={columns} dataSource={data} pagination={false} scroll={{ y: 600 }} />}
                <div className="team-form-footer">
                    <Button className="team-but-two" onClick={onClose}>
                        确定
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}
