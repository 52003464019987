import React, { useEffect, useState, useContext } from "react";
import { Button, message, Table } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useContractModel } from '../contractParticularsModel'
import { ViechDrawer } from './viechDrawer'
import { MileageDrawer } from './mileageDrawer'
import { LogDrawer } from './logDrawer'
import { BillModal } from './billModal'
import { usecontractDetailsModel } from './detailsModel'
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import { WorkOrderInfoTable } from './WorkOrderTable'
import { CreateBillDrawer } from './creatbillDrawer'
import { contractService } from '../contractParticularsServer.js'
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { YhBut } from '@/erp_subpackage/components/YhButton'
import moment from "moment";
import { numberParseChina, formatNum, fmoney, momentFormat } from '@/erp_subpackage/utils/util';



export const TableBox = ({ mode, rowCode }) => {
    const { setVehicleVisable, setMileageVisable, setLogVisable, setOpen } = useContractModel()

    const { setMeilageoneTotal, addSelect, editSelect, removeVehicle, setDataSource, setBilldetailData, billdetailData, lookSelect, loading, billData, getVehicleList,
        editFormVal, addFormVal, setSelectedRowKeys, selectedRowKeys, setAddSelect, setEditSelect,
        setLogdatasource, setMileagedata, setNewfilterData, setDescTotal, setCreatevisible, setCreateBill,
        getdedtailsOne, selectedVehicle, setSelectedVehicle
    } = usecontractDetailsModel()


    //工单信息
    const [workorderinfo, setWorkorderinfo] = useState(false)

    //车辆code
    const [vechielcode, setVechielcode] = useState('')

    //车辆牌照查询参数
    const [searchParam, setSearchParam] = useState();

    const addVechielSelect = mode === 'edit' ? editSelect : mode == 'add' ? addSelect : lookSelect

    let formval = mode == 'add' ? addFormVal : editFormVal

    useEffect(() => {
        setDataSource(addVechielSelect)
    }, [addVechielSelect])


    //点击行
    const onRow = (record) => {
        let findObj = selectedRowKeys.find(item => item.vehicleId === record.vehicleId)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.vehicleId !== record.vehicleId);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };




    const columns = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => `${i + 1}`,
            fixed: 'left'
        },
        {
            title: '车辆牌照',
            width: 120,
            dataIndex: 'vehicleCode',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />

        },
        {
            title: '车辆品牌',
            width: 100,
            dataIndex: 'brandId',
            render: (text) => <YhTooltip text={text} />
        },
        {
            title: '签约日期',
            width: 180,
            dataIndex: 'signStartTime',
            editable: true,
            editType: 'datepicker2',
            render: (text) => text ? moment(text).format("YYYY-MM-DD") : text
        },
        {
            title: 'VIN码',
            width: 120,
            dataIndex: 'vehicleVin',
            render: (text) => <YhTooltip text={text} />

        }, {
            title: '发动机号',
            width: 180,
            dataIndex: 'engineCode',
            render: (text) => <YhTooltip text={text} />

        }, {
            title: '注册时间',
            width: 180,
            dataIndex: 'drivingSignDate',
            render: (text) => {
                const date = new Date(text);
                const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
                return formattedDate;
            },

        }, {
            title: '登记时间',
            width: 150,
            dataIndex: 'createTime',

        }, {
            title: '云小牛设备编号',
            width: 120,
            dataIndex: 'yxnEquipmentCode',
            render: (text) => <YhTooltip text={text} />

        },
        {
            title: '云小牛SN码',
            width: 120,
            dataIndex: 'yxnSn',
            render: (text) => <YhTooltip text={text} />

        },
        {
            title: '计费价格',
            width: 100,
            dataIndex: 'billPrice',
            render: (text) => {
                if (text === null || text === undefined || text == '') {
                    return 0
                } else {
                    return parseFloat(text).toFixed(3)
                }
            }
        },
        {
            title: '签约里程',
            width: 100,
            dataIndex: 'signMileage'

        },
        {
            title: '当前里程',
            width: 100,
            dataIndex: 'mileageCurrent',
        },
        {
            title: '上月账单里程',
            width: 120,
            dataIndex: 'mileageLast',
        },
        {
            title: "操作",
            align: "center",
            dataIndex: 'enable',
            fixed: "right",
            width: 150,
            render: (text, record, i) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {mode == 'edit' && ((formval.billMethod === '048001')) && record.signFlag === 1 && <Button
                        style={{
                            height: '25px',
                            color: '#fff',
                            borderRadius: '4px',
                            marginRight: '8px',
                            padding: '0 8px',
                        }}
                        type='primary'
                        primary='true'
                        onClick={() => {
                            setMileageVisable(true);
                            let list = editSelect.filter(item => item.vehicleId === record.vehicleId)
                            const modifiedData = list.map(item => ({
                                ...item,
                                totalAmount: item.mileageLast === 0 ? (item.signMileage * item.billPrice).toFixed(2) : ((item.mileageCurrent - item.mileageLast) * item.billPrice).toFixed(2)
                            }));
                            setMileagedata(modifiedData)
                            setNewfilterData(modifiedData)

                        }}
                    >
                        里程维护
                    </Button>}
                    {mode == 'edit' && ((formval.billMethod === '048001')) && record.signFlag === 1 && <Button
                        style={{
                            height: '25px',
                            color: '#fff',
                            borderRadius: '4px',
                            marginRight: '8px',
                            padding: '0 8px',
                        }}
                        type='primary'
                        primary='true'
                        onClick={() => {
                            setCreatevisible(true);
                            let list = editSelect.filter(item => item.vehicleId === record.vehicleId)
                            const modifiedData = list.map(item => ({
                                ...item,
                                totalAmount: item.mileageLast === 0 ? (item.signMileage * item.billPrice).toFixed(2) : ((item.mileageCurrent - item.mileageLast) * item.billPrice).toFixed(2)
                            }));
                            setCreateBill(modifiedData)
                            setNewfilterData(modifiedData)
                        }

                        }
                    >
                        生成账单
                    </Button>}
                    {mode !== 'add' && record.signFlag === 1 && ((formval.billMethod === '048001')) && (
                        <Button
                            style={{
                                height: '25px',
                                color: '#fff',
                                borderRadius: '4px',
                                marginRight: '8px',
                                padding: '0 8px',
                            }}
                            type="primary"
                            primary="true"
                            onClick={() => {
                                setOpen(true);

                                try {
                                    let data = {
                                        pageSize: 15,
                                        pageNum: 1,
                                        endTime: '',
                                        startTime: '',
                                        orders: [{
                                            signId: rowCode,
                                            billType: 2,
                                        }],

                                        searchItem: {
                                            signId: rowCode,
                                            billType: 2,
                                            vehicleId: record.vehicleId,
                                        }
                                    }
                                    setVechielcode(record.vehicleCode)
                                    contractService.mileagetotal(data).then(res => {
                                        let { retData } = res
                                        let { payableAmount, paidAmount, totalAmount } = retData
                                        const obj = { payableAmount, paidAmount, totalAmount }
                                        setMeilageoneTotal(obj)

                                    })
                                    contractService.mileageorder(data).then(res => {
                                        let { retData: { list } } = res
                                        const updatedData = list.map(obj => ({ ...obj, mileageDiff: obj.mileageCurrent - obj.mileageLast }));
                                        setBilldetailData(updatedData)
                                    })

                                } catch (error) {
                                    console.log(error)
                                }
                            }}
                        >
                            账单查看
                        </Button>
                    )}
                    {(mode !== 'add' && (formval.billMethod === '048001')) && record.signFlag === 1 && <Button
                        style={{
                            height: '25px',
                            color: '#fff',
                            borderRadius: '4px',
                            marginRight: '8px',
                            padding: '0 8px',
                        }}
                        type='primary'
                        primary='true'
                        onClick={() => {
                            setLogVisable(true);
                            let data = {
                                pageSize: 10, pageNum: 1, startTime: "", endTime: "", orders: [{ signId: formval.id, vehicleId: record.vehicleId }],
                                searchItem: { signId: rowCode, vehicleId: record.vehicleId }
                            }
                            contractService.mileageLog(data).then(res => {
                                let { retData: { list } } = res
                                const modifiedData = list.map((obj) => ({
                                    ...obj,
                                    type: "修改",
                                    result: "成功",
                                }));

                                console.log("list", modifiedData)
                                setLogdatasource(modifiedData)
                            })
                        }}
                    >
                        操作日志
                    </Button>}
                    {mode !== 'look' && record.signFlag === 1 && <Button
                        style={{
                            height: '25px',
                            color: '#fff',
                            borderRadius: '4px',
                            padding: '0 8px',
                        }}
                        onClick={() => removeVehicle(record, mode)}
                        danger='true'
                        type='primary'
                    >
                        删除
                    </Button>}
                    {mode !== 'look' && record.signFlag === 0 &&
                        <YhBut
                            type="delete"
                            click={() => {
                                const index = addVechielSelect.findIndex(item => item.vehicleCode === record.vehicleCode);
                                addVechielSelect.splice(index, 1);
                                mode === 'edit' ? setEditSelect(addVechielSelect) : setAddSelect(addVechielSelect);

                                const list = selectedVehicle.filter(e => e.vehicleCode != record.vehicleCode);
                                setSelectedVehicle(list);
                            }}
                        />}
                </div>
            )
        },
    ];

    const newSelectColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                editType: col?.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = (row) => {
        // // 将计费价格保留两位小数
        // const updatedPrice = Number(row.billPrice).toFixed(2);
        // const updatedsignMileage = Number(row.signMileage).toFixed(2);
        let time = moment(row.signStartTime, "YYYY-MM-DD HH:mm:ss")
        // // 在这里处理保存逻辑，将修改后的值存储到列表中的相应位置
        const updatedList = addVechielSelect.map(item => {
            if (item.vehicleId === row.vehicleId) {
                return {
                    ...item,
                    signStartTime: time,
                };
            }
            return item;
        });

        // 更新列表数据
        if (mode === 'add') {
            setAddSelect(updatedList)

        } else if (mode === 'edit') {
            setEditSelect(updatedList)
        }

    }

    return (
        <YhBox >
            <div style={{ paddingBottom: '8px', paddingTop: '8px' }}>
                <Button className="btn-item" shape="round" onClick={() => {
                    if (!formval.customerId) {
                        message.warning('请先选择回到基础信息添加客户再选择车辆')
                        return false
                    }
                    if (!formval.signType) {
                        message.warning('请先选择签约类型再选择车辆')
                        return false
                    }
                    if (formval.signType === '046001') {
                        if (!formval.bigBagMode) {
                            message.warning('请先选择大包模式再选择车辆')
                            return false
                        }
                    }
                    setVehicleVisable(true);
                    getVehicleList()
                }
                } disabled={mode === 'look'}>选择车辆</Button>
                {(mode == 'edit' && (formval.billMethod === '048001')) && <Button className="btn-item" shape="round" onClick={() => {
                    setMileageVisable(true);
                    let list = editSelect
                    const modifiedData = list.map(item => ({
                        ...item,
                        totalAmount: item.mileageLast === 0 ? (item.signMileage * item.billPrice).toFixed(2) : ((item.mileageCurrent - item.mileageLast) * item.billPrice).toFixed(2)
                    }));
                    setMileagedata(modifiedData);
                }
                }>里程维护</Button>}
                {(mode == 'edit' && ((formval.billMethod === '048001'))) && <Button className="btn-item" shape="round" onClick={() => {
                    setCreatevisible(true)
                    let list = editSelect
                    const modifiedData = list.map(item => ({
                        ...item,
                        totalAmount: item.mileageLast === 0 ? (item.signMileage * item.billPrice).toFixed(2) : ((item.mileageCurrent - item.mileageLast) * item.billPrice).toFixed(2)
                    }));
                    setCreateBill(modifiedData)
                    setNewfilterData(list)
                }}>生成账单</Button>}
            </div>

            <div style={{ marginLeft: '20px', marginTop: '-40px' }}>
                <YhPageHeader
                    size="small"
                    justify="end"
                    searchBtnVisible={false}
                    resetBtnVisible={false}
                    onChange={(updateParam) => {
                        setSearchParam({ ...searchParam, ...updateParam })
                    }}
                    searchFields={[{
                        type: "Input",
                        placeholder: "车辆牌照",
                        fieldName: "vehicleCode",
                    }]}
                />
            </div>

            <>
                <Table
                    className="custom-table"
                    columns={newSelectColumns}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    rowKey={record => record.vehicleId}
                    loading={loading}
                    scroll={{ x: 'max-content' }}
                    dataSource={
                        addVechielSelect.filter((item) => {
                            return new RegExp(searchParam?.vehicleCode).test(item.vehicleCode)
                        })
                    }
                    // rowSelection={{
                    //     //全部选中时 (存row[])
                    //     onSelectAll: (selected, selectedRows, changeRows) => {
                    //         selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                    //             : setSelectedRowKeys((origin) => {
                    //                 let changeRowId = changeRows.map(item => item.vehicleId);
                    //                 return origin.filter(item => changeRowId.indexOf(item.vehicleId) < 0)
                    //             });
                    //     },
                    //     onSelect: (record, selected, selectedRows) => onRow(record),
                    //     selectedRowKeys: selectedRowKeys.map(item => item.vehicleId)
                    // }}
                    onRow={record => ({
                        onClick: () => onRow(record),
                        onContextMenu: (e) => {
                            e.preventDefault();
                            setSelectedRowKeys([record]);
                        }
                    })}
                />
            </>

            {workorderinfo && <><WorkOrderInfoTable /></>}
            <ViechDrawer mode={mode} />
            <MileageDrawer mode={mode} rowCode={rowCode} />
            <LogDrawer mode={mode} rowCode={rowCode} />
            {<BillModal mode={mode} />}
            <CreateBillDrawer mode={mode} rowCode={rowCode} />
        </YhBox>
    )

}