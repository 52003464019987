import React, { useState, useEffect, useContext } from 'react'
import { Input, Modal, Form, Col, Row, Select, message, Table, Button, Spin, Skeleton, Switch } from 'antd'
import { getTeamMemberList, editTeam, bindTeamMember, unBindTeamMember } from '@/service/teamService'
import { EditTeamMemberPoint } from '@/service/teamService'
import { getPcodeDict } from '@/utils/dataStorage'
import { getMoveCarList } from '@/service/moveCarService'
import { platformUserList } from '@/service/customerService'
import { MyContext } from '@/store/myContext'
import './teamEdit.less'
import { teamUserBindOfficialQrcode, teamUserBindOfficialQuery } from '@/service/permissin'
// import { fileLookUrl } from "@/config/app.js"
import debounce from 'lodash/debounce'
import { useForm } from 'antd/es/form/Form'
const { Option } = Select
const TeamEdit = (props) => {
    const { showAllOrgan } = useContext(MyContext)
    const { visible, onCancel, onTeamLoadData, row, org, settlementMethodEmun } = props
    const [form] = Form.useForm()
    // const [formMember] = Form.useForm()
    const [memberData, setMemberData] = useState([]) // 成员列表的数据
    const [selectMemberInfo, setSelectMemberInfo] = useState({
        rowInfo: {},
        rowIndex: -1,
    }) //记录成员列表当前行下标及当前行信息
    // const [memberVisible, setMemberVisible] = useState(false)
    // const [idFileList, setIDFileList] = useState([]) //图片list
    // const [headFileList, setheadFileList] = useState([]) //图片list
    // const [previewImage, setpreviewImage] = useState({
    //     //预览展示头像MD5
    //     visible: false,
    //     md5: "",
    // })
    // const [isMemberEdit, setIsMemberEdit] = useState(false)
    const [carList, setCarList] = React.useState([])

    //所属经理
    const [businessManager, setBusinessManager] = useState({
        data: [],
        fetching: false,
    })

    // 绑定公众号
    const [islVisible, setIslVisible] = useState(false)
    const [ticket, setTicket] = useState('')
    const [ticketLoading, setTicketLoading] = useState(false)
    const [polling, setPolling] = useState(false)
    const [userId, setUserId] = useState('')
    const [settlementMethod, setSettlementMethod] = useState('point')
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (visible) {
            setSettlementMethod(row.settlementMethod)
            setTeamOnes(row)
            console.log(row.id)
            getMemberList(row.id)
            getCarList({
                searchItem: {},
                pageNum: 1,
                pageSize: 100,
                orders: [
                    {
                        column: 'updateTime',
                        rule: 'DESC',
                    },
                ],
            })
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let timer = 0
        if (polling) {
            timer = setInterval(() => {
                teamUserBindOfficialQuery(userId).then((res) => {
                    console.log(res)
                    // if (res && res.retData) {
                    message.success('绑定成功')
                    setPolling(false)
                    clearInterval(timer)
                    setIslVisible(false)
                    getMemberList(row.id)
                    // }
                })
            }, 2000)
        }
        return () => {
            clearInterval(timer)
        }
    }, [polling]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        getMemberList(row.id)
    }, [pagination.current]) // eslint-disable-line react-hooks/exhaustive-deps

    //分页事件
    const onTableChange = async (pagination) => {
        setpagination({
            ...pagination,
            current: pagination.current,
        })
    }

    const getCarList = (param) => {
        getMoveCarList(param).then((res) => {
            console.log(res)
            // if (res && res.retCode === 200) {
            setCarList(res.retData.list)
            // }
        })
    }
    const setTeamOnes = (results) => {
        setBusinessManager({
            data: [{ text: results.managerName, value: results.managerId }],
            fetching: false,
        })
        form.setFieldsValue({
            ...results,
            disableFlag: results.disableFlag === 0 ? true : false,
        })
    }
    const getMemberList = (teamId) => {
        setSelectMemberInfo({
            rowInfo: {},
            rowIndex: -1,
        })
        getTeamMemberList({
            pageNum: pagination.current,
            pageSize: 10,
            searchItem: { teamId, disableFlag: 0 },
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            setMemberData(res.retData)
            setpagination({
                current: pagination.current,
                total: res.retData.total,
                pageSize: 10,
            })
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const onCustomerSubmit = () => {
        form.validateFields()
            .then((fieldsValue) => {
                console.log(fieldsValue)
                editTeam({
                    ...fieldsValue,
                    id: row.id,
                    disableFlag: 0,
                }).then((res) => {
                    // if (res && res.retCode === 200) {
                    onTeamLoadData(1)
                    form.resetFields()
                    onCancel(false)
                    message.success('修改成功')
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
            })
            .catch((err) => {
                message.error(err.retMsg)
            })
    }
    //成员列表的点击当前行
    const selectMemberRow = (record, index) => {
        setSelectMemberInfo({
            rowInfo: record,
            rowIndex: index,
        })
    }

    const [changeMemberinfoForm] = Form.useForm()
    const handleChangMemberInfo = (info) => {
        let title = settlementMethod === 'point' || settlementMethod === 'work_hour' ? '请输入个人点位' : '请输入分成比例'
        Modal.confirm({
            title,
            content: (
                <Form form={changeMemberinfoForm}>
                    <Form.Item
                        label={title}
                        name={`${settlementMethod === 'point' || settlementMethod === 'work_hour' ? 'allocationPoint' : 'userDistributionPoints'}`}
                        rules={[
                            {
                                pattern: /^(0\.(0[1-9]|[1-9]{1,2}|[1-9]0)$)|^0$|^1$|^1\.(0{1,2})$/,
                                message: `请输入正确的${settlementMethod === 'point' || settlementMethod === 'work_hour' ? '个人点位' : '分成比例'}`,
                            },
                        ]}
                    >
                        <Input placeholder={title} />
                    </Form.Item>
                </Form>
            ),
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                changeMemberinfoForm.validateFields().then((values) => {
                    console.log(values)
                    return new Promise((resolve, reject) => {
                        if (values) {
                            resolve(values)
                        } else {
                            message.warning(title)
                            reject(title)
                        }
                    }).then((res) => {
                        let data = {
                            id: info.id,
                            ...values,
                        }
                        EditTeamMemberPoint(data).then((res) => {
                            getMemberList(row.id)
                        })
                    })
                })
            },
            onCancel: () => {},
        })
    }

    //成员列表table配置
    const pointMemberColumns = [
        {
            title: '姓名',
            align: 'center',
            dataIndex: 'userName',
        },
        {
            title: '手机号',
            align: 'center',
            dataIndex: 'userPhone',
        },
        {
            title: '职务',
            align: 'center',
            dataIndex: 'userJobTitle',
            render: (text) => <span>{getPcodeDict(text)?.title}</span>,
        },
        {
            title: '个人点位',
            align: 'center',
            dataIndex: 'allocationPoint',
            render: (text, record) => (
                <span
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                        handleChangMemberInfo(record)
                    }}
                >
                    {text || '添加'}
                </span>
            ),
        },
        {
            title: '微信绑定',
            algin: 'center',
            dataIndex: 'officialUnionId',
            render: (officialUnionId, record) => (
                <span>
                    {!officialUnionId ? (
                        <Button
                            size="small"
                            onClick={() => {
                                setIslVisible(true)
                                setTicketLoading(true)
                                setUserId(record.id)
                                teamUserBindOfficialQrcode(record.id)
                                    .then((res) => {
                                        setTicketLoading(false)
                                        // if (res && res.retCode === 200) {
                                        setTicket(res.retData.ticket)
                                        setPolling(true)
                                        // } else {
                                        //     message.error(res.retMsg)
                                        //     setIslVisible(false)
                                        //     setPolling(false)
                                        // }
                                    })
                                    .catch((res) => {
                                        setIslVisible(false)
                                        setPolling(false)
                                    })
                            }}
                        >
                            绑定
                        </Button>
                    ) : (
                        '已绑定'
                    )}
                </span>
            ),
        },
    ]

    //成员列表table配置
    const shareRatioMemberColumns = [
        {
            title: '姓名',
            align: 'center',
            dataIndex: 'userName',
        },
        {
            title: '手机号',
            align: 'center',
            dataIndex: 'userPhone',
        },
        {
            title: '职务',
            align: 'center',
            dataIndex: 'userJobTitle',
            render: (text) => <span>{getPcodeDict(text)?.title}</span>,
        },
        {
            title: '分成比例',
            align: 'center',
            dataIndex: 'userDistributionPoints',
            render: (text, record) => (
                <span
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                        handleChangMemberInfo(record)
                    }}
                >
                    {text || '添加'}
                </span>
            ),
        },
        {
            title: '登录账号',
            align: 'center',
            dataIndex: 'userAccount',
        },
        {
            title: '微信绑定',
            algin: 'center',
            dataIndex: 'officialUnionId',
            render: (officialUnionId, record) => (
                <span>
                    {!officialUnionId ? (
                        <Button
                            size="small"
                            onClick={() => {
                                setIslVisible(true)
                                setTicketLoading(true)
                                setUserId(record.id)
                                teamUserBindOfficialQrcode(record.id)
                                    .then((res) => {
                                        setTicketLoading(false)
                                        // if (res && res.retCode === 200) {
                                        setTicket(res.retData.ticket)
                                        setPolling(true)
                                        // } else {
                                        //     message.error(res.retMsg)
                                        //     setIslVisible(false)
                                        //     setPolling(false)
                                        // }
                                    })
                                    .catch((res) => {
                                        setIslVisible(false)
                                        setPolling(false)
                                    })
                            }}
                        >
                            绑定
                        </Button>
                    ) : (
                        '已绑定'
                    )}
                </span>
            ),
        },
    ]

    //成员列表table配置
    const quotaMemberColumns = [
        {
            title: '姓名',
            align: 'center',
            dataIndex: 'userName',
        },
        {
            title: '手机号',
            align: 'center',
            dataIndex: 'userPhone',
        },
        {
            title: '职务',
            align: 'center',
            dataIndex: 'userJobTitle',
            render: (text) => <span>{getPcodeDict(text)?.title}</span>,
        },
        {
            title: '登录账号',
            align: 'center',
            dataIndex: 'userAccount',
        },
        {
            title: '微信绑定',
            algin: 'center',
            dataIndex: 'officialUnionId',
            render: (officialUnionId, record) => (
                <span>
                    {!officialUnionId ? (
                        <Button
                            size="small"
                            onClick={() => {
                                setIslVisible(true)
                                setTicketLoading(true)
                                setUserId(record.id)
                                teamUserBindOfficialQrcode(record.id)
                                    .then((res) => {
                                        setTicketLoading(false)
                                        // if (res && res.retCode === 200) {
                                        setTicket(res.retData.ticket)
                                        setPolling(true)
                                        // } else {
                                        //     message.error(res.retMsg)
                                        //     setIslVisible(false)
                                        //     setPolling(false)
                                        // }
                                    })
                                    .catch((res) => {
                                        setIslVisible(false)
                                        setPolling(false)
                                    })
                            }}
                        >
                            绑定
                        </Button>
                    ) : (
                        '已绑定'
                    )}
                </span>
            ),
        },
    ]
    // const memberInsert = () => {
    //     console.log("memberInsert")
    //     formMember.resetFields()
    //     setIDFileList([])
    //     setheadFileList([])
    //     setIsMemberEdit(false)
    //     setMemberVisible(true)
    // }
    // const memberEdit = () => {
    //     formMember.resetFields()
    //     console.log("memberEdit")
    //     if (!Object.keys(selectMemberInfo.rowInfo).length) {
    //         message.warning("请选择你要编辑的成员！")
    //         return
    //     }
    //     setIsMemberEdit(true)
    //     console.log(selectMemberInfo)
    //     const obj = selectMemberInfo.rowInfo
    //     const headImg = selectMemberInfo.rowInfo.headImg
    //         ? [
    //               {
    //                   uid: "-1",
    //                   status: "done",
    //                   response: {
    //                       retData: selectMemberInfo.rowInfo.headImg,
    //                   },
    //                   url: `${fileLookUrl().image}${selectMemberInfo.rowInfo.headImg}`,
    //               },
    //           ]
    //         : []
    //     const idCardImg = selectMemberInfo.rowInfo.idCardImg
    //         ? [
    //               {
    //                   uid: "-1",
    //                   status: "done",
    //                   response: {
    //                       retData: selectMemberInfo.rowInfo.idCardImg,
    //                   },
    //                   url: `${fileLookUrl().image}${selectMemberInfo.rowInfo.idCardImg}`,
    //               },
    //           ]
    //         : []
    //     setIDFileList(idCardImg)
    //     setheadFileList(headImg)
    //     formMember.setFieldsValue({
    //         ...obj,
    //         disableFlag: selectMemberInfo.rowInfo.disableFlag === 0 ? true : false,
    //         headImg,
    //         idCardImg,
    //     })
    //     setMemberVisible(true)
    // }
    // const onMemberSubmit = () => {
    //     if (isMemberEdit) {
    //         formMember.validateFields().then((fieldsValue) => {
    //             EditTeamMember({
    //                 ...fieldsValue,
    //                 id: selectMemberInfo.rowInfo.id,
    //                 teamId: row.id,
    //                 disableFlag: 0,
    //                 headImg: headFileList.length > 0 ? headFileList[0].response.retData : null,
    //                 idCardImg: idFileList.length > 0 ? idFileList[0].response.retData : null,
    //             }).then((res) => {
    //                 // if (res && res.retCode === 200) {
    //                 getMemberList(row.id)
    //                 setMemberVisible(false)
    //                 message.success("成员编辑成功")
    //                 // } else {
    //                 //     message.error(res.retMsg)
    //                 // }
    //             })
    //             // .catch((err) => {
    //             //     message.error(err.retMsg)
    //             // })
    //         })
    //     } else {
    //         formMember.validateFields().then((fieldsValue) => {
    //             console.log(fieldsValue)
    //             let bool = false
    //             if (fieldsValue.userJobTitle !== "10101") {
    //                 bool = true
    //             } else {
    //                 let findMember = memberData.find((item) => item.userJobTitle === "10101")
    //                 bool = findMember ? false : true
    //             }
    //             if (!bool) {
    //                 message.warning("只能存在一个班长")
    //                 return
    //             }

    //             addTeamMember({
    //                 ...fieldsValue,
    //                 teamId: row.id,
    //                 disableFlag: 0,
    //                 headImg: headFileList.length > 0 ? headFileList[0].response.retData : null,
    //                 idCardImg: idFileList.length > 0 ? idFileList[0].response.retData : null,
    //             }).then((res) => {
    //                 // if (res && res.retCode === 200) {
    //                 getMemberList(row.id)
    //                 setMemberVisible(false)
    //                 message.success("成员新增成功")
    //                 // } else {
    //                 //     message.error(res.retMsg)
    //                 // }
    //             })
    //             // .catch((err) => {
    //             //     message.error(err.retMsg)
    //             // })
    //         })
    //     }
    // }

    const [memberInfo, setMemberInfo] = useState({
        id: '', //列表
        data: [],
        fetching: false,
    })
    //列表查询
    const memberQuery = (value) => {
        setMemberInfo({
            ...memberInfo,
            data: [],
            fetching: true,
        })

        getTeamMemberList({
            searchItem: { disableFlag: 0, userName: value },
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.userName,
                    value: item.id,
                })
                return arr
            }, [])
            setMemberInfo({
                ...memberInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const memberQuerys = debounce(memberQuery, 1000)

    //客户经理列表查询
    const businessManagerQuery = (value, key) => {
        setBusinessManager({
            ...businessManager,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])
                setBusinessManager({
                    data: data,
                    fetching: false,
                })
            } else {
                message.error('没有查询到')
            }
        })
    }
    //防抖
    const businessManagerQuerys = debounce(businessManagerQuery, 1000)

    //弹窗控制
    const [addMemberVisible, setAddMemberVisible] = useState(false)
    //选中添加的成员
    const [addMember, setMember] = useState([])
    const handleChangeCustomer = (val) => {
        setMember(val)
    }

    const handleBind = () => {
        setAddMemberVisible(true)
    }

    const handleToBind = () => {
        bindTeamMember({ teamId: row.id, userIds: addMember }).then((res) => {
            message.success('绑定成功')
            setAddMemberVisible(false)
            setMember([])
            getMemberList(row.id)
        })
    }

    const handleUnBind = async () => {
        if (!Object.keys(selectMemberInfo.rowInfo).length) {
            message.warning('请选择你要解绑的成员信息')
            return
        }
        Modal.confirm({
            content: `确定要解绑成员 - ${selectMemberInfo.rowInfo.userName}?`,
            okText: '确定',
            cancelText: '取消',
            centered: true,
            onOk() {
                unBindTeamMember({ teamId: row.id, userIds: [selectMemberInfo.rowInfo.id] }).then((res) => {
                    message.success('解绑成功')
                    getMemberList(row.id)
                })
            },
        })
    }
    return (
        <>
            <Modal
                visible={visible}
                title="团队修改"
                centered={true}
                onOk={() => onCustomerSubmit()}
                onCancel={() => onCancel(false)}
                width={1260}
                bodyStyle={{
                    paddingRight: '65px',
                }}
            >
                <div>
                    <Form form={form} {...formItemLayout} initialValues={{ disableFlag: true, settlementMethod: 'point' }}>
                        <Row gutter={20}>
                            <Col span={24} offset={1}>
                                基本信息
                            </Col>
                            <Col span={12}>
                                <Form.Item label="团队名称" name="teamName" rules={[{ required: true, message: '团队名称' }]}>
                                    <Input placeholder="请输入团队名称" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="团队联系人"
                                    name="contactName"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入团队联系人',
                                        },
                                        {
                                            pattern: /^[\u4E00-\u9FA5]{2,5}$/,
                                            message: '请输入正确的姓名',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入团队联系人" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="联系电话"
                                    name="contactPhone"
                                    rules={[
                                        {
                                            required: true,
                                            message: `请输入手机号码!`,
                                        },
                                        {
                                            pattern: /^1\d{10}$/,
                                            message: '请输入正确手机号格式',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入联系电话" />
                                </Form.Item>
                            </Col>

                            {showAllOrgan ? (
                                <Col span={12}>
                                    <Form.Item
                                        label="所属机构"
                                        name="organId"
                                        rules={[
                                            {
                                                required: true,
                                                message: `请选择所属机构！`,
                                            },
                                        ]}
                                    >
                                        <Select placeholder="请选择所属机构" style={{ width: '100%' }}>
                                            {org.map((item) => (
                                                <Select.Option value={item.id} key={item.id}>
                                                    {item.organName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ) : (
                                <></>
                            )}

                            <Col span={12}>
                                <Form.Item label="团队属性" name="teamAttribute">
                                    <Select showSearch allowClear placeholder="请选择团队属性" style={{ width: '100%' }}>
                                        {getPcodeDict('028').children.map((item) => (
                                            <Select.Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="打印机编号" name="feieyunSn">
                                    <Input placeholder="请输入打印机编号" />
                                </Form.Item>
                            </Col>

                            <Col span={24} offset={1}>
                                结算方式
                            </Col>
                            <Col span={12}>
                                <Form.Item label="结算方式" name="settlementMethod" rules={[{ required: true, message: '结算方式' }]}>
                                    <Select allowClear placeholder="请选择结算方式" style={{ width: '100%' }} onChange={setSettlementMethod} disabled>
                                        {Object.entries(settlementMethodEmun).map((item) => (
                                            <Select.Option value={item[0]} key={item[1]}>
                                                {item[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24} offset={1}>
                                其他信息
                            </Col>
                            {settlementMethod === 'share_ratio' && (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            label="税点"
                                            name="taxRatio"
                                            rules={[
                                                {
                                                    pattern: /^[0|1](\.[0-9]{1,2}){0,1}$/,
                                                    message: '税点范围为0-1',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入税点" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="平台服务费"
                                            name="platServiceRatio"
                                            rules={[
                                                {
                                                    pattern: /^[0|1](\.[0-9]{1,2}){0,1}$/,
                                                    message: '平台服务费范围为0-1',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入平台服务费" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="团队分成" name="shareRatio">
                                            <Input placeholder="请输入分成" />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                            {settlementMethod === 'quota' && (
                                <>
                                    <Col span={12}>
                                        <Form.Item label="定额费用" name="quotaAmount">
                                            <Input placeholder="请输入定额费用" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="佣金比例"
                                            name="quotaRatio"
                                            // rules={[
                                            //     {
                                            //         pattern: /^[0|1](\.[0-9]{1,2}){0,1}$/,
                                            //         message: "平台服务费范围为0-1",
                                            //     },
                                            // ]}
                                        >
                                            <Input placeholder="请输入平台服务费" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="客户经理" name="managerId">
                                            <Select
                                                showSearch
                                                placeholder="请选择客户经理"
                                                notFoundContent={businessManager.fetching ? <Spin size="small" /> : null}
                                                filterOption={false}
                                                onFocus={() => businessManagerQuerys('')}
                                                onSearch={businessManagerQuerys}
                                                style={{ width: '100%' }}
                                            >
                                                {businessManager.data.map((item) => (
                                                    <Option value={item.value} key={item.value}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                            {/* <Col span={12}>
                            <Form.Item label="手持设备号" name="handdeviceCode">
                                <Input placeholder="请输入手持设备号" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="打印机编号" name="feieyunSn">
                                <Input placeholder="请输入打印机编号" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="微信收款二维码" name="wxQrCode">
                                <Input placeholder="请输入微信收款二维码" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="支付宝收款二维码" name="aliPayQrCode">
                                <Input placeholder="请输入支付宝收款二维码" />
                            </Form.Item>
                        </Col> */}
                            <Col span={12}>
                                <Form.Item label="绑定服务车" name="vehicleId">
                                    <Select
                                        optionFilterProp="children"
                                        showSearch
                                        allowClear
                                        placeholder="请选择绑定服务车"
                                        style={{ width: '100%' }}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {carList.map((item) => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.vehicleCode}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="推荐人" name="refererUserId">
                                    <Input placeholder="请输入推荐人" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="请输入备注" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="disableFlag" label="团队状态" valuePropName="checked">
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{ paddingLeft: '40px' }}>
                        <div className="team-list-but">
                            {/* <Button shape="round" size="small" className="team-but-left" onClick={() => memberInsert()}>
                                新增
                            </Button>
                            <Button shape="round" size="small" className="team-but-left" onClick={() => memberEdit()}>
                                编辑
                            </Button>
                            <Button
                                shape="round"
                                size="small"
                                className="team-but-left"
                                onClick={() => {
                                    deleteTeamMember(selectMemberInfo.rowInfo.id).then((res) => {
                                        // if (res && res.retCode === 200) {
                                        getMemberList(row.id)
                                        message.success("成员删除成功")
                                        // } else {
                                        //     message.error(res.retMsg)
                                        // }
                                    })
                                    // .catch((err) => {
                                    //     message.error(err.retMsg)
                                    // })
                                }}
                            >
                                删除
                            </Button> */}
                            <Button shape="round" size="small" className="team-but-left" onClick={() => handleBind()}>
                                绑定团队成员
                            </Button>
                            <Button shape="round" size="small" className="team-but-left" onClick={() => handleUnBind()}>
                                解绑团队成员
                            </Button>
                        </div>
                        {(settlementMethod === 'point' || settlementMethod === 'work_hour') && (
                            <Table
                                size="small"
                                rowKey={(record) => record.id}
                                columns={pointMemberColumns}
                                dataSource={memberData.list}
                                onRow={(record, index) => ({
                                    onClick: () => selectMemberRow(record, index),
                                })}
                                rowClassName={(record, index) => {
                                    let className = ''
                                    if (index === selectMemberInfo.rowIndex) className = 'dark-row'
                                    return className
                                }}
                                onChange={onTableChange}
                                pagination={{
                                    showSizeChanger: false,
                                    showTotal: () => `共${memberData.total}条`,
                                    position: ['bottomCenter'],
                                    ...pagination,
                                }}
                            />
                        )}

                        {settlementMethod === 'share_ratio' && (
                            <Table
                                size="small"
                                rowKey={(record) => record.id}
                                columns={shareRatioMemberColumns}
                                dataSource={memberData.list}
                                onRow={(record, index) => ({
                                    onClick: () => selectMemberRow(record, index),
                                })}
                                rowClassName={(record, index) => {
                                    let className = ''
                                    if (index === selectMemberInfo.rowIndex) className = 'dark-row'
                                    return className
                                }}
                                onChange={onTableChange}
                                pagination={{
                                    showSizeChanger: false,
                                    showTotal: () => `共${memberData.total}条`,
                                    position: ['bottomCenter'],
                                    ...pagination,
                                }}
                            />
                        )}

                        {settlementMethod === 'quota' && (
                            <Table
                                size="small"
                                rowKey={(record) => record.id}
                                columns={quotaMemberColumns}
                                dataSource={memberData.list}
                                onRow={(record, index) => ({
                                    onClick: () => selectMemberRow(record, index),
                                })}
                                rowClassName={(record, index) => {
                                    let className = ''
                                    if (index === selectMemberInfo.rowIndex) className = 'dark-row'
                                    return className
                                }}
                                onChange={onTableChange}
                                pagination={{
                                    showSizeChanger: false,
                                    showTotal: () => `共${memberData.total}条`,
                                    position: ['bottomCenter'],
                                    ...pagination,
                                }}
                            />
                        )}
                    </div>
                </div>
            </Modal>

            <Modal
                width={350}
                visible={islVisible}
                footer={null}
                onCancel={() => {
                    setIslVisible(false)
                    setPolling(false)
                }}
            >
                <Spin spinning={ticketLoading} tip="Loading...">
                    <Skeleton paragraph={{ rows: 6 }} loading={ticketLoading}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                style={{
                                    width: '300px',
                                }}
                                src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=` + ticket}
                                alt=""
                            />
                            <span>微信扫描二维码关注云豪车服管家公众号</span>
                        </div>
                    </Skeleton>
                </Spin>
            </Modal>

            <Modal title="绑定新成员" width={350} visible={addMemberVisible} onOk={() => handleToBind()} onCancel={() => setAddMemberVisible(false)}>
                <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="请选择成员"
                    notFoundContent={memberInfo.fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onChange={handleChangeCustomer}
                    onFocus={() => memberQuery()}
                    onSearch={memberQuerys}
                    style={{ width: '100%' }}
                    value={addMember}
                >
                    {memberInfo.data.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                            {item.text}
                        </Select.Option>
                    ))}
                </Select>
            </Modal>

            {/* <Modal
                visible={memberVisible}
                title={isMemberEdit ? "成员修改" : "成员新增"}
                centered={true}
                onOk={() => onMemberSubmit()}
                onCancel={() => setMemberVisible(false)}
            >
                <Form form={formMember} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="姓名" name="userName" rules={[{ required: true, message: "请输入姓名" }]}>
                                <Input placeholder="请输入姓名" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="手机号"
                                name="userPhone"
                                rules={[
                                    { required: true, message: "请输入手机号" },
                                    {
                                        pattern: /^1\d{10}$/,
                                        message: "请输入正确手机号格式",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入手机号" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="职务" name="userJobTitle" rules={[{ required: true, message: "请选择职务" }]}>
                                <Select showSearch allowClear placeholder="请选择职务" style={{ width: "100%" }}>
                                    {getPcodeDict("101").children.map((item) => (
                                        <Select.Option value={item.dictCode} key={item.id}>
                                            {item.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="分成比例"
                                name="userDistributionPoints"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入分成比例",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入分成比例" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item
                                label="登录账户"
                                name="userAccount"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入登录账户",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入登录账户" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="密码"
                                name="userPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "请输入密码",
                                    },
                                ]}
                            >
                                <Input placeholder="请输入密码" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item name="disableFlag" label="成员状态" valuePropName="checked">
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="实名认证" name="idCardImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={idFileList}
                                    onPreview={(file) => {
                                        const md5 = file.response.retData
                                        setpreviewImage({ visible: true, md5 })
                                    }}
                                    onChange={handleIDChange}
                                >
                                    {idFileList.length >= 1 ? null : <Button size="small">上传身份证</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="成员头像" name="headImg" valuePropName="fileList" getValueFromEvent={normFile}>
                                <Upload
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={headFileList}
                                    onPreview={(file) => {
                                        const md5 = file.response.retData
                                        setpreviewImage({ visible: true, md5 })
                                    }}
                                    onChange={handleImgChange}
                                >
                                    {headFileList.length >= 1 ? null : <Button size="small">上传成员头像</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal> */}
        </>
    )
}
export default TeamEdit
