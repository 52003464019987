import React,{useContext, useEffect} from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { MyContext } from "@/store/myContext"
import { useSaleDcModel } from "../seleDcModel"

export default () => {
    const { state } = useContext(MyContext)
    const { loadData,  } = useSaleDcModel()
    useEffect(() => {
        if (state.activeMenu === "saleDc") {
            loadData()
        }
    }, [state])
    return (
        <>
            <PageHeader />
            <DataBox />
        </>
    )
}