import React, { useRef } from "react";
import { message } from "antd";
import YhDrawer from "@/erp_subpackage/components/YhDrawer";
import { YhForm } from "@/erp_subpackage/components/YhFormBox";
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { debounce } from "lodash";
import { useWarehouseModel } from "../warehouseModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"

export const AddWarehouseDrawer = () => {
    const { addWareVisible: visible, shopName, setAddWaerVisible, insertWarehouse } = useWarehouseModel();
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const formRef = useRef();

    const onCancel = () => {
        setAddWaerVisible(false);
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current.validateFields().then(res => {
            insertWarehouse({
                ...res,
                typeName: getTreeNodeName(res.typeCode)
            }).then(res => {
                res && onCancel()
            })
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }

    const formConfig = {
        formRef,
        labelCol: { span: 4 },
        formValues: { shopName },
        items: [
            {
                type: "Input",
                fieldLabel: "仓库编码",
                fieldName: "code",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "仓库名称",
                fieldName: "name",
                span: 24,
                rules: [{ required: true, message: "请输入仓库名称" }]
            }, {
                type: "Select",
                fieldLabel: "仓库类型",
                fieldName: "typeCode",
                options: getSelectedDictOne(DictCodeEnmu.WAREHOUSE_TYPE),
                rules: [{ required: true, message: "仓库类型" }],
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
                span: 24,
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "机构负责人",
                fieldName: "orgContact",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "仓库负责人",
                fieldName: "contact",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "电话",
                fieldName: "phone",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "地址",
                fieldName: "address",
                span: 24,
            }, {
                type: "Input",
                fieldLabel: "创建时间",
                fieldName: "createTime",
                span: 24,
                disable: true
            }, {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
                span: 24,
            }
        ]
    };

    return (
        <YhDrawer
            title="仓库新增"
            visible={visible}
            onClose={onCancel}
            okText="保存"
            width={600}
            onOk={debounce(() => { onSubmit() }, 900)}
        >
            <div style={{ padding: "20px 36px" }}><YhForm {...formConfig} /></div>
        </YhDrawer>
    )
}