import React, { useState, useEffect, useRef, useContext } from "react"
import { Form, Input, Select, InputNumber, DatePicker } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import moment from "moment"
const { Option } = Select
const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            if (inputRef && inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        })
    }

    const save = async (e) => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleSave({ ...record, ...values })
        } catch (errInfo) {
            console.log("数据保存失败", errInfo)
        }
    }
    const inputTypes = (str, record) => {
        if (str === "num") {
            return <InputNumber ref={inputRef} min={0} onBlur={save} />
        } else if (str === "price" || str === "lateSalePrice") {
            return <InputNumber ref={inputRef} min={0} onBlur={save} />
        } else {
            return <Input ref={inputRef} onBlur={save} />
        }
    }
    const inputRules = (str) => {
        if (str === "num") {
            return [
                {
                    required: true,
                    message: `数值不可为空`,
                },
                {
                    pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                    message: "请输入数字且可保留两位小数",
                },
            ]
        } else if (str === "price") {
            return [
                {
                    required: true,
                    message: `数值不可为空`,
                },
                {
                    validator: (rule, value, callback) => {
                        if (value > 0) {
                            if (form.getFieldsValue(["lateSalePrice"]).lateSalePrice < value) {
                                callback(`销售价需要大于采购价`)
                            } else {
                                callback()
                            }
                        } else {
                            callback(`采购价需要大于0`)
                        }
                    },
                },
            ]
        } else if (str === "lateSalePrice") {
            return [
                {
                    required: true,
                    message: `数值不可为空`,
                },
                {
                    validator: (rule, value, callback) => {
                        console.log(value)
                        console.log(form.getFieldsValue(["price"]).price)
                        if (form.getFieldsValue(["price"]).price > value) {
                            callback(`销售价需要大于采购价`)
                        } else {
                            callback()
                        }
                    },
                },
            ]
        }
    }
    let childNode = children
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={inputRules(dataIndex)}
            >
                {inputTypes(dataIndex, record)}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

export { EditableRow, EditableCell }
