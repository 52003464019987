import React from "react";
import { TopButs } from "./TopButs";
import { FormBox } from "./FormBox";
import { TableBox } from "./TableBox";
import { FixedBottom } from "./FixedBottom";
import { MaterialDialog } from "./MaterialDialog";
import { Print } from "./Print";
import { StockDialog } from "./StockDialog";

export default (props) => {
    const { mode } = props;
    return (
        <div className="yh-detail-box">
            {mode !== "add" && <TopButs {...props} />}
            <FormBox {...props} />
            <TableBox {...props} />
            <FixedBottom {...props} />
            <MaterialDialog {...props} />
            <StockDialog {...props} />
            <Print />
        </div>
    )
}

