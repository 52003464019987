import React, { useEffect, useState, useContext } from "react"
import { Button, Form, Input, Col, Row, message, Select, Card, Upload } from "antd"
import {
    getMaintenancePackageOne,
    maintenancePackageInsert,
    maintenancePackageEdit,
    getModuleList,
    getRuleJoinList,
    deleteRuleJoinOne,
    copyRuleJoinOne,
} from "@/service/setMealService"
import BraftEditor from "braft-editor"
import { ContentUtils } from "braft-utils"
import "braft-editor/dist/index.css"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import ListComponent from "@/components/listComponent"
import { MyContext } from "@/store/myContext"

//套餐详情
export default ({ modeKey, mode, rowInfo }) => {
    const { dispatch, detailsPage, Option } = useContext(MyContext)
    const [form] = Form.useForm()
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null)) //富文本
    // const [receivableMonye, setReceivablesMonye] = useState({ itemAccount: 0, material: 0 }); //每次变动计算表单实收金额
    const [moduleList, setModuleList] = useState([]) // 模块列表
    const [saveLoading, setSaveLoading] = useState(false) //保存按钮

    //编辑回显
    useEffect(() => {
        if (mode === "edit") {
            handleModule()
            packDetailsRuleLoadData()
            getMaintenancePackageOne(rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                let result = res.retData
                form.setFieldsValue({
                    packageName: result.packageName,
                    packageExplain: result.packageExplain,
                    packageTotalAmount: result.packageTotalAmount,
                    modelId: result.modelId,
                })
                result.operationStandardDesc && setEditorState(BraftEditor.createEditorState(result.operationStandardDesc))
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
        }
    }, [])

    //获取模块列表
    const handleModule = () => {
        let data = {
            pageSize: 1000,
            pageNum: 1,
            searchItem: {},
        }
        getModuleList(data).then((res) => {
            // if (res && res.retCode === 200) {
            setModuleList(res.retData.list)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    // //应收金额 套餐价格
    // useEffect(() => {
    //     let all = (receivableMonye.itemAccount + receivableMonye.material).toFixed(2);
    //     form.setFieldsValue(({
    //         packageTotalAmount: all
    //     }));
    // }, [receivableMonye])

    //富文本上传图片
    const uploadHandler = (param) => {
        if (param.file.status === "done") {
            setEditorState(
                ContentUtils.insertMedias(editorState, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
        }
    }
    const extendControls = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    action={`${fileUploadUrl}upload/image_old`}
                    headers={{ authorization: getToken() }}
                    showUploadList={false}
                    onChange={uploadHandler}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        {/* <Icon type="picture" theme="filled" /> */}上传图片
                    </button>
                </Upload>
            ),
        },
    ]
    //富文本配置
    const controls = [
        "undo",
        "redo",
        "separator",
        "font-size",
        "line-height",
        "letter-spacing",
        "separator",
        "text-color",
        "bold",
        "italic",
        "underline",
        "strike-through",
        "separator",
        "superscript",
        "subscript",
        "remove-styles",
        "emoji",
        "separator",
        "text-indent",
        "text-align",
        "separator",
        "headings",
        "list-ul",
        "list-ol",
        "blockquote",
        "code",
        "separator",
        "link",
        "separator",
        "hr",
        "separator",
        // 'media', 'separator',
        "clear",
    ]

    //表单提交
    const onsubmit = () => {
        setSaveLoading(true)
        // let items = itemRef.current.upItemData();
        // let parts = partRef.current.upPartData();
        form.validateFields()
            .then((res) => {
                let data = {
                    ...res,
                    operationStandardDesc: editorState.toHTML(),
                    // parts: parts.map(item => { let { partName, partCode, ...parms } = item; return { ...parms, id: item.partId ? item.partId : null, partId: item.id } }),
                    // items: items.map(item => { let { itemName, itemCode, ...parms } = item; return { ...parms, id: item.itemId ? item.itemId : null, itemId: item.id } })
                }
                if (mode === "insert") {
                    maintenancePackageInsert(data)
                        .then((res) => {
                            // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("新增成功")
                            // } else {
                            //     message.warning(res.retMsg)
                            //     setSaveLoading(false)
                            // }
                        })
                        .catch((err) => {
                            // message.error(err.retMsg)
                            setSaveLoading(false)
                        })
                } else if (mode === "edit") {
                    maintenancePackageEdit({ id: rowInfo.id, ...data })
                        .then((res) => {
                            // if (res && res.retCode === 200) {
                            handleCancel()
                            message.success("编辑成功")
                            // } else {
                            //     message.warning(res.retMsg)
                            //     setSaveLoading(false)
                            // }
                        })
                        .catch((err) => {
                            // message.error(err.retMsg)
                            setSaveLoading(false)
                        })
                }
            })
            .catch((err) => {
                message.warning("请检查必填项")
                setSaveLoading(false)
            })
    }

    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "setMealManage",
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    /**
     * 规则列表
     */
    const [ruleList, setRuleList] = useState([])
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标

    //套餐详情下面的规则匹配列表
    const packDetailsRuleLoadData = () => {
        let data = {
            searchItem: { packageId: rowInfo.id },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getRuleJoinList(data).then((res) => {
            // if (res && res.retCode === 200) {
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setRuleList(res.retData.list)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    const columns = [
        {
            title: "规则名称",
            align: "center",
            dataIndex: "ruleName",
        },
        {
            title: "作业项目",
            align: "center",
            dataIndex: "itemNames",
        },
        {
            title: "配件项目",
            align: "center",
            dataIndex: "partNames",
        },
        {
            title: "价格",
            align: "center",
            dataIndex: "ruleActualAmount",
        },
        {
            title: "更新时间",
            align: "center",
            dataIndex: "updateTime",
        },
    ]
    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "规则新增",
            edit: "规则编辑",
        }
        let key = `${modeKey}/ruleDetails/${mode}`
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的规则")
                return
            }
            key += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: key,
            content: detailsPage.RuleDetails({
                modeKey: key,
                parentKey: modeKey,
                mode,
                rowInfo: selectInfo.rowInfo,
                parentRowInfo: rowInfo,
                packDetailsRuleListApi: packDetailsRuleLoadData,
                ruleList: ruleList,
            }),
        })
    }
    //复制 或 删除
    const handleCopyOrDel = (str) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要操作的数据")
            return
        }
        if (str === "delete") {
            deleteRuleJoinOne(selectInfo.rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                packDetailsRuleLoadData()
                message.success("删除成功")
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
        } else {
            copyRuleJoinOne(selectInfo.rowInfo.id).then((res) => {
                // if (res && res.retCode === 200) {
                packDetailsRuleLoadData()
                message.success("复制成功")
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
        }
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleCopyOrDel("delete"),
            content: "删除",
        },
        {
            click: () => handleCopyOrDel("copy"),
            content: "复制",
        },
    ]
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">维修套餐</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>维修套餐详情</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={onsubmit} loading={saveLoading}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row>
                            <Col span={6} xxl={4}>
                                <Form.Item
                                    label="套餐名称"
                                    name="packageName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入套餐名称",
                                        },
                                    ]}
                                >
                                    <Input placeholder="套餐名称" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={6} xxl={4}>
                                <Form.Item
                                    label="套餐价格"
                                    name="packageTotalAmount"
                                >
                                    <Input placeholder="0.00" disabled />
                                </Form.Item>
                            </Col> */}
                            <Col span={6} xxl={4}>
                                <Form.Item
                                    label="所属模块"
                                    name="modelId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择所属模块",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="请选所属模块"
                                        optionFilterProp="children"
                                        onFocus={() => handleModule()}
                                        style={{ width: "100%" }}
                                    >
                                        {moduleList.map((item) => (
                                            <Option value={item.id} key={item.id}>
                                                {item.modelName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} xxl={8}>
                                <Form.Item label="套餐说明" name="packageExplain" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                                    <Input placeholder="套餐说明" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Card title="作业标准说明:" size="small">
                                    <BraftEditor value={editorState} controls={controls} onChange={(e) => setEditorState(e)} extendControls={extendControls} />
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            {mode === "edit" && (
                <ListComponent
                    title="规则匹配列表"
                    buttons={topButtons}
                    columns={columns}
                    dataSource={ruleList}
                    selectInfo={selectInfo}
                    setSelectInfo={(obj) => setSelectInfo(obj)}
                    scroll={{ y: 500 }}
                />
            )}
        </>
    )
}
