import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Input, Select, Form, Col, Row } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import moment from 'moment'
import { getOne } from "@/service/customerService"
import { platformUserList } from "@/service/customerService"
const { Option } = Select
export default (props) => {
    const { mode,rowInfo } = props
    const [form] = Form.useForm()
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    const [formData, setFormData] = useState(null);
    //签约类型
    const [newList, setnewList] = useState([]);
    //大包模式
    const [bagMode,setBagMode]=useState([])
    //计算方式
    const [chargeMode,setChargeMode]=useState([])
    useEffect(()=>{
        getOne(rowInfo.id).then((res)=>{
            let list  =res.retData
         if(list){ 
            let data2={ pageNum: 1, pageSize: 15, searchItem: {nickname:''}}
            platformUserList(data2).then((res)=>{
                let {retData:{list:name}}=res
                let newData = name.map(item => {
                    if(item.id===list.signManager){
                        return item.nickname
                    }
                }).filter((element)=>element)
                console.log(newData)
                list={...list,billDate:list.billDate===5?'每月5号':'每月25号',signManager:newData}
                form.setFieldsValue(list);
            
            })

               
            }
        })
        signTypeList()
        bigBagModeList()
        chargeModeList()
        
    },[])
    
     //签约类型
     const signTypeList =()=>{
        setnewList(getPcodeDict('046').children.map(item=>{
            return ({text:item.title,value:item.dictCode,key:item.id})
        }))
    }
    //大包模式
    const bigBagModeList=()=>{
        setBagMode(getPcodeDict('047').children.map(item=>{
            return ({text:item.title,value:item.dictCode,key:item.id})
        }))
    }
    //计算里程
    const chargeModeList=()=>{
        setChargeMode(getPcodeDict('048').children.map(item=>{
            return ({text:item.title,value:item.dictCode,key:item.id})
        }))
    }

    return (
        <Box>
            <Form form={form} {...formItemLayout}>
                <div className="type">签约信息</div>
                <Row gutter={20}>
                    <Col span={6}>
                        <Form.Item label="签约类型·" name="signType">
                            <Select
                                disabled={true}
                                style={{ width: "100%" }}
                            >
                                {newList.map((item) => (
                                    <Option value={item.value} key={item.value}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="大包模式" name="bigBagMode">
                        <Select
                                disabled={true}
                                style={{ width: "100%" }}
                            >
                                {bagMode.map((item) => (
                                    <Option value={item.value} key={item.value}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="计费方式" name="billMethod">
                        <Select
                                disabled={true}
                                style={{ width: "100%" }}
                            >
                                {chargeMode.map((item) => (
                                    <Option value={item.value} key={item.value}>
                                        {item.text}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="签约里程"name="signMileage">
                            <Input  disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="计费价格"name="billPrice">
                            <Input  disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="出账日期" name="billDate">
                            <Input  disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="签约日期" name="signStartTime">
                        <Input  disabled={true} />
                            {/* <DatePicker
                                style={{ width: "100%" }}
                                showTime={{
                                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                                }}
                                disabled={true}
                            /> */}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="截止日期" name="signEndTime">
                        <Input  disabled={true} />
                            {/* <DatePicker
                                style={{ width: "100%" }}
                                showTime={{
                                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                                }}
                                disabled={true}
                            /> */}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="签约经理" name="signManager">
                            <Input  disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="协议单号" name="contractCode">
                            <Input  disabled={true} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={6}>
                        <Form.Item label="司机电话" name="driverPhone">
                            <Input placeholder="请输入司机电话" disabled={isLook(mode)} />
                        </Form.Item>
                    </Col> */}
                    
                </Row>
            </Form>
        </Box>
       
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin: 20px 0;
    .type {
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
`