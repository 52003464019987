import React, { FC } from "react";
import "./style.less";
import { PlusOutlined, CloseOutlined, RollbackOutlined } from "@ant-design/icons";


// export type IYhButtonProps = {
//     type: "add" | "delete" | "restore" | "disabled"
//     txt: string,
//     click: () => void,
//     disabled?: boolean
// }

export const YhBut = ({
    type,
    txt,
    click,
    disabled = false,
}) => {
    const colors = {
        "add": "#028e19",
        "delete": "#d81e06",
        "restore": "#4fb4ec",
        "disabled": "#919292",   //未激活
    }

    const Icon = (type) => {
        switch (type) {
            case "add":
                return <PlusOutlined style={{ color: "#fff", fontSize: "14px" }} />
            case "delete":
                return <CloseOutlined style={{ color: "#fff", fontSize: "14px" }} />
            case "restore":
                return <RollbackOutlined style={{ color: "#fff", fontSize: "14px" }} />
            default:
                return <></>
        }
    }

    return <div className={`yh-but ${disabled ? 'yh-but-disabled' : ''}`} onClick={() => { !disabled && click() }}>
        <span className="yh-but-icon" style={{ background: disabled ? "#919292" : colors[type] }}>
            {Icon(type)}
        </span>
        <span className="yh-but-txt">{txt}</span>
    </div>
}