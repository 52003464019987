import React, { useState, useEffect } from "react"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { Table } from 'antd'
import { usePrebookModel } from "../prebookModel";
import YhIcon from "@/components/pubIcon"

export const Material = ({ modeKey, mode, rowInfo, dispatch, formRef }) => {

    const { getMaterialList, setMaterialList } = usePrebookModel();
    const [staticInfo, setStaticInfo] = useState({ materialAmount: 0, materialQuantity: 0, enough: 0, notenough: 0 })

    useEffect(() => {

        let materialAmount = 0;
        let enough = 0;
        let notenough = 0;
        getMaterialList(mode) && getMaterialList(mode).forEach(e => {
            materialAmount = Number(materialAmount) + Number(e.actualAmount);
            materialAmount = materialAmount.toFixed(2);

            enough += e.availableStock < e.materialNumber ? 0 : 1;
            notenough += e.availableStock < e.materialNumber ? 1 : 0;
        });
        setStaticInfo({ materialAmount: materialAmount, materialQuantity: getMaterialList(mode) && getMaterialList(mode).length, enough: enough, notenough: notenough });
    }, [getMaterialList(mode)])

    const columns = [
        {
            title: '序号',
            width: 50,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "商品编号",
            width: 200,
            align: "center",
            dataIndex: "materialCode",
            sorter: (a, b) => {
                return a.materialCode.charCodeAt(0) - b.materialCode.charCodeAt(0)
            }
        },
        {
            title: "商品名称",
            width: 200,
            align: "center",
            dataIndex: "materialName",
            sorter: (a, b) => {
                return a.materialName?.localeCompare(b.materialName, 'zh-Hans-CN', { sensitivity: 'accent' })
            }
        },
        {
            title: "品牌",
            width: 120,
            align: "center",
            dataIndex: "brandName",
        },
        {
            title: "单位",
            width: 120,
            align: "center",
            dataIndex: "unitName",
        },
        {
            title: "当前库存",
            width: 120,
            align: "center",
            dataIndex: "availableStock",
        },
        {
            title: "数量",
            width: 120,
            align: "center",
            dataIndex: "materialNumber",
        },
        {
            title: "是否满足",
            width: 150,
            align: "center",
            dataIndex: "enough",
            defaultSortOrder: 'ascend',
            render: (text, record) => {
                return record.availableStock < record.materialNumber ? <span style={{ color: 'red' }}>否</span> : '是';
            },
            sorter: (a, b) => {
                return a.availableStock - a.materialNumber
            }
        },
        {
            title: "销售单价",
            width: 120,
            align: "center",
            dataIndex: "salePrice",
        },
        {
            title: "总计",
            width: 120,
            align: "center",
            dataIndex: "materialAmount",
        },
        {
            title: "优惠",
            width: 120,
            align: "center",
            dataIndex: "preferentialAmount",
        },
        {
            title: "折扣",
            width: 120,
            align: "center",
            dataIndex: "materialDiscount",
        },
        {
            title: "总金额",
            width: 120,
            align: "center",
            dataIndex: "actualAmount",
        },
        {
            title: "备注",
            width: 120,
            align: "center",
            dataIndex: "remark",
        },
        {
            title: "操作",
            width: 120,
            align: "center",
            dataIndex: "operation",
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteOne(record)} />
            },
        },

    ]

    const deleteOne = (record) => {
        let list = getMaterialList(mode).filter((item) => item.id !== record.id);
        setMaterialList(mode, list);
    };

    const action =
        <>
            <div style={{ display: 'inline-block' }}>满足：{staticInfo.enough}项</div>
            <div style={{ display: 'inline-block', marginLeft: 20 }}>不满足：<span style={{ color: 'red' }}>{staticInfo.notenough}项</span></div>
        </>

    return (
        <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: '10px' }}>
            <div style={{ height: 0, position: 'relative', float: 'left', top: -35, left: 100, }}>
                总金额￥<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.materialAmount}</span>元（{staticInfo.materialQuantity}项）
            </div>
            <Table
                size="small"
                columns={columns}
                dataSource={getMaterialList(mode)}
                rowKey={(record) => record.id}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: false,
                    showTotal: () => `共${getMaterialList(mode).length}条`,
                    position: ["bottomCenter"],
                }}
            />
        </YhBox>
    )
}
