import React, { useEffect, useState } from "react"
import { Modal, Button, Input, message, Form, Checkbox, Radio } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import { packageRuleSave, getRuleOne } from "@/service/setMealService"

const CheckboxGroup = Checkbox.Group

/**
 * @param {Boolean} visible 弹窗开关
 * @param {String} mode  区别新增 编辑 查看
 * @param {String} ruleId  规则id
 * @param {Function} loadDataApi  列表加载
 */

//车型匹配详情
export default ({ visible, onClose, mode, ruleId, loadDataApi }) => {
    const [form] = Form.useForm()
    const [dictValue, setDictValue] = useState(null) //多选框：从字典获取到的数据
    useEffect(() => {
        let obj = {
            ruleBrand: getPcodeDict("022001").children.map((item) => ({
                label: item.title,
                value: item.dictCode,
            })), //适用品牌
            ruleModel: getPcodeDict("022002").children.map((item) => ({
                label: item.title,
                value: item.dictCode,
            })), //车型
            ruleEmission: getPcodeDict("022003").children.map((item) => ({
                label: item.title,
                value: item.dictCode,
            })), //排放
            ruleEngineModel: getPcodeDict("022004").children.map((item) => ({
                label: item.title,
                value: item.dictCode,
            })), //发动机型号
            ruleEngine: getPcodeDict("022005").children.map((item) => ({
                label: item.title,
                value: item.dictCode,
            })), //发动机
            ruleThreeGuarantees: getPcodeDict("022006").children.map((item) => ({ label: item.title, value: item.dictCode })), //三包/收费
        }
        setDictValue(obj)
        echoForm()
    }, [])

    //编辑回显
    const echoForm = () => {
        if (mode === "edit" || mode === "look") {
            getRuleOne(ruleId)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        let result = res.retData
                        form.setFieldsValue({
                            ...result,
                            ruleBrand: result.ruleBrand ? result.ruleBrand.split(",") : [],
                            ruleModel: result.ruleModel ? result.ruleModel.split(",") : [],
                            ruleEmission: result.ruleEmission ? result.ruleEmission.split(",") : [],
                            ruleEngineModel: result.ruleEngineModel ? result.ruleEngineModel.split(",") : [],
                            ruleEngine: result.ruleEngine ? result.ruleEngine.split(",") : [],
                            ruleThreeGuarantees: result.ruleThreeGuarantees ? result.ruleThreeGuarantees.split(",") : [],
                        })
                    // } else {
                    //     message.warning(res.retMsg)
                    // }
                })
                // .catch((err) => message.error(err.retMsg))
        } else {
            // form.setFieldsValue({ ruleThreeGuarantees: "022006002" });
        }
    }

    const onsubmit = () => {
        form.validateFields().then((res) => {
            let data = {
                ...res,
                // ruleThreeGuarantees: res.ruleThreeGuarantees ? [res.ruleThreeGuarantees] : null
            }
            // console.log(data)
            if (mode === "insert") {
                packageRuleSave(data)
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            loadDataApi && loadDataApi()
                            onClose()
                            message.success("车型新增成功")
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                    // .catch((err) => message.error(err.retMsg))
            } else if (mode === "edit") {
                packageRuleSave({ ...data, id: ruleId })
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            loadDataApi && loadDataApi()
                            onClose()
                            message.success("车型编辑成功")
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                    // .catch((err) => message.error(err.retMsg))
            }
        })
    }
    //全部选中
    const handleCheckedAll = (str) => {
        form.setFieldsValue({
            [str]: dictValue[str]?.map((item) => item.value),
        })
    }
    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 18,
        },
    }
    let tabName = {
        insert: "新增",
        edit: "编辑",
        look: "查看",
    }
    return (
        <Modal
            title={`车型匹配${tabName[mode]}`}
            width={800}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button onClick={onClose} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={onsubmit} key="submit" disabled={mode === "look" ? true : false}>
                    确认
                </Button>,
            ]}
        >
            <Form form={form} {...formItemLayout}>
                <Form.Item label="规则名称" name="ruleName" rules={[{ required: true, message: "请选择所属模块" }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="适用品牌"
                    extra={
                        <span
                            className="added-but1"
                            onClick={() => {
                                handleCheckedAll("ruleBrand")
                            }}
                        >
                            全选
                        </span>
                    }
                    name="ruleBrand"
                    // rules={[{ required: true, message: "请选择所属模块" }]}
                >
                    <CheckboxGroup options={dictValue?.ruleBrand} />
                </Form.Item>
                <Form.Item
                    label="车型"
                    extra={
                        <span
                            className="added-but1"
                            onClick={() => {
                                handleCheckedAll("ruleModel")
                            }}
                        >
                            全选
                        </span>
                    }
                    name="ruleModel"
                >
                    <CheckboxGroup options={dictValue?.ruleModel} />
                </Form.Item>
                <Form.Item
                    label="排放"
                    extra={
                        <span
                            className="added-but1"
                            onClick={() => {
                                handleCheckedAll("ruleEmission")
                            }}
                        >
                            全选
                        </span>
                    }
                    name="ruleEmission"
                >
                    <CheckboxGroup options={dictValue?.ruleEmission} />
                </Form.Item>
                <Form.Item
                    label="发动机型号"
                    extra={
                        <span
                            className="added-but1"
                            onClick={() => {
                                handleCheckedAll("ruleEngineModel")
                            }}
                        >
                            全选
                        </span>
                    }
                    name="ruleEngineModel"
                >
                    <CheckboxGroup options={dictValue?.ruleEngineModel} />
                </Form.Item>
                <Form.Item
                    label="发动机"
                    extra={
                        <span
                            className="added-but1"
                            onClick={() => {
                                handleCheckedAll("ruleEngine")
                            }}
                        >
                            全选
                        </span>
                    }
                    name="ruleEngine"
                >
                    <CheckboxGroup options={dictValue?.ruleEngine} />
                </Form.Item>
                <Form.Item
                    label="是否三包"
                    extra={
                        <span
                            className="added-but1"
                            onClick={() => {
                                handleCheckedAll("ruleThreeGuarantees")
                            }}
                        >
                            全选
                        </span>
                    }
                    name="ruleThreeGuarantees"
                >
                    <CheckboxGroup options={dictValue?.ruleThreeGuarantees} />
                </Form.Item>
                {/* <Form.Item
                    label="是否三包"
                    name="ruleThreeGuarantees"
                >
                    <Radio.Group options={dictValue?.ruleThreeGuarantees} />
                </Form.Item> */}
            </Form>
        </Modal>
    )
}
