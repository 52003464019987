import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useWarehouseModel } from "../warehouseModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

export function PageHeader() {
    const {
        searchParam, organType,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useWarehouseModel();
    // const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);
    const { getSelectedDictOne } = useDictModel(() => []);

    const searchFields = [
        // ...(organType ? [{
        //     type: "Select",
        //     placeholder: "所属机构",
        //     fieldName: "shopId",
        //     options: orgDs
        // }] : []),
        {
            type: "Select",
            placeholder: "仓库类型",
            fieldName: "typeCode",
            options: [...getSelectedDictOne(DictCodeEnmu.WAREHOUSE_TYPE), { label: "全部仓库", value: "全部仓库" }]
        },
        {
            type: "Input",
            placeholder: "仓库名称",
            fieldName: "name"
        }
    ]

    return (
        <YhPageHeader
            title="仓库管理"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}