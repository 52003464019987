import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import ItemDrawer from "@/components/pubPartDrawer/itemDrawer" //项目弹框
import { EditableRow, EditableCell } from "./pubEditble"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"


//物料
export default ({ mode, materialRef, initData }) => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const [visible, setVisible] = useState(false) //弹框
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //维修物料的总价

    useEffect(() => {
        if (Object.keys(initData).length > 0) {
            const {parts,partAmount} = initData
            setData(parts ? parts : [])
            setTotalPrice(partAmount ? partAmount : "0.00")
        }
    }, [initData])

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.partAmount)
        })
        // onReceivables({ itemAccount: null, material: num })
        setTotalPrice(num.toFixed(2))
    }, [data])

    //暴露给父组件的方法
    useImperativeHandle(materialRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upMaterialData: () => {
            return data
        },
    }))

    //新增确定
    const setPartList = (selectPart) => {
        if (selectPart.length) {
            let newArr = []
            selectPart.forEach((item) => {
                let unselect = item
                let isSelect = data.find((item) => item.partId === unselect.id)
                if (isSelect === undefined) {
                    newArr.push({
                        partId: unselect.id,
                        partCode: unselect.partCode,
                        partName: unselect.partName,
                        partBrand: unselect.partBrand,
                        partUnit: unselect.partUnit, //单位
                        partRetailPrice: unselect.partRetailPrice, //单价
                        partNumber: 1, //数量
                        partAmount: unselect.partRetailPrice * 1, //总计
                        remark: unselect.remark, //备注
                    })
                }
            })
            setData([...data, ...newArr])
        }
        setVisible(false)
    }
    /**
     * 以下是页面展示
     */
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning("请选择你要删除的维修项目")
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.partId) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success("批量删除成功")
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex = newData.findIndex((item) => item.partId === row.partId)
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
    }

    //是否是查看
    const isMode = () => {
        return mode === "look" ? false : true
    }
    //table配置
    const columns = [
        {
            title: "商品编号",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "partCode",
        },
        {
            title: "商品名称",
            align: "center",
            width: 200,
            fixed: "left",
            dataIndex: "partName",
        },
        {
            title: "配件属性",
            align: "center",
            width: 200,
            fixed: "left",
            dataIndex: "partAttr",
        },
        {
            title: "单位",
            align: "center",
            width: "120px",
            dataIndex: "partUnit",
        },
        {
            title: "规格",
            align: "center",
            width: "120px",
            dataIndex: "partSpec",
        },
        {
            title: "品牌",
            align: "center",
            width: 200,
            dataIndex: "partBrand",
        },
        {
            title: "使用车型",
            align: "center",
            width: 200,
            dataIndex: "partApplicableModel",
        },
        {
            title: "零售价",
            align: "center",
            width: 185,
            dataIndex: "partRetailPrice",
            editable: mode === "look" ? false : true,
        },
        {
            title: "数量",
            align: "center",
            width: "120px",
            dataIndex: "partNumber",
            editable: mode === "look" ? false : true,
        },

        {
            title: "总计",
            align: "center",
            width: 185,
            dataIndex: "partAmount",
        },
        {
            title: "备注",
            align: "center",
            width: 160,
            dataIndex: "remark",
            editable: isMode(),
        },
        !isMode()
            ? {}
            : {
                  title: "操作",
                  align: "center",
                  width: "80px",
                  dataIndex: "operation",
                  render: (_, record) => {
                      return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaterialOne(record)} />
                  },
              },
    ]

    //单元格编辑保存
    const materialHandleSave = (row) => {
        //每次编辑计算总计
        row.partAmount = (row.partNumber * row.partRetailPrice).toFixed(2)
        const newData = [...data]
        const index = newData.findIndex((item) => row.partId === item.partId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    //导出模版
    const exportTemplete = async () => {
        setExportLoading(true)
        let data = {
            type: "get",
        }
        let resFlie = await download("/ci/order/part/export_template", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }


    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>维修物料</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isMode() && (
                        <div className="list-name-but">
                            <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={() => setVisible(true)} />
                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.partId}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
            {isMode() && <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setPartList} drawerMode="part" />}
            {
                        exportLoading && <Loading />
                    }
        </div>
    )
}
