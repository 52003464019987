// import {
//     RECEIVE_GOODSLIST,
//     REQUEST_GOODSLIST,
// } from "./actions";
import { menu, tabs } from "../pages/tabs"

const initialState = {
    panes: [], //打开的标签页列表
    activeMenu: "", //活动的菜单
    vehicleQuery: "", //全局查询客户下属车辆
    userQuery: "", //全局查找司机
    vehicleInfo: null, //订单编辑的车辆信息
    dictValueAll: {
        //存取页面所有用到的字典值
    },
    // isFetching: false,
    // goodsList: []
}

//查找路由路径 是否在菜单里面存在。
const forFind = (array, pathName) => {
    for (const item of array) {
        if (item.key === pathName) {
            return item
        }
        if (item.children && item.children.length > 0) {
            let forFindRet = forFind(item.children, pathName)
            if (forFindRet) {
                return forFindRet
            }
        }
    }
}
//tabs  删除
function removeTabsAction(state, action) {
    let activeMenu = state.activeMenu //展示的activeMenu
    let panes = state.panes.slice() //已有的tab状态
    let targetKey = action.targetKey //要删除的key

    if (action.activeMenu) {
        activeMenu = action.activeMenu
        //已经关闭的列表  重新push一个。
        if (!panes.find((i) => i.key === activeMenu)) {
            let activeObj = forFind(menu, activeMenu)
            panes.push({
                name: activeObj.name,
                key: activeMenu,
                content: tabs[activeObj.key],
            })
        }
        //从全局的过滤掉要删除的
        panes = panes.filter((item) => item.key !== targetKey)
    } else {
        //找到对应的下标
        let preIndex = panes.findIndex((item) => item.key === targetKey) - 1
        preIndex = Math.max(preIndex, 0)

        //从全局的过滤掉要删除的
        panes = panes.filter((item) => item.key !== targetKey)

        if (targetKey === activeMenu) {
            activeMenu = panes[preIndex] ? panes[preIndex].key : ""
        }
    }

    return { ...state, panes, activeMenu }
}

/**
 * @param {Object} state  全局状态
 * @param {Object} action  派发的参数
 *   *其中action里面的参数
 *   *@param {String} type  要触发的事件类型
 *   *@param {String} name  tabs的标签名字
 *   *@param {String} activeMenu  切换到要展示的tabs页签
 *   *@param {String} deleteKey  要删除的tabs页签
 *   *@param {ReactNode} content  react的组件
 */
//tabs 变换
function changeTabsAction(state, action) {
    //tab之间切换只需要传activeMenu,  添加tab需要传name名称，tabs没有的需要传content。
    let panes = action.deleteKey ? state.panes.filter((item) => item.key !== action.deleteKey) : state.panes.slice()
    let activeMenu = action.activeMenu
    let name = action.name
    // debugger
    if (!panes.find((i) => i.key === activeMenu)) {
        panes.push({
            name: name,
            key: activeMenu,
            content: action.content ? action.content : tabs[activeMenu],
        })
    }

    // debugger
    if (action.change && action.content && action.targetKey) {
        panes = panes.filter((item) => item.key !== action.targetKey)
        panes.push({
            name: name,
            key: activeMenu,
            content: action.content ? action.content : tabs[activeMenu],
        })
    }

    return {
        ...state,
        panes,
        activeMenu,
        vehicleQuery: action.vehicleQuery,
        userQuery: action.userQuery,
        vehicleInfo: action.vehicleInfo,
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        //tabs变换
        case "changeTabs":
            return changeTabsAction(state, action)
        //tabs 删除
        case "removeTabs":
            return removeTabsAction(state, action)
        case "changeDict":
            return { ...state }
        // case REQUEST_GOODSLIST:
        //     return Object.assign({}, state, {
        //         isFetching: true
        //     });
        // case RECEIVE_GOODSLIST:
        //     return Object.assign({}, state, {
        //         isFetching: false,
        //         goodsList: state.goodsList.concat(action.goodsList)
        //     });
        default:
            throw new Error("Unexpected action")
    }
}
export { initialState, reducer }
