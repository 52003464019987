import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message, Checkbox } from "antd"
import YhIcon from "@/components/pubIcon"
import { EditableRow, EditableCell } from "./pubEditble"
import { getPackageJoinItem } from "@/service/setMealService"
import ItemDrawer from "@/components/pubPartDrawer/itemDrawer" //项目弹框

//项目
export default ({
    packageJoinId,
    mode,
    itemRef,
    // onReceivables
}) => {
    const [visible, setVisible] = useState(false) //弹框
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //项目的总价
    const [maintenanceKeys, setMaintenanceKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualMoney)
        })
        // onReceivables({ itemAccount: num, material: null });
        setTotalPrice(num.toFixed(2))
    }, [data])

    useEffect(() => {
        if (mode !== "insert") {
            echoData()
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    //反显的数据查询
    const echoData = async (parentOrderId = "") => {
        let data = {
            pageSize: 1000,
            searchItem: {
                packageRuleJoinId: parentOrderId ? parentOrderId : packageJoinId,
            },
        }
        await getPackageJoinItem(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    let editArr = res.retData.list.map((item) => ({
                        ...item,
                        id: item.itemId,
                        itemId: item.id,
                        actualMoney: item.actualAmount, //总金额
                    }))
                    setData(editArr)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }

    //新增确定。 有选中的，过滤掉列表里面有的，在合并到一起。
    const setItemList = (selectItem) => {
        // console.log(selectItem)
        if (selectItem.length) {
            let newArr = []
            selectItem.forEach((sItem) => {
                let isSelect = data.find((item) => item.id === sItem.id)
                if (isSelect === undefined) {
                    newArr.push({
                        id: sItem.id,
                        itemCode: sItem.itemCode,
                        itemName: sItem.itemName,
                        itemWorkHours: sItem.itemWorkHours, //工时
                        workHoursPrice: sItem.workHoursPrice, //单价
                        itemAmount: sItem.itemAmount, //工时费
                        preferentialAmount: 0, //优惠
                        itemDiscount: "", //折扣
                        actualMoney: sItem.itemAmount, //总金额
                        giftFlag: "not_gift", //赠送：不赠送
                        selectFlag: "selected", //默认选中：选中
                    })
                }
            })
            setData([...data, ...newArr])
        }
        setVisible(false)
    }

    //批量删除多选的成员id
    const maintenanceSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaintenanceKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (maintenanceKeys.length === 0) {
            message.warning("请选择你要删除的维修项目")
            return
        }
        let arr = data.filter((item) => maintenanceKeys.indexOf(item.id) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaintenanceKeys([])
        message.success("批量删除成功")
    }
    //维修项目删除一条数据
    const deleteMaintenanceOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex = newData.findIndex((item) => item.id === row.id)
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
    }

    //是否赠送
    const handleCheck = (e, text, recordId) => {
        let checkedStr = e.target.checked ? "gift" : "not_gift"
        let newData = data.map((item) => {
            if (recordId === item.id) {
                return {
                    ...item,
                    giftFlag: checkedStr,
                    // workHoursPrice: 0,                  //单价
                    // itemAmount: 0.00,                   //工时费
                    preferentialAmount: 0, //优惠
                    itemDiscount: "", //折扣
                    actualMoney: e.target.checked ? "0.00" : (item.itemWorkHours * item.workHoursPrice).toFixed(2), //总金额
                }
            } else {
                return item
            }
        })
        // //赠送
        // if (e.target.checked) {
        //     newData = data.map(item => {
        //         if (recordId === item.id) {
        //             return {
        //                 ...item,
        //                 giftFlag: checkedStr,
        //                 // workHoursPrice: 0,                  //单价
        //                 // itemAmount: 0.00,                   //工时费
        //                 preferentialAmount: 0,                //优惠
        //                 itemDiscount: "",                      //折扣
        //                 actualMoney: "0.00",                //总金额
        //             }
        //         } else {
        //             return item;
        //         }
        //     })
        // } else {
        //     newData = data.map(item => ({
        //         ...item,
        //         giftFlag: recordId === item.id ? checkedStr : item.giftFlag,
        //         // workHoursPrice: 1,        //单价
        //         // itemAmount: 1,      //工时费
        //         preferentialAmount: 0,                //优惠
        //         itemDiscount: "",                      //折扣
        //         actualMoney: (item.itemWorkHours * item.workHoursPrice).toFixed(2),      //总金额
        //     }));
        // }
        setData(newData)
    }
    //默认选中
    const handleSelectCheck = (e, text, recordId) => {
        let checkedStr = e.target.checked ? "selected" : "unselected"
        let newData = data.map((item) => ({
            ...item,
            selectFlag: recordId === item.id ? checkedStr : item.selectFlag,
        }))
        setData(newData)
    }
    //暴露给父组件的方法
    useImperativeHandle(itemRef, () => ({
        //upData 就是暴露给父组件刷新保存后的的方法
        upData: (parentOrderId) => echoData(parentOrderId),
        //父组件保存的时候 获取所有编辑完后的数据
        upItemData: () => data,
    }))

    //选中展示table配置
    const columns = [
        {
            title: "项目编码",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            align: "center",
            // width: 200,
            fixed: "left",
            dataIndex: "itemName",
        },
        {
            title: "工时",
            align: "center",
            width: 100,
            dataIndex: "itemWorkHours",
            editable: true,
        },
        {
            title: "单价",
            align: "center",
            width: 185,
            dataIndex: "workHoursPrice",
            editable: true,
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "itemAmount",
        },
        {
            title: "优惠",
            align: "center",
            width: 185,
            dataIndex: "preferentialAmount",
            editable: true,
        },
        {
            title: "折扣",
            align: "center",
            width: 100,
            dataIndex: "itemDiscount",
            editable: true,
        },
        {
            title: "总金额",
            align: "center",
            width: 120,
            dataIndex: "actualMoney",
        },
        {
            title: "赠送",
            align: "center",
            width: 100,
            dataIndex: "giftFlag",
            render: (text, record) => <Checkbox defaultChecked={text === "gift" ? true : false} onChange={(e) => handleCheck(e, text, record.id)} />,
        },
        {
            title: "默认选中",
            align: "center",
            width: 100,
            dataIndex: "selectFlag",
            render: (text, record) => <Checkbox defaultChecked={text === "selected" ? true : false} onChange={(e) => handleSelectCheck(e, text, record.id)} />,
        },
        {
            title: "操作",
            align: "center",
            width: 80,
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaintenanceOne(record)} />
            },
        },
    ]

    //单元格编辑保存
    const handleSave = (row) => {
        // console.log(row)
        //项目工时费   工时*单价
        row.itemAmount = row.itemWorkHours * row.workHoursPrice
        if (row.giftFlag === "gift") {
            row.actualMoney = "0.00"
        } else {
            //项目小计  有折扣 ：工时费 *折扣   没有：工时费
            row.actualMoney = row.itemDiscount
                ? ((row.itemAmount * row.itemDiscount) / 10 - row.preferentialAmount).toFixed(2)
                : (row.itemAmount - row.preferentialAmount).toFixed(2)
        }
        const newData = [...data]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const maintenanceColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>作业项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    <div className="list-name-but">
                        <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={() => setVisible(true)} />
                        {!deleteAll ? (
                            <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                批量删除
                            </span>
                        ) : (
                            <>
                                <span className="list-delete" onClick={deleteTemaAll}>
                                    确认删除
                                </span>
                                <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                    取消
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id}
                components={components}
                columns={maintenanceColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 1300, y: 260 }}
                rowSelection={deleteAll ? maintenanceSelection : false}
            />
            <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setItemList} drawerMode="item" />
        </div>
    )
}
