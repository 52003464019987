import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message, Checkbox, Image, Upload, Button } from "antd"
import YhIcon from "@/components/pubIcon"
import { EditableRow, EditableCell } from "./pubEditble"
import { getPackageJoinPart } from "@/service/setMealService"
import ItemDrawer from "@/components/pubPartDrawer/itemDrawer" //项目弹框
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

//物料
export default ({
    packageJoinId,
    mode,
    partRef,
    // onReceivables
}) => {
    const [visible, setVisible] = useState(false) //弹框
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //物料的总价
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualMoney)
        })
        // onReceivables({ itemAccount: null, material: num });
        setTotalPrice(num.toFixed(2))
    }, [data])

    useEffect(() => {
        if (mode !== "insert") {
            echoData()
        }
    }, [])

    //物料回显
    const echoData = async (parentOrderId = "") => {
        let data = {
            pageSize: 1000,
            searchItem: {
                packageRuleJoinId: parentOrderId ? parentOrderId : packageJoinId,
            },
        }
        await getPackageJoinPart(data).then((res) => {
            // if (res && res.retCode === 200) {
            let editArr = res.retData.list.map((item) => ({
                ...item,
                id: item.partId,
                partId: item.id,
                totalAmount: item.partAmount, //总计
                actualMoney: item.actualAmount, //总金额
            }))
            setData(editArr)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    //新增确定
    const setPartList = (selectPart) => {
        // console.log(selectPart);
        if (selectPart.length) {
            let newArr = []
            selectPart.forEach((sPart) => {
                let isSelect = data.find((item) => item.id === sPart.id)
                if (isSelect === undefined) {
                    newArr.push({
                        id: sPart.id,
                        partCode: sPart.partCode,
                        partName: sPart.partName,
                        partImage: null,
                        unitPrice: sPart.partRetailPrice, //单价
                        partNumber: 1, //数量
                        totalAmount: sPart.partRetailPrice * 1, //总计
                        preferentialAmount: 0, //优惠
                        partDiscount: "", //折扣
                        actualMoney: sPart.partRetailPrice * 1, //总金额
                        giftFlag: "not_gift", //赠送：不赠送
                        selectFlag: "selected", //默认选中：选中
                    })
                }
            })
            setData([...data, ...newArr])
        }
        setVisible(false)
    }

    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning("请选择你要删除的维修项目")
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.id) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success("批量删除成功")
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex = newData.findIndex((item) => item.id === row.id)
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
    }

    //是否赠送
    const handleCheck = (e, text, recordId) => {
        let checkedStr = e.target.checked ? "gift" : "not_gift"
        let newData = data.map((item) => {
            if (recordId === item.id) {
                return {
                    ...item,
                    giftFlag: checkedStr,
                    // unitPrice: 0.00,        //单价
                    // totalAmount: 0.00,      //总计
                    preferentialAmount: 0, //优惠
                    partDiscount: "", //折扣
                    actualMoney: e.target.checked ? "0.00" : (item.partNumber * item.unitPrice).toFixed(2), //总金额
                }
            } else {
                return item
            }
        })
        setData(newData)
    }
    //默认选中
    const handleSelectCheck = (e, text, recordId) => {
        let checkedStr = e.target.checked ? "selected" : "unselected"
        let newData = data.map((item) => ({
            ...item,
            selectFlag: recordId === item.id ? checkedStr : item.selectFlag,
        }))
        setData(newData)
    }
    //暴露给父组件的方法
    useImperativeHandle(partRef, () => ({
        //upData 就是暴露给父组件刷新保存后的的方法
        upData: (parentOrderId) => echoData(parentOrderId),
        //父组件保存的时候 获取所有编辑完后的数据
        upPartData: () => data,
    }))

    const handleImgChange = (info, recordId) => {
        if (info.file.status === "done") {
            // console.log(info.file, info.fileList);
            let md5 = info?.file?.response?.retData
            let newData = data.map((item) => ({
                ...item,
                partImage: recordId === item.id ? md5 : item.partImage,
            }))
            setData(newData)
            message.success(`${info.file.name}图片上传成功`, recordId)
        } else if (info.file.status === "error") {
            message.error(`${info.file.name}上传失败，请重新尝试`)
        }
    }
    //table配置
    const columns = [
        {
            title: "商品编号",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "partCode",
        },
        {
            title: "商品名称",
            align: "center",
            // width: 200,
            fixed: "left",
            dataIndex: "partName",
        },
        {
            title: "商品图片",
            align: "center",
            width: 150,
            dataIndex: "partImage",
            render: (text, record) =>
                text ? (
                    <>
                        <Image src={`${fileLookUrl().image}${text}`} width={34} height={34} key={record.id} />
                        <span
                            className="added-but"
                            onClick={() => {
                                let recordId = record.id
                                let newData = data.map((item) => ({
                                    ...item,
                                    partImage: recordId === item.id ? null : item.partImage,
                                }))
                                setData(newData)
                            }}
                        >
                            移除
                        </span>
                    </>
                ) : (
                    <Upload
                        action={`${fileUploadUrl}upload/image_old`}
                        headers={{ authorization: getToken() }}
                        key={record.id}
                        itemRender={null}
                        onChange={(info) => handleImgChange(info, record.id)}
                    >
                        <Button size="small">上传图片</Button>
                    </Upload>
                ),
        },
        {
            title: "数量",
            align: "center",
            width: 100,
            dataIndex: "partNumber",
            editable: true,
        },
        {
            title: "单价",
            align: "center",
            width: 185,
            dataIndex: "unitPrice",
            editable: true,
        },
        {
            title: "总计",
            align: "center",
            dataIndex: "totalAmount",
        },
        {
            title: "优惠",
            align: "center",
            width: 185,
            dataIndex: "preferentialAmount",
            editable: true,
        },
        {
            title: "折扣",
            align: "center",
            width: 120,
            dataIndex: "partDiscount",
            editable: true,
        },
        {
            title: "总金额",
            align: "center",
            width: 160,
            dataIndex: "actualMoney",
        },
        {
            title: "赠送",
            align: "center",
            width: 100,
            dataIndex: "giftFlag",
            render: (text, record) => <Checkbox defaultChecked={text === "gift" ? true : false} onChange={(e) => handleCheck(e, text, record.id)} />,
        },
        {
            title: "默认选中",
            align: "center",
            width: 100,
            dataIndex: "selectFlag",
            render: (text, record) => <Checkbox defaultChecked={text === "selected" ? true : false} onChange={(e) => handleSelectCheck(e, text, record.id)} />,
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaterialOne(record)} />
            },
        },
    ]

    //单元格编辑保存
    const handleSave = (row) => {
        console.log(row)
        //配件总计   数量*单价
        row.totalAmount = row.partNumber * row.unitPrice
        //单挑赠送 总金额 为 0
        if (row.giftFlag === "gift") {
            row.actualMoney = "0.00"
        } else {
            //配件总金额  有折扣 总计 *折扣 -优惠   没有总计 -优惠
            row.actualMoney = row.partDiscount
                ? ((row.totalAmount * row.partDiscount) / 10 - row.preferentialAmount).toFixed(2)
                : (row.totalAmount - row.preferentialAmount).toFixed(2)
        }

        const newData = [...data]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>配件项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    <div className="list-name-but">
                        <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={() => setVisible(true)} />
                        {!deleteAll ? (
                            <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                批量删除
                            </span>
                        ) : (
                            <>
                                <span className="list-delete" onClick={deleteTemaAll}>
                                    确认删除
                                </span>
                                <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                    取消
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 1550, y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
            <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setPartList} drawerMode="part" />
        </div>
    )
}
