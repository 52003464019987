import React, { useContext } from "react"
import { MyContext } from "@/store/myContext"
import { PageHeader } from "./PageHeader"
import { DataBox } from "./DataBox"
import { useSaleOrderModel } from "../saleOrderModel"
import { SalePrint } from "./SaleOrderPrint"

export default () => {
    const { print } = useSaleOrderModel()
    const { state } = useContext(MyContext)
    return (
        <>
            {state.activeMenu === "saleOrder" && (
                <>
                    <PageHeader />
                    <DataBox />
                    {print && <SalePrint />}
                </>
            )}
        </>
    )
}
