import { useState, useEffect } from "react";
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { getRandomStr } from "@/erp_subpackage/utils/util";
import { psiService } from "./psiService"

export const usePsiModel = createModel(function () {
    const initStatistics = {
        differenceNumTotal: 0,
        occupyDifferenceTotal: 0,
        currentNumTotal: 0,
    }
    const {
        user: { shopId, organId },
    } = useGlobalModel(({ user }) => [user])
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [descTotal, setDescTotal] = useState(initStatistics);
    const [loading, setLoading] = useState(false);
    //商品明细
    const [purchasePinData, setPurchasePinData] = useState([]);
    const [pinPgCfg, setPinPgCfg] = useState(initPgCfg);
    //单据明细查看
    const [detailsVisible, setDatailsVisible] = useState({
        visible: false,
        code: "",
    });

    //弹框
    const initGoodsRecordModal = {
        remainVisible: false,  //进销存剩余数
        occupyVisible: false,  //占用库存数
        startVisible: false,  //期初数
        compensateVisible: false,  //补偿数
        type: "all"
    }
    const [goodsRecordModal, setGoodsRecordModal] = useState(initGoodsRecordModal);

    const onCancel = () => {
        setGoodsRecordModal(initGoodsRecordModal);
    };

    const onOpen = (row, { str, type = "all", title = "弹框", field }) => {
        setGoodsRecordModal({
            ...initGoodsRecordModal,
            row,
            [str]: true,  //打开对应弹框
            type, title, field
        });
    }

    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading(true)
        setDescTotal(initStatistics);
        try {
            const searchParams = {
                shopId,
                organId,
                ...searchValue,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? initPgCfg.pageNum,
            }
            let { retData } = await psiService.page(searchParams);
            const { records, ...pg } = retData;
            console.log(records.map(item => ({
                ...item,
                key: getRandomStr()
            })));
            setDataSource(records.map(item => ({
                ...item,
                key: getRandomStr()
            })));
            setPgCfg(transPgToPagination(pg));
            setLoading(false)
            let { retData: Data } = await psiService.statistics(searchParams);
            setDescTotal(Data ?? {});
           
           
        } catch {
            setLoading(false);
            // setDataSource([])
        }
    }

    //导出
    const exportPsi = async (searchValue = searchParam) => {
        try {
            const searchParams = {
                shopId,
                organId,
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
            }
            let result = await psiService.export(searchParams);
            convertRes2Blob(result);
        } catch { }
    }

    return {
        /***field***/
        searchParam, shopId,
        dataSource,
        selectedRowKeys,
        pgCfg,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData, exportPsi,
        descTotal, setDescTotal,
        purchasePinData, setPurchasePinData,
        pinPgCfg, setPinPgCfg,
        goodsRecordModal,
        onCancel, onOpen,
        detailsVisible, setDatailsVisible,
        loading
    }
});


