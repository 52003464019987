import React, { useState, useEffect, useContext } from "react"
import { Tree, Button, Form, Input, message } from "antd"
import TopSearch from "@/components/TopSearch"
import { dictInsert, getDictAll, editDict, disableDict } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import "./dictStyle.less"

const { TextArea } = Input

const DataDictionaries = () => {
    const { state } = useContext(MyContext)
    const [form] = Form.useForm()
    const [treeList, setTreeList] = useState(null) //树结构列表
    const [selectTreeObj, setSelectTreeObj] = useState({}) //选择的字典数据
    const [mode, setMode] = useState("edit") //默认展示编辑
    const [initTree, setInitTree] = useState(null)
    const [noEdit, setNoEdit] = useState(false)
    useEffect(() => {
        if (state.activeMenu === "dataDictionaries") {
            loadData()
        }
    }, [state])

    //遍历树换title
    const mapTree = (arr) => {
        return arr.forEach((item) => {
            item.title = item.dictNumber + " " + item.title
            item.disabled = item.disableFlag === 1 ? true : false
            item.children && mapTree(item.children)
        })
    }
    //字典树加载。
    const loadData = () => {
        getDictAll().then((res) => {
            // if (res && res.retCode === 200) {
                let result = JSON.parse(JSON.stringify(res.retData).replace(/id/g, "key"))
                mapTree(result)
                // setDataDict(res.retData);  //每次改变都初始下本地缓存得字典
                setInitTree(res.retData)
                setTreeList(result)
            // }
        })
    }

    //查找父级id，返回父级。
    const forFind = (array, key) => {
        for (const item of array) {
            if (item.id === key) {
                return item
            }
            if (item.children && item.children.length > 0) {
                let forFindRet = forFind(item.children, key)
                if (forFindRet) {
                    return forFindRet
                }
            }
        }
    }

    //选中当前行 根据当前行拿到信息
    const onSelect = (selectedKeys, info) => {
        setMode("edit")
        if (selectedKeys.length) {
            let selectObj = forFind(initTree, selectedKeys[0])
            if (selectObj.dictType === "system") {
                setNoEdit(true)
            } else {
                setNoEdit(false)
            }
            setSelectTreeObj(selectObj)
            //编辑回显
            form.setFieldsValue({
                ...selectObj,
                parentCode: selectObj.parentCode || "",
            })
        } else {
            setSelectTreeObj({})
            form.resetFields()
        }
    }
    //新增模式
    const handleInsert = (str) => {
        if (!Object.keys(selectTreeObj).length) {
            message.warning("请选择增加选项")
            return
        }
        message.success(`${str === "insert" ? "增加同级" : "增加下级"}模式开启`)
        form.setFieldsValue({
            dictCode: "自动生成",
            parentCode: selectTreeObj.parentCode || "",
            title: "",
            remark: "",
        })
        setMode(str)
    }

    //删除字典
    const deleteDict = () => {
        if (!Object.keys(selectTreeObj).length) {
            message.warning("请选择你要禁用选项")
            return
        }
        if (selectTreeObj.children.length > 0) {
            message.warning("请先禁用完子节点才可以禁用父节点")
            return
        }
        disableDict({ id: selectTreeObj.id, disableFlag: 1 }).then((res) => {
            // if (res && res.retCode === 200) {
                loadData()
                setSelectTreeObj({})
                message.success(`${selectTreeObj.title}:禁用成功`)
            // }
        })
    }
    //字典新增或编辑
    const dictSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                remark: values.remark,
                dictName: values.title,
                parentCode: values.parentCode || null,
            }
            //增加下级
            if (mode === "nextInsert") {
                dictInsert({
                    ...data,
                    dictCode: "",
                    parentCode: selectTreeObj.dictCode || null,
                }).then((res) => {
                    // if (res && res.retCode === 200) {
                        message.success("增加下级成功")
                        setMode("edit")
                        loadData()
                    // }
                })
                //增加同级
            } else if (mode === "insert") {
                dictInsert({ ...data, dictCode: "" })
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            message.success("增加同级成功")
                            setMode("edit")
                            loadData()
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //     }
                    // })
                //编辑
            } else {
                editDict({ ...data, id: selectTreeObj.id })
                    .then((res) => {
                        // if (res && res.retCode === 200) {
                            message.success("编辑成功")
                            loadData()
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //     }
                    // })
            }
            setSelectTreeObj({})
        })
    }

    //表单项 布局
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    }

    return (
        <>
            {state.activeMenu === "dataDictionaries" && (
                <>
                    <TopSearch title="数据字典" />
                    <div className="dict-container">
                        <div className="dict-list-box">
                            <div className="dict-list-header">
                                <div className="dict-list-name">
                                    <span>字典列表</span>
                                </div>
                                <div className="dict-list-but">
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert("insert")}>
                                        增加同级
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={() => handleInsert("nextInsert")}>
                                        增加下级
                                    </Button>
                                    <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={deleteDict}>
                                        禁用
                                    </Button>
                                </div>
                            </div>
                            <div className="dict-table-box">
                                {treeList && <Tree selectedKeys={[selectTreeObj.id || ""]} onSelect={onSelect} treeData={treeList} />}
                            </div>
                        </div>
                        <div className="dict-from-box">
                            {Object.keys(selectTreeObj).length ? (
                                <>
                                    <div className="dict-list-header">
                                        <div className="dict-list-but">
                                            <Button type="primary" shape="round" size="small" className="dict-but-left" onClick={dictSubmit}>
                                                保存
                                            </Button>
                                            <Button
                                                type="primary"
                                                shape="round"
                                                size="small"
                                                className="dict-but-left"
                                                onClick={() => {
                                                    setSelectTreeObj({})
                                                    setMode("edit")
                                                }}
                                            >
                                                取消
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="dict-from-content">
                                        <Form form={form} {...formItemLayout}>
                                            <Form.Item name="parentCode" label="父级编码">
                                                <Input placeholder="空" disabled />
                                            </Form.Item>
                                            <Form.Item name="dictCode" label="编码">
                                                <Input placeholder="空" disabled />
                                            </Form.Item>
                                            <Form.Item
                                                name="title"
                                                label="名称"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "名称不可为空",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="请输入名称" disabled={noEdit} />
                                            </Form.Item>
                                            <Form.Item name="remark" label="备注">
                                                <TextArea rows={4} placeholder="请输入字典描述" disabled={noEdit} />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </>
                            ) : (
                                <div className="dict-init-box">
                                    <span>数据字典</span>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
export default DataDictionaries
