import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { combinationList, combinationDelete } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { Modal } from "antd"

export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "combination") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        combinationList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的维保组合信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除该维保组合?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                combinationDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的维保组合")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的维保组合")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //客户开关
    // const clientSweitchChange = (checked, e, id) => {
    //     e.stopPropagation()
    //     // serviceOriceUpdate({ id, status: checked ? 1 : 2 }).then((res) => {
    //     //     message.success("操作成功")
    //     //     loadData(1)
    //     // })
    // }

    //table配置
    const columns = [
        {
            title: "名称",
            align: "center",
            dataIndex: "name",
        },
        {
            title: "间隔里程(万公里)",
            align: "center",
            dataIndex: "intervalMileage",
        },
        {
            title: "预警里程",
            align: "center",
            dataIndex: "earlyWarningMileage",
        },
        {
            title: "维修项目工时费用",
            align: "center",
            dataIndex: "itemAmount",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "名称",
                valueName: "name",
            },
            {
                type: "input",
                placeholder: "间隔里程",
                valueName: "intervalMileage",
            },
            {
                type: "input",
                placeholder: "预警里程",
                valueName: "earlyWarningMileage",
            },
            {
                type: "input",
                placeholder: "维修项目工时费用",
                valueName: "itemAmount",
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "combination" && (
                <>
                    <TopSearch
                        title="维保组合"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="维保组合"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
