import { useState } from "react";
import { createModel } from "hox";
import { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { questionService } from "./questionService";
import { message } from "antd";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { useLockFn } from "ahooks";

export const useQuestionModel = createModel(function () {
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            ...searchValue,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
            }

        }
        let { retData } = await questionService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading({ spinning: false });
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //导出
    const exportQuestion = useLockFn(async (page, searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        const searchParams = {
            ...searchValue,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
            }
        }
        let result = await questionService.export(searchParams);
        convertRes2Blob(result);
        setLoading({ spinning: false });
        return result;
    })

    //下载模板
    const downloadTpl = useLockFn(async () => {
        let result = await questionService.downloadTpl();
        convertRes2Blob(result)
    })

    //启用禁用
    const onDisabled = async (id, checked) => {
        let { retData } = await questionService.disable(id, checked ? 0 : 1);
        loadData();
        message.success("操作成功");
        return retData;
    }
    //删除
    const onRemove = async () => {
        let { retData } = await questionService.remove(selectedRowKeys[0]);
        loadData();
        setSelectedRowKeys([]);
        message.success("操作成功");
        return retData;
    }

    /** 
     * 表单详情
     */
    const initFormVal = {
        createUser: "",
        RADIO: true, MULTIPLE_CHOICE: false, TRUE_FALSE: false,//默认选中单选题
        //多选和单选字段
        A: undefined, A_C: false, A_R: true,
        B: undefined, B_C: false, B_R: false,
        C: undefined, C_C: false, C_R: false,
        D: undefined, D_C: false, D_R: false,
        //判断题
        TRUE_S: false, TRUE_E: false
    }

    const initDialogInfo = { visible: false, mode: "add" };
    const [detailDialog, setDetailDialog] = useState(initDialogInfo);
    const setDetailDialogInfo = (mode) => setDetailDialog(mode ? { visible: true, mode } : initDialogInfo);

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState(initFormVal);
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState({});

    //表单修改
    const updateFormVal = (mode, val, init) => {
        const setFormVal = mode === "edit" ? setEditFormVal : mode === "add" ? setAddFormVal : setLookFormVal;
        if (init) return setFormVal(origin => ({ ...origin, ...initFormVal, ...val }));
        setFormVal(origin => val ? { ...origin, ...val } : initFormVal);
    }

    //获取一条详情数据 Api
    const getQuestionOne = async (id = selectedRowKeys[0], mode) => {
        let { retData } = await questionService.one(id);
        let details = retData.details;
        //单选
        let temp = { ...initFormVal, RADIO: false };
        if (retData.attrCode === "RADIO") {
            details.forEach(item => {
                temp[item.answerKey] = item.answerValue;
                temp[item.answerKey + "_R"] = item.status === "1"
            })
        } else if (retData.attrCode === "MULTIPLE_CHOICE") {
            details.forEach(item => {
                temp[item.answerKey] = item.answerValue;
                temp[item.answerKey + "_C"] = item.status === "1"
            });
        } else if (retData.attrCode === "TRUE_FALSE") {
            let find = details.find(item => item.status === "1");
            if (find) {
                temp["TRUE_S"] = find.answerKey === "A";
                temp["TRUE_E"] = find.answerKey === "B"
            }
        }
        let data = {
            ...retData,
            ...temp,
            [retData.attrCode]: true,
        }
        console.log(data);
        updateFormVal(mode, data ?? {});
        return retData;
    }

    //表单新增 Api
    const insertQuestion = async (data) => {
        let { retData } = await questionService.insert({ ...data, deleteFlag: 0, status: "0" });
        message.success("新增成功");
        loadData();
        return retData;
    }

    //表单编辑 Api
    const editQuestion = async (data) => {
        let { retData } = await questionService.update({
            ...data,
            id: editFormVal.id,
            deleteFlag: 0
        });
        message.success("编辑成功");
        loadData();
        return retData;
    }

    return {
        /***列表***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        loading,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportQuestion,
        onDisabled,
        onRemove,
        /*详情 */
        detailDialog, setDetailDialogInfo,
        editFormVal,
        addFormVal,
        lookFormVal,
        updateFormVal,
        insertQuestion,
        editQuestion,
        getQuestionOne,
        downloadTpl
    }
});