import React, { useState } from "react";
import { Button, Tooltip, Table, message, Upload } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { usePurchasePlanDetailsModel } from "./purchasePlanDetailModel";
import { formatInt, formatNum } from "@/erp_subpackage/utils/util";
import { EditableCell, EditableRow } from '@/erp_subpackage/components/YHEditTable/editTable';
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { purchasePoService } from "../../../po/purchasePoService";
import { importFileConfig } from "@/erp_subpackage/utils/importFileConfig";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export const TableBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const {
        editList, addList, lookList, editFormVal, updateList, setMaterialDialog, removeMaterial, downloadTpl, insertMaterial
    } = usePurchasePlanDetailsModel();
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const tableList = mode === "edit" ? editList : mode === "look" ? lookList : addList;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //货位实时数据
    const [shelfDs, setShelfDs] = useState([]);
    const [loading, setLoading] = useState(false)
    //仓库选择
    const whChange = (value, options) => {
        if (options && options.id) {
            queryShelf(options.value)
        }
    };

    //货位根据仓库code查询
    const queryShelf = async (whCode) => {
        const searchParams = {
            shopId, shopName, shopCode,
            warehouseCode: whCode,
            pageSize: -1,
            pageNum: 1,
            status: 1
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        setShelfDs(records.filter(item => item.status).map(item => ({
            id: item.id,
            label: item.code,
            value: item.code
        })))
    }

    // //货位触焦 
    // const shelfFocus = (record) => {
    //     if (record["warehouseCode"]) {
    //         queryShelf(record["warehouseCode"])
    //     } else {
    //         setShelfDs([]);
    //     }
    // }
    //导入成功
    const importCallback = async (list) => {
        if (list && list.length) {
            let tempArr = [];
            let str = ""
            list.forEach(item => {
                if (item.status) {
                    tempArr.push(item.entity)
                } else {
                    str += `【${item.msg}】`;
                }
            })
            insertMaterial(mode, tempArr);
            str && message.warning(str)
        }
    }

    //根据状态控制页面是否可操作
    //已转接可以填写到货数量，其他的不能操作哦，到货=采购数量后不可以编辑
    const controlEditable = () => {
        if (mode === "add") return true;
        if (mode === "look") return false;
        if (mode === "edit" && (editFormVal.status === DictCodeEnmu.PPLAN_TRANSFER)) return false;
        return true;
    }

    /////////////货位
    const shelfChange = (value, option, record, rowIndex) => {
        let dataSource = [...tableList];
        dataSource.splice(rowIndex, 1, record);
        updateList(mode, dataSource);
        // setControlled(true)
    }
    //货位触焦 
    const shelfFocus = async (record, rowIndex) => {
        setLoading(true)
        try {
            let dataSource = [...tableList];
            const newRow = dataSource[rowIndex];
            let warehouseCode = record["warehouseCode"]
            if (warehouseCode) {
                //货位列表  判断是当前仓库的货位列表  不做接口请求
                let newRecords = shelfDs.find(item => item.warehouseCode === warehouseCode) ? shelfDs : [];
                if (!newRecords.length) {
                    const { retData } = await warehouseService.shelfAllPage({
                        pageSize: 10000,
                        pageNum: 1,
                        warehouseCode,
                    })
                    newRecords = retData.map((item) => ({
                        label: item.code,
                        value: item.code,
                        id:item.id,
                        warehouseCode: item.warehouseCode
                    }))
                    setShelfDs(newRecords)
                }
                //查询配件有没有在改仓库下采购过，
                let { retData } = await purchasePoService.getMaterialShelf({
                    shopId,
                    materialCode: record.materialCode,
                    warehouseCode: warehouseCode
                })
                // 采购过的货位带出来不可以编辑， 其它的可以编辑(有临时货位默认带出临时货位)
                if (retData.shelfCode) {
                    newRow.shelfCode = retData.shelfCode;
                    newRow.shelfId = retData.shelfId;
                    newRow.isPurShelf = retData.shelfCode;  //做是否可编辑
                    message.warning(`自动匹配到配件【${record.materialCode}】在该仓库下采购的货位，货位不可做修改操作！！`)
                    dataSource.splice(rowIndex, 1, { ...record, ...newRow });
                    updateList(mode, dataSource);
                }
            } else {
                setShelfDs([]);
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
        }
    }


    const action = controlEditable() ? <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => setMaterialDialog(true)}
        >新增商品</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (!selectedRowKeys.length) {
                    message.warning("请选择需要批量的数据")
                    return;
                }
                removeMaterial(mode, selectedRowKeys);
                setSelectedRowKeys([])
            }}
        >批量删除</Button>
        {/* <Upload showUploadList={false}  {...importPropsConfig(`/ci-storage/purchase/plan/details/import/${shopId}`, importCallback)}>
            <Button className="btn-item" shape="round">导入</Button>
        </Upload> */}
        <Upload showUploadList={false}  {...importFileConfig({
            url: `/ci-storage/purchase/details/import`,
            callback: importCallback,
            params: { shopId, shopName },
            isMessage: false
        })}>
            <Button className="btn-item" shape="round">导入</Button>
        </Upload>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => downloadTpl()}
        >下载模板</Button>
    </> : null

    const columns = [
        {
            title: '序号',
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
            // render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 120,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text} {record.icon === 1 && <span style={{ background: "orange", color: "white" }}>多</span>} </p></Tooltip>,
        }, {
            title: '单位',
            width: 50,
            dataIndex: 'unitName',
        }, {
            title: '采购数量',
            width: 100,
            dataIndex: 'planNum',
            // editable: controlEditable(),
            editable: mode === 'add' ? true : false,
        }, {
            title: '采购单价',
            width: 100,
            dataIndex: 'price',
            editable: controlEditable(),
            render: text => formatNum(text)
        }, {
            title: '采购金额',
            width: 100,
            dataIndex: 'amount',
            render: text => formatNum(text)
        }, {
            title: '所属仓库',
            width: 150,
            dataIndex: 'warehouseCode',
            editable: controlEditable(),
            editType: "select",
            // onSelectChange: whChange,
            options: whDs,
            render: (text, record) => whDs.find(item => item.value === text)?.label ?? record.warehouseName ?? "请选择仓库",
        }, {
            title: '所属货位',
            width: 150,
            dataIndex: 'shelfCode',
            editable: controlEditable(),
            editType: "select",
            onSelectFocus: shelfFocus,
            onSelectChange: shelfChange,
            options: shelfDs,
            render: (text) => text ?? "请选择货位",
        }, {
            title: '到货数量',
            width: 100,
            dataIndex: 'num',
            editable: mode === "edit" || mode === "add",
        }, {
            title: '已到货数量',
            width: 100,
            dataIndex: 'arrivedTotal',
        }, {
            title: '未到货数量',
            width: 100,
            dataIndex: 'unarrivedNum',
        }, {
            title: '品牌',
            width: 100,
            dataIndex: 'brandName',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
            editable: true,
        }, {
            title: '操作',
            width: 50,
            fixed: "right",
            align: "center",
            render: (text, record) =>
                controlEditable() && <YhBut
                    type="delete"
                    txt="删除"
                    click={() => removeMaterial(mode, [record.materialCode])}
                />
        }
    ];

    const newColumn = columns.map((col, index) => {
        let disaBled = col.dataIndex === "num"
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                //（已到货数量 < 采购数量)的时候， 到货数量才可以编辑
                //查看模式不可编辑   、
                editable: col.dataIndex === "shelfCode" ? !record.isPurShelf : (disaBled ? record.planNum !== record.arrivedTotal : col?.editable),
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    });

    //内联编辑
    const handleSave = async (row, dataIndex) => {
        //货位的行编辑放到触焦里面操作， 防止覆盖赋值
        if (dataIndex !== "shelfCode") {
            let dataSource = [...tableList];
            const index = dataSource.findIndex((item) => row.materialCode === item.materialCode);

            const item = dataSource[index];
            row.planNum = +formatInt(row.planNum);
            row.price = +formatNum(row.price);
            row.amount = formatNum(row.planNum * row.price);
            //已到货数量
            row.arrivedTotal = +formatInt(row.arrivedTotal);
            //未到货数量
            // row.unarrivedNum = +formatInt(row.unarrivedNum)
            row.num = +formatInt(row.num, row.planNum - row.arrivedTotal, "到货数量<=未到货数量");
            row.warehouseName = whDs.find(item => item.value === row.warehouseCode)?.label;
            // row.shelfId = shelfDs.find(item => item.value === row.shelfCode)?.id;



            //判断仓库变化时，  重置货位
            if (row.warehouseCode !== dataSource[index].warehouseCode) {
                setLoading(true)
                try {
                    //货位列表
                    let newRecords = []
                    let params = {
                        pageSize: 10000,
                        pageNum: 1,
                        warehouseCode: row.warehouseCode,
                    }
                    const { retData: shelfAll } = await warehouseService.shelfAllPage(params)
                    newRecords = shelfAll.map((item) => ({
                        label: item.code,
                        value: item.code,
                        id:item.id,
                        warehouseCode: item.warehouseCode
                    }))
                    setShelfDs(newRecords);
                    //查询配件有没有在改仓库下采购过， 
                    let { retData } = await purchasePoService.getMaterialShelf({
                        shopId,
                        materialCode: row.materialCode,
                        warehouseCode: row.warehouseCode
                    })
                    // debugger;
                    // 采购过的货位带出来不可以编辑， 其它的可以编辑(有临时货位默认带出临时货位)
                    if (retData.shelfCode) {
                        row.shelfCode = retData.shelfCode;
                        row.shelfId = retData.shelfId;
                        row.isPurShelf = retData.shelfCode;  //做是否可编辑
                        message.warning(`自动匹配到配件【${row.materialCode}】在该仓库下采购的货位，货位不可做修改操作！！`)
                    } else {
                        let isTemp = newRecords?.find(item => (item.value ?? "").indexOf("临时货位") > -1);
                        row.shelfCode = isTemp?.value;
                        row.shelfId = isTemp?.id;
                        row.isPurShelf = "";
                    }
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                }
            }

            dataSource.splice(index, 1, { ...item, ...row });
            updateList(mode, dataSource);
        }
    }

    return (
        <YhBox title={<span style={{ paddingLeft: "20px" }}>商品信息</span>} rightChildren={action}>
            <Table
                loading={{ tip: "数据查询中...", spinning: loading }}
                rowKey={record => record.materialCode}
                rowClassName='yh-editable-row yh-edit-table-tr'
                rowSelection={{
                    selectedRowKeys: selectedRowKeys, //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowKeys(selectedRowKeys);
                    }
                }}
                components={{
                    body: {
                        row: EditableRow,
                        cell: EditableCell,
                    },
                }}
                columns={newColumn}
                dataSource={tableList}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}

