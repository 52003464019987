import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
class InternalService {
    //核销记录
    async page(searchVo) {
        const apiUrl = "/fms/allotAccount/writeoff/page";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    async one(id) {
        const apiUrl = `/fms/allotAccount/writeoff/one/${id}`;
        return await httpRequest.post(apiUrl, {}, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //保存
    async writeoff(searchVo) {
        const apiUrl = "/fms/allotAccount/writeoff";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //审核
    async audit(searchVo) {
        const apiUrl = "/fms/allotAccount/writeoff/audit";
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //查询门店结算单位的 下属关联
    async queryShopUnitBe(shopId) {
        const apiUrl = `/fms/accountUnit/find/one/${shopId}`;
        return await httpRequest.post(apiUrl, {}, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //调拨信息查询
    async allot(searchVo) {
        const apiUrl = `/fms/allot/listPage`;
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //调拨详情查询
    async detailPage(searchVo) {
        const apiUrl = `/fms/allot/detailPage`;
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
}
export const internalService = new InternalService();
