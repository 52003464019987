import React, { useState, useContext, useEffect } from "react"
import { MyContext } from "@/store/myContext"
import { tdmsDataSearch, tdmsCategoryRootlist, tdmsDeleteData, tdmsCategorySearchTree } from "../../../../src/service/tdmsService"
import { download, convertRes2Blob, tdmsDownLoad } from "@/utils/exportExcel"
import { message, Divider, Table, Space, Button, Input, TreeSelect } from "antd"
import ListComponent from "@/components/listComponent"
import { HeartTwoTone, EyeOutlined, LikeOutlined } from "@ant-design/icons"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import moment from "moment"
import TopSearch from "@/components/TopSearch"
import {API_SERVER_HOST, API_SERVER_PORT} from "../../../config/app";
const { TreeNode } = TreeSelect
export default (props) => {
    const [loading, setLoading] = useState(false) // 审核列表loading
    const [loadingInfo, setLoadingInfo] = useState({ loadingVisible: false, txt: "" })
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [selectInfo, setSelectInfo] = useState({}) //记录列表当前行下标及当前行信息

    const { dispatch, detailsPage, state, Option } = useContext(MyContext)
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })
    // 列表数据
    const [dataList, setDataList] = useState([])

    // 分类数据
    const [treeData, setTreeData] = useState([])

    //分页事件
    const onTableChange = async (page, filters, sorter) => {
        setpagination({
            ...pagination,
            current: pagination.current,
        })
        loadData(page.current, 
            {...searchValue,
                orderByCreateTimeDescFlag:sorter?.field=='createTime'&&sorter?.order=='descend'?'desc':'',
                orderByCreateTimeAscFlag:sorter?.field=='createTime'&&sorter?.order=='ascend'?'asc':'',
                orderByUpdateTimeDescFlag:sorter?.field=='updateTime'&&sorter?.order=='descend'?'desc':'',
            })
    }

    useEffect(() => {
        if (state.activeMenu === "tdmsData") {
            console.log(props)
            loadParentSelectData()
            loadData(pagination.current, {...searchValue, categoryIds: props.rowInfo })
            setSearchValue({...searchValue, categoryIds: props.rowInfo })
        }
    }, [state.activeMenu])

    // 审核列表数据
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            ...search,
            page: {
                pageNum: current,
                pageSize: 10,
            },
        }
        tdmsDataSearch(data).then((res) => {
            if (res && res.retCode === 200) {
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setDataList(res.retData.records)
                setLoading(false)
            } else {
                message.warning(res.retMsg)
            }
        })
    }

    //table配置
    const columns = [
        {
            title: "标题",
            align: "center",
            dataIndex: "title",
        },
        {
            title: "所属分类",
            align: "center",
            dataIndex: "categoryNames",
        },
        {
            title: "上传者",
            align: "center",
            dataIndex: "createUser",
        },
        {
            title: "审核者",
            align: "center",
            dataIndex: "approvalUser",
        },
        {
            title: "是否原创",
            align: "center",
            dataIndex: "isOriginal",
            render: (text) => (text == 0 ? "是" : "否"),
        },
        {
            title: "创建时间",
            align: "center",
            dataIndex: "createTime",
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
            sorter: true,
        },
        {
            title: "更新时间",
            align: "center",
            dataIndex: "updateTime",
            render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
            sorter: true,
            sortDirections: [ 'descend'],
        },
        {
            title: "状态",
            align: "center",
            dataIndex: "status",
            render: (t, r) => (
                <span>
                    <LikeOutlined style={{ fontSize: 18, marginRight: 3, marginLeft: 3 }} />
                    {r.likeNum}
                    <HeartTwoTone style={{ fontSize: 18, marginRight: 3, marginLeft: 3 }} twoToneColor="#eb2f96" />
                    {r.favorNum}
                    <EyeOutlined style={{ fontSize: 18, marginRight: 3, marginLeft: 3 }} />
                    {r.accessNum}
                </span>
            ),
        },
    ]

    //新增 编辑
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "技术资料新增",
            edit: "技术资料编辑",
            look: "技术资料查看",
        }
        let modeKey = "tdmsData/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的资料")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的资料")
                return
            }
            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
            // modeKey += "/" + selectInfo.rowInfo.id
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.DataDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }

    //导出
    const exportData = async () => {
        console.log(selectInfo)
        setLoadingInfo({ loadingVisible: true, txt: "快速导出中" })
        let data = {
            type: "get",
            data: {
                dataDto: { ...searchValue },
            },
        }
        let resFlie = await tdmsDownLoad("/tdms/data/download", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
            setLoadingInfo({ loadingVisible: false, txt: "" })
        } else {
            message.warning("导出异常，请重新尝试")
        }
    }

    // 数据处理
    const toTree = (data, parent) => {
        var tree = []
        var temp
        for (var i = 0; i < data.length; i++) {
            data[i].title = data[i].name
            data[i].key = data[i].id
            if (data[i].pid == parent) {
                var obj = data[i]
                temp = toTree(data, data[i].id)
                if (temp.length > 0) {
                    obj.children = temp
                }
                tree.push(obj)
            }
        }
        return tree
    }

    //过滤后端传来的动态路由 重新生成规范路由
    function mapTree(resourceArr) {
        return resourceArr.map(item => {
            let { id, pid, name, children } = item;
            const haveChildren = children && children.length > 0;
            return {
                id: id,
                pId: pid,
                value: id,
                title: name,
                children: haveChildren ? mapTree(children) : []
            };
        })
    }


    // 获取技术分类
    const loadParentSelectData = () => {
        tdmsCategorySearchTree().then((res) => {
            let list = res.retData
            if (list) {
                setTreeData(mapTree(list))
            }
        })
    }

    // 循环遍历树
    const treeDataMethods = (list) => {
        return list?.map((item) => (
            <TreeNode key={item.id} title={item.name}>
                {item?.children.length > 0 && treeDataMethods(item?.children)}
            </TreeNode>
        ))
    }
    //搜索
    const searchList = {
        normalList: [
            {
                type: "treeSelect",
                placeholder: "技术资料",
                valueName: "categoryIds",
                data: treeData,
            },
            {
                type: "input",
                placeholder: "标题",
                valueName: "title",
            },
            {
                type: "select",
                placeholder: "是否原创",
                valueName: "isOriginal",
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            是
                        </Option>
                        <Option value={1} key={1}>
                            否
                        </Option>
                    </>
                ),
            },
            {
                type: "input",
                placeholder: "作者",
                valueName: "author",
            },
        ],
    }

    const accountNum = getUserInfo() ? getUserInfo().accountNum : "未获取到"

    // 删除
    const handleDeleteDataFile = () => {
        console.log("删除", selectInfo, getUserInfo() ? getUserInfo().accountNum : "未获取到")
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的资料")
            return
        } else if (accountNum != "admin") {
            message.warning("您不是管理员没有删除权限!!!")
            return
        }
        tdmsDeleteData(selectInfo.rowInfo.id).then((res) => {
            if (res.retData) {
                message.success("删除成功!")
                loadData()
            }
        })
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDeleteDataFile(),
            content: "删除",
        },
        {
            content: "导入",
            upload: true,
            action:  `${API_SERVER_HOST}:${API_SERVER_PORT}/tdms/data/upload`,
            onChange(info) {
                if (info.file.status === "done" && info.file?.response?.retCode === 200) {
                    message.success({
                        content: `${info.file.name}导入${info?.file?.response?.retData}`,
                        duration: 8,
                    })
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name}导入异常，请尝试重新导入`)
                }
            },
        },
        {
            click: () => exportData(),
            content: "导出",
        },
    ]

    return (
        <>
            {state.activeMenu === "tdmsData" && (
                <>
                    <TopSearch searchListData={searchList} searchValue={searchValue} setSearchValue={(obj) => setSearchValue(obj)} handleSearch={loadData} />

                    <ListComponent
                        title="资料列表"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={dataList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        // rowSelection={true}
                        scroll={{ x: "100%" }}
                    />
                </>
            )}
        </>
    )
}
