import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { classificationList, classificationDelete, classificationDisable } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { Modal, Switch } from "antd"

export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "systemClassification") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        classificationList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的系统分类信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除该系统分类?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                classificationDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的系统分类")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的系统分类")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //禁用开关
    const sweitchChange = (checked, e, id) => {
        e.stopPropagation()
        classificationDisable({ id, disableFlag: checked ? 0 : 1 }).then((res) => {
            message.success("操作成功")
            loadData(1)
        })
    }

    //table配置
    const columns = [
        {
            title: "名称",
            align: "center",
            dataIndex: "name",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
        // {
        //     title: "禁用",
        //     align: "center",
        //     width: "12%",
        //     dataIndex: "disableFlag",
        //     render: (text, record) => (
        //         <Switch size="small" checked={text === 1 ? false : true} onChange={(checked, e) => sweitchChange(checked, e, record.id)} />
        //     ),
        // },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "分类名称",
                valueName: "name",
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "systemClassification" && (
                <>
                    <TopSearch
                        title="系统分类"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="系统分类"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
