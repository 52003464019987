import React, { useEffect, useRef, useState } from "react";
import { Button, message } from "antd";
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox";
import { useInternalDetailsModel } from "./internalDetailsModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { debounce } from "lodash";
import { internalService } from "../../internalService";
import { useGetState } from 'ahooks';

export const FormBox = ({ modeKey, mode, rowId, dispatch }) => {
    const formRef = useRef();
    const { user: { shopCode, shopName, shopId, userName, orgShopCode, orgShopName }, accUnitDs } = useGlobalModel(() => []);
    const {
        editFormVal, addFormVal,
        addGoodsList,
        editGoodsList,
        updateEditFormVal,
        updateAddFormVal,
        allotLoadData,
        saveInternal,
        getInternalDetailOne,
        audit,
        setAddGoodsList,
        setEditGoodsList
    } = useInternalDetailsModel();
    const updateFormVal = mode === "add" ? updateAddFormVal : updateEditFormVal;
    const [inUnitBeList, setinUnitBeList] = useGetState([]);  //所属机构对应的关联实体 
    const [outUnitBeList, setOutUnitBeList] = useState([]);  //对账单位对应的关联实体
    const adverseUnitOption = accUnitDs.filter(item => item.shop !== shopId).map(item => ({ label: item.name ?? "", value: item.code ?? "", shopcode: item.shopCode }))

    //编辑
    useEffect(() => {
        if (mode === "edit") {
            getInternalDetailOne(rowId).then(res => {
                res.outShopCode && getShopOutUnitDetail({ label: res.adverseUnitName ?? "", value: res.adverseUnitCode ?? "" }, true, res.adverseShopCode);
                res.shopCode && getShopinUnitDetail(res.shopCode);
            });
        }
        if (mode === "add") {
            orgShopCode && getShopinUnitDetail(orgShopCode);
        }
        return () => {
            updateFormVal(null)
            mode === "add" ? setAddGoodsList([]) : setEditGoodsList([])
        }
    }, [rowId]);

    useEffect(() => {
        let writeoffAmount = 0;
        addGoodsList.forEach((item) => {
            writeoffAmount += item.totalAmount
        })
        updateAddFormVal({ writeoffAmount })
    }, [addGoodsList])

    //回显选中对账单位带出来的数据 
    const getShopOutUnitDetail = async (option, echo = false, shopid) => {
        let { retData } = await internalService.queryShopUnitBe(shopid);
        let data = {
            adverseUnitCode: option.value,
            adverseUnitName: option.label,
            adverseShopCode: shopid,
            adverseBeCode: undefined,
            adverseBeName: undefined,
        }
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setOutUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
            } else {
                setOutUnitBeList([]);
                message.warning("对账单位对应的核算单位没有关联实体")
            };
            //回显时不用执行一下操作
            if (echo) return;
            saveCache({
                ...data,
            });
        } else {
            saveCache(data);
            setOutUnitBeList([]);
            message.warning("对账单位没有关联的核算单位");
        }
    }


    //回显选中所属机构带出来的数据  
    const getShopinUnitDetail = async (shopId) => {
        let { retData } = await internalService.queryShopUnitBe(shopId);
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setinUnitBeList(retData.bentityList.map(item => ({ label: item.name ?? "", value: item.code ?? "" })));
            } else {
                setinUnitBeList([]);
                message.warning("所属机构对应的核算单位没有关联实体")
            }
        } else {
            setinUnitBeList([]);
            message.warning("所属机构没有关联的核算单位");
        }
    }

    // 返回
    const goBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "accountInternal",
        })
    }

    //保留缓存及回显表单
    const saveCache = (val, isFormItem = true) => {
        mode === "edit" ? updateEditFormVal(val) : updateAddFormVal(val);
    }

    //对账单位
    const adverseUnitChange = (value, option) => {
        if (option && option.shopcode) {
            getShopOutUnitDetail(option, false, option?.shopcode);
            allotLoadData({}, { outShopCode: option?.shopcode, shopCode:orgShopCode, status: 1, })
        }
    }


    //关联实体
    const BeChange = (value, option) => {
        option && saveCache({ beName: option.label, beCode: option.value });
    }

    //对账实体
    const adverseBeChange = (value, option) => {
        option && saveCache({ adverseBeName: option.label, adverseBeCode: option.value });
    }

    const onChange = (changedValues, values) => {
        saveCache(changedValues);
    }


    const save = (status) => {
        let billCodeList = (mode == "add" ? addGoodsList : editGoodsList).map(item => item.code);
        let adverseUnitCode = (mode == "add" ? addFormVal : editFormVal)?.adverseUnitCode
        let params = {
            billCodeList,
            outShopCode: accUnitDs.find(item => item.code === adverseUnitCode)?.shopCode,
            shopCode:orgShopCode,
            status,
            writeoffType: 2
        }
        formRef.current.validateFields().then(async val => {
            saveInternal(params).then(res => {
                if (res) {
                    mode == "add" ? message.success(`保存${status && '申请'}成功`) : message.success(`编辑${status && '申请'}成功`)
                    goBack()
                }
            })
        })
    }
    const confirm = (status) => {
        let params = {
            code: editFormVal.code,
            adverseAffirmUser: userName,
            status
        }
        formRef.current.validateFields().then(async val => {
            audit(params).then(res => {
                if (res) {
                    message.success("审核成功")
                    goBack()
                }
            })
        })
    }
    const rightChildren = <>
        {(Number(editFormVal.status) <= 1 || mode == "add") &&
            <>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={debounce(() => {
                        save(0);
                    }, 500)}
                >保存</Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={debounce(() => {
                        mode == "add" ? save(1) : confirm(1)
                    }, 500)}
                >申请</Button>
            </>
        }
        {Number(editFormVal.status) == 1 &&
            <>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={debounce(() => {
                        confirm(3);
                    }, 500)}
                >确认</Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={debounce(() => {
                        confirm(2);
                    }, 500)}
                >驳回</Button>
            </>
        }
        <Button
            className="btn-item"
            type="link"
            shape="round"
            onClick={() => goBack()}
        >返回</Button>
    </>
    const baseFormItem = {
        title: "基础信息",
        style: { padding: "10px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: 'Input',
                fieldLabel: "对账单号",
                fieldName: "code",
                disable: true,
                placeholder: "自动生成"
            },
            {
                type: 'Input',
                fieldLabel: "所属机构",
                fieldName: "shopName",
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: "关联实体",
                fieldName: "beCode",
                onSelectChange: BeChange,
                options: inUnitBeList,
                // rules: [{ required: true, message: "请选择关联实体" }],
                hidden: mode !== "add"
            },
            {
                type: 'Input',
                fieldLabel: "关联实体",
                fieldName: "beName",
                // rules: [{ required: true, message: "请选择关联实体" }],
                hidden: mode === "add"
            },
            {
                type: 'Input',
                fieldLabel: "对账金额",
                fieldName: "writeoffAmount",
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: "对账单位",
                fieldName: "adverseUnitCode",
                onSelectChange: adverseUnitChange,
                options: adverseUnitOption,
                rules: [{ required: true, message: "请选择对账单位" }],
                hidden: mode !== "add"
            },
            {
                type: 'Input',
                fieldLabel: "对账单位",
                fieldName: "adverseUnitName",
                rules: [{ required: true, message: "请选择对账单位" }],
                hidden: mode === "add"
            },
            {
                type: 'Select',
                fieldLabel: "对账实体",
                fieldName: "adverseBeCode",
                onSelectChange: adverseBeChange,
                options: outUnitBeList,
                rules: [{ required: true, message: "请选择对账实体" }],
                hidden: mode !== "add"
            },
            {
                type: 'Input',
                fieldLabel: "对账实体",
                fieldName: "adverseBeName",
                rules: [{ required: true, message: "请选择对账实体" }],
                hidden: mode === "add"
            },
            {
                type: 'Input',
                fieldLabel: "申请说明",
                fieldName: "note",
            },
            //带出字段
            {
                type: 'Input',
                fieldLabel: "对账实体名称",
                fieldName: "adverseBeName",
                hidden: true
            },
            {
                type: 'Input',
                fieldLabel: "对账单位名称",
                fieldName: "adverseUnitName",
                hidden: true
            },
            {
                type: 'Input',
                fieldLabel: "对账单位门店code",
                fieldName: "adverseShopCode",
                hidden: true
            },
            {
                type: 'Input',
                fieldLabel: "关联实体名称",
                fieldName: "beName",
                hidden: true
            },
            {
                type: 'Input',
                fieldLabel: "从属门店Code",
                fieldName: "shopCode",
                hidden: true
            },


        ]
    };

    const poFromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: mode === "edit" ? editFormVal : addFormVal,
        onChange,
        disabled: mode === "edit",
        boxs: [baseFormItem]
    }


    return (
        <YhFormBox {...poFromConfig} />
    )
}