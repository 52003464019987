import React, { useEffect, useContext } from "react";
import { Table, Tooltip } from "antd";
import { useSaleOrderModel } from "../saleOrderModel";
import { YhTag } from "@/erp_subpackage/components/YhTag";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { momentFormat, formatNum } from "@/erp_subpackage/utils/util";
import { MyContext } from "@/store/myContext"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import YhTable from "@/erp_subpackage/components/YhTable"

export function DataTable() {
    const { dataSource, pgCfg, loading, loadData, setSelectedRow, selectedRow } = useSaleOrderModel();
    const { dispatch, detailsPage } = useContext(MyContext)
    const { getTreeNodeName } = useDictModel();

    useEffect(() => {
        loadData()
    }, [])

    const onLook = (mode, code) => {
        let tabName = {
            add: "销售订单新增",
            edit: "销售订单编辑",
            look: "销售订单查看",
        }
        let modeKey = "saleOrder/" + mode

        //动态传入要删除的nav key
        function handleDispatch(obj = {}) {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.SaleOrderDetails({
                    dispatch,
                    modeKey,
                    mode,
                    rowId: code,
                }),
            })
        }
        if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '销售订单号',
            width: 120,
            dataIndex: 'saleOrderCode',
            fixed: "left",
            render: (text) => <YhTooltip text={text} onClick={() => onLook("look", text)} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={(e) => { e.stopPropagation(); onLook("look", text) }}>{text}</p></Tooltip>,
        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag status={text} text={getTreeNodeName(text)} />,
        }, {
            title: "所属机构",
            width: 160,
            dataIndex: "shopName",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: "订单来源",
            width: 120,
            dataIndex: "orderSourceName",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: "单据编号",
            width: 120,
            dataIndex: "orderCode",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: "单据类型",
            width: 120,
            dataIndex: "orderTypeCode",
            render: (text) => <YhTooltip text={ getTreeNodeName(text)} />,   
        }, {
            title: '客户名称',
            width: 150,
            dataIndex: 'customerName',
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '联系人',
            width: 150,
            dataIndex: 'contact',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '电话',
            width: 95,
            dataIndex: 'phone',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '销售类型',
            width: 100,
            dataIndex: 'salesTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '订单数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '订单金额',
            width: 80,
            dataIndex: 'totalAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '优惠金额',
            width: 80,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '营业额',
            width: 80,
            dataIndex: 'receivedAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '结算方',
            width: 80,
            dataIndex: 'settlementPartyName',
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payType',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
           
        }, {
            title: "付款时间",
            width: 160,
            dataIndex: "paytime",
            render: (text) => momentFormat(text)
        }, {
            title: "支付方式",
            width: 120,
            dataIndex: "paymentMethodCode",
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
        }, {
            title: '发票类型',
            width: 90,
            dataIndex: 'invoiceTypeCode',
            //render: (text) => <p className="one-line">{getTreeNodeName(text)}</p>,
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
        }, {
            title: '车辆类型',
            width: 90,
            dataIndex: 'vehicleTypeName', 
             render: (text) => <YhTooltip text={text} />,
        }, {
            title: '车牌号',
            width: 80,
            dataIndex: 'licensePlate',
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: 'VIN码',
            width: 80,
            dataIndex: 'vin',
            //render: (text) => <p className="one-line">{text}</p>,
            render: (text) => <YhTooltip text={text} />,

        }, {
            title: '制单人',
            width: 80,
            dataIndex: 'createUser',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '制单日期',
            width: 130,
            dataIndex: 'createTime',
            render: (text) => momentFormat(text)
        }, {
            title: "收货人",
            width: 120,
            dataIndex: "consignee",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: "收货电话",
            width: 120,
            dataIndex: "consigneePhone",
        }, {
            title: "收货地址",
            width: 160,
            dataIndex: "consigneeAddress",
            render: (text) => <YhTooltip text={text} />,
            // render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }, {
            title: '打印次数',
            width: 70,
            fixed: "right",
            align: "center",
            dataIndex: 'printingTimes',
        }
    ];


    //点击行
    const onRow = (record) => {
        let findObj = selectedRow.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRows = selectedRow.filter(item => item.id !== record.id);
            setSelectedRow(newSelectedRows);
        } else {
            setSelectedRow(origin => [...origin, record]);
        }
    };

    return (
        <YhTable
            style={{ cursor: "pointer" }}
            loading={loading}
            rowKey={record => record.id}
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRow((origin) => [...origin, ...changeRows])
                        : setSelectedRow((origin) => {
                            let changeRowId = changeRows.map(item => item.id);
                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRow.map(item => item.id)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize }).then();
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRow([record]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
