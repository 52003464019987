import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Row, Col, Checkbox, Button, Select, Upload, Modal, Divider, message, Radio, Spin } from 'antd'
import { getPcodeDict } from '@/utils/dataStorage'
import { platformUserList } from '@/service/customerService'
import { getBussinessEntityList } from '@/service/dataService'
import { organPage } from '@/service/orgService'
import { organInsert } from '@/service/orgService'
import { Map, Marker } from 'react-amap'
import { getToken } from '@/utils/dataStorage'
import { fileUploadUrl, fileLookUrl } from '@/config/app.js'
import debounce from 'lodash/debounce'
import ServiceAreaMap from './ServiceAreaMap/ServiceAreaMap'
import { commonService } from '@/erp_subpackage/pages/common/commonService'
import InsertAddressMap from '@/components/TMapChoose' //地图
import ChooseAreaMap from "./chooseAreaMap"
const { Option } = Select
const AddOrg = ({ dispatch }) => {
    const [form] = Form.useForm()
    const [value, setValue] = React.useState(1)
    const onChange = (e) => {
        console.log('radio checked', e.target.value)
        setValue(e.target.value)
    }
    const [map2Visible, setmap2Visible] = useState(false) //预览图片使用的dialog
    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })
    const [path, setPath] = useState([
        // {
        //     Q: 30.742119228248132,
        //     R: 103.85287489280108,
        //     lat: 30.742119,
        //     lng: 103.852875,
        // },
        // {
        //     Q: 30.751561258825078,
        //     R: 104.33901991233233,
        //     lat: 30.751561,
        //     lng: 104.33902,
        // },
        // {
        //     Q: 30.467898416378546,
        //     R: 104.19345106467608,
        //     lat: 30.467898,
        //     lng: 104.193451
        // }
    ])
    const [visiblePolygon, setvisiblePolygon] = useState(true)
    const [mapVisible, setmapVisible] = useState(false) //预览图片使用的dialog
    let ref = useRef()
    let refs = useRef()
    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [previewidCartVisible, setpreviewidCartVisible] = useState(false) //预览图片使用的dialog
    const [businessLicenseList, setBusinessLicenseList] = useState([]) //图片list
    const [idcardTailList, setIdcardTailList] = useState([]) //图片list
    const [previewiDVisible, setpreviewiDVisible] = useState(false) //预览图片使用的dialog

    const [idcardHeadList, setIdcardHeadList] = useState([]) //图片list
    const [previewImage, setpreviewImage] = useState('') //预览展示的行驶证
    const [previewidCartImage, setpreviewidCartImage] = useState('') //预览展示的行驶证
    const [previewiDImage, setpreviewiDImage] = useState('') //预览展示的行驶证
    const [markerPosition, setMarkerPosition] = useState({ longitude: 121, latitude: 36 }) //预览展示的行驶证
    const [signAddrList, setSignAddrList] = useState({
        //地图初始信息
        name: '东华门街道南河沿大街69号',
        addr: '北京市东城区东华门街道天安门',
        longitude: 116.397451,
        latitude: 39.909187,
        organProvince: '',
        organCity: '',
        organDistrict: '',
        organLng: '',
        organLat: '',
    })
    const [data, setData] = useState([]) //默认展示数据

    const [businessManager, setBusinessManager] = useState({
        data: [],
        fetching: false,
    })

    const [beList, setBeList] = useState([])
    const [shopList, setShopList] = useState([])

    // useEffect(() => {
    //     tool && tool.polygon()
    // }, [tool])
    useEffect(() => {
        organPage({
            searchItem: { orderFlag: 0 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            // if (res && res.retCode === 200) {
            console.log(res.retData.list)
            setData(res.retData.list)
            // }
        })
        // getBeAndShop()
        getBeList()
    }, [])

    //获取经营实体list 门店信息list
    // const getBeAndShop = async () => {
    //     const data = {
    //         sourceApp: 'ci',
    //         branch: 'main',
    //         sourceAuthKey: '',
    //         page: {
    //             pageSize: -1,
    //             pageNum: 1,
    //         },
    //     }
    //     let { retData: {records: beList} } = await commonService.getBenetityFms(data)
    //     const shopData = {"name":"sdf","sourceApp":"fms","page":{"pageSize":10,"pageNum":1}}
    //     let { retData: {records: shopList}  } = await commonService.getShopFms(shopData)
    //     setBeList(beList ?? [])
    //     setShopList(shopList ?? [])
    // }

    const getBeList = async () => {
        const data = {
            sourceApp: 'ci',
            branch: 'main',
            sourceAuthKey: '',
            page: {
                pageSize: -1,
                pageNum: 1,
            },
        }
        let { retData: {records: beList} } = await commonService.getBenetityFms(data)
        setBeList(beList ?? [])
    }

    const getShopList = async (val) => {
        let name = val
        const shopData = {"name":name,"sourceApp":"fms","page":{"pageSize":15,"pageNum":1},status:0}
        let { retData: {records: shopList}  } = await commonService.getShopFms(shopData)
        setShopList(shopList ?? [])
    }

    //改变数据通用方法(单层)
    const changeData = (results) => {
        const {
            result: {
                address_component: { province: organProvince, city: organCity, district: organDistrict },
            },
            addr,
            name,
            latitude,
            longitude,
        } = results

        setSignAddrList({
            name,
            addr,
            longitude,
            latitude,
            organProvince,
            organCity,
            organDistrict,
        })
        form.setFieldsValue({
            organContactAddress: addr,
        })
        setmapVisible(false)
    }
    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //行驶证图片查看
    const handlePreview = async (file) => {
        setpreviewVisible(true)
    }
    //行驶证图片查看
    const handleidCardPreview = async (file) => {
        setpreviewidCartVisible(true)
    }
    //行驶证图片查看
    const handleiDPreview = async (file) => {
        setpreviewiDVisible(true)
    }
    // 图片上传
    const handleImgChange = async (info) => {
        if (info.file.status === 'done') {
            let md5 = info?.file?.response?.retData
            setpreviewImage(md5)
            setBusinessLicenseList(info.fileList)
        } else if (info.file.status === 'removed') {
            setBusinessLicenseList([])
            setpreviewImage('')
        }
        console.log(previewImage)
    }
    // 图片上传
    const handleiDImgChange = async (info) => {
        if (info.file.status === 'done') {
            let md5 = info?.file?.response?.retData
            setpreviewiDImage(md5)
            setIdcardTailList(info.fileList)
        } else if (info.file.status === 'removed') {
            setIdcardTailList([])
            setpreviewiDImage('')
        }
        console.log(previewImage)
    }
    // 图片上传
    const handleidcardHeadImgChange = async (info) => {
        if (info.file.status === 'done') {
            let md5 = info?.file?.response?.retData
            setpreviewidCartImage(md5)
            setIdcardHeadList(info.fileList)
        } else if (info.file.status === 'removed') {
            setIdcardHeadList([])
            setpreviewidCartImage('')
        }
        console.log(previewImage)
    }
    const handleBack = () => {
        dispatch({
            type: 'removeTabs',
            targetKey: 'orgManage/insert',
            activeMenu: 'orgManage',
        })
    }

    const businessManagerQuery = (value, key) => {
        setBusinessManager({
            ...businessManager,
            fetching: true,
        })

        platformUserList({
            searchItem: {
                nickname: value,
            },
            pageNum: 1,
            pageSize: 15,
        }).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                const data = results.reduce((arr, item) => {
                    arr.push({
                        text: item.nickname,
                        value: item.id,
                    })
                    return arr
                }, [])
                setBusinessManager({
                    data: data,
                    fetching: false,
                })
            } else {
                console.log('没有查询到')
            }
        })
    }
    //防抖
    const businessManagerQuerys = debounce(businessManagerQuery, 1000)
    //实体信息查询
    const fetchEntity = (value) => {
        setEntityData({
            ...entityData,
            fetching: true,
        })
        let data = {
            searchItem: {
                entityName: value,
            },
            pageNum: 1,
            pageSize: 10,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }
        getBussinessEntityList(data).then((res) => {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.entityName,
                value: team.id,
            }))
            setEntityData({
                data: dataMap,
                fetching: false,
            })
        })
    }
    //防抖
    const fetchEntitys = debounce(fetchEntity, 1000)
    //选择实体
    const handleChangeEntity = (value, option) => {
        form.setFieldsValue({
            ciBusinessEntityName: value ? option.children : undefined,
        })
    }
    return (
        <>
            <div
                style={{
                    paddingLeft: '11px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                }}
            >
                <Radio.Group onChange={onChange} value={value}>
                    <Radio value={1}>区域管理中心</Radio>
                    <Radio value={2}>区域实体店</Radio>
                </Radio.Group>
            </div>

            <div className="list-container">
                <div className="list-header">
                    <div className="list-but">
                        {
                            <Button
                                shape="round"
                                className="but-left"
                                onClick={() => {
                                    form.validateFields().then((values) => {
                                        console.log(values)
                                        organInsert({
                                            ...values,
                                            businessLicense: previewImage,
                                            idcardHead: previewidCartImage,
                                            idcardTail: previewiDImage,
                                            serviceBrand: values.serviceBrand && values.serviceBrand.join(','),
                                            threeGuaranteesBrand: values.threeGuaranteesBrand && values.threeGuaranteesBrand.join(','),
                                            organType: value,
                                            organProvince: signAddrList.organProvince,
                                            organCity: signAddrList.organCity,
                                            organDistrict: signAddrList.organDistrict,
                                            organLng: signAddrList.longitude,
                                            organLat: signAddrList.latitude,
                                            businessEntityName: beList.find((item) => item.code === values.businessEntityCode)?.name,
                                            shopName: shopList.find((item) => item.code === values.shopCode)?.name,
                                        }).then((res) => {
                                            // if (res && res.retCode === 200) {
                                            handleBack()
                                            message.success('新增成功')
                                            // } else {
                                            //     message.error(res.retMsg)
                                            // }
                                        })
                                        // .catch((err) => {
                                        //     if (err && err.retCode === 500) {
                                        //         message.error(err.retMsg)
                                        //     }
                                        // })
                                    })
                                }}
                            >
                                保存
                            </Button>
                        }
                        <Button
                            shape="round"
                            className="but-left"
                            onClick={() => {
                                handleBack()
                            }}
                        >
                            取消{' '}
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout} initialValues={{ autoDispatch: 0, allowTransfer: 0 }}>
                        <Divider orientation="left">基础信息</Divider>

                        <Row>
                            {value == '1' ? null : (
                                <Col span={12}>
                                    <Form.Item label="所属管理中心" name="organBelong">
                                        <Select showSearch optionFilterProp="children" placeholder="请选择所属管理中心" style={{ width: '100%' }}>
                                            {data.map((item) => {
                                                return (
                                                    <Option value={item.id} key={item.id}>
                                                        {item.organName}
                                                    </Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}

                            <Col span={12}>
                                <Form.Item label="机构代码" name="organCode">
                                    <Input disabled placeholder="自动生成" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="机构名称" name="organName" rules={[{ required: true, message: '请输入机构名称' }]}>
                                    <Input placeholder="请输入机构名称" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="联系人" name="organContactName" rules={[{ required: true, message: '请输入联系人' }]}>
                                    <Input placeholder="请输入联系人" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="联系电话" name="organContactPhone" rules={[{ required: true, message: '请输入联系电话' }]}>
                                    <Input placeholder="请输入联系电话" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="默认业务经理" name="defBusinessManager">
                                    <Select
                                        showSearch
                                        placeholder="请选择业务经理"
                                        notFoundContent={businessManager.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onFocus={() => businessManagerQuerys('')}
                                        onSearch={businessManagerQuerys}
                                        style={{ width: '100%' }}
                                    >
                                        {businessManager.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={12}>
                                <Form.Item label="所属实体" name="ciBusinessEntityId">
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="请选择所属实体队"
                                        notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        onChange={handleChangeEntity}
                                        onFocus={() => fetchEntity()}
                                        style={{ width: '100%' }}
                                        onSearch={fetchEntitys}
                                        filterOption={false}
                                    >
                                        {entityData.data.map((item) => (
                                            <Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Form.Item hidden name="ciBusinessEntityName">
                                <Input />
                            </Form.Item>

                            {/* {value == "1" ? ( */}
                            <Col span={12}>
                                <Form.Item label="机构性质" name="organNature" rules={[{ required: true, message: '请选择机构性质' }]}>
                                    <Select showSearch optionFilterProp="children" placeholder="请选择机构性质" style={{ width: '100%' }}>
                                        <Option value={1}>{'自营'}</Option>
                                        <Option value={2}>{'合伙'}</Option>
                                        <Option value={3}>{'加盟'}</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* ) : null} */}

                            <Col span={12}>
                                <Form.Item label="联系地址" name="organContactAddress" rules={[{ required: true, message: '请选择联系地址' }]}>
                                    <Input
                                        ref={ref}
                                        placeholder="点击通过地图选择"
                                        onFocus={() => {
                                            ref.current.blur()
                                            setmapVisible(true)
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="机构自动派单" name="autoDispatch" rules={[{ required: true, message: '请选择联系地址' }]}>
                                    <Radio.Group>
                                        <Radio value={0}>自动派单</Radio>
                                        <Radio value={1}>不自动派单</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="调拨权限" name="allowTransfer" rules={[{ required: true, message: '请选择调拨权限' }]}>
                                    <Radio.Group>
                                        <Radio value={0}>不允许</Radio>
                                        <Radio value={1}>允许</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="门店选择" name="shopCode">
                                    <Select showSearch allowClear optionFilterProp="children" placeholder="请选择门店" style={{ width: '100%' }} onSearch={getShopList} onFocus={() => {getShopList('')}}>
                                        {shopList.map((item) => {
                                            return (
                                                <Option value={item.code} key={item.id}>
                                                    {item.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="实体选择" name="businessEntityCode">
                                    <Select showSearch allowClear optionFilterProp="children" placeholder="请选择实体" style={{ width: '100%' }}>
                                        {beList.map((item) => {
                                            return (
                                                <Option value={item.code} key={item.key}>
                                                    {item.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="单号前缀" name="orderCodePrefix">
                                    <Input placeholder="请输入单号前缀" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation="left">服务信息</Divider>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="维修工数量" name="repairNumber">
                                    <Select showSearch allowClear placeholder="请选择维修工数量" style={{ width: '100%' }}>
                                        {getPcodeDict('033').children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="服务车数量" name="vehicleNumber">
                                    <Select showSearch allowClear placeholder="请选择服务车数量" style={{ width: '100%' }}>
                                        {getPcodeDict('034').children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="场地面积" name="siteArea">
                                    <Input placeholder="请输入场地面积" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="配件仓库面积" name="warehouseArea">
                                    <Input placeholder="请输入配件仓库面积" />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item label="可服务品牌" name="serviceBrand" labelCol={{ span: 4 }} wrapperCol={{ span: 24 }}>
                                    <Checkbox.Group style={{ width: '100%', marginLeft: '26px' }} onChange={(e) => {}}>
                                        <Row>
                                            {/* salesArea 销售区域*/}
                                            {getPcodeDict('035').children.map((item) => (
                                                <Col span={4} key={item.id}>
                                                    <Checkbox value={item.dictCode}>{item.title}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item label="可三包服务品牌" name="threeGuaranteesBrand" labelCol={{ span: 4 }} wrapperCol={{ span: 24 }}>
                                    <Checkbox.Group style={{ width: '100%', marginLeft: '26px' }} onChange={(e) => {}}>
                                        <Row>
                                            {/* salesArea 销售区域*/}
                                            {getPcodeDict('036').children.map((item) => (
                                                <Col span={4} key={item.id}>
                                                    <Checkbox value={item.dictCode}>{item.title}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item label="服务区域" name="serviceArea" labelCol={{ span: 4 }} wrapperCol={{ span: 24 }}>
                                    <Input
                                        ref={refs}
                                        placeholder="通过圈选地图选择"
                                        disabled
                                        addonAfter={
                                            <Button className="map-but" onClick={() => setmap2Visible(true)}>
                                                点击获取地图
                                            </Button>
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation="left">公司信息</Divider>

                        <Row>
                            <Col span={12}>
                                <Form.Item label="实体公司名称" name="companyName">
                                    <Input placeholder="请输入实体公司名称" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="统一信用代码" name="companyCode">
                                    <Input placeholder="请输入统一信用代码" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="法人姓名" name="legalPersonName">
                                    <Input placeholder="请输入法人姓名" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="法人联系方式" name="legalPersonPhone">
                                    <Input placeholder="请输入法人联系方式" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="实体公司营业执照" name="businessLicense" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={businessLicenseList}
                                        onPreview={handlePreview}
                                        onChange={handleImgChange}
                                    >
                                        {businessLicenseList.length >= 1 ? null : <Button>上传图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="法人身份证正面" name="idcardHead" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={idcardHeadList}
                                        onPreview={handleidCardPreview}
                                        onChange={handleidcardHeadImgChange}
                                    >
                                        {idcardHeadList.length >= 1 ? null : <Button>上传图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item label="法人身份证背面" name="idcardTail" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={idcardTailList}
                                        onPreview={handleiDPreview}
                                        onChange={handleiDImgChange}
                                    >
                                        {idcardTailList.length >= 1 ? null : <Button>上传图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Modal visible={previewVisible} title="实体公司营业执照" onCancel={() => setpreviewVisible(false)} onOk={() => setpreviewVisible(false)}>
                    <img alt="" style={{ width: '100%' }} src={`${fileLookUrl().image}${previewImage}`} />
                </Modal>
                <Modal
                    visible={previewidCartVisible}
                    title="法人身份证正面"
                    onCancel={() => setpreviewidCartVisible(false)}
                    onOk={() => setpreviewidCartVisible(false)}
                >
                    <img alt="" style={{ width: '100%' }} src={`${fileLookUrl().image}${previewidCartImage}`} />
                </Modal>
                <Modal visible={previewiDVisible} title="法人身份证背面" onCancel={() => setpreviewiDVisible(false)} onOk={() => setpreviewiDVisible(false)}>
                    <img alt="" style={{ width: '100%' }} src={`${fileLookUrl().image}${previewiDImage}`} />
                </Modal>


                {
                    mapVisible && <InsertAddressMap
                    visible={mapVisible}
                    onCloseMap={() => setmapVisible(false)}
                    saveAddressDetail={changeData}
                    defaultInfo={{
                        lat: signAddrList.latitude,
                        lng: signAddrList.longitude
                    }}
                />
                }

                {/* <ServiceAreaMap
                    path={path}
                    visible={map2Visible}
                    onSubmit={(path) => {
                        form.setFieldsValue({
                            serviceArea: JSON.stringify(path),
                        })
                        refs.current.blur()
                        setmap2Visible(false)
                    }}
                /> */}

                <ChooseAreaMap
                path={path}
                    visible={map2Visible}
                    onSubmit={(path) => {
                        form.setFieldsValue({
                            serviceArea: JSON.stringify(path),
                        })
                        setPath(path)
                        refs.current.blur()
                        setmap2Visible(false)
                    }}
                />

            </div>
        </>
    )
}

export default AddOrg

// docs(changelog):Change '以移除' to '已移除'
