import React, { useEffect, useState } from "react"
import { Button, Form, Input, Col, Row, message, Select, Switch, Upload, Card } from "antd"
import { insertSystem, editSystem, getSystemOne } from "@/service/maintenanceService"
import BraftEditor from "braft-editor"
import { ContentUtils } from "braft-utils"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

const { Option } = Select
const SystemDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null)) //富文本
    const [choseConType, setChoseConType] = useState()
    const [confType, setConfType] = useState([])

    useEffect(() => {
        resetFormItem()
        _getConfType()
    }, [])
    //移动车表单提交
    const systemSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                disableFlag: values.disableFlag ? 0 : 1,
                confVal: choseConType !== "rich_text" ? values.confVal : editorState.toHTML(),
            }
            //新增
            if (mode === "insert") {
                insertSystem(data).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleCancel()
                    message.success("新增成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
                //编辑
            } else if (mode === "edit") {
                editSystem({ ...data, id: rowInfo.id }).then((res) => {
                    // if (res && res.retCode === 200) {
                    handleCancel()
                    message.success("编辑成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
            }
        })
    }

    //获取系统配置信息
    const _getConfType = () => {
        // getConfType().then(res => {
        //     if (res && res.retCode === 200) {
        //         setConfType(res.retData)
        //     }
        // })
        setConfType([
            { tag: "number", desc: "数字" },
            { tag: "string", desc: "字符串" },
            { tag: "json_obj", desc: "json对象" },
            { tag: "json_array", desc: "json数组" },
            { tag: "rich_text", desc: "富文本" },
        ])
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit") {
            getSystemOne(rowInfo.id).then((res) => {
                if (res && res.retCode === 200) {
                    let result = res.retData
                    // result.commodityDesc && setEditorState(BraftEditor.createEditorState(result.commodityDesc));
                    form.setFieldsValue({
                        ...res.retData,
                        disableFlag: res.retData.disableFlag === 0 ? true : false,
                    })
                    //如果是富文本
                    if (result.confType === "rich_text") {
                        setChoseConType(result.confType)
                        setEditorState(BraftEditor.createEditorState(result.confVal))
                    }
                }
            })
        } else {
            //新增默认推荐展示
            form.setFieldsValue({
                disableFlag: true,
            })
        }
    }

    //富文本上传图片
    const uploadHandler = (param) => {
        if (param.file.status === "done") {
            setEditorState(
                ContentUtils.insertMedias(editorState, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
        }
    }

    const extendControls = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    action={`${fileUploadUrl}upload/image_old`}
                    headers={{ authorization: getToken() }}
                    showUploadList={false}
                    onChange={uploadHandler}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        上传图片
                    </button>
                </Upload>
            ),
        },
    ]

    //富文本配置
    const controls = [
        "undo",
        "redo",
        "separator",
        "font-size",
        "line-height",
        "letter-spacing",
        "separator",
        "text-color",
        "bold",
        "italic",
        "underline",
        "strike-through",
        "separator",
        "superscript",
        "subscript",
        "remove-styles",
        "emoji",
        "separator",
        "text-indent",
        "text-align",
        "separator",
        "headings",
        "list-ul",
        "list-ol",
        "blockquote",
        "code",
        "separator",
        "link",
        "separator",
        "hr",
        "separator",
        // 'media', 'separator',
        "clear",
    ]
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "systemConfig",
        })
    }
    //类型切换
    const handleconfTypeChange = (val) => {
        setChoseConType(val)
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">系统配置</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>系统配置信息</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={systemSubmit}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="配置的key"
                                    name="confKey"
                                    rules={[
                                        {
                                            required: true,
                                            message: "配置的key不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入配置的key" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item
                                    label="配置的值"
                                    name="confVal"
                                    rules={[
                                        {
                                            required: choseConType !== "rich_text",
                                            message: "配置的不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入配置的值" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="配置类型"
                                    name="confType"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择配置类型",
                                        },
                                    ]}
                                >
                                    <Select showSearch allowClear placeholder="请选择配置类型" style={{ width: "100%" }} onChange={handleconfTypeChange}>
                                        {confType.map((item) => (
                                            <Option value={item.tag} key={item.tag}>
                                                {item.desc}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="请输入备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item name="disableFlag" label="使用状态" valuePropName="checked">
                                    <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {choseConType === "rich_text" ? (
                    <Row>
                        <Col span={24}>
                            <Card title="富文本值:">
                                <BraftEditor value={editorState} controls={controls} onChange={(e) => setEditorState(e)} extendControls={extendControls} />
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <></>
                )}
            </div>
        </>
    )
}
export default SystemDetails
