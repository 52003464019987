import React, { useEffect } from "react";
import { Table } from "antd";
import { useStockDetailedModel } from "../stockDetailedModel";
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import { formatNum } from "@/erp_subpackage/utils/util";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,selectedKeys,setSelectedKeys
    } = useStockDetailedModel();
    const { setOccupyStockInfo,setMaterialInfo } = useGlobalModel(() => []);

    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 66,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 160,
            dataIndex: 'materialCode',
            // render: (text) => <YhTooltip text={text} />,
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '品牌',
            width: 120,
            dataIndex: 'brandName',
        }, {
            title: '仓库名称',
            width: 120,
            dataIndex: 'warehouseName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '仓库编号',
            width: 180,
            dataIndex: 'warehouseCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '仓库类型',
            width: 180,
            dataIndex: 'warehouseTypeName',
        }, {
            title: '货位编号',
            width: 160,
            dataIndex: 'shelfCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '所属机构',
            width: 160,
            dataIndex: 'shopName',
            render: (text) => <YhTooltip text={text} />,
        },
        //  {
        //     title: '初始库存数量',
        //     width: 100,
        //     dataIndex: 'inventoryInitial',
        // },
         {
            title: '库存数量',
            width: 100,
            dataIndex: 'inventoryNum',
        }, {
            title: '占用库存数量',
            width: 100,
            dataIndex: 'inventoryOccupied',
            render: (text, record) => <p className="one-line click-span" onClick={(e) => {
                e.stopPropagation();
                setOccupyStockInfo(true, {
                    materialCode: record.materialCode
                });
            }}>{text}</p>,
        }, {
            title: '可用库存数量',
            width: 100,
            dataIndex: 'availableStock',
        }, {
            title: '入库数量',
            width: 100,
            dataIndex: 'num',
        }, {
            title: '入库单价',
            width: 100,
            dataIndex: 'latestPurPrice',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        }, {
            title: '入库金额',
            width: 100,
            dataIndex: 'amount',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        }, {
            title: '最近销价',
            width: 100,
            dataIndex: 'lateSalePrice',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        }, {
            title: '库存金额',
            width: 100,
            dataIndex: 'totalAmount',
            render: text => typeof text === "number" ? `￥${formatNum(text)}` : ""
        }, {
            title: '供应商编号',
            width: 180,
            dataIndex: 'supplierCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '供应商名称',
            width: 210,
            dataIndex: 'supplierName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '采购单号',
            width: 210,
            dataIndex: 'purchaseCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '入库日期',
            width: 160,
            dataIndex: 'stockIoTime',
        }, {
            title: '替换编号',
            width: 160,
            dataIndex: 'replaceCode',
        }
    ];

    //点击行选高亮
    const onRow = (record) => {
        let findIndex = selectedRowKeys.indexOf(record.id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(record.id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
        let findObj = selectedKeys.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedKeys = selectedKeys.filter(item => item.id !== record.id);
            setSelectedKeys(newSelectedKeys);
        } else {
            setSelectedKeys(origin => [...origin, record]);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.id);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                    selected ? setSelectedKeys((origin) => [...origin, ...changeRows])
                    : setSelectedKeys((origin) => {
                        let changeRowId = changeRows.map(item => item.id);
                        return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                    });
                    },
                onSelect: (record) => onRow(record),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                    setSelectedKeys([record]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
