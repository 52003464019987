import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

class SaleSoService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/sale/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async total(searchVo) {
        const apiUrl = "/ci-storage/sale/total";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/sale/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }

    async insert(insertVo) {
        const apiUrl = "/ci-storage/sale/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/sale/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params) {
        const apiUrl = "/ci-storage/sale/materialDelete";
        return await httpRequest.post(apiUrl, params);
    }
    //导出
    async export(params) {
        const apiUrl = "/ci-analyze/sale/export";
        return await httpRequest.post(apiUrl, params);
    }
    //转销售单
    async transfer(planCode) {
        const apiUrl = `/ci-storage/sale/transfer/${planCode}`;
        return await httpRequest.get(apiUrl);
    }
    // //详情导入
    // async invalidate(){
    //     const apiUrl = "/ci-storage/sale/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/ci-storage/sale/detail/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    //作废
    async invalidate(params) {
        const apiUrl = "/ci-storage/sale/invalidate";
        return await httpRequest.post(apiUrl, params);
    }
    //库房确认
    async warehousing(params) {
        const apiUrl = "/ci-storage/sale/warehousing";
        return await httpRequest.post(apiUrl, params);
    }
    //销售结算
    async settle(params) {
        const apiUrl = "/ci-storage/sale/settlePro";
        return await httpRequest.post(apiUrl, params);
    }
    //加盟商销售结算
    async settleJoin(params) {
        const apiUrl = "/ci-storage/sale/settleJoin";
        return await httpRequest.post(apiUrl, params);
    }
    //销售补单
    async supplementInsert(insertVo) {
        const apiUrl = "/ci-storage/sale/supplementInsert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //详情导出
    async detailsExport(params) {
        const apiUrl = "/ci-analyze/sale/detailsExport";
        return await httpRequest.post(apiUrl, params);
    }
    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/sale/print/${id}`;
        return await httpRequest.get(apiUrl);
    }

    //查询特定得收款账户
    accountFind(params) {
        const apiUrl = "/fms/account/find";
        return httpRequest.post(apiUrl, params);
    }

    // 商城审核(1 驳回 0 通过)
    examine(params) {
        const apiUrl = '/ci-storage/sale/examine'
        return httpRequest.post(apiUrl, params)
    }
    // 商城审核(1 驳回 0 通过)
    updateStatus(obj) {
        const apiUrl = '/ci-storage/sale/updateStatus'
        return httpRequest.post(apiUrl, obj)
    }
    //查询最高售价和最低售价
    async price(obj){
        const apiUrl = '/ci-storage/sale/customerHigAndLow';
        return await httpRequest.post(apiUrl,obj);
    }
}

export const saleSoService = new SaleSoService();