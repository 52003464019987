import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getBussinessCostList, bussinessCostDelete } from "@/service/dataService"
import { getTeamMemberList } from "@/service/teamService"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import Audit from "./audit"
import { Modal, Select, Button } from "antd"
import { organPage } from "@/service/orgService"
import { getBussinessCategoryList } from "@/service/dataService"
const { Option } = Select

export default () => {
    const { state, message, showAllOrgan } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [isAudit, setIsAudit] = useState(false)
    const [auditId, setAuditId] = useState("")
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [org, setOrg] = useState([])

    useEffect(() => {
        if (state.activeMenu === "operatCost") {
            loadData(pagination.current)
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { disableFlag: 0, ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "applyTime",
                    rule: "DESC",
                },
            ],
            startTime: search.startTime,
            endTime: search.endTime,
        }
        getBussinessCostList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的经营成本信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除该经营成本?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                bussinessCostDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的经营成本")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的经营成本")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    // //开关
    // const clientSweitchChange = (checked, e, id) => {
    //     e.stopPropagation()
    //     bussinessCostUpdate(id, checked ? 0 : 1).then((res) => {
    //         message.success("操作成功")
    //         loadData(1)
    //     })
    // }

    const handleToAudit = (id) => {
        setIsAudit(true)
        setAuditId(id)
    }

    //table配置
    const columns = [
        {
            title: "项目名称",
            align: "center",
            dataIndex: "projectName",
        },
        // {
        //     title: "申请类型",
        //     align: "center",
        //     dataIndex: "applicantType",
        //     render: (text, record) => <>{text == 1 ? "个人" : "团队"}</>,
        // },
        {
            title: "申请对象",
            align: "center",
            dataIndex: "teamUserName",
        },
        {
            title: "申请团队",
            align: "center",
            dataIndex: "teamName",
        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
        },
        {
            title: "费用",
            align: "center",
            dataIndex: "cost",
        },
        {
            title: "申请时间",
            align: "center",
            dataIndex: "applyTime",
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "status",
            render: (text, record) => (
                <>
                    {text === 1 && (
                        <Button
                            type="primary"
                            onClick={() => {
                                handleToAudit(record.id)
                            }}
                        >
                            待审核
                        </Button>
                    )}
                    {text === 2 && <span style={{ color: "#2e3aeb" }}>已通过</span>}
                    {text === 3 && <span style={{ color: "#f53f3f" }}>已驳回</span>}
                </>
            ),
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "userAccount",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: "searchSelect",
                placeholder: "请选择项目",
                valueName: "projectId",
                optionName: "categoryName",
                optionId: "id",
                searchItemName: "projectName",
                searchApi: getBussinessCategoryList,
                
            },
            {
                type: "searchSelect",
                placeholder: "请选择申请人",
                valueName: "teamUserId",
                optionName: "userName",
                optionId: "id",
                searchItemName: "userName",
                searchApi: getTeamMemberList,
                
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "operatCost" && (
                <>
                    <TopSearch
                        title="经营成本"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="经营成本"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            org={org}
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                    {isAudit && (
                        <Audit
                            org={org}
                            auditId={auditId}
                            visible={isAudit}
                            setVisible={setIsAudit}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
