import React, { FC } from "react";
import { Description } from "./Description";
import "./box.less";

export const YhBox = (props) => {
    const {
        title,
        descriptions,
        action,
        width,
        maxHeight,
        children,
        rightChildren,
        contentClass,
        style = {},
        hidden = false
    } = props;
    return (
        <div
            className={`yh-list-container ${width ? 'inline-block' : ''}`}
            style={{ width: width ?? 'auto', ...style }}
            hidden={hidden}
        >
            <div
                hidden={!title && !(descriptions && descriptions.length >= 0)}
                className={`list-header ${width ? '' : 'default flex  justify-between'}`}
            >
                <div
                    hidden={!title}
                    className="list-header-title"
                >
                    {title}
                </div>
                <div
                    hidden={!(rightChildren || descriptions && descriptions.length >= 0)}
                    className={`list-header-content ${width ? 'flex  justify-between' : ''}`}
                >
                    {rightChildren ? rightChildren : descriptions?.map((el, index) => <Description key={index} {...el} />)}
                </div>
            </div>

            <div
                hidden={!(action)}
                className="list-buttons"
            >
                {
                    action
                }
            </div>
            <div className={`list-content ${maxHeight ? 'overflow-hidden overflow-y-auto' : ''} ${contentClass ? contentClass : ""}`}
                style={maxHeight ? { maxHeight: maxHeight } : {}}>
                {
                    children
                }
            </div>
        </div>
    )
}