import React, { useState, useEffect } from "react"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { Button, Table, message } from 'antd'
import { usePrebookModel } from "../prebookModel";
import { EditableRow, EditableCell } from '../../pubEditble'
import { MaterialHistory } from '@/erp_subpackage/pages/common/materialHistory/index'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import AddMaterial from "./MaterialAdd"
import YhTooltip from '@/components/YhTooltip'
import YhIcon from "@/components/pubIcon"

export const Material = ({ modeKey, mode, rowInfo, dispatch, formRef }) => {

    const { user: { shopId } } = useGlobalModel()
    const [loading, setLoading] = useState(false);
    const [addVisible, setAddVisible] = useState(false);
    const { getInfo, getMaterialList, setMaterialList } = usePrebookModel();
    const [pagination, setPagination] = useState({ total: 0, current: 1, pageSize: 10 })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [staticInfo, setStaticInfo] = useState({ materialAmount: 0, materialQuantity: 0 })
    const [historyData, setHistoryData] = useState({ visible: false, materialCode: '', materialName: '', shopId: '' })

    useEffect(() => {
        setPagination({ total: getMaterialList(mode) && getMaterialList(mode).length, current: 1, pageSize: 10, })

        let materialAmount = 0;
        getMaterialList(mode) && getMaterialList(mode).forEach(e => {
            materialAmount = Number(materialAmount) + Number(e.actualAmount);
            materialAmount = materialAmount.toFixed(2);
        });
        setStaticInfo({ materialAmount: materialAmount, materialQuantity: getMaterialList(mode) && getMaterialList(mode).length });
    }, [getMaterialList(mode)])

    const onTableChange = async (pagination) => {
        setPagination({ ...pagination, current: pagination.current })
    }

    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "商品编号",
            width: 200,
            align: "center",
            dataIndex: "materialCode",
            sorter: (a, b) => a.materialCode.localeCompare(b.materialCode),
            render: (text, record) => (
                <YhTooltip
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                        setHistoryData({ visible: true, materialCode: record.materialCode, materialName: record.materialName, shopId: shopId })
                    }}
                    text={text}
                />
            ),
        },
        {
            title: "商品名称",
            width: 200,
            align: "center",
            dataIndex: "materialName",
        },
        {
            title: "品牌",
            width: 120,
            align: "center",
            dataIndex: "brandName",
        },
        {
            title: "单位",
            width: 120,
            align: "center",
            dataIndex: "unitName",
        },
        {
            title: "可用库存",
            width: 120,
            align: "center",
            dataIndex: "availableStock",
        },
        {
            title: "数量",
            width: 120,
            align: "center",
            dataIndex: "materialNumber",
            editable: true,
        },
        {
            title: "销售单价",
            width: 120,
            align: "center",
            dataIndex: "salePrice",
            editable: true,
        },
        {
            title: "总计",
            width: 120,
            align: "center",
            dataIndex: "materialAmount",
        },
        {
            title: "优惠",
            width: 120,
            align: "center",
            dataIndex: "preferentialAmount",
            editable: true,
        },
        {
            title: "折扣",
            width: 120,
            align: "center",
            dataIndex: "materialDiscount",
            editable: true,
        },
        {
            title: "总金额",
            width: 120,
            align: "center",
            dataIndex: "actualAmount",
        },
        {
            title: "备注",
            width: 200,
            align: "center",
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            width: 120,
            align: "center",
            dataIndex: "operation",
            render: (_, record) => {
                return (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                    <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteOne(record)} />
            },
        },
    ]

    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    //单元格编辑保存
    const materialHandleSave = (row, status) => {
        row.materialAmount = (row.materialNumber * row.salePrice).toFixed(2);
        if (row.materialDiscount > 0) {
            row.actualAmount = ((row.materialAmount * row.materialDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualAmount = (row.materialAmount - row.preferentialAmount).toFixed(2)
        }

        const newData = [...getMaterialList(mode)]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setMaterialList(mode, newData);
    }

    //新增物料回调
    const chooseMaterialCbk = (materials) => {
        setAddVisible(false)
        let arr = []
        materials.forEach((item) => {
            if (!getMaterialList(mode).find((m) => m.inventoryDetailsId === item.id)) {
                let newItem = {
                    id: new Date().getTime() + parseInt(Math.random() * 100000),
                    materialCode: item.materialCode,
                    materialName: item.materialName,
                    unitCode: item.unitCode,
                    unitName: item.unitName,
                    brandCode: item.brandCode,
                    brandName: item.brandName,
                    salePrice: item.lateSalePrice || item.retailPrice,
                    purchasePrice: item.latestPurPrice,
                    availableStock: item.availableStock,
                    materialNumber: item.num,
                    materialAmount: (item.lateSalePrice || item.retailPrice) * item.num,
                    materialDiscount: 0,
                    preferentialAmount: 0,
                    actualAmount: ((item.lateSalePrice || item.retailPrice) * item.num).toFixed(2),
                    remark: '',
                    purchaseDetailId: item.id,
                }
                arr.push(newItem)
            }
        })
        setMaterialList(mode, [...getMaterialList(mode), ...arr])
    }

    const onRow = (record) => {
        let findIndex = selectedRowKeys.indexOf(record.id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(record.id);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const deleteOne = (record) => {
        let list = getMaterialList(mode).filter((item) => item.id !== record.id);
        setMaterialList(mode, list);
        setSelectedRowKeys([]);
    };

    const deleteBatch = () => {
        let list = getMaterialList(mode);
        selectedRowKeys.forEach((e) => {
            list = list.filter((item) => item.id !== e)
        })
        setMaterialList(mode, list);
        setSelectedRowKeys([]);
    };

    const add = () => {
        let fields = formRef.current.getFieldsValue()
        let orderType = fields && fields.orderType;
        if (!orderType) {
            message.error("请先选择工单类型")
            return
        }
        setAddVisible(true);
    };

    const action =
        <>
            {getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px", position: 'relative', top: 5 }} onClick={() => add()} />
            }
            <Button
                className="btn-item"
                type="link"
                shape="round"
                disabled={getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) ? false : true}
                onClick={deleteBatch}
                style={{ margin: "10px" }}>
                批量删除
            </Button>
        </>

    return (
        <>
            <YhBox title="维修用料" rightChildren={action} style={{ marginBottom: '10px' }}>
                <div style={{ height: 0, position: 'relative', float: 'left', top: -50, left: 100, }}>
                    总金额￥<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.materialAmount}</span>元（{staticInfo.materialQuantity}项）
                </div>
                <Table
                    size="small"
                    columns={MaterialColumns}
                    components={{ body: { row: EditableRow, cell: EditableCell } }}
                    dataSource={getMaterialList(mode)}
                    rowKey={(record) => record.id}
                    loading={loading}
                    onChange={onTableChange}
                    rowSelection={{
                        onSelectAll: (selected, selectedRows, changeRows) => {
                            let changeRowId = changeRows.map(item => item.id);
                            if (selected) {
                                setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                            }
                            else {
                                setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                            }
                        },
                        onSelect: (record, selected, selectedRows) => onRow(record),
                        selectedRowKeys: selectedRowKeys
                    }}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${pagination.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                />
            </YhBox>
            <AddMaterial visible={addVisible} onClose={(bool) => setAddVisible(bool)} onItemOk={chooseMaterialCbk} />
            {historyData.visible && (
                <MaterialHistory {...historyData} onCancel={() => setHistoryData({ visible: false, materialCode: '', materialName: '', shopId: '' })} />
            )}
        </>
    )
}
