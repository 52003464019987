import React, { useContext, useEffect, useState } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { getFleetList, disableFleet } from "@/service/fleetService"
import { MyContext } from "@/store/myContext"
import YhTooltip from '@/components/YhTooltip'
//车队列表
const MotorcadeRecord = () => {
    const { dispatch, state, message, detailsPage, Switch, Option } = useContext(MyContext)
    const [motorcadeList, setMotorcadeList] = useState(null) //数据列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState({}) //搜索
    useEffect(() => {
        if (state.activeMenu === "motorcadeRecord") {
            loadData(pagination.current)
        }
    }, [state])

    //默认加载
    const loadData = (current, search = {}) => {
        let data = {
            ...search,
            pageNum: current,
            pageSize: 10,
            searchItem: {},
            // orders: [{ column: "createTime", rule: "DESC" }]
        }
        getFleetList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                    setLoading(false)
                    setMotorcadeList(res.retData.list)
                // }
            })
            .catch((err) => {
                setMotorcadeList([])
                setLoading(false)
            })
    }

    //新增
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "车队新增",
            edit: "车队编辑",
            look: "车队查看",
        }
        let modeKey = "motorcadeRecord/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的车队信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的车队信息")
                return
            }

            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.MotorcadeDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //车队开关
    const fleetSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        disableFleet(id, checked ? 0 : 1).then((res) => {
            // if (res && res.retCode === 200) {
                message.success("操作成功")
                loadData(1)
            // } else {
            //     message.error(res.retMsg)
            // }
        })
    }
    //table配置
    const columns = [
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            render:  (text) => <YhTooltip maxWidth={600} text={text}/>,
        },
        {
            title: "车队名称",
            align: "center",
            dataIndex: "motorcadeName",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "队长姓名",
            align: "center",
            dataIndex: "captainName",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "队长电话",
            align: "center",
            dataIndex: "captainPhone",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
            render:  (text) => <YhTooltip maxWidth={300} text={text}/>,
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "fleetDesc",
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "motorcadeStatus",
            render: (text, record) => (
                <Switch size="small" checked={text == 0 ? true : false} onChange={(checked, e) => fleetSweitchChange(checked, e, record.id)} />
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "请输入车队名称",
                valueName: "motorcadeName",
            },
            {
                type: "input",
                placeholder: "请输入队长名称",
                valueName: "captainName",
            },
            {
                type: "select",
                placeholder: "请选择车队状态",
                valueName: "motorcadeStatus",
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            开启
                        </Option>
                        <Option value={1} key={1}>
                            禁用
                        </Option>
                    </>
                ),
            },
            {
                type: "RangePicker",
                valueName: ["createTime", "updataTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
    ]
    return (
        <>
            {state.activeMenu === "motorcadeRecord" && (
                <>
                    <TopSearch
                        title="车队管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="车队列表"
                        buttons={topButtons}
                        loading={loading}
                        columns={columns}
                        dataSource={motorcadeList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
export default MotorcadeRecord
