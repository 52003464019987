import React from "react";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { usePurchaseAdvancedDetailsModel } from "./purchaseAdvancedDetailModel";
import { formatNum } from "@/erp_subpackage/utils/util";

export const FixedBottom = ({ modeKey, mode, rowCode, dispatch }) => {
    const { collapsed } = useGlobalModel(({ collapsed }) => [collapsed]);
    const { addDesc, editDesc, lookDesc } = usePurchaseAdvancedDetailsModel();
    const typeDesc = {
        add: addDesc,
        edit: editDesc,
        look: lookDesc
    }
    const orderStatistics = [
        {
            label: "采购项数",
            value: typeDesc[mode].itemNumAll,
            color: "red",
            status: true
        },
        {
            label: "采购数量",
            value: formatNum(typeDesc[mode].numAll),
            color: "red",
            status: true
        },
        {
            label: "采购总金额",
            value: formatNum(typeDesc[mode].totalAll),
            color: "red",
            status: true
        },
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${collapsed ? '80px' : '200px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}