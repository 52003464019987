import React from "react";
import { Button } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useStockStatisticModel } from "../stockStatisticModel";
import { DataTable } from "./DataTable";
import { formatNum } from '@/erp_subpackage/utils/util';

export const DataBox = () => {
    const { selectedRowKeys, descTotal, rowsDescTotal, exportList } = useStockStatisticModel();

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => exportList()}
        >导出</Button>
    </>;

    const desc = [
        {
            label: "库存总数",
            value: !selectedRowKeys.length ? descTotal.stockNum : rowsDescTotal.total,
            color: "red"
        },
        // {
        //     label: "库存种类",
        //     value: !selectedRowKeys.length ? descTotal.type : rowsDescTotal.type,
        //     color: "red"
        // },
        {
            label: "库存成本（最近）",
            value: "￥" + formatNum(!selectedRowKeys.length ? descTotal.stockAmount : rowsDescTotal.latestAmountTotal),
            color: "red"
        },
        // {
        //     label: "库存成本（平均）",
        //     value: !selectedRowKeys.length ? descTotal.averageAmount : rowsDescTotal.averageAmount,
        //     color: "red"
        // }
    ]


    return (
        <YhBox title="库存统计列表" action={action} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}

