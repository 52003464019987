import React, { useContext, useState, useEffect } from "react"
import { message } from "antd"
import { YhFormBox } from '@/erp_subpackage/components/YhFormBox'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { organPage } from "@/service/orgService"
import { getTeamList, getTeamMemberList } from "@/service/teamService"
import { getUserList } from '@/service/customerService'
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import { usePrebookModel } from "../prebookModel";

export const FormBox = ({ mode, rowInfo, formRef }) => {

    const { showAllOrgan } = useContext(MyContext)
    const { user: { organTypeCode, organId }, orgDs } = useGlobalModel();
    const { getInfo, getPrebookOne } = usePrebookModel();

    const [orgDisable, setOrgDisable] = useState(false);
    const [teamInfo, setTeamInfo] = useState({ data: [], fetching: false });
    const [memberInfo, setMemberInfo] = useState({ id: "", data: [], fetching: false });

    useEffect(() => {
        getPrebookOne(mode, rowInfo.id);

        //平台机构可选择其他机构，非平台机构默认本机构并不可编辑
        if (organTypeCode != 3) {
            setOrgDisable(true);
        }
    }, [])


    //团队列表查询
    const teamQuery = (value) => {
        let fields = formRef.current.getFieldsValue()
        let organId = fields && fields.organId;
        if (!organId && showAllOrgan) {
            message.error("请先选择机构")
            return
        }
        setTeamInfo({
            ...teamInfo,
            fetching: true,
        })
        let data = {
            searchItem: { organId },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getTeamList(data).then((res) => {
            if (res && res.retCode === 200) {
                let results = res.retData.list
                const data = results.map((item) => ({
                    text: item.teamName,
                    value: item.id,
                }))
                setTeamInfo({
                    data: data,
                    fetching: false,
                })
            }
        })
    }

    //成员列表查询
    const memberQuery = (value) => {
        setMemberInfo({
            ...memberInfo,
            data: [],
            fetching: true,
        })
        let fields = formRef.current.getFieldsValue();
        let teamId = fields && fields.teamId;
        if (!teamId) {
            message.error("请先选择团队")
            return
        }

        getTeamMemberList({
            pageNum: 1,
            pageSize: 10000,
            searchItem: { disableFlag: 0, userName: value, teamId },
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.userName,
                    value: item.id,
                    userName: item.userName,
                    userPhone: item.userPhone,
                })
                return arr
            }, [])
            setMemberInfo({
                ...memberInfo,
                data: data,
                fetching: false,
            })
        })
    }

    //客户选择
    const mainRepairChange = (val, option) => {
        if (val && option) {
            let { label, value } = option
            formRef.current.setFieldsValue({
                allRepair: [value]
            });
        }
    }

    //用户查询
    const userQuery = async (value) => {
        let D = {
            pageSize: 15,
            currentPage: 1,
            realName: value,
            customerId: rowInfo.customerId,
        }
        return await getUserList(D).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                return results.map((item) => ({
                    key: item.id,
                    id: item.id,
                    label: item.userName + '(' + item.userMobile + ')',
                    value: item.id,
                    repairContactPhone: item.userMobile,
                    repairContactName: item.userName,
                }))
            } else {
                message.warning('该客户下没有用户')
                return [];
            }
        })
    }

    const userChange = (value, option) => {
        if (value && option) {
            let { repairContactPhone, repairContactName } = option
            formRef.current.setFieldsValue({
                repairContactPhone: repairContactPhone,
                repairContactName: repairContactName,
            });
        }
    }

    const baseFormItem = {
        title: ' ',
        style: { padding: '0 20px', marginBottom: '10px' },
        contentClass: 'pr-77',
        items: [
            {
                type: "Select",
                fieldLabel: '选择机构',
                fieldName: "shopId",
                disable: orgDisable,
                options: orgDs,
                span: 8
            },
            {
                type: "Select",
                fieldLabel: '选择团队',
                fieldName: 'teamId',
                onSelectFocus: () => teamQuery(),
                onSelectChange: () => memberQuery(),
                options: teamInfo.data.map((item) => {
                    return ({ label: item.text, value: item.value, key: item.value })
                }),
                rules: [{ required: true, message: '请选择团队' }],
                span: 8
            },
            {
                type: "Select",
                fieldLabel: '主维修人',
                fieldName: 'mainRepair',
                onSelectFocus: () => memberQuery(),
                onSelectChange: mainRepairChange,
                options: memberInfo.data.map((item) => {
                    return ({ label: item.text, value: item.value, key: item.value })
                }),
                rules: [{ required: true, message: '请选择主维修人' }],
                span: 8
            },
            {
                type: "Select",
                fieldLabel: '团队成员',
                fieldName: 'allRepair',
                selectMode: "multiple",
                onFocus: () => memberQuery(),
                options: memberInfo.data.map((item) => {
                    return ({ label: item.text, value: item.value, key: item.value })
                }),
                rules: [{ required: true, message: '请选择团队成员' }],
                span: 8
            },
            {
                type: 'AsyncSelect',
                fieldLabel: '报修用户',
                fieldName: 'customerUserId',
                selectfetchList: userQuery,
                onSelectChange: userChange,
                defaultQuery: "%",
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: '请选择报修用户' }],
                span: 8
            },
            {
                type: "Select",
                fieldLabel: '服务方式',
                fieldName: "serviceMethod",
                options: getPcodeDict('012').children.map(item => {
                    return ({ label: item.title, value: item.dictCode, key: item.id })
                }),
                span: 8
            },
            {
                type: "Select",
                fieldLabel: '故障类型',
                fieldName: "serviceType",
                options: getPcodeDict('011').children.map(item => {
                    return ({ label: item.title, value: item.dictCode, key: item.id })
                }),
                span: 8
            },
            {
                type: "Select",
                fieldLabel: '工单类型',
                fieldName: "orderType",
                options: getPcodeDict('014').children.map(item => {
                    return ({ label: item.title, value: item.dictCode, key: item.id })
                }),
                span: 8
            },
            {
                type: 'Input',
                fieldLabel: '联系人',
                fieldName: 'repairContactPhone',
                hidden: true
            },
            {
                type: 'Input',
                fieldLabel: '联系电话',
                fieldName: 'repairContactName',
                hidden: true
            },
            {
                type: 'Input',
                fieldLabel: '备注',
                fieldName: 'remark',
                span: 8
            },
        ]
    }

    const poFromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: 'right',
        disabled: false,
        formValues: getInfo(mode),
        onChange: (changedValues) => { },
        boxs: [baseFormItem],
    }

    return <YhFormBox {...poFromConfig} />
}
