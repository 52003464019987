import React, { useEffect, useRef, useState } from "react"
import { Button, message, Modal, Radio } from "antd"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { debounce } from "lodash"
import { usePurchaseRoDetailsModel } from "./purchaseRoDetailModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { getBentityListOption } from '@/utils/dataStorage'

export const FormBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const {
        editFormVal, addFormVal, lookFormVal,
        updateFormVal, updateList,
        getPurchaseRoOne,
        insertDetail,
        editDetail,saveBtnLoading,setSaveBtnLoading
    } = usePurchaseRoDetailsModel()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const { fetchSupplierList } = useGlobalModel(() => []);
    const formRef = useRef()
    const [more, setMore] = useState(true);  //控制更多按钮
    const formVal = mode === "edit" ? editFormVal : mode === "look" ? lookFormVal : addFormVal;

    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (mode === "edit" || mode === "look") {
            getPurchaseRoOne(rowCode, mode)
        }
        return () => {
            updateFormVal(mode)
            updateList(mode)
        }
    }, [rowCode])

    //关闭当前页
    const onClose = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "purchaseRo",
        })
    }

    const onChange = (changedValues) => {
        updateFormVal(mode, changedValues);
    }

    const reset = () => {
        getPurchaseRoOne(rowCode, mode).then(res => {
            message.success("刷新成功")
        })
    }

    //销售实体
    const bentityChange = (value, option) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value })
    }

    const documentChange = (value, option) => {
        option && onChange({ documentTypeName: option.label, documentTypeCode: option.value });
    }

    //表单提交
    const onSubmit = debounce(async () => {
        formRef.current.validateFields().then((val) => {
            let data = {
                ...val,
                invoiceTypeName: getTreeNodeName(val.invoiceTypeCode)
            }
            console.log(data)
            if (mode === "edit") {
                editDetail(data).then(res => {
                    res && getPurchaseRoOne(rowCode, mode)
                })
            }
            else {
                insertDetail(data).then(res => {
                    res && onClose()
                })
            }
        }).catch((err) => {
            setSaveBtnLoading(false)
            message.warning(getFormValidatorErrText(err))
        })
    }, 900)

    //供应商选中  回显供应商类型、联系人、电话
    const supplierChange = (value, option) => {
        if (option && option.id) {
            let { label, value, type_code, type_name, contact, phone } = option;
            updateFormVal(mode, {
                contact, phone,
                supplierName: label,
                supplierCode: value,
                supplierTypeCode: type_code,
                supplierTypeName: type_name
            });
        }
    }

    //选择联系人
    const handleContact = async () => {
        if (!formVal.supplierCode) {
            return message.warning("请选择联系人后进行操作！")
        };
        let ret = await fetchSupplierList(formVal.supplierName);
        if (ret && ret.length) {
            let options = [];
            if (ret[0].contact) {
                options.push({ contact: ret[0].contact, phone: ret[0].phone })
            }
            if (ret[0].name2) {
                options.push({ contact: ret[0].name2, phone: ret[0].phone2 })
            }
            let activeIndex = -1;
            Modal.confirm({
                title: `联系人选择【${formVal.supplierName}】`,
                content: <Radio.Group onChange={(e) => { activeIndex = e.target.value }}>
                    {options.map((item, index) => <Radio value={index} key={index}>{item.contact}</Radio>)}
                </Radio.Group>,
                okText: '确定',
                cancelText: '取消',
                onOk: () => new Promise((resolve, reject) => {
                    if (activeIndex > -1) {
                        resolve(activeIndex);
                    } else {
                        message.warning("请选择联系人");
                        reject('请选择联系人');
                    }
                }).then(res => {
                    updateFormVal(mode, {
                        contact: options[activeIndex].contact,
                        phone: options[activeIndex].phone
                    });
                }),
            });
        }
    }

    const disabledFromBtn = () => {
        let status = formVal.status;
        if (mode === "look") return false;
        if (mode === "add") return true;

        if (mode === "edit" &&
            (status === DictCodeEnmu.PO_SETTLE ||
                status === DictCodeEnmu.PO_COMPLETELY_IO || formVal.status === "purchase-status.paying")
        ) {
            return false
        }
        return true;
    }


    const rightChildren = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={onSubmit}
            hidden={mode === "look"}
            loading={saveBtnLoading}
        >{saveBtnLoading ? '保存中' : '保存'}</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => reset()}
            hidden={mode === "add"}
        >刷新</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={onClose}
        >返回</Button>
    </>

    const baseFormItem = {
        title: "基本信息",
        style: { padding: "0 20px", marginBottom: "10px" },
        rightChildren,
        items: [
            {
                type: "Input",
                fieldLabel: "退货单号",
                fieldName: "returnCode",
                disable: true,
                placeholder: "自动生成"
            }, {
                type: "AsyncSelect",
                fieldLabel: "供应商名称",
                fieldName: "supplierName",
                onSelectChange: supplierChange,
                placeholder: "供应商",
                rules: [{ required: true, message: "请选择供应商" }],
                selectfetchList: fetchSupplierList,
                defaultQuery: formVal.supplierName
            }, {
                type: "Input",
                fieldLabel: <span className="click-span" onClick={handleContact}>联系人</span>,
                fieldName: "contact",
                rules: [{ required: true, message: "请填入联系人" }]
            }, {
                type: 'Select',
                fieldLabel: '采购实体',
                fieldName: 'bentityCode',
                options: getBentityListOption(),
                onSelectChange: bentityChange,
                rules: [{ required: true, message: "请选择采购实体" }]
            }, {
                type: "Input",
                fieldLabel: "供应商类型",
                fieldName: "supplierTypeName",
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "联系电话",
                fieldName: "phone",
                rules: [{ required: true, message: "请输入联系电话" }]
            }, {
                type: "Input",
                fieldLabel: "退货地址",
                fieldName: "returnAddress",
            }, {
                type: "Input",
                fieldLabel: "所属机构",
                fieldName: "shopName",
                disable: true,
            }, {
                type: "Select",
                fieldLabel: "发票类型",
                fieldName: "invoiceTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
            }, {
                type: "Select",
                fieldLabel: "结算类型",
                fieldName: "payType",
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                rules: [{ required: true, message: "请选择结算类型" }]
            },
            {
                type: "Select",
                fieldLabel: "单据类型",
                fieldName: "documentTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.PRETURN_TYPE),
                rules: [{ required: true, message: "采退类型" }],
                onSelectChange: documentChange,
            },{
                type: "Input",
                fieldLabel: "退货原因",
                fieldName: "returnReason",
                span: 5,
                labelCol: { span: 4 },
                colClassName: "detail-note-col",
                rules: [{ required: true, message: "请输入退货原因" }]
            }, {
                type: "Button",
                fieldLabel: <a className="color-blue text-line" onClick={() => setMore(!more)}>{more ? '更多>>' : "隐藏"}</a>,
                fieldName: "",
                span: 1,
                labelCol: { span: 20, offset: 4 },
            }, {
                type: 'FormUpload',
                fieldLabel: '附件',
                fieldName: 'fileName',
                fieldMd5: 'md5',
                defaultFileName: formVal.fileName,
                defaultFileMd5: formVal.md5,
                onSetFieldsValue: onChange,
                hidden: more
            }, {
                type: "Input",
                fieldLabel: "备注",
                fieldName: "note",
                span: 12,
                labelCol: { span: 3 },
                hidden: more
            },  {
                type: "Input",
                fieldLabel: "制单人",
                fieldName: "createUser",
                disable: true,
                hidden: more
            }, {
                type: "Input",
                fieldLabel: "制单时间",
                fieldName: "createTime",
                hidden: more,
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "库管员",
                fieldName: "storekeeper",
                hidden: more,
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "出库时间",
                fieldName: "stockIoTime",
                hidden: more,
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "结算员",
                fieldName: "reckoner",
                hidden: more,
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "结算时间",
                fieldName: "settleTime",
                hidden: more,
                disable: true,
            }, {
                type: "Input",
                fieldLabel: "核销时间",
                fieldName: "writeOffTime",
                hidden: more,
                disable: true,
            },

            //带出字段
            {
                type: "Input",
                fieldLabel: "采购实体名称",
                fieldName: "bentityName",
                hidden: true,
            },
            {
                type: "Input",
                fieldLabel: "供应商名称",
                fieldName: "supplierCode",
                hidden: true
            }, {
                type: "Input",
                fieldLabel: "供应商类型code",
                fieldName: "supplierTypeCode",
                hidden: true
            }
        ]
    };


    const fromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: formVal,
        // disabled: mode === "look",
        disabled: !disabledFromBtn(),
        onChange,
        boxs: [baseFormItem],
    }

    return (
        <YhFormBox {...fromConfig} />
    )
}
