import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useStockDetailedModel } from "../stockDetailedModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";

export function PageHeader() {
    const {
        searchParam, organType,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useStockDetailedModel();
    const { orgDs, whDs } = useGlobalModel(({ orgDs }) => [orgDs]);
    const searchFields = [
        ...(organType ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        {
            type: "Input",
            placeholder: "品牌",
            fieldName: "brandName",
        } ,
        {
            type: "Select",
            placeholder: "仓库名称",
            options: whDs,
            fieldName: "warehouseCode"
        },
        {
            type: "Input",
            placeholder: "商品编号",
            fieldName: "materialCode",
        },
        {
            type: "Input",
            placeholder: "商品名称",
            fieldName: "materialName",
        },
        {
            type: "Input",
            placeholder: "采购单号",
            fieldName: "purchaseCode",
        },
        {
            type: "Input",
            placeholder: "供应商名称",
            fieldName: "supplierName"
        },
    ]

    return (
        <YhPageHeader
            title="库存明细"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}