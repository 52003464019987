import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { exportRecordList } from "@/service/ordersService"
import { MyContext } from "@/store/myContext"
import { Select, Switch } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
const { Option } = Select
const TypeList = {
    customer: "客户信息",
    order: "订单信息",
    "order-detail": "订单明细",
}

const statusList = {
    processing: "进行中",
    success: "完成",
    fail: "失败",
}
export default () => {
    const { state, message } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [isRefreshing, setIsRefreshing] = useState(true) //是否正在刷新
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    // useEffect(() => {
    //     if (state.activeMenu === "orderExportDetail") {
    //         loadData(pagination.current)
    //     }
    // }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isRefreshing) {
            const intervalId = setInterval(() => {
                loadData();
            }, 2000);
            return () => clearInterval(intervalId);
        }
    }, [isRefreshing]);

    //控制刷新
    const refresh = () => {
        setIsRefreshing(!isRefreshing)
        if (isRefreshing) {
            loadData()
        }
    }


    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        exportRecordList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }



    //列表button
    const topButtons = []

    //table配置
    const columns = [
        {
            title: "类型",
            width: 100,
            align: "center",
            dataIndex: "typeDesc",
        },

        {
            title: "名称",
            width: 200,
            align: "center",
            dataIndex: "name",
        },
        {
            title: "状态",
            width: 100,
            align: "center",
            dataIndex: "status",
            render: (text) => statusList[text],
        },
        {
            title: "开始时间",
            width: 200,
            align: "center",
            dataIndex: "startTime",
        },

        {
            title: "结束时间",
            width: 200,
            align: "center",
            dataIndex: "endTime",
        },
        // {
        //     title: "失败原因",
        //     width: 500,
        //     align: "center",
        //     dataIndex: "failMsg",
        // },
        {
            title: "下载",
            width: 100,
            align: "center",
            dataIndex: "downloadUrl",
            fixed: 'right',
            render: (text) => (text ? <a href={text}>下载</a> : ""),
        },

    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入名称",
                valueName: "name",
            },
            {
                type: "select",
                placeholder: "类型",
                valueName: "type",
                optionList: () =>
                    Object.entries(TypeList).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
            {
                type: "select",
                placeholder: "状态",
                valueName: "status",
                optionList: () =>
                    Object.entries(statusList).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "orderExportDetail" && (
                <>
                    <div style={{ display: 'flex', float: 'right', marginTop: '10px', marginRight: '14px' }}>
                        <Switch
                            checkedChildren="开启自动刷新"
                            unCheckedChildren="关闭自动刷新"
                            checked={isRefreshing}
                            onChange={refresh}
                        />
                    </div>

                    <TopSearch
                        title="导出记录"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="导出记录"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                </>
            )}
        </>
    )
}
