import React, { useContext, useEffect, useState } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { operaLogPage, operaLogOne } from "@/service/log"
import { getUserList } from "@/service/customerService"
import { MyContext } from "@/store/myContext"
import { Card, List, Select, Button } from "antd"
import Modal from "antd/es/modal/Modal"
import { OperatorUserType } from "@/utils/enum.js"
const {Option} = Select

const detailMap = {
    createUser: "创建人",
    createTime: "创建时间",
    updateUser: "修改人",
    updateTime: "修改时间",
    deleteFlag: "删除标记;0:正常;1:已删除",
    application: "应用",
    userType: "操作人类型",
    userName: "操作人名称",
    requestId: "请求的唯一id",
    statusCode: "操作状态码",
    url: "url",
    method: "请求方式",
    path: "路径",
    ip: "ip",
    userAgent: "userAgent",
    startTime: "开始时间",
    endTime: "结束时间",
    requestHeader: "请求头信息",
    requestParams: "请求参数信息",
    requestBody: "请求body信息",
    responseBody: "响应信息",
    remark: "备注",
}

const applicationList = ["ci","ci-customer","ci-platform","ci-file-server"]

export default () => {
    const { state } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [detailVisible, setDetailVisible] = useState(false)
    const [current, setCurrent] = useState({})
    const [detailDateSource, setDetailDateSource] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "operationLogs") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    const showDetail = (record) => {
        operaLogOne(record.id).then(res => {
            setCurrent(res.retData)
            console.log(current)
            let arr = Object.entries(detailMap).map(item => {
                return {
                    name: item[1],
                    val: res.retData[item[0]]
                }
            })

            
            setDetailDateSource(arr)
            setDetailVisible(true)
        })
        
    }

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
        }
        operaLogPage(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setList(res.retData.list)
            setLoading(false)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //table配置
    const columns = [
        {
            title: "操作人",
            align: "center",
            dataIndex: "userName",
        },
        {
            title: "操作人类型",
            align: "center",
            dataIndex: "userType",
            render:(text)=>(OperatorUserType[text])
        },
        {
            title: "用户名称",
            align: "center",
            dataIndex: "userName",
        },
        {
            title: "请求的唯一ID",
            align: "center",
            dataIndex: "requestId",
        },
        {
            title: "状态码",
            align: "center",
            dataIndex: "statusCode",
        },
        {
            title: "请求方式",
            align: "center",
            dataIndex: "method",
        },
        {
            title: "路径",
            align: "center",
            dataIndex: "path",
        },
        {
            title: "url",
            align: "center",
            dataIndex: "url",
        },
        {
            title: "ip",
            align: "center",
            dataIndex: "ip",
        },
        {
            title: "创建时间",
            align: "center",
            dataIndex: "createTime",
        },
        {
            title: "开始时间",
            align: "center",
            dataIndex: "startTime",
        },
        {
            title: "结束时间",
            align: "center",
            dataIndex: "endTime",
        },
        {
            title: "操作",
            align: "center",
            width: 100,
            render: (text, record) => (
                <Button
                    onClick={() => {
                        showDetail(record)
                    }}
                >
                    {" "}
                    查看详情
                </Button>
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "searchSelect",
                placeholder: "请选择操作人",
                valueName: "userId",
                optionName: "userName",
                optionId: "id",
                searchApi: getUserList,
            },
            {
                type: "select",
                placeholder: "应用",
                valueName: "application",
                optionList: () =>
                applicationList.map((item) => (
                    <Option value={item} key={item}>
                        {item}
                    </Option>
                )),
            },
            {
                type: "select",
                placeholder: "操作人类型",
                valueName: "userType",
                optionList: () =>
                Object.entries(OperatorUserType).map((item) => (
                    <Option value={item[0]} key={item[0]}>
                        {item[1]}
                    </Option>
                )),
            },
            {
                type: "input",
                placeholder: "请求的唯一id",
                valueName: "requestId",
            },
            {
                type: "input",
                placeholder: "操作状态码",
                valueName: " statusCode",
            },
            {
                type: "input",
                placeholder: "url",
                valueName: " url",
            },
            {
                type: "input",
                placeholder: "请求方式",
                valueName: " method",
            },
            {
                type: "input",
                placeholder: "路径",
                valueName: "  path",
            },
            {
                type: "input",
                placeholder: "ip",
                valueName: " ip",
            },
            {
                type: "input",
                placeholder: "userAgent",
                valueName: " userAgent",
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }


    return (
        <>
            {state.activeMenu === "operationLogs" && (
                <>
                    <TopSearch
                        justify="start"
                        title="日志"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        
                    />
                    <ListComponent
                        title="操作日志"
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll = {{ x: 3000 }}
                    />
                    <Modal
                        visible={detailVisible}
                        onOk={() => {
                            setDetailVisible(false)
                            setCurrent({})
                        }}
                        onCancel={() => {
                            setDetailVisible(false)
                            setCurrent({})
                        }}
                        width={"80%"}
                    >
                        <div className="list-container">
                            <div className="form-details-warp">
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 1,
                                        md: 2,
                                        lg: 2,
                                        xl: 4,
                                        xxl: 4,
                                    }}
                                    dataSource={detailDateSource}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Card title={item.name}> {item.val}</Card>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                </>
            )}
        </>
    )
}
