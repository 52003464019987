import React, { useRef, useEffect, useState } from "react";
import YHDrawer from "@/erp_subpackage/components/YhDrawer";
import { YhForm } from "@/erp_subpackage/components/YhFormBox";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { usePsiModel } from "../psiModel";
import { message, Table } from "antd";
import { purchasePoService } from "@/erp_subpackage/pages/purchase/po/purchasePoService";
import { purchaseRoService } from "@/erp_subpackage/pages/purchase/ro/purchaseRoService";
import { saleSoService } from "@/erp_subpackage/pages/sale/so/saleSoService";
import { saleRoService } from "@/erp_subpackage/pages/sale/ro/saleRoService";
import { formatNum } from "@/erp_subpackage/utils/util";
// import { saleIrregularService } from "@/erp_subpackage/pages/sale/irregular/saleIrregularService";

//销售单结算
export function DetailsDrawer() {
	const { setLoadingInfo } = useGlobalModel();
	const {
		detailsVisible: { visible, code, type }, setDatailsVisible
	} = usePsiModel();
	const formRef = useRef();
	const [formVal, setFormVal] = useState({});
	const [list, setList] = useState([]);

	useEffect(() => {
		if (visible && code) {
			getDetailOne(code)
		}
	}, [visible])

	const onCancel = () => {
		setDatailsVisible({ visible: false, code: "" });
	}

	const getDetailOne = async (code) => {
		setLoadingInfo({ loadingVisible: true, txt: "数据加载中..." });
		setFormVal({}); setList([]);
		try {
			let tempFn;
			switch (type) {
				case "io_spd":
					tempFn = purchasePoService.one;
					break;
				case "io_sprd":
					tempFn = purchaseRoService.one;
					break;
				case "io_ssd":
					tempFn = saleSoService.one;
					break;
				case "io_ssrd":
					tempFn = saleRoService.one;
					break;
				// case "io_usrd":
				// 	tempFn = saleIrregularService.one;
				// 	break;
				case "occupy_ssrd":
					tempFn = saleSoService.one;
					break;
				case "occupy_sprd":
					tempFn = purchaseRoService.one;
					break;
					// case "occupy_van":
					// 	tempFn = appointmentService.one;
					// 	break;
					// case "occupy_vmm":
					// 	tempFn = maintenanceService.one;
					break;
				case "compensate":
					tempFn = purchasePoService.one;
					break;
				case "init":
					tempFn = purchasePoService.one;
					break;
				default:
					message.warning("查询异常");
					setLoadingInfo({ loadingVisible: false, txt: "" });
					return
			}
			const { retData: { details, materialDetails, ...from } } = await tempFn(code);
			setFormVal({
				...from,
				code,
				partner: from.customerName ?? from.supplierName
			});
			setList(details ?? materialDetails ?? [])
			setLoadingInfo({ loadingVisible: false, txt: "" });
		} catch { setLoadingInfo({ loadingVisible: false, txt: "" }) }
	}

	const formConfig = {
		formRef,
		formValues: formVal,
		labelCol: { span: 6 },
		items: [
			{
				type: "Input",
				fieldLabel: "单据编号",
				fieldName: "code",
				span: 12,
				disable: true
			}, {
				type: "Input",
				fieldLabel: "单位名称",
				fieldName: "partner",
				span: 12,
				disable: true
			}, {
				type: "Input",
				fieldLabel: "制单员",
				fieldName: "createUser",
				span: 12,
				disable: true
			}, {
				type: "Input",
				fieldLabel: "库管员",
				fieldName: "storekeeper",
				span: 12,
				disable: true
			}, {
				type: "Input",
				fieldLabel: "制单时间",
				fieldName: "createTime",
				span: 12,
				disable: true
			}, {
				type: "Input",
				fieldLabel: "出入库时间",
				fieldName: "stockIoTime",
				span: 12,
				disable: true
			}
		]
	};


	const columns = [
		{
			title: '序号',
			width: 60,
			align: "center",
			fixed: "left",
			render: (t, r, i) => i + 1
		}, {
			title: '商品编码',
			fixed: "left",
			width: 180,
			dataIndex: 'materialCode',
		}, {
			title: '商品名称',
			fixed: "left",
			width: 160,
			dataIndex: 'materialName',
			render: (text) => <p className="ellipsis">{text}</p>,
		}, {
			title: '单价',
			width: 120,
			dataIndex: 'price',
			align: "right",
			render: (text) => `￥${formatNum(text)}`
		}, {
			title: '数量',
			width: 120,
			dataIndex: 'num',
			align: "right",
		}, {
			title: '金额',
			width: 120,
			dataIndex: 'amount',
			align: "right",
			render: (text, record) => `￥${formatNum(record.amount ?? record.materialAmount ?? record.num * record.price)}`
			// render: (text, record) => `￥${formatNum(record.num * record.price)}`
		},
		// {
		// 	title: '折扣金额',
		// 	width: 120,
		// 	dataIndex: 'discountRate',
		// 	align: "right",
		// 	render: (text, record) => `￥${formatNum(record.num * record.price * (1 - (text / 100)))}`
		// },
	];


	return (
		<YHDrawer
			{...{
				title: '单据明细查看',
				visible,
				onCancel,
				okText: "确认",
				width: 1000,
				onOk: onCancel
			}}
		>
			<YhForm {...formConfig} />
			<Table
				rowKey={record => record.id}
				columns={columns}
				dataSource={list}
				pagination={false}
				scroll={{ x: 'max-content' }}
			/>
		</YHDrawer>
	)
}
