import React from "react";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { Table, Button } from "antd";
import { formatNum, momentFormat } from "@/erp_subpackage/utils/util";
import { usePsiModel } from "../../psiModel";


export const DetailsTable = ({ dataSource, recordType, loading, exportDetail, ...props }) => {
	const {
		setDatailsVisible
	} = usePsiModel();
	const obj = {
		io_spd: "采购",
		io_sprd: "采退",
		io_ssd: "销售",
		io_ssrd: "销退",
		io_vmm: "维修",
		io_vrm: "维退",
	}

	const columns = [
		{
			title: '序号',
			width: 66,
			align: "center",
			render: (t, r, i) => i + 1
		}, {
			title: '业务日期',
			width: 180,
			align: "center",
			dataIndex: 'createTime',
		}, {
			title: '单据编号',
			width: 120,
			align: "center",
			dataIndex: 'code',
			render: (text, record, index) => <span className="click-span" onClick={(e) => setDatailsVisible({
				visible: true,
				code: text,
				type: record.type
			})}> {text}</span >,
		}, {
			title: '单价',
			width: 160,
			align: "center",
			dataIndex: 'price',
			render: text => `￥${formatNum(text)}`
		}, {
			title: '数量',
			width: 140,
			align: "center",
			dataIndex: 'num',
		}, {
			title: '金额',
			width: 120,
			align: "center",
			render: (text, record) => `￥${formatNum(record.num * record.price)}`
		}
	];


	const action = <Button
		className="btn-item"
		shape="round"
		onClick={() => exportDetail()}
	>导出</Button>


	const desc = [
		{
			label: `${obj[recordType]}数量`,
			value: dataSource.reduce((c, n) => { c = c + n.num; return c }, 0).toFixed(2),
			color: "red"
		},
	]

	return <YhBox title={action} descriptions={desc}>
		<Table
			loading={loading}
			rowKey={record => record.id}
			columns={columns}
			dataSource={dataSource}
			pagination={false}
			scroll={{ x: 'max-content', y: 340 }}
		/>
	</YhBox>
}