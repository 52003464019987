import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { soStatistics, soStatisticsExport,soStatisticsTotal } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message,Tooltip,Table,Button } from "antd"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum } from '@/erp_subpackage/utils/util';
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { YhBox } from "@/erp_subpackage/components/YhBox";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export default () => {
    const { state,Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [totalData, setotalData] = useState({}) //总计
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName },fetchFmsShopList,setMaterialInfo } = useGlobalModel()
    const [searchValue, setSearchValue] = useState({
        // startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        // endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        // customerName: "",
        // documentNumber: "",
        // createUser: "",
        // tradeTypeCode: "",
        // payType: "",
        // saleTypeCode: "",
        startTime: "",
        endTime: "",
        settlementEndTime: "",
        settlementEndTime: "",
    })
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    //副标题计算
    useEffect(() => {
        if (state.activeMenu === "soStatistics") {
            loadData(pagination.current)
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps


    //列表数据
    const loadData = (current, search = searchValue) => {
        setLoading(true)
        console.log()
        let data = {
             ...search,
            pageNum: current,
            pageSize: search?.pageSize??10,
            shopCode,
            shopId,
            settlementStartTime:searchValue.settlementStartTime? moment(searchValue.settlementStartTime).format("YYYY-MM-DD HH:mm:ss"):'',
            settlementEndTime:searchValue.settlementEndTime?  moment(searchValue.settlementEndTime).format("YYYY-MM-DD HH:mm:ss"):'',
            startTime:searchValue.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):'',
            endTime:searchValue.endTime?  moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):'',
        }
        soStatistics(data).then(({retData}) => {
            setpagination({
                current: current,
                total: retData.total,
                pageSize: retData.size,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(retData.records)
        })
        soStatisticsTotal(data).then(({retData}) => {
            setotalData(retData)
        })
        setLoading(false)
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    const handleExport = () => {
        let data = {
            ...searchValue,  
            pageSize:-1,
            pageNum:1,   
            shopId,
            shopCode,
            settlementStartTime: searchValue.settlementStartTime?moment(searchValue.settlementStartTime).format("YYYY-MM-DD HH:mm:ss"):'',
            settlementEndTime:searchValue.settlementEndTime?  moment(searchValue.settlementEndTime).format("YYYY-MM-DD HH:mm:ss"):'',
            startTime:searchValue.startTime? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss"):'',
            endTime: searchValue.endTime? moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss"):'', 

        }
        soStatisticsExport(data).then((res) => {
            message.success(res.retData)
        })
    }

    //列表button
    // const topButtons = [
    //     {
    //         click: () => handleExport(),
    //         content: "导出",
    //     },
    // ]

    //列表button
    const topButtons = <>
    <Button
        className="btn-item"
        shape="round"
        onClick={() => handleExport()}
    >导出</Button>
</>
    //table配置
    const columns = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '客户名称',
            fixed: "left",
            width: 200,
            dataIndex: 'customerName',
            render: (text) => <YhTooltip text={text} />,
            //render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '客户类型',
            fixed: "left",
            width: 120,
            dataIndex: 'customerTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '单据编号',
            fixed: "left",
            width: 180,
            dataIndex: 'code',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '单据类型',
            width: 100,
            fixed: "left",
            dataIndex: 'tradeTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '单据状态',
            width: 100,
            dataIndex: 'status',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
        }, {
            title: '销售类型',
            width: 80,
            dataIndex: 'salesTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '结算类型',
            width: 80,
            dataIndex: 'payTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '商品编号',
            width: 200,
            dataIndex: 'materialCode',
            render: (text,record) => <YhTooltip text={text} onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }} />,
            //render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品类型',
            width: 160,
            dataIndex: 'typeName',
            render: (text) => <YhTooltip text={text} />,

        }, {
            title: '销售数量',
            width: 100,
            dataIndex: 'num',
        }, {
            title: '销售价格',
            width: 100,
            dataIndex: 'price',
            render: (text) => `￥${text ?? 0}`
        }, {
            title: '销售金额',
            width: 110,
            dataIndex: 'amount',
            render: (text) => `￥${text ?? 0}`
        }, {
            title: '销售成本',
            width: 110,
            dataIndex: 'costAmount',
            render: (text) => `￥${text ?? 0}`
        }, {
            title: '销售毛利',
            width: 110,
            dataIndex: 'profit',
            render: (text) => `￥${text ?? 0}`

        }, {
            title: '制单人',
            width: 120,
            dataIndex: 'createUser',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '结算日期',
            width: 160,
            dataIndex: 'settleTime',
        }, {
            title: '物料编号',
            width: 100,
            dataIndex: 'insideCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '物料名称',
            width: 100,
            dataIndex: 'insideName', 
            render: (text) => <YhTooltip text={text} />,  
        }, {
            title: '替换编号',
            width: 200,
            dataIndex: 'replaceCode',
            render: (text) => <YhTooltip text={text} />,   
        }, {
            title: '销售订单号',
            width: 180,
            dataIndex: 'transferCode',   
            render: (text) => <YhTooltip text={text} />,               
        }, {
            title: '积压标记',
            width: 120,
            dataIndex: 'overstock',

        },{
            title: '备注',
            width: 200,
            dataIndex: 'note',
        }, {
            title: '配件销售频次',
            width: 100,
            dataIndex: 'saleCount',
        }, {
            title: '配件销退频次',
            width: 100,
            dataIndex: 'sreturnCount',
        }
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "单据编号",
                valueName: "code",
            }, 
            {
                type: "input",
                placeholder: "制单员",
                valueName: "createUser",
            },
            {
                type: "RangePicker",
                valueName: ["settlementStartTime", "settlementEndTime"],
                placeholder: ['结算开始时间', '结算结束时间'],
                span: 16,
                xxl: 8,
            },

        ],
        hideList: [
            {
                type: "input",
                placeholder: "商品编号",
                valueName: "materialCode",
            },
            {
                type: "input",
                placeholder: "商品名称",
                valueName: "materialName",
            },
            {
                type: "select",
                placeholder: "销售类型",
                valueName: "saleTypeCode",
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.SALE_TYPE).map((item) =>
                ( 
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )),
            },
            {
                type: "select",
                placeholder: "结算类型",
                valueName: "payType",
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item) =>
                ( 
                <Option key={item.key} value={item.value}>
                    {item.label}
                </Option>
            )),
            },
            {
                type: "select",
                placeholder: "客户类型",
                valueName: "customerTypeCode",
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.CUSTOMER_TYPE).map((item) =>
                    ( 
                    <Option key={item.key} value={item.value}>
                        {item.label}
                    </Option>
                )),
            },
            {
                type: "select",
                placeholder: "单据类型",
                valueName: "tradeTypeCode",
                optionList: () =>
                getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).map((item) =>
                    ( 
                    <Option key={item.key} value={item.value}>
                        {item.label}
                    </Option>
                )),
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                placeholder: ['开单开始时间', '开单结束时间'],
                span: 16,
                xxl: 8,
            },

        ],
    }
    const desc = [
        {
            label: "销售数量",
            value: totalData.saleNum,
            color: "red"
        },
        {
            label: "销售金额",
            value: "￥" + formatNum(totalData.saleAmount?? 0),
            color: "red"
        },
        {
            label: "销售成本",
            value: "￥" + formatNum(totalData.costAmount?? 0),
            color: "red"
        },
        {
            label: "销售毛利",
            value: "￥" + formatNum(totalData.profit?? 0),
            color: "red"
        },

    ]

    return (
        <>
            {state.activeMenu === 'soStatistics' && (
                <>
                    <TopSearch
                        title="销售明细报表"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <YhBox title="销售明细报表" action={topButtons} descriptions={desc} style={{marginTop:20}}>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={list}
                            pagination={{
                                ...pagination,
                                position: ['bottomCenter'],
                                showTotal: () => `共${pagination.total}条`,
                                onChange: (current, serach = searchValue) => {
                                    setSearchValue({...searchValue,pageSize:serach})
                                    loadData(current, searchValue)
                                },
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </YhBox>
                </>
            )}
        </>
    )
}
