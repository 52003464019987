import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getItemList, getItemStatistical } from "@/service/maintenanceService"
import { MyContext } from "@/store/myContext"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import { ItemAttr } from "@/utils/enum"
import { Select } from "antd"
import { classificationList } from "@/service/dataService"
import YhTooltip from '@/components/YhTooltip'
import { PORTAL_API_SERVER_HOST } from "../../../config/app";
import { useGoodsModel } from "../../../erp_subpackage/pages/base/goods/goodsModel";
import useGlobalModel from "../../../erp_subpackage/model/globalModel";

//维修项目
const ProjectManage = () => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { dispatch, state, message, detailsPage } = useContext(MyContext)
    const [projectList, setProjectList] = useState([]) //列表
    const [subTitle, setSubTitle] = useState([])
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const { user: { shopId } } = useGlobalModel();


    useEffect(() => {
        if (state.activeMenu === "projectManage") {
            loadData(pagination.current)
            getItemStatistical()
                .then((res) => {
                    let subTitle = []
                        res.retData.forEach(item => {
                            if(item.itemAttr && item.count){
                                subTitle.push(`${item.itemAttr}:${item.count}条`)
                            }
                            if(!item.itemAttr && item.count){
                                subTitle.push(`其他:${item.count}条`)
                            }
                        })
                        setSubTitle(subTitle)
                })
        }
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },{
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getItemList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setProjectList(res.retData.list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //新增
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "维修项目新增",
            edit: "维修项目编辑",
            look: "维修项目查看",
        }
        let modeKey = "projectManage/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的维修项目信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的维修项目信息")
                return
            }

            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.ProjectDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }
    //导出
    const exportItem = async () => {
        setExportLoading(true)
        let data = {
            type: "post",
            data: {
                searchItem: searchValue,
                orders: [{ column: "updateTime", rule: "DESC" }],
            },
        }
        let resFlie = await download("/ci/maintenance_item/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    //table配置
    const columns = [
        {
            title: "项目编码",
            align: "center",
            dataIndex: "itemCode",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "项目名称",
            align: "center",
            dataIndex: "itemName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "系统分类",
            align: "center",
            dataIndex: "itemClassificationName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "项目属性",
            align: "center",
            dataIndex: "itemAttr",
            render: (text) => <YhTooltip text={ItemAttr[text]} />,
        },
        {
            title: "品牌",
            align: "center",
            dataIndex: "itemBrand",
            render:  (text) => <YhTooltip text={text}/>,
        },
        {
            title: "工时",
            align: "center",
            dataIndex: "itemWorkHours",
        },
        {
            title: "单价",
            align: "center",
            dataIndex: "workHoursPrice",
        },
        {
            title: "工时费",
            align: "center",
            dataIndex: "itemAmount",
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入系统分类",
                valueName: "itemClassificationName",
            },
            {
                type: "select",
                placeholder: "请选择项目属性",
                valueName: "itemAttr",
                optionList: () =>
                Object.entries(ItemAttr).map((item) => (
                        <Select.Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Select.Option>
                    )),
            },
            {
                type: "input",
                placeholder: "请输入项目编码",
                valueName: "itemCode",
            },
            {
                type: "input",
                placeholder: "请输入项目名称",
                valueName: "itemName",
            },
            {
                type: "input",
                placeholder: "请输入品牌",
                valueName: "itemBrand",
            },
        ],
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            content: "导入",
            upload: true,
            action : PORTAL_API_SERVER_HOST+"md/si/import",
            data: {shopId:shopId,branch:"ci"},
            onChange(info) {
                if (info.file.status === "done" && info.file?.response?.retCode === 200) {
                    message.success({
                        content: `${info.file.name}导入${info?.file?.response?.retData}`,
                        duration: 8,
                    })
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name}导入异常，请尝试重新导入`)
                }
            },
        },
        {
            click: () => exportItem(),
            content: "导出",
        },
    ]
    return (
        <>
            {state.activeMenu === "projectManage" && (
                <>
                    <TopSearch
                        title="维修项目管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="维修项目列表"
                        subTitle={subTitle}
                        buttons={topButtons}
                        columns={columns}
                        dataSource={projectList}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

{
                        exportLoading && <Loading />
                    }
                </>
            )}
        </>
    )
}
export default ProjectManage
