import React from "react"
import { Button, message, Modal } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { Description } from "@/erp_subpackage/components/YhBox/Description"
import { useSaleOrderDetailModel } from "./saleOrderDetailModel"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { debounce } from "lodash"
import { repairFormatNum } from "@/erp_subpackage/utils/util"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useSaleOrderModel } from "../../saleOrderModel";

export const TopButs = ({ modeKey, mode, dispatch }) => {
    const { editDesc, setSettleVisible, detailAddForm, detailEditForm, detailLookForm, onTransfer, setCheckModal } =
        useSaleOrderDetailModel()
    const { setSelectedRow } = useSaleOrderModel();
    const { getTreeNodeName } = useDictModel()

    // 转销售单
    const onTrunSaleSo = async () => {
        let { totalAll } = editDesc
        totalAll = +repairFormatNum(totalAll)
        let { customerCode, customerName, payType, settlementPartyCode } = detailEditForm
        // if (!(customerCode && customerName && payType && settlementPartyCode)) {
        //     message.warning("请补全客户名称或结算类型或结算方")
        //     return false
        // }
        turnSaleTips("", true, detailEditForm.saleOrderCode)
    }
    const turnSaleTips = (info, isSave, saleOrderCode) => {
        Modal.confirm({
            title: "销售订单转接销售单",
            content: `单号为${saleOrderCode}的销售订单是否确认转接为销售单`,
            okText: "确定",
            onOk: debounce(async () => {
                isSave && (await saveTrunSaleSo(saleOrderCode))
            }, 500),
            cancelText: "取消",
            onCancel: () => { },
        })
    }

    const saveTrunSaleSo = async (saleOrderCode) => {
        let retData = await onTransfer(saleOrderCode)
        retData &&
            dispatch({
                type: "removeTabs",
                targetKey: modeKey,
                activeMenu: "saleSo",
            }) &&
            message.success("转接销售单成功")
        if (retData) {
            setSelectedRow([]);
        }
    }

    const onSettle = () => {
        setSettleVisible(true)
    }

    // 商城审核
    const onCheck = () => {
        setCheckModal(true)
    }

    // 确认结算
    const action =
        mode === "edit" ? (
            <>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={debounce(() => {
                        if (detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD) {
                            onTrunSaleSo()
                            return
                        }
                        if (detailEditForm.status != DictCodeEnmu.SALE_ORDER_SETTLE) {
                            message.warning('商城订单请先结算!')
                            return
                        }
                        onTrunSaleSo()
                    }, 900)}
                // disabled={topButsControl || detailEditForm.status != DictCodeEnmu.SALE_ORDER_CREATE}
                >
                    转销售单
                </Button>
                {/* <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onSettle}
                    disabled={ detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD || detailEditForm.status !== DictCodeEnmu.SALE_ORDER_CREATE}
                >
                    确认结算
                </Button> */}
                {/* <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onCheck}
                    disabled={ (detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD || detailEditForm.status !== DictCodeEnmu.SALE_ORDER_PENDING)}
                >
                    商城审核
                </Button> */}
            </>) : ""
    // : mode === "look" && detailLookForm.orderSourceCode != DictCodeEnmu.SOURCE_ADD ? (
    //     <Button
    //         className="btn-item"
    //         type="link"
    //         shape="round"
    //         onClick={onSettle}
    //     >
    //         结算查看
    //     </Button> ) : (false)

    const typeTitle = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const orderStatu = [
        {
            label: "销售订单",
            value: typeTitle[mode]?.saleOrderCode ?? "暂无",
            color: "blue",
            status: true,
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status)) ?? "暂无",
            color: "green",
            status: true,
        },
    ]

    return (
        <YhBox
            title={
                <>
                    {orderStatu.map((item, index) => (
                        <Description key={index} {...item} />
                    ))}
                </>
            }
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}
