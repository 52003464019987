import React from "react";
import {Form, Switch} from "antd";

export const __FormSwitch = (props) => {
    return (
        <Form.Item
            label={props.fieldLabel}
            name={props.fieldName}
            rules={props.rules}
            valuePropName="checked"
        >
            <Switch
            	checkedChildren={props.checkedChildren||"开启"}
                unCheckedChildren={props.unCheckedChildren||"关闭"}
                disabled={props.disable}
            />
        </Form.Item>
    )
}