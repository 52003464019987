import { post, get } from "../utils/request"

/**
 * 账单
 */
//列表
function getAccountStaticSummaryList(data) {
    return post("/ci-customer/customer/accountStaticSummary", data)
}

//客户账户统计-账户信息
function getAccountStatic(customerUserId) {
    return get(`/ci-customer/customer/accountStatic/${customerUserId}`)
}

//客户账户统计-待结算订单
function customerUserUnsettledOrder(data) {
    return post("/ci-customer/customer/customerUserUnsettledOrder", data)
}

//客户账户统计-结算订单

function confirmSettleOrder(data) {
    return post("/ci-customer/customer/confirmSettleOrder", data)
}

export { getAccountStaticSummaryList, getAccountStatic, customerUserUnsettledOrder, confirmSettleOrder }
