import React, { useState, useEffect } from "react"
import { Button, Table, message, Modal, Input, Select, Switch } from "antd"
import { getOrganAccount, organAccountRecharge, organAccountLog } from "@/service/orgService"
import AccountDetails from "./accountDetails"
import PubDrawer from "@/components/Drawer"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"

const { Option } = Select
const AccountInfo = (props) => {
    const { customerInfo, organInfo, visible, setVisible } = props
    const [customerData, setCustomerData] = useState(null) //列表
    const [dictObj, setDictObj] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [paginationHis, setPaginationHis] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [accountVisible, setAccountVisible] = useState({
        //详情页开关
        visible: false,
        mode: "",
    })
    const initDict = () => {
        let obj = {
            accountType: {}, //工单来源
        }
        getPcodeDict("042").children.forEach((item) => {
            obj.accountType[item.dictCode] = item.title
        })
        setDictObj(obj)
    }

    useEffect(() => {
        initDict()
    }, [])
    useEffect(() => {
        visible && loadData(1)
    }, [visible])
    //table配置

    //账户详情关闭
    const onDetailsClose = () => {
        setAccountVisible({
            visible: false,
            mode: "",
        })
        loadData(1)
    }
    //新增，编辑
    const handleDetails = (detailsmode) => {
        if (detailsmode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看或编辑的账户信息")
                return
            }
        }
        setAccountVisible({
            visible: true,
            mode: detailsmode,
        })
    }

    //默认加载
    const loadData = (current) => {
        let data = {
            orders: [{ column: "createTime", rule: "DESC" }],
            pageNum: current,
            pageSize: 10,
            searchItem: { organId: organInfo.id },
        }
        getOrganAccount(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setCustomerData(res.retData.list)
                // }
            })
            .catch((err) => {
                setCustomerData([])
            })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }

    const onTableChangeHis = async (page) => {
        await setPaginationHis({
            ...pagination,
            current: page.current,
        })
        lookRechargeRecord(page.current)
    }

    //账户重置
    const accountRecharge = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择要充值的账户")
            return
        }
        let {id,accountType} = selectInfo.rowInfo
        let recharge = "" //充值金额
        let paymentType = -1 //支付方式
        Modal.confirm({
            title: `${organInfo.organName}的账户充值`,
            content: (
                <>
                    <span>充值金额：</span>
                    <Input
                        placeholder="请输入充值金额"
                        onChange={(e) => {
                            recharge = e.target.value
                        }}
                    />
                    <span>账户类型：</span>
                    <Select
                        showSearch
                        allowClear
                        defaultValue={accountType}
                        onChange={(value) => {
                            accountType = value
                        }}
                        placeholder="请选择账户类型"
                        style={{ width: "100%" }}
                        disabled
                    >
                        {getPcodeDict("042").children.map((item) => (
                            <Option value={item.dictCode} key={item.id}>
                                {item.title}
                            </Option>
                        ))}
                    </Select>
                    <span>支付方式：</span>
                    <Select
                        showSearch
                        allowClear
                        onChange={(value) => {
                            paymentType = value
                        }}
                        placeholder="请选择账户类型"
                        style={{ width: "100%" }}
                    >
                        <Option value={1}>现金</Option>
                        <Option value={2}>支付宝</Option>
                        <Option value={3}>微信</Option>
                        <Option value={4}>其他</Option>
                    </Select>
                </>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (!/^[0-9]+(.[0-9]{0,2})?$/.test(recharge)) {
                        // console.log(recharge, accountType, paymentType);
                        message.warning("请输入正确数值的金额")
                        return reject("请输入正确数值的金额")
                    }
                    if (paymentType < 0) {
                        message.warning("请选择支付方式")
                        return reject("请选择支付方式")
                    }
                    return resolve({
                        rechangeAmount: Number(recharge),
                        accountType,
                        paymentType: paymentType,
                        accountId:id,
                    })
                }).then((res) => {
                    let data = {
                        ...res,
                        // updateUser: getUserInfo() ? getUserInfo().nickname : "未获取到", //获取系统业务员
                    }
                    organAccountRecharge(data).then((res) => {
                        // if (res && res.retCode === 200) {
                        loadData(1)
                        message.success(`${organInfo.organName}的账户充值成功`)
                        // } else {
                        //     message.warning(res.retMsg)
                        // }
                    })
                    // .catch((err) => {
                    //     if (err && err.retCode === 500) {
                    //         message.error(err.retMsg)
                    //         return
                    //     }
                    // })
                })
            },
            onCancel: () => {},
        })
    }
    let obj = {
        0: "授信账户",
        1: "返利账户",
        2: "预存账户",
        3: "临时授信账户",
        4: "服务费账户",
        5: "代金券账户",
        6: "预付款账户",
        7: "订金账户",
    }
    const columns = [
        // {
        //     title: "账户编号",
        //     align: "center",
        //     dataIndex: "accountCode",
        // },
        {
            title: "账户类型",
            align: "center",
            dataIndex: "accountType",
            render: (text) => <>{dictObj.accountType[text]}</>,
        },
        {
            title: "账户金额",
            align: "center",
            dataIndex: "totalAmount",
        },
        {
            title: "账户余额",
            align: "center",
            dataIndex: "remainingAmount",
        },
        // {
        //     title: "登记日期",
        //     align: "center",
        //     dataIndex: "createTime",
        //     // render: (text) => <>{timeShift(text)}</>,
        // },
        // {
        //     title: "备注",
        //     align: "center",
        //     dataIndex: "accountDesc",
        // },
        // {
        //     title: "业务员",
        //     align: "center",
        //     dataIndex: "createUser",
        // },
        {
            title: "账户状态",
            align: "center",
            width: "12%",
            dataIndex: "accountStatus",
            render: (text) => <Switch size="small" checked={text == 0 ? true : false} checkedChildren="启用" unCheckedChildren="禁用" />,
        },
    ]

    const [recordMoney, setRecordMoney] = useState(false) //充值记录
    const [moneyData, setMoneyData] = useState(null) //记录列表,
    const [moneyMode, setMoneyMode] = useState("") //查看消费记录， 还有充值记录
    //充值记录
    const lookRechargeRecord = (current, str) => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要查看的账户信息");
            return;
        };
        // let loanType = searchType
        // if (str) {
        //     setSearchType(str === "consumption" ? 1 : 0)
        //     loanType = str === "consumption" ? 1 : 0
        // }
        const {id:accountId} = selectInfo.rowInfo
        setRecordMoney(true)
        setMoneyMode(str)
        let data = {
            pageNum: current,
            pageSize: 10,
            searchItem:{
                accountId
            }
        }
        organAccountLog(data).then((res) => {
            setMoneyData(res.retData.list)
            setPaginationHis({
                //分页配置
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
        })
    }
    const typeObj = {
        0: "云创（维修单）",
        1: "云修（维修单）",
        2: "供应链（销售单）",
    }
    const consumptionColumns = [
        {
            title: "单据号码",
            align: "center",
            dataIndex: "relationId",
        },
        {
            title: "单据类型",
            align: "center",
            dataIndex: "busType",
            render: (text) => <>{typeObj[text]}</>,
        },
        {
            title: "付款金额",
            align: "center",
            dataIndex: "billAmount",
            render: (text) => <>{`${text}元`}</>,
        },
        {
            title: "付款日期",
            align: "center",
            dataIndex: "createTime",
        },
    ]
    const rechargeColumns = [
        {
            title: "账户类型",
            align: "center",
            dataIndex: "accountType",
            render: (text) => <>{dictObj.accountType[selectInfo.rowInfo.accountType]}</>,
        },
        {
            title: "金额",
            align: "center",
            dataIndex: "changeAmount",
        },
        {
            title: "类型",
            align: "center",
            dataIndex: "remark",
        },
        // {
        //     title: "操作员",
        //     align: "center",
        //     dataIndex: "createUser",
        // },
        // {
        //     title: "充值日期",
        //     align: "center",
        //     dataIndex: "createTime",
        // },
    ]
    return (
        <Modal
            width={1200}
            visible={visible}
            cancelText={"null"}
            onCancel={() => setVisible(false)}
            footer={[
                <Button
                    key="submit"
                    onClick={() => {
                        setVisible(false)
                    }}
                >
                    确定
                </Button>,
            ]}
            title={organInfo.organName}
        >
            <div>
                <div className="list-header" style={{ marginBottom: "15px" }}>
                    {/* <div className="list-name">
                        <span>账户信息</span>
                    </div> */}
                    <div className="list-but">
                        <Button shape="round" className="but-left" onClick={() => handleDetails("insert")}>
                            新增
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleDetails("edit")}>
                            {" "}
                            编辑
                        </Button>
                        {/* <Button shape="round" className="but-left"> 导入</Button>
                        <Button shape="round" className="but-left">导出</Button> */}
                        <Button shape="round" className="but-left" onClick={accountRecharge}>
                            {" "}
                            账户充值
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => lookRechargeRecord(1, "recharge")}>
                            {" "}
                            流水记录
                        </Button>
                        {/* <Button shape="round" className="but-left" onClick={() => lookRechargeRecord(1, "consumption")}>
                            消费记录
                        </Button> */}
                    </div>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={customerData}
                    // loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${pagination.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                    onRow={(record, index) => ({
                        onClick: () => setSelectInfo({ rowInfo: record, rowIndex: index }),
                    })}
                    rowClassName={(record, index) => (index === selectInfo.rowIndex ? "dark-row" : "")}
                />
            </div>
            <AccountDetails organInfo={organInfo} rowInfo={selectInfo.rowInfo} accountVisible={accountVisible} onClose={onDetailsClose} />
            <PubDrawer visible={recordMoney} onClose={() => setRecordMoney(false)}>
                <div className="team-drawer-title">
                    <span>{moneyMode === "consumption" ? "消费记录" : "充值记录"}</span>
                </div>
                <div className="team-drawer-content">
                    <Table
                        size="small"
                        rowKey={(record) => record.id}
                        // columns={moneyMode === "consumption" ? consumptionColumns : rechargeColumns}
                        columns={rechargeColumns}
                        dataSource={moneyData}
                        pagination={paginationHis}
                        onChange={onTableChangeHis}
                    />
                    <div className="team-form-footer">
                        <Button onClick={() => setRecordMoney(false)} className="team-but-one">
                            取消
                        </Button>
                        <Button className="team-but-two" onClick={() => setRecordMoney(false)}>
                            确认
                        </Button>
                    </div>
                </div>
            </PubDrawer>
        </Modal>
    )
}
export default AccountInfo
