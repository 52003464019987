import React, { useState, useEffect } from "react"
import { Modal, Row, Col, Radio, Input, message } from "antd"
import { orderVCancelReviewAudit } from "@/service/ordersService"

export default (props) => {
    const { visible, setVisible, onSuccess, reviewId } = props //拍单信息
    const [reviewerStatus, setReviewerStatus] = useState("adopt")
    const [str, setStr] = useState("")
    useEffect(() => {}, [visible])
    const onOk = () => {
        if (reviewerStatus === "adopt" || (reviewerStatus === "reject" && str)) {
            orderVCancelReviewAudit({
                id: reviewId,
                reviewerStatus,
                rejectInfo: reviewerStatus === "adopt" ? "" : str,
            }).then((res) => {
                onSuccess && onSuccess()
                message.success("审核成功")
                setVisible(false)
            })
        } else {
            message.warning("请输入工单驳回的原因")
        }
    }

    return (
        <Modal forceRender visible={visible} title="订单审核" centered={true} onOk={() => onOk()} onCancel={() => setVisible(false)} width={400}>
            <Row style={{ marginBottom: "10px" }}>
                <Col span="6">审核状态</Col>
                <Col span="16">
                    <Radio.Group
                        onChange={(e) => {
                            setReviewerStatus(e.target.value)
                        }}
                        value={reviewerStatus}
                    >
                        <Radio value={"adopt"}>通过</Radio>
                        <Radio value={"reject"}>驳回</Radio>
                    </Radio.Group>
                </Col>
            </Row>

            {reviewerStatus === "reject" && (
                <Row>
                    <Col span="6">驳回原因</Col>
                    <Col span="16">
                        <Input
                            placeholder="请输入工单驳回的原因"
                            onChange={(e) => {
                                setStr(e.target.value)
                            }}
                        />
                    </Col>
                </Row>
            )}
        </Modal>
    )
}
