import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { businessEliteIncome, businessEliteIncomeExport } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message } from "antd"

export default () => {
    const { state } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({
        startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
    })
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [order, setOrder] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "businessEliteIncome") {
            loadData(pagination.current)
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}, order) => {
        setLoading(true)
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: order
                ? [order]
                : [
                      {
                          column: "updateTime",
                          rule: "DESC",
                      },
                  ],
            // startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
            // endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
            startTime: searchValue.startTime,
            endTime: searchValue.endTime,
        }
        businessEliteIncome(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
            setLoading(false)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let order = { column: "updateTime", rule: "DESC" }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === "ascend" ? "ASC" : "DESC"
            order = {
                column: sorter.field,
                rule,
            }
        }
        setOrder(order)
        loadData(page.current, searchValue, order)
    }

    const handleExport = () => {
        let data = {
            searchItem: { ...searchValue },
            orders: Object.keys(order).length > 0
                ? [order]
                : [
                      {
                          column: "updateTime",
                          rule: "DESC",
                      },
                  ],
            startTime: searchValue.startTime ? searchValue.startTime : moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
            endTime: searchValue.endTime ? searchValue.endTime : moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        }
        businessEliteIncomeExport(data).then((res) => {
            message.success(res.retData)
        })
    }

    //列表button
    const topButtons = [
        {
            click: () => handleExport(),
            content: "导出",
        },
    ]

    //table配置
    const columns = [
        {
            title: "名字",
            align: "center",
            dataIndex: "nickname",
        },
        {
            title: "客户数量",
            align: "center",
            dataIndex: "customerNum",
            sorter: true,
        },
        {
            title: "维修订单数量",
            align: "center",
            dataIndex: "maintenanceOrderNum",
            sorter: true,
        },
        {
            title: "商城订单数量",
            align: "center",
            dataIndex: "mallOrderNum",
            sorter: true,
        },
        {
            title: "维修工单流水",
            align: "center",
            dataIndex: "maintenanceOrderAmount",
            sorter: true,
        },
        {
            title: "商城工单流水",
            align: "center",
            dataIndex: "mallOrderAmount",
            sorter: true,
        },
        {
            title: "有效客戶数量",
            align: "center",
            dataIndex: "efficientCustomerNum",
            sorter: true,
        },
        {
            title: "时间范围",
            align: "center",
            dataIndex: "timeLimit",
            sorter: true,
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "businessEliteIncome" && (
                <>
                    <TopSearch
                        title="业绩分析"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                        defaultSearch={{
                            startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
                            endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
                        }}
                    />
                    <ListComponent
                        title="业绩分析"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        loading={loading}
                        rowKey={(record) => record.businessManager}
                    />
                </>
            )}
        </>
    )
}
