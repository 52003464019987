import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// export interface IDetailList {
//     averagePurchasePrice?: number, // 0,
//     brandCode?: string, // "",
//     brandName?: string, // "",
//     createUser?: string, // "",
//     differenceAmount?: number, // 0,
//     differenceNum?: number, // 0,
//     id?: string, // "",
//     inventoryNum?: number, // 0,
//     materialCode?: string, // "",
//     materialName?: string, // "",
//     model?: string, // "",
//     note?: string, // "",
//     realNum?: number, // 0,
//     replaceCode?: string, // "",
//     result?: string, // "",
//     scrapAmount?: number, // 0,
//     scrapNum?: number, // 0,
//     scrapReason?: string, // "",
//     shelfCode?: string, // "",
//     shelfId?: string, // "",
//     stocktakingCode?: string, // "",
//     unitCode?: string, // "",
//     unitName?: string, // "",
//     warehouseCode?: string, // "",
//     warehouseName?: string, // ""
//     latestPurPrice?: string,
//     [key: string]: any,
// }

// export interface IStockCheckFields {
//     endTime?: any,  // "",
//     id?: string,  // "",
//     lossNum?: number,  // 0,
//     note?: string,  // "",
//     realTotalNum?: number,  // 0,
//     result?: string,  // "",
//     shopId?: string,  // "",
//     shopName?: string,  // "",
//     startTime?: any,  // "",
//     status?: string,  // "",
//     stocktakingCode?: string,  // "",
//     surplusNum?: number,  // 0,
//     type?: string,  // "",
//     updateUser?: string,  // ""
//     createUser?: string,  // ""
//     bookTotalNum?: number,  // 0,
//     warehouseCode?: string, // "",
//     warehouseName?: string, // ""
//     duration?: string | number,
//     details?: IDetailList[] | null
//     shelfCode?: string | string[] |any
// }

// 盘库单
class StockCheckService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/stock/check/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/stock/check/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }

    async insert(insertVo) {
        const apiUrl = "/ci-storage/stock/check/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/stock/check/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //作废
    async invalidate(params) {
        const apiUrl = "/ci-storage/stock/check/invalidate";
        return await httpRequest.post(apiUrl, params);
    }
    //盘点完成
    async complete(params) {
        const apiUrl = "/ci-storage/stock/check/complete";
        return await httpRequest.post(apiUrl, params);
    }
    //盘点审核
    async finalAudit(params) {
        const apiUrl = "/ci-storage/stock/check/finalAudit";
        return await httpRequest.post(apiUrl, params);
    }
    //导入盘库
    async warehousing() {
        const apiUrl = "/ci-storage/stock/check/import";
        return await httpRequest.post(apiUrl);
    }
    //明细删除
    async materialDelete(params) {
        const apiUrl = "/ci-storage/stock/check/materialDelete";
        return await httpRequest.post(apiUrl, params);
    }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/ci-storage/stock/check/detail/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    //导出
    async export(params) {
        const apiUrl = "/ci-analyze/stock/check/export";
        return await httpRequest.post(apiUrl, params);
    }
    //出入库明细记录
    async record(params) {
        const apiUrl = "/ci-storage/stockIo/findByCode";
        return await httpRequest.post(apiUrl, params);
    }
    //出入库明细统计
    async recordStatistics(params) {
        const apiUrl = "/ci-storage/stock/details/statistics";
        return await httpRequest.post(apiUrl, params);
    }
}

export const stockCheckService = new StockCheckService();