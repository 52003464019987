import React, { useRef, useEffect, useState } from "react"
import { message } from "antd"
import YhDrawer from "@/erp_subpackage/components/YhDrawer"
import { YhForm } from "@/erp_subpackage/components/YhFormBox"
import { useSaleOrderDetailModel } from "./saleOrderDetailModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { ISettleTypeEnum, IPayMethodDs, IPayMethodCreditDs } from "@/erp_subpackage/utils/ErpEnum"
import useGlobalModel from "@/erp_subpackage/model/globalModel"
import { debounce } from "lodash"
//结算
export const SettleDrawer = ({ mode }) => {
    const { settleVisible: visible, detailAddForm, detailEditForm, detailLookForm, setSettleVisible, onSettle, getDetailEditOne } = useSaleOrderDetailModel()
    const { getSelectedDictOne } = useDictModel()
    const {
        user: { userName,shopCode },
    } = useGlobalModel()
    const formRef = useRef()
    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const [settleType, setSettleType] = useState(ISettleTypeEnum.SETTLE_CASH) //结算类型，  默认现金结账
    const [payMethodDs, setPayMethodDs] = useState(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)) // 支付方式
    useEffect(() => {
        if (visible) {
            formRef.current.setFieldsValue({ ...typeForm[mode], note: "", payType: DictCodeEnmu.SETTLE_TYPE_CASH, paymentMethodCode: detailLookForm.paymentMethodCode})
            setSettleType(typeForm[mode].payType)
            setPayMethodOptions(typeForm[mode].payType)
            if(typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH){
                setPayMethodDs(IPayMethodDs)
            }else if(typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CREDIT){
                setPayMethodDs(IPayMethodCreditDs)
            }
        }
    }, [visible])
    const setPayMethodOptions = () => {
    }

    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value, option) => {
        formRef.current.setFieldsValue({ payType: option.value, payMethodCode: undefined });
        if (value === DictCodeEnmu.SETTLE_TYPE_CASH) {
            setPayMethodDs(IPayMethodDs)
        }else if(value === DictCodeEnmu.SETTLE_TYPE_CREDIT){
            setPayMethodDs(IPayMethodCreditDs)
        }
    }
    //监听批量录入字段
    const onChange = () => {}

    const onCancel = () => {
        setSettleVisible(false)
    }

    //表单提交
    const onSubmit = async () => {
        formRef.current
            .validateFields()
            .then(async (res) => {
                let data = {
                    id: typeForm[mode].id,
                    saleOrderCode: res.saleOrderCode,
                    note: res.note,
                    reckoner: userName,
                    payType: res.payType,
                    payableAmount: res.receivedAmount,
                    details:[]
                }
                try {
                    message.success("结算成功")
                    onCancel()
                    getDetailEditOne(typeForm[mode].saleOrderCode, "edit")
                } catch (error) {
                    onCancel()
                }
            })
            .catch(() => {
            })
    }

    const onFinish = () => {}
    const [payTypeList, setPayTypeList] = useState([])
    useEffect(() => {
        if( shopCode=='SHOP-M-00000016'||shopCode=='SHOP-M-00000017'||shopCode=='SHOP-M-00000018'){
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE))
        }else{
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item)=>{
                return{...item,disabled:item.value=='settle-type.credit'?true:false}}))         }
    }, [])
    const formConfig = {
        formRef,
        onFinish,
        labelCol: { span: 6 },
        disabled: mode === "look",
        onChange,
        items: [
            {
                type: "Input",
                fieldLabel: "销售订单号",
                fieldName: "saleOrderCode",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "付款日期",
                fieldName: "createTime",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "单据编号",
                fieldName: "orderCode",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "单据类型",
                fieldName: "orderTypeName",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "结算日期",
                fieldName: "settleTime",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "已付金额",
                fieldName: "receivableAmount",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "应付金额",
                fieldName: "receivedAmount",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "客户名称",
                fieldName: "customerName",
                span: 12,
                disable: true,
            },
            {
                type: "Select",
                fieldLabel: "所属机构",
                fieldName: "shopName",
                options: [],
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "客户编码",
                fieldName: "customerCode",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "销售实体",
                fieldName: "bentityName",
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "优惠金额",
                fieldName: "discountTotalAmount",
                span: 12,
                disable: true,
            },
            {
                type: "Select",
                fieldLabel: "支付方式",
                fieldName: "paymentMethodCode",
                options: IPayMethodDs,
                span: 12,
                rules: [{ required: true, message: "该项为必选项" }],
            },
            {
                type: "Select",
                fieldLabel: "结算类型",
                fieldName: "payType",
                onSelectChange: settleTypeChange,
                rules: [{ required: true, message: "该项为必选项" }],
                // options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                options:payTypeList,
                span: 12,
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "结算备注",
                fieldName: "note",
                span: 12
            },
        ],
    }
    return (
        <YhDrawer
            title="销售订单结算"
            visible={visible}
            onClose={onCancel}
            okText="保存"
            width={800}
            onOk={debounce(() => {
                onSubmit()
            }, 900)}
            showFooter={ mode === "look" ? false : true}
        >
            <div style={{ padding: "20px 36px" }}>
                <YhForm {...formConfig} />
            </div>
        </YhDrawer>
    )
}
