import React, { useEffect, useContext } from "react"
import { Table, Tooltip, Button } from "antd"
import { useProductModel } from "../productModel"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { ProductPageHeader } from "./ProductPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { YhTag } from "@/erp_subpackage/components/YhTag"
import { formatNum } from "@/erp_subpackage/utils/util"
import { MyContext } from "@/store/myContext"
export const ProductGoodsDetails = (props) => {
    const { initPgCfg, detailsData, detailPgCfg, stockDesc, getProductDetail } = useProductModel()
    const { modeKey, mode, rowId, dispatch, type,goodList, parentModeKey } = props
    const { getTreeNodeName } = useDictModel()
    const { state, detailsPage } = useContext(MyContext)
    useEffect(() => {
        getProductDetail(initPgCfg, { code: rowId })
    }, [rowId])

    const desc = [
        {
            label: "可用库存总计",
            value: (+formatNum(stockDesc.availableStock)),
            color: "red",
        },
    ]

    const goBack = () => {
        if(type === 'down'){
            if(!state.panes.find(item => item.key === parentModeKey)){
                dispatch({
                    type: "changeTabs",
                    name: '商品上架',
                    activeMenu: parentModeKey,
                    content: detailsPage.ProductDetails({
                        dispatch,
                        modeKey:parentModeKey,
                        mode: 'one',
                        goodList: goodList
                    }),
                })
            }else {
                dispatch({
                    type: "removeTabs",
                    targetKey: modeKey,
                    activeMenu: parentModeKey,
                })
            }
            return
        }
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "product",
        })
    }

    const action = (
        <>
            <Button className="btn-item" type="link" shape="round" onClick={() => goBack()}>
                返回
            </Button>
            ,
        </>
    )

    const column = [
        {
            title: "序号",
            width: 80,
            fixed: "left",
            render: (t, r, i) => i + 1,
        },
        {
            title: "商品编号",
            width: 120,
            dataIndex: "code",
            fixed: "left",
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line click-span">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: "商品名称",
            width: 120,
            dataIndex: "name",
            fixed: "left",
            render: (text) => (
                <Tooltip placement="topLeft" title={text}>
                    <p className="one-line">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: "上架状态",
            width: 120,
            dataIndex: "upStatus",
            render: (text) => <YhTag status={text} text={getTreeNodeName(text)} />,
        },
        {
            title: "品牌",
            width: 100,
            dataIndex: "brandName",
        },
        {
            title: "所属机构",
            width: 140,
            dataIndex: "shopName",
        },
        {
            title: "所属分类",
            width: 160,
            dataIndex: "typeName",
        },
        {
            title: "可用库存",
            width: 100,
            dataIndex: "availableStock",
        },
        {
            title: "商城价格",
            width: 100,
            dataIndex: "shopPrice",
        },
        {
            title: "最新进价",
            width: 100,
            dataIndex: "latestPrice",
        },
        {
            title: "进货价格",
            width: 100,
            dataIndex: "purchasePrice",
        },
        {
            title: "仓库",
            width: 100,
            dataIndex: "warehouseName",
        },
        {
            title: "货位",
            width: 120,
            dataIndex: "shelfCode",
        },
    ]

    return (
        <>
            <ProductPageHeader {...props} />
            <YhBox descriptions={desc} action={action} title="商品明细" style={{ marginBottom: "15px" }}>
                <Table
                    rowKey={(record) => record.id}
                    columns={column}
                    dataSource={detailsData}
                    pagination={{
                        ...detailPgCfg,
                        position: ["bottomCenter"],
                        showTotal: () => `共${detailPgCfg.total}条`,
                        onChange: (pageNum, pageSize) => {
                            getProductDetail({ pageNum, pageSize, code: rowId })
                        },
                    }}
                    scroll={{ x: "max-content", y: 500 }}
                />
            </YhBox>
        </>
    )
}
