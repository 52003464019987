import React, { useEffect, useState } from 'react'
import { Modal, Tabs, Form, Row, Col, Input, Select, Empty } from 'antd'
import { orderThreeGuaranteesDetail } from '@/service/ordersService'
import TopSearch from '@/components/TopSearch'
import ListComponent from '@/components/listComponent'
import { getPcodeDict } from '@/utils/dataStorage'
const { TabPane } = Tabs
const { Option } = Select
export default (props) => {
    const { visible, setVisible, id } = props
    const [factoryVal, setFactoryVal] = useState([])
    const [form] = Form.useForm()
    const [formF] = Form.useForm()
    useEffect(() => {
        if (visible) loadData()
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        orderThreeGuaranteesDetail(id).then((res) => {
            console.log(res)
            let results = res.retData
            form.setFieldsValue({
                ...results,
            })
            const { factoryThreeGuaranteesInfo } = results

            let factoryInfo = []
            if (factoryThreeGuaranteesInfo) {
                const { claimNumber, valList } = factoryThreeGuaranteesInfo
                factoryInfo.push({
                    titleName: '索赔单号',
                    val: claimNumber,
                    componentSort: -1
                })
                valList.forEach((item) => {
                    const { titleName, val, componentSort } = item
                    factoryInfo.push({
                        titleName,
                        val,
                        componentSort
                    })
                })
            }
            setFactoryVal(factoryInfo.sort((a, b) => a.componentSort-b.componentSort))

            // const { list, total } = res.retData
            // setpagination({
            //     current: current,
            //     total: total,
            //     pageSize: 10,
            // })
            // let listData = []
            // let columsData = []
            // list.forEach((item, index) => {
            //     const { orderId, orderCode, valList } = item
            //     let newI = {
            //         orderCode,
            //         orderId,
            //     }
            //     valList.forEach((i) => {
            //         newI[i.titleCode] = i.val
            //         if (index === 0) {
            //             columsData.push({
            //                 title: i.titleName,
            //                 align: 'center',
            //                 dataIndex: i.titleCode,
            //                 sort: i.componentSort,
            //             })
            //         }
            //     })
            //     listData.push(newI)
            // })
            // setList(listData)
            // columsData = columsData.sort((a, b) => a.sort - b.sort)
            // columsData.length > 0 && columsData.push({
            //     title: '状态',
            //     align: 'center',
            //     dataIndex: 'orderCode',
            //     render: (text) => <>{text ? <span style={{ color: 'blue' }}>已匹配</span> : <span>未匹配</span>}</>,
            // })
            // setColums(columsData)
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    return (
        <>
            <Modal
                forceRender
                visible={visible}
                title="三包详情"
                onCancel={() => {
                    setVisible(false)
                }}
                centered={true}
                footer={[]}
                width={1200}
                zIndex={1000}
            >
                <div style={{ height: '600px', overflowY: 'scroll' }}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="机构自提" key="1">
                            <Form form={form} {...formItemLayout}>
                                <Row>
                                    <Col span={6}>
                                        <Form.Item label="三包品牌" name="threeGuaranteesBrand">
                                            <Select placeholder="三包品牌" style={{ width: '100%' }} disabled>
                                                {getPcodeDict('106').children.map((item) => (
                                                    <Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="所属实体" name="ciBusinessEntityName">
                                            <Input placeholder="所属实体" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="索赔单号" name="claimNumber">
                                            <Input placeholder="可自动生成索赔单号" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="结算单号" name="statementNumber">
                                            <Input placeholder="结算单号" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="材料费" name="materialAmount">
                                            <Input placeholder="材料费" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="工时费" name="hourlyWage">
                                            <Input placeholder="工时费" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="外出费" name="travelExpenses">
                                            <Input placeholder="外出费" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="餐补费" name="mealSupplement">
                                            <Input placeholder="餐补费" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="其他费用" name="otherAmount">
                                            <Input placeholder="其他费用" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="出库金额" name="outboundAmount">
                                            <Input placeholder="出库金额" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="索赔总额" name="totalClaim">
                                            <Input placeholder="索赔总额" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="故障处理" name="faultHandle">
                                            <Input placeholder="故障处理" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="工时费汇总" name="totalHourlyWage">
                                            <Input placeholder="三包工时费汇总" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="维修时间" name="repairTime">
                                            <Input placeholder="维修时间" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="维修人员" name="maintenanceStaff">
                                            <Input placeholder="维修人员" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item label="工时定额" name="workHour">
                                            <Input placeholder="工时定额" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Form.Item label="" name="ciBusinessEntityName" hidden>
                                        <Input placeholder="" disabled />
                                    </Form.Item>
                                </Row>
                            </Form>
                        </TabPane>
                        <TabPane tab="厂家提报" key="2">
                            {factoryVal.length > 0 ? (
                                <Form {...formItemLayout}>
                                    <Row>
                                        {factoryVal.map((item) => (
                                            <Col span={6}>
                                                <Form.Item label={item.titleName}>
                                                    <Input placeholder={item.titleName} disabled value={item.val} />
                                                </Form.Item>
                                            </Col>
                                        ))}
                                    </Row>
                                </Form>
                            ) : (
                                <Empty />
                            )}
                        </TabPane>
                    </Tabs>
                </div>
            </Modal>
        </>
    )
}
