import React, { useEffect, useState } from "react";
import { Table } from "antd";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { useContractModel } from '../contractParticularsModel'

export function DataTable({ goPageNav }) {
    const { loadData, dataSource, selectedRowKeys, pgCfg, setSelectedRowKeys, loading, dictObj, initDict } = useContractModel()


    useEffect(() => {
        initDict();
        loadData();
    }, [])

    const onRow = (record) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === record) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([record]);
        }
    };

    const columns = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => `${i + 1}`,
            fixed: 'left'
        }, {
            title: '合约单号',
            width: 120,
            dataIndex: 'signCode',
            fixed: 'left',
            render: (text, record) => <YhTooltip text={text} onClick={() => { goPageNav("edit", record.id) }} />,
        }, {
            title: '签约类型',
            width: 80,
            dataIndex: 'signType',
            fixed: 'left',
            render: (text) => { if (text === '046001') { return text = '大包' } else { return text = '月结' } }

        }, {
            title: '大包模式',
            width: 80,
            dataIndex: 'bigBagMode',
            fixed: 'left',
            render: (text) => <>{dictObj.bigBagMode[text]}</>,
        }, {
            title: '客户名称',
            width: 80,
            dataIndex: 'customerName',
            render: (text) => <YhTooltip text={text} maxWidth={300} />,
        },
        {
            title: '签约实体',
            width: 80,
            dataIndex: 'signBusinessEntityName',
            render: (text) => <YhTooltip text={text} maxWidth={300} />,
        },

        {
            title: '单据金额',
            width: 80,
            dataIndex: 'totalAmount',
            render: (text) => text.toFixed(2)
        }, {
            title: '已收金额',
            width: 80,
            dataIndex: 'paidAmount',
            render: (text) => text.toFixed(2)


        }, {
            title: '应收金额',
            width: 80,
            dataIndex: 'payableAmount',
            render: (text) => text.toFixed(2)

        }, {
            title: '维修单数',
            width: 80,
            dataIndex: 'orderNum',

        },
        {
            title: '创建人',
            width: 80,
            dataIndex: 'createUserName',
        },
        {
            title: '创建时间',
            width: 140,
            dataIndex: 'createTime',
        },
        {
            title: '所属机构',
            width: 140,
            dataIndex: 'organName',
        },
    ];

    return (
        <Table
            dataSource={dataSource}
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.id);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                },
                onSelect: (record) => onRow(record.id),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize });
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record),
            //     onContextMenu: (e) => {
            //         e.preventDefault();
            //         setSelectedRowKeys([record.id]);
            //     }
            // })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}