import React from "react"
// import LoadableComponent from '../utils/LoadableComponent'

//首页
// import Home from './home/index';
// import Home from './home/index copy';
import HomeMap from "./homeMap"
import StatisticsIndex from "./statisticsIndex"
//团队管理
import TeamManage from "./teamManage"
//团队成员管理
import TeamMemberManage from "./teamManage/memberManage"
//团队成员收益
import MemberIncome from "./teamManage/memberIncome"
//团队收益
import TeamIncome from "./teamManage/teamStatic"
//订单待派单
import PendingOrder from "./ordersManage/pendingOrder"
//订单审核
import ReviewOrder from "./ordersManage/reviewOrder"
//订单审核
import ExamineOrder from "./ordersManage/reviewOrders"
//订单审核
import AllOrder from "./ordersManage/allOrder"
//待退款订单
import RefundedOrder from "./ordersManage/refundedOrder/refundedOrder"
//待退单订单
import ReturnOrder from "./ordersManage/returnOrder"
//订单待付款
import PaymentOrder from "./ordersManage/paymentOrder"
//订单待处理
import OrdersManage from "./ordersManage"
//订单已完成
import CompleteOrder from "./ordersManage/completeList"
//订单已完成
import CancelleOrder from "./ordersManage/cancelleOrder"
//订单导出明细
import OrderExportDetail from "./ordersManage/orderExportDetail"
//维修领料单
import RepairPickingList from "./ordersManage/repairPickingList"
//维修退料单
import RepairReturnList from "./ordersManage/repairReturnList"
//厂家结算单
import FactorySettle from "./ordersManage/factorySettle"
//三包账单
import SanbaoBill from "./ordersManage/sanbaoBill"
//快速开单
import QuickBilling from "./quickBilling"
//错误页面
import Error from "./errorPage"
//移动车管理
import MoveCarManage from "./moveCarManage"
//数据字典
import DataDictionaries from "./dataDictionaries"
//appbanner设置
import AppBannerSet from "./dataManage/appBannerSet"
//所属实体
import Entity from "./dataManage/entity"
//故障码
import FaultCode from "./dataManage/faultCode"
//车辆附件模版管理
import VehicleEnclosure from "./dataManage/vehicleEnclosure"
//服务定价
import ServicePricing from "./dataManage/servicePricing"
//经营成本
import OperatingCosts from "./dataManage/operatingCosts"
//账目分类
import AccountClass from "./dataManage/accountClass"
//配件管理
import MaterialManage from "./dataManage/materialManage"
//维修模版
import RepairTemplate from "./dataManage/repairTemplate"
//通知管理
import NoticeManage from "./dataManage/noticeManage"
//维修项目管理
import ProjectManage from "./dataManage/projectManage"
//系统分类
import SystemClassification from "./dataManage/systemClassification"
//维保组合
import Combination from "./dataManage/combination"

//系统配置管理
import SystemConfig from "./dataManage/systemConfig"
//仪表灯分类
import InstrumentLampType from "./dataManage/instrumentLampType"
//品牌管理
import BrandManege from "./dataManage/brandManage"
//仪表灯列表
import InstrumentLampList from "./dataManage/instrumentLampList"
//客户档案
import ClientRecord from "./customerManage/clientRecord"
//车队档案
import MotorcadeRecord from "./customerManage/motorcadeRecord"
//车辆档案
import VehicleRecord from "./customerManage/vehicleRecord"
//红包记录
import RedEnvelope from "./customerManage/redEnvelope"
//用户档案
import UserRecord from "./customerManage/userRecord"
//客户回访
import ReturnVisit from "./customerManage/returnVisit"
//工单详情
import OrderDetails from "./ordersManage/orderDetails.jsx"
//客户详情
import ClientDetails from "./customerManage/clientRecord/clientDetails.jsx"
//用户详情
import UserDetails from "./customerManage/userRecord/userDetails.jsx"
//车辆详情
import VehicleDetails from "./customerManage/vehicleRecord/vehicleDetails.jsx"
//维修项目详情
import ProjectDetails from "./dataManage/projectManage/projectDetails.jsx"
//维修配件详情
import MaterialDetails from "./dataManage/materialManage/materialDetails.jsx"
//系统配置详情
import SystemDetails from "./dataManage/systemConfig/systemDetails.jsx"
//仪表灯类型详情
import InstrumentLampTypeDetails from "./dataManage/instrumentLampType/details.jsx"
//仪表灯列表详情
import InstrumentLampListDetails from "./dataManage/instrumentLampList/details.jsx"
//仪表灯列表详情
import BrandManageDetails from "./dataManage/brandManage/details.jsx"
//车辆品牌
import VehicleBrand from "./dataManage/vehicleBrand"
//车辆品牌(新)
import VehicleBrandname from "./dataManage/vehicleBrandname"
//车队详情
import MotorcadeDetails from "./customerManage/motorcadeRecord/motorcadeDetails.jsx"
//移动车详情
import MoveCarDetails from "./moveCarManage/moveCarDetails.jsx"
//维保16模块
import SetMealManage from "./setMealManage"
//套餐详情
import SetMealDetail from "./setMealManage/details.jsx"
//套餐模块维护
import SetMealModule from "./setMealManage/setMealModule.jsx"
//规则详情
import RuleDetails from "./setMealManage/ruleDetails.jsx"
//套餐车型匹配
import SetMealCarType from "./setMealManage/setMealCarType.jsx"
//积分管理
import PointManage from "./pointManage/index"
// //商品列表
// import GoodsList from "./shoppingManage/goodsList"
// //商品规格
// import GoodsSpec from "./shoppingManage/goodsSpec"
// //商品详情
// import GoodDetails from "./shoppingManage/goodsList/details"
//商品订单管理
// import GoodsOrder from "./shoppingManage/goodsOrder"
//智能诊断列表
import AiDiagnose from "./aiDiagnose"
//智能诊断详情
import AiDiagnoseDetails from "./aiDiagnose/details"
//智能诊断部件维护
import PartMaintenance from "./aiDiagnose/partMaintenance"
//智能诊断口语管理
import DiagnoseSpoken from "./aiDiagnose/diagnoseSpoken"
//
import ImageClassify from "./dataManage/imageClassify/index"
//
import ImageClassifyDetail from "./dataManage/imageClassify/details"

import AllCarList from "./allCarManage/allCarList"
//整车管理 车型管理
import AllCarTypeManage from "./allCarManage/allCarTypeManage"
//整车管理 访问记录
import AllCarAccessRecord from "./allCarManage/allCarAccessRecord"
//整车管理 咨询记录
import AllCarConsultationRecord from "./allCarManage/allCarConsultationRecord"

//商城订单
import ShopOrder from "./shopOrder/orderManage"
//商城详情
import ShopOrderDetail from "./shopOrder/orderManage/details"


//商城订单
import ShopOrderB from "./shopOrder/orderManageb"
//商城详情
import ShopOrderBDetail from "./shopOrder/orderManageb/details"

//权限管理
//菜单管理
import MenuMange from "@/pages/permission/menuManage"
//角色管理
import RoleManage from "@/pages/permission/roleManage"
//角色管理详情
import RoleManageDetail from "@/pages/permission/roleManage/detail"

// 机构管理
import OrgManage from "./orgManage"
import OrderUnsettelManage from "./orderManage/unsettled"
import OrderManage from "./orderManage"
import PersonnelManagement from "./orgManage/manageList/user/index"

//团队结算账单
import TeamSettleds from "./orderManage/team/teamSettleds"
import TeamUnsettleds from "./orderManage/team/teamUnsettleds"

//统计
//角色管理详情
import CustomerStatistics from "@/pages/statistics/customerStatistics"
import BusinessEliteIncome from "@/pages/statistics/businessEliteIncome"
import OrganIncome from "@/pages/statistics/organIncome"
import TransferDataMonitor from "@/pages/statistics/transferDataMonitor"
import TransferMonitor from "@/pages/statistics/transferMonitor"
import PoListStatistics from "@/pages/statistics/poListStatistics"
import PoStatistics from "@/pages/statistics/poStatistics"
import SoListStatistics from "@/pages/statistics/soListStatistics"
import SoStatistics from "@/pages/statistics/soStatistics"
import RepairListStatistics from "@/pages/statistics/repairListStatistics"
import RepairReportStatistics from "@/pages/statistics/repairReportStatistics"
import SBrepairReportStatistics from "@/pages/statistics/SBrepairReportStatistics"
import MonitoringStatistics from "@/pages/statistics/monitoringStatistics"
import BillMonitor from "../pages/statistics/billMonitor"
import JXCStatistics from "@/pages/statistics/JXCStatistics"
import StockInAndOut from "@/pages/statistics/stockInAndOut"

//日志
//角色管理详情
import OperationLogs from "@/pages/logs/operationLog"

//个人设置
//编辑列表
import AccountSet from "@/pages/settings/accountSet"

// 资料管理
import TdmsIndex from "@/pages/tdms/index"

// 技术资料
import TdmsData from "@/pages/tdms/data"
// 技术资料详情
import DataDetails from "./tdms/data/details"
import TdmsApproval from "@/pages/tdms/approval"
// 资料审核详情
import ApprovalDetails from "./tdms/approval/details.jsx"
import TdmsCategory from "@/pages/tdms/category"
import TdmsFocus from "@/pages/tdms/focus"
import TdmsMsg from "@/pages/tdms/msg"
import TdmsNav from "@/pages/tdms/nav"

//门店管理
import StoreManagement from "@/pages/storeManagement/storeManagement"
//合约管理
import ContractParticulars from '@/pages/contractManagement/contractParticulars/view/index'
import {ContractlDetails} from "@/pages/contractManagement/contractParticulars/view/detailbox"
import {BillTable} from '@/pages/contractManagement/contractParticulars/view/BillTable'
import {MileageOneTable} from '@/pages/contractManagement/contractParticulars/view/MileageOneTable'

/*****************erp融合模块************************** */
//基础数据
import BaseDict from "../erp_subpackage/pages/base/dict/view/index";
import BaseGoods from "../erp_subpackage/pages/base/goods/view/index";
import BaseGoodsDetails from "../erp_subpackage/pages/base/goods/view/GoodsDetails";
import YHBaseGoods from "../erp_subpackage/pages/base/yhgoods/view/index";
import BaseWarehouse from "../erp_subpackage/pages/base/warehouse/view/index";
import BaseWarehouseDetails from "../erp_subpackage/pages/base/warehouse/view/detail/index";
import BaseSupplier from "../erp_subpackage/pages/base/supplier/view/index"
import {
    BaseSupplierDetails
} from "../erp_subpackage/pages/base/supplier/view/detailsBox"
import BaseCustomer from "../erp_subpackage/pages/base/customer/view/index"
import {
    BaseCustomerDetails
} from "../erp_subpackage/pages/base/customer/view/detailsBox"
import BaseVehicle from "../erp_subpackage/pages/base/vehicle/view/index"
import {
    BaseVehicleDetails
} from "../erp_subpackage/pages/base/vehicle/view/detailsBox"

//采购业务
import PurchaseAdvanced from "../erp_subpackage/pages/purchase/advanced/view/index";
import PurchaseAdvancedDetails from "../erp_subpackage/pages/purchase/advanced/view/detail/index";
import PurchasePlan from "../erp_subpackage/pages/purchase/plan/view/index";
import PurchasePlanDetails from "../erp_subpackage/pages/purchase/plan/view/detail/index";
import PurchasePo from "../erp_subpackage/pages/purchase/po/view/index";
import PurchasePoDetails from "../erp_subpackage/pages/purchase/po/view/detail/index";
import PurchaseRo from "../erp_subpackage/pages/purchase/ro/view/index";
import PurchaseRoDetails from "../erp_subpackage/pages/purchase/ro/view/detail/index";
import PurchaseDr from "../erp_subpackage/pages/purchase/dr/view/index";
import PurchaseDrDetails from "../erp_subpackage/pages/purchase/dr/view/details/index";

import SaleOrder from "../erp_subpackage/pages/sale/order/view/index"
import {
    SaleOrderDetails
} from "../erp_subpackage/pages/sale/order/view/details/index"
import SaleSo from "../erp_subpackage/pages/sale/so/view/index"
import {
    SaleSoDetails
} from "../erp_subpackage/pages/sale/so/view/details/index"
import SaleRo from "../erp_subpackage/pages/sale/ro/view/index"
import {
    SaleRoDetails
} from "../erp_subpackage/pages/sale/ro/view/details/index"
import SaleDc from "../erp_subpackage/pages/sale/dc/view/index"
import {
    SaleDcDetails
} from "../erp_subpackage/pages/sale/dc/view/details/index"

import Product from "../erp_subpackage/pages/shopping/product/view/index"
import {
    ProductGoodsDetails
} from "../erp_subpackage/pages/shopping/product/view/LookGoodsDetailsBox"
import {
    ProductDetails
} from "../erp_subpackage/pages/shopping/product/view/details/index.jsx"

//库存管理
import StockDetailed from "../erp_subpackage/pages/stock/detailed/view/index";
import StockStatistic from "../erp_subpackage/pages/stock/statistic/view/index";
import StockCheck from "../erp_subpackage/pages/stock/check/view/index";
import StockCheckDetails from "../erp_subpackage/pages/stock/check/view/detail/index";
import StockOutput from "../erp_subpackage/pages/stock/output/view/index"
import {
    StockOutputDetails
} from "../erp_subpackage/pages/stock/output/view/details/index"
import StockEntry from "../erp_subpackage/pages/stock/entry/view/index"
import {
    StockEntryDetails
} from "../erp_subpackage/pages/stock/entry/view/details/index"
import StockAlloc from "../erp_subpackage/pages/stock/alloc/view/index"
import JXCReport from "../erp_subpackage/pages/stock/JXC-report/view/index"
import PSIReport from "../erp_subpackage/pages/stock/PSI-report/view/index"
import BacklogManage from "..//erp_subpackage/pages/stock/backlog/view/index"
import InventoryStatistics from "..//erp_subpackage/pages/stock/inventoryStatistics/view/index"



//预约管理
//待转接
import TobeTransferred from "./bookingManagement/tobeTransferred"
//已转接
import AppointmentTransfer from "./bookingManagement/transferred"
//已取消
import AppointmentCancelled from "./bookingManagement/cancelled"

// 账户管理
// 调拨对账单
import AccountInternal from "../erp_subpackage/pages/account/internal/view/index"
import {
    InternalDetails
} from "../erp_subpackage/pages/account/internal/view/detail/index"

//资料管理 考试程序
import PaperQuestion from "../erp_subpackage/pages/paper/question/view/index";
import PaperRecord from "../erp_subpackage/pages/paper/record/view/index";

//维修预约单
import Prebook from "./ordersManage/prebook/index"
import PrebookDetail from "./ordersManage/prebook/detail/index"

const menu = [{
    name: "首页",
    icon: "icon-shouye",
    key: "homeIndex",
    parent: "1",
},
{
    name: "首页",
    icon: "icon-shouye",
    key: "homeMap",
    parent: "1",
},


{
    name: "快速开单",
    icon: "icon-shouye",
    key: "quickBilling",
    parent: "1",
},
{
    name: "工单管理",
    icon: "icon-dingdan",
    key: "ordersManages",
    parent: "2",
    children: [{
        name: "待派单工单",
        icon: "icon-tianjiaziji",
        key: "pendingOrder",
    },
    {
        name: "待审核工单",
        icon: "icon-tianjiaziji",
        key: "reviewOrder",
    },
    {
        name: "待处理工单",
        icon: "icon-tianjiaziji",
        key: "ordersManage",
    },
    {
        name: "待付款工单",
        icon: "icon-tianjiaziji",
        key: "paymentOrder",
    },
    {
        name: "已完成工单",
        icon: "icon-tianjiaziji",
        key: "completeOrder",
    },
    {
        name: "订单导出明细",
        icon: "icon-tianjiaziji",
        key: "orderExportDetail",
    },
    {
        name: "已取消工单",
        icon: "icon-tianjiaziji",
        key: "cancelleOrder",
    },
    {
        name: "全部工单",
        icon: "icon-tianjiaziji",
        key: "allOrder",
    },
    {
        name: "待退款工单",
        icon: "icon-tianjiaziji",
        key: "refundedOrder",
    },
    {
        name: "维修领料单",
        icon: "icon-tianjiaziji",
        key: "repairPickingList",
    },
    {
        name: "维修退料单",
        icon: "icon-tianjiaziji",
        key: "repairReturnList",
    },
    {
        name: "待审批工单",
        icon: "icon-tianjiaziji",
        key: "examineOrderWait",
    },
    {
        name: "已驳回工单",
        icon: "icon-tianjiaziji",
        key: "examineOrderAdopt",
    },
    {
        name: "已作废工单",
        icon: "icon-tianjiaziji",
        key: "examineOrderReject",
    },
    {
        name: "退单工单",
        icon: "icon-tianjiaziji",
        key: "returnOrder",
    },
    {
        name: "厂家结算单",
        icon: "icon-tianjiaziji",
        key: "factorySettle",
    },
    {
        name: "维修预约单-待转",
        icon: "icon-tianjiaziji",
        key: "preBookNew",
    },
    {
        name: "维修预约单-已转",
        icon: "icon-tianjiaziji",
        key: "preBookConvert",
    },
    {
        name: "维修预约单-已作废",
        icon: "icon-tianjiaziji",
        key: "preBookCancel",
    }
    
    ]
},
{
    name: "移动车管理",
    icon: "icon-cheliang",
    key: "moveCarManage",
    parent: "3",
},
{
    name: "团队管理",
    icon: "icon-tuandui",
    key: "teamManage",
    parent: "4",
    children: [{
        name: "团队管理",
        icon: "icon-tianjiaziji",
        key: "teamManage",
    },
    {
        name: "成员管理",
        icon: "icon-tianjiaziji",
        key: "teamMemberManage",
    },
    {
        name: "成员收益",
        icon: "icon-tianjiaziji",
        key: "memberIncome",
    },
    {
        name: "团队收益",
        icon: "icon-tianjiaziji",
        key: "teamIncome",
    },
    ],
},
{
    name: "客户管理",
    icon: "icon-kehu",
    key: "customerManage",
    parent: "5",
    children: [{
        name: "客户管理",
        icon: "icon-tianjiaziji",
        key: "clientRecord",
    },
    {
        name: "车队管理",
        icon: "icon-tianjiaziji",
        key: "motorcadeRecord",
    },
    {
        name: "车辆管理",
        icon: "icon-tianjiaziji",
        key: "vehicleRecord",
    },
    {
        name: "用户管理",
        icon: "icon-tianjiaziji",
        key: "userRecord",
    },
    {
        name: "红包管理",
        icon: "icon-tianjiaziji",
        key: "redEnvelope",
    },
    {
        name: "客户回访",
        icon: "icon-tianjiaziji",
        key: "returnVisit",
    },
    ],
},
{
    name: "账单管理",
    icon: "icon-tuandui",
    key: "orderManage",
    parent: "13",
    children: [{
        name: "客户账单",
        icon: "icon-tuandui",
        key: "orderManage",
        parent: "13",
        children: [{
            name: "已结算",
            icon: "icon-tianjiaziji",
            key: "orderManageSettleds",
        },
        {
            name: "未结算",
            icon: "icon-tianjiaziji",
            key: "orderManageUnsettleds",
        }
        ]
    }, {
        name: "结算账单",
        icon: "icon-tuandui",
        key: "jiesuanzhangdan",
        parent: "13",
        children: [{
            name: "已结算",
            icon: "icon-tianjiaziji",
            key: "teamSettleds",
        },
        {
            name: "未结算",
            icon: "icon-tianjiaziji",
            key: "teamUnsettleds",
        }
        ]
    },{
        name: "三包账单",
        icon: "icon-tianjiaziji",
        key: "sanbaoBill",
    },],
},
{
    name: "数据管理",
    icon: "icon-RectangleCopy",
    key: "dataManage",
    parent: "6",
    children: [{
        name: "配件管理",
        icon: "icon-tianjiaziji",
        key: "materialManage",
    },
    {
        name: "仪表灯类型",
        icon: "icon-tianjiaziji",
        key: "instrumentLampType",
    },
    {
        name: "品牌管理",
        icon: "icon-tianjiaziji",
        key: "brandManage",
    },
    {
        name: "仪表灯列表",
        icon: "icon-tianjiaziji",
        key: "listInstrumentLamp",
    },
    {
        name: "维修项目管理",
        icon: "icon-tianjiaziji",
        key: "projectManage",
    },
    {
        name: "系统分类",
        icon: "icon-tianjiaziji",
        key: "systemClassification",
    },
    {
        name: "维保组合",
        icon: "icon-tianjiaziji",
        key: "combination",
    },
    {
        name: "系统配置",
        icon: "icon-tianjiaziji",
        key: "systemConfig",
    },
    {
        name: "订单图片分类",
        icon: "icon-tianjiaziji",
        key: "imageClassify",
    },
    {
        name: "通知管理",
        icon: "icon-tianjiaziji",
        key: "noticeManage",
    },
    {
        name: "维修模版",
        icon: "icon-tianjiaziji",
        key: "repairTemplate",
    },
    {
        name: "APPBanner设置",
        icon: "icon-tianjiaziji",
        key: "appBannerSet",
    },
    {
        name: "所属实体",
        icon: "icon-tianjiaziji",
        key: "entity",
    },
    {
        name: "故障码查询",
        icon: "icon-tianjiaziji",
        key: "faultCode",
    },
    {
        name: "车辆附件模版管理",
        icon: "icon-tianjiaziji",
        key: "vehicleEnclosure",
    },
    {
        name: "服务定价",
        icon: "icon-tianjiaziji",
        key: "servicePricing",
    },
    {
        name: "经营成本",
        icon: "icon-tianjiaziji",
        key: "operatCost",
    },
    {
        name: "账目分类",
        icon: "icon-tianjiaziji",
        key: "accountClass",
    },
    {
        name: "车辆品牌",
        icon: "icon-tianjiaziji",
        key: "vehicleBrand",
    },
    {
        name: "车辆品牌",
        icon: "icon-tianjiaziji",
        key: "vehicleBrandname",
    },
    ],
},
{
    name: "数据字典",
    icon: "icon-shujuzidian",
    key: "dataDictionaries",
    parent: "7",
},
{
    name: "维保套餐",
    icon: "icon-weixiuguize",
    key: "setMealManage",
    parent: "8",
},
// {
//     name: "商品管理",
//     icon: "icon-shujuzidian",
//     key: "shoppingManage",
//     parent: "9",
//     children: [
//         {
//             name: "商品列表",
//             icon: "icon-tianjiaziji",
//             key: "goodsList",
//         },
//         {
//             name: "商品规格",
//             icon: "icon-tianjiaziji",
//             key: "goodsSpec",
//         },
//         {
//             name: "订单管理",
//             icon: "icon-tianjiaziji",
//             key: "goodsOrder",
//         },
//     ],
// },
{
    name: "积分管理",
    icon: "icon-shujuzidian",
    key: "pointManage",
    parent: "10",
},

{
    name: "智能诊断",
    icon: "icon-shujuzidian",
    key: "aiDiagnose",
    parent: "11",
},
{
    name: "整车管理",
    icon: "icon-shujuzidian",
    key: "allCarManage",
    parent: "12",
    children: [{
        name: "整车管理",
        icon: "icon-tianjiaziji",
        key: "allCarList",
    },
    {
        name: "车型管理",
        icon: "icon-tianjiaziji",
        key: "allCarTypeManage",
    },
    {
        name: "咨询记录",
        icon: "icon-tianjiaziji",
        key: "allCarConsultationRecord",
    },
    {
        name: "访问记录",
        icon: "icon-tianjiaziji",
        key: "allCarAccessRecord",
    },
    ],
},
{
    name: "权限管理",
    icon: "icon-shujuzidian",
    key: "permission",
    parent: "13",
    children: [{
        name: "菜单管理",
        icon: "icon-tianjiaziji",
        key: "menuManage",
    },
    {
        name: "角色管理",
        icon: "icon-tianjiaziji",
        key: "roleManage",
    },
    ],
},
{
    name: "商城订单",
    icon: "icon-shujuzidian",
    key: "permissions",
    parent: "13",
    children: [
    {
        name: "订单b",
        icon: "icon-tianjiaziji",
        key: "shopOrderb",
    },
    {
        name: "订单c",
        icon: "icon-tianjiaziji",
        key: "shopOrderc",
    },
   
    ],
},
{
    name: "机构管理",
    icon: "icon-shujuzidian",
    key: "orgManage",
    parent: "12",
    children: [{
        name: "机构管理",
        icon: "icon-tianjiaziji",
        key: "orgManage",
    },
    {
        name: "人员管理",
        icon: "icon-tianjiaziji",
        key: "personnelManagement",
    },
    ],
},
{
    name: "统计分析",
    icon: "icon-shujuzidian",
    key: "customerStatistics",
    parent: "12",
    children: [{
        name: "数据分析",
        icon: "icon-shouye",
        key: "statisticsIndex",
        parent: "1",
    },{
        name: "客户统计",
        icon: "icon-tianjiaziji",
        key: "customerStatistics",
    },{
        name: "业绩分析",
        icon: "icon-tianjiaziji",
        key: "businessEliteIncome",
    },{
        name: "机构占比",
        icon: "icon-tianjiaziji",
        key: "organIncome",
    },
    {
        name: "调拨数据监测",
        icon: "icon-tianjiaziji",
        key: "transferDataMonitor",
    },
    {
        name: "调入调出监测",
        icon: "icon-tianjiaziji",
        key: "transferMonitor",
    },
    {
        name: "采购单报表",
        icon: "icon-tianjiaziji",
        key: "poListStatistics",
    },{
        name: "采购明细报表",
        icon: "icon-tianjiaziji",
        key: "poStatistics",
    },{
        name: "销售单报表",
        icon: "icon-tianjiaziji",
        key: "soListStatistics",
    },{
        name: "销售明细报表",
        icon: "icon-tianjiaziji",
        key: "soStatistics",
    },{
        name: "维修单报表",
        icon: "icon-tianjiaziji",
        key: "repairListStatistics",
    },{
        name: "维修明细报表",
        icon: "icon-tianjiaziji",
        key: "repairReportStatistics",
    },{
        name: "维修三包成本核算",
        icon: "icon-tianjiaziji",
        key: "SBrepairReportStatistics",
    },{
        name: "业务数据监测",
        icon: "icon-tianjiaziji",
        key: "monitoringStatistics",
    },
    {
        name: "账单监测",
        icon: "icon-tianjiaziji",
        key: "billMonitor",
    },
    {
        name: "进销存统计表",
        icon: "icon-tianjiaziji",
        key: "JXCStatistics",
    },
    {
        name: "出入库记录明细",
        icon: "icon-tianjiaziji",
        key: "stockInAndOut",
    },
],
},
{
    name: "资料管理",
    icon: "icon-shujuzidian",
    key: "tdms",
    parent: "12",
    children: [{
        name: "资料总览",
        icon: "icon-tianjiaziji",
        key: "tdmsIndex",
    },
    {
        name: "技术资料",
        icon: "icon-tianjiaziji",
        key: "tdmsData",
    },
    {
        name: "新增分类",
        icon: "icon-tianjiaziji",
        key: "tdmsCategory",
    },
    {
        name: "资料审核",
        icon: "icon-tianjiaziji",
        key: "tdmsApproval",
    },
    {
        name: "消息互动",
        icon: "icon-tianjiaziji",
        key: "tdmsMsg",
    },
    {
        name: "页面设置",
        icon: "icon-tianjiaziji",
        key: "tdmsSetting",
        children: [{
            name: "页面导航",
            icon: "icon-tianjiaziji",
            key: "tdmsNav",
        },
        {
            name: "焦点图设置",
            icon: "icon-tianjiaziji",
            key: "tdmsFocus",
        }
        ],
    },
    {
        name: "问题题库",
        icon: "icon-tianjiaziji",
        key: "paperQuestion",
    }, {
        name: "答题记录",
        icon: "icon-tianjiaziji",
        key: "paperRecord",
    }
    ],
},
{
    name: "个人设置",
    icon: "icon-shujuzidian",
    parent: "12",
    children: [{
        name: "账户",
        icon: "icon-tianjiaziji",
        key: "personelSet",
    },],
},
{
    name: "日志",
    icon: "icon-shujuzidian",
    parent: "13",
    children: [{
        name: "操作日志",
        icon: "icon-tianjiaziji",
        key: "operationLogs",
    },],
},
{
    name: "基础档案",
    icon: "icon-shujuzidian",
    parent: "14",
    children: [{
        name: "数据字典",
        icon: "icon-tianjiaziji",
        key: "baseDict",
    }, {
        name: "商品档案",
        icon: "icon-tianjiaziji",
        key: "baseGoods",
    },{
        name: "云豪商品",
        icon: "icon-tianjiaziji",
        key: "yhBaseGoods",
    }, {
        name: "仓库档案",
        icon: "icon-tianjiaziji",
        key: "baseWarehouse",
    },
    {
        name: "供应商档案",
        icon: "icon-tianjiaziji",
        key: "baseSupplier",
    },
    {
        name: "客户档案",
        icon: "icon-tianjiaziji",
        key: "baseCustomer",
    },
    {
        name: "车辆档案",
        icon: "icon-tianjiaziji",
        key: "baseVehicle",
    },
    ],
},
{
    name: "采购业务",
    icon: "icon-shujuzidian",
    key: "purchase",
    parent: "15",
    children: [{
        name: "采购平台",
        icon: "icon-tianjiaziji",
        key: "purchaseAdvanced",
    },{
        name: "采购计划",
        icon: "icon-tianjiaziji",
        key: "purchasePlan",
    }, {
        name: "采购列表",
        icon: "icon-tianjiaziji",
        key: "purchasePo",
    }, {
        name: "采购退货",
        icon: "icon-tianjiaziji",
        key: "purchaseRo",
    }, {
        name: "调入单",
        icon: "icon-tianjiaziji",
        key: "purchaseDr",
    }],
},
{
    name: "销售业务",
    icon: "icon-shujuzidian",
    key: "sale",
    parent: "15",
    children: [{
        name: "销售订单",
        icon: "icon-tianjiaziji",
        key: "saleOrder",
    },
    {
        name: "销售单",
        icon: "icon-tianjiaziji",
        key: "saleSo",
    },
    {
        name: "销售退货",
        icon: "icon-tianjiaziji",
        key: "saleRo",
    },
    {
        name: "调出单",
        icon: "icon-tianjiaziji",
        key: "saleDc",
    },
    ],
},
{
    name: "商城业务",
    icon: "icon-shujuzidian",
    key: "shopping",
    parent: "16",
    children: [{
        name: "商品管理",
        icon: "icon-tianjiaziji",
        key: "product",
    },],
},
{
    name: "库存管理",
    icon: "icon-shujuzidian",
    key: "stock",
    parent: "17",
    children: [{
        name: "库存明细",
        icon: "icon-tianjiaziji",
        key: "stockDetailed",
    }, {
        name: "库存统计",
        icon: "icon-tianjiaziji",
        key: "stockStatistic",
    }, {
        name: "库存盘点",
        icon: "icon-tianjiaziji",
        key: "stockCheck",
    }, {
        name: "出库单",
        icon: "icon-tianjiaziji",
        key: "stockOutput",
    },
    {
        name: "入库单",
        icon: "icon-tianjiaziji",
        key: "stockEntry",
    },
    {
        name: "调拨管理",
        icon: "icon-tianjiaziji",
        key: "stockAlloc",
    },
    {
        name: "进销存统计报表",
        icon: "icon-tianjiaziji",
        key: "jxcReport",
    },
    {
        name: "进销存报表",
        icon: "icon-tianjiaziji",
        key: "psiReport",
    },
    {
        name: "积压管理",
        icon: "icon-tianjiaziji",
        key: "backlogManage",
    },
    {
        name: "销存率统计",
        icon: "icon-tianjiaziji",
        key: "inventoryStatistics",
    }, 
    ]
},
{
    name: "账户管理",
    icon: "icon-shujuzidian",
    key: "account",
    parent: "18",
    children: [{
        name: "调拨对账单",
        icon: "icon-tianjiaziji",
        key: "accountInternal",
    }]
},
{
    name: "门店管理",
    icon: "icon-shujuzidian",
    key: "account",
    parent: "18",
    children: [{
        name: "门店管理",
        icon: "icon-tianjiaziji",
        key: "storeManagement",
    }]
},
{
    name: "合约管理",
    icon: "icon-shujuzidian",
    key: "contractManagement",
    parent: "19",
    children: [{
        name: "合约详情管理",
        icon: "icon-tianjiaziji",
        key: "contractParticulars",
    },
    {
        name: "对账单明细",
        icon: "icon-tianjiaziji",
        key: "BillTable",
        hidden:'true'
    },
    {
        name: "对账单里程明细",
        icon: "icon-tianjiaziji",
        key: "MileageOneTable",
        hidden:'true'
    },
]
},
{
    name: "预约管理",
    icon: "icon-shujuzidian",
    key: "",
    parent: "20",
    children: [{
        name: "待转接",
        icon: "icon-tianjiaziji",
        key: "tobeTransferred",
    },
    {
        name: "已转接",
        icon: "icon-tianjiaziji",
        key: "appointmentTransfer",
        hidden:'true'
    },
    {
        name: "已取消",
        icon: "icon-tianjiaziji",
        key: "appointmentCancelled",
        hidden:'true'
    },
]
},


]

//列表组件
const tabs = {
    homeIndex: < HomeMap />,
    statisticsIndex: < StatisticsIndex />,
    quickBilling: < QuickBilling />,
    customerStatistics: < CustomerStatistics />,
    businessEliteIncome: < BusinessEliteIncome />,
    transferDataMonitor: < TransferDataMonitor />,       //调拨数据监测
    transferMonitor: < TransferMonitor />,       //调入调出监测
    poListStatistics: < PoListStatistics />,       //采购单报表
    poStatistics: < PoStatistics />,       //采购明细报表
    soListStatistics: < SoListStatistics />,       //销售单报表
    soStatistics: < SoStatistics />,       //销售明细报表
    repairListStatistics: < RepairListStatistics />,       //维修单报表
    repairReportStatistics: < RepairReportStatistics />,       //维修明细报表
    SBrepairReportStatistics: < SBrepairReportStatistics />,       //维修三包成本核算
    monitoringStatistics:<  MonitoringStatistics  />,  //业务数据监测
    billMonitor:<  BillMonitor  />, //账单检测
    JXCStatistics:<  JXCStatistics  />,  //业务数据监测
    contractParticulars: < ContractParticulars/>,//合约管理
    stockInAndOut: < StockInAndOut/>,//出入库记录明细
    organIncome: < OrganIncome />,       
    operationLogs: < OperationLogs />,
    ordersManage: < OrdersManage />,
    orderManage: < OrderManage />,
    completeOrder: < CompleteOrder />,
    cancelleOrder: < CancelleOrder />,
    orderExportDetail: <OrderExportDetail/>,
    pendingOrder: < PendingOrder />,
    reviewOrder: < ReviewOrder />,
    examineOrderWait: <ExamineOrder status={"wait"}/>,
    examineOrderAdopt: <ExamineOrder status={"adopt"}/>,
    examineOrderReject: <ExamineOrder status={"reject"}/>,
    allOrder: < AllOrder />,
    refundedOrder: < RefundedOrder />,
    returnOrder: <ReturnOrder/>,
    repairPickingList: < RepairPickingList />,
    repairReturnList: < RepairReturnList />,
    factorySettle: < FactorySettle />,
    // sanbaoBill: < SanbaoBill />,
    sanbaoBill: (props) => < SanbaoBill {
        ...props
    }
    />,
    paymentOrder: < PaymentOrder />,
    teamManage: < TeamManage />,
    teamMemberManage: < TeamMemberManage />,
    memberIncome: < MemberIncome />,
    teamIncome: < TeamIncome />,
    teamSettleds: < TeamSettleds />,
    teamUnsettleds: < TeamUnsettleds />,
    moveCarManage: < MoveCarManage />,
    appBannerSet: < AppBannerSet />,
    entity: < Entity />,
    faultCode: < FaultCode/>,
    vehicleEnclosure: < VehicleEnclosure/>,
    servicePricing: < ServicePricing />,
    accountClass: < AccountClass />,
    operatCost: < OperatingCosts />,
    error404: < Error />,
    dataDictionaries: < DataDictionaries />,
    materialManage: < MaterialManage />,
    repairTemplate: < RepairTemplate />,
    projectManage: < ProjectManage />,
    systemClassification: <SystemClassification/>,
    combination: <Combination />,
    clientRecord: < ClientRecord />,
    motorcadeRecord: < MotorcadeRecord />,
    vehicleRecord: < VehicleRecord />,
    vehicleBrand: < VehicleBrand />,
    vehicleBrandname: < VehicleBrandname />,
    redEnvelope: < RedEnvelope />,
    userRecord: < UserRecord />,
    returnVisit: < ReturnVisit />,
    systemConfig: < SystemConfig />,
    instrumentLampType: < InstrumentLampType />,
    listInstrumentLamp: < InstrumentLampList />,
    brandManage: < BrandManege />,
    setMealManage: < SetMealManage />,
    pointManage: < PointManage />,
    aiDiagnose: < AiDiagnose />,
    imageClassify: < ImageClassify />,
    allCarList: < AllCarList />,
    allCarTypeManage: < AllCarTypeManage />,
    allCarAccessRecord: < AllCarAccessRecord />,
    allCarConsultationRecord: < AllCarConsultationRecord />,
    storeManagement:< StoreManagement/>,
    "shopOrderb": < ShopOrderB />,
    "shopOrderc": < ShopOrder />,
    menuManage: < MenuMange />,
    roleManage: < RoleManage />,
    orgManage: < OrgManage />,
    personnelManagement: < PersonnelManagement />,
    orderManageSettleds: < OrderManage />,
    orderManageUnsettleds: < OrderUnsettelManage />,
    noticeManage: < NoticeManage />,
    tdmsIndex: < TdmsIndex />,
    personelSet: < AccountSet />,
    // tdmsData: <TdmsData />,
    tdmsData: (props) => < TdmsData {
        ...props
    }
    />,
    tdmsApproval: < TdmsApproval />,
    tdmsCategory: < TdmsCategory />,
    tdmsFocus: < TdmsFocus />,
    tdmsMsg: < TdmsMsg />,
    tdmsNav: < TdmsNav />,
    //下面是erp融合列表组件
    baseDict: < BaseDict />,
    baseGoods: < BaseGoods />,
    yhBaseGoods: < YHBaseGoods />,
    baseWarehouse: < BaseWarehouse />,
    baseSupplier: < BaseSupplier />,
    baseCustomer: < BaseCustomer />,
    // baseVehicle: < BaseVehicle />,
    baseVehicle: (props) => < BaseVehicle {
        ...props
    }
    />,
    //采购业务
    purchaseAdvanced: <PurchaseAdvanced />,
    purchasePlan: <PurchasePlan />,
    purchasePo: <PurchasePo />,
    purchaseRo: <PurchaseRo />,
    purchaseDr: <PurchaseDr />,
    // 销售业务
    saleOrder: < SaleOrder />,
    saleSo: < SaleSo />,
    saleRo: < SaleRo />,
    saleDc: < SaleDc />,
    // 商城业务
    product: < Product />,
    // 库存管理
    stockDetailed: < StockDetailed />,
    stockStatistic: < StockStatistic />,
    stockCheck: < StockCheck />,
    stockEntry: < StockEntry />,
    stockOutput: < StockOutput />,
    stockAlloc: < StockAlloc />,
    // 进销存报表
    jxcReport: < JXCReport />,
    // 新进销存报表
    psiReport: < PSIReport />,
    backlogManage: < BacklogManage/>,
    inventoryStatistics: < InventoryStatistics />,
    // 账户管理
    // 调拨对账单
    accountInternal: < AccountInternal />,
    //资料管理  考试程序
    paperQuestion: < PaperQuestion />,
    paperRecord: < PaperRecord />,
    tobeTransferred: <TobeTransferred />,
    appointmentTransfer: <AppointmentTransfer/>,
    appointmentCancelled: <AppointmentCancelled/>,
    //维修预约单
    preBookNew: <Prebook tab={'preBookNew'}/>,
    preBookConvert: <Prebook tab={'preBookConvert'}/>,
    preBookCancel: <Prebook tab={'preBookCancel'}/>,
    prebookDetail: <PrebookDetail/>,
}

//详情组件
const detailsPage = {
    OrderDetails: (props) => < OrderDetails {
        ...props
    }
    />,
    ProjectDetails: (props) => < ProjectDetails {
        ...props
    }
    />,
    MaterialDetails: (props) => < MaterialDetails {
        ...props
    }
    />,
    RoleManageDetail: (props) => < RoleManageDetail {
        ...props
    }
    />,
    SystemDetails: (props) => < SystemDetails {
        ...props
    }
    />,
    InstrumentLampTypeDetails: (props) => < InstrumentLampTypeDetails {
        ...props
    }
    />,
    BrandManageDetails: (props) => < BrandManageDetails {
        ...props
    }
    />,
    InstrumentLampListDetails: (props) => < InstrumentLampListDetails {
        ...props
    }
    />,
    VehicleDetails: (props) => < VehicleDetails {
        ...props
    }
    />,
    UserDetails: (props) => < UserDetails {
        ...props
    }
    />,
    MotorcadeDetails: (props) => < MotorcadeDetails {
        ...props
    }
    />,
    ClientDetails: (props) => < ClientDetails {
        ...props
    }
    />,
    MoveCarDetails: (props) => < MoveCarDetails {
        ...props
    }
    />,
    SetMealDetail: (props) => < SetMealDetail {
        ...props
    }
    />,
    SetMealModule: (props) => < SetMealModule {
        ...props
    }
    />,
    RuleDetails: (props) => < RuleDetails {
        ...props
    }
    />,
    SetMealCarType: (props) => < SetMealCarType {
        ...props
    }
    />,
    // GoodDetails: (props) => <GoodDetails {...props} />,
    AiDiagnoseDetails: (props) => < AiDiagnoseDetails {
        ...props
    }
    />,
    PartMaintenance: (props) => < PartMaintenance {
        ...props
    }
    />,
    DiagnoseSpoken: (props) => < DiagnoseSpoken {
        ...props
    }
    />,
    ImageClassifyDetail: (props) => < ImageClassifyDetail {
        ...props
    }
    />,
    ShopOrderDetail: (props) => < ShopOrderDetail {
        ...props
    }
    />,
    ShopOrderBDetail: (props) => < ShopOrderBDetail {
        ...props
    }
    />,
    DataDetails: (props) => < DataDetails {
        ...props
    }
    />,
    ApprovalDetails: (props) => < ApprovalDetails {
        ...props
    }
    />,
    /*erp详情组件 */
    BaseGoodsDetails: (props) => < BaseGoodsDetails {
        ...props
    }
    />,
    BaseWarehouseDetails: (props) => < BaseWarehouseDetails {
        ...props
    }
    />,
    PurchaseAdvancedDetails: (props) => < PurchaseAdvancedDetails {
        ...props
    }
    />,
    PurchasePlanDetails: (props) => < PurchasePlanDetails {
        ...props
    }
    />,
    PurchasePoDetails: (props) => < PurchasePoDetails {
        ...props
    }
    />,
    PurchaseRoDetails: (props) => < PurchaseRoDetails {
        ...props
    }
    />,
    PurchaseDrDetails: (props) => < PurchaseDrDetails {
        ...props
    }
    />,

    // erp融合项目
    BaseSupplierDetails: (props) => < BaseSupplierDetails {
        ...props
    }
    />,
    BaseCustomerDetails: (props) => < BaseCustomerDetails {
        ...props
    }
    />,
    BaseVehicleDetails: (props) => < BaseVehicleDetails {
        ...props
    }
    />,
    StockCheckDetails: (props) => < StockCheckDetails {
        ...props
    }
    />,
    StockOutputDetails: (props) => < StockOutputDetails {
        ...props
    }
    />,
    StockEntryDetails: (props) => < StockEntryDetails {
        ...props
    }
    />,
    SaleOrderDetails: (props) => < SaleOrderDetails {
        ...props
    }
    />,
    SaleSoDetails: (props) => < SaleSoDetails {
        ...props
    }
    />,
    SaleRoDetails: (props) => < SaleRoDetails {
        ...props
    }
    />,
    SaleDcDetails: (props) => < SaleDcDetails {
        ...props
    }
    />,
    ProductGoodsDetails: (props) => < ProductGoodsDetails {
        ...props
    }
    />,
    ProductDetails: (props) => < ProductDetails {
        ...props
    }
    />,
    InternalDetails: (props) => < InternalDetails {
        ...props
    }
    />,
    ContractlDetails:(props)=>< ContractlDetails 
    {...props}
    />,
    BillTable:(props)=>< BillTable 
    {...props}
    />,
    MileageOneTable:(props)=>< MileageOneTable
    {...props}
    />,
    PrebookDetail:(props)=>< PrebookDetail 
    {...props}
    />,

    
}

export {
    menu,
    tabs,
    detailsPage
}