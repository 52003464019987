import { axiosInst } from "./AxiosInst"
// import qs from 'qs';

export async function get(url, data = {}, header = {}, errorIgnore = false) {
    let res = await axiosInst.get(url, {
        params: data,
        headers: { ...header },
        errorIgnore
    })
    return res
}

export async function post(url, data = {}, header = {}, timeout = {}, errorIgnore = false) {
    let res = await axiosInst.post(url, data, {
        ...timeout,
        headers: { ...{ "Content-Type": "application/json" }, ...header },
        errorIgnore
    })
    return res
}
