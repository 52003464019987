import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, message, Modal, DatePicker, Upload, Button, Space, Radio } from "antd"
import { bussinessCostUpdate, bussinessCostOne } from "@/service/dataService"
import moment from "moment"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"

export default (props) => {
    const { auditId, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [applicantType, setApplicantType] = useState(1)

    const [imgs, setImgs] = useState({
        //身份证图片list
        file: [],
        md5: "",
    })

    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        bussinessCostOne(auditId).then((res) => {
            let results = res.retData
            let imgres = results.img || ""
            let file = []
            if(imgres){
                file = imgres.split(",").map((item, index) => {
                    return {
                        uid: index,
                        status: "done",
                        url: `${fileLookUrl().image}${item}`,
                        response: {
                            retData: item,
                        },
                    }
                })
                setImgs({ file, md5: imgres })
            }
            form.setFieldsValue({
                ...results,
                img: file,
                applyTime: results.applyTime ? moment(results.applyTime) : null,
            })
            if(results.costType === 1 || results.costType === 2 ){
                setApplicantType(1)
            }else if(results.costType === 3){
                setApplicantType(2)
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const handleChangeStatus = (id, status) => {
        bussinessCostUpdate({ id, status }).then((res) => {
            message.success("操作成功")
            onSuccess && onSuccess()
            handleCancel()
        })
    }

    return (
        <>
            <Modal forceRender visible={visible} title="经营成本" footer={null} width={600} onCancel={handleCancel}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="项目名称" name="projectName" rules={[{ required: true, message: "请输入项目名称" }]}>
                                    <Input disabled placeholder="备注" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="所属机构"
                                    name="organName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择所属机构",
                                        },
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item label="申请类型" name="applicantType">
                                    <Radio.Group disabled>
                                        <Radio value={1}>个人</Radio>
                                        <Radio value={2}>团队</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}

                            {applicantType === 1 && (
                                <Col span={24}>
                                    <Form.Item label="申请人" name="teamUserName" rules={[{ required: true, message: "请选择申请人" }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            )}

                            {applicantType === 2 && (
                                <Col span={24}>
                                    <Form.Item label="申请团队" name="teamName" rules={[{ required: true, message: "请选择申请团队" }]}>
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={24}>
                                <Form.Item label="费用" name="cost" rules={[{ required: true, message: "请输入费用" }]}>
                                    <Input disabled placeholder="请输入费用" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="日期日期" name="applyTime" rules={[{ required: true, message: "请选择日期" }]}>
                                    <DatePicker
                                        disabled
                                        style={{ width: "100%" }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        showTime={{
                                            defaultValue: moment("00:00:00", "HH:mm:ss"),
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="照片" name="img" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        disabled
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={imgs.file}
                                        // onPreview={() => handlePreview(imgs.md5)}
                                        // onChange={handleImgChange}
                                    >
                                        {imgs.file.length >= 9 ? null : <Button>上传照片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="备注" name="remark">
                                    <Input disabled placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <div style={{ textAlign: "center" }}>
                        <Space size={8}>
                            <Button danger onClick={() => handleChangeStatus(auditId, 3)}>
                                驳回
                            </Button>
                            <Button type="primary" onClick={() => handleChangeStatus(auditId, 2)}>
                                通过
                            </Button>
                        </Space>
                    </div>
                </div>
            </Modal>
        </>
    )
}
