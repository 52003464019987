import React, { useContext, useEffect, useState, useMemo } from "react"
import { MyContext } from "@/store/myContext"
import { tdmsCategoryRootlist, tdmsCategoryList } from "@/service/tdmsService"
import { Button, Form, Input, Col, Row, message, Tabs } from "antd"
import { TagOutlined, DownOutlined, UpOutlined } from "@ant-design/icons"
const { TabPane } = Tabs

export default () => {
    const [tabList, setTabList] = useState([]) // tab数据
    const [position, setPosition] = useState(["left"])
    const [loading, setLoading] = useState(false) // 审核列表loading
    const [actived, setActived] = useState("")
    const [status, setStatus] = useState(false)
    const { dispatch, detailsPage, tabs, state } = useContext(MyContext)
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        if (state.activeMenu === "tdmsIndex") {
            loadData()
        }
    }, [state.activeMenu])
    // 获取tab数据
    const loadData = (current, search = {}) => {
        tdmsCategoryRootlist().then((res) => {
            if (res && res.retCode === 200) {
                let newData = res.retData.filter((item) => item.pid === undefined)
                // newData.unshift({ name: "全部", path: "全部" })
                setTabList(newData)
                setActived(newData[0]?.name)
                onTabChange(newData[0]?.id)
                setLoading(false)
            } else {
                message.warning(res.retMsg)
            }
        })
    }

    const OperationsSlot = {
        left: (
            <span className="tabs-extra-demo-button" style={{ marginRight: 10 }}>
                所属分类:
            </span>
        ),
    }

    const slot = React.useMemo(() => {
        if (position.length === 0) return null

        return position.reduce((acc, direction) => ({ ...acc, [direction]: OperationsSlot[direction] }), {})
    }, [position])

    const onTabChange = (id) => {
        tdmsCategoryList(id).then((res) => {
            if (res && res.retCode === 200) {
                let result = res.retData
                setDataList(result)
            }
        })
    }

    const onChangeStatus = () => {
        setStatus(!status)
    }

    const goToData = (id) => {
        if (!id) return message.error("请选择分类")
        const modeKey = "tdmsData"
        dispatch({
            change: true,
            type: "changeTabs",
            name: "技术资料",
            activeMenu: modeKey,
            targetKey: modeKey,
            content: tabs.tdmsData({
                // modeKey,
                // dispatch,
                rowInfo: id,
            }),
        })
    }

    return (
        <>
            <div style={{ paddingLeft: 20 }}>
                <div className="data-tabs">
                    <div className={status ? "data-tabs-title" : "data-tab-btn-actived"}>
                        <span style={{ color: "black" }}>所属分类:</span>
                        {tabList.map((item, index) => {
                            return (
                                <span
                                    className={actived === item.name ? "tab-title tab-actived" : "tab-title"}
                                    key={index}
                                    onClick={() => {
                                        setActived(item.name)
                                        onTabChange(item.id)
                                    }}
                                >
                                    {item.name}
                                </span>
                            )
                        })}
                    </div>
                    {status ? (
                        <span className="data-tab-btn" onClick={onChangeStatus}>
                            收起
                            <UpOutlined />
                        </span>
                    ) : (
                        <span className="data-tab-btn" onClick={onChangeStatus}>
                            更多
                            <DownOutlined />
                        </span>
                    )}
                </div>
                <div className="data-container">
                    {dataList.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                className="data-card"
                                onClick={() => {
                                    goToData(item.id)
                                }}
                            >
                                <div className="data-title">
                                    <TagOutlined style={{ marginRight: 5 }} />
                                    {item.name}
                                </div>
                                <div className="data-content">
                                    <div className="data-like">
                                        <span>点赞</span>
                                        {item.likeNum ? <span className="data-num">{item.likeNum}</span> : <span className="data-num">0</span>}
                                    </div>
                                    <div className="data-like">
                                        <span>收藏</span>
                                        {item.favorNum ? <span className="data-num">{item.favorNum}</span> : <span className="data-num">0</span>}
                                    </div>
                                </div>
                                <div className="data-footer"></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
