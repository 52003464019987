import React from "react"
import "./style.css"

//此效果来源于https://codepen.io/MarioDesigns/pen/LLrVLK

class Loading extends React.Component {
    render() {
        let { className = "", style = {} } = this.props
        if(Object.entries(style).length < 1){
            style={
                background: "rgba(0,0,0,0.5)",
                height: "100vh",
                with: "100vh",
                position: "fixed",
                left: "0",
                top: "0",
                zIndex: 9999,
            }
        }
        return (
            <div id="my-loading" className={className} style={style}>
                <div className="loader"></div>
                <div className="shadow"></div>
            </div>
        )
    }
}

export default Loading
