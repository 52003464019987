import React, { useEffect, useState, useRef } from "react"
import { getVehicleOne, getOne } from "@/service/customerService"
import { message, Modal } from "antd"
import Imgs from "./imgs"
import BasicInfo from "./basicInfo"
import DabaoInfro from "./dabaoInfo"
import OtherInfo from "./otherInfo"
import OtherAccessories from "./otherAccessories"
import ContractInfo from './contractInfo'
import styled from "@emotion/styled"
import { editVehicle, insertVehicle } from "@/service/customerService"

const VehiclePopupDetail = (props) => {
    const { visible, customerData, mode, setVisible, onSuccess, rowInfo } = props
    const [detail, setDetail] = useState({})


    const [driverImgInfo, setDriverImgInfo] = useState(null)

    const basicInfoRef = useRef(null)
    const otherInfoRef = useRef(null)
    const contractInfoRef = useRef(null)
    const bigBagInfoRef = useRef(null)
    const imgsRef = useRef(null)


    useEffect(() => {
        resetFormItem()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //订单新增， 带过来客户信息就设置上
    useEffect(() => {
        if (customerData && Object.keys(customerData).length > 0) {
            // setCustomerInfo({
            //     data: [
            //         {
            //             text: customerData.customerName,
            //             value: customerData.customerId,
            //         },
            //     ],
            //     fetching: false,
            // })
            // form.setFieldsValue({
            //     customerId: customerData.customerId,
            // })
        }
    }, [customerData]) // eslint-disable-line react-hooks/exhaustive-deps

    //回显表单项
    const resetFormItem = () => {
        if (mode !== "insert") {
            getVehicleOne(rowInfo.id).then((res) => {
                setDetail(res.retData)
            })

        }
    }

    const handleCancel = () => {
        setVisible("")
    }

    const onSubmit = async () => {
        if (mode === "look") {
            handleCancel(false)
            return
        }
        let bigBagInfo = null,
            basicInfo = null,
            otherInfo = null,
            imgs = null
        await basicInfoRef.current
            .upData()
            .validateFields()
            .then((data) => {
                basicInfo = data
            })
        // await bigBagInfoRef.current
        //     .upData()
        //     .validateFields()
        //     .then((data) => {
        //         bigBagInfo = data
        //     })
        await otherInfoRef.current
            .upData()
            .validateFields()
            .then((data) => {
                otherInfo = data
            })
        await imgsRef.current
            .upData()
            .validateFields()
            .then((data) => {
                imgs = data
            })

        Object.keys(imgs).forEach((key) => {
            imgs[key] = imgs[key]?.length > 0 ? imgs[key][0].response.retData : null
        })
        basicInfo = {
            ...basicInfo,
            ...imgs,
            // bigBagFlag: bigBagInfo.bigBagFlag,
            yxnEquipmentCode: otherInfo.yxnEquipmentCode,
            yxnSn: otherInfo.yxnSn,
            drivingSignDate: basicInfo.drivingSignDate ? basicInfo.drivingSignDate.format("YYYY-MM-DDTHH:mm:ss") : null,
            drivingDistributeDate: basicInfo.drivingDistributeDate ? basicInfo.drivingDistributeDate.format("YYYY-MM-DDTHH:mm:ss") : null,
        }
        // bigBagInfo = {
        //     ...bigBagInfo,
        //     signContractDate: bigBagInfo.signContractDate ? bigBagInfo.signContractDate.format("YYYY-MM-DD") : null,
        //     expirationDate: bigBagInfo.expirationDate ? bigBagInfo.expirationDate.format("YYYY-MM-DD") : null,
        // }
        const data = { bigBagInfo, basicInfo, otherInfo }
        if (rowInfo && rowInfo.id) {
            data.vehicleId = rowInfo.id
            editVehicle(data).then(res => {
                message.success("编辑成功")
                setVisible(false)
                onSuccess && onSuccess()
            })
        } else {
            insertVehicle(data).then(res => {
                message.success("新增成功")
                setVisible(false)
                onSuccess && onSuccess()
            })
        }

    }

    return (
        <Modal visible={visible} title="车辆信息" centered={true} onOk={() => onSubmit()} onCancel={() => handleCancel(false)} width={1260}>
            <Box>
                <Imgs detail={detail} mode={mode} imgsRef={imgsRef} setDriverImgInfo={setDriverImgInfo} />
                <BasicInfo detail={detail} mode={mode} basicInfoRef={basicInfoRef} driverImgInfo={driverImgInfo} customerData={customerData} />
                <ContractInfo rowInfo={rowInfo} mode={mode} />
                {/* <DabaoInfro detail={detail} mode={mode} bigBagInfoRef={bigBagInfoRef} /> */}
                <OtherInfo detail={detail} mode={mode} otherInfoRef={otherInfoRef} />
                {rowInfo && rowInfo.id && mode !== "insert" && <OtherAccessories vehicleId={rowInfo.id} mode={mode} />}
            </Box>
        </Modal>
    )
}

const Box = styled.div`
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    .ant-form-item {
        margin-bottom: 10px
    }
`
export default VehiclePopupDetail
