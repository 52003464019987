import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import zeng from "@/assets/images/zeng.png"
import { EditableRow, EditableCell } from "../pubEditble"

//其他项目
export default ({ materialRef, returnMaintainMaterialList, setIsChange, returnOrderInfo }) => {
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //总价
    useEffect(() => {
        setData(
            returnMaintainMaterialList.map((item) => ({
                ...item,
                availableUseNum: item.materialNumber,
                remainingNum: item.materialNumber,
                totalCount: item.materialAmount, //总计
                materialDiscount: item.materialDiscount, //折扣
                actualMoney: item.actualAmount,
            }))
        )
    }, [returnMaintainMaterialList])

    //是否可以修改
    const [isUpdate, setIsUpdate] = useState(false)
    useEffect(() => {
        if (returnOrderInfo.returnStatus === "billed") {
            setIsUpdate(true)
        } else {
            setIsUpdate(false)
        }
    }, [returnOrderInfo])

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualMoney)
        })
        setTotalPrice(num.toFixed(2))
    }, [data])

    //暴露给父组件的方法
    useImperativeHandle(materialRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return data
        },
    }))

    /**
     * 以下是页面展示
     */
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning("请选择你要删除的项目")
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.id) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success("批量删除成功")
        setIsChange && setIsChange(true)
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex
        if (row.id) {
            delIndex = newData.findIndex((item) => item.id === row.id)
        } else {
            delIndex = newData.findIndex((item) => item.time === row.time)
        }
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
        setIsChange && setIsChange(true)
    }

    //table配置
    //table配置
    const columns = [
        {
            title: "序号",
            align: "center",
            width: 40,
            fixed: "left",
            render: (t, r, i) => i + 1,
        },
        {
            title: "商品编号",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "materialCode",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.materialCode.localeCompare(b.materialCode),
        },
        {
            title: "商品名称",
            align: "center",
            width: 200,
            fixed: "left",
            dataIndex: "materialName",
            defaultSortOrder: "ascend",
            sorter: (a, b) => a.materialName.localeCompare(b.materialName),
        },
        {
            title: "领料单号",
            align: "center",
            width: 120,
            dataIndex: "maintainOrderCode",
        },
        {
            title: "品牌",
            align: "center",
            width: 200,
            dataIndex: "brandName",
        },
        {
            title: "单位",
            align: "center",
            width: "120px",
            dataIndex: "unitName",
        },
        {
            title: "可用数量",
            align: "center",
            width: "120px",
            dataIndex: "availableUseNum",
        },
        {
            title: "已选数量",
            align: "center",
            width: "120px",
            dataIndex: "materialNumber",
            editable: isUpdate,
        },
        {
            title: "单价",
            align: "center",
            width: 185,
            dataIndex: "unitPrice",
        },
        {
            title: "总计",
            align: "center",
            width: 185,
            dataIndex: "totalCount",
        },
        {
            title: "优惠",
            align: "center",
            width: 185,
            dataIndex: "preferentialAmount",
        },
        {
            title: "折扣",
            align: "center",
            width: "120px",
            dataIndex: "materialDiscount",
        },

        {
            title: "总金额",
            align: "center",
            width: 160,
            dataIndex: "actualMoney",
        },
        {
            title: "是否赠送",
            align: "center",
            width: 100,
            dataIndex: "giftFlag",
            render: (text) => (text === "gift" ? <img src={zeng} style={{ width: "35px" }} /> : ""),
        },
        {
            title: "备注",
            align: "center",
            width: 160,
            dataIndex: "remark",
        },
        isUpdate
            ? {
                  title: "操作",
                  align: "center",
                  width: "80px",
                  dataIndex: "operation",
                  render: (_, record) => {
                      return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaterialOne(record)} />
                  },
              }
            : {},
    ]

    //单元格编辑保存
    const materialHandleSave = (row, status) => {
        //查看是否是三包
        console.log(status)
        //每次服务性质不是三包的话，把之前选的三包给清空掉。
        if (row.serviceNature === "016001") {
            // row.threeGuaranteesUnit = "10601";
        } else {
            row.threeGuaranteesUnit = ""
            row.claimNumber = ""
        }
        if (status === "serviceNature") {
            row.unitPrice = row.serviceNature === "016001" ? row.price : row.serviceNature === "016002" ? row.lateSalePrice || row.retailPrice : row.unitPrice
        }
        //每次编辑计算总计
        row.totalCount = (row.materialNumber * row.unitPrice).toFixed(2)

        // if (row.serviceNature !== "016001") {
        //有折扣   数量 * 单价 *折扣 - 优惠
        if (+row.materialDiscount > 0) {
            row.actualMoney = ((row.totalCount * row.materialDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualMoney = (row.totalCount - row.preferentialAmount).toFixed(2)
        }
        // } else {
        // row.actualMoney = 0
        // row.preferentialAmount = 0
        // row.materialDiscount = null
        // row.totalCount = 0 //总计
        // }
        if (row.giftFlag === "gift") {
            row.actualMoney = "0.00"
        }
        const newData = [...data]
        const index = newData.findIndex((item) => row.id === item.id && item.temporaryId === row.temporaryId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
        setIsChange && setIsChange(true)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>维修用料</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isUpdate && (
                        <div className="list-name-but">
                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.time}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
        </div>
    )
}
