import React, { useEffect, useImperativeHandle } from "react";
import { Form, Row } from "antd";

export const __Form = ({ formRef, ...props }) => {
    const [form] = Form.useForm();
    const { labelCol, labelAlign, disabled, formValues, onChange, onFinish } = props
    const onValuesChange = (changedValues, values) => {
        onChange && onChange(changedValues, values);
    }
    useEffect(() => {
        form.setFieldsValue(formValues);
    }, [formValues]);

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields();
        },
        setFieldsValue: (formValues) => {
            form.setFieldsValue(formValues);
        },
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        resetFields: form.resetFields,
        inst: () => {
            return form;
        }
    }));
    return <Form
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        labelCol={labelCol}
        labelAlign={labelAlign}
    >
        <Row
            justify="start"
        >
            {
                props?.children
            }
        </Row>
    </Form>;
}