import React from "react";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { useGoodsModel } from "../goodsModel";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

export function PageHeader() {
    const {
        searchParam, organType,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useGoodsModel();
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs]);
    const { getSelectedDictOne } = useDictModel(() => [])

    const searchFields = [
        {
            type: "Input",
            placeholder: "VIN",
            fieldName: "vin"
        },
        {
            type: "Input",
            placeholder: "商品编号",
            fieldName: "code"
        },
        {
            type: "Input",
            placeholder: "商品名称",
            fieldName: "name"
        },
        {
            type: "Input",
            placeholder: "替换编号",
            fieldName: "replaceCode"
        },
        {
            type: "Input",
            placeholder: "发动机型号",
            fieldName: "engineType"
        },
        ...(organType ? [{
            type: "Select",
            placeholder: "所属机构",
            fieldName: "shopId",
            options: orgDs
        }] : []),
        {
            type: "Select",
            placeholder: "状态",
            fieldName: "enable",
            options: [{ label: "启用", value: "1" }, { label: "禁用", value: "0" }]
        },
        {
            type: "Select",
            placeholder: "品牌",
            fieldName: "brandCode",
            options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND)
        }
    ]
    return (
        <YhPageHeader
            title="商品管理"
            size="small"
            triggerSearch
            searchValues={searchParam}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={(params) => { loadData({ pageNum: 1 }, { ...searchParam, ...params }) }}
            handleReset={resetSearchParam}
        />
    );
}