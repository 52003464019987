import React, { useContext, useEffect, useState } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getRedEnvelopeList, syncRedEnvelopeStatus } from "@/service/customerService"
import { getUserList } from "@/service/customerService"
import { MyContext } from "@/store/myContext"
import { Card, List } from "antd"
import Modal from "antd/es/modal/Modal"
//红包管理
const redEnvelope = {
    NOTSEND: "未发放",
    SENDING: "发放中",
    SENT: "已发放待领取",
    FAILED: "发放失败",
    RECEIVED: "已领取",
    RFUND_ING: "退款中",
    REFUND: "已退款",
}

const detailMap = {
    orderId: "订单id",
    sign: "签名",
    remark: "备注",
    actName: "活动名称",
    deleteFlag: "删除标记;0:正常;1:已删除",
    wxResult: "微信返回的请求信息",
    customerId: "客户id",
    sceneId: "场景id",
    id: "id",
    unionId: "用户唯一id",
    mchBillno: "商户订单号`28位`",
    mchId: "商户id",
    sendName: "商户名称",
    riskInfo: "活动信息",
    throwInfo: "异常信息",
    updateUser: "修改人id",
    updateTime: "修改时间",
    wishing: "红包祝福语",
    userId: "接受红包的用户id ",
    wxappid: "公众账号appid",
    nonceStr: "随机字符串",
    totalAmount: "付款金额 付款金额，单位分",
    totalNum: "红包发放总人数",
    createTime: "创建时间",
    clientIp: "Ip地址",
    createUser: "创建人id",
    reOpenid: "用户openid",
    orderCode: "订单编号",
    userName: "用户名称",
    customerName: "客户名称",
    status: "红包发放状态 ",
}

export default () => {
    const { state } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [detailVisible, setDetailVisible] = useState(false)
    const [current, setCurrent] = useState({})
    const [detailDateSource, setDetailDateSource] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "redEnvelope") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    const showDetail = (record) => {
        setCurrent(record)
        console.log(current)
        let arr = Object.keys(record).map((k) => {
            let name = detailMap[k]
            let val = record[k]
            if ("status" === name) {
                val = redEnvelope[val]
            }
            return {
                name: name,
                val: val,
            }
        })
        setDetailDateSource(arr)
        setDetailVisible(true)
    }

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
        }
        getRedEnvelopeList(data).then((res) => {
            // if (res && res.retCode === 200) {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setList(res.retData.list)
            setLoading(false)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //table配置
    const columns = [
        {
            title: "订单编号",
            align: "center",
            dataIndex: "orderCode",
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
        },
        {
            title: "用户名称",
            align: "center",
            dataIndex: "userName",
        },
        {
            title: "红包金额",
            align: "center",
            dataIndex: "totalAmount",
            render: (text) => <>{text / 100}</>,
        },
        {
            title: "发放状态",
            align: "center",
            dataIndex: "status",
            render: (text) => <>{redEnvelope[text]}</>,
        },
        {
            title: "红包祝福语",
            align: "center",
            dataIndex: "wishing",
        },
        {
            title: "红包发放总人数",
            align: "center",
            dataIndex: "totalNum",
        },
        {
            title: "操作",
            align: "center",
            width: 100,
            render: (text, record) => (
                <button
                    onClick={() => {
                        showDetail(record)
                    }}
                >
                    {" "}
                    查看详情
                </button>
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入订单Id",
                valueName: "orderId",
            },
            {
                type: "input",
                placeholder: "请输入订单Code",
                valueName: "orderCode",
            },
            {
                type: "searchSelect",
                placeholder: "请选择用户",
                valueName: "userId",
                optionName: "userName",
                optionId: "id",
                searchApi: getUserList,
            },
            // {
            //     type: "input",
            //     placeholder: "请输入用户Id",
            //     valueName: "userId",
            // },
            {
                type: "input",
                placeholder: "请输入用户名称",
                valueName: "userName",
            },
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "微信商户号",
                valueName: "mchBillno",
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    function handleDetails(mode) {
        if ("sync_status" === mode) {
            setLoading(true)
            syncRedEnvelopeStatus().then((res) => {
                loadData(pagination.current)
            })
        }
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("sync_status"),
            content: "同步红包发送状态",
        },
    ]

    return (
        <>
            {state.activeMenu === "redEnvelope" && (
                <>
                    <TopSearch
                        justify="start"
                        title="红包管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="红包管理"
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        buttons={topButtons}
                        onTableChange={onTableChange}
                    />
                    <Modal
                        visible={detailVisible}
                        onOk={() => {
                            setDetailVisible(false)
                            setCurrent({})
                        }}
                        onCancel={() => {
                            setDetailVisible(false)
                            setCurrent({})
                        }}
                        width={"80%"}
                    >
                        <div className="list-container">
                            <div className="form-details-warp">
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 4,
                                        lg: 4,
                                        xl: 6,
                                        xxl: 3,
                                    }}
                                    dataSource={detailDateSource}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Card title={item.name}> {item.val}</Card>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal>
                </>
            )}
        </>
    )
}
