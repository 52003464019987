import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import YhIcon from '@/components/pubIcon'
import AddMaterial from "./MaterialAdd"
import { EditableRow, EditableCell } from '../../pubEditble'
import { getPcodeDict } from '@/utils/dataStorage'
import Loading from '@/components/Loading'
import { MaterialHistory } from '@/erp_subpackage/pages/common/materialHistory/index'
import { usePrebookModel } from "../prebookModel";
import RepairPickingListDetail from '../../repairPickingList/detail'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import YhTooltip from '@/components/YhTooltip'
//物料
export default ({ mode, popupMode, threeGuaranteeId }) => {
    const { getInfo, getMaterialList, setMaterialList, guid } = usePrebookModel();
    const [addVisible, setAddVisible] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0) //维修物料的总价
    const [dictObj, setDictObj] = useState({
        serviceNature: {},
        threeGuaranteesUnit: {},
    })
    const { user: { shopId }, } = useGlobalModel()
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [loading, setLoading] = useState(false)
    const [historyData, setHistoryData] = useState({ visible: false, materialCode: '', materialName: '', shopId: '' })
    const [maintainOrderId, setMaintainOrderId] = useState('')
    const [maintainOrderDetailVisible, setMaintainOrderDetailVisible] = useState(false)

    //点开弹框初始下页面需要的字典数据
    const initDict = () => {
        let serviceNature = {}
        let threeGuaranteesUnit = {}
        //服务性质
        getPcodeDict('016').children.forEach((item) => {
            serviceNature[item.dictCode] = item.title
        })
        //三包单位
        getPcodeDict('106').children.forEach((item) => {
            threeGuaranteesUnit[item.dictCode] = item.title
        })
        setDictObj({
            serviceNature: serviceNature,
            threeGuaranteesUnit: threeGuaranteesUnit,
        })
    }

    useEffect(() => {
        initDict()
    }, [])

    //计算总价
    useEffect(() => {
        let materialAmount = 0;
        getMaterialList(mode) && getMaterialList(mode).filter((item) => item.threeGuaranteeId == threeGuaranteeId).forEach(e => {
            materialAmount = Number(materialAmount) + Number(e.actualAmount);
            materialAmount = materialAmount.toFixed(2);
        });
        setTotalPrice(materialAmount);
    }, [threeGuaranteeId, getMaterialList(mode)])


    //table配置
    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "商品编号",
            width: 200,
            align: "center",
            dataIndex: "materialCode",
            sorter: (a, b) => a.materialCode.localeCompare(b.materialCode),
            render: (text, record) => (
                <YhTooltip
                    style={{ color: '#2e3aeb', cursor: 'pointer' }}
                    onClick={() => {
                        setHistoryData({ visible: true, materialCode: record.materialCode, materialName: record.materialName, shopId: shopId })
                    }}
                    text={text}
                />
            ),
        },
        {
            title: "商品名称",
            width: 200,
            align: "center",
            dataIndex: "materialName",
        },
        {
            title: "品牌",
            width: 120,
            align: "center",
            dataIndex: "brandName",
        },
        {
            title: "单位",
            width: 120,
            align: "center",
            dataIndex: "unitName",
        },
        {
            title: "可用库存",
            width: 120,
            align: "center",
            dataIndex: "availableStock",
        },
        {
            title: "数量",
            width: 120,
            align: "center",
            dataIndex: "materialNumber",
            editable: true,
        },
        {
            title: "销售单价",
            width: 120,
            align: "center",
            dataIndex: "salePrice",
            editable: true,
        },
        {
            title: "总计",
            width: 120,
            align: "center",
            dataIndex: "materialAmount",
        },
        {
            title: "优惠",
            width: 120,
            align: "center",
            dataIndex: "preferentialAmount",
            editable: true,
        },
        {
            title: "折扣",
            width: 120,
            align: "center",
            dataIndex: "materialDiscount",
            editable: true,
        },
        {
            title: "总金额",
            width: 120,
            align: "center",
            dataIndex: "actualAmount",
        },
        {
            title: "备注",
            width: 200,
            align: "center",
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            width: 120,
            align: "center",
            dataIndex: "operation",
            render: (_, record) => {
                return (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                    <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteOne(record)} />
            },
        },
    ]

    //单元格编辑保存
    const materialHandleSave = (row, status) => {
        row.materialAmount = (row.materialNumber * row.salePrice).toFixed(2);
        if (row.materialDiscount > 0) {
            row.actualAmount = ((row.materialAmount * row.materialDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualAmount = (row.materialAmount - row.preferentialAmount).toFixed(2)
        }
        const newData = [...getMaterialList(mode)]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setMaterialList(mode, newData);
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    //新增物料回调
    const chooseMaterialCbk = (materials) => {
        setAddVisible(false)
        let arr = []
        materials.forEach((item) => {
            if (!getMaterialList(mode).find((m) => m.inventoryDetailsId === item.id)) {
                let newItem = {
                    id: guid().replaceAll("-", ""),
                    threeGuaranteeId: threeGuaranteeId.replace("-", ""),
                    materialCode: item.materialCode,
                    materialName: item.materialName,
                    unitCode: item.unitCode,
                    unitName: item.unitName,
                    brandCode: item.brandCode,
                    brandName: item.brandName,
                    salePrice: item.lateSalePrice || item.retailPrice,
                    purchasePrice: item.latestPurPrice,
                    availableStock: item.availableStock,
                    materialNumber: item.num,
                    materialAmount: (item.lateSalePrice || item.retailPrice) * item.num,
                    materialDiscount: 0,
                    preferentialAmount: 0,
                    actualAmount: ((item.lateSalePrice || item.retailPrice) * item.num).toFixed(2),
                    remark: '',
                    purchaseDetailId: item.id,
                }
                arr.push(newItem)
            }
        })
        setMaterialList(mode, [...getMaterialList(mode), ...arr])
    }

    const onRow = (record) => {
        let findIndex = selectedRowKeys.indexOf(record.id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(record.id);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const deleteOne = (record) => {
        let list = getMaterialList(mode).filter((item) => item.id !== record.id);
        setMaterialList(mode, list);
        setSelectedRowKeys([]);
    };

    const deleteBatch = () => {
        let list = getMaterialList(mode);
        selectedRowKeys.forEach((e) => {
            list = list.filter((item) => item.id !== e)
        })
        setMaterialList(mode, list);
        setSelectedRowKeys([]);
    };

    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>维修物料</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({getMaterialList(mode) && getMaterialList(mode).length}项){' '}
                    </span>
                    <div className="list-name-but">
                        {getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                            <>
                                <YhIcon
                                    type="icon-zengjia"
                                    style={{ color: '#37B36F', fontSize: '26px' }}
                                    onClick={() => {
                                        setAddVisible(true)
                                    }}
                                />
                                <span className="list-delete" onClick={() => deleteBatch()}>
                                    批量删除
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id}
                components={components}
                columns={MaterialColumns}
                dataSource={getMaterialList(mode) && getMaterialList(mode).filter((item) => item.threeGuaranteeId == threeGuaranteeId)}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={{
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let changeRowId = changeRows.map(item => item.id);
                        if (selected) {
                            setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        }
                        else {
                            setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                        }
                    },
                    onSelect: (record, selected, selectedRows) => onRow(record),
                    selectedRowKeys: selectedRowKeys
                }}
            />
            <AddMaterial visible={addVisible} onClose={(bool) => setAddVisible(bool)} onItemOk={chooseMaterialCbk} />
            {loading && (
                <Loading
                    style={{
                        background: 'rgba(0,0,0,0.5)',
                        height: '100vh',
                        with: '100vh',
                        position: 'fixed',
                        left: '0',
                        top: '0',
                        zIndex: 9999,
                    }}
                />
            )}
            {historyData.visible && (
                <MaterialHistory {...historyData} onCancel={() => setHistoryData({ visible: false, materialCode: '', materialName: '', shopId: '' })} />
            )}
            {maintainOrderDetailVisible && (
                <RepairPickingListDetail
                    rowInfo={{ id: maintainOrderId }}
                    visible={maintainOrderDetailVisible}
                    setVisible={setMaintainOrderDetailVisible}
                    handleSuc={() => {
                        setMaintainOrderDetailVisible(false)
                    }}
                    mode={'look'}
                />
            )}
        </div>
    )
}
