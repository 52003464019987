import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { tdmsPictureSearch, tdmsPictureDelete, tdmsPictureMove } from "@/service/tdmsService"
import { MyContext } from "@/store/myContext"
import { fileLookUrl } from "@/config/app"
import { Image, Modal, Popconfirm, Button } from "antd"
import EditModal from "./editModal"

export default () => {
    const { dispatch, state, message, detailsPage, Switch } = useContext(MyContext)
    const [dataSource, setDataSource] = useState([]) //列表
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [mode, setMode] = useState("")
    const [editVisible, setEditVisible] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "tdmsNav") {
            loadData()
        }
        return () => setDataSource([])
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, searchValue = {}) => {
        let data = {
            classify: 1,
            page: {
                pageNum: current,
                pageSize: 10,
            },
        }
        tdmsPictureSearch(data).then((res) => {
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setDataSource(res.retData.records)
        })
    }
    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current)
    }
    //新增 编辑
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的信息")
                return
            }
            setMode("edit")
        } else {
            setMode("add")
        }
        setEditVisible(true)
    }
    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的导航")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除导航 - ${selectInfo.rowInfo.name}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                tdmsPictureDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current)
                })
            },
        })
    }
    // //配置开关
    // const sweitchChange = (checked, e, id) => {
    //     e.stopPropagation()
    //     vehicleBrandDisable({ id: id, disableFlag: checked ? 0 : 1 }).then((res) => {
    //         message.success("操作成功")
    //     })
    //     loadData(pagination.current, searchValue)
    // }

    // 导航删除
    const onDelete = (record) => {}
    // 导航位置移动
    const onMove = (originId, changeId) => {
        tdmsPictureMove([originId, changeId]).then((res) => {
            message.success("操作成功!")
            loadData(1)
        })
    }

    //table配置
    const columns = [
        {
            title: "导航图片",
            align: "center",
            dataIndex: "fileMd5",
            width: "200px",
            render: (text) => (text ? <Image src={`${fileLookUrl().image}${text}`} width={68} height={68} /> : null),
        },
        {
            title: "名称",
            align: "center",
            dataIndex: "name",
            with: 100,
        },
        {
            title: "URL",
            align: "center",
            dataIndex: "url",
            with: 300,
        },
        {
            title: "操作",
            align: "center",
            width: "300px",
            dataIndex: "disableFlag",
            render: (text, record, index) => (
                <>
                    <Button shape="round" disabled={index === 0} className="yc-public-but" onClick={() => onMove(record.id, dataSource[index - 1].id)}>
                        上移
                    </Button>
                    <Button
                        shape="round"
                        disabled={index === dataSource.length - 1}
                        className="yc-public-but"
                        onClick={() => onMove(record.id, dataSource[index + 1].id)}
                    >
                        下移
                    </Button>
                </>
            ),
        },
    ]
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "品牌名称",
                valueName: "name",
            },
            {
                type: "Checkbox",
                valueName: "disableFlag",
                placeholder: "禁用状态",
                defaultChecked: searchValue.disableFlag ? true : false,
            },
        ],
    }
    return (
        <>
            {state.activeMenu === "tdmsNav" && (
                <>
                    {/* <TopSearch
                        title="品牌管理"
                        justify="end"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    /> */}
                    <ListComponent
                        title="页面导航"
                        buttons={topButtons}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        scroll={{ x: "100%" }}
                    />
                </>
            )}
            <EditModal mode={mode} row={selectInfo.rowInfo} visible={editVisible} onLoadData={loadData} onCancel={(bool) => setEditVisible(bool)} />
        </>
    )
}
