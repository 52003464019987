import React, { useState, useEffect } from 'react'
import { message, Button } from 'antd'
import { YhFormBox } from '@/erp_subpackage/components/YhFormBox'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { getPcodeDict } from '@/utils/dataStorage'
import { getBentityListOption } from '@/utils/dataStorage'
import { getVehicleList } from '@/service/customerService'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { usePrebookModel } from '../prebookModel'
import { getUserOrgan } from '@/utils/dataStorage'
import { debounce } from 'lodash'
// import InsertAddressMap from '../../insertAddressMap'
import InsertAddressMap from '@/components/TMapChoose' //地图

export const FormBox = ({ mode, rowInfo, formRef }) => {
    const {
        fetchYCCustomerList,
        orgDs,
        user: { shopId },
    } = useGlobalModel()
    const { getSelectedDictOne } = useDictModel(() => [])
    const [vehicleList, setVehicleList] = useState([])
    const [mapVisible, setMapVisible] = useState(false) //地图
    const [mapAddr, setMapAddr] = useState(null) //地图地址信息

    const { getInfo, setInfo, setItemList, setMaterialList, setThreeGuaranteesList, getPrebookOne, setOrderType } = usePrebookModel()

    useEffect(() => {
        formRef.current.resetFields()
        setItemList(mode, [])
        setMaterialList(mode, [])
        setThreeGuaranteesList(mode, [])
        if (mode === 'add') {
            formRef.current.setFieldsValue({
                orderType: '014002',
                serviceMethod: '012001',
                serviceType: '011001',
            })
            setInfo(mode, { shopId: shopId })
            setOrderType(mode, '014002')
        }
        if (mode === 'edit') {
            getPrebookOne(mode, rowInfo.id)
        }
        //初始化报修地址
        initRepairAddress()
    }, [])

    const initRepairAddress = () => {
        if (mode === 'add') {
            const { organAddress, organLat, organLng } = getUserOrgan()
            if (organAddress && organLat && organLng) {
                setMapAddr({
                    //地图初始信息
                    name: organAddress,
                    addr: organAddress,
                    repairLng: organLng,
                    repairLat: organLat,
                })
                formRef.current.setFieldsValue({ repairAddress: organAddress})
            } else {
                setMapAddr(null)
            }
        }
        if (mode === 'edit') {
            setMapAddr({ repairAddress: rowInfo.repairAddress, repairLng: rowInfo.repairLng, repairLat: rowInfo.repairLat })
        }
    }

    //地图保存
    const saveAddressDetail = (ret) => {
        setMapAddr({ repairAddress: ret.addr, repairLng: ret.longitude, repairLat: ret.latitude })
        formRef.current.setFieldsValue({ repairAddress: ret.addr, repairLng: ret.longitude, repairLat: ret.latitude })
        setMapVisible(false)
    }

    //客户选择
    const customerChange = (value, option) => {
        if (value && option) {
            let { id, label, address } = option
            formRef.current.setFieldsValue({
                customerId: id,
                customerName: label,
                vehicleCode: null,
            })
            setVehicleList([])
            vehicleQuery()
        }
    }

    //车辆列表查询
    const vehicleQuery = debounce(async (val) => {
        let fields = formRef.current.getFieldsValue()
        let customerId = fields && fields.customerId
        let D = {
            pageSize: 15,
            currentPage: 1,
            searchItem: {
                vehicleCode: val,
                customerId: customerId || null,
            },
        }

        let list = await getVehicleList(D).then((res) => {
            let results = res.retData.list
            if (results && results.length > 0) {
                return results.map((item) => ({
                    key: item.id,
                    id: item.id,
                    text: item.vehicleCode,
                    value: item.id,
                    vehicleVin: item.vehicleVin,
                    vehicleType: item.vehicleType,
                    vehicleModel: item.vehicleModelId,
                    customerAddress: item.customerAddress,
                    sender: item.sender,
                    senderPhone: item.senderPhone,
                }))
            } else {
                message.warning('没有此车辆')
                return []
            }
        })
        setVehicleList(list)
    }, 500)

    const vehicleChange = (val, option) => {
        if (val && option) {
            let { label, value, vehicleVin, vehicleType, vehicleModel, customerAddress, sender, senderPhone } = option
            formRef.current.setFieldsValue({
                vehicleCode: label,
                vehicleId: value,
                vehicleVin: vehicleVin,
                vehicleType: vehicleType,
                vehicleModel: vehicleModel,
                contactAddress: customerAddress,
                senderRepairUserName: sender,
                senderRepairUserPhone: senderPhone,
            })
        }
    }

    const orderTypeChange = (val, option) => {
        if (val && option) {
            let { value } = option
            setOrderType(mode, value)
        }
    }

    const baseFormItem = {
        title: ' ',
        style: { padding: '0 20px', marginBottom: '10px' },
        contentClass: 'pr-77',
        items: [
            {
                type: 'Input',
                fieldLabel: '客户ID',
                fieldName: 'customerId',
                hidden: true,
            },
            {
                type: 'AsyncSelect',
                fieldLabel: '客户名称',
                fieldName: 'customerName',
                selectfetchList: fetchYCCustomerList,
                onSelectChange: customerChange,
                defaultQuery: '公司',
                allowClear: true,
                showSearch: true,
                rules: [{ required: true, message: '请选择客户名称' }],
            },
            {
                type: 'Input',
                fieldLabel: '车辆ID',
                fieldName: 'vehicleId',
                hidden: true,
            },
            {
                type: 'Select',
                fieldLabel: '车辆牌照',
                fieldName: 'vehicleCode',
                onSelectSearch: (val) => vehicleQuery(val),
                onSelectChange: (val, option) => vehicleChange(val, option),
                options: vehicleList.map((item) => {
                    return {
                        label: item.text,
                        value: item.value,
                        key: item.value,
                        vehicleVin: item.vehicleVin,
                        vehicleType: item.vehicleType,
                        vehicleModel: item.vehicleModel,
                        customerAddress: item.customerAddress,
                        sender: item.sender,
                        senderPhone: item.senderPhone,
                    }
                }),
            },
            {
                type: 'Input',
                fieldLabel: '送修人',
                fieldName: 'senderRepairUserName',
                rules: [{ required: true, message: '请输入送修人' }],
            },
            {
                type: 'Input',
                fieldLabel: '联系电话',
                fieldName: 'senderRepairUserPhone',
                rules: [{ required: true, message: '请输入联系电话' }],
            },
            {
                type: 'Input',
                fieldLabel: 'Vin码',
                fieldName: 'vehicleVin',
            },
            {
                type: 'Input',
                fieldLabel: '车辆类型',
                fieldName: 'vehicleType',
                // options: getPcodeDict('029').children.map(item => {
                //     return ({ label: item.title, value: item.dictCode, key: item.id })
                // }),
            },
            {
                type: 'Input',
                fieldLabel: '车辆型号',
                fieldName: 'vehicleModel',
            },
            {
                type: 'Input',
                fieldLabel: '故障描述',
                fieldName: 'faultDesc',
                rules: [{ required: true, message: '请输入故障描述' }],
            },
            {
                type: 'Input',
                fieldLabel: '车辆里程',
                fieldName: 'mileage',
            },
            {
                type: 'Input',
                fieldLabel: '工作小时',
                fieldName: 'workHours',
            },
            {
                type: 'Input',
                fieldLabel: '备注',
                fieldName: 'remark',
            },
            {
                type: 'Input',
                fieldLabel: '联系地址',
                fieldName: 'contactAddress',
            },
            {
                type: 'Input',
                fieldLabel: '报修地址',
                fieldName: 'repairAddress',
                disable: true,
                addon: (
                    <Button style={{ margin: 0, padding: '0px 5px' }} onClick={() => setMapVisible(true)}>
                        获取位置
                    </Button>
                ),
                onBlur: () => setMapVisible(true),
            },
            {
                type: 'Input',
                fieldLabel: '报修地址lng',
                fieldName: 'repairLng',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '报修地址lng',
                fieldName: 'repairLat',
                hidden: true,
            },
            {
                type: 'Select',
                fieldLabel: '工单类型',
                fieldName: 'orderType',
                onSelectChange: orderTypeChange,
                options: getPcodeDict('014').children.map((item) => {
                    return { label: item.title, value: item.dictCode, key: item.id }
                }),
                rules: [{ required: true, message: '请选择工单类型' }],
            },
            {
                type: 'Select',
                fieldLabel: '服务方式',
                fieldName: 'serviceMethod',
                options: getPcodeDict('012').children.map((item) => {
                    return { label: item.title, value: item.dictCode, key: item.id }
                }),
                rules: [{ required: true, message: '请选择服务方式' }],
            },
            {
                type: 'Select',
                fieldLabel: '故障类型',
                fieldName: 'serviceType',
                options: getPcodeDict('011').children.map((item) => {
                    return { label: item.title, value: item.dictCode, key: item.id }
                }),
                rules: [{ required: true, message: '请选择故障类型' }],
            },
            {
                type: 'Select',
                fieldLabel: '所属机构',
                fieldName: 'shopId',
                options: orgDs,
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: '经营实体',
                fieldName: 'entityCode',
                options: getBentityListOption().map((item) => {
                    return { label: item.text, value: item.value, key: item.value }
                }),
            },
            {
                type: 'Select',
                fieldLabel: '结算方',
                fieldName: 'settlementPartyCode',
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY).map((item) => {
                    return { label: item.label, value: item.value, key: item.value }
                }),
            },
            {
                type: 'Select',
                fieldLabel: '结算类型',
                fieldName: 'settlementType',
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
            },
            {
                type: 'Datepicker',
                fieldLabel: '进厂时间',
                fieldName: 'entryFactoryTime',
                showTime: true,
            },
            {
                type: 'Datepicker',
                fieldLabel: '出厂时间',
                fieldName: 'outFactoryTime',
                showTime: true,
            },
            {
                type: 'Input',
                fieldLabel: '待修内容',
                fieldName: 'tobeRepairedItems',
            },
            {
                type: 'Input',
                fieldLabel: rowInfo.prebookStatus == 2 ? '作废原因' : '处理意见',
                fieldName: rowInfo.prebookStatus == 2 ? 'cancelReason' : 'processResult',
            },
        ],
    }

    const fromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: 'right',
        disabled: getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus == 0 ? false : true),
        formValues: getInfo(mode),
        onChange: (changedValues) => {},
        boxs: [baseFormItem],
    }

    return (
        <>
            <YhFormBox {...fromConfig} />
            {mapVisible && (
                <InsertAddressMap
                    visible={mapVisible}
                    onCloseMap={() => setMapVisible(false)}
                    saveAddressDetail={saveAddressDetail}
                    defaultInfo={{
                        lat: mapAddr.repairLat,
                        lng: mapAddr.repairLng,
                    }}
                />
            )}
        </>
    )
}
