import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

//discountPrice 优惠价  shopPrice 商场价 tradePrice 批发价 itemCode 商品编码  itemName 商品名称
//categoryCode 所属分类  categoryName 所属分类名称

//详情
// export interface ISCMGoodsBase {
// brandCode: string,               // "",
// brandName: string,               // "",
// code: string,               // "",
// createTime: string,               // "",
// createUser: string,               // "",
// deleteFlag: number,               // 0,
// engineType: string,               // "",
// id: string,               // "",
// inventoryNum: number,               // 0,
// limitLower: number,               // 0,
// limitUpper: number,               // 0,
// madeFrom: string,               // "",
// model: string,               // "",
// name: string,               // "",
// nameEn: string,               // "",
// note: string,               // "",
// orderNum: number,               // 0,
// purchasePrice: number,               // 0,'
// latestPurPrice: number,
// replaceCode: string,               // "",
// retailPrice: number,               // 0,
// shelfCode: string,               // "",
// shelfId: string,               // "",
// status: string | number | boolean,               // 0,
// typeCode: string,               // "",
// typeName: string,               // "",
// unitCode: string,               // "",
// unitName: string,               // "",
// updateTime: string,               // "",
// updateUser: string,               // "",
// warehouseCode: string,               // "",
// warehouseId: string,               // "",
// warehouseName: string,               // "",
// wholesalePrice: number,               // 0
// materialCode?: string,
// materialName?: string,
// materialNameEn?: string,
// price?: number,
// enable?: number,
// minOrderNum?: number,
// materialDetal?:IWarehouseMaterialDetal[]
// }


// //搜索条件
// export interface IMaterialSearchFields {
//     code?: string,
//     name?: string,
//     replaceCode?: string,
//     typeCode?: string,
//     engineType?: string,
//     orderStatus?: string,
//     shopId?: string,
// }

// export interface IWarehouseMaterialDetal {
//     id?: string, // "",
//     materialCode?: string, // "",
//     materialName?: string, // "",
//     purchasePrice?: number, // 0,
//     repairPrice?: number, // 0,
//     shelfCode?: string, // "",
//     shelfId?: string, // "",
//     shopId?: string, // "",
//     shopName?: string, // "",
//     warehouseCode?: string, // "",
//     warehouseId?: string, // "",
//     warehouseName?: string, // ""
// }


class GoodsService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/base/material/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/base/material/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }
    async insert(insertVo) {
        const apiUrl = "/ci-storage/base/material/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/base/material/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //启用禁用
    async disable(params) {
        const apiUrl = `/ci-storage/base/material/change`;
        return await httpRequest.post(apiUrl, params);
    }
    //导入
    async import(file) {
        const appPageUrl = "/ci-storage/base/material/import";
        return await httpRequest.post(appPageUrl, file);
    }
    //导出
    async export(searchVo) {
        const appPageUrl = "/ci-analyze/export/material";
        return await httpRequest.post(appPageUrl, searchVo);
    }
    //分页查询商品列表-用于采购
    async findPageByPurchase(searchVo) {
        const appPageUrl = `/ci-storage/base/material/findPageByPurchase`;
        return await httpRequest.post(appPageUrl);
    }
    //下载模板
    async downloadTpl() {
        const appPageUrl = "/ci-storage/base/material/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    //商品导入图片
    async importImg(file) {
        const appPageUrl = "/ci-storage/base/material/importImg";
        return await httpRequest.post(appPageUrl, file);
    }
    //同步
    async sync(data) {
        const appPageUrl = `/ci-storage/base/material/sync`;
        return await httpRequest.post(appPageUrl, data, { timeout: 300000 });
    }
}

export const goodsService = new GoodsService();
