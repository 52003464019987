import React, { useRef, useEffect, useState } from 'react'
import { message, Modal, Button } from 'antd'
import YhDrawer from '@/erp_subpackage/components/YhDrawer'
import { YhForm } from '@/erp_subpackage/components/YhFormBox'
import { usePurchaseAdvancedDetailsModel } from './purchaseAdvancedDetailModel'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { debounce } from 'lodash'
import { formatNum, repairFormatNum } from '@/erp_subpackage/utils/util'
import { defaultMdCmParam } from '@/erp_subpackage/model/globalModel'
import { purchaseAdvancedService } from '../../purchaseAdvancedService'
import { commonService } from '@/erp_subpackage/pages/common/commonService'
import { AdjustDrawer } from './AdjustDrawer'
// import { OpenAccountDrawer } from './OpenAccountDrawer'
import { RechargeDrawer } from './RechargeDrawer'
import { YhFmsAddAccount } from '@/erp_subpackage/components/YhFmsAddAccount'
import { IsFranchisees } from '@/utils/common'

export const SettleDrawer = ({ modeKey, mode, rowCode, dispatch }) => {
    const {
        settleVisible: visible,
        editFormVal,
        lookFormVal,
        addFormVal,
        setSettleVisible,
        onSettle,
        updateFormVal,
        setLookFormVal,
    } = usePurchaseAdvancedDetailsModel()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const {
        user: { userName, shopCode },
    } = useGlobalModel()
    const formVal = mode === 'edit' ? editFormVal : lookFormVal
    const [newCase, setNewcase] = useState('')
    const formRef = useRef()
    const typeForm = {
        add: addFormVal,
        edit: editFormVal,
        look: lookFormVal,
    }
    // const [settleType, setSettleType] = useState(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE_CASH)) //结算类型
    const [settleType, setSettleType] = useState(typeForm[mode].payType) //结算类型
    const [cash, setCash] = useState(false)
    const [editPayMethodOptions, setEditPayMethodOptions] = useState(getSelectedDictOne(DictCodeEnmu.PAY_METHOD)) //编辑根据结算类型展示结算方式
    const [accountTypeDs, setAccountTypeDs] = useState([]) //实时存取收款账户类型值settle-type.cash
    const { sourceApp, branch } = defaultMdCmParam
    useEffect(() => {
        visible && setSettleType(formVal.payType)
        //formRef.current.setFieldsValue({ ...typeForm[mode] })

        visible &&
            queryAccount(
                typeForm[mode].supplierCode,
                typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_BALANCE ? 1 : 1,
                typeForm[mode].payType
            )
    }, [visible])
    // useEffect(() => {

    //  })

    const setPayMethodOptions = (payType) => {
        let newPayMethodOptions = getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter((item) => {
            if (item.relationvalue === payType) {
                return item
            }
        })
        setEditPayMethodOptions(newPayMethodOptions)
    }
    // 查询客户账户类型及可用额度
    const queryAccount = async (partnerCode, creditFlag, payType) => {
         //加盟商不需要查询账户
         if (IsFranchisees()) {
            return
        }
        if (mode == 'look') {
            // debugger
            const { settleDetails } = lookFormVal
            const { payMethodName } = settleDetails[0]
            let cashname1 = payMethodName

            updateFormVal(mode, {
                cashname1,
                ...lookFormVal,
            })
            console.log(lookFormVal)
        }

        //console.log(creditFlag)
        let params = {
            partnerCode, //供应商编码
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag, //为记账类型的  1挂账 2现结
            sourceApp,
            shopCode,
        }
        const { retData } = await purchaseAdvancedService.accountFind(params)
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter((item) => {
            return item
        })
        let newAccTypeDs = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        if (payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
            newAccTypeDs = filterResult.map((item) => ({
                ...item,
                label: item.name ?? '',
                value: item.code ?? '',
                available: item.availableAmount ?? 0,
                accountTypeCode: item.accountTypeCode,
                key: item.key ?? '',
            }))
            console.log(newAccTypeDs)
        } else if (payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
            //挂账  account-type.credit
            newAccTypeDs = filterResult
                .filter((item) => {
                    if (item.accountTypeCode !== 'account-type.credit-month')
                        return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                })
                .map((item1) => ({
                    ...item1,
                    label: item1.name ?? '',
                    value: item1.code ?? '',
                    available: item1.availableAmount ?? 0,
                    accountTypeCode: item1.accountTypeCode,
                    key: item1.key ?? '',
                }))
            console.log(newAccTypeDs)
            // newAccTypeDs = filterResult.map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accountTypeCode: item1.accountTypeCode , key: item1.key ?? "" }));
        } else if (payType === DictCodeEnmu.SETTLE_TYPE_BALANCE) {
            //月结  account-type.credit-month
            newAccTypeDs = filterResult
                .filter((item) => {
                    if (item.accountTypeCode == 'account-type.credit-month')
                        return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                })
                .map((item1) => ({
                    ...item1,
                    label: item1.name ?? '',
                    value: item1.code ?? '',
                    available: item1.availableAmount ?? 0,
                    accountTypeCode: item1.accountTypeCode,
                    key: item1.key ?? '',
                }))
            console.log(newAccTypeDs)
            //  newAccTypeDs = filterResult.map(item1 => ({ label: item1.name ?? "", value: item1.code ?? "", available: item1.availableAmount ?? 0, accountTypeCode: item1.accountTypeCode , key: item1.key ?? "" }));
        }
        setAccountTypeDs(newAccTypeDs)
        let accTypeDs = newAccTypeDs[0]
        if (newAccTypeDs.length > 0) {
            setCash(accTypeDs.accountTypeCode === 'account-type.cash')
            formRef.current.setFieldsValue({
                payMethodName: accTypeDs.label,
                payMethodCode: accTypeDs.value,
                payMethodValue: accTypeDs.accountTypeCode,
                available: repairFormatNum(accTypeDs?.available),
            })
        } else {
            setCash(false)
            // message.warning(`该${typeForm[mode].supplierName}供应商没有对应账户`)

            if (payType === 'settle-type.balance') {
                message.warning(`该${typeForm[mode].supplierName}供应商没有对应的账户`)
            } else {
                Modal.confirm({
                    title: '提示',
                    content: `该${typeForm[mode].supplierName}供应商没有对应的账户是否新增？`,
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                        setAddAccountVisible(true)
                    },
                    onCancel: () => {
                        setSettleVisible(false)
                    },
                })
            }
            formRef.current.setFieldsValue({ payMethodName: undefined, payMethodCode: undefined, payMethodValue: undefined, available: undefined })
        }
    }
    //每次类型选择重置表格数据
    const settleTypeChange = (value, option) => {
        if (option && !IsFranchisees()) {
            setSettleType(option.value)
            //重置支付方式
            // formRef.current.setFieldsValue({ payType: option.value, payMethodCode: undefined });
            queryAccount(typeForm[mode].supplierCode, value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : value === DictCodeEnmu.SETTLE_TYPE_BALANCE ? 1 : 1, value)
            setPayMethodOptions(value)
        }
    }
    const onCancel = () => {
        setSettleVisible(false)
    }

    const [okLoading, setOkLoading] = useState(false)
    //加盟商结算
    const franchiseesSettle = () => {
        formRef.current.validateFields().then(async (val) => {
            let {
                payType,
                payableAmount,
                advancedCode,
                note,
                payMethodCode,
                payMethodValue,
                cashCode,
                cashName,
                fmsNote,
                available,
                cashAttach,
                cashAttachFileName,
            } = val
            let params = {
                id: formVal.id,
                payableAmount,
                payType,
                reckoner: userName,
                note,
                fmsNote
            }
            setOkLoading(true)
            onSettle(params).then((res) => {
                res && onCancel()
            }).finally(() => {
                setTimeout(() => {
                    setOkLoading(false)
                }, 1000);
            })
        })
    }

   

    //表单提交
    const onSubmit = async () => {
        if (IsFranchisees()) {
            //加盟商结算
            return franchiseesSettle()
        }
        // debugger
        formRef.current.validateFields().then(async (val) => {
            let {
                payType,
                payMethodName,
                payableAmount,
                advancedCode,
                note,
                payMethodCode,
                payMethodValue,
                cashCode,
                cashName,
                fmsNote,
                available,
                cashAttach,
                cashAttachFileName,
            } = val
            if (settleType === DictCodeEnmu.SETTLE_TYPE_CASH && accountTypeDs.length === 0) {
                message.warning('您的现金账户没有开户')
                return
            }
            if (payType === DictCodeEnmu.SETTLE_TYPE_CASH && !cash && available < payableAmount) {
                message.warning('您的现结账户可用额度不够')
                let rechargeData = accountTypeDs.find((i) => i.value === formRef.current.getFieldsValue().payMethodCode)
                setAddRechargeData({ ...rechargeData, rechargeAmount: formRef.current.getFieldsValue().payableAmount })
                const { code, partnerCode, shopCode } = rechargeData
                let data1 = {
                    accountCode: code,
                    statusList: 0,
                    partnerCode,
                    shopCode,
                    partnerTypeCode: 'user-type.supplier',
                    page: {
                        pageSize: 10,
                        pageNum: 1,
                    },
                    sourceApp: 'fms',
                }
                let fmsrechargeList = await commonService.fmsRechargeList(data1)
                let waringmsg = '您的现结账户可用额度不够, 是否充值?'
                if (fmsrechargeList && fmsrechargeList.retData && fmsrechargeList.retData.records && fmsrechargeList.retData.records.length > 0) {
                    //有调额申请记录未审核
                    waringmsg = '您的现结账户可用额度不够, 有充值申请未审核，是否再次调额?'
                }
                Modal.confirm({
                    title: '提示',
                    content: waringmsg,
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                        setAddRechargeVisible(true)
                    },
                    onCancel: () => {
                        // setSettleVisible(false)
                    },
                })
                return
            }

            if (payType !== DictCodeEnmu.SETTLE_TYPE_CASH && available < payableAmount) {
                message.warning('您的账户可用额度不够')
                let adjustData = accountTypeDs.find((i) => i.value === formRef.current.getFieldsValue().payMethodCode)
                setAddAdjustData({ ...adjustData, adjustAmount: formRef.current.getFieldsValue().payableAmount })
                const { code, partnerCode, shopCode } = adjustData
                let data1 = {
                    accountCode: code,
                    status: 1,
                    statusList: 1,
                    partnerCode,
                    partnerTypeCode: 'user-type.supplier',
                    shopCode,
                    page: {
                        pageSize: 10,
                        pageNum: 1,
                    },
                    sourceApp: 'fms',
                }
                let fmsAdjustList = await commonService.fmsAdjustList(data1)
                let waringmsg = '您的账户可用额度不够, 是否调额?'
                if (fmsAdjustList && fmsAdjustList.retData && fmsAdjustList.retData.records && fmsAdjustList.retData.records.length > 0) {
                    //有调额申请记录未审核
                    waringmsg = '您的账户可用额度不够, 有调额申请未审核，是否再次调额?'
                }
                Modal.confirm({
                    title: '提示',
                    content: waringmsg,
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                        setAddAdjustVisible(true)
                    },
                    onCancel: () => {
                        // setSettleVisible(false)
                    },
                })
                return
            }
            // let  liat =accountTypeDs.map(item=>item['accountTypeCode'])[0]
            let params = {
                id: formVal.id,
                advancedCode,
                note,
                reckoner: userName,
                payType,
                accountCode: payMethodCode,
                accountName: accountTypeDs.find((item) => item.value === payMethodCode).label,
                // accountType: payMethodValue,
                payableAmount,
                //payMethodName: accountTypeDs.find(item => item.value === payMethodCode).label,
                //payMethodCode,
                payMethodCode: cashCode,
                payMethodName: newCase ?? '',
                fmsNote,
                accountType: accountTypeDs.find((item) => item.value === payMethodCode).accountTypeCode,

                details:
                    accountTypeDs.find((item) => item.value === payMethodCode).accountTypeCode !== 'account-type.cash'
                        ? []
                        : [
                              {
                                  payMethodCode: cashCode,
                                  payMethodName: newCase ?? '',
                                  // accountName: payMethodName,
                                  accountName: accountTypeDs.find((item) => item.value === payMethodCode).label,
                                  accountCode: payMethodCode,
                                  amount: payableAmount,
                                  payee: '',
                                  cashAttach,
                                  cashAttachFileName,
                              },
                          ],
            }
            setOkLoading(true)
            onSettle(params).then((res) => {
                res && onCancel()
            }).finally(() => {
                setTimeout(() => {
                    setOkLoading(false)
                }, 1000);
            })
        })
        // .catch(err => {
        //     message.warning(getFormValidatorErrText(err));
        // })
    }
    const payMethodChange = (value, option) => {
        if (option.accountTypeCode === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true)
        } else {
            setCash(false)
        }
        return (
            option &&
            formRef.current.setFieldsValue({
                payMethodName: option.label,
                payMethodValue: option.accountTypeCode,
                available: formatNum(option.available),
                cashName: undefined,
                cashCode: undefined,
            })
        )
    }
    const cashSelectChange = (value, option) => {
        setNewcase(option.label)
        return option && formRef.current.setFieldsValue({ cashName: option.label, cashCode: option.value })
    }
    //图片变更缓存保留  保留缓存及回显表单
    const onSetFieldsValue = (formValues) => {
        updateFormVal(mode, formValues)
    }
    const formConfig = {
        formRef,
        formValues: typeForm[mode],
        labelCol: { span: 6 },
        disabled: mode === 'look',
        items: [
            {
                type: 'Input',
                fieldLabel: '采购单号',
                fieldName: 'advancedCode',
                span: 24,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '制单日期',
                fieldName: 'createTime',
                span: 24,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '供应商名称',
                fieldName: 'supplierName',
                span: 24,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '供应商编码',
                fieldName: 'supplierCode',
                span: 24,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '采购实体',
                fieldName: 'bentityName',
                span: 24,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '所属门店',
                fieldName: 'shopName',
                span: 24,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '应付金额',
                fieldName: 'payableAmount',
                span: 24,
                disable: true,
            },
            // {
            //     type: "Input",
            //     fieldLabel: "已付金额",
            //     fieldName: "paidAmount",
            //     span: 24,
            //     disable: true,
            // },
            {
                type: 'Select',
                fieldLabel: '结算类型',
                fieldName: 'payType',
                onSelectChange: settleTypeChange,
                options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item) => {
                    return { ...item, disabled: item.value == 'settle-type.credit' ? true : false }
                }),
                rules: [{ required: true, message: '请选择结算类型' }],
                span: 24,
            },
            ...((str) => {
                if (str) {
                    return [
                        {
                            type: 'Select',
                            fieldLabel: '支付方式',
                            fieldName: 'payMethodCode',
                            onSelectChange: payMethodChange,
                            //结算类型为成月结/挂账 可以选额度 其他：现结账户、现金账户
                            // options: settleType ? getSelectedDictOne(DictCodeEnmu.PAY_METHOD).filter(item => {
                            //     if (settleType === DictCodeEnmu.SETTLE_TYPE_BALANCE || settleType === DictCodeEnmu.SETTLE_TYPE_CREDIT ) {
                            //         return item.value === DictCodeEnmu.PAY_METHOD_LIMIT
                            //     } else {
                            //         return item.value !== DictCodeEnmu.PAY_METHOD_LIMIT
                            //     }
                            // }) : [],
                            options: accountTypeDs,
                            rules: [{ required: true, message: '请选择支付方式' }],
                            span: 24,
                            //hidden: mode === "look"
                        },
                    ]
                } else {
                    return []
                }
            })(!IsFranchisees()),

            // {
            //     type: "Input",
            //     fieldLabel: "支付方式",
            //     fieldName: "payMethodName",
            //     span: 24,
            //     hidden: !(mode === "look")
            // },
            // {
            //     type: "Input",
            //     fieldLabel: "现金类支付",
            //     fieldName: "cashName",
            //     hidden: !(mode === "look"),
            //     span: 24,
            // },
            ...((str) => {
                if( IsFranchisees()) {
                    return []
                }
                if (str) {
                    let arr = []
                    if (mode == 'look') {
                        arr = [
                            {
                                type: 'Input',
                                fieldLabel: '现金类支付',
                                fieldName: 'cashname1',
                                span: 24,
                            },
                        ]
                    } else {
                        arr = [
                            {
                                type: 'Select',
                                fieldLabel: '现金类支付',
                                fieldName: 'cashCode',
                                span: 24,
                                options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS),
                                onSelectChange: cashSelectChange,
                                // hidden: mode === "look"
                            },
                        ]
                    }
                    return [
                        ...arr,
                        {
                            type: 'FormUpload',
                            fieldLabel: '附件',
                            fieldName: 'cashAttachFileName',
                            fieldMd5: 'cashAttach',
                            defaultFileName: formVal.cashAttachFileName,
                            defaultFileMd5: formVal.cashAttach,
                            onSetFieldsValue,
                            span: 24,
                            isUseAdvancedrtalApi: true,
                        },
                    ]
                } else {
                    return [
                        {
                            type: 'Input',
                            fieldLabel: '可用额度',
                            fieldName: 'available',
                            span: 24,
                            hidden: mode === 'look',
                            disable: true,
                        }
                    ]
                }
            })(cash),
            {
                type: 'Input',
                fieldLabel: '结算备注',
                fieldName: 'fmsNote',
                span: 24,
            },
            // {
            //     type: "Input",
            //     fieldLabel: "支付方式对应得账户类型",
            //     fieldName: "payMethodValue",
            //     hidden: true
            // },
        ],
    }

    //开户调额
    //开户调额
    const [addAccountVisible, setAddAccountVisible] = useState(false)
    const [addAdjustVisible, setAddAdjustVisible] = useState(false)
    const [addAdjustData, setAddAdjustData] = useState(null)
    const [addRechargeVisible, setAddRechargeVisible] = useState(false)
    const [addRechargeData, setAddRechargeData] = useState(null)

    const onAddAccountSucess = () => {
        const val = formRef.current.getFieldsValue().payType
        let option
        getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).forEach((item) => {
            if (item.value === val) {
                option = item
            }
        })
        settleTypeChange(val, option)
    }

    const addAdjustSucess = () => {
        setSettleVisible(false)
    }

    const addRechargeSucess = () => {
        setSettleVisible(false)
    }

    //手动调额
    const onAddAdjust = () => {
        if (settleType !== DictCodeEnmu.SETTLE_TYPE_CASH) {
            let adjustData = accountTypeDs.find((i) => i.value === formRef.current.getFieldsValue().payMethodCode)
            if (adjustData) {
                setAddAdjustData({ ...adjustData, adjustAmount: formRef.current.getFieldsValue().payableAmount })
                setAddAdjustVisible(true)
            } else {
                message.error('请选择调额账户')
            }
        } else {
            message.error('现结账户不能调额')
        }
    }

    return (
        <YhDrawer
            title="采购付款"
            visible={visible}
            onClose={onCancel}
            okText="保存"
            width={600}
            onOk={debounce(() => {
                onSubmit()
            }, 600)}
            showFooter={mode === 'edit'}
            okLoading={okLoading}
        >
            <div style={{ padding: '20px 36px' }}>
                {!IsFranchisees() && (
                    <div className="">
                        <Button
                            style={{ margin: ' 0 0 20px 60px' }}
                            onClick={() => {
                                setAddAccountVisible(true)
                            }}
                        >
                            开户
                        </Button>
                        <Button style={{ margin: ' 0 0 20px 20px' }} onClick={onAddAdjust}>
                            调额
                        </Button>
                    </div>
                )}

                <YhForm {...formConfig} />
            </div>

            {/* {addAccountVisible && (
                <OpenAccountDrawer
                    visible={addAccountVisible}
                    setVisible={setAddAccountVisible}
                    beCode={typeForm[mode].bentityCode}
                    beName={typeForm[mode].bentityName}
                    payType={typeForm[mode].payType}
                    supplier={{ code: typeForm[mode].supplierCode, name: typeForm[mode].supplierName }}
                    onSucess={onAddAccountSucess}
                />
            )} */}

            {addAccountVisible && (
                <YhFmsAddAccount
                    visible={addAccountVisible}
                    setVisible={setAddAccountVisible}
                    customerList={[{ code: typeForm[mode].supplierCode, name: typeForm[mode].supplierName }]}
                    beCode={typeForm[mode].bentityCode}
                    beName={typeForm[mode].bentityName}
                    formRef={formRef}
                    onSucess={onAddAccountSucess}
                    type={'1'}
                />
            )}

            {addAdjustVisible && (
                <AdjustDrawer
                    visible={addAdjustVisible}
                    setVisible={setAddAdjustVisible}
                    selectedData={addAdjustData}
                    onSucess={addAdjustSucess}
                    supplier={{ code: typeForm[mode].supplierCode, name: typeForm[mode].supplierName }}
                />
            )}

            {addRechargeVisible && (
                <RechargeDrawer
                    visible={addRechargeVisible}
                    setVisible={setAddRechargeVisible}
                    selectedData={addRechargeData}
                    onSucess={addRechargeSucess}
                    supplier={{ code: typeForm[mode].supplierCode, name: typeForm[mode].supplierName }}
                />
            )}
        </YhDrawer>
    )
}
