import { useRef } from 'react'
import { createModel } from 'hox'
import { getToken } from '../../utils/dataStorage'
import { WS_API_SERVER } from '../../config/app'
import { message } from 'antd'
import {useHomePengddingOrderModel} from './homePengddingOrder'
import {useHomeMapModel} from './homeMapModel'

export const useHomeSoketModel = createModel(function () {
    const {handleAddPenddingOrders} = useHomePengddingOrderModel()
    const { handleAddOrderMarker } = useHomeMapModel()

    let wsUrl = `${WS_API_SERVER}/ci-ws?authentication=${getToken()}`
    let tt = null
    const ws = useRef(null)

    //一，创建ws
    const createWebSocket = () => {
        try {
            if (getToken()) {
                wsUrl = `${WS_API_SERVER}/ci-ws?authentication=${getToken()}`
                ws.current = new WebSocket(wsUrl)
                init()
            } else {
                console.log('没有token')
                reconnect(wsUrl)
            }
        } catch (e) {
            console.log('长链接网络异常')
            message('长链接网络异常,请刷新重试')
            reconnect(wsUrl)
        }
    }
    //二，调用init方法，该方法内把一些监听事件封装如下：当网络断开的时候，会先调用onerror，onclose事件可以监听到，会调用reconnect方法进行重连操作。正常的情况下，是先调用onopen方法的，当接收到数据时，会被onmessage事件监听到。
    const init = () => {
        ws.current.onclose = function () {
            console.log('链接关闭')
            reconnect(wsUrl)
        }
        ws.current.onerror = function (err) {
            console.log('发生异常了')
            reconnect(wsUrl)
        }
        ws.current.onopen = function () {
            // handleAddPenddingOrders("d2bdda9f3c1c4b65be5fb80408c763b7")
            // handleAddOrderMarker("d2bdda9f3c1c4b65be5fb80408c763b7")
            if (ws && ws.current && ws.current.send) {
                // 订阅新订单推送
                ws.current.send(
                    JSON.stringify({
                        router: 'topic_router_order_create',
                        data: {
                            action: 'subscription',
                        },
                    })
                )
            }
            //心跳检测重置
            heartCheck.start()
        }
        ws.current.onmessage = ({ data }) => {
            data = JSON.parse(data)
            if (data.topic === 'topic_order_created') {
                let orderId = data.data.orderId
                handleAddPenddingOrders(orderId)
                handleAddOrderMarker(orderId)
            }
            heartCheck.start()
        }
    }

    let lockReconnect = false //避免重复连接
    //三， 重连操作 reconnect：如果网络断开的话，会执行reconnect方法，使用了一个定时器，4秒后会重新创建一个新的websocket链接，重新调用createWebSocket函数，重新会执行及发送数据给服务器端。
    const reconnect = (url) => {
        console.log('重连')
        if (lockReconnect) {
            return
        }
        lockReconnect = true
        //没连接上会一直重连，设置延迟避免请求过多
        tt && clearTimeout(tt)
        tt = setTimeout(function () {
            createWebSocket(url)
            lockReconnect = false
            // window.location.reload()
        }, 4000)
    }

    //四，心跳检测
    let heartCheck = {
        timeout: 10000,
        timeoutObj: null,
        serverTimeoutObj: null,
        start() {
            // console.log('start');
            let self = this
            this.timeoutObj && clearTimeout(this.timeoutObj)
            this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
            this.timeoutObj = setTimeout(() => {
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                var msg = { router: 'heartbeat_router' }
                msg = JSON.stringify(msg)
                // console.log('发送一个心跳', msg);
                ws.current.send(msg)
                self.serverTimeoutObj = setTimeout(() => {
                    ws.current.close()
                }, self.timeout)
            }, this.timeout)
        },
    }

    return {
        createWebSocket
    }
})
