import React, { useContext, useState, useEffect } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { MyContext } from "@/store/myContext"
import { getorderList, updateOrderSettle } from "@/service/orderSettlement"
import moment from "moment"

import { Form, Input, Row, Col, DatePicker, Checkbox, Tooltip, Button, Upload, Modal, message } from "antd"
import OrderTDetails from "./orderDetails"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
// const { TabPane } = Tabs

// 账单管理
const OrderManage = () => {
    const { dispatch, state, Option } = useContext(MyContext)
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [loading, setLoading] = useState(false) //表格 loading
    const [idFileList, setIDFileList] = useState([]) //图片list
    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })
    const [tabsStatus, setTabsStatus] = useState(2)
    const [allSettled, setAllSettled] = useState(false)
    const handleIDChange = (info) => {
        console.log(info)
        setIDFileList([])
        console.log(idFileList)
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage({ visible: false, md5: md5 })
            console.log(idFileList)
        }
        setIDFileList(info.fileList)
    }
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [data, setData] = useState([]) //默认展示数据
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        if (tabsStatus === 2) {
            setIsModalVisible(false)
            return false
        }
        form.validateFields().then((fieldsValue) => {
            const param = {
                ...selectInfo.rowInfo,
                ...fieldsValue,
                settlementTime: moment(fieldsValue.settlementTime).format("YYYY-MM-DD"),
                businessVoucher: idFileList.length > 0 ? idFileList[0].response.retData : null,
                unsettledAmount: Number(selectInfo.rowInfo.totalAmount) - Number(fieldsValue.settledAmount) - Number(fieldsValue.discountAmount),
                totalAmount: fieldsValue.settledAmount,
                discountAmount: fieldsValue.discountAmount || 0,
            }

            updateOrderSettle(param)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        loadData(pagination.current, searchValue)
                        setIsModalVisible(false)
                        form.resetFields()
                        setIDFileList([])

                        message.success("结算成功")
                    // } else {
                    //     message.error(res.retMsg)
                    // }
                })
                // .catch((err) => {
                //     message.error(err.retMsg)
                // })
            // businessVoucher pingz
        })
        // updateOrderList
        // setIsModalVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const [form] = Form.useForm()
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    useEffect(() => {
        form.setFieldsValue({
            settledAmount: null,
        })
        if (allSettled) {
            form.setFieldsValue({
                discountAmount: null,
                settledAmount: selectInfo.rowInfo.totalAmount,
            })
        }
    }, [allSettled])
    useEffect(() => {
        if (state.activeMenu === "orderManageSettleds") {
            loadData(pagination.current, searchValue)
        }
        return () => setData([])
    }, [state.activeMenu, tabsStatus, pagination.current])

    //默认加载数据列表
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: {
                ...search,
                settlementStatus: tabsStatus,
            },
            pageNum: current,
            pageSize: 10,
            orders: [{ column: "updateTime", rule: "DESC" }],
        }
        getorderList(data).then((res) => {
            // console.log(res);
            // if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setSelectInfo({
                    rowInfo: {},
                    rowIndex: -1,
                })
                setData(res.retData.list)
                setLoading(false)
            // } else {
            //     // message.error(res.retMsg)
            //     message.error("请求异常")
            // }
        })
    }

    //分页事件 置空当前工单
    const onTableChange = async (page) => {
        setpagination({
            ...pagination,
            current: page.current,
        })
        // loadData(page.current, searchValue)
    }

    //table配置
    const columns = [
        {
            title: "账单编号",
            align: "center",
            dataIndex: "settlementNo",
        },
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            render: (text) => (
                <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
                    <p className="ellipsis">{text}</p>
                </Tooltip>
            ),
        },
        {
            title: "单据数量",
            dataIndex: "orderNum",
        },
        {
            title: "账单金额",
            align: "center",
            dataIndex: "totalAmount",
        },
        {
            title: "已结算",
            align: "center",
            dataIndex: "settledAmount",
        },
        {
            title: "未结算",
            align: "center",
            dataIndex: "unsettledAmount",
        },
        {
            title: "业务经理",
            align: "center",
            dataIndex: "manager",
        },
        {
            title: "账单类型",
            align: "center",
            dataIndex: "settlementType",
            render: (text) => {
                return <>{{ 1: "月结账单", 2: "临时账单" }[text]}</>
            },
        },
        // {
        //     title: "工单状态",
        //     align: "center",
        //     dataIndex: "orderStatus",
        //     render: (text) => <>{dictObj.orderStatus[text]}</>,
        // },

        {
            title: "开单员",
            align: "center",
            dataIndex: "drawer",
            render: (text) => (
                <Tooltip placement="topLeft" title={text} arrowPointAtCenter>
                    <p className="ellipsis">{text}</p>
                </Tooltip>
            ),
        },

        {
            title: "出账日期",
            align: "center",
            dataIndex: "createTime",
        },

        {
            title: "发票",
            align: "center",
            dataIndex: "settlementInvoice",
            render: (text) => {
                return <>{{ 1: "申请开票", 2: "已开票" }[text]}</>
            },
        },
        // {
        //     title: "操作",
        //     align: "center",
        //     dataIndex: "settlementInvoice",
        //     render: (text) => {
        //         return <>
        //             {{ 1: '申请开票', 2: '已结算' }[text]}
        //         </>
        //     }
        // },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "customerName",
            },
            {
                type: "select",
                placeholder: "请选择",
                valueName: "settlementInvoice",
                showSearch: true,
                optionFilterProp: "children",
                optionList: () =>
                    [
                        { text: "已开票", value: 2 },
                        { text: "未开票", value: 1 },
                    ].map((item) => (
                        <Option value={item.value} key={item.value}>
                            {item.text}
                        </Option>
                    )),
            },
        ],
    }
    const handleDetails = async (mode) => {
        let tabName = {
            settle: "结算",
            look: "账单明细",
        }
        let modeKey = "orderManageSettleds/" + mode
        if (mode === "settle") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择")
                return
            }
            setIsModalVisible(true)
            if (tabsStatus === 2) {
                const businessVoucher = selectInfo.rowInfo.businessVoucher
                    ? [
                          {
                              uid: "-1",
                              status: "done",
                              response: {
                                  retData: selectInfo.rowInfo.businessVoucher,
                              },
                              url: `${fileLookUrl().image}${selectInfo.rowInfo.businessVoucher}`,
                          },
                      ]
                    : []
                setIDFileList(businessVoucher)
                form.setFieldsValue({
                    operator: selectInfo.rowInfo.operator,
                    settledAmount: selectInfo.rowInfo.totalAmount,
                    settlementTime: moment(selectInfo.rowInfo.settlementTime),
                    discountAmount: selectInfo.rowInfo.discountAmount,
                    businessVoucher,
                })
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择")
                return
            }
            dispatch({
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: <OrderTDetails selectInfo={selectInfo}></OrderTDetails>,
            })
        }
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("settle"),
            content: tabsStatus === 1 ? "结算" : "查看",
        },
        tabsStatus === 1 && {
            click: () => handleDetails("look"),
            content: "账单明细",
        },
    ]

    return (
        <>
            {/* <Tabs
                defaultActiveKey={1}
                onChange={(e) => {
                    console.log(e)
                    setTabsStatus(Number(e))
                }}
                size="small"
                style={{ marginTop: "43px" }}
                tabBarStyle={{ marginLeft: "10px", marginTop: "43px" }}
            >
                <TabPane tab="未结算" key={1}></TabPane>
                <TabPane tab="已结算" key={2}></TabPane>
            </Tabs> */}
            <TopSearch
                title="已结算"
                justify="start"
                searchListData={searchList}
                searchValue={searchValue}
                setSearchValue={(obj) => setSearchValue(obj)}
                handleSearch={loadData}
            />
            <ListComponent
                buttons={topButtons}
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={pagination}
                onTableChange={onTableChange}
                selectInfo={selectInfo}
                scroll={{ x: 3300 }}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />
            <Modal getContainer={false} title="结算订单" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form} {...formItemLayout}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="账单编号" style={{ marginBottom: 0 }}>
                                {selectInfo.rowInfo.settlementNo}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="客户名称" style={{ marginBottom: 0 }}>
                                {selectInfo.rowInfo.customerName}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="账单金额" style={{ marginBottom: 0 }}>
                                {selectInfo.rowInfo.totalAmount}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="已结算" style={{ marginBottom: 0 }}>
                                {selectInfo.rowInfo.settledAmount}
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="未结算">{selectInfo.rowInfo.unsettledAmount}</Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="优惠金额" name="discountAmount">
                                <Input placeholder="请输入优惠金额" disabled={tabsStatus === 2} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="结算金额" name="settledAmount" rules={[{ required: true, message: "请输入结算金额" }]}>
                                <Input
                                    disabled={tabsStatus === 2}
                                    placeholder="请输入结算金额"
                                    suffix={
                                        <Checkbox
                                            disabled={tabsStatus === 2}
                                            checked={allSettled}
                                            onChange={(e) => {
                                                setAllSettled(!allSettled)
                                            }}
                                        >
                                            全部
                                        </Checkbox>
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="结算人" name="operator" rules={[{ required: true, message: "请输入结算人" }]}>
                                <Input placeholder="请输入结算人" disabled={tabsStatus === 2} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="结算日期" name="settlementTime" rules={[{ required: true, message: "请选择结算日期" }]}>
                                <DatePicker disabled={tabsStatus === 2} placeholder="请选择结算日期" style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="上传结算凭证" name="businessVoucher" rules={[{ required: true, message: "请上传结算凭证" }]}>
                                <Upload
                                    disabled={tabsStatus === 2}
                                    action={`${fileUploadUrl}upload/image_old`}
                                    headers={{ authorization: getToken() }}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={idFileList}
                                    onPreview={(file) => {
                                        const md5 = file.response.retData
                                        setpreviewImage({ visible: true, md5 })
                                    }}
                                    onChange={handleIDChange}
                                >
                                    {idFileList.length >= 1 ? null : <Button size="small">上传结算凭证</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal visible={previewImage.visible} title="头像查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage.md5}`} />
            </Modal>
        </>
    )
}
export default OrderManage
