import React, { useEffect, useState } from "react"
import { Row, Col, DatePicker, Button, Divider, message, Radio, Table, Modal } from "antd"
import moment from "moment"
import styled from "@emotion/styled"
import Loading from "@/components/Loading"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import { quotaOrderSummary, createQuotaSettle } from "@/service/teamService"

export default (props) => {
    const { rowInfo, visible, setVisible } = props
    const [loading, setLoading] = useState(false) //列表loading
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const dateFormat = "YYYY/MM/DD HH:mm:ss"
    const [startTime, setStartTime] = useState(moment("2022-01-01 00:00:00").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"))
    const [endTime, setEndTime] = useState(moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"))
    const [pagination, setpagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    })

    const [orderType, setOrderType] = useState("")
    const [settledStatus, setSettledStatus] = useState(1)
    const [incomeData, setIncomeData] = useState({
        detailList: { records: [] },
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    useEffect(() => {
        if (visible) {
            getStatic()
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getStatic()
    }, [orderType, settledStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    const getStatic = (pageNum = 1) => {
        setLoading(true)
        quotaOrderSummary({
            startTime,
            endTime,
            pageNum: pageNum,
            pageSize: settledStatus === 1 ? 100000 : 10,
            searchItem: {
                orderType,
                settledStatus,
                teamId: rowInfo.id,
            },
        })
            .then((res) => {
                let { retData } = res
                if (!retData.detailList) {
                    retData.detailList = { records: [] }
                }
                setIncomeData(res.retData)
                setpagination({
                    current: pageNum,
                    total: res.retData.detailList.total,
                    pageSize: settledStatus === 1 ? 100000 : 10,
                })

                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    //分页事件 置空当前行信息  翻页时置空成员列表
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        getStatic(page.current)
    }

    //生成结算单
    const handleCreatQuotaSettlement = () => {
        console.log(selectedRowKeys)
        if (selectedRowKeys.length === 0) {
            return message.error("请选择要结算的订单！")
        }
        let threeContractHours = 0,
            chargedHours = 0,
            allPrice = 0
        selectedRows.forEach((item) => {
            if (item.orderType === "三包") {
                threeContractHours += item.teamIncome * 100
            } else {
                chargedHours += item.teamIncome * 100
            }
            allPrice += item.teamIncome * 100
        })
        Modal.confirm({
            title: "确认账单",
            content: (
                <>
                    <p>
                        生成团队：<span>{rowInfo.teamName}</span>
                    </p>
                    <p>
                        工单数量：<span>{selectedRows.length}单</span>
                    </p>
                    <p>
                        三包工时：<span>{(threeContractHours / 100).toFixed(2)}</span>
                    </p>
                    <p>
                        收费工时：<span>{(chargedHours / 100).toFixed(2)}</span>
                    </p>
                    <p>
                        账单金额：<span>{(allPrice / 100).toFixed(2)}</span>
                    </p>
                </>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                createQuotaSettle({ teamId: rowInfo.id, orderCodes: selectedRowKeys }).then((res) => {
                    message.success("结算单生成成功！")
                    getStatic()
                })
            },
            onCancel: () => {},
        })
    }

    const columns = [
        {
            title: "工单编号",
            dataIndex: "orderCode",
            key: "orderCode",
            align: "center",
        },
        {
            title: "公司名称",
            dataIndex: "customerName",
            key: "customerName",
            align: "center",
        },
        {
            title: "工单类型",
            dataIndex: "orderType",
            key: "orderType",
            align: "center",
        },
        {
            title: "总工时费",
            dataIndex: "totalWorkHoursAmount",
            key: "totalWorkHoursAmount",
            align: "center",
        },
        {
            title: "团队收益",
            dataIndex: "teamIncome",
            key: "teamIncome",
            align: "center",
        },
        {
            title: "竣工日期",
            dataIndex: "finishTime",
            key: "finishTime",
            align: "center",
        },
    ]

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys)
            setSelectedRows(newSelectedRows)
        },
    }

    return (
        <>
            <Modal
                forceRender
                visible={visible}
                title={rowInfo.teamName}
                centered={true}
                width={"80vw"}
                onCancel={() => {
                    setVisible(false)
                }}
                // onOk={() => {
                //     setVisible(false)
                // }}
                footer={[]}
            >
                <div className="form-details-warp">
                    <>
                        <div className="list-container">
                            <div className="list-header">
                                <Row align="middle " justify="space-between">
                                    <Col>
                                        <Row align="middle">
                                            <Col style={{ textAlign: "right" }}>统计时间：</Col>
                                            <Col>
                                                <DatePicker.RangePicker
                                                    showTime
                                                    defaultValue={[moment(startTime), moment(endTime)]}
                                                    format={dateFormat}
                                                    onChange={(date, str) => {
                                                        setStartTime(moment(new Date(str[0])).format("YYYY-MM-DDTHH:mm:ss"))
                                                        setEndTime(moment(new Date(str[1])).format("YYYY-MM-DDTHH:mm:ss"))
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Button
                                            shape="round"
                                            size="small"
                                            className="team-but-left"
                                            onClick={() => {
                                                getStatic()
                                            }}
                                        >
                                            查询
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <Divider style={{ margin: "16px 0" }} />
                            <Contentdiv>
                                <ContentItem onClick={() => setSettledStatus("")}>
                                    <span>总金额</span>
                                    <p>{incomeData.totalAmount || 0}</p>
                                </ContentItem>
                                <ContentItem onClick={() => setSettledStatus(2)}>
                                    <span>已生成金额</span>
                                    <p>{incomeData.settledAmount || 0}</p>
                                </ContentItem>
                                <ContentItem onClick={() => setSettledStatus(1)}>
                                    <span>未生成金额</span>
                                    <p>{incomeData.unsettledAmount || 0}</p>
                                </ContentItem>
                                <ContentItem onClick={() => setSettledStatus("")}>
                                    <span>总单据</span>
                                    <p>{incomeData.totalOrderNum || 0}</p>
                                </ContentItem>
                                <ContentItem onClick={() => setSettledStatus(2)}>
                                    <span>已生成单据</span>
                                    <p>{incomeData.settledOrderNum || 0}</p>
                                </ContentItem>
                                <ContentItem onClick={() => setSettledStatus(1)}>
                                    <span>未生成单据</span>
                                    <p>{incomeData.unsettledOrderNum || 0}</p>
                                </ContentItem>
                            </Contentdiv>
                            <Divider style={{ margin: "16px 0" }} />
                        </div>
                        <div className="list-container">
                            <div>
                                <div
                                    className="list-header"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Radio.Group defaultValue="d">
                                        <Radio.Button value="d" onClick={() => setOrderType("")}>
                                            全部
                                        </Radio.Button>
                                        <Radio.Button value="014002" onClick={() => setOrderType("014002")}>
                                            三包
                                        </Radio.Button>
                                        <Radio.Button value="014001" onClick={() => setOrderType("014001")}>
                                            收费
                                        </Radio.Button>
                                    </Radio.Group>
                                    <div>
                                        {settledStatus === 1 && (
                                            <Button shape="round" size="small" className="team-but-left" onClick={handleCreatQuotaSettlement}>
                                                生成结算单
                                            </Button>
                                        )}

                                        <Button
                                            shape="round"
                                            size="small"
                                            className="team-but-left"
                                            onClick={async () => {
                                                if (incomeData.detailList.records.length === 0) {
                                                    message.warning("无可导出数据")
                                                    return
                                                }
                                                setExportLoading(true)
                                                let data = {
                                                    type: "post",
                                                    data: {
                                                        startTime,
                                                        endTime,
                                                        searchItem: {
                                                            orderType,
                                                            settledStatus,
                                                            teamId: rowInfo.id,
                                                        },
                                                    },
                                                }
                                                let resFlie = await download("/ci/order_team_settlement/quotaOrderSummaryExport", data)
                                                if (resFlie && resFlie.status === 200) {
                                                    message.success("已导出")
                                                    convertRes2Blob(resFlie)
                                                } else {
                                                    message.warning("导出异常，请重新尝试")
                                                }
                                                setExportLoading(false)
                                            }}
                                        >
                                            导出
                                        </Button>
                                    </div>
                                </div>
                                <Table
                                    loading={loading}
                                    dataSource={incomeData.detailList.records.map((item) => {
                                        return {
                                            key: item.orderCode,
                                            ...item,
                                        }
                                    })}
                                    onChange={onTableChange}
                                    pagination={{
                                        showQuickJumper: true,
                                        showSizeChanger: false,
                                        showTotal: () => `共${pagination.total}条`,
                                        position: ["bottomCenter"],
                                        ...pagination,
                                    }}
                                    columns={columns}
                                    size="small"
                                    rowSelection={settledStatus === 1 ? rowSelection : null}
                                />
                            </div>
                        </div>
                        {exportLoading && <Loading />}
                    </>
                </div>
            </Modal>
        </>
    )
}

const Contentdiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const ContentItem = styled.div`
    width: 12%;
    border: 1px solid rgba(98, 98, 98, 0.2);
    text-align: center;
    align-self: center;
    padding: 20px 0;
    margin: 10px 2%;
    cursor: pointer;

    span {
        color: rgba(0, 0, 0, 0.45);
        display: inline-block;
        font-size: 14px;
        margin-bottom: 4px;
    }

    p {
        color: rgba(0, 0, 0, 0.65);
        font-size: 24px;
        margin: 0;
    }
`
