import React, { useEffect } from "react";
import { Table, Tooltip } from "antd";
import { usePurchasePlanModel } from "../purchasePlanModel";
import { YhTag } from "@/erp_subpackage/components/YhTag";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { momentFormat, formatNum } from "@/erp_subpackage/utils/util";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
export function DataTable({ goPageNav }) {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys,
    } = usePurchasePlanModel();

    const { getTreeNodeName } = useDictModel(() => []);

    useEffect(() => {
        loadData()
    }, []);

    const columns = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => `${i + 1}`,
            fixed: "left",
        }, {
            title: '采购计划号',
            width: 120,
            dataIndex: 'planCode',
            fixed: "left",
            render: (text) => <YhTooltip text={text} onClick={() => goPageNav("look", text)} />,
            // render: (text, record) => <Tooltip placement="topLeft" title={text}><p className="one-line click-span" onClick={() => goPageNav("look", text)}>{text}</p></Tooltip>,
        }, {
            title: '单据状态',
            width: 80,
            dataIndex: 'status',
            fixed: "left",
            render: (text) => <YhTag status={text} text={getTreeNodeName(text)} />,
        }, {
            title: '所属机构',
            width: 120,
            dataIndex: 'shopName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '采购类型',
            width: 80,
            dataIndex: 'purchaseTypeName',
            render: (text) => <YhTooltip text={text}  />,
            //render: (text) => <Tooltip placement="topLeft" title={text}><p className="one-line">{text}</p></Tooltip>,
        }, {
            title: '商品类型',
            width: 80,
            dataIndex: 'materialTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '采购数量',
            width: 80,
            dataIndex: 'totalNum',
        }, {
            title: '已到数量',
            width: 80,
            dataIndex: 'arrivedNum',
        }, {
            title: '未到数量',
            width: 80,
            dataIndex: 'unarrivedNum',
        }, {
            title: '采购总金额',
            width: 90,
            dataIndex: 'totalAmount',
            render: (text) => `￥${formatNum(text)}`
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '制单人',
            width: 140,
            dataIndex: 'createUser',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '发运方式',
            width: 120,
            dataIndex: 'deliveryMethodName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '是否直发',
            width: 80,
            dataIndex: 'isDirect',
            render: (text) => {
                if (text === null) {
                    return ""
                } else {
                    return text ? "是" : "否"
                }
            }
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }, {
            title: '打印次数',
            width: 70,
            fixed: "right",
            align: "center",
            dataIndex: 'printingTimes',
        }
    ];


    //点击行
    const onRow = (record) => {
        let findObj = selectedRowKeys.find(item => item.id === record.id)
        if (findObj) {
            let newSelectedRowKeys = selectedRowKeys.filter(item => item.id !== record.id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            setSelectedRowKeys(origin => [...origin, record]);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                //全部选中时 (存row[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRows])
                        : setSelectedRowKeys((origin) => {
                            let changeRowId = changeRows.map(item => item.id);
                            return origin.filter(item => changeRowId.indexOf(item.id) < 0)
                        });
                },
                onSelect: (record, selected, selectedRows) => onRow(record),
                selectedRowKeys: selectedRowKeys.map(item => item.id)
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}



