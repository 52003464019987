import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { teamUserIncomeAnalysis, getTeamList } from "@/service/teamService"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import Detail from "./detail"
import Loading from "@/components/Loading"
import { organPage } from "@/service/orgService"
import { Select, Tooltip, Modal } from "antd"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import TeamStatic from "../teamStatic"
import moment from "moment"
import YhTooltip from "@/components/YhTooltip"
import { settlementMethodEmun } from "@/utils/enum"

const { Option } = Select
//访问列表
export default () => {
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { dispatch, state, message, showAllOrgan } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    //搜索机构列表
    const [searchValue, setSearchValue] = useState({
        startTime: moment().startOf('month').format("YYYY-MM-DD"),
        endTime: moment().startOf('day').format("YYYY-MM-DD"),
    })
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [org, setOrg] = useState([])
    const [loading, setLoading] = useState(false) //表格 loading
    const [order, setOrder] = useState({})

    // 团队收益
    const [teamData, setTeamData] = useState({
        visibel: false,
        teamId: "",
        teamName: "",
    })

    useEffect(() => {
        if (state.activeMenu === "memberIncome") {
            loadData(pagination.current, searchValue)
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}, order = { column: "updateTime", rule: "DESC" }) => {
        setLoading(true)
        let data = {
            searchItem: { ...search,
                startTime:search.startTime ? search.startTime+'T00:00:00': null,
                endTime:search.endTime ? search.endTime+'T23:59:59': null,
             },
            pageNum: current,
            pageSize: 10,
            orders: [order],
            startTime: search.startTime ? search.startTime+'T00:00:00': null,
            endTime: search.endTime ? search.endTime+'T23:59:59': null,
        }
        teamUserIncomeAnalysis(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            // setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setSelectInfo({ rowInfo: res.retData.list[0], rowIndex: -1 })
            setList(res.retData.list)
            setTimeout(() => {
                setLoading(false)
            }, 200)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let order = { column: "updateTime", rule: "DESC" }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === "ascend" ? "ASC" : "DESC"
            order = {
                column: sorter.field,
                rule,
            }
        }
        setOrder(order)
        loadData(page.current, searchValue, order)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => exportData(),
            content: "导出",
        },
    ]

    const exportData = async () => {
        setExportLoading(true)
        let sendOrder = Object.keys(order).length > 0 ? order : { column: "updateTime", rule: "DESC" }
        let data = {
            type: "post",
            data: {
                pageSize: pagination.pageSize,
                pageNum: pagination.current,
                startTime: searchValue.startTime ? searchValue.startTime+'T00:00:00' : null,
                endTime: searchValue.endTime ? searchValue.endTime+'T23:59:59' : null,
                searchItem: {
                    ...searchValue,
                    startTime:searchValue.startTime ? searchValue.startTime+'T00:00:00' : null,
                    endTime:searchValue.endTime ? searchValue.endTime+'T23:59:59' : null,
                },
                orders: [sendOrder],
            },
        }
        let resFlie = await download("/ci/team_user/incomeAnalysis/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    //新增
    const handleDetails = async (mode) => {
        if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的成员")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //table配置
    const columns = [
        {
            title: "姓名",
            align: "center",
            dataIndex: "userName",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "职务",
            align: "center",
            dataIndex: "userJobTitle",
            // render: (text) => <span>{getPcodeDict(text)?.title}</span>,
            render: (text) => <YhTooltip text={getPcodeDict(text)?.title} >{getPcodeDict(text)?.title}</YhTooltip>,

        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "所属团队",
            align: "center",
            dataIndex: "teamInfo",
            render: (text, record) =>
                text &&
                text.split(",").map((item) => {
                    let arr = item.split("+")
                    return (
                        <div
                            key={arr[0]}
                            style={{ color: "#2e3aeb", cursor: "pointer" }}
                            onClick={() => {
                                handleToTeamIncome(arr)
                            }}
                        >
                            {arr[1]}({settlementMethodEmun[arr[2]]})
                        </div>
                    )
                }),
        },
        {
            title: "工单量",
            align: "center",
            dataIndex: "totalOrderNum",
            sorter: true,
        },
        {
            title: "结算方式",
            align: "center",
            dataIndex: "userDistributionPoints",
            render: (text, record) => (
                <span>
                    {record.userDistributionPoints ? `分成比例:${record.userDistributionPoints}` : ""}{" "}
                    {record.allocationPoint ? `个人点位:${record.allocationPoint}` : ""}
                </span>
            ),
        },
        {
            title: "收益",
            align: "center",
            dataIndex: "totalIncome",
            sorter: true,
            render: (text, record) => (
                <Tooltip
                    placement="topLeft"
                    title={`总工时费：${record.totalHourlyWage ? record.totalHourlyWage : 0}、总税费：${
                        record.totalTaxFee ? record.totalTaxFee : 0
                    }、总平台管理费：${record.totalPlatServiceFee ? record.totalPlatServiceFee : 0}、维修配件收益：${
                        record.repairPartIncome ? record.repairPartIncome : 0
                    }`}
                    arrowPointAtCenter
                >
                    {text}
                </Tooltip>
            ),
        },
    ]

    const handleToTeamIncome = async (arr) => {
        // await dispatch({
        //     type: "removeTabs",
        //     targetKey: "teamIncome",
        // })
        // dispatch({
        //     type: "changeTabs",
        //     name: "团队收益",
        //     activeMenu: "teamIncome",
        //     content: <TeamStatic teamName={arr[1]} teamId={arr[0]}></TeamStatic>,
        // })
        setTeamData({
            visibel: true,
            teamName: arr[1],
            teamId: arr[0],
        })
    }

    //搜索JSON
    const searchList = {
        normalList: [
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: "searchSelect",
                placeholder: "请选择团队",
                valueName: "teamId",
                optionName: "teamName",
                optionId: "id",
                searchItemName: "teamName",
                searchApi: getTeamList,
            },
            {
                type: "input",
                placeholder: "用户名",
                valueName: "userName",
            },
            {
                type: "select",
                placeholder: "请选择结算方式",
                valueName: "teamSettlementMethod",
                disabledClear: true,
                optionList: () =>
                    Object.entries(settlementMethodEmun).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
            {
                type: "DatePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
           
        ],
    }

    return (
        <>
            {state.activeMenu === "memberIncome" && (
                <>
                    <TopSearch
                        title="成员收益"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="成员收益"
                        scroll={{ x: "max-content" }}
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        loading={loading}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                        rowKey={(record) => record.userId}
                    />

                    {showVisible && <Detail org={org} rowInfo={selectInfo.rowInfo} visible={showVisible} mode={showMode} setVisible={setShowVisible}  defaultStartTime={searchValue.startTime} defaultEndTime={searchValue.endTime} />}
                    {exportLoading && <Loading />}
                    {teamData.visibel && (
                        <Modal
                            visible={teamData.visibel}
                            title="团队收益"
                            centered={true}
                            width={"100vw"}
                            onCancel={() => {
                                setTeamData({ visibel: false, teamId: "", teamName: "" })
                            }}
                            onOk={() => {
                                setTeamData({ visibel: false, teamId: "", teamName: "" })
                            }}
                        >
                            <TeamStatic teamId={teamData.teamId} teamName={teamData.teamName} defaultStartTime={searchValue.startTime} defaultEndTime={searchValue.endTime} />
                        </Modal>
                    )}
                </>
            )}
        </>
    )
}
