import {
    httpRequest
} from "@/erp_subpackage/utils/HttpRequest";
class ProductService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/ciShopInventory/listPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    // 明细查询
    async details(params) {
        const apiUrl = '/ci-storage/base/material/findById'
        return await httpRequest.post(apiUrl, params);
    }

    // 上架  下架
    async upDown(params) {
        const apiUrl = "/ci-storage/ciShopInventory/upDown";
        return await httpRequest.post(apiUrl, params);
    }

    // 批量上架
    async batchUp(params) {
        const apiUrl = "/ci-storage/shop/batchUp";
        return await httpRequest.post(apiUrl, params);
    }

    // 查询所属机构
    async getOrg() {
        const apiUrl = "/ci-storage/ciShopInventory/getOrg";
        return await httpRequest.get(apiUrl);
    }

    // 商品明细检索
    async getDetails(params) {
        const apiUrl = "/ci-storage/ciShopInventory/getDetails";
        return await httpRequest.post(apiUrl, params);
    }

    // 图片上传服务
    async uploadImg(params) {
        const apiUrl = '/ci-storage/base/material/insertImg'
        return await httpRequest.post(apiUrl, params)
    }

}

export const productService = new ProductService()