import { useState, useEffect, useRef} from "react";
import { message } from 'antd'
import { createModel } from "hox";
import {contractService} from './contractParticularsServer'
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import {usecontractDetailsModel} from './view/detailsModel'
import { getPcodeDict } from "@/utils/dataStorage"

export const useContractModel = createModel(function(){
    const {user: { shopId,organId}}=useGlobalModel()
    const {editFormVal}=usecontractDetailsModel()
     //列表数据源
     const [dataSource, setDataSource] = useState([]);
     const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
      //查询条件
     const [searchParam, setSearchParam] = useState({});
     //分页参数
     const initPgCfg = { pageSize: 10, current: 1 }
     const [pgCfg, setPgCfg] = useState(initPgCfg);
      //表格多选row
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedData, setSelectedData] = useState();
    //选择车辆
    const [vehicleVisable,setVehicleVisable]= useState(false);
    //里程维护
    const [mileageVisable,setMileageVisable]= useState(false);
    //操作日志
    const [logVisable,setLogVisable]= useState(false);
    const [dictObj, setDictObj] = useState(null)
    const formRef = useRef()

    const initDict = () => {
        let obj = {
            bigBagMode: {},
        }
        getPcodeDict("047").children.forEach((item) => {
            obj.bigBagMode[item.dictCode] = item.title
        })
        setDictObj(obj)
    }
    
    //对账单信息
    const [open,setOpen]=useState(false);
    useEffect(() => {
        let filters = dataSource.filter(el => selectedRowKeys.length > 0 ? el.code === selectedRowKeys[0] : false);
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined;
        setSelectedData(selectedData)
    }, [selectedRowKeys]);

    const loadData = async(page,searchValue=searchParam)=>{
    setLoading({ spinning: true, tip: "加载中..." })
          try {      
            const searchParams = {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
                startTime:'',
                endTime:'',
                orders:[
                    {
                    organId,
                    ...searchValue
                }
                ],
                searchItem:{ ...searchValue}
            }
          let {retData} = await contractService.page(searchParams)
          const {list,...pg} = retData
          setPgCfg( {
            total: pg.total,
            pageSize: pg.pageSize,
            current: pg.pageNum 
        });
          setDataSource(list)
        
          setLoading({ spinning: false })
          } catch (error) {
            console.log(console.error())
          }
        
    }

    const onExport=async (searchValue = searchParam)=>{
        setLoading({ spinning: true, tip: "列表加载中..." });
        const data = {
                organId,
                signCode:searchValue.signCode ??'',
                customerId:searchValue.customerId??'',
                vehicleCode:searchValue.vehicleCode??''
        }
       contractService.export(data).then((res)=>{  
        message.success('导出进行中。请稍后去导出记录列表查看')
    })
      
        setLoading({ spinning: false });
    }

    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

     //重置搜索参数
     const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    return {
        dataSource,
        loading,
        searchParam,
        setSearchParam,
        pgCfg,
        selectedRowKeys,
        setSelectedRowKeys,
        loadData,
        updateSearchParam,
        resetSearchParam,
        vehicleVisable,
        setVehicleVisable,
        onExport,
        mileageVisable,setMileageVisable,
        setLogVisable,logVisable,
        open,setOpen,formRef,
        dictObj,initDict
    }
})