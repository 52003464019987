import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
class StockOutputService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/stockIo/out/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/stockIo/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }
    //导出
    async export(params) {
        const apiUrl = "/ci-analyze/stock/out/export";
        return await httpRequest.post(apiUrl, params);
    }
    // 统计出库数、出库总计
    async total(searchVo){
        const apiUrl = "/ci-storage/stockIo/out/total/amount";
        return await httpRequest.post(apiUrl, searchVo);
    }
}

export const stockOutputService = new StockOutputService();