import React, { useEffect, useState, useContext } from "react";
import { Table } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader";
import { usecontractDetailsModel } from './detailsModel'
import { formatNum } from '@/erp_subpackage/utils/util';
import { getPcodeDict } from "@/utils/dataStorage"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"


export const WorkOrderInfoTable = ({ }) => {
    const { loading, workOrderData, pgCfgwork, editFormVal, lookFormVal, serachworke, setSerachworke, descTotalwork, openWorkerOrder
    } = usecontractDetailsModel()
    const { getSelectedDictOne } = useDictModel()

    const updateSearchParamt = (updateParam) => {
        setSerachworke({ ...serachworke, ...updateParam })
    }

    const resetSearchParamt = () => {
        setSerachworke({});
        openWorkerOrder({ customerId: editFormVal.customerId ?? lookFormVal.customerId ?? "", });
    }
    const column2 = [
        {
            title: '序号',
            width: 40,
            render: (t, r, i) => `${i + 1}`,
            fixed: 'left'
        },
        {
            title: '单据编号',
            width: 180,
            dataIndex: 'orderCode',
            fixed: "left",
        },
        {
            title: '支付状态',
            width: 120,
            dataIndex: 'payStatus',
            fixed: "left",
        }, {
            title: '工单类型',
            width: 120,
            dataIndex: 'orderType',
            fixed: "left",
        }, {
            title: '三包信息',
            width: 280,
            dataIndex: 'threeGuaranteesInfo',

        }, {
            title: '工时费',
            width: 100,
            dataIndex: 'hourlyWage',
            render: (text) => { return (text ?? 0).toFixed(2) }

        }, {
            title: '材料费',
            width: 100,
            dataIndex: 'materialAmount',
            render: (text) => { return (text ?? 0).toFixed(2) }

        },
        {
            title: '外出费',
            width: 100,
            dataIndex: 'travelExpenses',
            render: (text) => { return (text ?? 0).toFixed(2) }

        },
        {
            title: '餐补费',
            width: 100,
            dataIndex: 'mealSupplement',
            render: (text) => { return (text ?? 0).toFixed(2) }

        },
        {
            title: '其他费用',
            width: 100,
            dataIndex: 'otherItemActualAmount',
            render: (text) => { return (text ?? 0).toFixed(2) }

        },
        {
            title: '三包工时费汇总',
            width: 120,
            dataIndex: 'threeGuaranteesTotalHourlyWage',
            render: (text) => { return (text ?? 0).toFixed(2) }

        }, {
            title: '费用金额',
            width: 100,
            dataIndex: 'totalAmount',
            render: (text) => { return (text ?? 0).toFixed(2) }

        }, {
            title: '预付款',
            width: 100,
            dataIndex: 'prepayments',
            render: (text) => { return (text ?? 0).toFixed(2) }

        }, {
            title: '已收金额',
            width: 100,
            dataIndex: 'paidAmount',
            render: (text) => { return (text ?? 0).toFixed(2) }
        }, {
            title: '应收金额',
            width: 100,
            dataIndex: 'payableAmount',
            render: (text) => { return (text ?? 0).toFixed(2) }

        }, {
            title: '车辆牌照',
            width: 100,
            dataIndex: 'vehicleCode',

        }, {
            title: 'VIN码',
            width: 180,
            dataIndex: 'vehicleVin',

        }, {
            title: '结算方',
            width: 180,
            dataIndex: 'settlementParty',

        },
        // {
        //     title: '出账日期',
        //     width: 180,
        //     dataIndex: 'billDate',

        // },
        {
            title: '开单日期',
            width: 180,
            dataIndex: 'createTime',

        }, {
            title: '结算日期',
            width: 180,
            dataIndex: 'settlementTime',

        }, {
            title: '核销日期',
            width: 180,
            dataIndex: 'writeOffTime',

        },
    ]

    const searchFields1 = [
        {
            type: "Select",
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: [{ label: "已记账", value: 'pay-status.bill', key: "pay-status.bill" }, { label: "已核销", value: 'pay-status.write-off', key: "pay-status.write-off" }]

        },
        {
            type: "Input",
            placeholder: "车辆牌照",
            fieldName: "vehicleCode"
        },
        {
            type: "Input",
            placeholder: "三包信息",
            fieldName: "threeGuaranteesInfo"
        }, {
            type: "Select",
            placeholder: "工单类型",
            fieldName: "orderType",
            options: getPcodeDict('014').children.map(item => {
                return ({ label: item.title, value: item.dictCode, key: item.id })
            }),
        }, {
            type: "Select",
            placeholder: "结算方",
            fieldName: "settlementParty",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY)
        },

        {
            type: "Input",
            placeholder: "单据编号",
            fieldName: "orderCode"
        },

    ]

    const searchMoreFields = [

        {
            type: "Datepicker",
            placeholder: "结算日期",
            fieldName: "settlementTime"
        },
        {
            type: "Datepicker",
            placeholder: "开单日期",
            fieldName: "createTime"
        }, {
            type: "Datepicker",
            placeholder: "核销日期",
            fieldName: "writeOffTime"
        },

    ]


    const desc1 = [
        {
            label: " 工时费",
            value: "￥" + (descTotalwork.hourlyWage ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "材料费",
            value: "￥" + (descTotalwork.materialAmount ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "外出费",
            value: "￥" + (descTotalwork.travelExpenses ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "餐补费",
            value: "￥" + (descTotalwork.mealSupplement ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "  三包工时汇总",
            value: "￥" + (descTotalwork.threeGuaranteesTotalHourlyWage ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "  费用金额",
            value: "￥" + (descTotalwork.totalAmount ?? 0).toFixed(2),
            color: "red"
        },
        {
            label: "  预付款",
            value: "￥" + (descTotalwork.prepayments ?? 0).toFixed(2),
            color: "red"
        }, {
            label: "  已收金额",
            value: "￥" + (descTotalwork.paidAmount ?? 0).toFixed(2),
            color: "red"
        }, {
            label: "  应收金额",

            value: "￥" + (descTotalwork.payableAmount ?? 0).toFixed(2),
            color: "red"
        },

    ]

    return (
        <>

            <YhBox descriptions={desc1}>
                <YhPageHeader
                    size="small"
                    justify="start"
                    triggerSearch
                    searchValues={serachworke}
                    searchFields={searchFields1}
                    searchMoreFields={searchMoreFields}
                    onChange={updateSearchParamt}
                    handleSearch={() => { openWorkerOrder({ ...serachworke }) }}
                    handleReset={resetSearchParamt}
                />
                <div>
                    <Table
                        columns={column2}
                        loading={loading}
                        dataSource={workOrderData}
                        scroll={{ y: 600 }}
                        pagination={{
                            ...pgCfgwork,
                            position: ["bottomCenter"],
                            showTotal: () => `共${pgCfgwork.total}条`,
                            onChange: (pageNum, pageSize) => {
                                openWorkerOrder(({ pageSize, pageNum }));
                            }
                        }}
                    />
                </div>
            </YhBox>

        </>

    )

}