import React from "react"
import { Tooltip, message, Switch } from "antd"
// import { getStorage } from "@/utils/dataStorage"
import { getUserCustomizeConfigOne } from "@/service/settings"
import { getPcodeDict } from "@/utils/dataStorage"
import { OrderReturnStatus } from "@/utils/enum.js"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/components/YhTooltip"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
export const saveKey = "repairReportManage"




export const getAllTable = (props) => {

    let commonData = [
        {
            title: "序号",
            key: "index",

        },
        {
            title: "单据编号",
            key: "orderCode",
        },
        {
            title: "客户名称",
            key: "customerName",
        },
        {
            title: "车牌牌照",
            key: "vehicleCode",
        },
        {
            title: "单据类型",
            key: "tradeType",
        },
        {
            title: "工单类型",
            key: "orderType",
        },
        {
            title: "故障类型",
            key: "faultClassification",
        },
        {
            title: "结算类型",
            key: "settlementAccountType",
        },
        {
            title: "单据状态",
            key: "orderStatusDesc",
        },
        {
            title: "商品名称",
            key: "materialName",
        },
        {
            title: "商品编号",
            key: "materialCode",
        },
        {
            title: "商品品牌",
            key: "materialBrand",
        },
        {
            title: "数量",
            key: "materialNumber",
        },
        {
            title: "采购单价",
            key: "purchasePrice",
        },
        {
            title: "销售单价",
            key: "salePrice",
        },
        {
            title: "优惠",
            key: "preferentialAmount",
        },
        {
            title: "折扣",
            key: "materialDiscount",
        },
        {
            title: "材料应收金额",
            key: "materialReceivableAmount",
        },

        {
            title: "材料实收金额",
            key: "materialActualAmount",
        },
        {
            title: "材料合计成本",
            key: "materialCostAmount",
        },
        {
            title: "材料毛利合计",
            key: "materialGrossProfit",
        },
        {
            title: "配件成本",
            key: "partCostAmount",
        },
        {
            title: "配件毛利",
            key: "partGrossProfit",
        },
        {
            title: "登记日期",
            key: "orderCreateTime",
        },
        {
            title: "结算日期",
            key: "settlementTime",
        },
    ]
    return commonData
}

export const getTableColumns = async (props, columnsData) => {
    let columns = []
    if (columnsData) {
        columns = columnsData
    } else {
        try {
            // let storageColumns = await getStorage(props.saveKey || saveKey)
            // if (storageColumns) {
            //     columns = storageColumns
            // } else {
            let customizeConfig = await getUserCustomizeConfigOne(props.saveKey || saveKey)
            if (customizeConfig && customizeConfig.retCode == 200) {
                columns = customizeConfig.retData
                    ? JSON.parse(customizeConfig.retData.val)
                    : getAllTable({ isAll: true }).map((item) => {
                        return { ...item, isShow: true }
                    })
            } else {
                message.warning("获取table排序失败，使用默认排序")
                columns = getAllTable({ isAll: true }).map((item) => {
                    return { ...item, isShow: true }
                })
                return
            }
            // }
        } catch (error) {
            message.warning("获取table排序失败，使用默认排序")
            columns = getAllTable({ isAll: true }).map((item) => {
                return { ...item, isShow: true }
            })
        }
    }

    columns = columns.filter((item) => item.isShow)

    columns = columns.map((item) => {
        switch (item.key) {
            case "index":
                return {
                    title: "序号",
                    width: 100,
                    align: "center",
                    dataIndex: "index",
                    render: (text, record, index) => index + 1
                }
            case "orderCode":
                return {
                    title: "单据编号",
                    width: 140,
                    align: "center",
                    dataIndex: "orderCode",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "customerName":
                return {
                    title: "客户名称",
                    align: "center",
                    width: 200,
                    dataIndex: "customerName",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "vehicleCode":
                return {
                    title: "车牌牌照",
                    align: "center",
                    dataIndex: "vehicleCode",
                    width: 140,
                }
            case "tradeType":
                return {
                    title: "单据类型",
                    align: "center",
                    dataIndex: "tradeType",
                    render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.tradeType[text]} />,
                }
            case "orderType":
                return {
                    title: "工单类型",
                    align: "center",
                    dataIndex: "orderType",
                    render: (text) => <YhTooltip maxWidth={220} text={text && props.dictObj.current.orderType[text]} />,
                }
            case "faultClassification":
                return {
                    title: "故障类型",
                    align: "center",
                    width: 140,
                    dataIndex: "faultClassification",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "settlementAccountType":
                return {
                    title: "结算类型",
                    align: "center",
                    width: 140,
                    dataIndex: "settlementAccountType",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "orderStatusDesc":
                return {
                    title: "单据状态",
                    align: "center",
                    width: 140,
                    dataIndex: "orderStatusDesc",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "materialName":
                return {
                    title: "商品名称",
                    align: "center",
                    width: 200,
                    dataIndex: "materialName",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "materialCode":
                return {
                    title: "商品编号",
                    align: "center",
                    width: 140,
                    dataIndex: "materialCode",
                    render: (text) => <YhTooltip text={text} />,
                }
            case "materialBrand":
                return {
                    title: "商品品牌",
                    align: "center",
                    dataIndex: "materialBrand",
                    width: 140,
                    render: (text) => <YhTooltip maxWidth={220} text={text} />,
                }
            case "materialNumber":
                return {
                    title: "数量",
                    align: "center",
                    dataIndex: "materialNumber",
                    // render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.serviceMethod[text]}/>,
                    width: 80,
                }
            case "purchasePrice":
                return {
                    title: "采购单价",
                    align: "center",
                    dataIndex: "purchasePrice",
                    //render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.orderType[text]}/>,
                    width: 80,
                }
            case "salePrice":
                return {
                    title: "销售单价",
                    align: "center",
                    dataIndex: "salePrice",
                    width: 80,
                    render: (text) => <YhTooltip maxWidth={220} text={text} />,
                }
            case "preferentialAmount":
                return {
                    title: "优惠",
                    align: "center",
                    dataIndex: "preferentialAmount",
                    width: 80,
                }
            case "materialDiscount":
                return {
                    title: "折扣",
                    align: "center",
                    dataIndex: "materialDiscount",
                    width: 80,
                }
            case "materialReceivableAmount":
                return {
                    title: "材料应收金额",
                    align: "center",
                    dataIndex: "materialReceivableAmount",
                    //render: (text) => <>{props.dictObj.current.orderSource[text]}</>,
                    width: 80,
                    //render: (text) => <YhTooltip maxWidth={220} text={props.dictObj.current.orderSource[text]}/>
                }
            case "materialActualAmount":
                return {
                    title: "材料实收金额",
                    align: "center",
                    dataIndex: "materialActualAmount",
                    width: 80,
                }
            case "materialCostAmount":
                return {
                    title: "材料成本金额",
                    align: "center",
                    dataIndex: "materialCostAmount",
                    width: 80,
                }
            case "materialGrossProfit":
                return {
                    title: "材料毛利金额",
                    align: "center",
                    dataIndex: "materialGrossProfit",
                    width: 80,
                }
            case "partCostAmount":
                return {
                    title: "配件成本",
                    align: "center",
                    dataIndex: "partCostAmount",
                    width: 80,
                }
            case "partGrossProfit":
                return {
                    title: "配件毛利",
                    align: "center",
                    dataIndex: "partGrossProfit",
                    width: 80,
                }
            case "orderCreateTime":
                return {
                    title: "登记日期",
                    align: "center",
                    dataIndex: "orderCreateTime",
                    width: 160,
                }
            case "settlementTime":
                return {
                    title: "结算日期",
                    align: "center",
                    dataIndex: "settlementTime",
                    width: 160,
                }
            default:
                console.log("没有对应的key")
                break
        }
    })
    return columns
}
