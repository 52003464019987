import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import zeng from "@/assets/images/zeng.png"
import { EditableRow, EditableCell } from "../pubEditble"
import { ItemAttr } from "@/utils/enum"

//其他项目
export default ({ maintenanceRef, returnMaintainMaterialList, setIsChange, returnOrderInfo }) => {
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //总价
    useEffect(() => {
        setData(
            returnMaintainMaterialList.map((item) => ({
                ...item,
                workHour: item.itemWorkHours, //工时
                maxWorkHour: item.itemWorkHours,
                hourPrice: item.workHoursPrice, //单价
                totalAll: item.itemAmount, //工时费
                discount: item.itemDiscount, //折扣
                actualMoney: item.state === "refuse_repair" ? 0 : item.actualAmount,
            }))
        )
    }, [returnMaintainMaterialList])

    //是否可以修改
    const [isUpdate, setIsUpdate] = useState(false)
    useEffect(() => {
        if (returnOrderInfo.returnStatus === "billed") {
            setIsUpdate(true)
        } else {
            setIsUpdate(false)
        }
    }, [returnOrderInfo])

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualMoney)
        })
        setTotalPrice(num.toFixed(2))
    }, [data])

    //暴露给父组件的方法
    useImperativeHandle(maintenanceRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return data
        },
    }))

    /**
     * 以下是页面展示
     */
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning("请选择你要删除的项目")
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.id) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success("批量删除成功")
        setIsChange(true)
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex
        if (row.id) {
            delIndex = newData.findIndex((item) => item.id === row.id)
        } else {
            delIndex = newData.findIndex((item) => item.time === row.time)
        }
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
        setIsChange(true)
    }

    //选中展示table配置
    const columns = [
        {
            title: "项目编码",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            align: "center",
            width: 400,
            fixed: "left",
            dataIndex: "itemName",
            render: (text, record) =>
                record.state === "refuse_repair" ? (
                    <span>
                        {text}
                        <span style={{ color: "#f00" }}>(拒修)</span>
                    </span>
                ) : (
                    <>{text}</>
                ),
        },
        {
            title: "预付款",
            align: "center",
            width: "120px",
            dataIndex: "prepayFlag",
            render: (text) => (text == 0 ? "否" : "是"),
        },
        {
            title: "项目属性",
            align: "center",
            width: "120px",
            dataIndex: "itemAttr",
            render: (text) => <p>{ItemAttr[text]}</p>,
        },
        {
            title: "工时",
            align: "center",
            width: 100,
            dataIndex: "workHour",
            editable: isUpdate,
        },
        {
            title: "单价",
            align: "center",
            width: 185,
            dataIndex: "price",
        },
        {
            title: "工时费",
            align: "center",
            width: 200,
            dataIndex: "receivableAmount",
        },
        {
            title: "优惠",
            align: "center",
            width: 185,
            dataIndex: "preferentialAmount",
        },
        {
            title: "折扣",
            align: "center",
            width: 100,
            dataIndex: "discount",
        },
        {
            title: "总金额",
            align: "center",
            width: "120px",
            dataIndex: "actualMoney",
        },
        {
            title: "是否赠送",
            align: "center",
            width: 100,
            dataIndex: "giftFlag",
            render: (text) => (text === "gift" ? <img src={zeng} style={{ width: "35px" }} alt="" /> : ""),
        },
        {
            title: "备注",
            align: "center",
            width: "120px",
            dataIndex: "remark",
        },
        isUpdate
            ? {
                  title: "操作",
                  align: "center",
                  width: "80px",
                  dataIndex: "operation",
                  render: (_, record) => {
                      return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaterialOne(record)} />
                  },
              }
            : {},
    ]

    //单元格编辑保存
    const materialHandleSave = (row, status) => {
        //每次服务性质不是三包的话，把之前选的三包给清空掉。 是三包给个默认值
        if (row.serviceNature === "016001") {
            // row.threeGuaranteesUnit = "10601";
        } else {
            row.threeGuaranteesUnit = ""
            row.claimNumber = ""
        }
        // const itemData = await getItemOne(row.id)
        // const {itemWorkHours, workHoursPrice} = itemData.retData
        //查看是否有三包
        // if (!row.threeGuaranteesUnit) {
        if (row.serviceNature !== "016001") {
            // row.workHour = itemWorkHours //工时
            // row.hourPrice = workHoursPrice //单价
            //每次编辑 重新计算工时费
            row.totalAll = (row.workHour * row.hourPrice).toFixed(2)
            //有折扣   工时费 *折扣 - 优惠
            if (+row.discount > 0) {
                let count = (row.totalAll * row.discount) / 10 - row.preferentialAmount
                row.actualMoney = count.toFixed(2)
            } else {
                let count = row.totalAll - row.preferentialAmount
                row.actualMoney = count.toFixed(2)
            }
        } else {
            row.actualMoney = 0 //总金额
            row.preferentialAmount = 0
            row.discount = null //折扣
            // row.workHour = 0 //工时
            // row.hourPrice = 0 //单价
            // row.workHour = itemWorkHours //工时
            // row.hourPrice = workHoursPrice //单价
            row.totalAll = 0 //工时费
        }
        // console.log(row)
        //服务性质据修改成 团队传来的状态据修
        if (row.state === "refuse_repair") {
            row.actualMoney = 0
            row.preferentialAmount = 0
            row.discount = null
        }
        if (row.giftFlag === "gift") {
            row.actualMoney = "0.00"
        }
        const newData = [...data]
        const index = newData.findIndex((item) => row.id === item.id && row.temporaryId === item.temporaryId)
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
        setIsChange(true)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>维修项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isUpdate && (
                        <div className="list-name-but">
                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.time}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
        </div>
    )
}
