import React, { useState, useEffect, useImperativeHandle } from "react"
import { Table, message } from "antd"
import YhIcon from "@/components/pubIcon"
import { EditableRow, EditableCell } from "./otherProjectTable"
import { getOrderOtherItems } from "@/service/ordersService"
import {useOrderModel} from "./orderModal"
//其他项目
export default ({ orderId, mode, otherProjectRef, onReceivables, parentOrderType }) => {
    const {apiCount} = useOrderModel()
    const [data, setData] = useState([]) //表格数据
    const [totalPrice, setTotalPrice] = useState(0) //总价
    const [orderSelectThreeGuaranteesUnit, setOrderSelectThreeGuaranteesUnit] = useState("014003") //记录整个订单的工单类型，看是否是三包， 收费，默认是  三包/收费
    const [isOperation, setIsOperation] = useState(false)
    useEffect(() => {
        if (mode !== "insert") {
            echoData()
            setOrderSelectThreeGuaranteesUnit(parentOrderType)
        }
    }, [])

    //计算总价
    useEffect(() => {
        let num = 0
        data.forEach((item) => {
            num += Number(item.actualAmount)
        })
        onReceivables({ otherAccount: num })
        setTotalPrice(num.toFixed(2))
    }, [data])

    //暴露给父组件的方法
    useImperativeHandle(otherProjectRef, () => ({
        //upData 就是暴露给父组件刷新保存后的的方法
        upData: (parentOrderId) => {
            echoData(parentOrderId)
        },
        //父组件保存的时候 获取所有编辑完后的数据
        upOtherData: () => {
            return data
        },
        orderTypeChange: async (orderType, isThree) => {
            await setOrderSelectThreeGuaranteesUnit(orderType)
            if (orderType === "014002") {
                await setData([])
            }
        },
    }))

    // 回显
    const echoData = async (parentOrderId = "") => {
        apiCount.current =  apiCount.current + 1
        console.log('other')
        let data = {
            pageSize: 1000,
            searchItem: { orderId: parentOrderId ? parentOrderId : orderId },
        }
        await getOrderOtherItems(data).then((res) => {
            let result = res.retData.list
            let editArr = result.map((item) => {
                return {
                    ...item,
                }
            })
            setData(editArr)
        }).finally(() => {
            apiCount.current =  apiCount.current - 1
        })
    }

    //新增确定
    const setPartList = () => {
        let check = {
            itemName: "项目名称",
            itemCode: "项目编号",
            unitPrice: "单价",
            itemNumber: "数量",
        }
        let isCheck = true
        data.forEach((item) => {
            Object.entries(check).forEach((val) => {
                if (!item[val[0]]) {
                    message.error(`${val[1]}是必填项`)
                    isCheck = false
                }
            })
        })
        if (isCheck) {
            setData([
                ...data,
                {
                    itemName: "",
                    itemCode: new Date().getTime(),
                    itemAttr: "",
                    serviceNature: "",
                    unitPrice: 0,
                    itemNumber: 1,
                    itemAmount: 0,
                    itemDiscount: null,
                    preferentialAmount: 0,
                    actualAmount: 0,
                    remark: "",
                    time: new Date().getTime(),
                },
            ])
        }
    }
    /**
     * 以下是页面展示
     */
    const [MaterialKeys, setMaterialKeys] = useState([]) // 批量删除的key
    const [deleteAll, setDeleteAll] = useState(false) //删除多选的控制按钮
    //批量删除多选的成员id
    const MaterialSelection = {
        columnWidth: "30px",
        onChange: (selectedRowKeys, selectedRows) => {
            setMaterialKeys(selectedRowKeys)
        },
    }
    //批量删除
    const deleteTemaAll = () => {
        if (MaterialKeys.length === 0) {
            message.warning("请选择你要删除的项目")
            return
        }
        let arr = data.filter((item) => MaterialKeys.indexOf(item.id) < 0)
        setDeleteAll(false)
        setData(arr)
        setMaterialKeys([])
        message.success("批量删除成功")
    }
    //维修物料删除一条数据
    const deleteMaterialOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex
        if (row.id) {
            delIndex = newData.findIndex((item) => item.id === row.id)
        } else {
            delIndex = newData.findIndex((item) => item.time === row.time)
        }
        newData.splice(delIndex, 1)
        setData(newData)
        message.success("删除成功")
    }

    //是否是查看
    const isMode = () => {
        return mode === "look" ? false : true
    }

    useEffect(() => {
        comIsOperation()
    },[orderSelectThreeGuaranteesUnit])

    const comIsOperation = () => {
        if (orderSelectThreeGuaranteesUnit === "014002") {
            return setIsOperation(false)
        }
        setIsOperation(true)
    }

    //table配置
    const columns = [
        {
            title: "项目编码",
            align: "center",
            width: 120,
            fixed: "left",
            dataIndex: "itemCode",
            editable: true,
        },
        {
            title: "项目名称",
            align: "center",
            width: 200,
            fixed: "left",
            dataIndex: "itemName",
            editable: true,
        },
        {
            title: "项目属性",
            align: "center",
            width: "120px",
            dataIndex: "itemAttr",
            editable: true,
        },
        {
            title: "项目性质",
            align: "center",
            width: 200,
            dataIndex: "serviceNature",
            editable: true,
        },
        {
            title: "单价",
            align: "center",
            width: "120px",
            dataIndex: "unitPrice",
            editable: true,
        },
        {
            title: "数量",
            align: "center",
            width: "120px",
            dataIndex: "itemNumber",
            editable: true,
        },
        {
            title: "总计",
            align: "center",
            width: "120px",
            dataIndex: "itemAmount",
        },
        // {
        //     title: "优惠",
        //     align: "center",
        //     width: 185,
        //     dataIndex: "preferentialAmount",
        //     editable: true,
        // },
        // {
        //     title: "折扣",
        //     align: "center",
        //     width: 185,
        //     dataIndex: "itemDiscount",
        //     editable: true,
        // },
        {
            title: "总金额",
            align: "center",
            width: 160,
            dataIndex: "actualAmount",
        },
        {
            title: "备注",
            align: "center",
            width: 160,
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            dataIndex: "operation",
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteMaterialOne(record)} />
            },
        },
    ]

    //单元格编辑保存
    const materialHandleSave = (row) => {
        //每次编辑计算总计
        row.itemAmount = (row.unitPrice * row.itemNumber).toFixed(2)
        if (row.itemDiscount) {
            row.actualAmount = ((row.unitPrice * row.itemNumber * row.itemDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualAmount = (row.unitPrice * row.itemNumber - row.preferentialAmount).toFixed(2)
        }
        const newData = [...data]
        let index
        if (row.id) {
            index = newData.findIndex((item) => row.id === item.id)
        } else {
            index = newData.findIndex((item) => row.time === item.time)
        }
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setData(newData)
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    }
    const MaterialColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: materialHandleSave,
            }),
        }
    })

    return (
        <div className="list-container" style={{ marginBottom: "100px" }}>
            <div className="list-header">
                <div className="list-name">
                    <span>其他项目</span>
                    <span className="total-price">
                        总金额&nbsp;¥&nbsp;<span>{totalPrice}</span>
                        &nbsp;元&nbsp;({data.length}项){" "}
                    </span>
                    {isMode() && isOperation && (
                        <div className="list-name-but">
                            <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px" }} onClick={setPartList} />

                            {!deleteAll ? (
                                <span className="list-delete" onClick={() => setDeleteAll(true)}>
                                    批量删除
                                </span>
                            ) : (
                                <>
                                    <span className="list-delete" onClick={deleteTemaAll}>
                                        确认删除
                                    </span>
                                    <span className="list-delete" onClick={() => setDeleteAll(false)}>
                                        取消
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Table
                size="small"
                rowKey={(record) => record.id || record.time}
                components={components}
                columns={MaterialColumns}
                dataSource={data}
                pagination={false}
                scroll={{ x: 2000, y: 260 }}
                rowSelection={deleteAll ? MaterialSelection : false}
            />
        </div>
    )
}
