import React, { useEffect } from "react"
import { Form, Col, Row, Input, message, Button, Select, Switch } from "antd"
import PubDrawer from "@/components/Drawer"
import { getAccountOne, insertAccount, editAccount } from "@/service/fleetService"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import { timeShift } from "@/utils/util"

const { Option } = Select

const TeamDetails = (props) => {
    //模式  父级信息  显示隐藏  隐藏回调 成员列表加载
    const { rowInfo, accountVisible, onClose, customerInfo } = props //customerInfo 是客户组件带过   rowInfo是账户组件的信息
    const [form] = Form.useForm()

    //每次新增和编辑拿到客户的id和信息
    useEffect(() => {
        if (accountVisible.mode === "edit") {
            isMode(rowInfo.id)
        } else {
            form.setFieldsValue({
                createUser: getUserInfo() ? getUserInfo().nickname : "未获取到", //获取系统业务员
                customerType: customerInfo.customerType, //获取客户的类型
                customerName: customerInfo.customerName,
                customerId: customerInfo.id,
            })
        }
        if (!accountVisible.visible) {
            form.resetFields()
        }
    }, [accountVisible])

    //回显
    const isMode = (id) => {
        getAccountOne(id)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    form.setFieldsValue({
                        ...res.retData,
                        customerName: customerInfo.customerName,
                        createTime: res.retData.createTime,
                        accountStatus: res.retData.accountStatus === 0 ? true : false,
                    })
                // }
            })
            // .catch((err) => {
            //     if (err && err.retCode === 200) {
            //         message.warning(err.retMsg)
            //     }
            // })
    }

    const onFinish = (fieldsValue) => {
        let data = {
            ...fieldsValue,
        }
        // delete data.customerType;
        if (accountVisible.mode === "insert") {
            insertAccount(data)
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        message.success(`${customerInfo.customerName}的账户新增成功`)
                        onClose()
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.warning(err.retMsg)
                //     }
                // })
        } else if (accountVisible.mode === "edit") {
            delete data.createTime
            editAccount(rowInfo.id, {
                ...data,
                accountStatus: data.accountStatus ? 0 : 1,
            })
                .then((res) => {
                    // if (res && res.retCode === 200) {
                        message.success(`${customerInfo.customerName}的账户编辑成功`)
                        onClose()
                    // }
                })
                // .catch((err) => {
                //     if (err && err.retCode === 500) {
                //         message.warning(err.retMsg)
                //     }
                // })
        }
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    return (
        <PubDrawer visible={accountVisible.visible} onClose={onClose}>
            <div className="team-drawer-title">
                <span>账户详情</span>
            </div>
            <div className="team-drawer-content">
                <div className="team-drawer-header">{accountVisible.mode === "insert" ? "账户新增" : "账户编辑"}</div>
                <div className="team-drawer-form">
                    <Form form={form} {...formItemLayout} onFinish={onFinish}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item label="账户编码" name="accountCode">
                                    <Input placeholder="自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="客户类型" name="customerType">
                                    <Select disabled placeholder="请选择客户类型" style={{ width: "100%" }}>
                                        {getPcodeDict("017").children.map((item) => (
                                            <Option value={item.dictCode} key={item.id}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="客户名称" name="customerName">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="账户类型"
                                    name="accountType"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择账户类型",
                                        },
                                    ]}
                                >
                                    <Select showSearch allowClear placeholder="请选择账户类型" style={{ width: "100%" }}>
                                        <Option value={0}>授信账户</Option>
                                        <Option value={1}>返利账户</Option>
                                        <Option value={2}>预存账户</Option>
                                        <Option value={3}>临时授信账户</Option>
                                        <Option value={4}>服务费账户</Option>
                                        <Option value={5}>代金券账户</Option>
                                        <Option value={6}>预付款账户</Option>
                                        <Option value={7}>订金账户</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="账户金额"
                                    name="totalAmount"
                                    rules={[
                                        {
                                            pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                                            message: "请输入数字且可保留两位小数",
                                        },
                                    ]}
                                >
                                    <Input disabled placeholder="请输入账户金额" prefix="¥" suffix="元" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="登记日期" name="createTime">
                                    <Input placeholder="自动生成" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="createUser" label="业务员">
                                    <Input placeholder="请输入业务员" disabled />
                                </Form.Item>
                            </Col>
                            {accountVisible.mode === "edit" && (
                                <Col span={12}>
                                    <Form.Item label="账户状态" name="accountStatus" valuePropName="checked">
                                        <Switch checkedChildren="启用" unCheckedChildren="禁用" />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Form.Item className="team-form-footer" wrapperCol={24}>
                            <Button onClick={onClose} className="team-but-one">
                                取消
                            </Button>
                            <Button htmlType="submit" className="team-but-two">
                                提交
                            </Button>
                        </Form.Item>
                        <Form.Item hidden name="customerId">
                            <Input />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </PubDrawer>
    )
}

export default TeamDetails
