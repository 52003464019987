/**
 * 常量
 */

/**
 * 菜单类型枚举
 */
const menuTypeEnum = {
    catalogue: "目录",
    menu: "菜单",
    button: "按钮",
}

/**
 * 菜单状态枚举
 */
const menuStatusEnum = {
    normal: "正常",
    disable: "禁用",
}

export { menuStatusEnum, menuTypeEnum }
