import React, { useState, useRef, useEffect } from "react";
import { message } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { YhForm } from "@/erp_subpackage/components/YhFormBox";
import { useQuestionModel } from "../questionModel";
import { getFormValidatorErrText } from "@/erp_subpackage/utils/util"
import { getPcodeDict } from "@/utils/dataStorage"

const enums = {
    "RADIO": "单选题",
    "MULTIPLE_CHOICE": "多选题",
    "TRUE_FALSE": "判断题",
}

export const DetailDialog = () => {
    const {
        detailDialog: { visible, mode }, setDetailDialogInfo,
        updateFormVal,
        editFormVal,
        addFormVal,
        lookFormVal,
        selectedRowKeys,
        getQuestionOne,
        insertQuestion,
        editQuestion
    } = useQuestionModel();
    const formRef = useRef();
    const formVal = mode === "edit" ? editFormVal : mode === "add" ? addFormVal : lookFormVal;
    //问题类型 RADIO、MULTIPLE_CHOICE、TRUE_FALSE
    const [attrType, setAttrType] = useState("RADIO");

    const options = getPcodeDict("038").children.map(item => ({ label: item.title, value: item.dictCode }));

    const onCancel = () => setDetailDialogInfo();

    useEffect(() => {
        if (visible) {
            //新增清楚缓存
            if (mode === "add") {
                updateFormVal(mode);
                setAttrType("RADIO");
                formRef.current.resetFields()
            } else {
                getQuestionOne(selectedRowKeys[0], mode).then(res => setAttrType(res.attrCode ?? "RADIO"));
            }
        }
    }, [visible])

    //表单提交
    const onSubmit = async () => {
        formRef.current.validateFields().then(res => {
            let {
                code, name, typeCode,
                // RADIO, MULTIPLE_CHOICE, TRUE_FALSE, //属性
                TRUE_S, TRUE_E, //判断题
                A, A_C, A_R,  //多选和单选
                B, B_C, B_R,
                C, C_C, C_R,
                D, D_C, D_R
            } = res;
            let details = [];
            if (attrType === "TRUE_FALSE" && !TRUE_S && !TRUE_E) {
                message.warning("请选择判断题的正确答案");
                return;
            }
            if (attrType === "TRUE_FALSE") {
                details = [{
                    answerKey: "A",
                    answerValue: "正确",
                    status: TRUE_S ? "1" : "0",
                    deleteFlag: 0
                }, {
                    answerKey: "B",
                    answerValue: "错误",
                    status: TRUE_E ? "1" : "0",
                    deleteFlag: 0
                }]
            } else if (attrType === "RADIO") {
                details = [{
                    answerKey: "A",
                    answerValue: A ?? "",
                    status: A_R ? "1" : "0",
                    deleteFlag: 0
                }, {
                    answerKey: "B",
                    answerValue: B ?? "",
                    status: B_R ? "1" : "0",
                    deleteFlag: 0
                }, {
                    answerKey: "C",
                    answerValue: C ?? "",
                    status: C_R ? "1" : "0",
                    deleteFlag: 0
                }, {
                    answerKey: "D",
                    answerValue: D ?? "",
                    status: D_R ? "1" : "0",
                    deleteFlag: 0
                }]
            } else if (attrType === "MULTIPLE_CHOICE") {
                if (!A_C && !B_C && !C_C && !D_C) {
                    message.warning("请勾选多选题答案");
                    return;
                }
                if ([A_C, B_C, C_C, D_C].filter(item => !!item).length < 2) {
                    message.warning("多选题答案的答案至少为两项");
                    return;
                }
                details = [{
                    answerKey: "A",
                    answerValue: A ?? "",
                    status: A_C ? "1" : "0",
                    deleteFlag: 0
                }, {
                    answerKey: "B",
                    answerValue: B ?? "",
                    status: B_C ? "1" : "0",
                    deleteFlag: 0
                }, {
                    answerKey: "C",
                    answerValue: C ?? "",
                    status: C_C ? "1" : "0",
                    deleteFlag: 0
                }, {
                    answerKey: "D",
                    answerValue: D ?? "",
                    status: D_C ? "1" : "0",
                    deleteFlag: 0
                }]
            }
            let data = {
                code,
                typeCode,
                name,
                typeName: options.find(item => item.value === typeCode)?.label,
                attrCode: attrType,
                attrName: enums[attrType],
                details
            }
            if (mode === "add") {
                insertQuestion(data).then(res => {
                    res && onCancel()
                })
            } else if (mode === "edit") {
                editQuestion(data).then(res => {
                    res && onCancel()
                })
            }
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }


    //表单change
    const onChange = (changedValues) => {
        //问题属性 
        if ("RADIO" in changedValues ||
            "MULTIPLE_CHOICE" in changedValues ||
            "TRUE_FALSE" in changedValues) {
            let fieldName = Object.keys(changedValues)[0];
            changedValues = { ...changedValues, RADIO: false, [fieldName]: true };
            console.log(fieldName, changedValues)
            setAttrType(fieldName);
            updateFormVal(mode, changedValues, true);
            return;
        }
        //单选题 单选
        if ("A_R" in changedValues ||
            "B_R" in changedValues ||
            "C_R" in changedValues ||
            "D_R" in changedValues
        ) {
            let fieldName = Object.keys(changedValues)[0];
            changedValues = { ...changedValues, A_R: false, B_R: false, C_R: false, D_R: false, [fieldName]: true };
            updateFormVal(mode, changedValues);
            return;
        }
        //判断题 单选
        if ("TRUE_S" in changedValues ||
            "TRUE_E" in changedValues
        ) {
            let fieldName = Object.keys(changedValues)[0];
            changedValues = { ...changedValues, TRUE_S: false, TRUE_E: false, [fieldName]: true };
            updateFormVal(mode, changedValues);
            return;
        }
        updateFormVal(mode, changedValues)
    }
    const formConfig = {
        formRef,
        labelCol: { span: 5 },
        formValues: formVal,
        onChange: onChange,
        disabled: mode === "look",
        items: [
            {
                type: "Input",
                fieldLabel: "问题编号",
                fieldName: "code",
                span: 8,
            }, {
                type: "Select",
                fieldLabel: "问题类型",
                fieldName: "typeCode",
                options: options,
                rules: [{ required: true, message: "请选择问题类型" }],
                span: 8,
            },
            //问题属性  attrCode
            {
                type: "Radio",
                fieldLabel: "问题属性",
                fieldName: "RADIO",
                colClassName: "yh-ant-col",
                radioName: "单选题",
                labelCol: { span: 12 },
                span: 4,
            }, 
            {
                type: "Radio",
                fieldName: "MULTIPLE_CHOICE",
                colClassName: "yh-ant-col",
                radioName: "多选题",
                span: 2,
                hidden:true
            },
             {
                type: "Radio",
                fieldName: "TRUE_FALSE",
                colClassName: "yh-ant-col",
                radioName: "判断题",
                span: 2,
            },
            {
                type: "Input",
                fieldLabel: "问题名称",
                fieldName: "name",
                colClassName: "yh-ant-col-7",
                rules: [{ required: true, message: "该项为必填" }],
                span: 24,
            },
            ...attrType === "TRUE_FALSE" ? [{
                type: "Radio",
                fieldLabel: "正确",
                fieldName: "TRUE_S",
                radioName: "是否为正确答案",
                colClassName: "yh-ant-col",
                span: 6,
            }, {
                type: "Radio",
                fieldLabel: "错误",
                fieldName: "TRUE_E",
                colClassName: "yh-ant-col",
                radioName: "是否为正确答案",
                span: 6,
            }] :
                //多选和单选 问题及答案
                [{
                    type: "Input",
                    fieldLabel: "答案选项A",
                    fieldName: "A",
                    colClassName: "yh-ant-col-10",
                    rules: [{ required: true, message: "该项为必填" }],
                    span: 16,
                }, {
                    type: "Checkbox",
                    fieldLabel: " ",
                    fieldName: "A_C",
                    checkboxName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "MULTIPLE_CHOICE"
                }, {
                    type: "Radio",
                    fieldLabel: " ",
                    fieldName: "A_R",
                    radioName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "RADIO"
                }, {
                    type: "Input",
                    fieldLabel: "答案选项B",
                    fieldName: "B",
                    colClassName: "yh-ant-col-10",
                    rules: [{ required: true, message: "该项为必填" }],
                    span: 16,
                }, {
                    type: "Checkbox",
                    fieldLabel: " ",
                    fieldName: "B_C",
                    checkboxName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "MULTIPLE_CHOICE"
                }, {
                    type: "Radio",
                    fieldLabel: " ",
                    fieldName: "B_R",
                    radioName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "RADIO"
                }, {
                    type: "Input",
                    fieldLabel: "答案选项C",
                    fieldName: "C",
                    colClassName: "yh-ant-col-10",
                    rules: [{ required: true, message: "该项为必填" }],
                    span: 16,
                }, {
                    type: "Checkbox",
                    fieldLabel: " ",
                    fieldName: "C_C",
                    checkboxName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "MULTIPLE_CHOICE"
                }, {
                    type: "Radio",
                    fieldLabel: " ",
                    fieldName: "C_R",
                    radioName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "RADIO"
                }, {
                    type: "Input",
                    fieldLabel: "答案选项D",
                    fieldName: "D",
                    colClassName: "yh-ant-col-10",
                    rules: [{ required: true, message: "该项为必填" }],
                    span: 16,
                }, {
                    type: "Checkbox",
                    fieldLabel: " ",
                    fieldName: "D_C",
                    checkboxName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "MULTIPLE_CHOICE"
                }, {
                    type: "Radio",
                    fieldLabel: " ",
                    fieldName: "D_R",
                    radioName: "是否为正确答案",
                    colClassName: "yh-ant-col-3",
                    span: 8,
                    hidden: attrType !== "RADIO"
                }]
        ]
    };

    return <YHModal
        visible={visible}
        title={`${mode === "edit" ? "编辑" : mode === "look" ? "查看" : "新增"}问题`}
        onCancel={onCancel}
        width={1200}
        bodyHeight={400}
        onOk={() => onSubmit()}
        showFooter={mode !== "look"}
    >
        <div style={{ padding: "12px" }}><YhForm {...formConfig} /></div>
    </YHModal>
}