import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// //仓库详情
// export interface IVRMWarehouseDetail {
//     address?: string,   // "",
//     branch?: string,   // "",
//     code?: string,   // "",
//     contact?: string,   // "",
//     createTime?: string,   // "",
//     createUser?: string,   // "",
//     deleteFlag?: string,   // 0,
//     enable?: string,   // 0,
//     id?: string,   // "",
//     name?: string,   // "",
//     note?: string,   // "",
//     orderNum?: string,   // 0,
//     orgCode?: string,   // "",
//     orgName?: string,   // "",
//     phone?: string,   // "",
//     shopId?: string,   // "",
//     shopName?: string,   // "",
//     status?: string,   // 0,
//     typeCode?: string,   // "",
//     typeName?: string,   // "",
//     updateTime?: string,   // "",
//     updateUser?: string,   // "",
//     ver?: string,   // 0
// }

// //区域详情
// export interface IVRMAreaDetail {
//     id: string,
//     orgId: string,
//     orgName: string,
//     warehouseId: string,
//     warehouseCode: string,
//     warehouseName: string,
//     code: string,
//     name: string,
//     areaDesc: string,
//     materialType: string,
//     createTime: string,
//     updateTime: string,
//     createUser: string,
//     updateUser: string,
// }

// /**
//  * 仓库 货架信息
//  */
//  export interface IVRMShelvesDetail {
//     id: string,
//     shopId: string,
//     shopName: string,
//     warehouseId: string,
//     warehouseName: string,
//     areaId: string,
//     areaName: string,
//     code: string,
//     name: string,
//     note: string,
// }

// /**
//  * 仓库 货位信息
//  */
//  export interface IVRMshelfDetail {
//     id: string,
//     wareHouseId: string,
//     orgId: string,
//     orgName: string,
//     warehouseCode: string,
//     warehouseName: string,
//     areaId: string,
//     areaCode: string,
//     areaName: string,
//     shelvesId: string,
//     shelvesCode: string,
//     shelvesName: string,
//     shelfName: string,
//     shelfDesc: string,
//     shelfCode: string,
//     code?:string
// }

class WarehouseService {
    //仓库
    async page(searchVo) {
        const apiUrl = "/ci-storage/base/warehouse/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/base/warehouse/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }
    async insert(insertVo) {
        const apiUrl = "/ci-storage/base/warehouse/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/base/warehouse/update";
        return await httpRequest.post(apiUrl, updateVo);
    }

    async getMaterialShelfTemporary(searchTe){
        const apiUrl = "/ci-storage/purchase/getMaterialShelfTemporary";
        return await httpRequest.post(apiUrl, searchTe);
    }

    async areaPage(searchVo) {
        const apiUrl = "/ci-storage/base/warehouse/area/listPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async areaInsert(insertVo) {
        const apiUrl = "/ci-storage/base/warehouse/area/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //批量录入区域
    async areaBatchInsert(insertVo) {
        const apiUrl = "/ci-storage/base/warehouse/area/batchinsert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async areaUpdate(updateVo) {
        const apiUrl = "/ci-storage/base/warehouse/area/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //区域导入
    async areaImport(file) {
        const appPageUrl = "vrm2/base/warehouse/area/import";
        return await httpRequest.post(appPageUrl, file);
    }
    //区域模板下载
    async areaDownloadTpl() {
        const appPageUrl = "/ci-storage/base/warehouse/area/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }


    async shelvesPage(searchVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelves/listPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async shelvesInsert(insertVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelves/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //批量录入货架
    async shelvesBatchInsert(insertVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelves/batchinsert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async shelvesUpdate(updateVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelves/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //货架导入
    async shelvesImport(file) {
        const appPageUrl = "vrm2/base/warehouse/shelves/import";
        return await httpRequest.post(appPageUrl, file);
    }
    //货架模板下载
    async shelvesDownloadTpl() {
        const appPageUrl = "/ci-storage/base/warehouse/shelves/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }


    async shelfPage(searchVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelf/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async shelfAllPage(searchVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelf/allPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async shelfInsert(insertVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelf/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //批量新增货位
    async shelfBatchInsert(insertVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelf/batchinsert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async shelfUpdate(updateVo) {
        const apiUrl = "/ci-storage/base/warehouse/shelf/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //货位模板下载
    async shelfDownloadTpl() {
        const appPageUrl = "/ci-storage/base/warehouse/shelf/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }

}

export const warehouseService = new WarehouseService();
