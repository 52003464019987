import { useState, useEffect } from "react";
import { createModel } from "hox";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { message } from "antd";
import { formatNum } from "@/erp_subpackage/utils/util";
import { purchaseDrService } from "./purchaseDrService";
import { useLockFn } from "ahooks";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";

export const usePurchaseDrModel = createModel(function () {
    const { user: { shopId, shopName, shopCode, organType, organTypeCode} } = useGlobalModel();
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表数据源分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //列表统计展示数据
    const initStatistics = {
        // aggregateAmount: "0.00",
        // paidAmount: "0.00",
        totalAmount: 0,
        totalNum: 0
    };
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initStatistics);
    const [statistics, setStatistics] = useState(initStatistics);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });

    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    useEffect(() => {
        let newRowsDescTotal = { ...initStatistics };
        selectedRowKeys.forEach((item) => {
            if (item.status !== DictCodeEnmu.PRO_CHANGE) {
                newRowsDescTotal.totalNum += +formatNum(item.totalNum)
                newRowsDescTotal.totalAmount += +formatNum(item.totalAmount)
            }
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [selectedRowKeys])

    //切换门店重置缓存
    useEffect(() => {
        setSearchParam({});
        setSelectedRowKeys([]);
    }, [shopId]);

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            ...searchValue,
            status: searchValue?.status ? searchValue?.status.join() : searchValue?.status,
            // shopId: searchValue.shopId ?? shopId,
            // shopId: searchValue.shopId ?? '',//默认查全部
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
            branchIn: "ci"
        }
        let { retData } = await purchaseDrService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading({ spinning: false });
        setSelectedRowKeys([]);
        purchaseDrService.pageStatistics(searchParams).then(({retData:statistics})=>{
            setStatistics(statistics ?? initStatistics);
        }).catch(()=>{
            console.log('统计数据失败') 
        })
    }
    // const loadTotal = async (searchParams) => {
    //     let { retData: statistics } = await purchaseDrService.pageStatistics(searchParams);
    //     setStatistics(statistics ?? initStatistics);
    // }

    //导出
    const exportPurchaseDr = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        const searchParams = {
            ...searchValue,
            // shopCode,
            branchIn:'ci',
            // shopId: searchValue.shopId ?? shopId,
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            pageSize: -1,
            pageNum: 1,
            id: selectedRowKeys.map(item => item.id).join(),
            status:searchValue.status?searchValue.status.toString():null,
        }
        let result = await purchaseDrService.export(searchParams);
        convertRes2Blob(result);
        setLoading({ spinning: false });
        return result;
    }

    //导出明细
    const exportPDrdetail = useLockFn(async (code) => {
        setLoading({ tip: "明细导出中", spinning: true });
        let result = await purchaseDrService.exportDetail({
            // shopId
            shopId: searchParam.shopId ? searchParam.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
        });
        convertRes2Blob(result);
        setLoading({ spinning: false });
    })
    return {
        /***field***/
        searchParam, organType, shopId, organTypeCode,
        dataSource,
        selectedRowKeys,
        pgCfg,
        statistics,
        loading,
        rowsDescTotal, 
        /***method***/
        setRowsDescTotal,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportPurchaseDr,
        exportPDrdetail
    }
});