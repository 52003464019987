import React, { useState, useEffect } from "react"
import { message, Button, Upload, Tabs, Empty, Modal } from "antd"
import styled from "@emotion/styled"
import { getVehicleAttrList, insertVehicleeAttr, deleteVehicleeAttrOne, editVehicleeAttrName } from "@/service/customerService"
import { getToken } from "@/utils/dataStorage"
import { fileLookUrl, fileUploadUrl } from "@/config/app"
import Input from "antd/lib/input/Input"

export default (props) => {
    const { vehicleId, mode, } = props

    const [attrList, setAttrList] = useState([])

    useEffect(() => {
        if (vehicleId) {
            getAttrList()
        }
    }, [vehicleId])

    const getAttrList = () => {
        getVehicleAttrList(vehicleId).then((res) => {
            let list = res.retData || []
            list.sort((a, b) => {
                if (!a.id) {
                    a.componentSort = Infinity
                }
                if (!b.id) {
                    b.componentSort = Infinity
                }
                return a.componentSort - b.componentSort
            })
            setAttrList(list)
        })
    }

    const handleChange = async (info, item) => {
        if (info.file.status === "done") {
            const { type, response, name } = info.file
            const { templateId, attrName } = item
            const data = {
                attrFileMd5: response.retData,
                attrFileName: name,
                attrFileType: type.includes("image") ? "img" : "file",
                templateId,
                attrName,
            }
            insertAttr(data)
        }
    }

    const insertAttr = (attrData) => {
        insertVehicleeAttr({ ...attrData, vehicleId }).then(() => {
            getAttrList()
        })
    }

    const handleResetName = (attr) => {
        let name = ""
        Modal.confirm({
            title: "请输入附件的名字",
            content: (
                <Input
                    defaultValue={attr.attrFileName}
                    onChange={(e) => {
                        name = e.target.value
                    }}
                ></Input>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (name) {
                        resolve(name)
                    } else {
                        message.warning("请输入附件的名字")
                        reject("请输入附件的名字")
                    }
                }).then((res) => {
                    //新的名字re s
                    console.log(res)
                    editVehicleeAttrName({ attrFileNam: res, id: attr.id }).then((res) => {
                        getAttrList()
                    })
                })
            },
            onCancel: () => {},
        })
    }

    const handleDelete = (attr) => {
        Modal.confirm({
            content: `确定要删附件 - ${attr.attrFileName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                deleteVehicleeAttrOne(attr.id).then((res) => {
                    message.success("删除成功")
                    getAttrList()
                })
            },
        })
    }

    const handleLook = (attr) => {
        const { attrFileMd5, attrFileType, attrFileName } = attr
        if (attrFileType === "img") {
            Modal.info({
                title: attrFileName,
                content: <img style={{ width: "100%" }} src={fileLookUrl().image + attrFileMd5} />,
                okText: "确定",
                cancelText: "取消",
                centered: true,
                width: 800,
            })
        } else {
            window.open(fileLookUrl().image + attrFileMd5)
        }
    }

    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    return (
        <Box>
            <div className="type">其他附件</div>
            <Tabs>
                {attrList.map((item, index) => {
                    return (
                        <Tabs.TabPane tab={item.attrName}  key={index}>
                            <Upload
                                disabled={isLook(mode)}
                                action={`${fileUploadUrl}upload/image_old`}
                                headers={{ Authorization: getToken() }}
                                onChange={(file) => {
                                    handleChange(file, item)
                                }}
                                showUploadList={false}
                            >
                                <Button>上传{item.attrName}</Button>
                            </Upload>
                            {item.attrList ? (
                                item.attrList.map((attr, i) => {
                                    return (
                                        <AttrRow  key={i}>
                                            <AttrFileName
                                                onClick={() => {
                                                    handleLook(attr)
                                                }}
                                            >
                                                {attr.attrFileName}
                                            </AttrFileName>
                                            <AttrSet>
                                                <span
                                                    className="set"
                                                    onClick={() => {
                                                        handleResetName(attr)
                                                    }}
                                                >
                                                    重命名
                                                </span>
                                                <span
                                                    className="set"
                                                    onClick={() => {
                                                        handleDelete(attr)
                                                    }}
                                                >
                                                    删除
                                                </span>
                                            </AttrSet>
                                        </AttrRow>
                                    )
                                })
                            ) : (
                                <Empty />
                            )}
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin: 20px 0;
    min-height: 300px;
    .type {
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
    }
`
const AttrRow = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f0f0f0;
    :hover {
        background-color: rgba(#000, 0.1);
    }
`

const AttrFileName = styled.div`
    flex: 1;
    cursor: pointer;
    color: #2e3aeb;
`

const AttrSet = styled.div`
    .set {
        cursor: pointer;
        margin: 0 10px;
    }
`
