import React from "react"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useProductModel } from "../productModel"
export function ProductPageHeader(props) {
   const { upOrgDs,detailSearchParam, getProductDetail, updateDetailSearchParam, resetDetailSearchParam } = useProductModel()
   const { getSelectedDictOne } = useDictModel()
   const {modeKey, mode, rowId, dispatch} = props
   const searchFields = [
            {
                type: "Select",
                placeholder: "上架状态",
                fieldName: "upStatus",
                options:getSelectedDictOne(DictCodeEnmu.DISPLAY_STATUS),
            },
            {
                type: "Select",
                placeholder: "品牌",
                fieldName: "brandCode",
                options: getSelectedDictOne(DictCodeEnmu.MATERIAL_BRAND),
            },
            {
                type: "Select",
                placeholder: "所属机构",
                fieldName: "shopName",
                options: upOrgDs,
                showSearch:true
            },
        ]

    return (
        <YhPageHeader
            title="商品明细查看"
            size="small"
            justify="end"
            triggerSearch={true}
            searchValues={detailSearchParam}
            searchFields={searchFields}
            onChange={updateDetailSearchParam}
            handleSearch={(params) => { getProductDetail({ pageNum: 1 }, { ...detailSearchParam, ...params, code:rowId }) }}
            handleReset={() =>{resetDetailSearchParam(rowId)}}
        />
    )
}
