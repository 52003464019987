import React, { useState, useEffect, useRef } from "react"
import { Button, Table, message, Modal, Input, Select, Switch } from "antd"
import { newGetList, deleteUser, editCommonlyUsedSenderRepairUser } from "@/service/customerService"
import { getPcodeDict } from "@/utils/dataStorage"
import UserPopupDetail from "@/pages/customerManage/userRecord/userPopupDetail"
import MoveUser from '@/pages/customerManage/userRecord/moveUser'
const { Option } = Select
const UserList = (props) => {
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [dictObj, setDictObj] = useState(null)
    const { customerInfo, setUserNum } = props
    const [userData, setUserData] = useState(null) //列表‘
    const order = useRef({ column: "updateTime", rule: "DESC" })
    const [customerData, setCustomerData] = useState({})
    const [moveUserData, setMoveUserData] = useState([])
    const [moveUserVisible, setMoveUserVisible] = useState(false)
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    useEffect(() => {
        initDict()
        loadData(1)
    }, [])
    //table配置
    //新增，编辑
    const handleDetails = (detailsmode) => {
        if (detailsmode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看或编辑的账户信息")
                return
            }
        }
    }

    //默认加载
    const loadData = (current) => {
        let data = {
            searchItem: {
                customerId: customerInfo.id,
            },
            pageNum: current,
            pageSize: 10,
            orders: [order.current],
        }
        newGetList(data)
            .then((res) => {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setUserNum(res.retData.total)
                setUserData(res.retData.list)
            })
            .catch((err) => {
                setUserData([])
            })
    }
    //分页事件
    // const onTableChange = async (page) => {
    //     await setpagination({
    //         ...pagination,
    //         current: page.current,
    //     })
    //     loadData(page.current)
    // }

     //分页事件 置空当前行信息
     const onTableChange = async (page, filters, sorter) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        let orderC = { column: "updateTime", rule: "DESC" }
        if (sorter && sorter.field && sorter.order) {
            let rule = sorter.order === "ascend" ? "ASC" : "DESC"
            orderC = {
                column: sorter.field,
                rule,
            }
        }
        order.current = orderC
        loadData(page.current)
    }

    const commonlyUsedSenderRepairUserChange = (e, record) => {
        editCommonlyUsedSenderRepairUser({userId: record.id,commonlyUsedSenderRepairUser:Number(e)}).then(res => {
            message.success("设置成功")
            loadData(pagination.current)
        })
    }

    //初始化数据字典
    const initDict = () => {
        let obj = {
            userRole: {}, //用户角色
        }
        getPcodeDict("021").children.forEach((item) => {
            obj.userRole[item.dictCode] = item.title
        })
        setDictObj(obj)
    }

    const handleUserStatus = (mode) => {
        if (mode !== "insert") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看或编辑的账户信息")
                return
            }
        }
        setShowVisible(true)
        setShowMode(mode)
    }

    const columns = [
        {
            title: "用户名",
            align: "center",
            dataIndex: "userName",
        },
        {
            title: "用户手机",
            align: "center",
            dataIndex: "userMobile",
        },
        {
            title: "真实姓名",
            align: "center",
            dataIndex: "realName",
        },
        {
            title: "用户角色",
            align: "center",
            dataIndex: "userRole",
            render: (text) => <>{dictObj.userRole[text]}</>,
        },
        // {
        //     title: "业务员",
        //     align: "center",
        //     dataIndex: "createTime",
        // },
        {
            title: "默认送修人",
            align: "center",
            width: "10%",
            dataIndex: "commonlyUsedSenderRepairUser",
            render: (text, record) => {
                return <Switch checked={!!text} size="small" onChange={(e) => {commonlyUsedSenderRepairUserChange(e,record)}} />
            },
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
            sorter: true,
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
        },
        {
            title: "用户状态",
            align: "center",
            width: "10%",
            dataIndex: "userStatus",
            render: (text, record) => {
                return <Switch checked={text == 0 ? true : false} size="small" disabled />
            },
        },
        {
            title: '操作',
            align: 'center',
            render: (text, r) => (
                <span
                    style={{ color: '#2e3aeb', cursor: 'pointer', marginLeft: '10px' }}
                    onClick={() => {
                        console.log(r)
                        const { customerId, customerName } = r
                        setCustomerData({ id: customerId, name: customerName })
                        setMoveUserData([r])
                        setMoveUserVisible(true)
                    }}
                >
                    用户转移
                </span>
            ),
        },
    ]

     //删除
     const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的用户信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除用户 - ${selectInfo.rowInfo.userName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                deleteUser(selectInfo.rowInfo.id).then((res) => {
                        message.success("删除成功")
                        loadData(pagination.current)
                })
            },
        })
    }

    return (
        <>
            <div>
                <div className="list-header" style={{ marginTop: "-10px" }}>
                    <div className="list-but">
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus("insert")}>
                            新增
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus("look")}>
                            查看
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleUserStatus("edit")}>
                            {" "}
                            编辑
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => handleDelete()}>
                            {" "}
                            删除
                        </Button>
                        <Button shape="round" className="but-left" onClick={() => {
                            setCustomerData({ id: customerInfo.id, name: customerInfo.customerName })
                            setMoveUserVisible(true)
                        }}>
                            {" "}
                            用户转移
                        </Button>
                    </div>
                </div>
                <Table
                    size="small"
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={userData}
                    // loading={loading}
                    onChange={onTableChange}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: false,
                        showTotal: () => `共${pagination.total}条`,
                        position: ["bottomCenter"],
                        ...pagination,
                    }}
                    onRow={(record, index) => ({
                        onClick: () => setSelectInfo({ rowInfo: record, rowIndex: index }),
                    })}
                    rowClassName={(record, index) => (index === selectInfo.rowIndex ? "dark-row" : "")}
                />
            </div>
            {showVisible && (
                <UserPopupDetail
                    rowInfo={selectInfo.rowInfo}
                    visible={showVisible}
                    mode={showMode}
                    setVisible={setShowVisible}
                    customerData={{ customerId: customerInfo.id, customerName: customerInfo.customerName }}
                    onSuccess={() => {
                        loadData(1)
                    }}
                />
            )}
            <MoveUser visible={moveUserVisible} setVisible={setMoveUserVisible} customerData={customerData} userData={moveUserData} onSuccess={() => {loadData(1)}} />
        </>
    )
}
export default UserList
