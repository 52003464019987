import React from "react";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import { useInternalDetailsModel } from "./internalDetailsModel";

export const internalStatusOption = [
    { label: "待申请", value: 0 },
    { label: "待对方确认", value: 1 },
    { label: "对方不认可", value: 2 },
    { label: "待财务确认", value: 3 },
    { label: "对方已驳回", value: 4 },
    { label: "待财务审核", value: 5 },
    { label: "财务已驳回", value: 6 },
    { label: "待最终审核", value: 7 },
    { label: "最终审核通过", value: 8 },
    { label: "最终已驳回", value: 9 },
]

export const TopButs = ({ id, mode }) => {
    const { editFormVal: { code, status } } = useInternalDetailsModel();

    const orderStatu = [
        {
            label: "内部对账单号",
            value: code ?? "",
            color: 'blue',
            status: true
        },
        {
            label: "状态",
            value: internalStatusOption.find(item => item.value === status)?.label ?? "",
            color: 'green',
            status: true
        },
    ]

    return (
        <YhBox title={<>
            {orderStatu.map((item, index) => <Description key={index} {...item} />)}
        </>}
            hidden={mode === "edit" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}