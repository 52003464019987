import React, { useContext, useState } from "react";
import { Button, message, Modal, Upload, Spin } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { DataTable } from './DataTable'
import { MyContext } from "@/store/myContext";
import { useContractModel } from '../contractParticularsModel'
import { importPropsConfigc } from "@/erp_subpackage/utils/importFile";
import { contractService } from '../contractParticularsServer'
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";

export const DataBox = () => {
    const { selectedRowKeys, onExport, loadData } = useContractModel()
    const { state, dispatch, detailsPage } = useContext(MyContext);
    const [uploading, setUploading] = useState({ spinning: false, tip: "加速上传中..." });
    const goPageNav = (mode, rowCode) => {
        let tabName = {
            add: "合约管理新增",
            edit: "合约管理编辑",
            look: "合约管理查看",
        }
        let modeKey = "contractParticulars/" + mode;

        //动态传入要删除的nav key
        const handleDispatch = (obj = {}) => {
            dispatch({
                ...obj,
                type: "changeTabs",
                name: tabName[mode],
                activeMenu: modeKey,
                content: detailsPage.ContractlDetails({
                    modeKey,
                    mode,
                    dispatch,
                    rowCode: rowCode ?? selectedRowKeys[0]
                }),
            })
        }

        if (mode === "edit") {
            const findNav = state.panes.find(item => item.key === modeKey);
            if (findNav) {
                Modal.confirm({
                    title: '温馨提醒！',
                    content: "您还有采购订单详情未保存，是否跳转",
                    okText: '跳转未保存页',
                    cancelText: '打开新页签',
                    onOk: () => {
                        handleDispatch()
                    },
                    //打开新页签重新生成
                    onCancel: () => {
                        handleDispatch({ deleteKey: modeKey })
                    }
                });
                return;
            }
            handleDispatch()
        }
        else if (mode === "add") {
            handleDispatch()
        }
        else if (mode === "look") {
            handleDispatch({ deleteKey: modeKey })
        }
    }


    const uploadCallback = () => {
        setUploading({ spinning: false })
        loadData()
    }

    //下载模板
    const downloadTpl = async () => {
        let result = await contractService.downloadTpl();
        convertRes2Blob(result)
        return result;
    }

    const action = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                goPageNav("add")
            }}
        >新增</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return false
                }
                goPageNav("edit")
            }}

        >编辑</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => {
                if (selectedRowKeys.length !== 1) {
                    message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！")
                    return false
                }
                goPageNav("look")
            }}
        >查看</Button>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => { onExport() }}
        >导出</Button>
        <Upload showUploadList={false} {...importPropsConfigc(`/ci-customer/sign_info/import`, uploadCallback, {}, () => setUploading({ spinning: true, tip: "加速上传中..." }))} >
            <Button
                className="btn-item"
                shape="round"
            >导入</Button>
        </Upload>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => downloadTpl()}
        >下载模板</Button>

    </>;

    return (
        <Spin {...uploading}>
            <YhBox title="合约管理" action={action}>
                <DataTable goPageNav={goPageNav} />
            </YhBox>
        </Spin>
    )
}