import React, {  useEffect, useState } from "react";
import { Table,Drawer} from "antd";
import {useContractModel} from '../contractParticularsModel'
import {usecontractDetailsModel} from './detailsModel'


export  const LogDrawer=({mode,rowCode})=> {
const  {logdatasource} =usecontractDetailsModel()
const {logVisable,setLogVisable}=useContractModel()



const columns=[
    {
        title: '车辆牌照',
        width: 80,
        dataIndex: 'vehicleCode',
    },
    {
        title: '操作对象',
        width: 80,
        dataIndex: 'operateName',
    },
     {
        title: '操作类型',
        width: 80,
        dataIndex: 'type',
    },
    {
        title: '操作结果',
        width: 100,
        dataIndex: 'result',
        
    },
    {
        title: '操作内容',
        width: 160,
        dataIndex: 'operateContent',
        
    },
    {
        title: '操作人',
        width: 140,
        dataIndex: 'createUser',
        
    },
    {
        title: '操作时间',
        width: 140,
        dataIndex: 'createTime',
        
    },
]

const onCancel =()=>{
    setLogVisable(false)
}



return (
    <Drawer 
    title='操作日志'
    open={logVisable}
    onClose={onCancel}
    width={800}
> 
 
    <div style={{ marginTop: "10px"}}>
    <Table
    rowKey={record => record.vehicleId}
    columns={columns}  
    dataSource={logdatasource}
    scroll={{ y: 600 }} 
    />
    </div>

</Drawer >
    
)
}