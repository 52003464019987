import React, { useEffect, useContext } from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { MyContext } from "@/store/myContext";

export default (props) => {
    const { state } = useContext(MyContext);

    return (
        <>
            {state.activeMenu === "baseVehicle" && <>
                <PageHeader />
                <DataBox {...props} />
            </>}
        </>
    )
}