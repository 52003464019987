import React from "react";
import { Button } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { usePurchasePlanDetailsModel } from "./purchasePlanDetailModel";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { DictCodeEnmu } from "../../../../../utils/DictCodeEnum";

export const TopButs = ({ modeKey, mode, rowCode, dispatch }) => {
    const { editFormVal, lookFormVal, editDesc, controlled, TransferPo } = usePurchasePlanDetailsModel();
    const { getTreeNodeName } = useDictModel(() => []);
    const formVal = mode === "edit" ? editFormVal : lookFormVal;

    const action = mode === "edit" ? <>
        <Button
            className="btn-item"
            shape="round"
            disabled={controlled || editDesc.unarrivedNumAll === 0}
            onClick={() => TransferPo().then(res => {
                res && dispatch({
                    type: "removeTabs",
                    targetKey: modeKey,
                    activeMenu: "purchasePo",
                })
            })}
        >转采购单</Button>
    </> : null;

    const orderStatus = [
        {
            label: "采购计划号",
            value: formVal.planCode ?? "暂无",
            color: "blue",
            status: true
        },
        {
            label: "状态",
            value: getTreeNodeName(formVal.status) ?? "暂无",
            color: "green",
            status: true
        }
    ]

    return (
        <YhBox title={orderStatus.map((item, index) => <Description key={index} {...item} />)}
            style={{ padding: "0 20px", marginBottom: "10px" }}
            action={action}
        />
    )
}