import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";

// export interface IStockStatisticFields {
//     availableStock?: number,    // 0,
//     averageAmount?: number,    // 0,
//     averagePurchasePrice?: number,    // 0,
//     brandCode?: string,    // "",
//     brandName?: string,    // "",
//     id?: string,    // "",
//     inventoryInitial?: number,    // 0,
//     inventoryNum?: number,    // 0,
//     inventoryOccupied?: number,    // 0,
//     lateSalePrice?: number,    // 0,
//     latestAmount?: number,    // 0,
//     latestPurPrice?: number,    // 0,
//     limitLower?: number,    // 0,
//     limitUpper?: number,    // 0,
//     madeFrom?: string,    // "",
//     materialCode?: string,    // "",
//     materialName?: string,    // "",
//     model?: string,    // "",
//     nameEn?: string,    // "",
//     purchasePrice?: number,    // 0,
//     replaceCode?: string,    // "",
//     retailPrice?: number,    // 0, 
//     shelfCode?: string,    // "",
//     shelfId?: string,    // "",
//     typeCode?: string,    // "",
//     typeName?: string,    // "",
//     unitCode?: string,    // "",
//     unitName?: string,    // "",
//     warehouseCode?: string,    // "",
//     warehouseName?: string,    // ""
//     [key: string]: any

// }

class StockStatisticService {
    //库存查询
    async page(searchVo) {
        const apiUrl = "/ci-storage/stock/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //分页查询
    async pages(searchVo) {
        const apiUrl = "/ci-storage/stock/findPages";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //采购明细查询
    async findPage(searchVo) {
        const apiUrl = "/ci-storage/stock/details/findPage";
        return await httpRequest.post(apiUrl, searchVo);
    }
    //采购明细单条单据对应价格查询
    async findId(stockId) {
        const apiUrl = `/ci-storage/stock/details/findId/${stockId}`;
        return await httpRequest.get(apiUrl);
    }
    //根据ID查询
    async one(id) {
        const apiUrl = `/ci-storage/purchase/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }
    //导出
    async export(params) {
        const apiUrl = "/ci-analyze/stock/statistics/export";
        return await httpRequest.post(apiUrl, params);
    }
    //占用明细查看
    async occupy(searchVo) {
        const apiUrl = `/ci-storage/statistics/purchaseSaleStorageDeatil`;
        return await httpRequest.post(apiUrl, searchVo);
    }
    //移库
    async relocation(searchVo) {
        const apiUrl = `/ci-storage/stock/relocation`;
        return await httpRequest.post(apiUrl, searchVo);
    }
    //移库记录查看
    async records(searchVo) {
        const apiUrl = `/ci-storage/records/page`;
        return await httpRequest.post(apiUrl, searchVo);
    }
    //移库还原接口
    async reduction(id) {
        const apiUrl = `/ci-storage/records/reduction/${id}`;
        return await httpRequest.get(apiUrl);
    }
}

export const stockStatisticService = new StockStatisticService();