import React, { useEffect, useState, useRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Form, Input, Col, Row, Select, message, Modal, Button, Upload, Rate, Spin, Radio, Tag, Tooltip } from 'antd'
import { storeInsert, storeUpdate, storeOne } from '@/service/storeManagement'
import InsertAddressMap from '@/components/TMapChoose' //地图
import { fileUploadUrl, fileLookUrl } from '@/config/app.js'
import { getToken } from '@/utils/dataStorage'
import { organPage } from '@/service/orgService'
import { getTeamList } from '@/service/teamService'
import debounce from 'lodash/debounce'

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [org, setOrg] = useState([])
    const [mapVisible, setMapVisible] = useState(false) //地图

    //缩略图
    const [storeThumbnailFileList, setstoreThumbnailFileList] = useState({
        file: [],
        md5: '',
    })

    //轮播图
    const [imgs, setImgs] = useState({
        file: [],
        md5: '',
    })

    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: '',
    })

    const [teamData, setTeamData] = useState({
        //团队信息
        data: [],
        fetching: false,
    })

    const [tags, setTags] = useState([])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [editInputIndex, setEditInputIndex] = useState(-1)
    const [editInputValue, setEditInputValue] = useState('')
    const inputRef = useRef(null)
    const editInputRef = useRef(null)
    useEffect(() => {
        if (inputVisible) {
            inputRef.current && inputRef.current.focus()
        }
    }, [inputVisible])
    useEffect(() => {
        editInputRef.current && editInputRef.current.focus()
    }, [inputValue])

    useEffect(() => {
        queryMotorcade()
        organPage({
            searchItem: { orderFlag: 0 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: 'createTime', rule: 'DESC' }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === 'edit') {
            storeOne(rowInfo.id).then((res) => {
                let results = res.retData
                console.log(results)
                let { storeImage: imgres, storeThumbnail, teamId, teamName, storeLabel } = results
                let fileList = [],
                    file = []
                //缩略图
                if (storeThumbnail) {
                    //回显图片， 有的话在做回显
                    fileList = [
                        {
                            uid: '-1',
                            status: 'done',
                            url: `${fileLookUrl().image}${storeThumbnail}`,
                        },
                    ]
                    setstoreThumbnailFileList({
                        file: fileList,
                        md5: storeThumbnail,
                    })
                }

                if (imgres) {
                    file = imgres.split(',').map((item, index) => {
                        return {
                            uid: index,
                            status: 'done',
                            url: `${fileLookUrl().image}${item}`,
                            response: {
                                retData: item,
                            },
                        }
                    })
                    setImgs({ file, md5: imgres })
                }

                if (teamId && teamName) {
                    setTeamData({
                        //团队信息
                        data: [{ text: teamName, value: teamId }],
                        fetching: false,
                    })
                }

                if (storeLabel) {
                    setTags(storeLabel.split(','))
                }

                form.setFieldsValue({
                    ...results,
                    storeThumbnail: fileList,
                    storeImage: file,
                })
            })
        } else {
            form.setFieldsValue({
                storeGrade: 5,
                display: 1,
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                storeThumbnail: storeThumbnailFileList.md5,
                storeImage: imgs.md5,
                storeLabel: tags.join(','),
            }
            console.log(data)
            if (mode === 'edit') {
                data.id = rowInfo.id
                storeUpdate(data).then((res) => {
                    message.success('编辑成功')
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                storeInsert(data).then((res) => {
                    message.success('编辑成功')
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    //团队信息查询
    const fetchTeam = (value) => {
        const organId = form.getFieldValue('organId')
        console.log(organId)
        setTeamData({
            ...teamData,
            fetching: true,
        })
        let data = {
            searchItem: { disableFlag: 0, teamName: value, organId },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: 'createTime',
                    rule: 'DESC',
                },
            ],
        }
        getTeamList(data).then((res) => {
            // if (res && res.retCode === 200) {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.teamName,
                value: team.id,
            }))
            setTeamData({
                data: dataMap,
                fetching: false,
            })
            // }
        })
    }
    //防抖
    const fetchTeams = debounce(fetchTeam, 1000)

    //选择的团队
    const handleChangeTeam = (value, option) => {
        form.setFieldsValue({
            teamName: value ? option.children : undefined,
        })
    }

    //选择机构
    const handleChangeOrgan = (value, option) => {
        form.setFieldsValue({
            organName: value ? option.children : undefined,
            teamId: '',
            teamName: '',
        })
        setTeamData({
            data: [],
            fetching: false,
        })
        console.log(form.getFieldsValue('teamId'))
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    // 图片上传
    const handleImgChange = async (info) => {
        setstoreThumbnailFileList([])
        if (info.file.status === 'done') {
            let md5 = info?.file?.response?.retData
            setstoreThumbnailFileList({ file: info.fileList, md5: md5 })
        }
    }

    const handleImgsChange = async (info) => {
        if (info.file.status === 'done' || info.file.status === 'removed') {
            let file = info.fileList
            let md5 = file.map((item) => {
                console.log(item)
                return item.response.retData
            })
            md5 = md5.join(',')
            setImgs({
                file,
                md5,
            })
        }
    }

    const handleCancel = () => {
        setVisible('')
    }

    //地图选择保存
    const saveAddressDetail = (results) => {
        const {
            result: {
                address_component: { province: storeProvince, city: storeCity, district: storeDistrict },
            },
            addr: storeAddress,
            latitude: storeLat,
            longitude: storeLng,
        } = results

        form.setFieldsValue({
            storeAddress,
            storeLng,
            storeLat,
            storeProvince,
            storeCity,
            storeDistrict,
        })
        setMapVisible(false)
    }

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag)
        console.log(newTags)
        setTags(newTags)
    }

    const showInput = () => {
        setInputVisible(true)
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }

        setInputVisible(false)
        setInputValue('')
    }

    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value)
    }

    const handleEditInputConfirm = () => {
        const newTags = [...tags]
        newTags[editInputIndex] = editInputValue
        setTags(newTags)
        setEditInputIndex(-1)
        setInputValue('')
    }

    return (
        <>
            <Modal forceRender visible={visible} title="门店" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1200}>
                <div className="form-details-warp _chooseMapBox">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item label="门店名称" name="storeName" rules={[{ required: true, message: '请输入门店名称' }]}>
                                    <Input placeholder="名称" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="门店电话" name="storePhone" rules={[{ required: true, message: '请输入门店电话' }]}>
                                    <Input placeholder="电话" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 21 }}
                                    label="门店地址"
                                    name="storeAddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请通过地图来获取地址',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="通过地图实时位置获取地址"
                                        disabled
                                        addonAfter={
                                            <Button style={{ borderRadius: '0 8px 8px 0' }} className="map-but" onClick={() => setMapVisible(true)}>
                                                获取位置
                                            </Button>
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="缩略图"
                                    name="storeThumbnail"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请上传图片',
                                        },
                                    ]}
                                >
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={storeThumbnailFileList.file}
                                        onPreview={(file) => {
                                            const md5 = file?.response?.retData || file?.url.split('/image/')[1]
                                            setpreviewImage({ visible: true, md5 })
                                        }}
                                        onChange={handleImgChange}
                                    >
                                        {storeThumbnailFileList.file && storeThumbnailFileList.file.length >= 1 ? null : (
                                            <Button size="small">上传缩略图</Button>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="等级" name="storeGrade">
                                    <Rate allowHalf />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="关联机构" name="organId">
                                    <Select placeholder="请选择所属机构" style={{ width: '100%' }} onChange={handleChangeOrgan}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.organName}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="关联团队" name="teamId">
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="请选择创业团队"
                                        notFoundContent={teamData.fetching ? <Spin size="small" /> : null}
                                        optionFilterProp="children"
                                        onChange={handleChangeTeam}
                                        onFocus={() => fetchTeam()}
                                        style={{ width: '100%' }}
                                        onSearch={fetchTeams}
                                        // filterOption={false}
                                    >
                                        {teamData.data.map((item) => (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 21 }}
                                    label="轮播图"
                                    name="storeImage"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                >
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={imgs.file}
                                        onPreview={(file) => {
                                            const md5 = file?.response?.retData || file?.url.split('/image/')[1]
                                            setpreviewImage({ visible: true, md5 })
                                        }}
                                        onChange={handleImgsChange}
                                    >
                                        {imgs.file.length >= 9 ? null : <Button>上传照片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <div style={{ textAlign: 'right' }}>标签:</div>
                            </Col>
                            <Col span={9}>
                                {tags.map((tag, index) => {
                                    if (editInputIndex === index) {
                                        return (
                                            <Input
                                                ref={editInputRef}
                                                key={tag}
                                                size="small"
                                                className="tag-input"
                                                value={editInputValue}
                                                onChange={handleEditInputChange}
                                                onBlur={handleEditInputConfirm}
                                                onPressEnter={handleEditInputConfirm}
                                            />
                                        )
                                    }

                                    const isLongTag = tag.length > 20
                                    const tagElem = (
                                        <Tag className="edit-tag" key={tag} closable={true} onClose={() => handleClose(tag)}>
                                            <span
                                                onDoubleClick={(e) => {
                                                    if (index !== 0) {
                                                        setEditInputIndex(index)
                                                        setEditInputValue(tag)
                                                        e.preventDefault()
                                                    }
                                                }}
                                            >
                                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                            </span>
                                        </Tag>
                                    )
                                    return isLongTag ? (
                                        <Tooltip title={tag} key={tag}>
                                            {tagElem}
                                        </Tooltip>
                                    ) : (
                                        tagElem
                                    )
                                })}
                                {inputVisible && (
                                    <Input
                                        ref={inputRef}
                                        type="text"
                                        size="small"
                                        className="tag-input"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onBlur={handleInputConfirm}
                                        onPressEnter={handleInputConfirm}
                                    />
                                )}
                                {!inputVisible && (
                                    <Tag className="site-tag-plus" onClick={showInput}>
                                        <PlusOutlined />
                                        添加
                                    </Tag>
                                )}
                                {/* </Form.Item> */}
                            </Col>
                            <Col span={12}>
                                <Form.Item label="是否显示" name="display">
                                    <Radio.Group>
                                        <Radio value={1}>是</Radio>
                                        <Radio value={0}>否</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Form.Item label="经度" name="storeLng" hidden>
                                <Input placeholder="经度" />
                            </Form.Item>
                            <Form.Item label="纬度" name="storeLat" hidden>
                                <Input placeholder="纬度" />
                            </Form.Item>
                            <Form.Item label="省" name="storeProvince" hidden>
                                <Input placeholder="省" />
                            </Form.Item>
                            <Form.Item label="市" name="storeCity" hidden>
                                <Input placeholder="市" />
                            </Form.Item>
                            <Form.Item label="区" name="storeDistrict" hidden>
                                <Input placeholder="区" />
                            </Form.Item>
                            <Form.Item label="机构名称" name="organName" hidden>
                                <Input placeholder="机构名称" />
                            </Form.Item>
                            <Form.Item label="团队名称" name="teamName" hidden>
                                <Input placeholder="团队名称" />
                            </Form.Item>
                        </Row>
                    </Form>
                    {mapVisible && (
                        <InsertAddressMap
                            visible={mapVisible}
                            onCloseMap={() => setMapVisible(false)}
                            saveAddressDetail={saveAddressDetail}
                            defaultInfo={{
                                lat: form.getFieldsValue().storeLat,
                                lng: form.getFieldsValue().storeLng,
                            }}
                        />
                    )}

                    <Modal visible={previewImage.visible} title="图片查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                        <img alt="" style={{ width: '100%' }} src={`${fileLookUrl().image}${previewImage.md5}`} />
                    </Modal>
                </div>
            </Modal>
        </>
    )
}
