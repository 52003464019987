import React, { useEffect, useState, useRef } from "react"
import { Form, Input, Col, Row, message, Modal, InputNumber } from "antd"
import { combinationInsert, combinationUpdate, combinationOne } from "@/service/dataService"
import Material from "./material"
import Maintenance from "./maintenance"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()

    const maintenanceRef = useRef() //维修项目 子组件
    const materialRef = useRef() //维修物料  子组件

    const [itemList, setItemList] = useState([])
    const [maintainMaterialList, setMaintainMaterialList] = useState([])

    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            combinationOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                })
                setItemList(results.itemList || [])
                setMaintainMaterialList(results.materialList || [])
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
            }
            data.items = maintenanceRef.current.upData().map((item) => ({
                id: item.id,
                itemId: item.itemId,
                remark: item.remark
            }))

            data.materials = materialRef.current.upData().map((item) => ({
                id: item.id,
                materialId: item.materialId,
                materialNumber: item.materialNumber,
                brandName: item.brandName,
                remark: item.remark
            }))
            if (mode === "edit") {
                data.id = rowInfo.id
                combinationUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                combinationInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender visible={visible} title="维保组合" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1200}>
                <div className="list-container">
                    <div className="form-details-warp" style={{ padding: "30px 10px 10px 10px" }}>
                        <Form form={form} {...formItemLayout}>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Form.Item label="组合名称" name="name" rules={[{ required: true, message: "请输入分类名称" }]}>
                                        <Input placeholder="组合名称" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="间隔里程" name="intervalMileage">
                                        <InputNumber placeholder="间隔里程"  style={{width: '100%'}} addonAfter="万公里"  precision={0}/>
                                    </Form.Item>
                                </Col>
                                {/* <Col span={12}>
                                    <Form.Item label="预警里程" name="earlyWarningMileage">
                                        <InputNumber placeholder="预警里程"  style={{width: '100%'}}  addonAfter="万公里"  precision={0}/>
                                    </Form.Item>
                                </Col> */}
                                <Col span={12}>
                                    <Form.Item label="工时费用" name="itemAmount">
                                        <InputNumber placeholder="工时费用"  style={{width: '100%'}}  precision={2}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>

                <Maintenance list={itemList} maintenanceRef={maintenanceRef} />
                <Material list={maintainMaterialList} materialRef={materialRef} />
            </Modal>
        </>
    )
}
