import React, { useState, useEffect, useRef, useContext } from "react"
import { Form, Input, InputNumber } from "antd"
import moment from "moment"
const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef()
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            if (inputRef && inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        if(dataIndex === "repairTime"){
            form.setFieldsValue({
                [dataIndex]: moment(record[dataIndex] || new Date()),
            })
        }else{
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            })
        }
    }

    const save = async (e) => {
        try {
            const values = await form.validateFields()
            toggleEdit()
            handleSave({ ...record, ...values })
        } catch (errInfo) {
            console.log("数据保存失败", errInfo)
        }
    }
    const inputTypes = (str, record) => {
        if (str === "itemDiscount") {
            return <InputNumber ref={inputRef} min={1} max={10} step={1} onBlur={save} />
        } else if (str === "unitPrice" ) {
            return <InputNumber ref={inputRef} min={0} formatter={(value) => `¥${value}`} parser={(value) => value.replace("¥", "")} onBlur={save} />
        }else {
            return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        }
    }
    const inputRules = (str) => {
        if ( str === "unitPrice" || str === "itemNumber") {
            return [
                {
                    required: true,
                    message: `数值不可为空`,
                },
                {
                    pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                    message: "请输入数字且可保留两位小数",
                },
            ]
        }
    }
    let childNode = children
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={inputRules(dataIndex)}
            >
                {inputTypes(dataIndex, record)}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

export { EditableRow, EditableCell }
