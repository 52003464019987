import React from "react"
import { Table, Button, Upload, Radio, Dropdown } from "antd"
import { API_SERVER_HOST, API_SERVER_PORT } from "@/config/app"
import { getToken } from "@/utils/dataStorage"
import { DownOutlined } from "@ant-design/icons";

/**
 * @param {String} title            //列表标题
 * @param {Array} buttons           //列表按钮组
 * @param {Array} columns           //table配置
 * @param {Array} dataSource        //table数据
 * @param {Object} pagination       //table分页配置
 * @param {Function} onTableChange  //table分页事件
 * @param {Object} selectInfo       //table行选信息
 * @param {Function} setSelectInfo  //table行选事件
 * @param {Boolean} loading         //table的Loading
 * @param {Object} scroll          //table的滚动条
 * @param {Object} rowSelection     //table的多选框
 * @param {Array} radioOptions     //用户商品订单的状态搜索
 * @param {Function} handleTableSearch  //用户商品订单选中回调
 */
export default ({
    title,
    buttons,
    columns,
    dataSource,
    pagination,
    onTableChange = null,
    selectInfo = null,
    setSelectInfo,
    loading = false,
    scroll = { x: 1800 },
    rowSelection = false,
    radioOptions = null,
    handleTableSearch = null,
    subTitle,
    rowKey = (record) => record.id
}) => {
    return (
        <div className="list-container">
            <div className="list-header">
                <div className="list-name">
                    <span>{title}</span>
                    {subTitle &&
                        subTitle.map((item, index) => (
                            <span key={index} style={{ color: "red", marginLeft: "20px", fontSize: "14px" }}>
                                {item}
                            </span>
                        ))}
                </div>
                <div className="list-but">
                    {buttons &&
                        buttons.map((item, index) =>
                            item ? (
                                item.upload ? (
                                    <Upload
                                        key={index}
                                        name="file"
                                        showUploadList={false}
                                        headers={{ authorization: getToken() }}
                                        action={item.action ?? `${API_SERVER_HOST}:${API_SERVER_PORT}/${item.actionUrl}`}
                                        data={item.data}
                                        onChange={item.onChange}
                                    >
                                        <Button shape="round" className="but-left">
                                            {item.content}
                                        </Button>
                                    </Upload>
                                ) : item.dropdown ? (
                                    item.ButtonType
                                ) : (
                                    <Button key={index} shape="round" className={["but-left", item.className ? item.className : ""]} onClick={item.click}>
                                        {item.content}
                                    </Button>
                                )
                            ) : (
                                ""
                            )
                        )}
                </div>
            </div>
            {radioOptions && <Radio.Group options={radioOptions} onChange={(e) => handleTableSearch(e)} defaultValue={"all"} optionType="button" />}
            <Table
                size="small"
                rowKey={rowKey}
                columns={columns}
                scroll={scroll}
                loading={loading}
                dataSource={dataSource}
                onChange={onTableChange}
                pagination={
                    pagination
                        ? {
                            showQuickJumper: true,
                            showSizeChanger: false,
                            showTotal: () => `共${pagination.total}条`,
                            position: ["bottomCenter"],
                            ...pagination,
                        }
                        : false
                }
                onRow={
                    selectInfo
                        ? (record, index) => ({
                            onClick: () =>
                                setSelectInfo({
                                    rowInfo: record,
                                    rowIndex: index,
                                }),
                        })
                        : null
                }
                rowClassName={selectInfo ? (record, index) => (index === selectInfo.rowIndex ? "dark-row" : "") : ""}
                rowSelection={rowSelection}
            />
        </div>
    )
}
