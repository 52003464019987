import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, message, Modal, } from "antd"
import { classificationInsert, classificationUpdate, classificationOne } from "@/service/dataService"
import { organPage } from "@/service/orgService"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()

    useEffect(() => {
        queryMotorcade()
    }, [])

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            classificationOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                    // disableFlag: results.disableFlag === 0 ? true : false,
                })
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                // disableFlag: values.disableFlag ? 1 : 2,
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                classificationUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                classificationInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    return (
        <>
            <Modal forceRender visible={visible} title="系统分类" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="分类名称" name="name" rules={[{ required: true, message: "请输入分类名称" }]}>
                                    <Input placeholder="分类名称" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item name="disableFlag" label="启用" valuePropName="checked">
                                    <Switch checkedChildren="是" unCheckedChildren="否" />
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
