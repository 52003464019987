import React, { useEffect, useState } from "react"
import { Modal, Button, message } from "antd"
import { orderPrintInfo, printNum } from "@/service/ordersService"
import { taskBookService } from "@/service/taskBookService"
import "./printCheckDetail.css"

const PrintCheckDetail = (props) => {
    const { visible, onClose, bookId, items } = props
    const [baseInfo, setBaseInfo] = useState({})
    const [checkDetail, setCheckDetail] = useState([])
    const [level, setLevel] = useState("");

    useEffect(() => {
        loadData();
        let levels = items.filter(e => e != "changeItems");
        setLevel(levels[0].replace("保养", ""));
    }, [])

    const loadData = () => {
        orderPrintInfo({ orderId: visible.orderId,printType:'normal' }).then((res) => {
            const {
                orderInfo
            } = res.retData
            setBaseInfo(orderInfo)
        })
        taskBookService.printCheckDetail({ "bookId": bookId, "items": items }).then((res) => {
            setCheckDetail(res.retData);
        })
    }

    //打印
    const print = async () => {
        if (!baseInfo.orderCode) {
            message.error("打印正在准备，请5秒后再点击")
            return
        }

        const el = document.getElementById("print-container")
        const iframe = document.createElement("IFRAME")
        let doc = null
        // iframe.setAttribute('style', 'position:absolute;top:0px;left:0px;width:100%;height:100%',);
        document.body.appendChild(iframe)
        doc = iframe.contentWindow.document
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./printCheckDetail.css">`)
        doc.write(el.innerHTML)
        doc.close()
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus()
        iframe.onload = () => {
            iframe.contentWindow.print()
            document.body.removeChild(iframe)
        }
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe)
        }
        const old = window.document.body.innerHTML
        const printPart = document.getElementById("print-container")
        window.document.body.innerHTML = ""
        window.document.body.appendChild(printPart)
        window.print()
        await printNum({orderId:visible.orderId,printType:'normal'})
        window.document.body.innerHTML = old
        window.location.reload()
    }

    return (
        <Modal
            title="维保任务书打印"
            width={1120}
            visible={visible.visible}
            onCancel={() => onClose({ visible: false, orderId: "" })}
            footer={[
                <Button onClick={() => onClose({ visible: false, orderId: "" })} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={print} key="submit">
                    确认打印
                </Button>,
            ]}>
            <div id="print-container">
                <div className="print-container">
                    <table className="print-table print-table-first" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                        <tbody>
                            <tr className="table-head">
                                <td colSpan={4} style={{ fontSize: 24 }}>云豪车服（A类）TCO服务车辆维保任务书({level})</td>
                            </tr>
                            <tr className="table-head">
                                <td rowSpan={3} style={{ width: '10%' }}>车辆信息</td>
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>用户名称：{baseInfo.customerName}</td>
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>底盘序列号：{baseInfo.customerVehicleModel}</td>
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>车型：{baseInfo.customerVehicleVin}</td>
                            </tr>
                            <tr className="table-head">
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>车牌号：{baseInfo.customerVehicleCode}</td>
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>联系人：{baseInfo.repairContactName}</td>
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>联系电话：{baseInfo.repairContactPhone}</td>
                            </tr>
                            <tr className="table-head">
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>日期：{baseInfo.createTime}</td>
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>工单号：{baseInfo.orderCode}</td>
                                <td style={{ width: '30%', textAlign: 'left', paddingLeft: 5 }}>行驶里程：{baseInfo.customerVehicleMileage}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="print-table print-table-first" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                        <tbody>
                            <tr className="table-head">
                                <td style={{ width: '10%', textAlign: 'center' }}>作业部位</td>
                                <td style={{ width: '5%', textAlign: 'center' }}>序号</td>
                                <td style={{ width: '45%', textAlign: 'center' }}>维保任务项目</td>
                                <td style={{ width: '10%', textAlign: 'center' }}>处理措施</td>
                                <td style={{ width: '30%', textAlign: 'center' }}>处理情况<br />（正常打√；异常请说明）</td>
                            </tr>
                            {checkDetail.map((item, index) =>
                                <>
                                    {item.isAreaGroupFirst == 1 ?
                                        <tr className="table-head" key={index + "a"}>
                                            <td colSpan={5} style={{ fontSize: 20 }}>{item.workingArea}</td>
                                        </tr>
                                        : null
                                    }
                                    <tr className="table-head" key={index}>
                                        {item.isItemGroupFirst == 1 ?
                                            <td rowSpan={item.itemGroupNum} style={{ width: '10%', textAlign: 'center' }}>{item.itemClassification}</td> : null
                                        }
                                        <td style={{ width: '5%', textAlign: 'center', textShadow: 'none', fontWeight: 'bold' }}>{index + 1}</td>
                                        <td style={{ width: '45%', textAlign: 'left', paddingLeft: 5, fontSize: 14, textShadow: 'none' }}>{item.itemNote}</td>
                                        <td style={{ width: '10%', textAlign: 'center', fontSize: 14, textShadow: 'none' }}>{item.treatmentMeasure}</td>
                                        <td style={{ width: '30%' }}></td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                    <table className="print-table print-table-first" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                        <tbody>
                            <tr className="table-head">
                                <td style={{ lineHeight: '15px', textAlign: 'center', fontSize: 14, textShadow: 'none' }}>异常汇总：</td>
                            </tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head"><td style={{ lineHeight: '15px' }}>&nbsp;</td></tr>
                            <tr className="table-head">
                                <td style={{ textAlign: 'left' }}>
                                    <span style={{ marginLeft: 20 }}>技师确认签名：</span>
                                    <span style={{ marginLeft: 150 }}>主管确认签名：</span>
                                    <span style={{ marginLeft: 150 }}>用户确认签名：</span>
                                    <span style={{ marginLeft: 150 }}>日期：</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Modal >
    )
}

export default PrintCheckDetail
