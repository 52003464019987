import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, message, Modal, DatePicker, Upload, Button, Spin, Radio } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { quotaOrderDetaiOne, quotaSettle } from "@/service/teamService"

export default (props) => {
    const { rowInfo, visible, setVisible, onSuccess, mode } = props
    const [form] = Form.useForm()
    const [invoiceTypes, setInvoiceTypes] = useState([])
    const [imgs, setImgs] = useState({
        file: [],
        md5: "",
    })

    useEffect(() => {
        if (visible) {
            setInvoiceTypes(getPcodeDict("040").children)
            console.log(getPcodeDict("040").children)
            quotaOrderDetaiOne(rowInfo.id).then((res) => {
                let imgres = res.retData.businessVoucher || ""
                let file = []
                if (imgres) {
                    file = imgres.split(",").map((item, index) => {
                        return {
                            uid: index,
                            status: "done",
                            url: `${fileLookUrl().image}${item}`,
                            response: {
                                retData: item,
                            },
                        }
                    })
                    setImgs({ file, md5: imgres })
                }
                form.setFieldsValue({ ...res.retData, businessVoucher: file })
            })
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    //表单提交
    const onUserSubmit = () => {
        if (mode === "look") {
            handleCancel()
            return
        }
        form.validateFields().then((values) => {
            let sendData = {
                ...values,
                id: rowInfo.id,
                invoiceType: values.invoiceType,
                businessVoucher: imgs.md5,
            }
            quotaSettle(sendData).then((res) => {
                message.success("结算成功")
                onSuccess && onSuccess()
                handleCancel()
            })
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible(false)
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    // const handleApplicantTypeChange = (e) => {
    //     setApplicantType(Number(e.target.value))
    // }

    //图片改变
    const handleImgChange = (info) => {
        if (info.file.status === "done" || info.file.status === "removed") {
            let file = info.fileList
            let md5 = file.map((item) => {
                console.log(item)
                return item.response.retData
            })
            md5 = md5.join(",")
            setImgs({
                file,
                md5,
            })
        }
    }

    const handleInvoiceTypesChange = (value, option) => {
        console.log(value)
        console.log(option)
        computedPrice(option.num)
    }

    const computedPrice = (invoiceTypeShrao) => {
        const { surplusAmount, totalAmount, quotaRatio, quotaAmount } = form.getFieldsValue(["surplusAmount", "totalAmount", "quotaRatio", "quotaAmount"])
        // 分成佣金=盈余费用*（1-发票税率）* 佣金比例
        const commissionAmount = Number(surplusAmount) * (1 - Number(invoiceTypeShrao)) * Number(quotaRatio)
        // const settlementAmount =
        //     Number(quotaAmount) * (1 - Number(invoiceTypeShrao)) + Number(surplusAmount) * (1 - Number(invoiceTypeShrao)) * (1 - Number(quotaRatio))
        const settlementAmount = Number(totalAmount) *  (1 - Number(invoiceTypeShrao)) - commissionAmount
        form.setFieldsValue({ commissionAmount, settlementAmount })
        // console.log(Number(totalAmount) *  (1 - Number(invoiceTypeShrao)) - commissionAmount)
    }

    return (
        <>
            <Modal forceRender visible={visible} title="账单结算" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={800}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout} initialValues={{ applicantType: 1, costType: 1 }}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item label="账单编号" name="settlementNo">
                                    <Input placeholder="账单编号" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="团队名称" name="teamName">
                                    <Input placeholder="团队名称" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="工单数量" name="orderNum">
                                    <Input placeholder="工单数量" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="发票类型" name="invoiceType" rules={[{ required: true, message: "请选择发票类型" }]}>
                                    <Select
                                        placeholder="请选择发票类型"
                                        style={{ width: "100%" }}
                                        onChange={handleInvoiceTypesChange}
                                        disabled={mode === "look"}
                                    >
                                        {invoiceTypes.map((item) => (
                                            <Select.Option value={item.dictCode} num={item.remark} key={item.id}>
                                                {item.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="账单金额" name="totalAmount">
                                    <Input placeholder="账单金额" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="定额费用" name="quotaAmount">
                                    <Input placeholder="定额费用" disabled />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="盈余费用" name="surplusAmount">
                                    <Input placeholder="盈余费用" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="佣金比例" name="quotaRatio">
                                    <Input placeholder="佣金比例" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="分成佣金" name="commissionAmount">
                                    <Input placeholder="分成佣金" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="结算金额" name="settlementAmount">
                                    <Input placeholder="结算金额" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="结算人" name="operator">
                                    <Input placeholder="结算人" disabled />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="结算日期" name="settlementTime">
                                    <Input placeholder="结算日期" disabled />
                                </Form.Item>
                            </Col>

                            <Col
                                span={24}
                            >
                                <Form.Item label="照片" name="businessVoucher" valuePropName="fileList" getValueFromEvent={normFile} labelCol={{ span: 3 }}
                                    wrapperCol={{ span: 20 }}>
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={imgs.file}
                                        onChange={handleImgChange}
                                    >
                                        {imgs.file.length >= 9 ? null : <Button>上传照片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
