import React, { useContext, useState, useEffect } from "react"
import { message, Modal, Input,Button, Dropdown,Menu } from "antd";
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { getCustomerListNew } from '@/service/customerService'
import { MyContext } from "@/store/myContext"
import { ConvertModel } from "./convert/index";
import { getPcodeDict } from "@/utils/dataStorage"
import { usePrebookModel } from "./prebookModel";
import { prebookService } from "./prebookService";
import { DownOutlined } from "@ant-design/icons";
import ParintMadal from './detail/printOrder' //打印弹框
import SettleScaniaPrints from './detail/settleScaniaPrints' //打印弹框  
import SknyPrints from './detail/sknyPrints' //打印弹框

const { TextArea } = Input;

export default ({ tab }) => {
    const { state, dispatch, detailsPage, Option } = useContext(MyContext)
    const { getSelectedDictOne, getTreeNodeName } = useDictModel();
    const {
        setActiveMenu,
        selectInfo, setSelectInfo,
        pagination, setpagination,
        prebookList, loadPrebookList,
        staticInfo,
        setConvertVisible,
        printVisible, setPrintVisible,
        printSettleScaniaVisible, setPrintSettleScaniaVisible,
        printSnkyVisible, setPrintSnkyVisible,handlePrint,handleSettleScaniaPrint,snkyPrint
    } = usePrebookModel();

    const [searchValue, setSearchValue] = useState({})
    const [dictObj, setDictObj] = useState({})

    useEffect(() => {
        let obj = {}
        getPcodeDict("011").children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        getPcodeDict("029").children.forEach((item) => {
            obj[item.dictCode] = item.title
        })
        setDictObj(obj);
    }, [])

    useEffect(() => {
        if (state.activeMenu.indexOf(("/")) == -1) {
            setActiveMenu(state.activeMenu);
            loadPrebookList(pagination.current, {}, state.activeMenu);
        }
    }, [state.activeMenu])

    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadPrebookList(page.current, searchValue, state.activeMenu)
    }
    const PrintMenu = () => {
        return (
            <Menu>
                <Menu.Item key="2">
                    <span onClick={() => handlePrint(selectInfo, setPrintVisible)}>财务打印</span>
                </Menu.Item>
                <Menu.Item key="3">
                    <span onClick={() => handleSettleScaniaPrint(selectInfo, setPrintSettleScaniaVisible)}>结算打印</span>
                </Menu.Item>
                <Menu.Item key="0">
                    <span onClick={() => snkyPrint(selectInfo, setPrintSnkyVisible,1)}>工单打印</span>
                </Menu.Item>
                <Menu.Item key="1">
                    <span onClick={() => snkyPrint(selectInfo, setPrintSnkyVisible,0)}>工单无单价打印</span>
                </Menu.Item>
            </Menu>
        )
    }
    //列表button
    const topButtons1 = [
        {
            click: () => handleDetails("add", selectInfo.rowInfo),
            content: "新增",
        },
        {
            click: () => handleDetails("edit", selectInfo.rowInfo),
            content: "编辑",
        },
        {
            click: () => handleConvert(),
            content: "转工单",
        },
        {
            click: () => cancellation(),
            content: "作废",
        },
        // {
        //     dropdown:true,
        //     ButtonType: <Dropdown overlay={PrintMenu}>
        //     <Button shape="round" className="but-left">
        //     打印
        //         <DownOutlined />
        //     </Button>
        // </Dropdown>
        // },
    ]

    const topButtons2 = [
        {
            click: () => handleDetails("edit", selectInfo.rowInfo),
            content: "编辑",
        }
    ]

    //详情
    const handleDetails = async (mode, row) => {
        if (mode != "add" && !Object.keys(row).length) {
            message.warning("请选择需要编辑的记录")
            return
        }
        let tabName = {
            add: "维修预约单新增",
            edit: "维修预约单编辑"
        }
        let modeKey = tab + "/" + mode
        dispatch({
            deleteKey: modeKey,
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.PrebookDetail({
                dispatch,
                modeKey,
                mode,
                rowInfo: row,
            }),
        })
    }

    const handleConvert = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择需要转工单的记录")
            return;
        }
        if (selectInfo.rowInfo.prebookStatus != 0) {
            message.warning("已开单状态才能转工单")
            return;
        }
        setConvertVisible(true);
    }

    //作废
    const cancellation = () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择需要作废的记录")
            return;
        }
        if (selectInfo.rowInfo.prebookStatus != 0) {
            message.warning("已开单状态才能转作废")
            return;
        }

        let cancelReason = ""
        Modal.confirm({
            title: '维修预约单作废',
            type: "error",
            content: <><span>作废原因</span>
                <TextArea
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    onChange={(e) => { cancelReason = e.target.value }} />
            </>,
            okText: '确定',
            cancelText: '取消',
            onOk: () => new Promise((resolve, reject) => {
                if (cancelReason) {
                    resolve(cancelReason);
                } else {
                    message.warning("请输入作废原因");
                    reject('请输入作废原因');
                }
            }).then(res => {
                let param = {
                    "id": selectInfo.rowInfo.id,
                    "cancelReason": res,
                }
                prebookService.cancel(param).then((response) => {
                    if (response && response.retCode === 200) {
                        loadPrebookList(1, searchValue, state.activeMenu)
                        message.success("作废成功");
                    }
                    else {
                        message.error("作废失败");
                    }
                });
            }),
        });
    }

    //table配置
    const columns = [
        {
            title: '序号',
            width: 50,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "预约单号",
            width: 140,
            align: "center",
            dataIndex: "prebookCode",
            render: (text, record) => <span onClick={() => { handleDetails('edit', record) }}>{text}</span>,
        },
        {
            title: "预约状态",
            width: 120,
            align: "center",
            dataIndex: "prebookStatus",
            render: (text) => (text === 0 ? "已开单" : text === 1 ? "已转接" : text === 2 ? "已作废" : ""),
        },
        state.activeMenu.indexOf(("preBookConvert")) != -1 ? {
            title: "转接单号",
            width: 140,
            align: "center",
            dataIndex: "orderCode",
        } : { width: 0 },
        {
            title: "客户名称",
            width: 200,
            align: "center",
            dataIndex: "customerName",
        },
        {
            title: "车辆牌照",
            width: 120,
            align: "center",
            dataIndex: "vehicleCode",
        },
        {
            title: "送修人",
            width: 120,
            align: "center",
            dataIndex: "senderRepairUserName",
        },
        {
            title: "送修人电话",
            width: 140,
            align: "center",
            dataIndex: "senderRepairUserPhone",
        },
        {
            title: "Vin码",
            width: 120,
            align: "center",
            dataIndex: "vehicleVin",
        },
        {
            title: "车辆类型",
            width: 120,
            align: "center",
            dataIndex: "vehicleType",
            render: (text) => <>{dictObj[text]}</>,
        },
        {
            title: "故障类型",
            width: 120,
            align: "center",
            dataIndex: "serviceType",
            render: (text) => <>{dictObj[text]}</>,
        },
        {
            title: "工时费",
            width: 120,
            align: "center",
            dataIndex: "itemAmount",
        },
        {
            title: "配件费",
            width: 120,
            align: "center",
            dataIndex: "materialAmount",
        },
        {
            title: "费用合计",
            width: 120,
            align: "center",
            render: (text, record) => (record.itemAmount + record.materialAmount),
        },
        {
            title: "优惠金额",
            width: 120,
            align: "center",
            dataIndex: "preferentialAmount",
        },
        {
            title: "营业额",
            width: 120,
            align: "center",
            dataIndex: "turnoverAmount",
        },
        {
            title: "销售毛利",
            width: 120,
            align: "center",
            dataIndex: "profitAmount",
        },
        {
            title: "销售成本",
            width: 120,
            align: "center",
            dataIndex: "costAmount",
        },
        {
            title: "结算类型",
            width: 120,
            align: "center",
            dataIndex: "settlementType",
            render: (text) => getTreeNodeName(text),
        },
        {
            title: "服务地点",
            width: 250,
            align: "center",
            dataIndex: "repairAddress",
        },
        {
            title: "结算方",
            width: 120,
            align: "center",
            dataIndex: "settlementPartyCode",
            render: (text) => getTreeNodeName(text)
        },
        {
            title: "进厂时间",
            width: 120,
            align: "center",
            dataIndex: "entryFactoryTime",
        },
        {
            title: "出厂时间",
            width: 120,
            align: "center",
            dataIndex: "outFactoryTime",
        },
        {
            title: "所属机构",
            width: 120,
            align: "center",
            dataIndex: "organName",
        },
        {
            title: "作废原因",
            width: 120,
            align: "center",
            dataIndex: "cancelReason",
        },
        {
            title: "备注",
            width: 120,
            align: "center",
            dataIndex: "remark",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'searchSelect',
                placeholder: '请选择客户名称',
                valueName: 'customerId',
                optionName: 'customerName',
                optionId: 'id',
                searchApi: getCustomerListNew,
                searchItemName: 'customerName'
            },
            {
                type: "input",
                placeholder: "送修人",
                valueName: "senderRepairUserName",
            },
            {
                type: "input",
                placeholder: "送修人电话",
                valueName: "senderRepairUserPhone",
            },
            {
                type: "input",
                placeholder: "车牌号",
                valueName: "vehicleCode",
            },
            {
                type: "input",
                placeholder: "Vin码",
                valueName: "vehicleVin",
                xxl: 6
            },
            {
                type: "select",
                placeholder: "预约状态",
                valueName: "prebookStatus",
                optionList: () => (
                    <>
                        <Option value={0} key={0}>已开单</Option>
                        <Option value={1} key={1}>已转接</Option>
                        <Option value={2} key={2}>已作废</Option>
                    </>
                ),
            },
            {
                type: "input",
                placeholder: "车辆型号",
                valueName: "vehicleModel",
            },
            {
                type: "select",
                placeholder: "故障类型",
                valueName: "serviceType",
                optionList: () => getPcodeDict('011').children.map(item => {
                    return <Option value={item.dictCode} key={item.dictCode}>{item.title}</Option>
                }),
            },
            {
                type: "select",
                placeholder: "工单类型",
                valueName: "orderType",
                optionList: () => getPcodeDict('014').children.map(item => {
                    return <Option value={item.dictCode} key={item.dictCode}>{item.title}</Option>
                }),
            },
            {
                type: "input",
                placeholder: "预约单号",
                valueName: "prebookCode",
                xxl: 6
            },
            {
                type: "input",
                placeholder: "报修地址",
                valueName: "repairAddress",
            },
            {
                type: "select",
                placeholder: "车辆类型",
                valueName: "vehicleType",
                optionList: () => getPcodeDict('029').children.map(item => {
                    return <Option value={item.dictCode} key={item.dictCode}>{item.title}</Option>
                }),
            },
            {
                type: "input",
                placeholder: "制单人",
                valueName: "createUser",
            },
            {
                type: "select",
                placeholder: "结算类型",
                valueName: "settlementType",
                optionList: () => getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map(item => {
                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                }),
            },
            {
                type: "select",
                placeholder: "结算方",
                valueName: "settlementPartyCode",
                optionList: () => getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY).map(item => {
                    return <Option value={item.value} key={item.value}>{item.label}</Option>
                }),
                xxl: 6,
            },
            {
                type: "RangePicker",
                fieldName: "",
                placeholder: ["制单日期起始", "制单日期结束"],
                valueName: ["createTimeStart", "createTimeEnd"],
                format: "YYYY-MM-DD",
                xxl: 8,
            },
            {
                type: "RangePicker",
                fieldName: "",
                placeholder: ["进场开始", "进场结束"],
                valueName: ["entryFactoryTimeStart", "entryFactoryTimeEnd"],
                format: "YYYY-MM-DD",
                xxl: 8,
            },
            {
                type: "RangePicker",
                fieldName: "",
                placeholder: ["出厂开始", "出厂结束"],
                valueName: ["outFactoryTimeStart", "outFactoryTimeEnd"],
                format: "YYYY-MM-DD",
                xxl: 8,
            },

        ],
    }

    return (
        <>
            <TopSearch
                title="维修预约单"
                searchListData={searchList}
                searchValue={searchValue}
                setSearchValue={(obj) => setSearchValue(obj)}
                handleSearch={loadPrebookList}
                justify={"start"}
            />
            <ListComponent
                title="预约单列表"
                subTitle={['预约单数：' + staticInfo.quantity, '费用合计：' + staticInfo.totalAmount, '营业额：' + staticInfo.turnoverAmount]}
                buttons={state.activeMenu.indexOf(("preBookNew")) != -1 ? topButtons1 : topButtons2}
                selectInfo={selectInfo}
                columns={columns}
                scroll={{ x: 3800 }}
                dataSource={prebookList}
                pagination={pagination}
                onTableChange={onTableChange}
                setSelectInfo={(obj) => setSelectInfo(obj)}
            />
            {printVisible.visible && <ParintMadal visible={printVisible} onClose={(bool) => {setPrintVisible(bool);loadPrebookList(pagination.current, searchValue)}} />}
            {printSettleScaniaVisible.visible && <SettleScaniaPrints visible={printSettleScaniaVisible} onClose={(bool) => {setPrintSettleScaniaVisible(bool);loadPrebookList(pagination.current, searchValue)}}/>}
            {printSnkyVisible.visible && <SknyPrints visible={printSnkyVisible} onClose={(bool) => {setPrintSnkyVisible(bool);loadPrebookList(pagination.current, searchValue)}}  type={printSnkyVisible.type}/>}
            <ConvertModel mode={"edit"} rowInfo={selectInfo && selectInfo.rowInfo} />
        </>
    )
}
