import React, { useEffect, useState, useImperativeHandle } from "react"
import { Table, Row, Button, message } from "antd"
import { CheckOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"
import BigFlagPlantAddCombination from "./bigFlagPlantAddCombination"
import BigFlagPlantEditCombination from "./bigFlagPlantEditCombination"
import { useRef } from "react"
import {planGenerateOrder} from "@/service/dataService"
const columns = [
    { title: "次数", dataIndex: "index", align: "center", width: "50px", render: (text) => text + 1 },
    { title: "里程（万公里）", dataIndex: "mileage", align: "center", width: 120 },
]
export default ({ startMileage, dataRef, plan }) => {
    const [visible, setVisible] = useState(false)
    const [editVisible, setEditVisible] = useState(false)
    const [combinationList, setCombinationList] = useState([])
    const [selectColum, setSelectColum] = useState(columns)
    const [mileageInfoListList, setMileageInfoListList] = useState(
        Array(72)
            .toString()
            .split(",")
            .map((item, index) => ({ index, mileage: startMileage + index + 1 }))
    )

    const mileageInfoListListRef = useRef(null)

    useEffect(() => {
        setMileageInfoListList([...mileageInfoListList.map((item, index) => ({ ...item, mileage: startMileage + index + 1 }))])
    }, [startMileage])

    useEffect(() => {
        mileageInfoListListRef.current = mileageInfoListList
    }, [mileageInfoListList])

    useEffect(() => {
        if (plan && plan.combinationList && plan.mileageList) {
            let { combinationList: combinations, mileageList: mileages } = plan
            let combinationIds = [],
                combinationArr = []
            combinations.forEach((item) => {
                combinationIds.push(item.id)
                combinationArr.push({ ...item, combinationId: item.id, combinationName: item.name })
            })
            setCombinationList(combinationArr.sort((a, b) => a.sort > b.sort))
            mileages = mileages.map((item) => {
                if (!item.combinationIds) {
                    item.combinationIds = []
                }
                let combinationObj = {}
                combinationIds.forEach((id) => {
                    combinationObj[id] = item.combinationIds.includes(id)
                })
                return combinationObj
            })
            setMileageInfoListList(mileages.map((item, index) => ({ ...mileageInfoListList[index], ...item })))
        }
    }, [plan])

    //暴露给父组件的方法
    useImperativeHandle(dataRef, () => ({
        //父组件保存的时候 获取所有编辑完后的数据
        upData: () => {
            return { combinationList, mileageInfoListList: mileageInfoListListRef.current }
        },
    }))

    const setAdd = (items) => {
        if (items.length) {
            let addcombinationList = []
            let addmileageInfoListList = mileageInfoListListRef.current
            items.forEach((item) => {
                if (combinationList.findIndex((i) => i.combinationId === item.id) === -1) {
                    addcombinationList.push({ ...item, combinationId: item.id, combinationName: item.name })
                    addmileageInfoListList = addmileageInfoListList.map((i) => ({ ...i, [item.id]: i.index % item.intervalMileage === 0 }))
                }
            })
            setMileageInfoListList(addmileageInfoListList)
            setCombinationList([...combinationList, ...addcombinationList])
        }
        setVisible(false)
    }

    const handleChangeStartBy = (data, index) => {
        let changemileageInfoListList = mileageInfoListListRef.current
        changemileageInfoListList = changemileageInfoListList.map((item, i) => {
            return {
                ...item,
                [data.combinationId]: i < index ? false : (i - index) % data.intervalMileage === 0,
            }
        })
        setMileageInfoListList(changemileageInfoListList)
    }

    const handleCreatOrder = (index) => {
       
        if (plan && plan.mileageList && plan.mileageList.length > 0) {
            const { planInfo, mileageList } = plan
            let planId = planInfo.id,
                mileageId = mileageList[index].id
                planGenerateOrder({planId,mileageId}).then(res => {
                    message.success("工单生成成功，请在订单列表中查看")
                    setVisible(false)
                })
        } else {
            message.error("请先保存计划再生成订单")
        }
    }

    useEffect(() => {
        setSelectColum([
            ...columns,
            ...combinationList.map((item) => ({
                title: item.combinationName,
                dataIndex: item.combinationId,
                width: 100,
                align: "center",
                render: (text, record, index) => (
                    <EmptyDic
                        onClick={() => {
                            handleChangeStartBy(item, index)
                        }}
                    >
                        {text ? <CheckOutlined color="#2e3aeb" /> : null}
                    </EmptyDic>
                ),
            })),
            {
                title: "操作",
                dataIndex: "index",
                align: "center",
                width: "50px",
                render: (text, record, index) => {
                    return (
                        <Button
                            type="primary"
                            onClick={() => {
                                handleCreatOrder(index)
                            }}
                        >
                            生成工单
                        </Button>
                    )
                },
            },
        ])
    }, [combinationList])

    const handleChangeCombination = (data, deleteIds) => {
        setCombinationList(data)
        if (deleteIds.length > 0) {
            let changemileageInfoListList = mileageInfoListListRef.current
            changemileageInfoListList = changemileageInfoListList.map((item) => {
                deleteIds.forEach((deleteId) => {
                    delete item[deleteId]
                })
                return item
            })
            setMileageInfoListList(changemileageInfoListList)
        }
        setEditVisible(false)
    }

    return (
        <Box>
            <Row>
                <Button
                    type="primary"
                    onClick={() => {
                        setVisible(true)
                    }}
                    style={{ margin: "0 10px 10px 0" }}
                >
                    添加维保组合
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        setEditVisible(true)
                    }}
                >
                    编辑维保组合
                </Button>
            </Row>

            <Table
                rowKey={(record) => record.index}
                columns={selectColum}
                dataSource={mileageInfoListList}
                scroll={{ x: "max-content", y: 200 }}
                pagination={false}
            />

            <BigFlagPlantAddCombination visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={setAdd} drawerMode="item" />
            {editVisible && (
                <BigFlagPlantEditCombination
                    visible={editVisible}
                    setVisible={setEditVisible}
                    combinationList={combinationList}
                    onSuccess={handleChangeCombination}
                />
            )}
        </Box>
    )
}

const Box = styled.div`
    width: 100%;
    overflow: hidden;
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin: 20px 0;
    .type {
        font-size: 16px;
        font-weight: 500;
        height: 30px;
        line-height: 30px;
        margin-bottom: 10px;
        color: red;
    }
`
const EmptyDic = styled.div`
    width: 100%;
    height: 23px;
`
