// import CryptoJS from 'crypto-js'
// import { Form } from 'antd'
import { shopGettoken } from '@/service/shop'
import { message, notification } from 'antd'
import { setShopToken, setShopBToken } from '@/utils/dataStorage'
import BigNumber from 'bignumber.js'
import FileSaver from 'file-saver';
import JSZip from 'jszip';

/**
 * 防抖函数
 * @param {*} func
 * @param {*} wait
 */
export function debounce(func, wait = 500) {
    let timeout // 定时器变量
    return function (event) {
        clearTimeout(timeout) // 每次触发时先清除上一次的定时器,然后重新计时
        event.persist && event.persist() //保留对事件的引用
        timeout = setTimeout(() => {
            func(event)
        }, wait) // 指定 xx ms 后触发真正想进行的操作 handler
    }
}

/**
 * 节流函数
 * @param {*} func
 * @param {*} interval
 */
export function throttle(func, interval = 100) {
    let timeout
    let startTime = new Date()
    return function (event) {
        event.persist && event.persist() //保留对事件的引用
        clearTimeout(timeout)
        let curTime = new Date()
        if (curTime - startTime <= interval) {
            //小于规定时间间隔时，用setTimeout在指定时间后再执行
            timeout = setTimeout(() => {
                func(event)
            }, interval)
        } else {
            //重新计时并执行函数
            startTime = curTime
            func(event)
        }
    }
}

/**
 * 生成指定区间的随机整数
 * @param min
 * @param max
 * @returns {number}
 */
export function randomNum(min, max) {
    return Math.floor(Math.random() * (max - min) + min)
}

/**
 * 加密函数，加密同一个字符串生成的都不相同
 * @param {*} str
 */
// export function encrypt(str) {
//     return CryptoJS.AES.encrypt(JSON.stringify(str), SECRETKEY).toString();
// }

/**
 * 解密函数
 * @param {*} str
 */
// export function decrypt(str) {
//     const bytes = CryptoJS.AES.decrypt(str, SECRETKEY);
//     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// }

/**
 * 判断是否是对象
 * @param {*} obj
 */
export function isObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
}

/**
 * 创建表单回显的对象
 * @param {*} obj
 */
// export function createFormField(obj) {
//     let target = {}
//     if (isObject(obj)) {
//         for (let [key, value] of Object.entries(obj)) {
//             target[key] = Form.createFormField({
//                 value
//             })
//         }
//     }
//     return target
// }

/**
 * 将img标签转换为【图片】
 * @param {string} str
 */
export function replaceImg(str) {
    if (typeof str === 'string') {
        str = str.replace(/<img(.*?)>/g, '[图片]')
    }
    return str
}

/**
 * 图片预加载
 * @param arr
 * @constructor
 */
export function preloadingImages(arr) {
    if (Array.isArray(arr)) {
        arr.forEach((item) => {
            const img = new Image()
            img.src = item
        })
    }
}

//阿拉伯数字转换成大写汉字
export function numberParseChina(money) {
    //汉字的数字
    var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
    //基本单位
    var cnIntRadice = ['', '拾', '佰', '仟']
    //对应整数部分扩展单位
    var cnIntUnits = ['', '万', '亿', '兆']
    //对应小数部分单位
    var cnDecUnits = ['角', '分', '毫', '厘']
    //整数金额时后面跟的字符
    var cnInteger = '整'
    //整型完以后的单位
    var cnIntLast = '圆'
    //最大处理的数字
    var maxNum = 999999999999999.9999
    //金额整数部分
    var integerNum
    //金额小数部分
    var decimalNum
    //输出的中文金额字符串
    var chineseStr = ''
    //分离金额后用的数组，预定义
    var parts
    if (money == '') {
        return ''
    }
    money = parseFloat(money)
    if (money >= maxNum) {
        //超出最大处理数字
        return ''
    }
    if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
    }
    //转换为字符串
    money = money.toString()
    if (money.indexOf('.') == -1) {
        integerNum = money
        decimalNum = ''
    } else {
        parts = money.split('.')
        integerNum = parts[0]
        decimalNum = parts[1].substr(0, 4)
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
            var n = integerNum.substr(i, 1)
            var p = IntLen - i - 1
            var q = p / 4
            var m = p % 4
            if (n == '0') {
                zeroCount++
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0]
                }
                //归零
                zeroCount = 0
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
            }
            if (m == 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q]
            }
        }
        chineseStr += cnIntLast
    }
    //小数部分
    if (decimalNum != '') {
        var decLen = decimalNum.length
        for (var i = 0; i < decLen; i++) {
            var n = decimalNum.substr(i, 1)
            if (n != '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i]
            }
        }
    }
    if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
    } else if (decimalNum == '') {
        chineseStr += cnInteger
    }
    return chineseStr
}

/**
 * 判断对象的值是不是全为空
 */
export function objectValueAllEmpty(object) {
    var isEmpty = true
    Object.keys(object).forEach(function (x) {
        if (object[x] != null && object[x] != '') {
            isEmpty = false
        }
    })
    if (isEmpty) {
        //值全为空
        return true
    }
    return false
}
//时间转换
export function timeShift(arr) {
    return `${arr[0]}-${arr[1].toString().padStart(2, '0')}-${arr[2].toString().padStart(2, '0')} ${arr[3].toString().padStart(2, '0')}:${arr[4]
        .toString()
        .padStart(2, '0')}:${arr[5] ? arr[5].toString().padStart(2, '0') : '00'}`
}

//商城登录
export function shopLogin() {
    shopGettoken()
        .then((res) => {
            setShopToken(res)
        })
        .catch((res) => {
            message.error('商城登录状态失效，请重新登录！')
        })
}

//B端商城登录
export function shopLoginB() {
    shopGettoken()
        .then((res) => {
            setShopBToken(res)
        })
        .catch((res) => {
            message.error('商城登录状态失效，请重新登录！')
        })
}

//只提取汉字
export function GetChinese(strValue) {
    if (strValue != null && strValue != '') {
        var reg = /[\u4e00-\u9fa5]/g
        if (strValue.match(reg)) {
            return strValue.match(reg).join('')
        } else {
            return ''
        }
    } else return ''
}
//去掉汉字
export function RemoveChinese(strValue) {
    if (strValue != null && strValue != '') {
        var reg = /[\u4e00-\u9fa5]/g
        return strValue.replace(reg, '')
    } else return ''
}

//生成默认密码
export function createPassword() {
    //创建26个字母数组
    const letterArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    let letterValue = ''
    const n = 2
    for (var i = 0; i < n; i++) {
        letterValue += letterArr[Math.floor(Math.random() * 26)]
    }

    let numberValue = ''
    const num = 6 //这个值可以改变的，对应的生成多少个数字，根据自己需求所改
    for (let i = 0; i < num; i++) {
        numberValue += Math.floor(Math.random() * 10)
    }
    return letterValue + numberValue
}

/**
 * bignumber计算方法封装
 */
export const bignumber = {
    // 加
    add(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.plus(y).toNumber()
    },
    // 减
    minus(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.minus(y).toNumber()
    },
    // 乘
    times(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.times(y).toNumber()
    },
    // 除
    dividedBy(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.dividedBy(y).toNumber()
    },
    // 多个相加
    multiAdd(...params){
        let num = BigNumber(0)
        params.forEach((x) => num = num.plus(BigNumber(x)))
        return num.toNumber()
    },
    //保留小数
    toFixed(num, x){
        return BigNumber(num).toFixed(x)
    }
}


/**
 * 图片批量打包zip并下载
 * @param imgSrcList 批量下载图片的路径
 * @param fileName 压缩文件的文件名
 */
 export const toZip = async (imgSrcList, fileName, cbk) => {
	let imgBase64 = [];
	let zip = new JSZip(); //实例化一个压缩文件对象
	let imgFolder = zip.folder(fileName); //新建一个图片文件夹用来存放图片，参数为文件名
	for (let i = 0; i < imgSrcList.length; i++) {
		let src = imgSrcList[i];
		let base64 = await getBase64(src);
		imgBase64.push(base64.substring(22))
	}

	function polling() {
		setTimeout(() => {
			if (imgSrcList.length === imgBase64.length) {
				for (let i = 0; i < imgSrcList.length; i++) {
					imgFolder.file(fileName + '-' + (i + 1) + '.png', imgBase64[i], { base64: true });
				}
				zip.generateAsync({ type: 'blob' }).then(function (content) {
					FileSaver.saveAs(content, `${fileName}.zip`);
				});
				message.destroy();
				message.success('打包完成');
				notification.close('downloadImgToZIP');
                cbk && cbk()
			} else {
				notification.warning({
					message: '图片开始批量下载...',
					description: '已完成：' + imgBase64.length + '/' + imgSrcList.length,
					key: 'downloadImgToZIP',
				});
				console.log('已完成：' + imgBase64.length + '/' + imgSrcList.length);
				
			}
		}, 300);
	}
	polling();
}

//传入图片路径，返回base64
function getBase64(img) {
	let image = new Image();
	image.setAttribute('crossOrigin', 'anonymous'); // 必须设置在src之前，跨域使用
	image.src = img+`?v=${new Date().getTime()}${Math.round(Math.random()*10000)}}`;
	return new Promise((resolve, reject) => {
		if (img) {
			image.onload = () => {
				resolve(getBase64Image(image));
			};
		} else {
			reject('none');
		}
	});
}


function getBase64Image(img) {
	let canvas = document.createElement('canvas');
	canvas.width = img.width;
	canvas.height = img.height;
	let ctx = canvas.getContext('2d');
	ctx.drawImage(img, 0, 0, img.width, img.height);
	return canvas.toDataURL('image/jpeg', 0.8);
}
