import { post, get } from "../utils/request"

//团队管理列表 分页查询
function getTeamList(data) {
    return post("/ci/team/list", data)
}

//团队新增
function addTeam(data) {
    return post("/ci/team/insert", data)
}

//团队获取一条数据
function getTeamOne(id) {
    return get(`/ci/team/one/${id}`)
}

//团队编辑
function editTeam(data) {
    return post(`/ci/team/update`, data)
}

//团队删除
function deleteTeam(teamId) {
    return post(`ci/team/delete/${teamId}`)
}

//成员管理列表
function getTeamMemberList(data) {
    return post("/ci/team_user/list", data)
}

//成员列表新增
function addTeamMember(data) {
    return post("/ci/team_user/insert", data)
}

//获取一条成员数据
function getTeamMember(id) {
    return get(`/ci/team_user/one/${id}`)
}

//成员列表编辑
function EditTeamMember(data) {
    return post(`/ci/team_user/update`, data)
}

//成员点位分成编辑
function EditTeamMemberPoint(data) {
    return post(`/ci/team_user/updatePoint`, data)
}

//绑定成员
function bindTeamMember(data){
    return post(`ci/team/bind_user`,data)
}

//解绑成员
function unBindTeamMember(data){
    return post(`ci/team/unbind_user`,data)
}

//成员删除
function deleteTeamMember(teamUserId) {
    return post(`/ci/team_user/delete/${teamUserId}`)
}

// 团队统计分析
function teamStatic(data) {
    return post("/ci/team/teamStatic", data)
}

// 团队统计分析导出
function teamStaticExport(data) {
    return post("/ci/team/teamStaticExport", data)
}


// 成员收益
function teamUserIncomeAnalysis(data) {
    return post("/ci/team_user/incomeAnalysis", data)
}

// 成员收益详情
function teamUserIncomeDetail(data) {
    return post("/ci/team_user/incomeAnalysis/detail", data)
}

// 定额结算单-结算单汇总
function quotaOrderSummary(data){
    return post("/ci/order_team_settlement/quotaOrderSummary", data)
}

//生成结算单
function createQuotaSettle(data){
    return post("/ci/order_team_settlement/quotaSettlement", data)
}

//结算单列表
function quotaSettlePage(data){
    return post("/ci/order_team_settlement/page", data)
}


//删除结算单
function deleteQuotaSettle(id){
    return get(`/ci/order_team_settlement/delete/${id}`)
}

//结算单账单明细
function quotaOrderDetailList(id){
    return get(`/ci/order_team_settlement/quotaOrderDetailList/${id}`)
}

//获取一条数据
function quotaOrderDetaiOne(id){
    return get(`/ci/order_team_settlement/one/${id}`)
}

//获取一条数据
function quotaOrderDetaiUpdate(data){
    return post(`/ci/order_team_settlement/update`,data)
}


//定额结算单-结算
function quotaSettle(data){
    return post(`/ci/order_team_settlement/quotaSettle`,data)
}



export {
    getTeamList,
    deleteTeam,
    getTeamMemberList,
    deleteTeamMember,
    addTeamMember,
    getTeamMember,
    EditTeamMember,
    EditTeamMemberPoint,
    addTeam,
    editTeam,
    getTeamOne,
    teamStatic,
    teamStaticExport,
    bindTeamMember,
    unBindTeamMember,
    teamUserIncomeAnalysis,
    teamUserIncomeDetail,
    quotaOrderSummary,
    createQuotaSettle,
    quotaSettlePage,
    deleteQuotaSettle,
    quotaOrderDetailList,
    quotaOrderDetaiOne,
    quotaSettle,
    quotaOrderDetaiUpdate
}
