import React from "react";
import { Table } from "antd";
import YhDrawer from "@/erp_subpackage/components/YhDrawer";
import { usePurchaseAdvancedDetailsModel } from "./purchaseAdvancedDetailModel";
import { useGlobalModel, transPgToPagination } from "@/erp_subpackage/model/globalModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

export const WhConfirmDrawer = ({ modeKey, mode, rowCode, dispatch }) => {
    const { wsConfirmVisible: visible, editList, lookList, setWsConfirmVisible, onWarehous } = usePurchaseAdvancedDetailsModel();
    const tableList = mode === "edit" ? editList : lookList;

    const onCancel = () => {
        setWsConfirmVisible(false);
    };
    const { user: { shopId, shopName, shopCode }, whDs, setMaterialInfo } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const columns = [
        {
            title: '序号',
            width: 80,
            dataIndex: "index",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
        }, {
            title: '入库数量',
            width: 160,
            dataIndex: 'num',
        }
    ];

    return (
        <YhDrawer
            title="库房确认"
            visible={visible}
            onClose={onCancel}
            okText="保存"
            width={860}
            onOk={onWarehous}
            showFooter={mode !== "look"}
        >
            <Table
                size="small"
                rowKey={record => record.id}
                columns={columns}
                dataSource={tableList}
                pagination={false}
                scroll={{ x: 'max-content', y: 400 }}
            />
        </YhDrawer>
    )
}