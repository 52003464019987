import React from "react";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useSaleOrderDetailModel } from "./saleOrderDetailModel";
import { formatNum } from "@/erp_subpackage/utils/util";

export const FixedBottom = ({ mode }) => {
    const { collapsed } = useGlobalModel(({ collapsed }) => [collapsed]);
    const { addDesc, editDesc, lookDesc } = useSaleOrderDetailModel();
    const typeDesc = {
        add: addDesc,
        edit: editDesc,
        look: lookDesc
    }
    const orderStatistics = [
        {
            label: "订单数量",
            value: formatNum(typeDesc[mode].numAll),
            color: 'green',
            status: true
        },
        {
            label: "订单项数",
            value: formatNum(typeDesc[mode].itemNumAll),
            color: 'green',
            status: true
        },
        {
            label: "订单金额",
            value: formatNum(typeDesc[mode].notTotalAll),
            color: 'green',
            status: true
        },
        {
            label: "优惠金额",
            value: formatNum(typeDesc[mode].discountAll),
            color: 'green',
            status: true
        },
        {
            label: "应收金额",
            value: formatNum(typeDesc[mode].totalAll),
            color: 'red',
            status: true
        }
    ]
    return (
        <div className="details-bot-fixed-warp" style={{ width: `calc(100% - ${collapsed ? '80px' : '200px'})`, paddingLeft: "43px" }}>
            {orderStatistics.map((item, index) => <Description key={index} {...item} />)}
        </div>
    )
}