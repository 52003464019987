import { createModel } from "hox";
import {  getToken } from "../../utils/dataStorage";
export const invoiceModel = createModel(function () {
    const imageUrl = (url) => {
        return  "https://api-2.yunhaogroup.com/fsrv/fetch/" + url + "?Authorization=" + getToken();
    }

    return {
        imageUrl
    }
});
