import { createModel } from "hox";
import { message } from "antd";
import { useState, useEffect } from "react";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { saleOrderService } from "./saleOrderService"
import { formatNum } from "@/erp_subpackage/utils/util";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";

export const useSaleOrderModel = createModel(function () {
    const { user: { shopId, shopName, shopCode, organType, organTypeCode } } = useGlobalModel(({ user }) => [user]);
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //列表数据源分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const initDesc = {
        totalNum: 0,
        totalAmount: 0
    }
    //表格选中row
    const [selectedRow, setSelectedRow] = useState([]);
    const [descTotal, setDescTotal] = useState(initDesc);
    const [rowsDescTotal, setRowsDescTotal] = useState(initDesc);//选中的数据统计展示的数据
    const [drawerVisible, setDrawerVisible] = useState(false);

    useEffect(() => {
        let newRowsDescTotal = { ...initDesc }
        selectedRow.forEach((item) => {
            if (item.status !== DictCodeEnmu.SALE_ORDER_CHANGE) {
                let { totalNum, totalAmount } = item
                newRowsDescTotal.totalNum += +formatNum(totalNum)
                newRowsDescTotal.totalAmount += +formatNum(totalAmount)
            }
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [selectedRow])

    const updatePgCfg = (updateParam) => {
        setPgCfg({ ...pgCfg, ...updateParam })
    }
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    const resetSearchParam = () => {
        setSearchParam({});
        loadData(initPgCfg, {});
        setSelectedRow([])
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ tip: "列表加载中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue, status,
            // shopId: searchValue.shopId ?? shopId,
            // shopId: searchValue.shopId ?? '',//默认查全部
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            // shopName: searchValue.shopName ?? shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await saleOrderService.page(searchParams);
        setSelectedRow([])
        let { retData: statistics } = await saleOrderService.total(searchParams);
        const { records, ...pg } = retData
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records);
        setDescTotal(statistics)
        setLoading({ spinning: false });
    }

    // 作废
    const onInvalidate = async () => {
        let { id, saleOrderCode } = selectedRow[0]
        let { retData } = await saleOrderService.invalidate({ id, saleOrderCode })
        if (retData) {
            message.success("作废成功")
            loadData()
        }
    }

    // 导出列表
    const exportSaleOrder = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue, status,
            // shopId,
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            shopName,
            shopCode,
            id: selectedRow.map(item => item.id).join(),
        }
        let { retData } = await saleOrderService.export(searchParams);
        message.success(retData)
        setLoading({ spinning: false });
    }

    // 导出详情
    const detailsExport = async (searchValue = searchParam) => {
        setLoading({ tip: "文件导出中", spinning: true });
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status;
        const searchParams = {
            ...searchValue,
            status,
            // shopId,
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            shopName,
            shopCode,
            id: selectedRow.map(item => item.id).join(),
        }
        let { retData } = await saleOrderService.detailsExport(searchParams);
        message.success(retData)
        setLoading({ spinning: false });
    }
    const [settleScaniaPrint, setSettleScaniaPrint] = useState(false);
    const [print, setPrint] = useState(false);

    return {
        /***field***/
        searchParam,
        dataSource,
        pgCfg,
        drawerVisible,
        initPgCfg,
        loading,
        descTotal,
        rowsDescTotal,
        print,
        settleScaniaPrint,
        organType,
        organTypeCode,
        selectedRow, setSelectedRow,
        /***method***/
        setDataSource,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setDrawerVisible,
        setLoading,
        setDescTotal,
        onInvalidate,
        exportSaleOrder,
        detailsExport,
        setRowsDescTotal,
        setSettleScaniaPrint,
        setPrint
    }
});