import React, { useEffect } from "react";
import { Table } from "antd";
import { useAllocModel } from "../allocModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useAllocModel();
    const { getTreeNodeName,setMaterialInfo } = useDictModel(() => []);

    useEffect(() => {
        loadData();
    }, [])

    const columns = [
        {
            title: '序号',
            width: 60,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '商品编号',
            fixed: "left",
            width: 160,
            dataIndex: 'materialCode',
            // render: (text) => <YhTooltip text={text} />,
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        },
        {
            title: '商品名称',
            fixed: "left",
            width: 160,
            dataIndex: 'materialName',
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: '调拨数量',
            width: 100,
            dataIndex: 'num',
        }, {
            title: '调拨单号',
            width: 220,
            dataIndex: 'transferCode',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '调出机构',
            width: 160,
            dataIndex: 'outShopName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '已出库数量',
            width: 100,
            dataIndex: 'stockIoNum',
        }, {
            title: '出库状态',
            width: 100,
            dataIndex: 'stockOutStatus',
            render: text => getTreeNodeName(text)
        }, {
            title: '出库时间',
            width: 180,
            dataIndex: 'transferOutTime',
        }, {
            title: '调入机构',
            width: 160,
            dataIndex: 'shopName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '已入库数量',
            width: 100,
            dataIndex: 'inStockIoNum',
        }, {
            title: '入库状态',
            width: 120,
            dataIndex: 'stockInStatus',
            render: text => getTreeNodeName(text)
        }, {
            title: '入库时间',
            width: 180,
            dataIndex: 'transferInTime',
        }, {
            title: '备注',
            width: 160,
            dataIndex: 'note',
        }
    ];

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}