import { useState } from "react";
import { createModel } from "hox";
import { vehicleService } from "./vehicleService";
import { message } from "antd"
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import useGlobalModel, { transPgToPagination, defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { useDictModel } from "../dict/dictModel";
import { useLockFn } from "ahooks";
import { getUserInfo } from "@/utils/dataStorage"
import moment from "moment"

export const useVehicleModel = createModel(function () {
    const { sourceApp, branch } = defaultMdCmParam
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName } } = useGlobalModel()
    const { getTreeNodeName } = useDictModel();
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false)
    //分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false); //  保存按钮
    //所有列表查询
    //列表查询
    const loadData = async (page, searchValue = searchParam) => {
        setLoading(true);
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await vehicleService.page(searchParams);
        const { records, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(records);
        setLoading(false);
    }
    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({
            ...searchParam,
            ...updateParam
        })
    }
    //重置搜索参数
    const resetSearchParam = async () => {
        await setSearchParam({});
        await setSelectedRowKeys([]);
        await loadData(initPgCfg, {});
    }
    /**
     * 表单详情
     */

    //编辑表单缓存
    const [editFormVal, setEditFormVal] = useState({});
    //新增表单缓存
    const [addFormVal, setAddFormVal] = useState({});
    //查看表单缓存
    const [lookFormVal, setLookFormVal] = useState({});

    //表单新增修改
    const updateAddFormVal = (val) => {
        val ? setAddFormVal({
            ...addFormVal,
            ...val
        }) : setAddFormVal({});
    }
    //表单新增修改
    const updateEditFormVal = (val) => {
        val ? setEditFormVal({
            ...editFormVal,
            ...val
        }) : setEditFormVal({});
    }

    //获取一条详情数据 Api
    const getVehicleOne = async (id, pattern) => {
        let { retData } = await vehicleService.one(id);
        let newRetData = {
            ...retData,
            registerDate: retData.registerDate ? moment(retData.registerDate) : null
        }
        if (retData && pattern === "edit") {
            setEditFormVal(newRetData);
        } else if (retData && pattern === "look") {
            setLookFormVal(newRetData);
        }
    }

    const setParams = (data) => {
        return {
            ...data,
            shopId,
            shopCode,
            shopName,
            orgName,
            orgCode,
            sourceApp,
            branch,
            enable: data.enable ? 1 : 0,
            categoryName: getTreeNodeName(data.categoryCode),
            brandName: getTreeNodeName(data.brandCode),
            registerDate: data.registerDate ? moment(data.registerDate).format("YYYY-MM-DD HH:mm:ss") : null
        }
    }

    //表单新增 Api
    const insertVehicle = useLockFn(async (data) => {
        setSaveBtnLoading(true)
        let params = setParams(data)
        let { retData } = await vehicleService.insert({ ...params, createUser: getUserInfo() ? getUserInfo().nickname : "未获取到", enable: 1 });
        retData ? message.success("车辆新增成功") : message.error("车辆新增失败");
        setSaveBtnLoading(false)
        return retData;
    })

    //表单编辑 Api
    const editVehicle = useLockFn(async (data) => {
        setSaveBtnLoading(true)
        let params = setParams(data)
        let { retData } = await vehicleService.update({ ...params,  updateUser: getUserInfo() ? getUserInfo().nickname : "未获取到" });
        retData ? message.success("车辆编辑成功") : message.error("车辆编辑失败");
        setSaveBtnLoading(false)
        return retData;
    })
    // 车辆状态
    const onDisabled = async (id, checked) => {
        let { retData } = await vehicleService.disable({ id, enable: checked ? 1 : 0 })
        retData ?
            loadData() && message.success("车辆编辑成功") : message.error("车辆编辑失败");
        return retData;
    }
    //导入
    const importVehicle = async (file) => {
        let { retData } = await vehicleService.import(file);
        retData ?
            loadData() && message.success("导入成功") : message.error("导入失败");
        return retData;
    }

    //导出
    const exportVehicle = async (searchValue = searchParam) => {
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            id: selectedRowKeys.join(),
        }
        let result = await vehicleService.export(searchParams);
        convertRes2Blob(result)
    }


    //下载模板
    const downloadTpl = async () => {
        let result = await vehicleService.downloadTpl();
        convertRes2Blob(result)
    }

    return {
        /***field***/
        searchParam, organType,
        dataSource,
        selectedRowKeys,
        editFormVal,
        addFormVal,
        lookFormVal,
        pgCfg,
        loading,
        /***method***/
        updateAddFormVal,
        updateEditFormVal,
        insertVehicle,
        editVehicle,
        setLoading,
        setPgCfg,
        getVehicleOne,
        setDataSource,
        setSelectedRowKeys,
        setSearchParam,
        resetSearchParam,
        updateSearchParam,
        loadData,
        importVehicle,
        exportVehicle,
        downloadTpl,
        setEditFormVal,
        setAddFormVal,
        onDisabled,
        setLookFormVal,setSaveBtnLoading,saveBtnLoading
    }
});