import React, { useEffect } from "react";
import { Table, Tooltip, Switch } from "antd";
import { useQuestionModel } from "../questionModel";
import { momentFormat } from "@/erp_subpackage/utils/util"


export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg, loading,
        loadData, setSelectedRowKeys, onDisabled
    } = useQuestionModel();

    useEffect(() => {
        loadData()
    }, [])

    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            fixed: "left",
            render: (text, record, index) => index + 1
        }, {
            title: '题库类型',
            width: 80,
            align: "center",
            dataIndex: 'typeName',
        }, {
            title: '问题编号',
            width: 140,
            align: "center",
            dataIndex: "code",
        }, {
            title: '问题',
            width: 360,
            dataIndex: "name",
            // render: (text) => <Tooltip placement="topLeft" title={text}><div className="one-line"><p>{text}</p></div></Tooltip>,
            render: (text) => <div>{text}</div>,
        }, {
            title: '答案选项',
            width: 260,
            dataIndex: 'details',
            render: text => {
                if (text && text.length) {
                    return <div className="ellipsis" style={{ width: "auto" }}>{text.map(item => <p key={item.id} style={{ margin: "0 0 4px 0" }}>{`${item.answerKey}.${item.answerValue}`}</p>)}</div>
                }
                return ""
            }
        }, {
            title: '创建时间',
            width: 160,
            dataIndex: 'createTime',
            align: "center",
            render: (text) => momentFormat(text)
        }, {
            title: '创建人',
            width: 140,
            dataIndex: 'createUser',
            align: "center",
        }, {
            title: '正确答案',
            width: 100,
            align: "center",
            dataIndex: "answer"
        }, {
            title: '操作',
            width: 60,
            align: "center",
            dataIndex: 'status',
            fixed: "right",
            render: (text, record) => (
                <Switch size="small" checked={record.status == 0} onChange={(checked, e) => {
                    e.stopPropagation()
                    onDisabled(record.id, checked);
                }} />
            ),
        }
    ];

    //点击行选高亮
    const onRow = (id) => {
        let findIndex = selectedRowKeys.indexOf(id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(id);
            setSelectedRowKeys(newSelectedRowKeys);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    return (
        <Table
            rowKey={record => record.id}
            loading={loading}
            rowSelection={{
                //全部选中时  (存string[])
                onSelectAll: (selected, selectedRows, changeRows) => {
                    let changeRowId = changeRows.map(item => item.id);
                    selected ? setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        : setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                },
                onSelect: (record) => onRow(record.id),
                selectedRowKeys: selectedRowKeys
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id),
                onContextMenu: (e) => {
                    e.preventDefault();
                    setSelectedRowKeys([record.id]);
                }
            })}
            scroll={dataSource.length ? { x: 'max-content', y: "calc(100vh - 430px)" } : { x: 'max-content' }}
        />
    )
}
