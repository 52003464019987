import React, { useEffect, useState } from "react"
import {  Form, Input, Col, Row,Switch, message,  Modal } from "antd"
import { vehicleBrandInsert, vehicleBrandUpdate, vehicleBrandOne } from "@/service/brandManage"

export default (props) => {
    const { mode, rowInfo, barndId, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()

    useEffect(() => {
        queryMotorcade()
    }, [])

    useEffect(() => {
        console.log(visible)
        console.log(rowInfo)
    }, [visible])
    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            vehicleBrandOne(rowInfo.id).then((res) => {
                let results = res.retData
                form.setFieldsValue({
                    ...results,
                    disableFlag: results.disableFlag === 0 ? true : false,
                })
            })
        } else {
            form.setFieldsValue({
                disableFlag: true,
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                disableFlag: values.disableFlag ? 0 : 1,
                type: "model"
            }
            if (mode === "edit") {
                data.id = rowInfo.id
                vehicleBrandUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                data.parentId = barndId
                vehicleBrandInsert(data).then((res) => {
                    message.success("新增成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    //取消
    const handleCancel = () => {
        setVisible("")
    }
    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    return (
        <Modal forceRender visible={visible} title="型号信息" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={1260}>
            <div className="form-details-warp">
                <Form form={form} {...formItemLayout}>
                    <Row gutter={100}>
                        <Col offset={3} span={9}>
                            <Form.Item
                                label="型号"
                                name="name"
                                // rules={[{ required: true, message: "请输入身份证号" }]}
                            >
                                <Input placeholder="请输入型号" disabled={isLook(mode)} />
                            </Form.Item>
                        </Col>
                        <Col offset={3} span={9}>
                            <Form.Item name="disableFlag" label="状态" valuePropName="checked">
                                <Switch disabled={isLook(mode)} checkedChildren="开启" unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
