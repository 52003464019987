import React from "react";
import { Button, message } from "antd";
import { DataTable } from "./DataTable";
import { DataTable1 } from "./DataTable1";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useJxcModel } from "../jxcModel";
import { formatNum } from '@/erp_subpackage/utils/util';

export const DataBox = () => {
    const { jxcOrGoodsDetail, setJxcOrGoodsDetail, descTotal, selectedRow, rowsDescTotal } = useJxcModel();
    const goodsDetail = () => {
        if (selectedRow.length !== 1) {
            message.warning("请选择单条数据进行编辑，点击鼠标右键可一键选中哦！");
            return;
        }
        setJxcOrGoodsDetail("goodsDetail")
    }
    const action = <>
        <Button
            className={`mr-5 ${jxcOrGoodsDetail === "jxcRecord" ? "tab-buts-active" : "btn-item"}`}
            type="link"
            shape="round"
            onClick={() => setJxcOrGoodsDetail("jxcRecord")}
        >进销存统计报表</Button>
        <Button
            className={`mr-5 ${jxcOrGoodsDetail === "goodsDetail" ? "tab-buts-active" : "btn-item"}`}
            type="link"
            shape="round"
            onClick={() => goodsDetail()}
        >商品明细账</Button>
    </>;

    const desc = [
        {
            label: "期末数量",
            value: !selectedRow.length ? descTotal.inventoryNum ?? 0 : rowsDescTotal.inventoryNum,
            color: "red"
        },
        {
            label: "期末金额",
            value: `${+(!selectedRow.length ? descTotal.lastestPriceAmount ?? 0 : rowsDescTotal.lastestPriceAmount) < 0 ? "￥-" : "￥"}
            ${formatNum(!selectedRow.length ? descTotal.lastestPriceAmount ?? 0 : rowsDescTotal.lastestPriceAmount)}`,
            color: "red"
        },
        {
            label: "入库数",
            value: !selectedRow.length ? descTotal.putStorageNum : rowsDescTotal.putStorageNum,
            color: "red"
        },
        {
            label: "入库金额",
            value: "￥" + formatNum(!selectedRow.length ? descTotal.putStorageAmount : rowsDescTotal.putStorageAmount),
            color: "red"
        },
        {
            label: "出库数",
            value: !selectedRow.length ? descTotal.putStorageAmount : rowsDescTotal.putStorageAmount,
            color: "red"
        },
        {
            label: "出库金额",
            value: "￥" + formatNum(!selectedRow.length ? descTotal.outStorageAmount : rowsDescTotal.outStorageAmount),
            color: "red"
        },
    ]
    return (
        <YhBox title=" "
            action={action}
            descriptions={desc}
        >
            {jxcOrGoodsDetail === "jxcRecord" ? <DataTable /> : <DataTable1 />}
        </YhBox>
    )
}