import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { soListStatistics, soListStatisticsExport, soListStatisticsTotal } from "@/service/statistics"
import { MyContext } from "@/store/myContext"
import moment from "moment"
import { message, Tooltip, Table, Button } from "antd"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { formatNum, formatNumFixed } from '@/erp_subpackage/utils/util';
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { YhBox } from "@/erp_subpackage/components/YhBox";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import "./statistics.less";


export default () => {
    const { state, Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [totalData, setotalData] = useState({}) //总计
    const { user: { shopId, shopCode, shopName, organType, orgCode, orgName }, fetchFmsShopList } = useGlobalModel()
    const [searchValue, setSearchValue] = useState({
        // startTime: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        // endTime: moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss").replace(/\s+/g, "T"),
        // customerName: "",
        // documentNumber: "",
        // createUser: "",
        // tradeTypeCode: "",
        // payType: "",
        // saleTypeCode: "",
        startTime: "",
        endTime: "",
        settlementEndTime: "",
        settlementEndTime: "",
    })
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => []);
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    //副标题计算
    useEffect(() => {
        if (state.activeMenu === "soListStatistics") {
            loadData(pagination.current)
        }

    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps


    //列表数据
    const loadData = (current, search = searchValue) => {
        setLoading(true)
        console.log()
        let data = {
            ...search,
            pageNum: current,
            pageSize: search?.pageSize ?? 10,
            shopCode,
            shopId,
            settlementStartTime: searchValue.settlementStartTime ? moment(searchValue.settlementStartTime).format("YYYY-MM-DD HH:mm:ss") : '',
            settlementEndTime: searchValue.settlementEndTime ? moment(searchValue.settlementEndTime).format("YYYY-MM-DD HH:mm:ss") : '',
            startTime: searchValue.startTime ? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss") : '',
            endTime: searchValue.endTime ? moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss") : '',
        }
        soListStatistics(data).then(({ retData }) => {
            setpagination({
                current: current,
                total: retData.total,
                pageSize: retData.size,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(retData.records)
        })
        soListStatisticsTotal(data).then(({ retData }) => {
            setotalData(retData)
        })
        setLoading(false)
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page, filters, sorter) => {
        setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    const handleExport = () => {
        let data = {
            ...searchValue,
            pageSize: -1,
            pageNum: 1,
            shopId,
            shopCode,
            settlementStartTime: searchValue.settlementStartTime ? moment(searchValue.settlementStartTime).format("YYYY-MM-DD HH:mm:ss") : '',
            settlementEndTime: searchValue.settlementEndTime ? moment(searchValue.settlementEndTime).format("YYYY-MM-DD HH:mm:ss") : '',
            startTime: searchValue.startTime ? moment(searchValue.startTime).format("YYYY-MM-DD HH:mm:ss") : '',
            endTime: searchValue.endTime ? moment(searchValue.endTime).format("YYYY-MM-DD HH:mm:ss") : '',

        }
        soListStatisticsExport(data).then((res) => {
            message.success(res.retData)
        })
    }

    //列表button
    // const topButtons = [
    //     {
    //         click: () => handleExport(),
    //         content: "导出",
    //     },
    // ]

    //列表button
    const topButtons = <>
        <Button
            className="btn-item"
            shape="round"
            onClick={() => handleExport()}
        >导出</Button>
    </>
    //table配置
    const columns = [
        {
            title: '序号',
            width: 80,
            align: "center",
            fixed: "left",
            render: (t, r, i) => i + 1
        }, {
            title: '销售单号',
            fixed: "left",
            width: 200,
            dataIndex: 'documentNumber',
            render: (text) => <YhTooltip text={text} />,
            // render: text => <Tooltip placement="topLeft" title={text} arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '客户名称',
            width: 200,
            dataIndex: 'customerName',
            render: (text) => <YhTooltip text={text} />,
            //render: text => <Tooltip placement="topLeft" title={text}arrowPointAtCenter><p className="ellipsis">{text}</p></Tooltip>
        }, {
            title: '单据状态',
            width: 100,
            dataIndex: 'status',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,

        }, {
            title: '客户类型',
            width: 100,
            dataIndex: 'customerTypeName',
            render: (text) => <YhTooltip text={text} />,

        }, {
            title: '客户销售频次',
            width: 100,
            dataIndex: 'salesFrequency',
        }, {
            title: '单据类型',
            width: 150,
            dataIndex: 'tradeTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '销售类型',
            width: 150,
            dataIndex: 'salesTypeName',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '结算类型',
            width: 150,
            dataIndex: 'payType',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
            //render: text => getTreeNodeName(text)
        }, {
            title: '单据状态',
            width: 150,
            dataIndex: 'status',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
            // render: text => getTreeNodeName(text)
        }, {
            title: '支付状态',
            width: 150,
            dataIndex: 'payStatus',
            render: (text) => <YhTooltip text={getTreeNodeName(text)} />,
            //render: text => getTreeNodeName(text)
        }, {
            title: '销售金额',
            width: 150,
            dataIndex: 'totalAmount',
            render: (text) => `￥${text}`
        }, {
            title: '优惠金额',
            width: 150,
            dataIndex: 'discountTotalAmount',
            render: (text) => `￥${text}`
        }, {
            title: '应收金额',
            width: 150,
            dataIndex: 'receivedAmount',
            render: (text) => `￥${text}`
        }, {
            title: '已收金额',
            width: 150,
            dataIndex: 'receivableAmount',
            render: (text) => text ? '￥' + text : '￥0'
            // }, {
            //     title: '已付金额',
            //     width: 120,
            //     dataIndex: 'paidAmount',
            //     render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '销售成本',
            width: 150,
            dataIndex: 'totalCost',
            render: (text) => `￥${text}`
        }, {
            title: '销售毛利',
            width: 150,
            dataIndex: 'grossProfit',
            render: (text) => text ? '￥' + text : '￥0'
        }, {
            title: '制单员',
            width: 150,
            dataIndex: 'createUser',
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '制单日期',
            width: 160,
            dataIndex: 'createTime',
        }, {
            title: '结算日期',
            width: 160,
            dataIndex: 'settleTime',

        }, {
            title: '客户类型',
            width: 150,
            dataIndex: 'customerTypeName',
            render: (text) => <YhTooltip text={text} />,
        }
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "销售单号",
                valueName: "documentNumber",
            },
            {
                type: "input",
                placeholder: "制单员",
                valueName: "createUser",
            },
            {
                type: "RangePicker",
                valueName: ["settlementStartTime", "settlementEndTime"],
                placeholder: ['结算开始时间', '结算结束时间'],
                span: 16,
                xxl: 8,
            },

        ],
        hideList: [
            {
                type: "select",
                placeholder: "销售类型",
                valueName: "saleTypeCode",
                optionList: () =>
                    getSelectedDictOne(DictCodeEnmu.SALE_TYPE).map((item) =>
                    (
                        <Option key={item.key} value={item.value}>
                            {item.label}
                        </Option>
                    )),
            },
            {
                type: "select",
                placeholder: "结算类型",
                valueName: "payType",
                optionList: () =>
                    getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item) =>
                    (
                        <Option key={item.key} value={item.value}>
                            {item.label}
                        </Option>
                    )),
            },
            {
                type: "select",
                placeholder: "单据类型",
                valueName: "tradeTypeCode",
                optionList: () =>
                    getSelectedDictOne(DictCodeEnmu.TRADE_TYPE).map((item) =>
                    (
                        <Option key={item.key} value={item.value}>
                            {item.label}
                        </Option>
                    )),
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                placeholder: ['开单开始时间', '开单结束时间'],
                span: 16,
                xxl: 8,
            },

        ],
    }
    const desc = [
        {
            label: "销售数量",
            value: totalData.totalNum,
            color: "red"
        },
        {
            label: "销售金额",
            value: "￥" + formatNum(totalData.payAmount ?? 0),
            color: "red"
        },
        {
            label: "应收金额",
            value: "￥" + formatNum(totalData.receivableAmount ?? 0),
            color: "red"
        },
        {
            label: "已收金额",
            value: "￥" + formatNum(totalData.receivedAmount ?? 0),
            color: "red"
        },
        {
            label: "已付金额",
            value: "￥" + formatNum(totalData.paidAmount ?? 0),
            color: "red"
        },
        {
            label: "销售成本",
            value: "￥" + formatNumFixed(totalData.costAmount ?? 0, 3),
            color: "red"
        },
        {
            label: "销售毛利",
            value: "￥" + formatNumFixed(totalData.grossProfitAmount ?? 0, 3),
            color: "red"
        },

    ]

    return (
        <>
            {state.activeMenu === 'soListStatistics' && (
                <>
                    <TopSearch
                        title="销售单报表"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <YhBox title="销售单报表" action={topButtons} descriptions={desc} style={{ marginTop: 20, marginLeft: 0 }}>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={list}
                            pagination={{
                                ...pagination,
                                position: ['bottomCenter'],
                                showTotal: () => `共${pagination.total}条`,
                                onChange: (current, serach = searchValue) => {
                                    setSearchValue({ ...searchValue, pageSize: serach })
                                    loadData(current, searchValue)
                                },
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </YhBox>
                </>
            )}
        </>
    )
}
