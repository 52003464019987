import { post, get } from "../utils/request"
import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";

/**
 * 客户统计
 */
//最新数据
export const customerLastData = (data) => {
    return get("/ci-customer/analyze/customer/last_data")
}

//分析统计数据
export const customerStatistics = (data) => {
    return post("/ci-customer/analyze/customer/statistics", data)
}

//最新数据
export const customerPage = (data) => {
    return post("/ci-customer/analyze/customer/page",data)
}

//机构收益分析
export const organIncome = (data) => {
    return post("/ci-analyze/organ/income",data)
}

//机构收益分析导出
export const organIncomeExport = (data) => {
    return post("/ci-analyze/organ/income/export",data)
}

//业务经理收益分析
export const businessEliteIncome = (data) => {
    return post("ci-analyze/business-elite/income",data)
}


//业务经理收益分析导出
export const businessEliteIncomeExport = (data) => {
    return post("ci-analyze/business-elite/income/export",data)
}


//调拨数据检测分析
export const transferDataMonitor = (data) => {
    const apiUrl = `sms/transfer/to/statistics`;
    return httpRequest.post(apiUrl, data,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
}
//调拨数据检测统计分析
export const transferDataMonitorTotal = (data) => {
    const apiUrl = `sms/transfer/to/top/statistics`;
    return httpRequest.post(apiUrl, data,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
}

//调拨数据检测导出
export const transferDataMonitorExport = (data) => {
    const apiUrl = `sms/transfer/to/statistics/export`;
    return httpRequest.postFile(apiUrl, data,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
}



//调入调出监测分析
export const transferMonitor = (data) => {
    const apiUrl = `sms/transfer/to/allMonitoring`;
    return httpRequest.post(apiUrl, data,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
}

//调入调出监测统计分析
export const transferMonitorTotal = (data) => {
    const apiUrl = `sms/transfer/to/allMonitoring`;
    return httpRequest.post(apiUrl, data,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
}

//调入调出监测导出
export const transferMonitorExport = (data) => {
    const apiUrl = `sms/transfer/to/allMonitoring/export`;
    return  httpRequest.postFile(apiUrl, data,{ baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
}


//采购单报表分析
export const poListStatistics = async (data) => {
    return await post("/ci-storage/purchase/form",data)
}
//采购单报表统计分析
export const poListStatisticsTotal = (data) => {
    return post("/ci-storage/purchase/statistics",data)
}

//采购单报表导出
export const poListStatisticsExport = (data) => {
    return post("ci-analyze/purchase/statistics/export",data)
}



//采购明细报表分析
export const poStatistics = (data) => {
    return post("/ci-storage/statistics/purchaseDetailsStatistics",data)
}
//采购明细报表统计分析
export const poStatisticsTotal = (data) => {
    return post("/ci-storage/statistics/purchaseDetailsStatisticsTotal",data)
}

//采购明细报表导出
export const poStatisticsExport = async(data) => {
    return await post("ci-analyze/purchase/detailsStatistics/export",data)
}



//销售单报表分析
export const soListStatistics = (data) => {
    return post("ci-storage/sale/statistics",data)
}
//销售单报表统计分析
export const soListStatisticsTotal = (data) => {
    return post("ci-storage/sale/statisticsTotal",data)
}

//销售单报表导出
export const soListStatisticsExport = (data) => {
    return post("ci-analyze/sale/statistics/export",data)
}



//销售明细报表分析
export const soStatistics = (data) => {
    return post("ci-storage/sale/order/statistics",data)
}
//销售明细报表统计分析
export const soStatisticsTotal = (data) => {
    return post("ci-storage/sale/order/statisticsTotal",data)
}

//销售明细报表导出
export const soStatisticsExport = (data) => {
    return post("ci-analyze/sale/order/statistics/export",data)
}



//维修单报表分析
export const repairListStatistics = (data) => {
    return post("/ci-analyze/order/statistics/maintenance/analyze",data)
}
//维修单报表统计分析
export const repairListStatisticsTotal = (data) => {
    return post("/ci-analyze/order/statistics/maintenance/analyze/top",data)
}

//维修单报表导出
export const repairListStatisticsExport = (data) => {
    return post("/ci-analyze/order/statistics/maintenance/analyze/export",data)
}



//维修明细报表分析
export const repairReportStatistics = (data) => {
    return post("/ci-analyze/order/statistics/maintenance/detail/analyze",data)
}
//维修明细报表统计分析
export const repairReportStatisticsTotal = (data) => {
    return post("/ci-analyze/order/statistics/maintenance/detail/analyze/top",data)
}

//维修明细报表导出
export const repairReportStatisticsExport = (data) => {
    return post("/ci-analyze/order/statistics/maintenance/detail/analyze/export",data)
}

//业务数据监控
export const moniToring = (data) => {
    return post("ci-storage/data/monitoring/page",data)
}
//业务数据监控统计分析
export const moniToringTotal = (data) => {
    return post("ci-storage/data/monitoring/statistics",data)
}

//业务数据监控导出
export const moniToringExport = (data) => {
    return post("/ci-analyze/data/monitoring/export",data)
}

//账单检测导出·
export const billMonitor = (data) => {
    return post("ci-storage/bill/monitoring/page",data)
}
//账单检测导出·统计分析
export const billMonitorTotal = (data) => {
    return post("ci-storage/bill/monitoring/statistics",data)
}


// 账单检测导出·
export const billMonitorExport =(data)=>{
    return post("/ci-analyze/bill/monitoring/export",data)
}

//进销存统计表
export const JXCStatistics = (data) => {
    return post("ci-storage/stock/from",data)
}
//商品统计表
export const JXCgoodsStatistics = (data) => {
    return post("ci-storage/stock/purchasePin",data)
}
//进销存统计表统计分析
export const JXCStatisticsTotal = (data) => {
    return post("ci-storage/data/monitoring/statistics",data)
}

//进销存统计表导出
export const JXCStatisticsExport = (data) => {
    return post("/ci-analyze/stock/from/export",data)
}
//商品统计表导出
export const JXCgoodsStatisticsExport = (data) => {
    return post("/ci-analyze/stock/purchasePin/export",data)
}

/**
 * 出入库记录明细
 */
//出入库记录明细最新数据
export const stockInAndOutPage = (data) => {
    return post("/ci-storage/stockIo/details/findPage",data)
}

//出入库记录明细统计数据
export const stockInAndOutStatistics = (data) => {
    return post("/ci-storage/stock/details/statistics", data)
}

// 导出·
export const stockInAndOutExport =(data)=>{
    return post("/ci-analyze/stockIo/export",data,{ timeout: 36000000 })
}