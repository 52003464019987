import { httpRequest } from "@/erp_subpackage/utils/HttpRequest";
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from "@/config/app";
import { getPortalToken } from "@/utils/dataStorage";
// export interface IDetailList {
//     amount?: number,  // 0,
//     confirmNum?: number,  // 0,
//     damageNum?: number,  // 0,
//     id?: string,  // "",
//     inTotal?: number,  // 0,
//     materialCode?: string,  // "",
//     materialName?: string,  // "",
//     note?: string,  // "",
//     num?: number,  // 0,
//     price?: number,  // 0,
//     advancedCode?: string,  // "",
//     shelfCode?: string,  // "",
//     shelfId?: string,  // "",
//     warehouseCode?: string,  // "",
//     warehouseName?: string,  // ""
//     [key: string]: any
// }

// export interface IPurchaseAdvancedFields {
//     arrivedNum?: number,     // 0,
//     bentityCode?: string,     // "",
//     bentityName?: string,     // "",
//     contact?: string,     // "",
//     createTime?: string,     // "",
//     createUser?: string,     // "",
//     damageTotalNum?: number,     // 0,
//     deliveryCode?: string,     // "",
//     deliveryMethod?: string,     // "",
//     deliveryMethodName?: string,     // "",
//     detailedAddress?: string,     // "",
//     id?: string,     // "",
//     invoiceStatus?: number,     // 0,
//     invoiceTypeCode?: string,     // "",
//     invoiceTypeName?: string,     // "",
//     isDirect?: string,     // "",
//     isSupplement?: number,     // 0,
//     materialTypeCode?: string,     // "",
//     materialTypeName?: string,     // "",
//     note?: string,     // "",
//     orderCode?: string,     // "",
//     paidAmount?: number,     // 0,
//     payMethodName?: string,     // "",
//     payStatus?: string,     // "",
//     payType?: string,     // "",
//     payableAmount?: number,     // 0,
//     phone?: string,     // "",
//     advancedCode?: string,     // "",
//     purchaseTypeCode?: string,     // "",
//     purchaseTypeName?: string,     // "",
//     reckoner?: string,     // "",
//     returnTotalNum?: number,     // 0,
//     settleTime?: string,     // "",
//     shopId?: string,     // "",
//     shopName?: string,     // "",
//     status?: string,     // "",
//     stockIoNum?: number,     // 0,
//     stockIoTime?: string,     // "",
//     storekeeper?: string,     // "",
//     supplierCode?: string,     // "",
//     supplierName?: string,     // "",
//     totalNum?: number,     // 0,
//     updateTime?: string,     // "",
//     updateUser?: string,     // "",
//     writeOffTime?: string,     // ""
//     saleOrderCode?: string,
//     favorableAmount?: number,
//     details?: IDetailList[] | null,
//     [key:string] 
// }

// export interface IPurchaseAdvancedStatistics {
//     totalNum: number,
//     totalAmount: number,
//     paidAmount: number
// }

class PurchaseAdvancedService {
    async page(searchVo) {
        const apiUrl = "/ci-storage/advanced/page";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async total(searchVo) {
        const apiUrl = "/ci-storage/advanced/total";
        return await httpRequest.post(apiUrl, searchVo);
    }
    async one(id) {
        const apiUrl = `/ci-storage/advanced/findById/${id}`;
        return await httpRequest.get(apiUrl);
    }

    async insert(insertVo) {
        const apiUrl = "/ci-storage/advanced/insert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    async update(updateVo) {
        const apiUrl = "/ci-storage/advanced/update";
        return await httpRequest.post(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params) {
        const apiUrl = "/ci-storage/advanced/materialDelete";
        return await httpRequest.post(apiUrl, params);
    }
    //提交
    async decisionSubmit(params) {
        const apiUrl = "/ci-storage/advanced/submit";
        return await httpRequest.post(apiUrl, params);
    }
    //操作处理
    async onSwitchChange(params) {
        const apiUrl = "/ci-storage/advanced/confirm";
        return await httpRequest.post(apiUrl, params);
    }
    //操作处理
    async transferSubmit(params) {
        const apiUrl = "/ci-storage/advanced/transfer";
        return await httpRequest.post(apiUrl, params);
    }
    //导出
    async export(params) {
        const apiUrl = "ci-analyze/purchase/export";
        return await httpRequest.post(apiUrl, params);
    }
    //作废
    async invalidate(params) {
        const apiUrl = "/ci-storage/purchase/invalidate";
        return await httpRequest.post(apiUrl, params);
    }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/ci-storage/purchase/detail/downloadTpl";
        return await httpRequest.getFile(apiUrl);
    }
    //库房确认
    async warehousing(params) {
        const apiUrl = "/ci-storage/purchase/warehousing";
        return await httpRequest.post(apiUrl, params);
    }
    //采购结算
    async settle(params) {
        const apiUrl = "/ci-storage/purchase/settlePro";
        return await httpRequest.post(apiUrl, params);
    }
    //采购加盟商结算
    async settleJoin(params) {
        const apiUrl = "/ci-storage/purchase/settleJoin";
        return await httpRequest.post(apiUrl, params);
    }
    //采购补单
    async supplementInsert(insertVo) {
        const apiUrl = "/ci-storage/purchase/supplementInsert";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //转接销售单
    async transfer(insertVo) {
        const apiUrl = "/ci-storage/purchase/transfer";
        return await httpRequest.post(apiUrl, insertVo);
    }
    //详情导出
    async detailsExport(params) {
        const apiUrl = "/ci-storage/purchase/detailsExport";
        return await httpRequest.postFile(apiUrl, params);
    }
    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/purchase/print/${id}`;
        return await httpRequest.get(apiUrl);
    }

    //查询特定得收款账户
    async accountFind(searchVo) {
        const apiUrl = 'fms/account/find'
        return await httpRequest.post(apiUrl, searchVo, { baseURL: PORTAL_API_SERVER_HOST, token: getPortalToken() });
    }
    //本机构下商品已有货位查询
    async getMaterialShelf(obj) {
        const apiUrl = "/ci-storage/purchase/getMaterialShelf";
        return await httpRequest.post(apiUrl, obj);
    }
}

export const purchaseAdvancedService = new PurchaseAdvancedService();