import React, { useState, useEffect } from "react";
import { Table, Button, Input, message } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { purchaseDrService } from "@/erp_subpackage/pages/purchase/dr/purchaseDrService";
import { useGlobalModel } from "@/erp_subpackage/model/globalModel";
import { usePurchasePoDetailsModel } from "./purchasePoDetailModel";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

const initPgCfg = { pageSize: 10, current: 1 };

export const StockDialog = ({ modeKey, mode, rowCode, dispatch }) => {
	const { stockVisible: visible, setStockVisible } = usePurchasePoDetailsModel(({ stockVisible }) => [stockVisible])
	//查询条件
	const [searchParam, setSearchParam] = useState({});
	//列表数据源
	const [dataSource, setDataSource] = useState([]);
	const [selectMaterial, setSelectMaterial] = useState([]);
	//列表数据源分页参数
	const [pgCfg, setPgCfg] = useState(initPgCfg);
	const [loading, setLoadding] = useState(false);
	const { setOccupyStockInfo, setWaitStockInfo,setMaterialInfo } = useGlobalModel(() => []);


	useEffect(() => {
		if (visible) {
			loadData(initPgCfg)
		} else {
			setSelectMaterial([]);
		};
	}, [visible])

	const onCancel = () => {
		setStockVisible(false);
	};

	//加载商品数据
	const loadData = async (page, searchValue = searchParam) => {
		setLoadding(true);
		try {
			const searchParams = {
				...searchValue,
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? initPgCfg.current,
			}
			const { retData } = await purchaseDrService.eachSystemFindPage(searchParams);
			const { list, ...pg } = retData;
			setPgCfg(transPgToPagination(pg));
			setDataSource((list ?? []).map((item, index) => ({ ...item, id: index })));
			setLoadding(false);
		} catch (err) { setLoadding(false) }
	}

	const column = [
		{
			title: '序号',
			width: 50,
			fixed: "left",
			align: "center",
			render: (t, r, i) => <>{i + 1}</>
		}, {
			title: '商品编号',
			width: 160,
			dataIndex: 'materialCode',
			fixed: "left",
			// render: (text) => <p className="ellipsis" title={text}>{text}</p>
			render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
		}, {
			title: '商品名称',
			width: 160,
			dataIndex: 'materialName',
			fixed: "left",
			render: (text) => <YhTooltip text={text} />,
		}, {
			title: '品牌',
			width: 120,
			dataIndex: 'brandName',
		}, {
			title: '所属门店',
			width: 160,
			dataIndex: 'shopName',
		}, {
			title: '仓库',
			width: 160,
			dataIndex: 'warehouseName',
		}, {
			title: '库存',
			width: 100,
			dataIndex: 'inventoryNum',
		}, {
			title: '占用库存',
			width: 100,
			dataIndex: 'inventoryOccupied',
			render: (text, record) => <p className="one-line click-span" onClick={(e) => {
				e.stopPropagation();
				setOccupyStockInfo(true, {
					materialCode: record.materialCode
				});
			}}>{text}</p>,
		}, {
			title: '待入库库存',
			width: 100,
			dataIndex: 'inventoryWait',
			render: (text, record) => <p className="one-line click-span" onClick={(e) => {
				e.stopPropagation();
				setWaitStockInfo(true, {
					materialCode: record.materialCode
				});
			}}>{text}</p>,
		}, {
			title: '可用库存',
			width: 100,
			dataIndex: 'availableStock',
		}, {
			title: '单价',
			width: 100,
			dataIndex: 'price',
		}, {
			title: '商品类型',
			width: 160,
			dataIndex: 'typeName',
		}, {
			title: '单位',
			width: 120,
			dataIndex: 'unitName',
		},
		// {
		// 	title: '货位',
		// 	width: 100,
		// 	dataIndex: 'shelfCode',
		// }
	];



	const rightChildren = <div className="flex">
		<Input
			allowClear
			size="small"
			className="border-r-16 mr-10"
			value={searchParam.materialCode}
			style={{ width: "150px" }}
			onChange={(e) => { setSearchParam({ ...searchParam, materialCode: e.target.value?.trim() }) }}
			placeholder="商品编号"
			onPressEnter={() => loadData(initPgCfg)}
		/>
		<Input
			allowClear
			size="small"
			className="border-r-16 mr-10"
			value={searchParam.materialName}
			style={{ width: "150px" }}
			onChange={(e) => { setSearchParam({ ...searchParam, materialName: e.target.value }) }}
			placeholder="商品名称"
			onPressEnter={() => loadData(initPgCfg)}
		/>
		<Button onClick={() => loadData(initPgCfg)} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
		<Button onClick={() => {
			setDataSource([]);
			setPgCfg(initPgCfg)
			setSearchParam({})
		}} shape="round">重置</Button>
	</div>

	return <YHModal
		visible={visible}
		okText="确认"
		onOk={onCancel}
		onCancel={onCancel}
		title="各门店库存"
		width={1050}
		bodyHeight={730}
		showFooter={true}
	>
		<YhBox title="库存列表" rightChildren={rightChildren} style={{ margin: "0" }}>
			<Table
				loading={loading}
				rowKey={record => record.id}
				columns={column}
				dataSource={dataSource}
				pagination={{
					...pgCfg,
					position: ["bottomCenter"],
					showTotal: (total) => `共${total}条`,
					onChange: (pageNum, pageSize) => {
						loadData({ pageNum, pageSize })
					}
				}}
				scroll={{ x: 'max-content' }}
			/>
		</YhBox>
	</YHModal>
}