import React, { useState } from "react";
import { Button, Table, Input, message, Upload, Tooltip, Spin } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { EditableRow, EditableCell } from "@/erp_subpackage/components/YHEditTable/editTable";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { formatInt, formatNum, formatDiscount, getRandomStr,bigNumber } from "@/erp_subpackage/utils/util";
import { usePurchaseDrDetailsModel } from "./purchaseDrDetailsModel";
import { importPropsConfig } from "@/erp_subpackage/utils/importFile";
import { warehouseService } from "@/erp_subpackage/pages/base/warehouse/warehouseService";
import { DictCodeEnmu } from "../../../../../utils/DictCodeEnum";
import { purchaseDrService } from "../../purchaseDrService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { BatchSelectWarehouseModal } from './BatchSelectWarehouseModal'

export const TableBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const { user: { shopId, shopName, shopCode, orgCode, orgName }, whDs, setMaterialInfo } = useGlobalModel();
    const { editList, addList, lookList, addFormVal, editFormVal, updateList, removeMaterial,setSaveBtn,
        setMaterialDialog, pDrDownload, exportPDrdetail
    } = usePurchaseDrDetailsModel();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchParms, setSearchParms] = useState("");
    const [searchVal, setSearchVal] = useState({ data: [], isSearch: false });   //用于搜索展示的数据
    //货位实时数据
    const [shelfDs, setShelfDs] = useState([])
    const tableList = mode === "edit" ? editList : mode === "look" ? lookList : addList;
    const formVal = mode === "edit" ? editFormVal : addFormVal;
    const [uploading, setUploading] = useState({ spinning: false, tip: "加速上传中..." });

    //批量选择仓库
    const [batchSelectWarehouseModal, setBatchSelectWarehouseModal] = useState(false)
    const [batchSelectWarehouseList, setBatchSelectWarehouseList] = useState([])


    //搜索  前端自己保存搜索的值， 搜索值为空还是用缓存
    const goodsFilter = (dataSource = null) => {
       // debugger
        let goodsList = dataSource ? dataSource : [...tableList];
        console.log(searchParms)
        let params = searchParms.trim()
        setSearchParms(params)
        if (params) {
            let reg = new RegExp(params);
            let arr = [];
            goodsList.forEach(item => {
                if (item.materialCode?.match(reg) || item.materialName?.match(reg)) {
                    arr.push(item);
                }
            })
            setSearchVal({ data: arr, isSearch: true });

            if (!arr.length) {
                message.warning("列表没有该商品！！")
            }
        } else {
            setSearchVal({ data: [], isSearch: false });
        }
    }


    //添加临时货位
    const addTempShelf = async (whCode, shelfList) => {
        let num = 1
        let code = "临时货位" + num;
        for (let i = 0; i < shelfList.length; i++) {
            let item = shelfList[i]
            if (item.code === ("临时货位" + num)) {
                num++;
                i = -1;
                code = "临时货位" + num;
            }
        }

        //查询对应仓库信息
        let findWh = whDs.find(item => item.value === whCode);
        let { retData } = await warehouseService.shelfInsert({
            code,
            orgCode,
            orgName,
            shopCode,
            shopId,
            shopName,
            sourceApp: "ci",
            branch: "ci",
            warehouseCode: findWh?.value,
            warehouseId: findWh?.id,
            warehouseName: findWh?.label,
        });
        message.success("临时货位新增成功");
        queryShelf(whCode)
    }

    //货位根据仓库code查询
    const queryShelf = async (whCode) => {
        const searchParams = {
            shopId, shopName, shopCode,
            warehouseCode: whCode,
            pageSize: -1,
            pageNum: 1,
        }
        let { retData } = await warehouseService.shelfPage(searchParams);
        const { records, ...pg } = retData;
        let shelfDs = records.filter(item => item.status).map(item => ({
            id: item.id,
            label: item.code,
            value: item.code
        }))
        shelfDs.push({
            label: <span style={{ color: "blue", cursor: "pointer" }} onClick={() => addTempShelf(whCode, records)}>添加临时货位</span>,
            value: "ci_linshihuowei",
            disabled: true
        })
        setShelfDs(shelfDs)
    }

    //货位触焦 
    const shelfFocus = (record) => {
        if (record["inWarehouseCode"]) {
            queryShelf(record["inWarehouseCode"])
        } else {
            setShelfDs([]);
        }
    }


    //导入明细成功
    // const upLoadCallback = async (list) => {
    //     try {
    //         if (list && list.length) {
    //             let newGoodsList = [...tableList];
    //             const repetition = [];
    //             for (let i = 0; i < list.length; i++) {
    //                 let material = list[i];
    //                 let isExist = newGoodsList.find((item) => item.purchaseDetailsId === material.purchaseDetailsId);
    //                 if (!isExist) {
    //                     let { retData } = await purchaseDrService.getMaterialShelf({
    //                         shopId,
    //                         materialCode: material.materialCode
    //                     })
    //                     newGoodsList.push({
    //                         ...material,
    //                         id: "",
    //                         key: material.id ?? getRandomStr(),
    //                         num: material.num ?? 1,
    //                         price: Number(material.price ?? 0),
    //                         inWarehouseCode: retData?.warehouseCode,
    //                         inWarehouseName: retData?.warehouseName,
    //                         inShelfCode: retData?.shelfCode,
    //                         inShelfId: retData?.shelfId,
    //                         //总计=单价*（1-下浮）*开单数量
    //                         amount: +formatNum(Number(material.price ?? 0) * (1 - Number(material.discountRate ?? 0)) * Number(material.num ?? 1)),
    //                     })
    //                 } else {
    //                     repetition.push(material.name ?? material.materialName);
    //                 }
    //             }
    //             !!repetition.length && message.warning(`商品名称：【${repetition.join(",")}】，重复采购单号配件的已过滤。`);
    //             updateList(mode, newGoodsList);
    //         }
    //         setUploading({ spinning: false })
    //     } catch (err) {
    //         console.error(err);
    //         setUploading({ spinning: false })
    //     }
    // };

      //导入明细成功
      const upLoadCallback = (list) => {
        // setButtonControl(true)
        if (!list?.length) return message.warning('请导入正确格式的配件')
        const isEdit = mode === 'edit'
        const goodsList = [...(isEdit ? editList : addList)]
        let newShelfRows= []
        let str = ''
        list.forEach((item) => {
            let num = item.num ?? 0
            let price = item.price
            if (!item.success) {
                str += `【${item.msg}】`
            }
            if (item.inventoryDetailsList && item.inventoryDetailsList.length) {
                let data = item.inventoryDetailsList.map((item) => {
                    return {
                        ...item,
                        // createTime: Number(item.createTime),
                    }
                })
                const tempArr = data.sort((a, b) => a.createTime - b.createTime)
                for (let i = 0; i < tempArr.length; i++) {
                    let goods = tempArr[i]
                    // 公共字段
                    let publicData = {
                        id: '',
                        key: goods.id,
                        purchaseDetailsId: goods.id,
                        materialCode: goods.materialCode,
                        materialName: goods.materialName, //商品名称
                        price: bigNumber.times(price,(1 - Number(item.discountRate ?? 0))).toFixed(2) ,
                        prePrice: price,
                        costPrice: goods.costPrice,
                        purchaseCode: goods.bussCode,
                        inventoryNum: goods.inventoryQuantity - goods.occupyNum,
                        typeName: goods.typeName,
                        typeCode: goods.typeCode,
                        outWarehouseCode: goods.warehouseCode, //仓库编码
                        outWarehouseName: goods.warehouseName, //仓库编码
                        outShelfCode: goods.shelfCode,
                        replaceCode: goods.replaceCode,
                        unitName: goods.unitName,
                        unitCode: goods.unitCode,
                        latestPurPrice: goods.latestPurPrice,
                        discountRate:item.discountRate
                    }
                    // 可用库存 = 库存数量 - 占用库存
                    let kynum = goods.inventoryQuantity - goods.occupyNum
                    //当匹配到最后一个配件, 可用库存不满足用户填写数量, 那就设置该配件为异常配件, 数量设置为减完前面配件可用库存剩余的数量
                    if (i === tempArr.length - 1 && kynum < num) {
                        newShelfRows.push({
                            ...publicData,
                            isHave: true,
                            error: item.msg,
                            num, //开单数量
                            confirmNum: num ?? 1,
                            preAmount: +formatNum(Number(price ?? 0) * Number(num ?? 1)),
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            amount: +formatNum(Number(price ?? 0) * (1 - Number(item.discountRate ?? 0)) * Number(num ?? 1)),
                        })
                        break
                    }
                    if (num <= 0) break
                    if (kynum) {
                        num = num - kynum
                        // 当 可用数量 小于
                        let tempNum = num < 0 ? kynum + num : kynum
                        newShelfRows.push({
                            ...publicData,
                            isHave: item.success,
                            msg: item.msg,
                            num: tempNum, //开单数量
                            confirmNum: tempNum ?? 1,
                            preAmount: +formatNum(Number(price ?? 0) * Number(tempNum ?? 1)),
                            //总计=单价*（1-下浮）*（单品开单数量-单品赠品数量）-单品优惠
                            amount: +formatNum(Number(price ?? 0) * (1 - Number(item.discountRate ?? 0)) * Number(tempNum ?? 1)),
                        })
                    }
                }
            }
        })
        updateList(mode, newShelfRows);
        setUploading({ spinning: false })
        message.success(str ? str : '导入成功', 20, 'info')
    }

    const wsChanges = async (value,option, record) => {
        let params = {
            shopId,
            warehouseCode: value,
            materialCode: record.materialCode,
        }
        const {
            retData: { shelfId, shelfCode },
        } = await warehouseService.getMaterialShelfTemporary(params)
        let dataSource = tableList
        const index = dataSource.findIndex((item) => record.key === item.key)
        const item = dataSource[index]
        // if (shelfCode && shelfId) {
        if (shelfCode ) {
            dataSource.splice(index, 1, { ...item, inShelfCode: shelfCode, inShelfId: shelfId })
        } else {
            dataSource.splice(index, 1, { ...item, inShelfCode: '', inShelfId: '', inWarehouseCode: '', inWarehouseName: '' })
            message.error('该仓库下没有临时货位')
        }
        updateList(mode, dataSource);
    }


    //多选仓库
    const toChooseWs = () => {
        let dataSource = tableList.filter((item) => !item.inWarehouseCode)
        // dataSource = dataSource.filter((i) => selectedRowKeys.includes(i.id))
        // dataSource.filter((item, index) => {
        //     isCheck = !item.inWarehouseCode
        //     !isCheck && message.error(`${item.materialName}有仓库，不能批量设置`)
        //     return isCheck
        // })
        if (dataSource.length > 0) {
            setBatchSelectWarehouseList(dataSource)
            setBatchSelectWarehouseModal(true)
        } else {
            message.error(`没有要设置仓库的配件`)
        }
    }

    const importUrl = formVal.branchOut === "vrm" ?
        `vrm2/stock/details/allocationImport/${formVal.outShopId}/${shopId}/${formVal.branchIn}`
        : formVal.branchOut === "ci" ?
            `/ci-storage/stock/details/allocationImport/${formVal.outShopId}/${shopId}/${formVal.branchIn}`
            : formVal.branchOut === "scm" ?
                `scm2/sale/so/allocationImport/${formVal.outShopId}/${shopId}/${formVal.branchIn}`
                : `bcm/stock/details/allocationImport/${formVal.outShopId}/${shopId}/${formVal.branchIn}`
    const action = <div>
        {mode === "add" && <>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    console.log(formVal)
                    if (!formVal.outShopCode) {
                        message.warning("请选择调出机构");
                        return false;
                    }
                    setMaterialDialog(true)
                }}
            >新增商品</Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    if (!selectedRowKeys.length) {
                        message.warning("请选择你要删除的数据");
                        return;
                    }
                    removeMaterial(mode, selectedRowKeys);
                    setSelectedRowKeys([])
                }}
            >删除商品</Button>
            <Button
                className="btn-item"
                shape="round"
                onClick={() => {
                    pDrDownload()
                }}
            >下载模板</Button>
        </>}
        {/* <Button
            className="btn-item"
            shape="round"
            hidden={mode !== "edit"}
            onClick={() => {
                exportPDrdetail()
            }}
        >导出</Button> */}
        <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(importUrl, upLoadCallback, {}, () => setUploading({ spinning: true, tip: "正在导入中..." }))}>
            <Button
                disabled={!formVal.branchOut}
                className="btn-item"
                shape="round">导入</Button>
        </Upload>
        <Button className="mr-5 btn-item" type="link" shape="round" onClick={toChooseWs}>
            选择仓库
        </Button>
    </div>;

    const rightChildren = <div className="flex">
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            style={{ width: "150px" }}
            placeholder="商品编号/商品名称"
            onChange={(e) => setSearchParms(e.target.value)}
            onPressEnter={() => goodsFilter()}
        />
        <Button shape="round" onClick={() => goodsFilter()}> 检索</Button>
    </div>

    const columns = [
        {
            title: '序号',
            width: 50,
            fixed: "left",
            align: "center",
            render: (t, r, i) => i + 1
        }, 
        {
            title: '提示',
            width: 60,
            fixed: 'left',
            align: 'center',
            dataIndex: '',
            render: (t, r) =>
                !r.isHave || r?.error ? (
                    <Tooltip
                        placement="topLeft"
                        title={!r.isHave ? r?.msg ?? '调入门店没有该商品, 保存时将自动添加该商品, 请及时维护对应的商品信息' : r.error}
                        arrowPointAtCenter
                    >
                        <ExclamationCircleOutlined style={{ color: '#FF4500', fontSize: '20px' }} />
                    </Tooltip>
                ) : (
                    ''
                ),
        },{
            title: '商品编号',
            width: 120,
            dataIndex: 'materialCode',
            fixed: "left",
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
            // render: (text, record, index) => <Tooltip placement="topLeft" title={text}><p className="ellipsis click-span" onClick={(e) => { e.stopPropagation(); setMaterialInfo(true, { materialCode: text, materialName: record.materialName }) }}>{text}</p></Tooltip>,
        }, {
            title: '商品名称',
            width: 160,
            dataIndex: 'materialName',
            render: (text) => <YhTooltip text={text} />,
            fixed: "left",
        }, {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '调拨数量',
            width: 100,
            dataIndex: 'num',
            editable: mode === "add",
        }, {
            title: '折前单价',
            width: 120,
            dataIndex: 'prePrice',
            editable: mode === "add" || formVal.status === DictCodeEnmu.TRANSFER_STATUS_INIT,
            render: (text) => `￥${formatNum(text)}`
        },  {
            title: '折前调拨金额',
            width: 120,
            dataIndex: 'preAmount',
            render: (text) => `￥${formatNum(text)}`
        },{
            title: '下浮',
            width: 100,
            dataIndex: 'discountRate',
            // editable: mode === "add" || status === DictCodeEnmu.TRANSFER_STATUS_INIT,
        },{
            title: '单价',
            width: 130,
            dataIndex: 'price',
            // editable: mode === "add" || formVal.status === DictCodeEnmu.TRANSFER_STATUS_INIT,
            render: (text) => `￥${formatNum(text)}`
        }, {
            //     title: '下浮',
            //     width: 100,
            //     dataIndex: 'discountRate',
            //     editable: mode === "add",
            // }, {
            title: '调拨金额',
            width: 136,
            dataIndex: 'amount',
            render: (text) => `￥${formatNum(text)}`
        },
        {
            title: '成本单价',
            width: 136,
            dataIndex: 'costPrice',
            render: (text) => `￥${formatNum(text ?? 0)}`,
        },
        // {
        //     title: '品牌',
        //     width: 100,
        //     dataIndex: 'brandName',
        // },
        {
            title: '调入仓库',
            width: 120,
            dataIndex: 'inWarehouseCode',
            editable: editFormVal.status === DictCodeEnmu.TRANSFER_STATUS_IN && mode === "edit" ? false : true,
            editType: "select",
            options: whDs,
            render: (text, record) => whDs.find(item => item.value === text)?.label ?? record.inWarehouseName ?? "请选择仓库",
            onSelectChange: wsChanges,
        }, {
            title: '调入货位',
            width: 120,
            dataIndex: 'inShelfCode',
            editable: false,
            // editable: editFormVal.status === DictCodeEnmu.TRANSFER_STATUS_IN && mode === "edit" ? false : true,
            // editType: "select",
            // options: shelfDs,
            // onSelectFocus: shelfFocus,
            // render: (text) => text ?? "请选择货位",
        }, {
            title: '调出仓库',
            width: 120,
            dataIndex: 'outWarehouseName',
        }, {
            title: '调出货位',
            width: 120,
            dataIndex: 'outShelfCode',
        }, {
            title: '商品类型',
            width: 120,
            dataIndex: 'typeName',
            // }, {
            //     title: '备注',
            //     width: 160,
            //     dataIndex: 'note',
            //     editable: mode === "add",
        },
        {
            title: '采购单号',
            width: 120,
            dataIndex: 'purchaseCode',
        },
        {
            title: '明细备注',
            width: 130,
            dataIndex: 'note',
            render: (text) => <YhTooltip text={text} />,
        }
    ];

    const newSelectColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑   (计算价格)
    const handleSave = (row) => {
        row.num = +formatInt(row.num);
        if (row.num > +row.inventoryNum) {
            message.warning("不可以大于库存数量");
            row.num = +row.inventoryNum
        }
        row.prePrice = +formatNum(row.prePrice);
        row.preAmount = +bigNumber.times(row.prePrice,row.num).toFixed(2);
        row.price = +bigNumber.times(row.prePrice,(1 - (+row.discountRate))).toFixed(2);
        row.discountRate = +formatDiscount(row.discountRate);
        row.inWarehouseName = whDs.find(item => item.value === row.inWarehouseCode)?.label;
        row.inShelfId = shelfDs.find(item => item.value === row.inShelfCode)?.id
        // //总计=单价*（1-下浮）*开单数量
        // row.amount = +formatNum(row.prePrice * (1 - row.discountRate) * row.num);
        row.amount = +bigNumber.times(bigNumber.times(row.prePrice,(1 - row.discountRate)).toFixed(2),row.num).toFixed(2);

        let dataSource = [...tableList];
        const index = dataSource.findIndex((item) => row.key === item.key);
        const item = dataSource[index];
        dataSource.splice(index, 1, { ...item, ...row });

        //判断仓库变化时，  重置货位
        if (row.inWarehouseCode !== dataSource[index].inWarehouseCode) {
            row.inShelfCode = undefined
        }
        updateList(mode, dataSource);

        //判断展示的是搜索后的数据，然后搜索的也改变
        if (searchVal.isSearch) {
            goodsFilter(dataSource)
        }
        setSaveBtn(true)
    }

    return (
        <Spin {...uploading}>
            <YhBox title={action} rightChildren={rightChildren}>
                <Table
                    rowKey={record => record.key}
                    // rowClassName='yh-editable-row yh-edit-table-tr'
                    rowClassName={(record) => {
                        if (!record?.isHave || record?.error) return 'editable-row yh-edit-table-tr background-FFB4B4'
                        return 'editable-row yh-edit-table-tr'
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys, //记录选中
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRowKeys(selectedRowKeys);
                        }
                    }}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={newSelectColumns}
                    dataSource={searchVal.isSearch ? searchVal.data : tableList}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
            </YhBox>
            <BatchSelectWarehouseModal
                mode={mode}
                batchSelectWarehouseModal={batchSelectWarehouseModal}
                setBatchSelectWarehouseModal={setBatchSelectWarehouseModal}
                batchSelectWarehouseList={batchSelectWarehouseList}
                selectedRowKeys={selectedRowKeys}
            />
        </Spin>
    )
}