import React, { useEffect, useState } from "react"
import { Form, Input, Col, Row, Select, message, Modal, DatePicker, Upload, Button, Spin, Radio } from "antd"
import { bussinessCostInsert, bussinessCostUpdate, bussinessCostOne } from "@/service/dataService"
import { organPage } from "@/service/orgService"
import moment from "moment"
import { getToken } from "@/utils/dataStorage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getTeamList, getTeamMemberList } from "@/service/teamService"
import { getUserInfo } from "@/utils/dataStorage"
import debounce from "lodash/debounce"
import { getBussinessCategoryList } from "@/service/dataService"

export default (props) => {
    const { mode, rowInfo, visible, setVisible, onSuccess } = props
    const [form] = Form.useForm()
    const [org, setOrg] = useState([])
    const [applicantType, setApplicantType] = useState(1)
    const [classList, setClassList] = useState([])
    //团队列表
    const [teamInfo, setTeamInfo] = useState({
        id: "", //列表
        data: [],
        fetching: false,
    })

    //成员列表
    const [memberInfo, setMemberInfo] = useState({
        id: "", //列表
        data: [],
        fetching: false,
    })

    const [imgs, setImgs] = useState({
        //身份证图片list
        file: [],
        md5: "",
    })

    useEffect(() => {
        queryMotorcade()
        getClass()
        organPage({
            searchItem: { showPlatformOrgan: true },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }, [])

    useEffect(() => {
        if (visible) {
            form.resetFields()
            let { organId } = getUserInfo()
            // if (nickname !== "平台管理机构admin") {
            form.setFieldsValue({ organId })
            // }
        }
    }, [visible])

    //获取分类
    const getClass = () => {
        let data = {
            searchItem: { disableFlag: 0 },
            pageNum: 1,
            pageSize: 1000,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getBussinessCategoryList(data).then((res) => {
            setClassList(res.retData.list)
        })
    }

    //查看编辑回显
    const queryMotorcade = () => {
        if (mode === "look" || mode === "edit") {
            bussinessCostOne(rowInfo.id).then((res) => {
                let results = res.retData
                let imgres = results.img || ""
                let file = []
                if (imgres) {
                    file = imgres.split(",").map((item, index) => {
                        return {
                            uid: index,
                            status: "done",
                            url: `${fileLookUrl().image}${item}`,
                            response: {
                                retData: item,
                            },
                        }
                    })
                    setImgs({ file, md5: imgres })
                }
                setTeamInfo({
                    id: results.teamId, //列表
                    data: [
                        {
                            text: results.teamName,
                            value: results.teamId,
                        },
                    ],
                    fetching: false,
                })
                setMemberInfo({
                    id: results.applicant, //列表
                    data: [
                        {
                            text: results.applicantName,
                            value: results.applicant,
                        },
                    ],
                    fetching: false,
                })
                if (results.costType === 1 || results.costType === 2) {
                    setApplicantType(1)
                } else if (results.costType === 3) {
                    setApplicantType(2)
                }
                form.setFieldsValue({
                    ...results,
                    img: file,
                    applyTime: results.applyTime ? moment(results.applyTime) : null,
                })
            })
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                applyTime: values.applyTime ? values.applyTime.format("YYYY-MM-DDTHH:mm:ss") : null,
                img: imgs.md5,
            }

            if (mode === "edit") {
                data.id = rowInfo.id
                bussinessCostUpdate(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                bussinessCostInsert(data).then((res) => {
                    message.success("编辑成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }
    useEffect(() => {
        if (visible) {
            form.resetFields()
            let { organId, nickname } = getUserInfo()
            // if (nickname !== "平台管理机构admin") {
            form.setFieldsValue({ organId })
            // }
        }
    }, [visible])

    const handleProjectChange = (choosedId) => {
        const choosedProject = classList.filter((item) => item.id === choosedId)[0]
        if (choosedProject.categoryType === 1 || choosedProject.categoryType === 2) {
            setApplicantType(1)
        } else if (choosedProject.categoryType === 3) {
            setApplicantType(2)
        }
    }

    const handleChangeOrg = () => {
        form.resetFields(["teamId", "applicant"])
    }
    //团队列表查询
    const teamQuery = (value) => {
        setMemberInfo({
            ...teamInfo,
            data: [],
            fetching: true,
        })
        let organId = form.getFieldValue("organId")
        getTeamList({
            searchItem: { disableFlag: 0, teamName: value, organId },
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.teamName,
                    value: item.id,
                })
                return arr
            }, [])
            setTeamInfo({
                ...teamInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const teamQuerys = debounce(teamQuery, 1000)
    const handleChangeTeam = () => {
        form.resetFields(["applicant"])
    }

    //成员列表查询
    const memberQuery = (value) => {
        setMemberInfo({
            ...memberInfo,
            data: [],
            fetching: true,
        })
        getTeamMemberList({
            pageNum: 1,
            pageSize: 10000,
            searchItem: { disableFlag: 0, userName: value },
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.userName,
                    value: item.id,
                })
                return arr
            }, [])
            setMemberInfo({
                ...memberInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const memberQuerys = debounce(memberQuery, 1000)

    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 18,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    // const handleApplicantTypeChange = (e) => {
    //     setApplicantType(Number(e.target.value))
    // }

    //图片改变
    const handleImgChange = (info) => {
        // console.log(info)
        // console.log(info.fileList)
        // if (info.file.status === "done") {
        //     console.log(info)
        //     let md5 = info?.file?.response?.retData
        //     setImgs({
        //         file: info.fileList,
        //         md5: md5,
        //     })
        // } else if (info.file.status === "removed") {
        //     setImgs({
        //         file: [],
        //         md5: "",
        //     })
        // }
        if (info.file.status === "done" || info.file.status === "removed") {
            let file = info.fileList
            console.log(file)
            let md5 = file.map((item) => {
                console.log(item)
                return item.response.retData
            })
            md5 = md5.join(",")
            setImgs({
                file,
                md5,
            })
        }
    }

    return (
        <>
            <Modal forceRender visible={visible} title="经营成本" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout} initialValues={{ applicantType: 1, costType: 1 }}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="项目名称" name="projectId" rules={[{ required: true, message: "请选择项目名称" }]}>
                                    <Select placeholder="请选择项目名称" style={{ width: "100%" }} onChange={handleProjectChange}>
                                        {classList.map((item) => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.categoryName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="所属机构"
                                    name="organId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择所属机构",
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择所属机构" style={{ width: "100%" }} onChange={handleChangeOrg}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.organName}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item label="申请类型" name="applicantType" onChange={handleApplicantTypeChange}>
                                    <Radio.Group>
                                        <Radio value={1}>个人</Radio>
                                        <Radio value={2}>团队</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item label="账单类型" name="costType">
                                    <Radio.Group>
                                        <Radio value={1}>支出</Radio>
                                        <Radio value={2}>收入</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col> */}

                            {applicantType === 1 && (
                                <Col span={24}>
                                    <Form.Item label="申请人" name="teamUserId" rules={[{ required: true, message: "请选择申请人" }]}>
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder="请选择申请人"
                                            style={{ width: "100%" }}
                                            notFoundContent={memberInfo.fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onFocus={() => memberQuery()}
                                            onSearch={memberQuerys}
                                            style={{ width: "100%" }}
                                        >
                                            {memberInfo.data.map((item) => (
                                                <Select.Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}

                            {applicantType === 2 && (
                                <Col span={24}>
                                    <Form.Item label="申请团队" name="teamId" rules={[{ required: true, message: "请选择申请团队" }]}>
                                        <Select
                                            showSearch
                                            allowClear
                                            placeholder="请选择团队"
                                            style={{ width: "100%" }}
                                            notFoundContent={teamInfo.fetching ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onChange={handleChangeTeam}
                                            onFocus={() => teamQuery()}
                                            onSearch={teamQuerys}
                                            style={{ width: "100%" }}
                                        >
                                            {teamInfo.data.map((item) => (
                                                <Select.Option value={item.value} key={item.value}>
                                                    {item.text}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}

                            {/* <Col span={24}>
                                <Form.Item label="公里数" name="kilometers">
                                    <Input placeholder="请输入公里数" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="销售金额" name="salesAmount">
                                    <Input placeholder="请输入销售金额" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="提成占比" name="commissionRate">
                                    <Input placeholder="请输入提成占比" />
                                </Form.Item>
                            </Col> */}
                            <Col span={24}>
                                <Form.Item label="费用" name="cost" rules={[{ required: true, message: "请输入费用" }]}>
                                    <Input placeholder="请输入费用" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="日期日期" name="applyTime" rules={[{ required: true, message: "请选择日期" }]}>
                                    <DatePicker
                                        style={{ width: "100%" }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        showTime={{
                                            defaultValue: moment("00:00:00", "HH:mm:ss"),
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="照片" name="img" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        action={`${fileUploadUrl}upload/image_old`}
                                        headers={{ authorization: getToken() }}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={imgs.file}
                                        // onPreview={() => handlePreview(imgs.md5)}
                                        onChange={handleImgChange}
                                    >
                                        {imgs.file.length >= 9 ? null : <Button>上传照片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="备注" name="remark">
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
