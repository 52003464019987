import React from "react";
import { Button, message } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { usePurchaseAdvancedDetailsModel } from "./purchaseAdvancedDetailModel";
import { Description } from "@/erp_subpackage/components/YhBox/Description";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import {advancedStatusEnum } from "@/erp_subpackage/utils/ErpEnum"

export const TopButs = ({ modeKey, mode, rowCode, dispatch }) => {
    const { editFormVal, addFormVal, lookFormVal, setTransferSaleDrawer,decisionSubmit, setSettleVisible, setWsConfirmVisible, verifyWhOrShelf, controlled } = usePurchaseAdvancedDetailsModel();
    const { getTreeNodeName } = useDictModel(() => []);
    const formVal = mode === "edit" ? editFormVal : mode === "look" ? lookFormVal : addFormVal;

    const action = mode === "edit" ? 
    // <>
    //     <Button
    //         className="btn-item"
    //         shape="round"
    //         disabled={controlled || formVal.status !== DictCodeEnmu.PO_CREATE}
    //         onClick={() => {
    //             if (!verifyWhOrShelf()) return false;
    //             setWsConfirmVisible(true)
    //         }}
    //     >确认入库</Button>
    //     <Button
    //         className="btn-item"
    //         shape="round"
    //         disabled={controlled || formVal.status !== DictCodeEnmu.PO_COMPLETELY_IO}
    //         onClick={() => {
    //             if (formVal.status !== DictCodeEnmu.PO_COMPLETELY_IO) {
    //                 message.warning("已入库的单据才可以进行结算");
    //                 return;
    //             }
    //             setSettleVisible(true)
    //         }}
    //     >确认结算</Button>
    //     <Button
    //         className="btn-item"
    //         shape="round"
    //         disabled={controlled || formVal.status === DictCodeEnmu.PO_CREATE } 
    //         onClick={() => {
    //             // isSale 0-未转接 1-已转接
    //             if (editFormVal.isSale == "1") {
    //                 message.warning("已转接过销售单的单据不可再次转接");
    //                 return;
    //             }
    //             setTransferSaleDrawer(true)
    //         }}
    //     >转销售单</Button>
    // </> : mode === "look" ? <>
    //     <Button
    //         className="btn-item"
    //         shape="round"
    //         onClick={() => setWsConfirmVisible(true)}
    //     >入库查看</Button>
    //     <Button
    //         className="btn-item"
    //         shape="round"
    //         onClick={() => setSettleVisible(true)}
    //     >结算查看</Button>
    // </> 
    <>

    </>
    : null;


    const orderStatus = [
        {
            label: "采购单",
            value: formVal.advancedCode ?? "暂无",
            color: "blue",
            status: true
        },
        {
            label: "状态",
            value: advancedStatusEnum[formVal.status] ?? "暂无",
            color: "green",
            status: true
        }
    ]
    return (
        <YhBox title={orderStatus.map((item, index) => <Description key={index} {...item} />)}
            style={{ padding: "0 20px", marginBottom: "10px" }}
            action={action}
        />
    )
}