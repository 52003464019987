import React from "react"
import { YhPageHeader } from "@/erp_subpackage/components/YhPageHeader"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { useSaleOrderModel } from "../saleOrderModel"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
export function PageHeader() {
    const { searchParam, initPgCfg, organTypeCode, updateSearchParam, resetSearchParam, loadData } = useSaleOrderModel()
    const { getSelectedDictOne } = useDictModel()
    const { orgDs } = useGlobalModel(({ orgDs }) => [orgDs])

    const searchFields = [
        ...(organTypeCode === 3 ? [
                  {
                      type: "Select",
                      placeholder: "所属机构",
                      fieldName: "shopId",
                      options: orgDs,
                  },
              ]
            : []),
        {
            type: "Input",
            placeholder: "销售订单号",
            fieldName: "saleOrderCode",
        },
        {
            type: "Input",
            placeholder: "客户名称",
            fieldName: "customerName",
        },
        {
            type: "Select",
            placeholder: "单据状态",
            fieldName: "status",
            mode: "multiple",
            options: getSelectedDictOne(DictCodeEnmu.SALE_ORDER),
        },
        {
            type: "Select",
            placeholder: "订单来源",
            fieldName: "orderSourceCode",
            options: getSelectedDictOne(DictCodeEnmu.ORDER_SOURCE),
        },
        {
            type: "Input",
            placeholder: "VIN码",
            fieldName: "vin",
        },
    ]
    const searchMoreFields = [
        {
            type: "Input",
            placeholder: "车牌号",
            fieldName: "licensePlate",
        },
        {
            type: "Input",
            placeholder: "车辆型号",
            fieldName: "vehicleModel",
        },
        {
            type: "Select",
            placeholder: "车辆类型",
            fieldName: "vehicleTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.VEHICLE_TYPE),
        },
        {
            type: "Select",
            placeholder: "单据类型",
            fieldName: "orderTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.TRADE_TYPE),
        },
        {
            type: "Select",
            placeholder: "结算方",
            fieldName: "settlementPartyCode",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_PARTY),
        },
        {
            type: "Select",
            placeholder: "支付方式",
            fieldName: "paymentMethodCode",
            options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD),
        },
        {
            type: "Select",
            placeholder: "结算类型",
            fieldName: "payType",
            options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
        },
        {
            type: "Select",
            placeholder: "支付状态",
            fieldName: "payStatus",
            options: getSelectedDictOne(DictCodeEnmu.PAY_STATUS),
        },
        {
            type: "Input",
            placeholder: "制单人",
            fieldName: "createUser",
        },
        {
            type: "Select",
            placeholder: "发票类型",
            fieldName: "invoiceTypeCode",
            options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
        },
        {
            type: "RangePicker",
            fieldName: '',
            placeholder: ["制单日期起始", "制单日期结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 16,
            xxl: 8,
        },
    ]

    return (
        <YhPageHeader
            size="small"
            title="销售订单"
            justify="end"
            triggerSearch={true}
            searchValues={searchParam}
            searchFields={searchFields}
            searchMoreFields={searchMoreFields}
            onChange={updateSearchParam}
            handleSearch={(params) => {
                loadData(initPgCfg, { ...searchParam, ...params })
            }}
            handleReset={resetSearchParam}
        />
    )
}
