import React, { useEffect, useImperativeHandle } from "react";
import { Form, Row, message } from "antd";
import { __FormItem as FormItem } from "./item";
import copy from "copy-to-clipboard"

export const __YhForm = ({ formRef, items, ...props }) => {
    const [form] = Form.useForm();
    const { labelCol, labelAlign, disabled, formValues, onChange, onFinish } = props
    const onValuesChange = (changedValues, values) => {
        //disabled ? form.setFieldsValue(formValues) : onChange?.(changedValues);
        onChange && onChange(changedValues);
    }
    useEffect(() => {
        // form.resetFields();
        form.setFieldsValue(formValues);
    }, [formValues]);

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields();
        },
        setFieldsValue: form.setFieldsValue,
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        resetFields: form.resetFields,
        inst: () => {
            return form;
        }
    }));

    const copyText = (fieldName, option) => {
        if (!option) {
            let value = form.getFieldsValue([fieldName]);
            if (typeof value[fieldName] !== "string") return;
            copy(value[fieldName]);
        } else {
            let value = form.getFieldsValue([fieldName]);
            if (typeof value[fieldName] !== "string") return;
            copy(option?.find(item => item.value == value[fieldName])?.label);
        }
        message.success('复制成功');
    }

    return <Form
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        labelCol={labelCol}
        labelAlign={labelAlign}
    >
        <Row
            justify="start"
        >
            {
                items.map((el, index) => {
                    return <FormItem key={index} {...el} disable={el.disable ?? disabled} copyText={(fieldName, option) => copyText(fieldName, option)} />
                })
            }
        </Row>
    </Form>;
}