import React from "react";
import {Form, InputNumber,Tooltip} from "antd";

export const __FormInputNumber = ({ placeholder, fieldLabel, fieldName, ...props }) => {
    const title = <span style={{ cursor: "pointer" }} onClick={(e) => {
        e.stopPropagation();
        if (props.copyText) {
            props.copyText(fieldName)
        }
    }}>复制</span>

    return (
        <Form.Item
        label={<Tooltip title={title}>{fieldLabel}</Tooltip>}
        name={fieldName}
        rules={props.rules}
        hidden={props.hidden}
        labelCol={props.labelCol}
            
        >
       
        <InputNumber 
        disabled={props.disable} 
        style={{ width: "100%" }} 
        min={0}
        step={1}
        allowClear={props.allowClear}
        placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
        onBlur={() => props.onInputBlur?.(fieldName)}
        precision={2}/>
      
        </Form.Item>
    )
}