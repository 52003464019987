import React, { useEffect, useState } from "react"
import { Button, Form, Input, Col, Row, message, Switch, Upload, Modal, Tabs } from "antd"
import { vehicleBrandInsert, vehicleBrandUpdate, vehicleBrandOne } from "@/service/brandManage"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getToken } from "@/utils/dataStorage"
import ModelList from "./modelList"
const { TabPane } = Tabs

const SystemDetails = (props) => {
    const { modeKey, dispatch, mode, rowInfo } = props
    const [form] = Form.useForm()
    const [previewVisible, setpreviewVisible] = useState(false) //预览图片使用的dialog
    const [previewImage, setpreviewImage] = useState("") //预览展示的行驶证
    const [licenseFileList, setLicenseFileList] = useState([]) //图片list

    useEffect(() => {
        resetFormItem()
    }, [])
    const systemSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                disableFlag: !values.disableFlag ? 1 : 0,
                logo: previewImage,
                type: "brand",
            }
            //新增
            if (mode === "insert") {
                vehicleBrandInsert(data).then((res) => {
                    handleCancel()
                    message.success("新增成功")
                })

                //编辑
            } else if (mode === "edit") {
                vehicleBrandUpdate({ ...data, id: rowInfo.id }).then((res) => {
                    handleCancel()
                    message.success("编辑成功")
                })
            }
        })
    }

    //图片查看
    const handlePreview = async (file) => {
        setpreviewVisible(true)
    }
    // 图片上传
    const handleImgChange = async (info) => {
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            setpreviewImage(md5)
            console.log(info.fileList)
            setLicenseFileList(info.fileList)
        } else if (info.file.status === "removed") {
            setLicenseFileList([])
            setpreviewImage("")
        }
    }
    //回显表单项
    const resetFormItem = () => {
        if (mode === "edit") {
            vehicleBrandOne(rowInfo.id).then((res) => {
                let result = res.retData
                let imgFileList = []
                if (result.logo) {
                    //回显图片， 有的话在做回显
                    imgFileList = [
                        {
                            uid: "-1",
                            status: "done",
                            url: `${fileLookUrl().image}${result.logo}`,
                        },
                    ]
                    //回显图片
                    setLicenseFileList(imgFileList)
                    setpreviewImage(result.logo)
                }
                form.setFieldsValue({
                    ...res.retData,
                    logo: imgFileList,
                    disableFlag: res.retData.disableFlag === 0 ? true : false,
                })
                // }
            })
        } else {
            //新增默认推荐展示
            form.setFieldsValue({
                disableFlag: true,
            })
        }
    }

    //表单绑定图片
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }
    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "brandManage",
        })
    }
    return (
        <>
            <div className="toolbar">
                <span className="modules-name">品牌管理</span>
            </div>
            <div className="list-container">
                <div className="list-header">
                    <div className="list-name">
                        <span>品牌管理</span>
                    </div>
                    <div className="list-but">
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={systemSubmit}>
                            保存
                        </Button>
                        <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                            取消
                        </Button>
                    </div>
                </div>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item
                                    label="名称"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "名称不可为空",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入名称" />
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item label="图片" name="logo" valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Upload
                                        // disabled={isLook(mode)}
                                        accept="image/*"
                                        headers={{ Authorization: getToken() }}
                                        action={`${fileUploadUrl}upload/image_old`}
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        fileList={licenseFileList}
                                        onPreview={handlePreview}
                                        onChange={handleImgChange}
                                    >
                                        {licenseFileList.length >= 1 ? null : <Button>上传图片</Button>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={100}>
                            <Col offset={3} span={9}>
                                <Form.Item name="disableFlag" label="启用" valuePropName="checked">
                                    <Switch checkedChildren="是" unCheckedChildren="否" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <Modal visible={previewVisible} title="图片查看" onCancel={() => setpreviewVisible(false)} onOk={() => setpreviewVisible(false)}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage}`} />
            </Modal>

            {mode !== "insert" && (
                <div className="list-container">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={"型号管理"} key="1">
                            <ModelList brandInfo={rowInfo} mode={mode} />
                        </TabPane>
                    </Tabs>
                </div>
            )}
        </>
    )
}
export default SystemDetails
