import React, { useEffect, useState } from "react"
import { Modal, Button, Input, message, Radio } from "antd"
import { orderPrintInfo, printNum, getOrderOne } from "@/service/ordersService"
import "./orderPrint.css"
import { getUserInfo, getPcodeDict } from "@/utils/dataStorage"
import { numberParseChina } from "@/utils/util"
import moment from "moment"
import { prebookService } from "../prebookService";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"

const ParintMadal = (props) => {
    const { visible, onClose } = props
    const [protList, setProtList] = useState([])
    const [itemList, setItemList] = useState([])
    const [baseInfo, setBaseInfo] = useState({})
    const [dictObj, setDictObj] = useState({ serviceType: {}, orderSource: {}, orderTypes: {} })
    const [serviceNature, setServiceNature] = useState("")
    const [titles, setTieles] = useState("")
    const [price, setPrice] = useState({})
    const [payType, setPayType] = useState("");
    const { getSelectedDictOne } = useDictModel(() => []);
    useEffect(() => {
        initDict()
        loadData()
    }, [])
    const loadData = () => {
        prebookService.one(visible.orderId).then((res) => {
            setBaseInfo(res.retData)
            const { entityName, orderType: orderTypes } = res.retData
            if (orderTypes && orderTypes === "014001") {
                //收费
                setServiceNature("016002")
            } else if (orderTypes && orderTypes === "014002") {
                // 三包
                setServiceNature("016001")
            }else if (orderTypes && orderTypes === "014006") {
                // 云豪三包
                setServiceNature("016004")
            }
            setTieles(entityName)
            // 016001 三包
            // 016002 收费
            setProtList(res.retData.materials)
            setItemList(res.retData.items)

            let preferentialAmount = 0;
            let receivableAmount = 0;
            let itemAmount = 0;
            res.retData.items.forEach(e => {
                itemAmount = (Number(itemAmount) + Number(e.actualAmount)).toFixed(2);
                preferentialAmount = (Number(preferentialAmount) + Number(e.preferentialAmount)).toFixed(2);
                receivableAmount = (Number(receivableAmount) + Number(e.actualAmount)).toFixed(2);
            });
            let materialAmount = 0;
            res.retData.materials.forEach(e => {
                materialAmount = (Number(materialAmount) + Number(e.actualAmount)).toFixed(2);
                preferentialAmount = (Number(preferentialAmount) + Number(e.preferentialAmount)).toFixed(2);
                receivableAmount = (Number(receivableAmount) + Number(e.actualAmount)).toFixed(2);
            });
            setPrice({
                orderItemActualAmount:itemAmount,
                orderMaintainMaterialAmount:materialAmount,
                orderTotalAmount:(Number(itemAmount) + Number(materialAmount)).toFixed(2),
                orderPreferentialAmount:preferentialAmount,
                orderActualAmount:receivableAmount,
            })
        })
    }

    //初始下订单用到的字典
    const initDict = () => {
        let obj = {
            serviceType: {}, //服务类型
            orderSource: {}, //支付方式
            orderTypes: {},
            threeGuaranteesUnit: {},
        }
        getPcodeDict("011").children.forEach((item) => {
            obj.serviceType[item.dictCode] = item.title
        })
        getPcodeDict("018").children.forEach((item) => {
            obj.orderSource[item.dictCode] = item.title
        })
        getPcodeDict("014").children.forEach((item) => {
            obj.orderTypes[item.dictCode] = item.title
        })
        getPcodeDict("106").children.forEach((item) => {
            obj.threeGuaranteesUnit[item.dictCode] = item.title
        })

        setDictObj(obj)
    }

    const print = async () => {

        const el = document.getElementById('print-container');
        const iframe = document.createElement('IFRAME');
        let doc = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./orderPrint.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
        await printNum({orderId:visible.orderId,printType:'financial'})
    }

    //转换number，保留两个0
    const switchNum = (num, n = 2) => {
        return Number(num).toFixed(n)
    }
    return (
        <Modal
            title="工单详情打印"
            width={1120}
            visible={visible.visible}
            onCancel={() => onClose({ visible: false, orderId: "" })}
            footer={[
                <Button onClick={() => onClose({ visible: false, orderId: "" })} key="back">
                    {" "}
                    取消
                </Button>,
                //      <Button type="primary" onClick={printPreview}  key="submit">
                //     打印预览
                //    </Button>,
                <Button type="primary" onClick={print} key="submit">
                    确认打印
                </Button>,
            ]}
        >
            <div id="print-container">
                <div className="print-container">
                    <p className="title" style={{ fontSize: "28px", marginBottom: '20px', marginTop: "40px" }}>
                        {titles}
                        {serviceNature === "016001" && "三包"}
                        {serviceNature === "016002" && "收费"}
                        {serviceNature === "016004" && "云豪大包"}结算单
                    </p>
                    <div className="print-name" style={{ fontSize: "18px", marginBottom: '18px' }}>
                        <div style={{ textAlign: "left", width: '35%' }}>
                            工单编号:
                            <span>
                                {baseInfo.prebookCode}
                                {serviceNature === "016001" && "SB"}
                                {serviceNature === "016002" && "SF"}
                                {serviceNature === "016004" && "YHDB"}
                            </span>
                        </div>
                        <div style={{ textAlign: "center", width: '30%' }}>
                        结算类型:
                            <span>{baseInfo?.settlementType?getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).find(item =>baseInfo?.settlementType==item.value).label:0 }</span>
                        </div>
                        <div style={{ textAlign: "right", width: '30%' }}>
                            打印日期:
                            <span>{moment().format("YYYY-MM-DD HH:mm")}</span>
                        </div>
                    </div>
                    <table className="print-table print-table-first" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                        <tbody>
                            <tr className="table-head">
                                <td>车辆牌照</td>
                                <td>{baseInfo.vehicleCode}</td>
                                <td>客户名称</td>
                                <td colSpan={5}>{baseInfo.customerName}</td>
                            </tr>
                            <tr className="table-head">
                                <td>Vin 码</td>
                                <td>{baseInfo.vehicleVin}</td>
                                <td>送 修 人</td>
                                <td>{baseInfo.senderRepairUserName}</td>
                                <td>电 话</td>
                                <td colSpan={2}>{baseInfo.senderRepairUserPhone}</td>
                            </tr>
                            <tr className="table-head">
                                <td>车型</td>
                                <td>{baseInfo.vehicleModel}</td>
                                <td>颜色</td>
                                <td>无</td>
                                <td>进厂时间</td>
                                <td>{baseInfo.entryFactoryTime ? moment(baseInfo.entryFactoryTime).format("YYYY-MM-DD HH:mm") : null}</td>
                                <td>出厂时间</td>
                                <td>{baseInfo.outFactoryTime ? moment(baseInfo.outFactoryTime).format("YYYY-MM-DD HH:mm") : null}</td>
                            </tr>
                            <tr className="table-head">
                                <td>故障描述</td>
                                <td colSpan={7}>{baseInfo.faultDesc}</td>
                            </tr>
                        </tbody>
                    </table>
                        <table className="print-table" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                            <tbody>
                                <tr className="table-item">
                                    <td>项目编号</td>
                                    <td colSpan={3}>项目名称</td>
                                    <td>工时</td>
                                    <td>单价</td>
                                    <td>优惠</td>
                                    <td>折扣</td>
                                    <td>总金额</td>
                                    <td>是否赠送</td>
                                    <td>备注</td>
                                </tr>

                                {itemList.length > 0 ? (
                                        itemList.map((item, index) => {
                                            return (
                                                <tr className="table-item" key={item.id}>
                                                    <td>{item.itemCode}</td>
                                                    <td colSpan={3}>{item.itemName}</td>
                                                    <td>{switchNum(item.itemWorkHours, 1)}</td>
                                                    <td>{switchNum(item.workHoursPrice)}</td>
                                                    <td>{switchNum(item.preferentialAmount)}</td>
                                                    <td>{switchNum(item.itemDiscount)}</td>
                                                    <td>{switchNum(item.actualAmount)}</td>
                                                    <td>{item.giftFlag === 'not_gift' ? "不赠送" : item.giftFlag === 'gift' ? "赠送" : ""}</td>
                                                    <td>{item.remark}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr className="table-prot">
                                            <td>无</td>
                                            <td colSpan={3}>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                            <td>无</td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    <table className="print-table" style={{ fontSize: "18px", fontWeight: 'bold' }}>
                        <tbody>
                                <tr className="table-prot">
                                    <td>序号</td>
                                    <td>商品编号</td>
                                    <td>商品名称</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>优惠</td>
                                    <td>折扣</td>
                                    <td>总金额</td>
                                    <td>备注</td>
                                </tr>

                            {protList.length > 0 ? (
                                    protList.map((item, index) => {
                                        return (
                                            <tr className="table-prot" key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.materialCode}</td>
                                                <td>{item.materialName}</td>
                                                <td>{item.materialNumber}</td>
                                                <td>{switchNum(item.salePrice)}</td>
                                                <td>{switchNum(item.preferentialAmount)}</td>
                                                <td>{switchNum(item.materialDiscount)}</td>
                                                <td>{switchNum(item.actualAmount)}</td>
                                                <td>{item.remark}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr className="table-prot">
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                        <td>无</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                    <table className="print-table print-table-last" style={{ fontSize: "18px", fontWeight: 'bold' }} >
                        <tbody>

                                <>
                                    <tr className="table-footer">
                                        <td>工时费</td>
                                        <td>{switchNum(price.orderItemActualAmount)}</td>
                                        <td>材料费</td>
                                        <td>{switchNum(price.orderMaintainMaterialAmount)}</td>
                                        <td>合计金额</td>
                                        <td colSpan={2}>{switchNum(price.orderTotalAmount)}</td>
                                    </tr>
                                    <tr className="table-footer">
                                        {price.orderPreferentialAmount > 0 ? (
                                            <>
                                                <td>优惠金额</td>
                                                <td>{switchNum(price.orderPreferentialAmount)}</td>
                                                <td>实际金额</td>
                                                <td>{switchNum(price.orderActualAmount)}</td>
                                                <td>大写</td>
                                                <td colSpan={3}>{numberParseChina(price.orderActualAmount)}</td>
                                            </>
                                        ) : (
                                            <>
                                                <td>实际金额</td>
                                                <td>{switchNum(price.orderActualAmount)}</td>
                                                <td>大写</td>
                                                <td colSpan={5}>{numberParseChina(price.orderActualAmount)}</td>
                                            </>
                                        )}
                                    </tr>
                                    <tr className="table-footer">
                                        <td>待结金额</td>
                                        <td ></td>
                                        <td>客户签字</td>
                                        <td ></td>
                                        <td>结算人</td>
                                        <td ></td>
                                        <td>会计</td>
                                        <td ></td>
                                    </tr>
                                </>
                        </tbody>
                    </table>
                    <div className="print-footer" style={{ fontSize: "18px", marginTop: '10px', fontWeight: 'bold',width:'1060px'}}>
                        <div style={{ textAlign: "left", width: '30%' }}>
                            制单员：
                            {/* <span>{getUserInfo() ? getUserInfo().nickname : "未获取到"}</span> */}
                            <span>{baseInfo.createUser??""}</span>
                        </div>
                        <div style={{ textAlign: "left", width: '40%' }}>
                            备注：<span>{baseInfo.remark}</span>
                        </div>
                        {/* <div style={{ textAlign: "right", width: '30%' }}>
                            打印日期:
                            <span>{moment().format("YYYY-MM-DD HH:mm")}</span>
                        </div> */}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ParintMadal
