import React, { useEffect, useState, useRef } from "react"
import { Button, Form, Input, Col, Row, message, Select, Card, Modal, Upload, Tree, Image, Badge, Switch } from "antd"
import BraftEditor from "braft-editor"
import "braft-editor/dist/index.css"
import { ContentUtils } from "braft-utils"
import { tdmsDataSelect, tdmsApprovalPass, tdmsApprovalreject, tdmsCategoryRootlist, tdmsDataUpdate } from "@/service/tdmsService"
import { fileUploadUrl, fileLookUrl } from "@/config/app.js"
import { getToken, getUserInfo } from "@/utils/dataStorage"
import { PlusOutlined } from "@ant-design/icons"

// 资料审核详情
export default ({ modeKey, mode, rowInfo, dispatch }) => {
    const [form] = Form.useForm()
    const [modelForm] = Form.useForm()
    const [dataVisible, setDataVisible] = useState(false)
    const [treeData, setTreeData] = useState(null)
    const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([])
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(null)) //富文本
    const [saveLoading, setSaveLoading] = useState(false) //保存按钮
    const [formDisable, setFormDisable] = useState(mode === "look" ? true : false)
    const [idFileList, setIDFileList] = useState([]) //图片list
    const [dataFileDtos, setDataFileDtos] = useState([])
    const [actived, setActived] = useState("html")
    const [fileType, setFileType] = useState("")
    const [dataPath, setDataPath] = useState([])
    const [dataName, setDataName] = useState([])
    const [categoryId, setCategoryId] = useState([])
    const [pathTrue, setPathTrue] = useState(false)
    const [previewImage, setpreviewImage] = useState({
        //预览展示头像MD5
        visible: false,
        md5: "",
    })
    const treeList = useRef(null)
    const choosedIds = useRef(null)

    // 表单回显
    useEffect(() => {
        loadParentSelectData()
        if (mode === "edit" || mode === "look" || mode === "approval") {
            tdmsDataSelect(rowInfo.id ?? rowInfo.dataId)
                .then((res) => {
                    if (res && res.retCode === 200) {
                        let { categoryPaths, categoryNames, categoryIds, dataFileDtos, ...result } = res.retData
                        if (categoryPaths) {
                            let pathArr = categoryPaths.split(",")
                            let nameArr = categoryNames.split(",")
                            let pathArr1 = pathArr.map((item) => {
                                return {
                                    path: item,
                                }
                            })
                            let nameArr1 = nameArr.map((item) => {
                                return {
                                    name: item,
                                }
                            })
                            setDataPath([...pathArr1])
                            setDataName([...nameArr1])
                            setPathTrue(true)
                            choosedIds.current = categoryIds.split(",")
                            getDefaultKeys(categoryIds.split(","))
                        }
                        result.content && setEditorState(BraftEditor.createEditorState(result.content))
                        form.setFieldsValue({
                            ...res.retData,
                            isOriginal: res.retData.isOriginal ? true : false,
                        })
                        let activeds = res.retData.fileType === "html" ? "html" : "pic"
                        setActived(activeds)
                        let dataFileDtosList = dataFileDtos && dataFileDtos.filter((item) => item.fileType != "application/pdf")
                        let dataFileDtosLists = dataFileDtosList && dataFileDtosList.filter((item) => item.fileType != "pdf")
                        setDataFileDtos(dataFileDtosLists)
                        let idFileList = dataFileDtosLists.map((item) => {
                            let { fileMd5 } = item
                            return {
                                uid: fileMd5,
                                status: "done",
                                response: {
                                    retData: fileMd5,
                                },
                                url: `${fileLookUrl().image}${fileMd5}`,
                            }
                        })
                        setIDFileList(idFileList)
                    } else {
                        message.warning(res.retMsg)
                    }
                })
                .catch((err) => {
                    message.error(err.retMsg)
                })
        }
    }, [form, mode, rowInfo.id])

    //富文本上传图片
    const uploadHandler = (param) => {
        console.log(param)
        let arr = []
        if (param.file.status === "done") {
            arr = [...param.fileList]
            setEditorState(
                ContentUtils.insertMedias(editorState, [
                    {
                        type: "IMAGE",
                        url: `${fileLookUrl().image}${param?.file?.response?.retData}`,
                    },
                ])
            )
            let arr1 = arr.map((item) => {
                return {
                    fileMd5: item.response.retData,
                    fileName: item.name,
                    fileType: "pic",
                }
            })
            setDataFileDtos(arr1)
        }
    }
    const extendControls = [
        {
            key: "antd-uploader",
            type: "component",
            component: (
                <Upload
                    accept="image/*"
                    headers={{ Authorization: getToken() }}
                    action={`${fileUploadUrl}upload/image_old`}
                    showUploadList={false}
                    onChange={uploadHandler}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        上传图片
                    </button>
                </Upload>
            ),
        },
    ]

    //富文本配置
    const controls = [
        "undo",
        "redo",
        "separator",
        "font-size",
        "line-height",
        "letter-spacing",
        "separator",
        "text-color",
        "bold",
        "italic",
        "underline",
        "strike-through",
        "separator",
        "superscript",
        "subscript",
        "remove-styles",
        "emoji",
        "separator",
        "text-indent",
        "text-align",
        "separator",
        "headings",
        "list-ul",
        "list-ol",
        "blockquote",
        "code",
        "separator",
        "link",
        "separator",
        "hr",
        "separator",
        // 'media', 'separator',
        "clear",
    ]

    // 编辑
    const onSubmit = (params) => {
        console.log(params)
        setSaveLoading(true)
        form.validateFields()
            .then((res) => {
                if (dataPath.length == 0 && params != "reject") {
                    message.error("请选择所属分类")
                    return
                }
                let type = actived === "html" ? "html" : "pic"
                let categoryIds = choosedIds.current && choosedIds.current.join(",")
                let nameArr = []
                let pathArr = []
                dataPath.forEach((item) => {
                    pathArr.push(item.path)
                })
                dataName.forEach((item) => [nameArr.push(item.name)])
                let nameStr = nameArr.join(",")
                let pathStr = pathArr.join(",")
                let data = {
                    ...res,
                    content: editorState.toHTML(),
                    isOriginal: res.isOriginal ? 0 : 1,
                    fileType: actived === "html" ? "html" : fileType === undefined ? type : fileType,
                    sendMsg: false,
                    categoryIds,
                    categoryNames: nameStr,
                    categoryPaths: pathStr,
                    dataFileDtos: dataFileDtos,
                }
                // console.log("submit data: ", data, nameArr, nameStr)
                if (mode === "edit" || mode === "approval") {
                    tdmsDataUpdate({ id: rowInfo.id ?? rowInfo.dataId, ...data })
                        .then((res) => {
                            if (res && res.retCode === 200) {
                                if (params === "pass") {
                                    onPassSubmit("pass")
                                } else if (params === "reject") {
                                    onPassSubmit("reject")
                                } else {
                                    handleCancel()
                                    form.resetFields()
                                    message.success("编辑成功")
                                }
                            } else {
                                message.warning(res.retMsg)
                                setSaveLoading(false)
                            }
                        })
                        .catch((err) => {
                            message.error(err.retMsg)
                            setSaveLoading(false)
                        })
                }
            })
            .catch((err) => {
                message.warning("请检查必填项")
                setSaveLoading(false)
                console.log(err)
            })
    }

    //取消
    const handleCancel = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "tdmsApproval",
        })
    }

    // 通过 驳回
    const onPassSubmit = (params) => {
        form.validateFields()
            .then((res) => {
                // debugger
                console.log(params)
                let result = ""
                let approvalStatus = ""
                if (params === "pass") {
                    result = 1
                    approvalStatus = 2
                } else {
                    result = 0
                    approvalStatus = 3
                }
                let data = {
                    comment: res.comment,
                    dataId: rowInfo.id ?? rowInfo.dataId,
                    userId: getUserInfo().id,
                    approvalStatus: approvalStatus,
                    result: result,
                }
                console.log("submit data: ", data)
                if (mode === "approval") {
                    tdmsApprovalPass({ ...data })
                        .then((res) => {
                            if (res && res.retCode === 200) {
                                // onSubmit()
                                handleCancel()
                                message.success("审核成功")
                            } else {
                                message.warning(res.retMsg)
                            }
                        })
                        .catch((err) => {
                            message.error(err.retMsg)
                        })
                    // onSubmit()
                }
            })
            .catch((err) => {
                message.warning("请检查必填项")
            })
    }

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    }

    //表单
    const formNode = () => (
        <div>
            <Form form={form} {...formItemLayout}>
                <Row>
                    <Col span={6} xxl={4}>
                        <Form.Item
                            label="标题"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入标题",
                                },
                            ]}
                        >
                            <Input placeholder="请输入标题" disabled={formDisable} />
                        </Form.Item>
                    </Col>
                    <Col span={6} xxl={4}>
                        <Form.Item label="作者" name="author">
                            <Input placeholder="请输入作者" disabled={formDisable} />
                        </Form.Item>
                    </Col>
                    <Col span={6} xxl={4}>
                        <Form.Item label="标签" name="labels">
                            <Input placeholder="前输入标签" disabled={formDisable} />
                        </Form.Item>
                    </Col>
                    <Col span={6} xxl={4}>
                        <Form.Item
                            label="审核意见"
                            name="comment"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入审核意见",
                                },
                            ]}
                        >
                            <Input placeholder="前输入审核意见" disabled={formDisable} />
                        </Form.Item>
                    </Col>
                    <Col span={6} xxl={4}>
                        <Form.Item label="是否原创" name="isOriginal" valuePropName="checked">
                            <Switch checkedChildren="是" unCheckedChildren="否" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )

    // 弹框配置
    const toTree = (data, parent) => {
        // debugger
        var tree = []
        var temp
        for (var i = 0; i < data.length; i++) {
            data[i].title = data[i].name
            data[i].key = data[i].id
            if (data[i].pid == parent) {
                var obj = data[i]
                temp = toTree(data, data[i].id)
                if (temp.length > 0) {
                    obj.children = temp
                }
                tree.push(obj)
            }
        }
        return tree
    }

    //获取默认的节点
    const getDefaultKeys = (choosedIds) => {
        if (treeList && treeList.current && treeList.current.length > 0) {
            let defaultKeys = []
            const getIds = (data, choosedid) => {
                // console.log(data)
                for (var i in data) {
                    if (data[i].id === choosedid) {
                        if (!data[i].children) {
                            defaultKeys.push(choosedid)
                        } else {
                            getIds(data[i].children)
                        }
                    } else if (data[i].children) {
                        getIds(data[i].children, choosedid)
                    }
                }
            }
            choosedIds.forEach((item) => {
                getIds(treeList.current, item)
            })
            setDefaultCheckedKeys(defaultKeys)
            // setShowTree(true)
        } else {
            setTimeout(() => {
                getDefaultKeys(choosedIds)
            }, 200)
        }
    }

    // 获取菜单列表
    const loadParentSelectData = () => {
        tdmsCategoryRootlist().then((res) => {
            let list = res.retData.map((item) => {
                return {
                    ...item,
                    key: item.id,
                }
            })
            if (list) {
                list = toTree(list, null)
                treeList.current = list
                setTreeData(list)
            }
        })
    }

    //选中树节点
    const onCheck = (checkedKeys, e) => {
        const checkedKeysResult = [...checkedKeys, ...e.halfCheckedKeys]
        choosedIds.current = checkedKeysResult
        if (e.checked) {
            setDataPath([...dataPath, { path: e.node.path }])
            setDataName([...dataName, { name: e.node.name }])
        } else {
            let newPath = dataPath.filter((item) => item.path !== e.node.path)
            let newName = dataName.filter((item) => item.name !== e.node.name)
            setDataPath(newPath)
            setDataName(newName)
        }
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    )

    const handleIDChange = async (info) => {
        // console.log(info)
        let arr = []
        if (info.file.status === "done") {
            let md5 = info?.file?.response?.retData
            let filsType = ""
            setpreviewImage({ visible: false, md5: md5 })
            if (info.file.type === "application/pdf") {
                filsType = "pdf"
            } else {
                filsType = "pic"
            }
            setFileType(filsType)
            arr = [...info.fileList]
            let arr1 = arr.map((item) => {
                return {
                    fileMd5: item.response.retData,
                    fileName: item.name,
                    fileType: item.type === "application/pdf" ? "pdf" : "pic",
                }
            })
            setDataFileDtos(arr1)
        }
        setIDFileList(info.fileList)
    }

    const onClickButton = (type) => {
        if (actived === type) return
        setActived(type)
        // console.log(actived)
    }

    //查看模式
    const isLook = (str) => {
        return str === "look" ? true : false
    }

    // 树确定
    const onConfirm = () => {
        setCategoryId(choosedIds.current)
        setDataVisible(false)
        setPathTrue(true)
        // console.log("树id", categoryId, choosedIds.current)
    }

    return (
        <>
            <Row>
                <Modal visible={dataVisible} onCancel={() => setDataVisible(false)} width={800} onOk={onConfirm}>
                    <Form form={modelForm} {...formItemLayout}>
                        <Row>
                            <Col span={12}>
                                <Tree
                                    // defaultExpandAll={true}
                                    defaultCheckedKeys={defaultCheckedKeys}
                                    onCheck={(checkedKeys, e) => onCheck(checkedKeys, e)}
                                    checkable
                                    treeData={treeData}
                                ></Tree>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <div className="toolbar">
                    <span className="modules-name">审核</span>
                </div>
                <div className="list-container" style={{ width: "100%", paddingRight: 20 }}>
                    <div
                        className="list-header-but"
                        onClick={() => {
                            if (mode === "look") {
                                return
                            }
                            setDataVisible(true)
                        }}
                    >
                        {/* <Button type="primary" size="middle" disabled={mode != "edit" ? true : false}>
                            选择分类
                        </Button> */}
                        <Button type="primary" size="middle" disabled={mode === "look" ? true : false}>
                            选择分类
                        </Button>
                        {pathTrue && (
                            <ul style={{ marginTop: 20, listStyle: "none" }}>
                                {dataPath.map((item, index) => {
                                    return (
                                        <li disabled="true" key={index} style={{ fontSize: 18, color: "#000000" }}>
                                            {item.path}
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                    </div>
                    <div className="list-header">
                        <div className="list-name">
                            <span>基础信息</span>
                        </div>
                    </div>
                    <div className="form-details-warp">{formNode()}</div>
                    <Button style={{ margin: "15px" }} type={actived === "html" ? "primary" : "default"} onClick={() => onClickButton("html")}>
                        文章模式
                    </Button>
                    <Button style={{ margin: "15px" }} type={actived === "pic" ? "primary" : "default"} onClick={() => onClickButton("pic")}>
                        图片模式
                    </Button>
                    {actived === "html" ? (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Card>
                                        <BraftEditor
                                            value={editorState}
                                            controls={controls}
                                            onChange={(e) => setEditorState(e)}
                                            extendControls={extendControls}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Card>
                                        <Upload
                                            disabled={mode === "look" ? true : false}
                                            action={`${fileUploadUrl}upload/image_old`}
                                            headers={{ Authorization: getToken() }}
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            fileList={idFileList}
                                            onPreview={(file) => {
                                                const md5 = file.response.retData
                                                setpreviewImage({ visible: true, md5 })
                                            }}
                                            onChange={handleIDChange}
                                        >
                                            {uploadButton}
                                        </Upload>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    )}
                    <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
                        {mode === "approval" ? (
                            <>
                                {" "}
                                <Button
                                    type="primary"
                                    shape="round"
                                    size="small"
                                    className="but-left"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        // onPassSubmit("reject")
                                        onSubmit("reject")
                                    }}
                                    loading={saveLoading}
                                >
                                    驳回
                                </Button>
                                <Button
                                    type="primary"
                                    shape="round"
                                    size="small"
                                    className="but-left"
                                    onClick={() => {
                                        // onPassSubmit("pass")
                                        onSubmit("pass")
                                    }}
                                >
                                    通过
                                </Button>
                            </>
                        ) : mode === "edit" ? (
                            <>
                                <Button
                                    type="primary"
                                    shape="round"
                                    size="small"
                                    className="but-left"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        onSubmit("")
                                    }}
                                >
                                    保存
                                </Button>
                                <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                                    取消
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button type="primary" shape="round" size="small" className="but-left" onClick={handleCancel}>
                                    取消
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Row>
            <Modal visible={previewImage.visible} title="图片查看" footer={null} onCancel={() => setpreviewImage({ ...previewImage, visible: false })}>
                <img alt="" style={{ width: "100%" }} src={`${fileLookUrl().image}${previewImage.md5}`} />
            </Modal>
        </>
    )
}
