import { post, get } from "../utils/request"

//车辆列表
function getMobileCar(data) {
    return post("/ci/home/mobile_car/page", data)
}
//车辆列表
function getVehicleList(data) {
    return post('/ci/home/mobile_car/page', data);
}
//获取一条车辆数据
function getVehicleOne(id) {
    return get(`/ci/home/mobile_car/one/${id}`)
}
//获取一条驾驶员数据
function getDriverOne(id) {
    return get(`/ci/home/team_user/one/${id}`)
}

//获取车辆GPS数据
function getGPSHistory(data) {
    return post("/ci/home/mobile_car/gps_history", data)
}

//获取团队下的订单
function getOrderTeamList(data) {
    return post("/ci/order/team_list", data)
}


//数据可视化首页
function getStatisticsIndex() {
    return get("/ci-platform/statistics/dataReport")
}

//首页地图虚拟车辆数据
function virtualList() {
    return get(`/ci/home/mobile_car/virtualList`)
}

export { getMobileCar, getVehicleOne, getDriverOne, getGPSHistory, getOrderTeamList, getVehicleList ,getStatisticsIndex,virtualList}