import React, { useState, useEffect } from "react";
import { usePrebookModel } from "../prebookModel";

export const FixedBottom = ({ modeKey, mode, rowId, dispatch }) => {

    const { getInfo, getMaterialList, getItemList } = usePrebookModel();
    const [staticInfo, setStaticInfo] = useState({
        itemAmount: 0,
        itemQuantity: 0,
        materialAmount: 0,
        materialQuantity: 0,
        totalAmount: 0,
        preferentialAmount: 0,
        receivableAmount: 0
    })

    useEffect(() => {
        let preferentialAmount = 0;
        let receivableAmount = 0;

        let itemAmount = 0;
        getItemList(mode) && getItemList(mode).forEach(e => {
            itemAmount = (Number(itemAmount) + Number(e.actualAmount)).toFixed(2);
            preferentialAmount = (Number(preferentialAmount) + Number(e.preferentialAmount)).toFixed(2);
            receivableAmount = (Number(receivableAmount) + Number(e.actualAmount)).toFixed(2);
        });
        let materialAmount = 0;
        getMaterialList(mode) && getMaterialList(mode).forEach(e => {
            materialAmount = (Number(materialAmount) + Number(e.actualAmount)).toFixed(2);
            preferentialAmount = (Number(preferentialAmount) + Number(e.preferentialAmount)).toFixed(2);
            receivableAmount = (Number(receivableAmount) + Number(e.actualAmount)).toFixed(2);
        });
        setStaticInfo({
            itemAmount: itemAmount,
            itemQuantity: getItemList(mode) && getItemList(mode).length,
            materialAmount: materialAmount,
            materialQuantity: getMaterialList(mode) && getMaterialList(mode).length,
            totalAmount: (Number(itemAmount) + Number(materialAmount)).toFixed(2),
            preferentialAmount: preferentialAmount,
            receivableAmount: receivableAmount
        });
    }, [getMaterialList(mode), getItemList(mode)])

    return (
        <div className="list-container" style={{ position: 'fixed', left: '205px', bottom: 0, width: 'calc(100% - 240px)', zIndex: 999 }}>
            <div className="form-details-warp bottom-info" style={{ padding: '10px 20px' }}>
                <span>开单时间：{getInfo(mode) && getInfo(mode).createTime}</span>
                <span>开单员：{(getInfo(mode) && getInfo(mode).createUser) ?? '管理员'}</span>
                <span>维修项目合计：<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.itemAmount}元（{staticInfo.itemQuantity}项）</span></span>
                <span>维修用料：<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.materialAmount}元（{staticInfo.materialQuantity}项）</span></span>
                <span>费用合计：<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.totalAmount}元</span> </span>
                <span>优惠金额：<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.preferentialAmount}元</span></span>
                <span>应收金额：<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.receivableAmount}元</span> </span>
            </div>
        </div >
    )
}