import { httpRequest } from '@/erp_subpackage/utils/HttpRequest'
import { PORTAL_API_SERVER_HOST, API_SERVER_HOST } from '@/config/app'

class AllocService {
   
    async page(searchVo) {
        const apiUrl = '/ci-storage/inventory/overstock/statics'
        return await httpRequest.post(apiUrl, searchVo, { baseUrl: API_SERVER_HOST })
    }
    //明细导出
    async export(searchVo) {
        const apiUrl = '/ci-analyze/inventory/overstock/statics/export'
        return await httpRequest.post(apiUrl, searchVo, { timeout: 300000, baseUrl: API_SERVER_HOST })
    }
}

export const allocService = new AllocService()
