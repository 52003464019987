import React, { useEffect, useRef, useState, useContext } from 'react'
import { Button, message, Modal, Radio } from 'antd'
import { YhFormBox } from '@/erp_subpackage/components/YhFormBox'
import { getFormValidatorErrText } from '@/erp_subpackage/utils/util'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { debounce } from 'lodash'
import { usePurchaseAdvancedDetailsModel } from './purchaseAdvancedDetailModel'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { MyContext } from '@/store/myContext'
import { getBentityListOption } from '@/utils/dataStorage'
import { usePurchaseAdvancedModel } from "../../purchaseAdvancedModel";

export const FormBox = ({ modeKey, mode, rowCode, dispatch }) => {
    const { state, detailsPage } = useContext(MyContext);
    const { editFormVal,editList, addFormVal, lookFormVal,controlled,decisionSubmit,transferSubmit, organType,saveBtnLoading, setStockVisible, updateFormVal, updateList, getPurchaseAdvancedOne, insertDetail, editDetail } =
        usePurchaseAdvancedDetailsModel()
    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const {user: {organNature},fetchSupplierList } = useGlobalModel(() => [])
    const formRef = useRef()
    const [more, setMore] = useState(true) //控制更多按钮
    const [list, setList] = useState([])
    const formVal = mode === 'edit' ? editFormVal : mode === 'look' ? lookFormVal : addFormVal
    const {loadData} = usePurchaseAdvancedModel();
    //编辑时， 没有缓存调用详情接口
    useEffect(() => {
        if (mode === 'edit' || mode === 'look') {
            getPurchaseAdvancedOne(rowCode, mode)
        }
        //新增 机构默认展示云豪科技平台为供应商
        if (mode === 'add') {
            !organType && getSupplier('平台管理机构')
        }
        return () => {
            updateFormVal(mode)
            updateList(mode)
        }
    }, [rowCode])
    useEffect(() => {
        materialList()
    }, [mode])
    //商品类型
    const materialList =()=>{
    let newList=getSelectedDictOne(DictCodeEnmu.MATERIAL_TYPE)
    const  newArr= newList.filter((obj,index,self)=>{
        return index===self.findIndex((o)=>(
            o.value===obj.value
            ))
    }) 
    setList(newArr)
    }

    //查询供应商
    const getSupplier = async (value) => {
        if (!value) return
        let retData = await fetchSupplierList(value)
        if (retData && retData.length) {
            let { label, value, type_code, type_name, contact, phone } = retData[0]
            updateFormVal(mode, {
                contact,
                phone,
                supplierName: label,
                supplierCode: value,
                supplierTypeCode: type_code,
                supplierTypeName: type_name,
            })
        }
    }
    //关闭当前页
    const onClose = () => {
        dispatch({
            type: 'removeTabs',
            targetKey: modeKey,
            activeMenu: 'purchaseAdvanced',
        })
        loadData()
    }

    //跳转供应商详情页
    const goSupplier = () => {
        dispatch({
            type: 'changeTabs',
            name: '供应商新增',
            activeMenu: 'baseSupplier/add',
            content: detailsPage.BaseSupplierDetails({
                dispatch,
                modeKey,
                mode: 'add',
            }),
        })
    }

    //供应商选中  回显供应商类型、联系人、电话
    const supplierChange = (value, option) => {
        if (option && option.id) {
            let { label, value, type_code, type_name, contact, phone } = option
            updateFormVal(mode, {
                contact,
                phone,
                supplierName: label,
                supplierCode: value,
                supplierTypeCode: type_code,
                supplierTypeName: type_name,
            })
        }
    }
    //销售实体
    const bentityChange = (value, option) => {
        option && onChange({ bentityName: option.label, bentityCode: option.value })
    }

    //选择联系人
    const handleContact = async () => {
        if (!formVal.supplierCode) {
            return message.warning('请选择联系人后进行操作！')
        }
        let ret = await fetchSupplierList(formVal.supplierName)
        if (ret && ret.length) {
            let options = []
            if (ret[0].contact) {
                options.push({ contact: ret[0].contact, phone: ret[0].phone })
            }
            if (ret[0].name2) {
                options.push({ contact: ret[0].name2, phone: ret[0].phone2 })
            }
            let activeIndex = -1
            Modal.confirm({
                title: `联系人选择【${formVal.supplierName}】`,
                content: (
                    <Radio.Group
                        onChange={(e) => {
                            activeIndex = e.target.value
                        }}
                    >
                        {options.map((item, index) => (
                            <Radio value={index} key={index}>
                                {item.contact}
                            </Radio>
                        ))}
                    </Radio.Group>
                ),
                okText: '确定',
                cancelText: '取消',
                onOk: () =>
                    new Promise((resolve, reject) => {
                        if (activeIndex > -1) {
                            resolve(activeIndex)
                        } else {
                            message.warning('请选择联系人')
                            reject('请选择联系人')
                        }
                    }).then((res) => {
                        updateFormVal(mode, {
                            contact: options[activeIndex].contact,
                            phone: options[activeIndex].phone,
                        })
                    }),
            })
        }
    }

    const onChange = (changedValues) => {
        updateFormVal(mode, changedValues)
    }

    const reset = () => {
        getPurchaseAdvancedOne(rowCode, mode).then((res) => {
            message.success('刷新成功')
        })
    }

    const findErrorList = () => {
        let list=editList.filter((item)=>item.num>item.satisfiedNum)
        if(organNature==3&&mode=='edit'&&list.length>0&&editFormVal.status == DictCodeEnmu.ADVANCED_WAIT_CONFIRM){
            return true
        }
        else{
            return false}
    }
    //表单提交
    const onSubmit = debounce(async () => {
        if(findErrorList()){
            return message.error('采购数量<=满足数量')
        }
        formRef.current
            .validateFields()
            .then((val) => {
                delete val.createTime
                let data = {
                    ...val,
                    materialTypeName: getTreeNodeName(val.materialTypeCode),
                    purchaseTypeName: getTreeNodeName(val.purchaseTypeCode),
                    deliveryMethodName: getTreeNodeName(val.deliveryMethod),
                    invoiceTypeName: getTreeNodeName(val.invoiceTypeCode),
                }
                if (mode === 'edit') {

                    if (formVal.status == DictCodeEnmu.ADVANCED_SUBMIT&&organNature!==3) {
                        editDetail({...data,
                            status:'advanced.wait.audit'
                        }).then((res) => {
                            res && getPurchaseAdvancedOne(rowCode, mode)
                        })
                    }
                    else{editDetail(data).then((res) => {
                        res && getPurchaseAdvancedOne(rowCode, mode)
                    })}
                } else {
                    insertDetail(data).then((res) => {
                        let modeKey = "purchaseAdvanced/edit";
                        //动态传入要删除的nav key
                        const handleDispatch = (obj = {}) => {
                            dispatch({
                                ...obj,
                                type: "changeTabs",
                                name: "采购平台编辑",
                                activeMenu: modeKey,
                                content: detailsPage.PurchaseAdvancedDetails({
                                    modeKey,
                                    mode:'edit',
                                    dispatch,
                                    rowCode: res.advancedCode
                                }),
                            })
                        }
                        const findNav = state.panes.find(item => item.key === 'purchaseAdvanced/add');
                        if (findNav) {
                             handleDispatch({ deleteKey: 'purchaseAdvanced/add' })
                        }
                         else{
                            handleDispatch()
                         }
                    })
                }
                return true
            })
            .catch((err) => {
                message.warning(getFormValidatorErrText(err))
                return false
            })
    }, 900)

    const rightChildren = (
        <>
            {/* <Button className="btn-item" shape="round" onClick={() => setStockVisible(true)} hidden={mode !== 'add'}>
                各机构库存
            </Button> */}
        {organNature!==3&&<Button
             className="btn-item"
             shape="round"
             disabled={controlled || !(formVal.status == DictCodeEnmu.ADVANCED_SUBMIT|| formVal.status == DictCodeEnmu.ADVANCED_WAIT_TRANSFER)}
             onClick={() => {
                transferSubmit().then(() => {
                    onClose()
                })
             }}
         >转接</Button>}
        {!(formVal.status == DictCodeEnmu.ADVANCED_WAIT_TRANSFER||formVal.status == DictCodeEnmu.ADVANCED_TRANSFER)&&<><Button
             className="btn-item"
             shape="round"
             disabled={mode=='add'||controlled}
             onClick={() => {
                if(findErrorList()){
                    return message.error('采购数量<=满足数量')
                }
                decisionSubmit().then(() => {
                    onClose()
                })
             }}
         >确认提交</Button>
            <Button className="btn-item" shape="round" onClick={onSubmit}  loading={saveBtnLoading}hidden={mode === 'look'}>
            {saveBtnLoading ? '保存中' : '保存'}
            </Button></>}
            <Button className="btn-item" shape="round" onClick={() => reset()} hidden={mode === 'add'}>
                刷新
            </Button>
            <Button className="btn-item" shape="round" onClick={onClose}>
                返回
            </Button>
        </>
    )

    //图片变更缓存保留  保留缓存及回显表单
    const onSetFieldsValue = (formValues) => {
        updateFormVal(mode, formValues)
    }

    const baseFormItem = {
        title: '基本信息',
        style: { padding: '0 20px', marginBottom: '10px' },
        rightChildren,
        items: [
            {
                type: 'Input',
                fieldLabel: '采购单号',
                fieldName: 'advancedCode',
                disable: true,
                placeholder: '自动生成',
            },
            // {
            //     type: 'AsyncSelect',
            //     fieldLabel: (
            //         <span className="click-span" onClick={goSupplier}>
            //             供应商名称
            //         </span>
            //     ),
            //     fieldName: 'supplierName',
            //     onSelectChange: supplierChange,
            //     placeholder: '供应商',
            //     rules: [{ required: true, message: '请选择供应商' }],
            //     selectfetchList: fetchSupplierList,
            //     defaultQuery: formVal.supplierName,
            // },
            // {
            //     type: 'Input',
            //     fieldLabel: (
            //         <span className="click-span" onClick={handleContact}>
            //             联系人
            //         </span>
            //     ),
            //     fieldName: 'contact',
            //     rules: [{ required: true, message: '请填入联系人' }],
            // },
            {
                type: 'Input',
                fieldLabel: '供应商名称',
                fieldName: 'supplierName',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '联系人',
                fieldName: 'contact',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '所属机构',
                fieldName: 'shopName',
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: '采购类型',
                fieldName: 'purchaseTypeCode',
                options: getSelectedDictOne(DictCodeEnmu.PURCHASE_TEPE, ['vrm', 'ci', 'main']),
                rules: [{ required: true, message: '请选择采购类型' }],
            },
            {
                type: 'Input',
                fieldLabel: '供应商类型',
                fieldName: 'supplierTypeName',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '联系电话',
                fieldName: 'phone',
                disable: true,
                rules: [{ required: true, message: '请输入联系电话' }],
            },
            {
                type: 'Select',
                fieldLabel: '采购实体',
                fieldName: 'bentityCode',
                options: getBentityListOption(),
                onSelectChange: bentityChange,
                rules: [{ required: true, message: '请选择采购实体' }],
            },
            // {
            //     type: 'Select',
            //     fieldLabel: '商品类型',
            //     fieldName: 'materialTypeCode',
            //     options: list,
            // },
            // {
            //     type: 'Select',
            //     fieldLabel: '结算类型',
            //     fieldName: 'payType',
            //     options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
            //     rules: [{ required: true, message: '请选择结算类型' }],
            //     disable: formVal.status === DictCodeEnmu.PO_BILL || formVal.status === DictCodeEnmu.PO_SETTLE,
            // },
            {
                type: 'Input',
                fieldLabel: '备注',
                fieldName: 'note',
            },
            {
                type: 'Input',
                fieldLabel: '制单人',
                fieldName: 'createUser',
                // hidden: more,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '制单时间',
                fieldName: 'createTime',
                // hidden: more,
                disable: true,
            },
            {
                type: 'FormUpload',
                fieldLabel: '附件',
                fieldName: 'fileName',
                fieldMd5: 'md5',
                defaultFileName: formVal.fileName,
                defaultFileMd5: formVal.md5,
                onSetFieldsValue,
            },

            // {
            //     type: 'Select',
            //     fieldLabel: '发票类型',
            //     fieldName: 'invoiceTypeCode',
            //     options: getSelectedDictOne(DictCodeEnmu.INVOICE_TYPE),
            //     span: 5,
            //     labelCol: { span: 4 },
            //     colClassName: 'detail-note-col',
            // },
            // {
            //     type: 'Button',
            //     fieldLabel: (
            //         <a className="color-blue text-line" onClick={() => setMore(!more)}>
            //             {more ? '更多>>' : '隐藏'}
            //         </a>
            //     ),
            //     fieldName: '',
            //     span: 1,
            //     labelCol: { span: 20, offset: 4 },
            // },
            // {
            //     type: 'Select',
            //     fieldLabel: '是否直发',
            //     fieldName: 'isDirect',
            //     options: [
            //         { label: '是', value: 1 },
            //         { label: '否', value: 0 },
            //     ],
            //     hidden: more,
            // },
            // {
            //     type: 'Select',
            //     fieldLabel: '运输方式',
            //     fieldName: 'deliveryMethod',
            //     options: getSelectedDictOne(DictCodeEnmu.LOGISTIC_MODE),
            //     hidden: more,
            // },

            // {
            //     type: 'Input',
            //     fieldLabel: '采购计划号',
            //     fieldName: 'planCode',
            //     span: 12,
            //     labelCol: { span: 3 },
            //     hidden: more,
            //     disable: true,
            // },
           
            // {
            //     type: 'Input',
            //     fieldLabel: '库管员',
            //     fieldName: 'storekeeper',
            //     hidden: more,
            //     disable: true,
            // },
            // {
            //     type: 'Input',
            //     fieldLabel: '入库时间',
            //     fieldName: 'stockIoTime',
            //     hidden: more,
            //     disable: true,
            // },
            // {
            //     type: 'Input',
            //     fieldLabel: '结算员',
            //     fieldName: 'reckoner',
            //     hidden: more,
            //     disable: true,
            // },
            // {
            //     type: 'Input',
            //     fieldLabel: '结算时间',
            //     fieldName: 'settleTime',
            //     hidden: more,
            //     disable: true,
            // },
            // {
            //     type: 'Input',
            //     fieldLabel: '核销时间',
            //     fieldName: 'writeOffTime',
            //     hidden: more,
            //     disable: true,
            // },
            //带出字段
            {
                type: 'Input',
                fieldLabel: '采购实体名称',
                fieldName: 'bentityName',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '供应商名称',
                fieldName: 'supplierCode',
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: '供应商类型code',
                fieldName: 'supplierTypeCode',
                hidden: true,
            },
        ],
    }

    const fromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: 'right',
        formValues: formVal,
        disabled: mode === 'look',
        onChange,
        boxs: [baseFormItem],
    }

    return <YhFormBox {...fromConfig} />
}
