import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getWvConsultationRecord } from "@/service/allCarService"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import YhTooltip from "@/erp_subpackage/components/YhTooltip"
//咨询列表
export default () => {
    const { state, Option } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (state.activeMenu === "allCarConsultationRecord") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            startTime: search.startTime,
            endTime: search.endTime,
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getWvConsultationRecord(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                    setpagination({
                        current: current,
                        total: res.retData.total,
                        pageSize: 10,
                    })
                    setList(res.retData.list)
                // } else {
                //     message.warning(res.retMsg)
                // }
            })
            // .catch((err) => message.error(err.retMsg))
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //table配置
    const columns = [
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "姓名",
            align: "center",
            dataIndex: "contactPersonName",
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "contactPersonPhone",
        },
        {
            title: "所属地区",
            align: "center",
            dataIndex: "contactPersonCity",
        },
        {
            title: "咨询日期",
            align: "center",
            dataIndex: "consultationTime",
        },
        {
            title: "咨询车型",
            align: "center",
            dataIndex: "vehicleName",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "select",
                placeholder: "请选择区域",
                valueName: "contactPersonCity",
                optionList: () =>
                    getPcodeDict("030").children.map((item) => (
                        <Option key={item.id} value={item.title}>
                            {item.title}
                        </Option>
                    )),
            },
            // {
            //     type: "select",
            //     placeholder: "请选择状态",
            //     valueName: "recordStatus",
            //     optionList: () => getPcodeDict("030").children.map(item => <Option key={item.id} value={item.dictCode} >{item.title}</Option>)
            // },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "allCarConsultationRecord" && (
                <>
                    <TopSearch
                        title="咨询记录"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent title="咨询记录" columns={columns} dataSource={list} pagination={pagination} onTableChange={onTableChange} />
                </>
            )}
        </>
    )
}
