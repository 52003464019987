import React, { useRef, useEffect, useState } from "react"
import { Spin } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { FormBox } from "./FormBox";
import { Material } from "./Material";
import { usePrebookModel } from "../prebookModel";

export const ConvertModel = ({ mode, rowInfo }) => {

    const {
        convertVisible, setConvertVisible,
        convertLoading, submitConvert,
        getInfo, getPrebookOne,
    } = usePrebookModel();
    const formRef = useRef();

    useEffect(() => {
        if (convertVisible && getInfo(mode) == null) {
            getPrebookOne(mode, rowInfo.id);
        }
    }, [convertVisible])

    return (
        <Spin {...convertLoading}>
            <YHModal
                visible={convertVisible}
                onCancel={() => { setConvertVisible(false) }}
                title="转工单"
                width={1200}
                bodyHeight={600}
                showFooter={true}
                onOk={() => submitConvert(formRef)}
            >
                <FormBox rowInfo={rowInfo} formRef={formRef} />
                <Material rowInfo={rowInfo} />
            </YHModal>
        </Spin>
    )
}
