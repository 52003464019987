import React, { useEffect, useState } from "react"
import { Modal, Button, message, Table } from "antd"
import { orderReviewList } from "@/service/ordersService"
import { timeShift } from "@/utils/util"
export default ({ visible, onClose, orderId }) => {
    const [reviewList, setReviewList] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        let data = {
            pageSize: 1000,
            searchItem: { orderId: orderId },
            pageNum: 1,
        }
        orderReviewList(data).then((res) => {
            // if (res && res.retCode === 200) {
            setReviewList(res.retData.list)
            // } else {
            //     message.warning(res.retMsg)
            // }
        })
        // .catch((err) => message.error(err.retMsg))
    }

    //table配置
    const columns = [
        {
            title: "审核人名称",
            align: "center",
            dataIndex: "reviewerName",
        },
        {
            title: "审核状态",
            align: "center",
            dataIndex: "reviewerStatus",
            render: (text) => <>{text === "adopt" ? "通过" : text === "reject" ? "驳回" : ""}</>,
        },
        {
            title: "审核时间",
            align: "center",
            dataIndex: "reviewerTime",
            // render: (text) => (text ? timeShift(text) : ""),
        },
        {
            title: "提交人名称",
            align: "center",
            dataIndex: "submitUserName",
        },
        {
            title: "驳回原因",
            align: "center",
            dataIndex: "rejectInfo",
        },
    ]
    return (
        <Modal
            title="工单审核记录详情"
            width={1000}
            visible={visible}
            onCancel={() => onClose({ visible: false, orderId: "" })}
            footer={[
                <Button onClick={() => onClose()} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={() => onClose()} key="submit">
                    确认
                </Button>,
            ]}
        >
            <Table size="small" rowKey={(record) => record.id} columns={columns} dataSource={reviewList} pagination={false} scroll={{ y: 500 }} />
        </Modal>
    )
}
