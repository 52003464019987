import { post, get } from "../utils/request"

/**
 * 车队接口
 */
//分页
function getFleetList(data) {
    return post("/ci-crms/fleet/list", data)
}
//新增
function insertFleet(data) {
    return post("/ci-crms/fleet/add", data)
}
//编辑
function editCustomer(id, data) {
    return post(`/ci-crms/fleet/edit/${id}`, data)
}
//获取一条数据
function getFleetOne(id) {
    return get(`/ci-crms/fleet/one/${id}`)
}
//车队启用禁用
function disableFleet(id, status) {
    return get(`/ci-crms/fleet/statusUpdate/${id}/${status}`)
}
/**
 * 司机接口
 */
//分页
function getDriverList(data) {
    return post("/ci-crms/driver/list", data)
}
//新增
function insertDriver(data) {
    return post("/ci-crms/driver/add", data)
}
//编辑
function editDriver(id, data) {
    return post(`/ci-crms/driver/edit/${id}`, data)
}
//获取一条数据
function getDriverOne(id) {
    return get(`/ci-crms/driver/one/${id}`)
}
//司机删除
function deleteDriverOne(id) {
    return get(`/ci-crms/driver/deleteOne/${id}`)
}

/**
 * 账户信息
 */
//分页
function getAccountList(data) {
    return post("/ci-crms/account/list", data)
}
//获取一条数据
function getAccountOne(id) {
    return get(`/ci-crms/account/one/${id}`)
}
//新增
function insertAccount(data) {
    return post("/ci-crms/account/add", data)
}
//编辑
function editAccount(id, data) {
    return post(`/ci-crms/account/edit/${id}`, data)
}
//账户重置
function rechargeAccount(data) {
    return post(`/ci-crms/account/recharge`, data)
}

export {
    getFleetList,
    insertFleet,
    editCustomer,
    getFleetOne,
    getDriverList,
    insertDriver,
    editDriver,
    getDriverOne,
    disableFleet,
    getAccountList,
    getAccountOne,
    insertAccount,
    editAccount,
    deleteDriverOne,
    rechargeAccount,
}
