import axios from "axios"
import https from "https"
import { message } from "antd"
import { TDMS_API_SERVE } from "../config/app"
import { getToken, setToken } from "./dataStorage"

const qs = require("qs")

// 创建axios实例
const service = axios.create({
    baseURL: TDMS_API_SERVE, // 基础api
    timeout: 10000, // 请求超时时间
    httpsAgent: new https.Agent({
        rejectUnauthorized: false,
    }),
    paramsSerializer: (params) =>
        qs.stringify(params, {
            arrayFormat: "repeat",
        }),
})

// request拦截器
service.interceptors.request.use(
    (config) => {
        if (/seller\/trade\/orders\/export/.test(config.url)) {
            config.timeout = 60000
        }
        // 获取访问Token
        const token = getToken()
        if (token) {
            config.headers.authorization = getToken()
        }
        if (config.method === "post") {
            config.headers["Content-Type"] = "application/json"
        }
        /** 进行参数序列化 */
        if ((config.method === "put" || config.method === "post") && config.headers["Content-Type"] !== "application/json") {
            config.data = qs.stringify(config.data, {
                arrayFormat: "repeat",
            })
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

// respone拦截器
service.interceptors.response.use(
    async (response) => {
        if (response.headers.authorization) {
            setToken(response.headers.authorization)
            // sessionStorage.setItem(TOKEN_KEY, res.headers.authorization);
        } else {
            if (response.data && response.data.authorization) {
                setToken(response.headers.authorization)
                // sessionStorage.setItem(TOKEN_KEY, res.data.authorization);
            }
        }
        return response.data
    },
    async (error) => {
        const error_response = error.response || {}
        const error_data = error_response.data || {}
        if (error_response.status === 403) {
            console.log(222)
            return Promise.reject(403)
        }
        if (error.config && error.config.message !== false) {
            let _message = error.code === "ECONNABORTED" ? "连接超时，请稍候再试！" : "网络错误，请稍后再试！"
            message.error(error_data.message || _message)
        }
        return Promise.reject(error)
    }
)

export default service
