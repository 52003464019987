import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getTeamMemberList, deleteTeamMember } from "@/service/teamService"
import { getPopularizeQrcode } from "@/service/otherService"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import Detail from "./detail"
import { Modal, Spin, Skeleton, Button, Select } from "antd"
import { teamUserBindOfficialQrcode, teamUserBindOfficialQuery } from "@/service/permissin"
import { organPage } from "@/service/orgService"
import { download, convertRes2Blob } from "@/utils/exportExcel"
import Loading from "@/components/Loading"
import { fileLookUrl } from "@/config/app"
import YhTooltip from "@/components/YhTooltip"
import useGlobalModel from "@/erp_subpackage/model/globalModel";
//访问列表
export default () => {
    const { orgDs,  user: { organType,  } } = useGlobalModel();
    const [exportLoading, setExportLoading] = useState(false) //导出loading
    const { state, message, showAllOrgan } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [org, setOrg] = useState([])

    // 绑定公众号
    const [islVisible, setIslVisible] = useState(false)
    const [ticket, setTicket] = useState("")
    const [ticketLoading, setTicketLoading] = useState(false)
    const [polling, setPolling] = useState(false)
    const [userId, setUserId] = useState("")

    useEffect(() => {
        if (state.activeMenu === "teamMemberManage") {
            console.log(999)
            console.log(organType)
            loadData(pagination.current)
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
    }, [state.activeMenu])

    useEffect(() => {
        let timer = 0
        if (polling) {
            timer = setInterval(() => {
                teamUserBindOfficialQuery(userId).then((res) => {
                    if (res && res.retData) {
                        message.success("绑定成功")
                        setPolling(false)
                        clearInterval(timer)
                        setIslVisible(false)
                        loadData(1, searchValue)
                    }
                })
            }, 2000)
        }
        return () => {
            clearInterval(timer)
        }
    }, [polling])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { disableFlag: 0, ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getTeamMemberList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
        {
            click: () => exportData(),
            content: "导出",
        },
    ]

    const exportData = async () => {
        setExportLoading(true)
        let data = {
            type: "post",
            data: {
                pageSize: pagination.pageSize,
                pageNum: pagination.current,
                startTime: searchValue.startTime ? searchValue.startTime : null,
                endTime: searchValue.endTime ? searchValue.endTime : null,
                searchItem: {
                    ...searchValue,
                },
                orders: [
                    {
                        column: "createTime",
                        rule: "DESC",
                    },
                ],
            },
        }
        let resFlie = await download("/ci/team_user/export", data)
        if (resFlie && resFlie.status === 200) {
            message.success("已导出")
            convertRes2Blob(resFlie)
        } else {
            message.warning("导出异常，请重新尝试")
        }
        setExportLoading(false)
    }

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的成员信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除成员 - ${selectInfo.rowInfo.userName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                deleteTeamMember(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的成员")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的成员")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //table配置
    const columns = [
        {
            title: "姓名",
            align: "center",
            dataIndex: "userName",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "手机号",
            align: "center",
            dataIndex: "userPhone",
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
        },
        {
            title: "团队名称",
            align: "center",
            dataIndex: "teamList",
            render: (text) =>text? text.map(i => i.teamName).join(',') : '',
        },
        {
            title: "职务",
            align: "center",
            dataIndex: "userJobTitle",
            render: (text) => <YhTooltip text={getPcodeDict(text)?.title}/>,
        },
        {
            title: "服务端显示商城",
            align: "center",
            dataIndex: "mallDisplay",
            render: (text) => <YhTooltip text={text === "display" ? "显示" : "不显示"}/>,
        },
        // {
        //     title: "点位",
        //     align: "center",
        //     dataIndex: "allocationPoint",
        // },
        // {
        //     title: "分成比例",
        //     align: "center",
        //     dataIndex: "userDistributionPoints",
        // },
        {
            title: "登录账号",
            align: "center",
            dataIndex: "userAccount",
            render: (text) => <YhTooltip text={text} />,

        },
        {
            title: "微信绑定",
            algin: "center",
            dataIndex: "officialUnionId",
            render: (officialUnionId, record) => (
                <span>
                    {!officialUnionId ? (
                        <Button
                            size="small"
                            onClick={() => {
                                setIslVisible(true)
                                setTicketLoading(true)
                                setUserId(record.id)
                                teamUserBindOfficialQrcode(record.id)
                                    .then((res) => {
                                        setTicketLoading(false)
                                        setTicket(res.retData.ticket)
                                        setPolling(true)
                                    })
                                    .catch((res) => {
                                        setIslVisible(false)
                                        setPolling(false)
                                    })
                            }}
                        >
                            绑定
                        </Button>
                    ) : (
                        "已绑定"
                    )}
                </span>
            ),
        },
        {
            title: "推广二维码",
            align: "center",
            dataIndex: "id",
            render: (text) => (
                <span
                    style={{ color: "#2e3aeb", cursor: "pointer" }}
                    onClick={() => {
                        getPopularizeQrcode(text).then((res) => {
                            const qrcode = res.retData
                            Modal.confirm({
                                title: "推广二维码",
                                content: (
                                    <img
                                        style={{ width: "200px", height: "200px", display: "bloack", margin: "20px auto" }}
                                        src={`${fileLookUrl().image}${qrcode}`}
                                        alt=""
                                    />
                                ),
                                okText: "确定",
                            })
                        })
                    }}
                >
                    查看
                </span>
            ),
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            
           
            {
                type: "input",
                placeholder: "用户名称",
                valueName: "userName",
            },
            {
                type: "input",
                placeholder: "用户手机号",
                valueName: "userPhone",
            },
            {
                type: "input",
                placeholder: "用户名",
                valueName: "userAccount",
            },
            {
                type: "input",
                placeholder: "团队名称",
                valueName: "teamName",
            },
            {
                hidden: !showAllOrgan,
                type: 'select',
                placeholder: '所属机构',
                valueName: 'organId',
                optionList: () =>
                    org.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                            {item.organName}
                        </Select.Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "teamMemberManage" && (
                <>
                    <TopSearch
                        title="成员管理"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="成员管理"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            org={org}
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}

                    <Modal
                        width={350}
                        visible={islVisible}
                        footer={null}
                        onCancel={() => {
                            setIslVisible(false)
                            setPolling(false)
                        }}
                    >
                        <Spin spinning={ticketLoading} tip="Loading...">
                            <Skeleton paragraph={{ rows: 6 }} loading={ticketLoading}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        style={{
                                            width: "300px",
                                        }}
                                        src={`https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=` + ticket}
                                        alt=""
                                    />
                                    <span>微信扫描二维码关注云豪车服管家公众号</span>
                                </div>
                            </Skeleton>
                        </Spin>
                    </Modal>
                    {exportLoading && <Loading />}
                </>
            )}
        </>
    )
}
