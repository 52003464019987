import React, { useContext } from "react"
import { Button, Input, message, Modal } from "antd"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { useSaleSoDetailModel } from "./saleSoDetailModal"
import { Description } from "@/erp_subpackage/components/YhBox/Description"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { MyContext } from "@/store/myContext"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
export const TopButs = ({ modeKey, mode, rowId, dispatch, formRef }) => {
    const { detailsPage } = useContext(MyContext)
    const { getTreeNodeName } = useDictModel(() => [])

    const {
        setSettleVisible,
        setWsConfirmModal,
        detailDataSource,
        detailAddForm,
        detailLookList,
        detailEditForm,
        detailLookForm,
        topButsControl,
        editDetail,
        setCheckModal,
    } = useSaleSoDetailModel()

    const onWhConfirm = () => {
        if (detailDataSource.length != 0 && mode === "edit") {
            setWsConfirmModal(true)
        } else if (detailLookList.length !== -1 && mode === "look") {
            setWsConfirmModal(true)
        } else {
            message.error("请添加商品详情")
        }
    }
    const onSettle = () => {
        
        formRef.current.validateFields().then(async res => {
            // let { id, saleCode } = detailEditForm
            // const resData = await editDetail({ ...res, id, saleCode })
            setSettleVisible(true)
        })
    }
    const onReturnRo = () => {
        let str = ""
        Modal.confirm({
            title: "整单退货",
            content: (
                <Input
                    placeholder="请输入退货原因"
                    onChange={(e) => {
                        str = e.target.value
                    }}
                />
            ),
            onOk: () => {
                return new Promise((resolve, reject) => {
                    if (str) {
                        resolve(str)
                    } else {
                        message.warning("请输入退货原因")
                        reject("请输入退货原因")
                    }
                }).then(async (res) => {
                    message.success("已帮您转接销售退货")
                    dispatch({
                        type: "changeTabs",
                        name: "销售退货新增",
                        activeMenu: "saleRo/add",
                        content: detailsPage.SaleRoDetails({
                            dispatch,
                            modeKey,
                            mode: "add",
                            type: "saleSo",
                            form: { ...detailEditForm, returnReason: str },
                            detailsList: detailDataSource.map((item) => {
                                let { detailsId, ...params } = item
                                return {
                                    ...params,
                                    saleNum: params.num,
                                    salePrice: params.price,
                                    detailId: detailsId,
                                }
                            }),
                        }),
                    })
                })
            },
        })
    }

    // 商城审核
    const onCheck = () => {
        setCheckModal(true)
    }
    const action =
        mode === "edit" ? (
            <>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onSettle}
                    disabled={topButsControl ||
                        detailEditForm.orderSourceCode != DictCodeEnmu.SOURCE_ADD ||
                        detailEditForm.status === DictCodeEnmu.SALE_STOCK_IO ||
                        detailEditForm.status === DictCodeEnmu.SALE_SETTLE ||
                        detailEditForm.status === DictCodeEnmu.SALE_REVERSED
                    }
                >
                    确认结算
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onWhConfirm}
                    disabled={topButsControl || detailEditForm.status === DictCodeEnmu.SALE_STOCK_IO || detailEditForm.status === DictCodeEnmu.SALE_REVERSED}
                >
                    库房出库
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onReturnRo}
                    disabled={topButsControl || detailEditForm.status !== DictCodeEnmu.SALE_STOCK_IO || detailEditForm.orderSourceCode != DictCodeEnmu.SOURCE_ADD}
                >
                    整单退货
                </Button>
                {/* <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onCheck}
                    disabled={topButsControl || detailEditForm.orderSourceCode === DictCodeEnmu.SOURCE_ADD}
                >
                    商城审核
                </Button> */}
            </>
        ) : mode === "look" ? (
            <>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onSettle}
                >
                    结算查看
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    onClick={onWhConfirm}
                >
                    出库查看
                </Button>
            </>
        ) : (
            false
        )

    const typeTitle = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const orderStatu = [
        {
            label: "销售单",
            value: typeTitle[mode]?.saleCode ?? "暂无",
            color: "blue",
            status: true,
        },
        {
            label: "状态",
            value: (typeTitle[mode]?.status && getTreeNodeName(typeTitle[mode]?.status)) ?? "暂无",
            color: "green",
            status: true,
        },
    ]

    return (
        <YhBox
            title={
                <>
                    {orderStatu.map((item, index) => (
                        <Description key={index} {...item} />
                    ))}
                </>
            }
            action={action}
            hidden={mode !== "add" ? false : true}
            style={{ paddingBottom: 0, marginBottom: "15px" }}
        />
    )
}
