import React, { useState, useEffect } from "react";
import { Badge, Upload, Button, Image, Form, Input, message } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import { SHOP_API_FILE_SERVE } from "@/config/app"

// export const getFileUrl = (md5) => {
// return `https://file-server.laoniuche.cn/file/fetch?id=${md5}`;
// return `https://file-server.laoniuche.cn/file/fetch?id=${md5}`;
// }

export const __FormFileImage = ({ md5List = [], onSetFieldsValue, photoNum = 100, ...props }) => {
    const [previewImage, setpreviewImage] = useState(md5List); //商品缩略图 string[]

    useEffect(() => {
        setpreviewImage(md5List)
    }, [md5List])

    useEffect(() => {
        onSetFieldsValue && onSetFieldsValue({ [props.fieldName]: previewImage })
    }, [previewImage]);

    /*上传图片成功后的回掉 */
    const upLoadCallback = async (file) => {
        try {
            let md5 = file.response.url;
            setpreviewImage(origin => [...origin, md5])
        } catch {

        }
    }

    return (
        <>
            <Form.Item name={props.fieldName} hidden><Input /></Form.Item>
            <Form.Item
                label={props.fieldLabel}
                rules={props.rules}
                labelCol={props.labelCol}
            >
                {previewImage && previewImage.map((item, index) => (
                    <div key={index} className="yh-img-file-box">
                        <Badge
                            count={props.disable ? null : <CloseCircleOutlined style={{ color: '#f5222d' }} onClick={(e) => {
                                e.stopPropagation()
                                let originImg = previewImage.filter(imgMd5 => imgMd5 !== item);
                                // console.log(originImg, item)
                                setpreviewImage(originImg);
                            }} />}
                        >
                            <Image src={item} />
                            {/* <Image src={getFileUrl(item)} /> */}
                        </Badge>
                    </div>
                ))}
                {!props.disable && <Upload
                    showUploadList={false}
                    beforeUpload={(file ) => {
                        let fileEnd = file.name?.substring(file.name.lastIndexOf(".")) ?? "";
                        let filetypes = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
                        let isType = filetypes?.includes(fileEnd);
                        if (!isType) {
                            message.error('不支持该格式的上传');
                            return false;
                        }
                        const isLt2M = file.size / 1024 / 1024 < 2;
                        if (!isLt2M) {
                            message.error('上传到文件不能超过2M');
                            return false;
                        }
                        return true;
                        // return new Promise((resolve, reject) => {
                        //     debugger;
                        //     let _URL = window.URL || window.webkitURL;
                        //     let img = new Image();
                        //     img.src = _URL.createObjectURL(file);
                        //     let width = 800, height = 800;
                        //     img.onload = function () {
                        //         debugger;
                        //         if (img.width > width || img.height > height) {
                        //             message.error(`上传尺寸最大${width}px * ${height}px!`);
                        //             reject();
                        //         } else {
                        //             resolve();
                        //         }
                        //     };
                        // });
                    }}

                    {...{
                        name: 'file',
                        // action: `${"https://file-server.laoniuche.cn"}/file/uploadFile`,
                        action: `${SHOP_API_FILE_SERVE}/uploaders?scene=goods`,
                        timeout: 300000,
                        headers: {
                            timeout: 300000,
                        },
                        onChange(info) {
                            try {
                                if (info.file.status === 'done' && info.file.response && info.file.response.url) {
                                    message.info({ content: `${info.file.name}导入${typeof info?.file?.response?.retData === "string" ? info?.file?.response?.retData : info.file.response.retMsg}`, duration: 10 });
                                    upLoadCallback(info.file);
                                } else if (info.file.status === 'error' && info.file.response) {
                                    message.error(`${info.file.name}:${info.file.response.message}导入异常，请尝试重新导入`);
                                    upLoadCallback(undefined);
                                } else if (info.file.status === 'error') {
                                    message.error("导入错误");
                                    upLoadCallback(undefined);
                                }
                                else if (!info.file.status) {
                                    upLoadCallback(undefined);
                                }
                            } catch (error) {
                                message.warning("导出异常，请重新尝试");
                                upLoadCallback(undefined);
                            }
                        },
                    }}
                >
                    {previewImage.length < photoNum && <Button className="btn-item" shape="round" disabled={props.disable}>上传</Button>}
                </Upload>}
            </Form.Item>
        </>
    )
}
