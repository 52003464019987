import React from "react";
import { DataBox } from "./DataBox";
import TopSearch from "@/components/TopSearch";
import "./baseDict.less";

export default function BaseDict() {
    return (
        <>
            <TopSearch title="数据字典" />
            <DataBox />
        </>
    )
}