import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getServiceOriceList, serviceOriceDelete, serviceOriceUpdate } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { Modal, Switch, Select } from "antd"
import YhTooltip from '@/components/YhTooltip'
import { organPage } from "@/service/orgService"
const { Option } = Select

export default () => {
    const { state, message, showAllOrgan } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [org, setOrg] = useState([])

    useEffect(() => {
        if (state.activeMenu === "servicePricing") {
            loadData(pagination.current)
            organPage({
                searchItem: { orderFlag: 0 },
                pageNum: 1,
                pageSize: 100,
                orders: [{ column: "createTime", rule: "DESC" }],
            }).then((res) => {
                setOrg(res.retData.list)
            })
        }
    }, [state.activeMenu])

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getServiceOriceList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的服务定价信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除该服务定价?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                serviceOriceDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的服务定价")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的服务定价")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }

    //客户开关
    const clientSweitchChange = (checked, e, id) => {
        e.stopPropagation()
        serviceOriceUpdate({ id, status: checked ? 1 : 2 }).then((res) => {
            message.success("操作成功")
            loadData(1, searchValue)
        })
    }

    //table配置
    const columns = [
        {
            title: "所属机构",
            align: "center",
            dataIndex: "organName",
            width: 200,
            render: (text) => <YhTooltip text={text} />,
        },
        {
            title: "工时单价",
            align: "center",
            dataIndex: "hourlyPrice",
            width: 80,
        },
        {
            title: "收费单价",
            align: "center",
            dataIndex: "incomePrice",
            width: 80,
        },
        {
            title: "维修配件占比",
            align: "center",
            dataIndex: "repairPartRate",
            width: 80,
            render: (text) => `${text}%`,
        },
        {
            title: "国产/进口",
            align: "center",
            dataIndex: "domestic",
            width: 80,
            render: (text) => text == 0 ? "进口" : "国产"
        },
        {
            title: "修改日期",
            align: "center",
            dataIndex: "updateTime",
            width: 100,
        },
        {
            title: "操作",
            align: "center",
            width: "12%",
            dataIndex: "status",
            width: 80,
            render: (text, record) => (
                <Switch size="small" checked={text == 1 ? true : false} onChange={(checked, e) => clientSweitchChange(checked, e, record.id)} />
            ),
        },
        {
            title: "备注",
            align: "center",
            dataIndex: "remark",
            width: 200,
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                hidden: !showAllOrgan,
                type: "select",
                placeholder: "请选择客户所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "servicePricing" && (
                <>
                    <TopSearch
                        title="服务定价"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="服务定价"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={900}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            org={org}
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
