import { useState, useEffect,  } from "react";
import { createModel } from "hox";
import { saleDcService } from "./saleDcService";
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";
import { useLockFn } from "ahooks";
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum";
import { formatNum } from "@/erp_subpackage/utils/util";


export const useSaleDcModel = createModel(function () {
    
    const { user: { shopId, shopCode, organType, organTypeCode }, setLoadingInfo } = useGlobalModel();
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表数据源分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //列表统计展示数据
    const initStatistics = {
        totalAmount: 0,
        totalNum: 0
    };    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initStatistics);
    const [statistics, setStatistics] = useState(initStatistics);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });

    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }

    useEffect(() => {
        let newRowsDescTotal = { ...initStatistics };
        selectedRowKeys.forEach((item) => {
            if (item.status !== DictCodeEnmu.SALE_ORDER_CHANGE) {
                let { totalNum, totalAmount } = item
                newRowsDescTotal.totalNum += +formatNum(totalNum)
                newRowsDescTotal.totalAmount += +formatNum(totalAmount)
            }
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [selectedRowKeys])
    //切换门店重置缓存
    useEffect(() => {
        setSearchParam({});
        setSelectedRowKeys([]);
    }, [shopId]);

    //重置
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading(true);
        const searchParams = {
            // outShopId: shopId ?? shopCode,
            ...searchValue,
            // outShopId:  searchValue.shopId ?? '',//默认查全部
            outShopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            status: searchValue?.status ? searchValue?.status.join() : searchValue?.status,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
            branchOut: "ci"
        }
        let { retData } = await saleDcService.page(searchParams);
        const { records, ...pg } = retData;
        let newPg = { ...pg, pageNum: pg.current };
        setPgCfg(transPgToPagination(newPg));
        setDataSource(records);
        setLoading(false);
        setSelectedRowKeys([]);
        loadTotal(searchParams);

    }
    const loadTotal = async (searchParams) => {
        let { retData: statistics } = await saleDcService.pageStatistics(searchParams);
        setStatistics(statistics ?? initStatistics);
    }

    //导出
    const exportSaleDc = async (searchValue = searchParam) => {
      
        setLoading({ tip: "文件导出中", spinning: true });
        try {
            const searchParams = {
                inAndOut: "1",
                // outShopId:searchValue.shopId ?? shopId,
                outShopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
                branchOut:'ci',
                ...searchValue,
                pageSize: -1,
                pageNum: 1,
                id: selectedRowKeys.map(item => item.id).join(),
                status:searchValue.status?searchValue.status.toString():null,
            }
            let result = await saleDcService.export(searchParams);
            result && setLoading({ spinning: false });
            convertRes2Blob(result);
        } catch { setLoading({ spinning: false }); }
    }
    //导出明细
    const exportSDcdetail = useLockFn(async (code) => {
        setLoading({ tip: "明细导出中", spinning: true });
        let result = await saleDcService.exportDetail({
            // outShopId: shopId
            outShopId: searchParam.shopId ? searchParam.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
        });
        convertRes2Blob(result);
        setLoading({ spinning: false });
    })
    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        statistics,
        loading,
        rowsDescTotal, 
        organTypeCode,
        /***method***/
        setRowsDescTotal,
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        exportSaleDc,
        exportSDcdetail,
        organType
    }
});