import React, { useState, useEffect } from "react"
import { YhBox } from "@/erp_subpackage/components/YhBox"
import { Button, Table, message } from 'antd'
import { usePrebookModel } from "../prebookModel";
import { EditableRow, EditableCell } from '../../pubEditble'
import YhIcon from "@/components/pubIcon"
import ItemDrawer from "@/components/pubPartDrawer/itemDrawer"

export const Item = ({ modeKey, mode, rowInfo, dispatch, formRef }) => {

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const { getInfo, getItemList, setItemList } = usePrebookModel();
    const [pagination, setPagination] = useState({ total: 0, current: 1, pageSize: 10 })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [staticInfo, setStaticInfo] = useState({ itemAmount: 0, itemQuantity: 0 })

    useEffect(() => {
        setPagination({ total: getItemList(mode) && getItemList(mode).length, current: 1, pageSize: 10, })

        let itemAmount = 0;
        getItemList(mode) && getItemList(mode).forEach(e => {
            itemAmount = Number(itemAmount) + Number(e.actualAmount);
            itemAmount = itemAmount.toFixed(2);
        });
        setStaticInfo({ itemAmount: itemAmount, itemQuantity: getItemList(mode) && getItemList(mode).length });
    }, [getItemList(mode)])

    const onTableChange = async (pagination) => {
        setPagination({
            ...pagination,
            current: pagination.current,
        })
    }

    const handleItemList = (items) => {
        if (items.length) {
            let newArr = []
            items.forEach((item) => {
                let unselect = item
                let itemData = {
                    id: new Date().getTime() + parseInt(Math.random() * 100000),
                    itemId: unselect.id,
                    itemCode: unselect.itemCode,
                    itemName: unselect.itemName,
                    itemAttr: unselect.itemAttr,
                    itemWorkHours: unselect.itemWorkHours, //工时
                    workHoursPrice: unselect.workHoursPrice, //单价
                    itemAmount: unselect.itemAmount, //工时费
                    preferentialAmount: 0, //优惠
                    discount: "", //折扣
                    actualAmount: unselect.itemAmount, //总金额
                    remark: unselect.remark, //备注
                    giftFlag: "not_gift",
                }
                newArr.push(itemData)
            })
            let list = [...getItemList(mode), ...newArr];
            setItemList(mode, list);
        }
        setVisible(false)
    }

    const columns = [
        {
            title: '序号',
            width: 40,
            align: "center",
            render: (t, r, i) => i + 1
        },
        {
            title: "项目编码",
            width: 120,
            align: "center",
            dataIndex: "itemCode",
        },
        {
            title: "项目名称",
            width: 200,
            align: "center",
            dataIndex: "itemName",
        },
        {
            title: "项目属性",
            width: 120,
            align: "center",
            dataIndex: "itemAttr",
            render: (text) => (text === 'import' ? "进口" : text === 'domestic' ? "国产" : ""),
        },
        {
            title: "工时",
            width: 100,
            align: "center",
            dataIndex: "itemWorkHours",
            editable: true,
        },
        {
            title: "单价",
            width: 100,
            align: "center",
            dataIndex: "workHoursPrice",
            editable: true,
        },
        {
            title: "工时费",
            width: 100,
            align: "center",
            dataIndex: "itemAmount",
        },
        {
            title: "优惠",
            width: 100,
            align: "center",
            dataIndex: "preferentialAmount",
            editable: true,
        },
        {
            title: "折扣",
            width: 100,
            align: "center",
            dataIndex: "itemDiscount",
            editable: true,
        },
        {
            title: "总金额",
            width: 100,
            align: "center",
            dataIndex: "actualAmount",
        },
        {
            title: "是否赠送",
            width: 120,
            align: "center",
            dataIndex: "giftFlag",
            render: (text) => (text === 'not_gift' ? "不赠送" : text === 'gift' ? "赠送" : ""),
        },
        {
            title: "备注",
            width: 120,
            align: "center",
            dataIndex: "remark",
            editable: true,
        },
        {
            title: "操作",
            width: 120,
            align: "center",
            dataIndex: "operation",
            render: (_, record) => {
                return (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                    <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteOne(record)} />
            },
        },
    ]

    const ItemColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: itemHandleSave,
            }),
        }
    })

    //单元格编辑保存
    const itemHandleSave = (row, status) => {
        row.itemAmount = (row.itemWorkHours * row.workHoursPrice).toFixed(2);
        if (row.itemDiscount > 0) {
            row.actualAmount = ((row.itemAmount * row.itemDiscount) / 10 - row.preferentialAmount).toFixed(2)
        } else {
            row.actualAmount = (row.itemAmount - row.preferentialAmount).toFixed(2)
        }

        const newData = [...getItemList(mode)]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setItemList(mode, newData);
    }

    const onRow = (record) => {
        let findIndex = selectedRowKeys.indexOf(record.id);
        let newSelectedRowKeys = [...selectedRowKeys];
        if (findIndex === -1) {
            newSelectedRowKeys.push(record.id);
        } else {
            newSelectedRowKeys.splice(findIndex, 1);
        }
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const deleteOne = (record) => {
        let list = getItemList(mode).filter((item) => item.id !== record.id);
        setItemList(mode, list);
        setSelectedRowKeys([]);
    };

    const deleteBatch = () => {
        let list = getItemList(mode);
        selectedRowKeys.forEach((e) => {
            list = list.filter((item) => item.id !== e)
        })
        setItemList(mode, list);
        setSelectedRowKeys([]);
    };

    const add = () => {
        let fields = formRef.current.getFieldsValue()
        let orderType = fields && fields.orderType;
        if (!orderType) {
            message.error("请先选择工单类型")
            return
        }
        setVisible(true);
    };

    const action =
        <>
            {getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) &&
                <YhIcon type="icon-zengjia" style={{ color: "#37B36F", fontSize: "26px", position: 'relative', top: 5 }} onClick={() => add()} />
            }
            <Button
                className="btn-item"
                type="link"
                shape="round"
                disabled={getInfo(mode) && (getInfo(mode).prebookStatus == null || getInfo(mode).prebookStatus === 0) ? false : true}
                onClick={deleteBatch}
                style={{ margin: "10px" }}>
                批量删除
            </Button>
        </>

    return (
        <YhBox title="维修项目" rightChildren={action} style={{ marginBottom: '10px' }}>
            <div style={{ height: 0, position: 'relative', float: 'left', top: -50, left: 100, }}>
                总金额￥<span style={{ color: 'red', fontWeight: 'bold' }}>{staticInfo.itemAmount}</span>元（{staticInfo.itemQuantity}项）
            </div>
            <Table
                size="small"
                columns={ItemColumns}
                components={{ body: { row: EditableRow, cell: EditableCell } }}
                dataSource={getItemList(mode)}
                rowKey={(record) => record.id}
                loading={loading}
                onChange={onTableChange}
                rowSelection={{
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        let changeRowId = changeRows.map(item => item.id);
                        if (selected) {
                            setSelectedRowKeys((origin) => [...origin, ...changeRowId])
                        }
                        else {
                            setSelectedRowKeys((origin) => origin.filter(item => changeRowId.indexOf(item) < 0));
                        }
                    },
                    onSelect: (record, selected, selectedRows) => onRow(record),
                    selectedRowKeys: selectedRowKeys
                }}
                pagination={{
                    showQuickJumper: true,
                    showSizeChanger: false,
                    showTotal: () => `共${pagination.total}条`,
                    position: ["bottomCenter"],
                    ...pagination,
                }}
            />
            <ItemDrawer visible={visible} onClose={(bool) => setVisible(bool)} onItemOk={handleItemList} drawerMode="item" />
        </YhBox >
    )
}
