import { post, get } from "../utils/request"

/**
 * 菜单管理
 */

/**
 * 树
 * @param {*} data
 * @returns
 */
export function getMenuTree(data) {
    return post("/ci-platform/menu/tree", data)
}

export function getMenuTreeFromToken() {
    return get("/ci-platform/menu/tree")
}

export function getMenuAllFromToken() {
    return get("/ci-platform/menu/all")
}

export function addMenu(data) {
    console.log(data)
    return post("/ci-platform/menu/insert", data)
}

export function editMenu(data) {
    console.log(data)
    return post("/ci-platform/menu/update", data)
}

export function getMenuAll() {
    let data = {
        pageSize: 10000,
        pageNum: 1,
        searchItem: {},
    }
    return post("/ci-platform/menu/page", data)
}

export function getMenuOne(id) {
    return get(`/ci-platform/menu/one/${id}`)
}
