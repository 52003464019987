import { createModel } from "hox"
import { message } from "antd"
import { useState, useEffect } from "react"
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel"
import { saleSoService } from "./saleSoService"
import { formatNum } from "@/erp_subpackage/utils/util"
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
export const useSaleSoModel = createModel(function () {
    const {
        user: { shopId, shopCode, shopName, userName, organType, organTypeCode },
    } = useGlobalModel(({ user }) => [user])
    const [print, setPrint] = useState(false)
    const initSearchParam = {}
    //查询条件
    const [searchParam, setSearchParam] = useState(initSearchParam)
    //列表数据源
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState({
        spinning: false,
        tip: "加载中...",
    })
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    //表格选中的值
    const [selectedRow, setSelectedRow] = useState({})
    //列表数据源分页参数
    const initPgCfg = {
        pageSize: 15,
        current: 1,
    }
    const [pgCfg, setPgCfg] = useState(initPgCfg)
    const initDesc = {
        totalNum: 0,
        totalAmount: 0,
        discountTotalAmount: 0,
        receivedAmount: 0,
        latestPurPrice: 0,
        receivableAmount: 0,
        grossProfit: 0,
    }
    const [descTotal, setDescTotal] = useState(initDesc)
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initDesc)
    const [descRows, setDescRows] = useState([])

    useEffect(() => {
        let newRowsDescTotal = initDesc
        descRows.forEach((item) => {
            if (item.status !== DictCodeEnmu.SALE_CHANGE) {
                let { totalNum, totalAmount, discountTotalAmount, receivedAmount, receivableAmount, totalCost, grossProfit } = item
                newRowsDescTotal.totalNum += totalNum
                newRowsDescTotal.totalAmount += +formatNum(totalAmount)
                newRowsDescTotal.discountTotalAmount += +formatNum(discountTotalAmount)
                newRowsDescTotal.receivedAmount += +formatNum(receivedAmount)
                newRowsDescTotal.latestPurPrice += +formatNum(totalCost)
                newRowsDescTotal.receivableAmount += +formatNum(receivableAmount)
                newRowsDescTotal.grossProfit += +((grossProfit ?? 0).toFixed(2))
            }
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [descRows])

    useEffect(() => {
        let filters = dataSource.filter((el) => (selectedRowKeys.length > 0 ? el.saleCode === selectedRowKeys[0] : false))
        const selectedData = filters && filters.length > 0 ? filters[0] : undefined
        // console.log(selectedRow)
        setSelectedRow(selectedData)
    }, [selectedRowKeys, dataSource])

    const [bingingModal, setBindingModal] = useState(false) //绑定弹框
    const [bingingDetailModal, setBindingDetailModal] = useState(false) //绑定明细弹框
    const [cancelSettleModal, setCancelSettleModal] = useState(false) //取消结算明细弹框
    const [dispatchingModal, setDispatchingModal] = useState(false) //派工弹框

    const updatePgCfg = (updateParam) => {
        setPgCfg({
            ...pgCfg,
            ...updateParam,
        })
    }
    const updateSearchParam = (updateParam) => {
        setSearchParam({
            ...searchParam,
            ...updateParam,
        })
    }
    const resetSearchParam = () => {
        setSearchParam(initSearchParam)
        loadData(initPgCfg, {})
        setSelectedRowKeys([])
        setSelectedRow({})
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ tip: "列表加载中...", spinning: true })
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status
        const searchParams = {
            ...searchValue,
            status,
            // shopId: searchValue.shopId ?? shopId,
            // shopId: searchValue.shopId ?? '',//默认查全部
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            // shopName: searchValue.shopName ?? shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await saleSoService.page(searchParams)
        const { records, ...pg } = retData
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }))
        setDataSource(
            records.map((item) => ({
                ...item,
                paymentMethodCode: item.settleDetails[0] ? item.settleDetails[0].payMethodCode : "",
            }))
        )
        setSelectedRowKeys([])
        let { retData: statistics } = await saleSoService.total(searchParams)
        setDescTotal(statistics)
        setLoading({ spinning: false })
        setDescRows([]);
    }

    const invalidate = async (cancelReason) => {
        let { id, saleCode } = selectedRow
        let { retData } = await saleSoService.invalidate({
            id,
            saleCode,
            cancelReason,
        })
        if (retData) {
            loadData()
            message.success("作废成功")
        }
    }
    const exportSaleSo = async (searchValue = searchParam) => {
        // setLoading({ tip: "文件导出中", spinning: true, })
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status
        const searchParams = {
            ...searchValue, status,
            // shopId,
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            shopName,
            shopCode,
            id: descRows.map((item) => item.id).join(),
        }
        let {retData} = await saleSoService.export(searchParams)
        // convertRes2Blob(result)
        message.success(retData)
        // setLoading({
        //     spinning: false,
        // })
    }
    const detailsExport = async (searchValue = searchParam) => {
        // setLoading({
        //     tip: "文件导出中",
        //     spinning: true,
        // })
        let status = Array.isArray(searchValue?.status) ? searchValue?.status.join() : searchValue?.status
        const searchParams = {
            ...searchValue,
            status,
            // shopId,
            shopId: searchValue.shopId ? searchValue.shopId : organTypeCode === 3 ? '' : shopId,//平台默认查全部
            shopName,
            shopCode,
            id: descRows.map((item) => item.id).join(),
        }
        let {retData} = await saleSoService.detailsExport(searchParams)
        message.success(retData)
        // convertRes2Blob(result)
        // setLoading({
        //     spinning: false,
        // })
    }
    const [settleScaniaPrint, setSettleScaniaPrint] = useState(false)

    return {
        /***field***/
        searchParam,
        dataSource,
        selectedRowKeys,
        pgCfg,
        initPgCfg,
        bingingModal,
        descTotal,
        selectedRow,
        print,
        rowsDescTotal,
        descRows,
        settleScaniaPrint,
        bingingDetailModal,
        cancelSettleModal,
        dispatchingModal,
        loading,
        organType,
        organTypeCode,
        /***method***/
        setDataSource,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        updatePgCfg,
        setBindingModal,
        setBindingDetailModal,
        setCancelSettleModal,
        setDispatchingModal,
        setDescTotal,
        setSelectedRow,
        invalidate,
        exportSaleSo,
        setPrint,
        detailsExport,
        setRowsDescTotal,
        setDescRows,
        setSettleScaniaPrint,
    }
})
