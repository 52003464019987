import React, { useEffect, useState, useContext } from 'react'
import { Modal } from 'antd'
import { MyContext } from '@/store/myContext'
import { factoryThreeGuaranteesList } from '@/service/ordersService'
import TopSearch from '@/components/TopSearch'
import ListComponent from '@/components/listComponent'

export default (props) => {
    const { dispatch, tabs, state, detailsPage, Option, showAllOrgan } = useContext(MyContext)
    const { visible, setVisible, rowInfo } = props
    const [list, setList] = useState([])
    const [colums, setColums] = useState([])
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })

    useEffect(() => {
        if (visible) loadData()
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    const toSanbanBill = async(claimNumber) => {
        await dispatch({
            type: 'removeTabs',
            targetKey: 'sanbaoBill',
        })
        await dispatch({
            type: 'changeTabs',
            activeMenu: 'sanbaoBill',
            name: '三包账单',
            content: tabs.sanbaoBill({
                claimNumber
            }),
        })
    }

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: {
                ...search,
                // ciFactoryThreeGuaranteesImportRecordId: '8c20f56229a14321aac9cc682e5ce9f0'
                ciFactoryThreeGuaranteesImportRecordId: rowInfo.id,
            },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                },
            ],
        }
        factoryThreeGuaranteesList(data).then((res) => {
            const { list, total } = res.retData
            setpagination({
                current: current,
                total: total,
                pageSize: 10,
            })
            let listData = []
            let columsData = []
            list.forEach((item, index) => {
                const { orderId, orderCode, valList, claimNumber } = item
                let newI = {
                    orderCode,
                    orderId,
                    claimNumber
                }
                index === 0 && columsData.push({
                    title: '索赔单号',
                    align: 'center',
                    dataIndex: 'claimNumber',
                    sort: -1,
                })
                valList.forEach((i) => {
                    newI[i.titleCode] = i.val
                    if (index === 0) {
                        columsData.push({
                            title: i.titleName,
                            align: 'center',
                            dataIndex: i.titleCode,
                            sort: i.componentSort,
                        })
                    }
                })
                listData.push(newI)
            })
            setList(listData)
            columsData = columsData.sort((a, b) => a.sort - b.sort)
            columsData.length > 0 &&
                columsData.push({
                    title: '状态',
                    align: 'center',
                    dataIndex: 'orderCode',
                    render: (text, record) => <>{text ? <span style={{ color: 'blue' }} onClick={() => {toSanbanBill(record.claimNumber)}}>已匹配</span> : <span>未匹配</span>}</>,
                    fixed: 'right',
                    width: '100px'
                })
            setColums(columsData)
        })
    }

    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'input',
                placeholder: '索赔单号',
                valueName: 'claimNumber',
            },
        ],
    }

    return (
        <>
            <Modal
                forceRender
                visible={visible}
                title="厂家结算单"
                onCancel={() => {
                    setVisible(false)
                }}
                centered={true}
                footer={[]}
                width={1200}
                zIndex={1000}
            >
                <TopSearch
                    title="详情"
                    searchListData={searchList}
                    searchValue={searchValue}
                    setSearchValue={(obj) => setSearchValue(obj)}
                    handleSearch={loadData}
                />
                <ListComponent
                    title="详情"
                    columns={colums}
                    dataSource={list}
                    pagination={pagination}
                    onTableChange={onTableChange}
                    scroll={{ x: 'max-content' }}
                />
            </Modal>
        </>
    )
}
