import React, { useEffect, useState } from "react"
import { createModel } from "hox";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";
import { taskBookService } from "@/service/taskBookService"
import moment from 'moment'
import { momentFormat } from "@/erp_subpackage/utils/util"

export const taskBookModel = createModel(function () {

    const { getTreeNodeName, getTreeNodeOne } = useDictModel(() => [])
    const [tabslist, setTablist] = useState([])
    const [treeData, setTreeData] = useState([])
    const [firstMaintainLevel, setFirstMaintainLevel] = useState()

    const [tcoDict, setTcoDict] = useState([])

    //表单缓存
    const [formVal, setFormVal] = useState({ disableFlag: true });

    const [jobData, setJobData] = useState([])
    const [itemData, setItemData] = useState([])
    const [onemaintainData, setOnemaintainData] = useState([])
    const [pdfData, setPdfData] = useState([]);
    const [choosedJobData, setChoosedJobData] = useState();
    const [itemSearchVal, setItemSearchVal] = useState('')
    const [chooseItemVisible, setChooseItemVisible] = useState(false)
    const [chooseItemType, setChooseItemType] = useState('')
    const [bookId, setBookId] = useState('')

    const loadTcoDict = async () => {
        let {retData} = await taskBookService.allDict({});
        setTcoDict(retData)
    }

    const getTaskBookDetail = async (vehicleCode) => {
        setFormVal([]);
        setJobData([]);
        setItemData([]);
        setOnemaintainData([]);
        let searchVo = {"vehicleCode": vehicleCode,"vehicleBrandName":"","vehicleModelName":"","pageSize":1,"pageNum":1,"orders":[]};
        let { retData: ret }= await taskBookService.taskBookList(searchVo);
        if (ret != null && ret.records != null && ret.records.length > 0) {
            setBookId(ret.records[0].id);
            let { retData } = await taskBookService.oneTaskBook(ret.records[0].id)
            const { changeItems, checkItems, checkDetails, pdfs , ...formVal } = retData
            if (retData) {
                const { firstMaintainLevel, vehicleBrandCode, vehicleModelCode, domestic } = retData
                
                setFirstMaintainLevel(firstMaintainLevel);
                const treeData = await getTreeData(vehicleModelCode === 'vehicle-type.trailer', domestic)
                const item = treeData?.find((i) => i.id === firstMaintainLevel?.split('~')[0])
                if (item) {
                    onTreeSelect(firstMaintainLevel, item, false)
                }
                let isDomestic = false
                let brand = getTreeNodeOne(vehicleBrandCode)
                
                if (brand && brand.length > 0) {
                    const { value } = brand[0]
                    if (value === '进口') {
                        isDomestic = false
                    } else {
                        isDomestic = true
                    }
                }

                setFormVal({
                    ...formVal,
                    disableFlag: !!formVal.disableFlag,
                    createTime: momentFormat(formVal.createTime),
                    transferTime: momentFormat(formVal.transferTime),
                    isDomestic,
                    drivingDistributeDate: formVal.drivingDistributeDate ? moment(formVal.drivingDistributeDate) : null,
                    scheduleRuleChinese: item?.scheduleRuleChinese,
                })

                setJobData([...checkDetails?.map((i) => ({ ...i, isChoosed: true }))])
                setItemData([...checkItems?.map((i) => ({ ...i, isChoosed: true }))])
                setOnemaintainData([...changeItems?.map((i) => ({ ...i, isChoosed: true }))])
                setPdfData([...pdfs?.map((i) => ({ ...i, isChoosed: true }))]);
            }
       }
    }

    const getTreeData = async (isTrailer, isDomestic) => {
        let isD = typeof isDomestic === 'boolean' ? isDomestic : formVal.isDomestic
        let brandType
        if (isTrailer) {
            brandType = ''
        } else {
            brandType = isD ? '国产' : '进口'
        }
        let data = {
            pageNum: 1,
            pageSize: 100,
            brandType,
        }
        let { retData } = await taskBookService.scheduleSelect(data);
        
        let treeData = retData.map((i) => {
            let { id, name, schedule, scheduleRule, scheduleRuleChinese } = i
            let children = []
            Object.entries(schedule).forEach((i) => {
                children.push({
                    value: i[1],
                    title: i[0].split('-')[0],
                    scheduleRule,
                    scheduleRuleChinese,
                })
            })
            return { ...i, value: id, title: name, children, selectable: false }
        })
        setTreeData(treeData)
        return treeData
    }

    const onTreeSelect = (e, i, isGetDefaultLibraryType = true) => {
        let { scheduleRule, scheduleRuleChinese, name, key } = i
        let val = { firstMaintainLevel: e, scheduleRuleChinese, name }
        val ? setFormVal({ ...formVal, ...val }) : setFormVal({})
        scheduleRule = Array.from(new Set(scheduleRule.split(',')))
            .sort()
            .join(',')
        let repla = [
            ['0', '零'],
            ['1', '一'],
            ['2', '二'],
            ['3', '三'],
            ['4', '四'],
            ['5', '五'],
            ['6', '六'],
            ['7', '七'],
            ['8', '八'],
            ['9', '九'],
        ]
        repla.forEach((i) => {
            scheduleRule = scheduleRule.replaceAll(i[0], i[1])
        })
        let arr = scheduleRule.split(',')
        arr = arr.map((i) => i + '级保养')
        setTablist(arr)
        
        isGetDefaultLibraryType && getDefaultLibraryType({ ...formVal, ...val })
    }

    const getDefaultLibraryType = async (formData) => {
        //setJobData([])
        const { driveTypeName, fuelTypeName, effluentStandardName, vehicleBrandName, vehicleModelName, firstMaintainLevel } = formData
        if (!firstMaintainLevel) {
            return
        }
        const firstMaintainLevelParentKey = firstMaintainLevel.split('~')[0]
        if (driveTypeName && fuelTypeName && effluentStandardName && vehicleBrandName && vehicleModelName) {
            const { retData } = await taskBookService.getLibraryType({
                driveTypeName,
                fuelTypeName,
                effluentStandardName,
                vehicleBrandName,
                vehicleModelName,
            })
            if (retData) {
                const { changeItems, checkDetails, firstMaintainLevel } = retData
                setOnemaintainData(changeItems)
                if (firstMaintainLevelParentKey && firstMaintainLevel === firstMaintainLevelParentKey) {
                    setJobData(
                        checkDetails.map((i) => ({ ...i, isChoosed: true, temporaryId: new Date().getTime() + Math.floor(Math.random() * 10000) }))
                    )
                }
            }
        }
    }

    const updataOnemaintainData = (type, choosedData) => {
        let data = [...onemaintainData]
        data = data.filter((item) => item.isChoosed)

        choosedData.forEach((item) => {
            return data.push({
                // ...item,
                itemCode: item.itemCode,
                itemName: item.itemName,
                id: '',
                temporaryId: new Date().getTime() + Math.floor(Math.random() * 10000),
                isChoosed: true,
                maintenanceLogBook: item.itemName,
                itemClassification: '',
                materialName: '',
                specification: '',
                materialCode: '回车检索',
            })
        })

        setOnemaintainData(data)
    }

    return {
        tcoDict,
        firstMaintainLevel,
        tabslist,
        treeData,
        jobData,
        itemData,
        onemaintainData,
        pdfData, 
        bookId,
        formVal,
        choosedJobData,
        itemSearchVal, 
        chooseItemVisible, 
        chooseItemType, 
        loadTcoDict,
        onTreeSelect,
        getTaskBookDetail,
        setItemData,
        setJobData,
        setOnemaintainData,
        setPdfData,
        updataOnemaintainData,
        setChoosedJobData,
        setItemSearchVal,
        setChooseItemVisible,
        setChooseItemType,
    }
});
