import React, { useEffect, useRef, useState, useContext } from "react"
import { Button, message } from "antd"
import { YhFormBox } from "@/erp_subpackage/components/YhFormBox"
import { formatNum } from "@/erp_subpackage/utils/util"
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel"
import { useSaleDcDetailsModel } from "./saleDcDetailsModel"
import useGlobalModel, { defaultMdCmParam } from "@/erp_subpackage/model/globalModel"
import { debounce } from "lodash"
import { saleDcService } from "../../saleDcService"
import { DictCodeEnmu } from "@/erp_subpackage/utils/DictCodeEnum"
import { purchaseDrService } from "@/erp_subpackage/pages/purchase/dr/purchaseDrService";
import { MyContext } from "@/store/myContext"

export const FormBox = ({ modeKey, mode, rowId, dispatch }) => {
    const formRef = useRef()
    const { fetchFmsShopList, user: { allowTransfer, businessEntityCode, businessEntityName, orgShopCode } } = useGlobalModel(({ user }) => [user]);
    const {
        editFormVal,
        addFormVal,
        editGoodsList,
        addGoodsList,
        updateFormVal,
        outWhConfirm,
        setPrint,
        insertSDc,
        updateSDc,
        getSDcDetailOne,
        verifyGoods,
        rejectDc,
        exportSDcdetail,
        setEditGoodsList,
        setAddGoodsList,saveBtnLoading,
        saveBtn
    } = useSaleDcDetailsModel();
    const { detailsPage } = useContext(MyContext);

    const { getSelectedDictOne, getTreeNodeName } = useDictModel(() => [])
    const formVal = mode === "add" ? addFormVal : editFormVal
    const [outUnitBeList, setOutUnitBeList] = useState([]) //调出机构对应的关联实体
    const [inUnitBeList, setInUnitBeList] = useState([]) //调入机构对应的关联实体

    //编辑时， 没有缓在调用详情接口  
    useEffect(() => {
        if (mode === "edit" || mode === "look") {
            getSDcDetailOne(rowId).then((res) => {
                getShopInUnitDetail({ label: res.shopName, value: res.shopId, id: res.shopId, branch: res.branchIn }, true)
                getShopOutUnitDetail(res.outShopId)
            })
        }else{
            getShopOutUnitDetail(addFormVal.outShopId)
        }
        
        // 销毁的时候触发
        return () => {
            updateFormVal(mode)
            mode === "add" ? setAddGoodsList([]) : setEditGoodsList([])
            
        }
    }, [rowId])


    //表单change
    const onChange = (changedValues, values) => {
        updateFormVal(mode, changedValues);
    }

    const goBack = () => {
        dispatch({
            type: "removeTabs",
            targetKey: modeKey,
            activeMenu: "saleDc",
        })
    }

    //表单提交
    const onSubmit = () => {
        //if (!verifyGoods(mode)) return
        let goods = mode === 'add' ? addGoodsList : editGoodsList
        if(goods.length==0){
            message.warning(`未添加商品明细不可生成调出单`)
            return
        }
        formRef.current.validateFields().then((val) => {
            let data = {
                ...val,
                transferTypeName: getTreeNodeName(val.transferTypeCode),
                outBentityName: val.outBentityCode ? businessEntityName : undefined,
                bentityName: inUnitBeList.find(item => item.value === val.bentityCode)?.label
            }
            mode === "edit" ? updateSDc(data) : insertSDc(data).then((res) => {
                if (res) {
                    goBack();
                    dispatch({
                        type: "changeTabs",
                        name: "调出单编辑",
                        activeMenu: "saleDc/edit",
                        deleteKey: "saleDc/edit",
                        content: detailsPage.SaleDcDetails({
                            modeKey: "saleDc/edit",
                            mode: "edit",
                            dispatch,
                            rowId: res.id
                        }),
                    })
                }
            })
        })
    }

    const getShopOutUnitDetail = async (id) => {
        let { retData } = await purchaseDrService.queryShopUnitBe(id)
        if (retData && retData.id) {
            if (retData.bentityList && retData.bentityList.length) {
                setOutUnitBeList(retData.bentityList.map((item) => ({ label: item.name ?? '', value: item.code ?? '' })))
            } else {
                setOutUnitBeList([])
                message.warning('调出机构对应的核算单位没有关联实体')
            }
        }
    }

    //回显选中调入机构带出来的数据
    const getShopInUnitDetail = async (option, echo = false) => {
        //分支为云创的  查询机构单条
        // if (option.branch === defaultMdCmParam.sourceApp) {
        //     let { retData: erpData } = await purchaseDrService.queryOrganOne(option.id ?? option.code);
        //     let data = {
        //         shopCode: option.code,
        //         shopName: option.name,
        //         shopId: option.id,
        //         branchIn: option.branch,
        //         transferTypeName: option.branch === "scm" ? "备品调拨" : "其它调拨",
        //         transferTypeCode: option.branch === "scm" ? DictCodeEnmu.TRANSFER_TYPE_BP : DictCodeEnmu.TRANSFER_TYPE_OTHERS,
        //         bentityName: undefined,   //每次选中的时候对应的实体置空
        //         bentityCode: undefined,
        //     }
        //     setInUnitBeList(erpData?.businessEntityCode ? [{ label: erpData.businessEntityName, value: erpData.businessEntityCode }] : []);
        //     //回显时不用执行一下操作
        //     if (echo) return;
        //     updateFormVal(mode, { ...data });
        //     mode === "add" ? setAddGoodsList([]) : setEditGoodsList([])
        // } else {
            let { retData } = await saleDcService.queryShopUnitBe(option.id)
            let data = {
                shopCode: option.code,
                shopName: option.name,
                shopId: option.id,
                transferTypeName: option.branch === "scm" ? "" : "其它调拨",
                transferTypeCode: option.branch === "scm" ? "" : DictCodeEnmu.TRANSFER_TYPE_OTHERS,
                bentityName: undefined, //每次选中的时候对应的实体置空
                bentityCode: undefined,
                branchIn: option.branch,
            }
            if (retData && retData.id) {
                if (retData.bentityList && retData.bentityList.length) {
                    setInUnitBeList(retData.bentityList.map((item) => ({ label: item.name ?? "", value: item.code ?? "" })))
                } else {
                    setInUnitBeList([])
                    message.warning("调入机构对应的核算单位没有关联实体")
                }
                //回显时不用执行一下操作
                if (echo) return
                updateFormVal(mode, { ...data });
                mode === "add" ? setAddGoodsList([]) : setEditGoodsList([])
            } else {
                setInUnitBeList([])
                message.warning("调入机构没有关联的核算单位");
                //回显时不用执行一下操作
                if (echo) return
                updateFormVal(mode, { ...data });
                mode === "add" ? setAddGoodsList([]) : setEditGoodsList([])
            }
        // }
    }



    //调入机构
    const inShopChange = (value, option) => {
        if (option) {
            getShopInUnitDetail(option)
            mode === "add" ? setAddGoodsList([]) : setEditGoodsList([])
        }
    }

    const onRefresh = debounce(() => {
        getSDcDetailOne(rowId).then((res) => message.success("已刷新"))
    }, 500)

    const rightChildren = (
        <>
            {mode === "edit" && <>
                <Button className="btn-item" type="link" shape="round" onClick={() => exportSDcdetail()}>
                    导出
                </Button>
                <Button className="btn-item" type="link" shape="round" onClick={() => setPrint(true)}>
                    打印
                </Button>
                <Button className="btn-item" type="link" shape="round" onClick={onRefresh}>
                    刷新
                </Button>
                <Button className="btn-item" type="link" shape="round" onClick={() => rejectDc()}>
                    驳回
                </Button>
                <Button
                    className="btn-item"
                    type="link"
                    shape="round"
                    disabled={editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT|| saveBtn}
                    onClick={debounce(() => {
                        outWhConfirm()
                    })}
                >
                    调出
                </Button>
            </>}
            <Button
                className="btn-item"
                type="link"
                shape="round"
                onloading={saveBtnLoading}
                disabled={mode === "edit" && editFormVal.status === DictCodeEnmu.TRANSFER_STATUS_IN}
                onClick={debounce(() => {
                    onSubmit()
                }, 900)}
            >
              {saveBtnLoading ? '保存中' : '保存'}
            </Button>
            <Button className="btn-item" type="link" shape="round" onClick={() => goBack()}>
                返回
            </Button>
        </>
    )

    const baseFormItem = {
        title: "基础信息",
        style: { padding: "10px 0 0 0", marginBottom: "15px" },
        contentClass: "pr-77",
        rightChildren,
        items: [
            {
                type: 'Input',
                fieldLabel: "调出单号",
                fieldName: "transferOutCode",
                disable: true,
                placeholder: "自动生成",
            },
            {
                type: mode === 'add'?'AsyncSelect':'Input',
                fieldLabel: "调入机构",
                fieldName:  mode === 'add'?"shopIdShow":'shopName',
                onSelectChange: inShopChange,
                rules: [{ required: true, message: "请选择调入机构" }],
                disable: mode === "edit",
                selectfetchList: fetchFmsShopList,
                isFmsShop: businessEntityCode && orgShopCode && allowTransfer,  //有实体 && 有门店 && 有调拨权限 才可以掉用主数据
                defaultQuery: mode === 'add'?formVal.shopName:formVal.shopCode,
            },
            {
                type: 'Input',
                fieldLabel: "调出机构",
                fieldName: "outShopName",
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: "备注",
                fieldName: "note",
            },
            {
                type: 'Select',
                fieldLabel: "调拨类型",
                fieldName: "transferTypeCode",
                options: getSelectedDictOne(DictCodeEnmu.TRANSFER_TYPE),
                // rules: [{ required: true, message: "请选择调拨类型" }],
                disable: true
            },
            {
                type: 'Select',
                fieldLabel: "调入实体",
                fieldName: "bentityCode",
                options: inUnitBeList,  //调出为云创的机构， 实体不是必填
                // disable: mode === "edit" && editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT,
                rules: [{
                    required: (() => {
                        console.log(formVal)
                        return formVal.branchIn !== defaultMdCmParam.sourceApp
                    })(), message: "请选择调入实体"
                }],
            },
            {
                type: 'Select',
                fieldLabel: "调出实体",
                fieldName: "outBentityCode",
                options: outUnitBeList,   //没有实体不是必填项、 
                // disable: mode === "edit" && editFormVal.status !== DictCodeEnmu.TRANSFER_STATUS_INIT,
                rules: [{ required: !!businessEntityCode && !!orgShopCode && allowTransfer, message: "请选择调出实体" }],
            },
            {
                type: 'Input',
                fieldLabel: '调入备注',
                fieldName: 'inNote',
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: "制单员",
                fieldName: "applier",
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: "制单日期",
                fieldName: "applyTime",
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: "调入员",
                fieldName: "transferIn",
                disable: true,
            }, {
                type: 'Input',
                fieldLabel: "调入日期",
                fieldName: "transferInTime",
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: "调出员",
                fieldName: "transferOut",
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: "调出日期",
                fieldName: "transferOutTime",
                disable: true,
            },
            {
                type: "Input",
                fieldLabel: "其他费用",
                fieldName: "otherFees",
                rules: [{ pattern: /^[0-9]+(.[0-9]{0,2})?$/, message: '请输入数字且可保留两位小数' }],
                hidden: true
            },
            {
                type: 'Input',
                fieldLabel: "运费",
                fieldName: "freight",
                rules: [{ pattern: /^[0-9]+(.[0-9]{0,2})?$/, message: '请输入数字且可保留两位小数' }],
                hidden: true
            },
            //带出字段
            {
                type: 'Input',
                fieldLabel: "调入实体名称",
                fieldName: "bentityName",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调出机构Code",
                fieldName: "outShopCode",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调出机构id",
                fieldName: "outShopId",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调入机构名称",
                fieldName: "shopName",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调入机构Id",
                fieldName: "shopId",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调入机构code",
                fieldName: "shopCode",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调出实体名称",
                fieldName: "outBentityName",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调拨类型名称",
                fieldName: "transferTypeName",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调入分支",
                fieldName: "branchIn",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调出分支",
                fieldName: "branchOut",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "状态",
                fieldName: "status",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调拨单号",
                fieldName: "code",
                hidden: true,
            },
            {
                type: 'Input',
                fieldLabel: "调入单号",
                fieldName: "transferInCode",
                hidden: true,
            },
            {
                type: 'FormUpload',
                fieldLabel: '附件',
                fieldName: 'outName',
                fieldMd5: 'outMd5',
                defaultFileName: formVal.outName,
                defaultFileMd5: formVal.outMd5,
                onSetFieldsValue: onChange,
            },
            {
                type: 'FormUpload',
                fieldLabel: '调入附件',
                fieldName: 'inName',
                fieldMd5: 'inMd5',
                defaultFileName: formVal.inName,
                defaultFileMd5: formVal.inMd5,
                isUsePortalApi:formVal.branchIn!=='ci'?true:false,
                onSetFieldsValue: onChange,
                hidden:mode == 'add'?true:false,
                lookBranch:true
            },
        ],
    }

    const poFromConfig = {
        formRef,
        labelCol: { span: 6 },
        labelAlign: "right",
        formValues: mode === "edit" ? editFormVal : addFormVal,
        onChange,
        boxs: [baseFormItem],
    }

    return <YhFormBox {...poFromConfig} />
}
