/**
 * 结算类型
 */
 export const  IVRMSettleTypeEnum =  {
    SETTLE_MONTHLY: "settle-type.monthly", //月结
	SETTLE_CREDIT: "settle-type.credit", //挂账
	SETTLE_CASH: "settle-type.cash",  //现结
	SETTLE_BALANCE: "settle-type.balance"//结转
}

export const UserTypeEnum = {
	'EMPLOYEE': "user-type.employee", // "内部员工"
	'MICRO_BOSS': "user-type.micro-boss", // "微老板"
	'DRIVER': "user-type.driver", //"司机"
	'HEADMAN': "user-type.headman", //"队长"
	'BOSS': "user-type.boss", // "老板"
	'SUPPLIER': "user-type.supplier", //"供应商"
	'CUSTOMER': "user-type.customer", //"客户"
}


