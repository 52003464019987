import React, { useRef, useEffect, useState } from 'react'
import { message, Modal, Upload, Button } from 'antd'
import YHDrawer from '@/erp_subpackage/components/YhDrawer'
import { YhForm } from '@/erp_subpackage/components/YhFormBox'
import { formatNum } from '@/erp_subpackage/utils/util'
import { useSaleSoDetailModel } from './saleSoDetailModal'
import { DictCodeEnmu } from '@/erp_subpackage/utils/DictCodeEnum'
import { useDictModel } from '@/erp_subpackage/pages/base/dict/dictModel'
import Table from 'antd/lib/table'
import { saleSoService } from '../../saleSoService'
import { ISettleTypeEnum, IPayMethodDs, IPayMethodCreditDs } from '@/erp_subpackage/utils/ErpEnum'
import { EditableRow, EditableCell } from '@/erp_subpackage/components/YHEditTable/editTable'
import useGlobalModel from '@/erp_subpackage/model/globalModel'
import { IVRMSettleTypeEnum } from '@/erp_subpackage/utils/enum'
import { getCustomerListNew } from '@/service/customerService'
import FontIcon from '@/erp_subpackage/components/YhIcon'
import { CloseCircleOutlined, CloudUploadOutlined } from '@ant-design/icons'
import { getToken } from '@/utils/dataStorage'
import { getBaseImgUploadUrl, getBaseUploadData, getBaseImgLookUrl } from '@/config/app'
import { YhFmsAddAccount } from '@/erp_subpackage/components/YhFmsAddAccount'
import { YhFmsAddAdjust } from '@/erp_subpackage/components/YhFmsAddAdjust'
import { YhFmsAddRecharge } from '@/erp_subpackage/components/YhFmsAddRecharge'
import { commonService } from '@/erp_subpackage/pages/common/commonService'
import { bigNumber } from '../../../../../utils/util'
import { IsFranchisees } from '@/utils/common'

//结算
export const SettleDrawer = ({ modeKey, mode, rowId, dispatch }) => {
    const {
        settleVisible: visible,
        detailAddForm,
        detailEditForm,
        detailLookForm,
        lookSettleDetails,
        setSettleVisible,
        onSettle,
        getDetailEditOne,
    } = useSaleSoDetailModel()
    const { getSelectedDictOne, getTreeNodeName, getTreeNodeOne } = useDictModel()
    const [addAccountVisible, setAddAccountVisible] = useState(false)
    const [addAdjustVisible, setAddAdjustVisible] = useState(false)
    const [addAdjustData, setAddAdjustData] = useState(null)
    const [addRechargeVisible, setAddRechargeVisible] = useState(false)
    const [addRechargeData, setAddRechargeData] = useState(null)
    const [queryAccountCustomer, setQueryAccountCustomer] = useState(null)

    const {
        user: { userName, shopCode, shopName, shopId },
    } = useGlobalModel()
    const formRef = useRef()
    const typeForm = {
        add: detailAddForm,
        edit: detailEditForm,
        look: detailLookForm,
    }
    const [cash, setCash] = useState(false)
    const formVal = mode === 'add' ? detailAddForm : detailEditForm
    const [settleType, setSettleType] = useState(ISettleTypeEnum.SETTLE_CASH) //结算类型，  默认现金结账
    const [payMethodDs, setPayMethodDs] = useState(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE)) // 支付方式
    const [accountTypeDs, setAccountTypeDs] = useState([]) //实时存取收款账户类型值
    const [customerCode, setCustomerCode] = useState('')
    const [payTypeList, setPayTypeList] = useState([])
    useEffect(() => {
        if (shopCode == 'SHOP-M-00000016' || shopCode == 'SHOP-M-00000017' || shopCode == 'SHOP-M-00000018') {
            setPayTypeList(getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE))
        } else {
            setPayTypeList(
                getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE).map((item) => {
                    return { ...item, disabled: item.value == 'settle-type.credit' ? true : false }
                })
            )
            // return{...item}}))
        }
    }, [])
    const initDataSource = {
        key: new Date().getTime().toString(),
        payMethodCode: '',
        payMethodName: '',
        available: 0,
        amount: 0,
        payee: '',
        note: '',
    }
    const [payDataSource, setPayDataSource] = useState([]) //表格默认值
    useEffect(() => {
        if (visible) {
            formRef.current.setFieldsValue({ ...typeForm[mode], note: '' })
            //(mode === "edit" ? [{ ...initDataSource, amount: detailEditForm.receivedAmount, }] : lookSettleDetails)
            setPayDataSource(
                mode === 'edit'
                    ? [
                          {
                              ...initDataSource,
                              payMethodCode: typeForm[mode].paymentMethodCode,
                              amount: detailEditForm.receivedAmount,
                              payMethodName: typeForm[mode].paymentMethodName,
                          },
                      ]
                    : lookSettleDetails
            )
            setSettleType(typeForm[mode].payType)
            setPayMethodOptions(typeForm[mode].payType)
            if (typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
                setPayMethodDs(IPayMethodDs)
            } else if (typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
                setPayMethodDs(IPayMethodCreditDs)
            }

            let { settlementPartyCode } = typeForm[mode]
            if (settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL) {
                //这里为部门内部结算时，查询销售实体为客户的接口换取id
                const searchData = {
                    pageSize: 15,
                    pageNum: 1,
                    orders: [
                        {
                            column: 'createTime',
                            rule: 'DESC',
                        },
                    ],
                    shopId: shopId,
                    searchItem: { customerNameEq: shopName },
                    customerStatus: 0,
                }
                getCustomerListNew(searchData).then((res) => {
                    // let { records } = res.retData
                    const {
                        retData: { list: records },
                    } = res
                    if (records) {
                        let { customerCode: code } = records[0]
                        setCustomerCode(code)
                        queryAccount(
                            code,
                            typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH
                                ? 0
                                : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE
                                ? 2
                                : 1,
                            typeForm[mode].payType,
                            shopName
                        )
                    } else {
                        message.warning(`该结算方为部门内部费用时，无维护对应为${shopName}客户`)
                    }
                })
            } else if (
                !(
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_SELF_PAY ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_DABAOMAINTENANCE
                )
            ) {
                let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode)[0]
                if (customerCode) {
                    queryAccount(
                        customerCode,
                        typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1,
                        typeForm[mode].payType,
                        name
                    )
                } else {
                    message.warning(`该结算方数据字典无维护对应的客户编码`)
                }
            } else if (mode !== 'look') {
                queryAccount(
                    typeForm[mode].customerCode,
                    typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : typeForm[mode].payType === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1,
                    typeForm[mode].payType,
                    typeForm[mode].customerName
                )
            }
            // queryAccount(
            //     typeForm[mode].customerCode,
            //     typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
            //     typeForm[mode].payType,
            //     typeForm[mode].customerName
            // )
        }
    }, [visible])

    const setPayMethodOptions = (payType) => {}

    // 查询客户账户类型及可用额度 后期补
    const queryAccount = async (partnerCode, creditFlag, payType, customerName) => {
        //加盟商不需要查询账户
        if (IsFranchisees()) {
            return
        }
        setQueryAccountCustomer({ code: partnerCode, name: customerName })
        let params = {
            partnerCode,
            beCode: typeForm[mode].bentityCode,
            status: 0,
            creditFlag, //为记账类型的  1挂账 2现结
            sourceApp: 'ci',
            shopCode,
        }
        const { retData } = await saleSoService.accountFind(params)
        //根据优惠类型， 过滤授信账户的 （返利|授信）
        let filterResult = retData.filter((item) => {
            return item
        })
        //现金账户 或者 挂账账户。
        let newAccTypeDs = [] // filterResult.map(item => ({ label: item.name ?? "", value: item.code ?? "", available: item.availableAmount ?? 0 }));
        console.log(payType)
        console.log(payType)
        console.log(payType)
        if (payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
            newAccTypeDs = filterResult.map((item) => ({
                ...item,
                label: item.name ?? '',
                value: item.code ?? '',
                available: item.availableAmount ?? 0,
                accountTypeCode: item.accountTypeCode,
                key: item.key ?? '',
            }))
        } else if (payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
            //挂账  account-type.credit
            newAccTypeDs = filterResult
                .filter((item) => {
                    if (item.accountTypeCode == 'account-type.credit')
                        return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                })
                .map((item1) => ({
                    ...item1,
                    label: item1.name ?? '',
                    value: item1.code ?? '',
                    available: item1.availableAmount ?? 0,
                    accountTypeCode: item1.accountTypeCode,
                    key: item1.key ?? '',
                }))
        } else if (payType === DictCodeEnmu.SETTLE_TYPE_BALANCE) {
            //月结  account-type.credit-month
            newAccTypeDs = filterResult
                .filter((item) => {
                    if (item.accountTypeCode == 'account-type.credit-month')
                        return { label: item.name ?? '', value: item.code ?? '', available: item.availableAmount ?? 0 }
                })
                .map((item1) => ({
                    ...item1,
                    label: item1.name ?? '',
                    value: item1.code ?? '',
                    available: item1.availableAmount ?? 0,
                    accountTypeCode: item1.accountTypeCode,
                    key: item1.key ?? '',
                }))
        }
        setAccountTypeDs(newAccTypeDs)

        //选择挂账， 自动带出挂账的账户及授信额度
        if (mode === 'edit') {
            if (newAccTypeDs.length > 0) {
                setCash(newAccTypeDs[0].accountTypeCode === 'account-type.cash')
                setPayDataSource(() => {
                    let available = +(newAccTypeDs[0]?.available ?? 0) //可用额度
                    let payAmount = +typeForm[mode].receivedAmount //需要支付的额度
                    // if (payAmount > available) { message.warning("该账户可用额度不足") };
                    let obj = [
                        {
                            ...initDataSource,
                            available: available,
                            // accountName: newAccTypeDs[0].label,
                            // accountCode: newAccTypeDs[0].value,
                            payMethodName: newAccTypeDs[0].label,
                            payMethodCode: newAccTypeDs[0].value,
                            payMethodValue: newAccTypeDs[0].accountTypeCode,
                            payment: typeForm[mode].paymentMethodName,
                            amount: payAmount, //+`${payAmount <= available ? payAmount : 0}`,
                        },
                    ]
                    return obj
                })
            } else {
                setCash(false)
                 {/* 开户额度修改 */}
                 message.warning(`该${customerName}客户没有对应的账户`)
                // if (payType === 'settle-type.balance') {
                //     message.warning(`该${customerName}客户没有对应的账户`)
                // } else {
                //     Modal.confirm({
                //         title: '提示',
                //         content: '该类型没有账户是否新增？',
                //         okText: '确定',
                //         cancelText: '取消',
                //         onOk: () => {
                //             setAddAccountVisible(true)
                //         },
                //         onCancel: () => {
                //             setSettleVisible(false)
                //         },
                //     })
                // }
            }
        }
    }

    // //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    // const settleTypeChange = (value, option) => {
    //     setPayDataSource([{ ...initDataSource, amount: detailEditForm.receivedAmount, }]);
    //     if (value === DictCodeEnmu.SETTLE_TYPE_CASH) {
    //         setPayMethodDs(IPayMethodDs)
    //     } else if (value === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
    //         setPayMethodDs(IPayMethodCreditDs)
    //     }
    //     setSettleType(value)
    // }

    //每次类型选择重置表格数据  为挂账时，请求到该账户的授信列表
    const settleTypeChange = (value, option) => {
        console.log(value, option)
        let { settlementPartyCode, bentityName } = typeForm[mode]
        //加盟商不需要查询账户
        if (option && !IsFranchisees()) {
            setSettleType(option.value)
            setPayDataSource([initDataSource])
            setPayDataSource([{ ...initDataSource, amount: detailEditForm.receivedAmount }])
            setCash(false)
            if (settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL) {
                //这里为部门内部结算时，查询销售实体为客户的接口换取id
                queryAccount(
                    customerCode,
                    value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
                    value,
                    shopName
                )
            } else if (
                !(
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_INTERNAL ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_SELF_PAY ||
                    settlementPartyCode === DictCodeEnmu.SETTLE_PARTY_DABAOMAINTENANCE
                )
            ) {
                let { value: customerCode, name } = getTreeNodeOne(settlementPartyCode)[0]
                if (customerCode) {
                    // queryAccount(customerCode, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, typeForm[mode].payType, name);
                    queryAccount(
                        customerCode,
                        value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
                        value,
                        name
                    )
                } else {
                    message.warning(`该结算方数据字典无维护对应的客户编码`)
                }
            } else {
                // queryAccount(typeForm[mode].customerCode, value === IVRMSettleTypeEnum.SETTLE_CASH ? 0 : value === IVRMSettleTypeEnum.SETTLE_BALANCE ? 2 : 1, value, typeForm[mode].customerName);
                queryAccount(
                    typeForm[mode].customerCode,
                    value === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === 'settle-type.balance' ? 2 : 1,
                    value,
                    typeForm[mode].customerName
                )
            }
            // queryAccount(typeForm[mode].customerCode, typeForm[mode].payType === DictCodeEnmu.SETTLE_TYPE_CASH ? 0 : typeForm[mode].payType === "settle-type.balance" ? 2 : 1, typeForm[mode].payType, typeForm[mode].customerName);
            setPayMethodOptions(value)
        }
    }
    //监听批量录入字段
    const onChange = (changedValues) => {}

    const onCancel = () => {
        setSettleVisible(false)
    }

    const onAddAccountSucess = () => {
        const val = formRef.current.getFieldsValue().payType
        let option
        for (let i = 0; i < payTypeList.length; i++) {
            if (payTypeList[i].value === val) {
                option = payTypeList[i]
            }
        }
        settleTypeChange(val, option)
    }

    const addAdjustSucess = () => {
        setSettleVisible(false)
    }

    const addRechargeSucess = () => {
        setSettleVisible(false)
    }

    const [okLoading, setOkLoading] = useState(false)
    //加盟商结算
    const franchiseesSettle = () => {
        formRef.current
            .validateFields()
            .then(async (res) => {
                let data = {
                    id: typeForm[mode].id,
                    note: res.note,
                    reckoner: userName,
                    payType: res.payType,
                    payableAmount: res.receivedAmount,
                }

                try {
                    setOkLoading(true)
                    let retData = await onSettle(data)
                    message.success('结算成功')
                    onCancel()
                    getDetailEditOne(typeForm[mode].saleCode, 'edit')
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                } catch (error) {
                    onCancel()
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                }
            })
            .catch((err) => {})
    }

   
    //表单提交
    const onSubmit = async () => {
        if (IsFranchisees()) {
            //加盟商结算
            return franchiseesSettle()
        }

        let filterCashCode = payDataSource.filter((item) => item.cashCode)
        if (filterCashCode.length !== payDataSource.length&&cash) {
            message.warning('请完善现金类支付方式！')
            return
        }
        let filterPayData = payDataSource.filter((item) => item.payMethodCode)
        if (filterPayData.length !== payDataSource.length) {
            message.warning('请完善收款信息')
            return
        }
        let totalAmount = filterPayData.reduce((con, next) => {
            con += +(next.amount ?? 0)
            return con
        }, 0)
        if (totalAmount !== +typeForm[mode].receivedAmount) {
            message.warning('您结算的金额与应收金额有差异')
            return
        }
        formRef.current
            .validateFields()
            .then(async (res) => {
                let newAccountCode = ''
                let accountType = ''
                let accountName = ''

                if (res.payType === DictCodeEnmu.SETTLE_TYPE_CREDIT) {
                    newAccountCode = filterPayData[0]?.['payMethodCode'] ?? ''
                    accountType = filterPayData[0]?.['payMethodValue'] ?? ''
                    accountName = filterPayData[0]?.['payMethodName'] ?? ''
                } else {
                    let { value, accountTypeCode, label } = accountTypeDs.find((item) => filterPayData[0]?.payMethodValue === item.accountTypeCode)
                    newAccountCode = value
                    accountType = accountTypeCode
                    accountName = label
                }
                if (!newAccountCode && res.payType === DictCodeEnmu.SETTLE_TYPE_CASH) {
                    message.warning(`您的现金${filterPayData[0]?.payMethodName}没有开户`)
                    return false
                }
                /**
                 * 判断结算类型类现结，现金账户的可用额度是否满足支付
                 */
                if (
                    res.payType === DictCodeEnmu.SETTLE_TYPE_CASH &&
                    accountType !== DictCodeEnmu.ACC_TYPE_CASH &&
                    payDataSource.reduce((pre, cur) => pre + (cur.available ? cur.available : 0), 0) < totalAmount
                ) {
                    message.warning('您的现结账户可用额度不够')
                    //额度不够可以去充值
                    let rechargeData = accountTypeDs.find((i) => i.value === payDataSource[0].payMethodCode)
                    setAddRechargeData({
                        ...rechargeData,
                        rechargeAmount: bigNumber.minus(payDataSource[0].amount, payDataSource[0].available),
                        partnerCode: queryAccountCustomer.code,
                        partnerName: queryAccountCustomer.name,
                    })
                     // 开户额度修改 
                    return
                    const { code, partnerCode, shopCode } = rechargeData
                    let data1 = {
                        accountCode: code,
                        statusList: 0,
                        partnerCode,
                        shopCode,
                        page: {
                            pageSize: 10,
                            pageNum: 1,
                        },
                        sourceApp: 'fms',
                    }
                    let fmsrechargeList = await commonService.fmsRechargeList(data1)
                    let waringmsg = '您的现结账户可用额度不够, 是否调额?'
                    if (fmsrechargeList && fmsrechargeList.retData && fmsrechargeList.retData.records && fmsrechargeList.retData.records.length > 0) {
                        //有调额申请记录未审核
                        waringmsg = '您的现结账户可用额度不够, 有充值申请未审核，是否再次调额?'
                    }
                    Modal.confirm({
                        title: '提示',
                        content: waringmsg,
                        okText: '确定',
                        cancelText: '取消',
                        onOk: () => {
                            setAddRechargeVisible(true)
                        },
                        onCancel: () => {
                            // setSettleVisible(false)
                        },
                    })

                    return
                }
                if (
                    res.payType !== DictCodeEnmu.SETTLE_TYPE_CASH &&
                    payDataSource.reduce((pre, cur) => pre + (cur.available ? cur.available : 0), 0) < totalAmount
                ) {
                    message.warning('您的账户可用额度不够')
                    //额度不够可以去提额
                    let adjustData = accountTypeDs.find((i) => i.value === payDataSource[0].payMethodCode)
                    setAddAdjustData({
                        ...adjustData,
                        adjustAmount: bigNumber.minus(payDataSource[0].amount, payDataSource[0].available),
                        partnerCode: queryAccountCustomer.code,
                        partnerName: queryAccountCustomer.name,
                    })
                     // 开户额度修改 
                    return
                    const { code, partnerCode, shopCode } = adjustData
                    let data1 = {
                        accountCode: code,
                        status: 1,
                        statusList: 1,
                        partnerCode,
                        shopCode,
                        page: {
                            pageSize: 10,
                            pageNum: 1,
                        },
                        sourceApp: 'fms',
                    }
                    let fmsAdjustList = await commonService.fmsAdjustList(data1)
                    let waringmsg = '您的账户可用额度不够, 是否调额?'
                    if (fmsAdjustList && fmsAdjustList.retData && fmsAdjustList.retData.records && fmsAdjustList.retData.records.length > 0) {
                        //有调额申请记录未审核
                        waringmsg = '您的账户可用额度不够, 有调额申请未审核，是否再次调额?'
                    }
                    Modal.confirm({
                        title: '提示',
                        content: waringmsg,
                        okText: '确定',
                        cancelText: '取消',
                        onOk: () => {
                            setAddAdjustVisible(true)
                        },
                        onCancel: () => {
                            // setSettleVisible(false)
                        },
                    })
                    return
                }

                let data = {
                    id: typeForm[mode].id,
                    saleCode: res.saleCode,
                    note: res.note,
                    reckoner: userName,
                    payType: res.payType,
                    payableAmount: res.receivedAmount,
                    accountCode: newAccountCode,
                    accountType,
                    accountName,
                    details:
                        filterPayData[0].payMethodValue !== 'account-type.cash' ||
                        settleType == DictCodeEnmu.SETTLE_TYPE_CREDIT ||
                        settleType == DictCodeEnmu.SETTLE_TYPE_BALANCE
                            ? []
                            : filterPayData.map((item) => {
                                  let { key, available, payMethodCode, payMethodValue, payMethodName, cashCode, cashName, ...params } = item
                                  cashName = getTreeNodeName(cashCode)
                                  return {
                                      ...params,
                                      payMethodCode: cashCode ?? payMethodValue,
                                      payMethodName: cashName ?? payMethodName,
                                      accountName,
                                      accountCode: newAccountCode,
                                  }
                              }),
                }

                try {
                    setOkLoading(true)
                    let retData = await onSettle(data)
                    message.success('结算成功')
                    onCancel()
                    getDetailEditOne(typeForm[mode].saleCode, 'edit')
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                } catch (error) {
                    console.log(error)
                    console.log(999)
                    onCancel()
                    setTimeout(() => {
                        setOkLoading(false)
                    }, 1000);
                }
            })
            .catch((err) => {})
    }

    //手动调额
    const onAddAdjust = () => {
        if (settleType !== DictCodeEnmu.SETTLE_TYPE_CASH) {
            let adjustData = accountTypeDs.find((i) => i.value === payDataSource[0].payMethodCode)
            setAddAdjustData({
                ...adjustData,
                adjustAmount: bigNumber.minus(payDataSource[0].amount, payDataSource[0].available),
                partnerCode: queryAccountCustomer.code,
                partnerName: queryAccountCustomer.name,
            })
            setAddAdjustVisible(true)
        } else {
            message.error('现结账户不能调额')
        }
    }

    const payMethodChange = (value, option) => {
        if (option.accountTypeCode === DictCodeEnmu.ACC_TYPE_CASH) {
            setCash(true)
        } else {
            setCash(false)
        }
        option &&
            formRef.current.setFieldsValue({
                accountName: option.label,
                accountCode: value,
                payMethodName: option.label,
                payMethodValue: option.accountTypeCode,
                available: formatNum(option?.available),
                cashName: undefined,
                cashCode: undefined,
            })
    }

    const cashSelectChange = (value, option) => {
        option && formRef.current.setFieldsValue({ cashName: option.label, cashCode: option.value })
    }

    const onFinish = (val) => {}

    const onCashAttachChange = (info, record) => {
        console.log(info)
        if (info.file.status === 'done' && info.file?.response?.retCode === 0) {
            let dataSource = [...payDataSource]
            const index = dataSource.findIndex((item) => record.key === item.key)
            const item = dataSource[index]
            dataSource.splice(index, 1, { ...item, ...{ ...record, cashAttach: info?.file?.response?.retData, cashAttachFileName: info.file.name } })
            setPayDataSource(dataSource)
        } else if (info.file.status === 'error' && info.file.response && info?.file?.response?.retData) {
            message.error('导入异常，请尝试重新导入')
        } else if (info.file.status === 'error') {
            message.error('导入错误')
        }
    }

    const onCashAttachDelete = (record) => {
        let dataSource = [...payDataSource]
        const index = dataSource.findIndex((item) => record.key === item.key)
        const item = dataSource[index]
        dataSource.splice(index, 1, { ...item, ...{ ...record, cashAttach: '', cashAttachFileName: '' } })
        setPayDataSource(dataSource)
    }

    const onPreview = (record) => {
        const cashAttach = record.cashAttach
        const cashAttachFileName = record.cashAttachFileName
        const type = cashAttach.split('.')[1]
        if (['jpg', 'png', 'jpeg', 'gif'].includes(type)) {
            Modal.info({
                title: '图片查看',
                // eslint-disable-next-line react/jsx-no-undef
                content: <img style={{ width: '100%' }} src={getBaseImgLookUrl(cashAttach)} />,
                okText: '确定',
                cancelText: '取消',
                centered: true,
                width: 800,
            })
        } else {
            // window.open(getBaseImgLookUrl(uid))
            window.open(getBaseImgLookUrl(cashAttach))
        }
    }

    const formConfig = {
        formRef,
        onFinish,
        labelCol: { span: 6 },
        disabled: mode === 'look',
        onChange,
        items: [
            {
                type: 'Input',
                fieldLabel: '销售单号',
                fieldName: 'saleCode',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '制单日期',
                fieldName: 'createTime',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '客户编码',
                fieldName: 'customerCode',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '客户名称',
                fieldName: 'customerName',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '销售实体',
                fieldName: 'bentityName',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '所属门店',
                fieldName: 'shopName',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '应收金额',
                fieldName: 'receivedAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '已收金额',
                fieldName: 'receivableAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '优惠金额',
                fieldName: 'discountTotalAmount',
                span: 12,
                disable: true,
            },
            {
                type: 'Input',
                fieldLabel: '结算日期',
                fieldName: 'settleTime',
                span: 12,
                disable: true,
            },
            {
                type: 'Select',
                fieldLabel: '结算类型',
                fieldName: 'payType',
                onSelectChange: settleTypeChange,
                rules: [{ required: true, message: '该项为必选项' }],
                // options: getSelectedDictOne(DictCodeEnmu.SETTLE_TYPE),
                options: payTypeList,
                span: 12,
            },
            {
                type: 'Input',
                fieldLabel: '结算备注',
                fieldName: 'fmsNote',
                span: 12,
            },
        ],
    }
    const columns = [
        {
            title: '支付方式',
            dataIndex: 'payMethodCode',
            editable: true,
            editType: 'select',
            options: accountTypeDs,
            onSelectChange: payMethodChange,
            width: 260,
            render: (text) => accountTypeDs.find((item) => item.value === text)?.label,
        },
        cash
            ? {
                  title: '现金类支付方式',
                  dataIndex: 'cashCode',
                  editable: true,
                  editType: 'select',
                  options: getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS),
                  onSelectChange: cashSelectChange,
                  width: 120,
                  render: (text) => getTreeNodeName(text),
              }
            : {
                  title: '可用额度',
                  dataIndex: 'available',
                  width: 100,
                  render: (text) => <span className="statu-red">{`￥${text}`}</span>,
              },
        ...((str) => {
            if (str) {
                return [
                    {
                        title: '附件',
                        dataIndex: 'cashAttach',
                        width: 120,
                        render: (text, record) => {
                            if (text) {
                                return (
                                    <div className="flex justify-around" style={{ position: 'relative', width: '32px', height: ' 32px' }}>
                                        <FontIcon
                                            onClick={() => {
                                                onPreview(record)
                                            }}
                                            style={{ fontSize: '32px' }}
                                            type="icon-tupian"
                                        />
                                        <CloseCircleOutlined
                                            onClick={() => {
                                                onCashAttachDelete(record)
                                            }}
                                            style={{ fontSize: '12px', color: 'red', position: 'absolute', top: '-6px', right: '-6px' }}
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <Upload
                                        action={getBaseImgUploadUrl()}
                                        data={getBaseUploadData()}
                                        headers={{ authorization: getToken() }}
                                        showUploadList={false}
                                        beforeUpload={(file ) => {
                                            const isLt20M = file.size / 1024 / 1024 < 20
                                            if (!isLt20M) {
                                                message.error('上传到文件不能超过20M')
                                                file.status='done'
                                            }
                                            return isLt20M
                                        }}
                                        onChange={(info) => {
                                            onCashAttachChange(info, record)
                                        }}
                                    >
                                        <CloudUploadOutlined style={{ fontSize: '24px', cursor: 'pointer', color: '#6C72F3' }} />
                                    </Upload>
                                )
                            }
                        },
                    },
                ]
            } else {
                return []
            }
        })(cash),
        {
            title: '结算金额',
            width: 120,
            dataIndex: 'amount',
            editable: mode === 'look' ? false : true,
            render: (text) => `￥${formatNum(text)}`,
        },
        {
            title: '收款人',
            editable: mode === 'look' ? false : true,
            dataIndex: 'payee',
            editType: 'select',
            options: getSelectedDictOne(DictCodeEnmu.PAYEE),
            width: 150,
            render: (text) => getTreeNodeName(text),
        },
    ]

    const lookColumns = [
        {
            title: '支付方式',
            dataIndex: 'accountName',
            width: 300,
        },
        detailLookForm.payType === DictCodeEnmu.SETTLE_TYPE_CASH
            ? {
                  title: '现金类支付方式',
                  dataIndex: 'payMethodName',
                  width: 120,
              }
            : { width: 0 },
        {
            title: '结算金额',
            width: 120,
            dataIndex: 'amount',
        },
        ...(detailLookForm.payType === IVRMSettleTypeEnum.SETTLE_CASH
            ? [
                  {
                      title: '现金类支付方式',
                      dataIndex: 'payMethodName',
                      width: 120,
                  },
                  {
                      title: '附件',
                      dataIndex: 'cashAttach',
                      width: 120,
                      render: (text, record) =>
                          text && (
                              <FontIcon
                                  onClick={() => {
                                      onPreview(record)
                                  }}
                                  style={{ fontSize: '32px' }}
                                  type="icon-tupian"
                              />
                          ),
                  },
              ]
            : [{}]),
        {
            title: '收款人',
            dataIndex: 'payee',
            align: 'center',
            width: 100,
            render: (text) => getTreeNodeName(text),
        },
    ]
    const newPayColumns = columns.map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index + 1,
                rowIndex,
                record,
                rules: col?.rules,
                options: col?.options,
                editType: col?.editType,
                editable: col?.editable,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave,
            }),
        }
    })

    //内联编辑
    const handleSave = (row) => {
        if (settleType === DictCodeEnmu.SETTLE_TYPE_CREDIT || settleType === DictCodeEnmu.SETTLE_TYPE_CASH) {
            if (row.payMethodCode) {
                let find = accountTypeDs.find((item) => item.value === row.payMethodCode)
                row.payMethodName = find?.label ?? ''
                row.payMethodValue = find?.accountTypeCode ?? ''
                row.payMethodCode = find?.value ?? ''
                row.available = +(find?.available ?? 0)
            }
        }

        let { payMethodCode, amount } = row
        let newRow = {
            ...row,
            //payMethodName:getTreeNodeName(payMethodCode),
            amount: +formatNum(amount),
            payMethodCode,
            //  payMethodName:formVal.orderSourceCode === DictCodeEnmu.SOURCE_ADD ? payMethodDs.find(item => item.value === payMethodCode)?.label: IPayMethodDs.find(item => item.value ===payMethodCode)?.label,
            payMethodName: payMethodCode
                ? settleType === DictCodeEnmu.SETTLE_TYPE_BALANCE || settleType === DictCodeEnmu.SETTLE_TYPE_CREDIT
                    ? IPayMethodCreditDs.find((item) => item.value === payMethodCode)?.label
                    : getSelectedDictOne(DictCodeEnmu.PAY_METHOD_CASHS).find((item) => item.value === payMethodCode)?.label
                : '',
        }

        let dataSource = [...payDataSource]
        const index = dataSource.findIndex((item) => row.key === item.key)
        const item = dataSource[index]

        dataSource.splice(index, 1, { ...item, ...newRow })
        setPayDataSource(dataSource)
    }
    return (
        <YHDrawer
            {...{
                title: '销售单结算',
                visible,
                onClose: onCancel,
                okText: '保存',
                onOk: onSubmit,
                width: 800,
                showFooter: detailEditForm.status === DictCodeEnmu.SALE_SETTLE || mode === 'look' ? false : true,
                okLoading
            }}
        >
            <div style={{ padding: '20px 36px' }}>
                <YhForm {...formConfig} />
            </div>
            {!IsFranchisees() && (
                <>
                    <div className="">
                         {/* 开户额度修改 */}
                        {/* <Button
                            style={{ margin: ' 0 0 20px 20px' }}
                            onClick={() => {
                                setAddAccountVisible(true)
                            }}
                        >
                            开户
                        </Button>
                        <Button style={{ margin: ' 0 0 20px 20px' }} onClick={onAddAdjust}>
                            调额
                        </Button> */}
                    </div>

                    <Table
                        rowClassName="editable-row yh-edit-table-tr"
                        components={{
                            body: {
                                row: EditableRow,
                                cell: EditableCell,
                            },
                        }}
                        columns={mode === 'edit' ? newPayColumns : lookColumns}
                        dataSource={payDataSource}
                        pagination={false}
                        scroll={{ x: 'max-content', y: 400 }}
                    />
                    <div className="table-bottom-total" onClick={() => {}}>
                        合计:
                        <span className="ml-15 statu-red">{`￥${formatNum(
                            payDataSource.reduce((con, next) => {
                                con = +(next.amount ?? 0)
                                return con
                            }, 0)
                        )}`}</span>
                    </div>
                </>
            )}
            {addAccountVisible && (
                <YhFmsAddAccount
                    visible={addAccountVisible}
                    setVisible={setAddAccountVisible}
                    customerList={[queryAccountCustomer]}
                    beCode={typeForm[mode].bentityCode}
                    beName={typeForm[mode].bentityName}
                    formRef={formRef}
                    onSucess={onAddAccountSucess}
                />
            )}

            {addAdjustVisible && (
                <YhFmsAddAdjust
                    visible={addAdjustVisible}
                    setVisible={setAddAdjustVisible}
                    addAdjustData={addAdjustData}
                    onSucess={addAdjustSucess}
                    accountCustomer={queryAccountCustomer}
                />
            )}

            {addRechargeVisible && (
                <YhFmsAddRecharge
                    visible={addRechargeVisible}
                    setVisible={setAddRechargeVisible}
                    addRechargeData={addRechargeData}
                    onSucess={addRechargeSucess}
                    accountCustomer={queryAccountCustomer}
                />
            )}
        </YHDrawer>
    )
}
