import React, { useState, FC, useEffect } from "react";
import { Button, message } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { usePsiModel } from "../../psiModel";
import { DetailsTable } from "./detailsTable";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { IremainDetail, psiService } from "../../psiService";
import { convertRes2Blob } from "@/erp_subpackage/utils/exportFile";

//占用库存数
export const OccupyNumModal = () => {
	const {
		goodsRecordModal: { occupyVisible: visible, row, type, title, field }, onCancel, shopId
	} = usePsiModel();
	const [recordType, setRecordType] = useState("occupy_ssd");
	const [dataSource, setDataSource] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (visible && row) {
			loadData()
		}
	}, [visible])

	const loadData = async (str = type === "all" ? "occupy_ssd" : type) => {
		setLoading(true);
		setRecordType(str)
		try {
			const searchParams = {
				shopId,
				materialCode: row?.materialCode,
				types: str.split(","),
			}
			let { retData } = await psiService.purchaseSaleStorageDeatil(searchParams);
			setDataSource(retData ?? []);
			setLoading(false)
		} catch {
			setLoading(false);
			setDataSource([])
		}
	}

	//导出
	const exportDetail = async () => {
		if (!dataSource.length) {
			message.warning("无数据");
			return;
		}
		// setLoadingInfo({ loadingVisible: true, txt: "快速导出中" });
		try {
			const searchParams = {
				shopId,
				materialCode: row?.materialCode,
				types: recordType.split(","),
			}
			let result = await psiService.purchaseSaleStorageDeatilExport(searchParams);
			// result && setLoadingInfo({ loadingVisible: false, txt: "" });
			convertRes2Blob(result);
		} catch {
			//  setLoadingInfo({ loadingVisible: false, txt: "" })
		}
	}

	const classname = (type) => {
		return 'btn-item tab-item ' + (recordType === type ? 'tab-active' : '')
	}

	return <YHModal
		visible={visible}
		onCancel={onCancel}
		title={<p><span>{title}:{row?.[field]}</span><span className="ml-20">商品编号:{row?.materialCode}</span><span className="ml-20">商品名称:{row?.materialName}</span></p>}
		width={1300}
		bodyHeight={550}
	>
		{type === "all" ? <div style={{ margin: "0 0 10px 10px" }}>
			<Button
				className={classname("occupy_ssd")}
				type="link"
				shape="round"
				onClick={() => loadData("occupy_ssd")}
			>销售占用明细</Button>
			<Button
				className={classname("occupy_prd")}
				type="link"
				shape="round"
				onClick={() => loadData("occupy_prd")}
			>采退占用明细</Button>
			<Button
				className={classname("occupy_vmm")}
				type="link"
				shape="round"
				onClick={() => loadData("occupy_vmm")}
			>维修占用明细</Button>
			<Button
				className={classname("occupy_van")}
				type="link"
				shape="round"
				onClick={() => loadData("occupy_van")}
			>预约占用明细</Button>
		</div > : <></>}

		<DetailsTable recordType={recordType} dataSource={dataSource} loading={loading} exportDetail={exportDetail} {...row} />
	</YHModal>
}