import React from "react";
import { Button, Table, message } from "antd";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import useGlobalModel from "@/erp_subpackage/model/globalModel";
import { useInternalDetailsModel } from "./internalDetailsModel";
import { useDictModel } from "@/erp_subpackage/pages/base/dict/dictModel";

export const TableBox = ({ id, mode }) => {
    const { user: { shopCode, orgShopCode } } = useGlobalModel();
    const { addGoodsList, editFormVal: { status }, editGoodsList, pgCfg, allotLoadData,
        addAllotRowKeys, setAddAllotRowKeys,
        setAddAllotRow,
        editAllotRowKeys, setEditAllotRowKeys,
        setEditAllotRow,
        addFormVal,
        setAllDetailVisible
    } = useInternalDetailsModel();
    const { getTreeNodeName } = useDictModel(() => []);

    const action = <Button
        className="btn-item"
        type="link"
        shape="round"
        onClick={() => {
            if (mode === "add" && !!!addFormVal.adverseUnitCode) {
                return message.error("请选择对账单位")
            }
            setAllDetailVisible(true)
        }}
    >查看对账明细</Button>

    const columns = [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t, r, i) => i + 1
        }, {
            title: '单据类型',
            width: 120,
            dataIndex: 'shopCode',
            fixed: "left",
            render: text => text === orgShopCode ? "调入" : "调出"

        }, {
            title: '调拨单号',
            width: 160,
            dataIndex: 'code',
            fixed: "left",
        }, {
            title: '单据金额',
            width: 100,
            dataIndex: 'aggregateAmount',
        }, {
            title: '交易机构',
            width: 130,
            dataIndex: 'outShopName',
        }, {
            title: '调拨类型',
            width: 100,
            dataIndex: 'transferTypeName',
        }, {
            title: '支付状态',
            width: 100,
            dataIndex: 'payStatus',
        }, {
            title: '支付类型',
            width: 100,
            dataIndex: 'payTypeName',
        }, {
            title: '业务员',
            width: 120,
            dataIndex: 'createUser',
        }, {
            title: '记账日期',
            width: 120,
            dataIndex: 'applyTime',
        }, {
            title: '记账金额',
            width: 100,
            dataIndex: 'applyAmount',
        }, {
            title: '备注',
            width: 120,
            dataIndex: 'note',
        }
    ];

    const setTypeDetailDataRow = async (selectedRows) => {
        if (mode === "add") {
            setAddAllotRow(selectedRows)
        } else if (mode === "edit") {
            setEditAllotRow(selectedRows)
        }
    }
    const setTypeDetailDataRowKeys = async (selectedRowKeys) => {
        if (mode === "add") {
            setAddAllotRowKeys(selectedRowKeys)
        } else if (mode === "edit") {
            setEditAllotRowKeys(selectedRowKeys)
        }
    }
    return (
        <YhBox title="" action={action} style={{ marginBottom: "75px", paddingTop: '15px' }}>
            <Table
                rowKey={record => record.id}
                rowSelection={{
                    selectedRowKeys: mode === "add" ? addAllotRowKeys : editAllotRowKeys, //记录选中
                    onChange: (selectedRowKeys, selectedRows) => {
                        setTypeDetailDataRowKeys(selectedRowKeys)
                        setTypeDetailDataRow(selectedRows)
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        setTypeDetailDataRow(selectedRows)
                    }
                }}
                columns={columns}
                dataSource={mode === "edit" ? editGoodsList : addGoodsList}
                pagination={{
                    ...pgCfg,
                    position: ["bottomCenter"],
                    showTotal: () => `共${pgCfg.total}条`,
                    onChange: (pageNum, pageSize) => {
                        allotLoadData({ pageNum, pageSize })
                    }
                }}
                scroll={{ x: 'max-content' }}
            />
        </YhBox>
    )
}