import {
    post,
    get
} from "../utils/request"
//查询客户信息
export const customerQueryInfo = (data) => {
    return post("/ci-customer/customer/query_info", data)
}

//快速开单
export const quickRepair = (data) => {
    return post("/ci/order/quick_repair", data)
}