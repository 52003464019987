import React, { useContext, useEffect, useState } from "react"
import ListComponent from "@/components/listComponent"
import TopSearch from "@/components/TopSearch"
import { newGetList, editCommonlyUsedSenderRepairUser } from "@/service/customerService"
import { MyContext } from "@/store/myContext"
import { getPcodeDict } from "@/utils/dataStorage"
import { organPage } from "@/service/orgService"
import YhTooltip from "@/components/YhTooltip"
import MoveUser from './moveUser'
import { set } from "lodash"

//用户列表
const UserRecord = () => {
    const { dispatch, state, message, detailsPage, Switch, Option } = useContext(MyContext)
    const [userData, setUserData] = useState(null) //列表
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState({}) //搜索
    const [dictObj, setDictObj] = useState(null)
    //机构列表
    const [org, setOrg] = useState([])

    const [customerData, setCustomerData] = useState({})
    const [moveUserData, setMoveUserData] = useState([])
    const [moveUserVisible, setMoveUserVisible] = useState(false)

    useEffect(() => {
        if (state.activeMenu === "userRecord") {
            loadData(pagination.current, searchValue)
        }
    }, [state])

    //初始下工单用到的字典
    const initDict = () => {
        let obj = {
            userRole: {}, //用户角色
        }
        getPcodeDict("021").children.forEach((item) => {
            obj.userRole[item.dictCode] = item.title
        })
        setDictObj(obj)
    }
    useEffect(() => {
        initDict()
        getOrganPage()
    }, [])

    //获取机构列表
    const getOrganPage = () => {
        organPage({
            searchItem: { orderFlag: 1 },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            setOrg(res.retData.list)
        })
    }
    //列表加载
    const loadData = (current, search = {}) => {
        setLoading(true)
        let data = {
            searchItem: search,
            pageNum: current,
            pageSize: 10,
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
        }
        console.log(data)
        newGetList(data)
            .then((res) => {
                // if (res && res.retCode === 200) {
                setpagination({
                    current: current,
                    total: res.retData.total,
                    pageSize: 10,
                })
                setSelectInfo({ rowInfo: {}, rowIndex: -1 })
                setUserData(res.retData.list)
                setLoading(false)
                // }
            })
            .catch((err) => {
                setUserData([])
                setLoading(false)
            })
    }

    //分页事件
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //新增
    const handleDetails = async (mode) => {
        let tabName = {
            insert: "用户新增",
            edit: "用户编辑",
            look: "用户查看",
        }
        let modeKey = "userRecord/" + mode
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的用户信息")
                return
            }
            modeKey += "/" + selectInfo.rowInfo.id
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的用户信息")
                return
            }
            //每次查看把之前的删除， 重新生成
            await dispatch({
                type: "removeTabs",
                targetKey: modeKey,
            })
        }
        await dispatch({
            type: "changeTabs",
            name: tabName[mode],
            activeMenu: modeKey,
            content: detailsPage.UserDetails({
                modeKey,
                dispatch,
                mode,
                rowInfo: selectInfo.rowInfo,
            }),
        })
    }

    const commonlyUsedSenderRepairUserChange = (e, record) => {
        editCommonlyUsedSenderRepairUser({userId: record.id,commonlyUsedSenderRepairUser:Number(e)}).then(res => {
            message.success("设置成功")
            loadData(pagination.current, searchValue)
        })
    }

    //table配置
    const columns = [
        {
            title: "客户名称",
            align: "center",
            dataIndex: "customerName",
            width:220,
            render: (text) => <YhTooltip maxWidth={220} text={text}/>,
        },
       
        {
            title: "用户手机",
            align: "center",
            dataIndex: "userMobile",
            render: (text) => <YhTooltip maxWidth={220} text={text}/>,
        },
        {
            title: "用户名",
            align: "center",
            dataIndex: "userName",
            render: (text) => <YhTooltip maxWidth={220} text={text}/>,
        },
        {
            title: "真实姓名",
            align: "center",
            dataIndex: "realName",
            render: (text) => <YhTooltip maxWidth={220} text={text}/>,
        },
        {
            title: "用户密码",
            align: "center",
            dataIndex: "userPassword",
            render: (text) => <>{"******"}</>,
        },
        {
            title: "用户角色",
            align: "center",
            dataIndex: "userRole",
            render: (text) => <YhTooltip maxWidth={220} text={dictObj.userRole[text]}/>,
        },
        {
            title: "默认送修人",
            align: "center",
            width: "10%",
            dataIndex: "commonlyUsedSenderRepairUser",
            render: (text, record) => {
                return <Switch checked={!!text} size="small" onChange={(e) => {commonlyUsedSenderRepairUserChange(e,record)}} />
            },
        },
        {
            title: "登记日期",
            align: "center",
            dataIndex: "createTime",
            // render: text => <>{timeShift(text)}</>
        },
        {
            title: "用户状态",
            align: "center",
            width: "10%",
            dataIndex: "userStatus",
            render: (text, record) => {
                return <Switch checked={text == 0 ? true : false} size="small" disabled />
            },
        },
        {
            title: '操作',
            align: 'center',
            render: (text, r) => (
                <span
                    style={{ color: '#2e3aeb', cursor: 'pointer', marginLeft: '10px' }}
                    onClick={() => {
                        console.log(r)
                        const { customerId, customerName } = r
                        setCustomerData({ id: customerId, name: customerName })
                        setMoveUserData([r])
                        setMoveUserVisible(true)
                    }}
                >
                    用户转移
                </span>
            ),
        },
    ]

    
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入客户名称",
                valueName: "customerName",
            },
            {
                type: "input",
                placeholder: "请输入用户名",
                valueName: "userName",
            },
            {
                type: "input",
                placeholder: "请输入真实姓名",
                valueName: "realName",
            },
            {
                type: "input",
                placeholder: "请输入用户手机号码",
                valueName: "userMobile",
            },
            {
                type: "select",
                placeholder: "请选择所属机构",
                valueName: "organId",
                optionList: () =>
                    org.map((item) => (
                        <Option value={item.id} key={item.id}>
                            {item.organName}
                        </Option>
                    )),
            },
            {
                type: "select",
                placeholder: "请选择用户状态",
                valueName: "userStatus",
                optionList: () => (
                    <>
                        <Option value={0} key={0}>
                            开启
                        </Option>
                        <Option value={1} key={1}>
                            禁用
                        </Option>
                    </>
                ),
            },
            {
                type: "RangePicker",
                valueName: ["startTime", "endTime"],
                span: 16,
                xxl: 8,
            },
            {
                type: "select",
                placeholder: "请选择用户角色",
                valueName: "userRole",
                optionList: () =>
                    getPcodeDict("021").children.map((item) => (
                        <Option key={item.id} value={item.dictCode}>
                            {item.title}
                        </Option>
                    )),
            },
        ],
    }
    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("look"),
            content: "查看",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => setMoveUserVisible(true),
            content: '用户转移',
        },
    ]
    return (
        <>
            {state.activeMenu && (
                <>
                    <TopSearch
                        title="用户管理"
                        justify="start"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="用户列表"
                        buttons={topButtons}
                        loading={loading}
                        columns={columns}
                        dataSource={userData}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        selectInfo={selectInfo}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                 <MoveUser visible={moveUserVisible} setVisible={setMoveUserVisible} customerData={customerData} userData={moveUserData} onSuccess={() => {loadData(1)}} />
                </>
            )}
        </>
    )
}
export default UserRecord
