import { useEffect, useState } from "react";
import { createModel } from "hox";
import { stockEntryService } from "./entryService"
import useGlobalModel, { transPgToPagination } from "@/erp_subpackage/model/globalModel";
import { convertRes2Blob } from "@/erp_subpackage//utils/exportFile";
import { message } from "antd";

const initDesc = { stockAmount: 0, stockNum: 0 }

export const useStockEntryModel = createModel(function () {
    const { user: { shopId, shopCode, shopName, organType, organId } } = useGlobalModel()
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState({ spinning: false, tip: "加载中..." });
    //表格选中行Key值
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    //列表数据源分页参数
    const initPgCfg = { pageSize: 15, current: 1 }
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    //单据汇总
    const [descTotal, setDescTotal] = useState(initDesc);
    //选中的数据统计展示的数据
    const [rowsDescTotal, setRowsDescTotal] = useState(initDesc);

    useEffect(() => {
        let newRowsDescTotal = { ...initDesc };
        selectedRowKeys.forEach((item, index) => {
            newRowsDescTotal.stockNum += item.inTotal
            newRowsDescTotal.stockAmount += item.amount
        })
        setRowsDescTotal(newRowsDescTotal)
    }, [selectedRowKeys])

    //设置搜索value
    const updateSearchParam = (updateParam) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        loadData(initPgCfg, {});
        setSelectedRowKeys([])
    }

    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            organId,
            shopName,
            shopCode,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? page?.current ?? pgCfg.current ?? initPgCfg.current,
        }
        let { retData } = await stockEntryService.page(searchParams);
        const {records,...pg } = retData ||{};
        
        // setDescTotal(totalVo)
        setPgCfg(transPgToPagination({ ...pg, pageNum: pg.current }));
        setDataSource(records ?? []);
        getTotal(page, searchValue)
        setLoading({ spinning: false });
    }
    // 统计右侧
    const getTotal = async (page,searchValue = searchParam)=>{
        const searchParams = {
            ...searchValue,
            shopId: searchValue.shopId ?? shopId,
            organId,
            pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
            pageNum: page?.pageNum ?? page?.current ?? pgCfg.current ?? initPgCfg.current,
        } 
        let {retData} =  await stockEntryService.total(searchParams)
        let {...totalVo} = retData || {}
         setDescTotal(totalVo)
    }

    //导出
    const exportEntry = async (searchValue = searchParam) => {
        // setLoading({ tip: "文件导出中", spinning: true });
        const ids = selectedRowKeys.map(i => i.id)
        const searchParams = {
            searchItem:{
                ...searchValue,
                shopId: searchValue.shopId ?? shopId,
                shopName,
                shopCode,
                ioFlag: 1,
                id: ids.join(),
                organId
            }
        }
        let {retData} = await stockEntryService.export(searchParams);
        message.success(retData)
        // convertRes2Blob(result);
        // setLoading({ spinning: false });
    }

    /*
     * 详情
    */
    //查看详情form
    const [detailLookForm, setDetailLookForm] = useState({})
    //查看详情列表
    const [detailLookList, setDetailLookList] = useState([])
    const getDetailLookOne = async (id, pattern) => {
        let { retData } = await stockEntryService.one(id);
        const { details, ...from } = retData
        setDetailLookForm(from);
        setDetailLookList(details)
    }

    return {
        /***列表***/
        searchParam, organType,
        initPgCfg,
        dataSource,
        selectedRowKeys,
        pgCfg,
        descTotal,
        rowsDescTotal,
        loading,
        setDataSource,
        getTotal,
        setSelectedRowKeys,
        resetSearchParam,
        updateSearchParam,
        loadData,
        setDescTotal,
        exportEntry,
        setRowsDescTotal,
        setLoading,
        /***详情***/
        detailLookForm,
        detailLookList,
        getDetailLookOne
    }
});