import React, { useState, useCallback, useRef, useEffect } from "react"
import { Table, Button, Modal } from "antd"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"
import YhIcon from "@/components/pubIcon"
import styled from "@emotion/styled"
const type = "DraggableBodyRow"

//项目
/**
 * saveKey 保存的key
 * allData 对应tabl所有的columns
 */
export default ({ visible, setVisible, combinationList, onSuccess }) => {
    const [data, setData] = useState([])
    const [deleteId, setDeleteId] = useState([])
    useEffect(() => {
        if (visible) {
            setData(combinationList.map((item, index) => ({ ...item, sort: index + 1 })))
        } else {
            setData([])
        }
    }, [visible])

    const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
        const ref = useRef()
        const [{ isOver, dropClassName }, drop] = useDrop({
            accept: type,
            collect: (monitor) => {
                const { index: dragIndex } = monitor.getItem() || {}
                if (dragIndex === index) {
                    return {}
                }
                return {
                    isOver: monitor.isOver(),
                    dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
                }
            },
            drop: (item) => {
                moveRow(item.index, index)
            },
        })
        const [, drag] = useDrag({
            type,
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        })
        drop(drag(ref))

        return <tr ref={ref} className={`${className}${isOver ? dropClassName : ""}`} style={{ cursor: "move", ...style }} {...restProps} />
    }

    const deleteOne = (row) => {
        //删除已选的
        let newData = [...data]
        let delIndex
        delIndex = newData.findIndex((item) => item.combinationId === row.combinationId)
        newData.splice(delIndex, 1)
        setData(newData)
        setDeleteId([...deleteId, row.combinationId])
    }

    const columns = [
        {
            title: "维保组合名字",
            dataIndex: "combinationName",
        },
        {
            title: "排序",
            dataIndex: "combinationName",
            render: (text, record, index) => index + 1,
        },
        {
            title: "操作",
            align: "center",
            width: "80px",
            dataIndex: "operation",
            render: (_, record) => {
                return <YhIcon type="icon-guanbi" style={{ color: "#D81E06", cursor: "pointer" }} onClick={() => deleteOne(record)} />
            },
        },
    ]

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex]
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            )
        },
        [data]
    )

    const handleSave = () => {
        onSuccess && onSuccess(data, deleteId)
    }

    const onCancel = () => {
        setVisible(false)
    }

    return (
        <Modal
            visible={visible}
            title="编辑维保组合"
            width={1260}
            onCancel={onCancel}
            footer={[
                <Button onClick={onCancel} key="back">
                    {" "}
                    取消
                </Button>,
                <Button type="primary" onClick={handleSave} key="submit">
                    保存
                </Button>,
            ]}
        >
            <DndProvider backend={HTML5Backend}>
                <Contentdiv>
                    <Table
                        rowKey={(record) => record.combinationId}
                        columns={columns}
                        dataSource={data}
                        components={components}
                        onRow={(record, index) => ({
                            index,
                            moveRow,
                        })}
                        pagination={false}
                        scroll={{
                            y: "60vh",
                        }}
                    />
                </Contentdiv>
            </DndProvider>
        </Modal>
    )
}

const Contentdiv = styled.div`
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
        padding: 5px;
    }
`
