import React, { useContext, useState, useEffect } from "react"
import TopSearch from "@/components/TopSearch"
import ListComponent from "@/components/listComponent"
import { getBussinessEntityList, bussinesEntityDelete } from "@/service/dataService"
import { MyContext } from "@/store/myContext"
import Detail from "./detail"
import { Modal, Select } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
const { Option } = Select

export default () => {
    const { state, message } = useContext(MyContext)
    const [showVisible, setShowVisible] = useState(false)
    const [showMode, setShowMode] = useState("")
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息

    useEffect(() => {
        if (state.activeMenu === "entity") {
            loadData(pagination.current)
        }
    }, [state.activeMenu])// eslint-disable-line react-hooks/exhaustive-deps

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: "updateTime",
                    rule: "DESC",
                },
            ],
        }
        getBussinessEntityList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }
    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
        {
            click: () => handleDetails("insert"),
            content: "新增",
        },
        {
            click: () => handleDetails("edit"),
            content: "编辑",
        },
        {
            click: () => handleDelete(),
            content: "删除",
        },
    ]

    //删除
    const handleDelete = async () => {
        if (!Object.keys(selectInfo.rowInfo).length) {
            message.warning("请选择你要删除的所属实体信息")
            return
        }
        const modal = Modal.confirm({
            content: `确定要删除所属实体--${selectInfo.rowInfo.entityName}?`,
            okText: "确定",
            cancelText: "取消",
            centered: true,
            onOk() {
                bussinesEntityDelete(selectInfo.rowInfo.id).then((res) => {
                    message.success("删除成功")
                    loadData(pagination.current, searchValue)
                })
            },
        })
    }
    //新增
    const handleDetails = async (mode) => {
        if (mode === "edit") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要编辑的所属实体")
                return
            }
        } else if (mode === "look") {
            if (!Object.keys(selectInfo.rowInfo).length) {
                message.warning("请选择你要查看的所属实体")
                return
            }
        }
        setShowMode(mode)
        setShowVisible(true)
    }


    //table配置
    const columns = [
        {
            title: "实体名称",
            align: "center",
            dataIndex: "entityName",
        },
        {
            title: "统一信用编码",
            align: "center",
            dataIndex: "entityCreditCode",
        },
        // {
        //     title: "法人",
        //     align: "center",
        //     dataIndex: "legalPerson",
        // },
        {
            title: "三包名称",
            align: "center",
            dataIndex: "threeGuaranteesName",
        },
        {
            title: "联系电话",
            align: "center",
            dataIndex: "contactPhone",
        },
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: "input",
                placeholder: "请输入实体名称",
                valueName: "entityName",
            },
            {
                type: "select",
                placeholder: "请选择三包品牌",
                valueName: "threeGuarantees",
                optionList: () =>
                    getPcodeDict("106").children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
        ],
    }

    return (
        <>
            {state.activeMenu === "entity" && (
                <>
                    <TopSearch
                        title="所属实体"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="所属实体"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: "max-content" }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />

                    {showVisible && (
                        <Detail
                            rowInfo={selectInfo.rowInfo}
                            visible={showVisible}
                            mode={showMode}
                            setVisible={setShowVisible}
                            onSuccess={() => {
                                loadData(1, searchValue)
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
