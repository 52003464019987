import React, { useState, useEffect, useRef, useContext } from "react"
import { Form, Input, Select, InputNumber, DatePicker, message, Spin } from "antd"
import { getPcodeDict } from "@/utils/dataStorage"
import moment from "moment"
import { getBussinessEntityList } from "@/service/dataService"
const { Option } = Select
const EditableContext = React.createContext()
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const [editing, setEditing] = useState(false)
    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })
    const inputRef = useRef()
    const form = useContext(EditableContext)
    useEffect(() => {
        if (editing) {
            if (inputRef && inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        if (dataIndex === "repairTime") {
            form.setFieldsValue({
                [dataIndex]: moment(record[dataIndex] || new Date()),
            })
        } else {
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            })
        }
    }

    const save = async (e, option, str) => {
        let defaultCiBusinessEntity = {}
        if (str === "threeGuaranteesBrand") {
            defaultCiBusinessEntity = {
                ciBusinessEntityId: "",
                ciBusinessEntityName: "",
            }
            try {
                let data = {
                    searchItem: {
                        threeGuarantees: e,
                    },
                    pageNum: 1,
                    pageSize: 100000,
                    orders: [
                        {
                            column: "createTime",
                            rule: "DESC",
                        },
                    ],
                }
                const entityList = await getBussinessEntityList(data)
                const { retData } = entityList
                if (retData.total > 0) {
                    defaultCiBusinessEntity = {
                        ciBusinessEntityId: retData.list[0].id,
                        ciBusinessEntityName: retData.list[0].entityName,
                    }
                }
            } catch {
                console.log("接口请求失败")
            }
        } else if (str === "ciBusinessEntityId") {
            defaultCiBusinessEntity = {
                ciBusinessEntityId: option.value,
                ciBusinessEntityName: option.children,
            }
        }
        try {
            const values = await form.validateFields()
            if (typeof values?.repairTime === "object") {
                values.repairTime = moment(values?.repairTime).format("YYYY-MM-DD HH:mm:ss")
            }
            toggleEdit()
            handleSave({ ...record, ...values, ...defaultCiBusinessEntity })
        } catch (errInfo) {
            console.log("数据保存失败", errInfo)
        }
    }

    const fetchEntity = (threeGuarantees) => {
        let data = {
            searchItem: {
                threeGuarantees,
            },
            pageNum: 1,
            pageSize: 100000,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getBussinessEntityList(data).then(({ retData }) => {
            const list = retData.list
            if (list.length > 0) {
                const dataMap = list.map((team) => ({
                    text: team.entityName,
                    value: team.id,
                }))
                setEntityData({
                    data: dataMap,
                    fetching: false,
                })
            } else {
                message.error("该三包暂无所属实体")
            }
        })
    }

    const inputTypes = (str, record) => {
        console.log(record)
        //三包单位
        if (str === "threeGuaranteesBrand") {
            return (
                <Select
                    ref={inputRef}
                    onChange={(e, option) => {
                        save(e, option, str)
                    }}
                    onBlur={save}
                >
                    {getPcodeDict("106").children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    ))}
                </Select>
            )
        } else if (str === "ciBusinessEntityId") {
            return (
                <Select
                    ref={inputRef}
                    onChange={(e, option) => {
                        save(e, option, str)
                    }}
                    placeholder="请选择所属实体队"
                    notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                    optionFilterProp="children"
                    onFocus={() => fetchEntity(record.threeGuaranteesBrand)}
                    style={{ width: "100%" }}
                    filterOption={false}
                >
                    {entityData.data.map((item) => (
                        <Select.Option value={item.value} key={item.value}>
                            {item.text}
                        </Select.Option>
                    ))}
                </Select>
            )
        } else if (str === "repairTime") {
            return <DatePicker showTime={{ format: "HH:mm:ss" }} format="YYYY-MM-DD HH:mm:ss" onChange={save} onOk={save} />
        } else if (str === "mealSupplement" || str === "hourlyWage" || str === "materialAmount" || str === "travelExpenses" || str === "otherAmount") {
            return <InputNumber ref={inputRef} min={0} formatter={(value) => `¥${value}`} parser={(value) => value.replace("¥", "")} onBlur={save} />
        } else {
            return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        }
    }
    const inputRules = (str) => {
        if (str === "hourPrice" || str === "unitPrice" || str === "preferentialAmount") {
            return [
                {
                    required: true,
                    message: `数值不可为空`,
                },
                {
                    pattern: /^[0-9]+(.[0-9]{0,2})?$/,
                    message: "请输入数字且可保留两位小数",
                },
            ]
        }
    }
    let childNode = children
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={inputRules(dataIndex)}
            >
                {inputTypes(dataIndex, record)}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}

export { EditableRow, EditableCell }
