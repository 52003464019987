import React, { useState, useEffect } from "react";
import { Table, Button, Input, message, Spin } from "antd";
import { YHModal } from "@/erp_subpackage/components/YHModal";
import { EditableCell, EditableRow } from "@/erp_subpackage/components/YHEditTable/editTable";
import { YhBox } from "@/erp_subpackage/components/YhBox";
import { useGlobalModel, transPgToPagination, defaultMdCmParam } from "@/erp_subpackage/model/globalModel";
import { YhBut } from "@/erp_subpackage/components/YhButton";
import { formatInt, formatNum } from "@/erp_subpackage/utils/util";
import { usePurchaseDrDetailsModel } from './purchaseDrDetailsModel';
import { purchaseDrService } from "../../purchaseDrService";
import YhTooltip from "@/erp_subpackage/components/YhTooltip"

const initPgCfg = { pageSize: 10, current: 1 };

export const MaterialDialog = ({ modeKey, mode, rowCode, dispatch }) => {
    const { user: { shopId, shopName, shopCode }, setMaterialInfo, orgDs } = useGlobalModel(({ user, whDs }) => [user, whDs]);
    const { materialDialog: visible, setMaterialDialog, insertMaterial, editFormVal, addFormVal, addList,  editList } = usePurchaseDrDetailsModel(({ materialDialog }) => [materialDialog])
    //查询条件
    const [searchParam, setSearchParam] = useState({});
    //列表数据源
    const [dataSource, setDataSource] = useState([]);
    const [selectMaterial, setSelectMaterial] = useState([]);
    //列表数据源分页参数
    const [pgCfg, setPgCfg] = useState(initPgCfg);
    const [loading, setLoading] = useState({ spinning: false, tip: "列表加载中..." });
    const formVal = mode === "edit" ? editFormVal : addFormVal;


    useEffect(() => {
        if (visible) {
            loadData(initPgCfg)
        } else {
            setSelectMaterial([]);
        };
    }, [visible])

    const onCancel = () => {
        setMaterialDialog(false);
    };

    //加载商品数据
    const loadData = async (page, searchValue = searchParam) => {
        setLoading({ spinning: true, tip: "列表加载中..." });
            try {
            let params = {...searchValue, materialCode: searchValue.materialCode?.trim(),  materialName: searchValue.materialName?.trim()}
            setSearchParam(params)
            const searchParams = {
                shopId: formVal.outShopId,
                ...params,
                saleTypeCode: "sale-type.general",
                age: 1,
                availableQuantityGT:0,
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? initPgCfg.pageSize,
                pageNum: (page?.current || page?.pageNum) ?? pgCfg.current ?? 1
            }
            let retData = {}
            const { branchOut } = formVal;
            switch (branchOut) {
                case "scm":
                    const scmResult = await purchaseDrService.scm2TransferDetails(searchParams);
                    retData = scmResult.retData
                    break;
                case "vrm":
                    const vrmResult = await purchaseDrService.vrm2TransferDetails({...searchParams});
                    retData = vrmResult.retData
                    break;
                case "bcm":
                    const bcmResult = await purchaseDrService.bcmTransferDetails(searchParams);
                    retData = bcmResult.retData;
                    break;
                case "ci":
                    const ciResult = await purchaseDrService.ciTransferDetails(searchParams);
                    //这里判断 调入或者调出机构任何一方为加盟性质的  价格取加盟商价格
                    let findOutShop = orgDs.find(item => item.value === formVal.outShopId) || {};
                    let findShop = orgDs.find(item => item.value === formVal.shopId) || {};
                    if (findOutShop.organ_nature === 3 || findShop.organ_nature === 3) {
                        retData.records = ciResult.retData?.records.map(item => ({
                            ...item,
                            price: item.franchiseePrice
                        }))
                    } else {
                        retData = ciResult.retData
                    }
                    break;
                case defaultMdCmParam.sourceApp:
                    // const bcmResult = await purchaseDrService.bcmTransferDetails(searchParams);
                    retData = []
                    break;
                default:
                    onCancel(false)
                    return message.warning("请先选择调出机构")
            }
            const { records, ...pg } = retData;
            setPgCfg(transPgToPagination(pg));
            // setDataSource(records.map((item, index) => ({ ...item, inventoryQuantity: item.inventoryQuantity ?? item.stockNum, pDrNum: 1, purchaseCode: item.purchaseCode ?? item.bussCode })));
            setDataSource(records.map((item, index) => ({ ...item, 
                inventoryQuantity: branchOut !== 'scm' ? item.stockNum ?? item.inventoryQuantity: item.inventoryQuantity - item.occupyNum,
                purchaseCode: item.purchaseCode ?? item.bussCode, 
                price: item.price ?? item.costPrice,
                bussCode:item.bussCode ?? '',
                bussType:item.bussType ?? ''
              })));
            setLoading({ spinning: false });
        } catch (err) {
            console.log(err);
            setLoading({ spinning: false });
        }
    }

     //添加商品  默认数量字段为1
     const add = async (record, mode) => {
        // const isEdit = mode === 'edit'
        let params = {
            details: [
                {
                    shopId,
                    materialCode: record.materialCode,
                },
            ],
        }
        let { retData } = await purchaseDrService.vrm2StockQuery(params)
        let { inWarehouseCode, inWarehouseName, inShelfCode, inShelfId } = retData[0]
        setSelectMaterial((origin) => [...origin, { ...record, pDrNum: 1, inWarehouseCode, inWarehouseName, inShelfCode, inShelfId }])
        // setSelectMaterial((origin) => [...origin, { ...record, pDrNum: 1,  }])
    }

    const column = (optype) => [
        {
            title: '序号',
            width: 40,
            fixed: "left",
            align: "center",
            render: (t, r, i) => <>{i + 1}</>
        }, {
            title: '商品编号',
            width: 160,
            dataIndex: 'materialCode',
            fixed: "left",
            // render: (text) => <p className="ellipsis" title={text}>{text}</p>
            render: (text, record) => <YhTooltip text={text} onClick={() => setMaterialInfo(true, { materialCode: text, materialName: record.materialName })} />,
        }, {
            title: '商品名称',
            width: 180,
            dataIndex: 'materialName',
            fixed: "left",
            render: (text) => <YhTooltip text={text} />,
        }, {
            title: '商品类型',
            width: 180,
            dataIndex: 'typeName',
        }, {
            title: '货位',
            width: 180,
            dataIndex: 'shelfCode',
        }, {
            title: '库存',
            width: 120,
            dataIndex: 'inventoryQuantity',
        }, optype === "add" ? {} : {
            title: '数量',
            width: 100,
            dataIndex: 'pDrNum',
            editable: true,
        }, {
            title: '单位',
            width: 100,
            dataIndex: 'unitName',
        }, {
            title: '采购单号',
            width: 100,
            dataIndex: 'purchaseCode',
        },{
            title: '操作',
            width: 60,
            fixed: "right",
            align: "center",
            className: "td-wrap",
            render: (text, record) => optype === "add" ? (
                <YhBut
                    type="add"
                    txt="添加"
                    disabled={!!selectMaterial.find(item => item.id === record.id) || +record.inventoryQuantity <= 0}
                    click={() => {
                        //添加商品
                        // setSelectMaterial(origin => [...origin, record])
                        add(record)
                    }}
                />
            ) : (
                <YhBut
                    type="delete"
                    txt="删除"
                    click={() => {
                        //删除对应商品
                        setSelectMaterial(origin => origin.filter(item => item.id !== record.id))
                    }}
                />
            )
        }
    ];

    const newSelectColumns = (type) => column(type).map((col, index) => {
        return {
            ...col,
            onCell: (record, rowIndex) => ({
                colIndex: index,
                rowIndex,
                record,
                rules: col.rules,
                options: col.options,
                editType: col.editType,
                editable: col?.editable,
                dataIndex: col.dataIndex,
                onSelectChange: col?.onSelectChange,
                onSelectFocus: col?.onSelectFocus,
                title: col.title,
                handleSave: (row) => handleSave(row, type),
            }),
        }
    })

    //内联编辑
    const handleSave = (row, type) => {
        row.pDrNum = +formatInt(row.pDrNum);
        if (row.pDrNum >= +row.inventoryQuantity) {
            message.warning("不可以大于库存数量");
            row.pDrNum = +row.inventoryQuantity
        }
        (type === "add" ? setDataSource : setSelectMaterial)(origin => {
            const newDataSource = [...origin];
            const index = newDataSource.findIndex((item) => row.id === item.id);
            const item = newDataSource[index];
            newDataSource.splice(index, 1, { ...item, ...row });
            return newDataSource;
        });
    }


   

    const rightChildren = <div className="flex">
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.materialCode}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, materialCode: e.target.value }) }}
            placeholder="商品编号"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Input
            allowClear
            size="small"
            className="border-r-16 mr-10"
            value={searchParam.materialName}
            style={{ width: "150px" }}
            onChange={(e) => { setSearchParam({ ...searchParam, materialName: e.target.value }) }}
            placeholder="商品名称"
            onPressEnter={() => loadData(initPgCfg)}
        />
        <Button onClick={() => loadData()} style={{ margin: "0px 5px 0px 10px" }} shape="round">检索</Button>
        <Button onClick={() => {
            loadData(initPgCfg, {});
            setSearchParam({})
        }} shape="round">重置</Button>
    </div>

    const onOk = async() => {
        if (loading.spinning) return false;
        setLoading({ spinning: true, tip: "商品仓库请求中..." });
        insertMaterial(mode, selectMaterial).then(res => {
            setLoading({ spinning: false });
        })

    }

    return <YHModal
        visible={visible}
        okText="确认添加"
        onOk={onOk}
        onCancel={onCancel}
        title="调入单-选择商品"
        width={1050}
        bodyHeight={730}
        showFooter={true}
    >
        <Spin {...loading}>
            <YhBox title="待选商品列表" rightChildren={rightChildren} style={{ margin: "0" }}>
                <Table
                    rowKey={record => record.id}
                    rowClassName={(record) => {
                        if (record.inventoryQuantity === 0) {
                            return "editable-row yh-edit-table-tr stock-td"
                        } else {
                            return "editable-row yh-edit-table-tr"
                        }
                    }}
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={newSelectColumns("add")}
                    dataSource={dataSource}
                    pagination={{
                        ...pgCfg,
                        position: ["bottomCenter"],
                        onChange: (pageNum, pageSize) => {
                            loadData({ pageNum, pageSize })
                        }
                    }}
                    scroll={{ x: 'max-content', y: 210 }}
                />
            </YhBox>
            <YhBox title="已选商品列表" style={{ margin: "5px 0 0" }}>
                <Table
                    rowKey={record => record.id}
                    rowClassName='editable-row yh-edit-table-tr'
                    components={{
                        body: {
                            row: EditableRow,
                            cell: EditableCell,
                        },
                    }}
                    columns={newSelectColumns("delete")}
                    dataSource={selectMaterial}
                    pagination={false}
                    scroll={{ x: 'max-content', y: 210 }}
                />
            </YhBox>
        </Spin>
    </YHModal>
}