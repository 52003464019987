import React, { useContext, useState, useEffect } from 'react'
import TopSearch from '@/components/TopSearch'
import ListComponent from '@/components/listComponent'
import {
    factoryThreeGuaranteesOrderList
} from '@/service/ordersService'
import { MyContext } from '@/store/myContext'
import { Select, message } from 'antd'
import { getPcodeDict } from '@/utils/dataStorage'
import Detail from './detail'
import { getCustomerListNew } from '@/service/customerService'
const { Option } = Select

const InvoiceStatus = {
    unInvoice: '未开票',
    invoiced: '已开票',
}

const SettlementStatus = {
    unSettlement: '未结算',
    settled: '已结算',
}

export default ({claimNumber=""}) => {
    const { state } = useContext(MyContext)
    const [list, setList] = useState([]) //列表
    const [searchValue, setSearchValue] = useState({})
    const [pagination, setpagination] = useState({
        //分页配置
        total: 0,
        current: 1,
        pageSize: 10,
    })
    const [selectInfo, setSelectInfo] = useState({ rowInfo: {}, rowIndex: -1 }) //记录当前行下标及信息
    const [dictObj, setDictObj] = useState(null)
    const [detailVisible, setDetailVisible] = useState(false)
    const [orderThreeGuaranteesId, setOrderThreeGuaranteesId] = useState("")

    useEffect(() => {
        if (state.activeMenu === 'sanbaoBill') {
            initDict()
            if(claimNumber){
                setSearchValue({...searchValue, claimNumber})
            }
            loadData(pagination.current, {...searchValue, claimNumber})
        }
    }, [state.activeMenu]) // eslint-disable-line react-hooks/exhaustive-deps
    const initDict = () => {
        let obj = {
            threeGuaranteesBrand: {}, //三包
            orderStatus:{}
        }
        getPcodeDict('106').children.forEach((item) => {
            obj.threeGuaranteesBrand[item.dictCode] = item.title
        })
        getPcodeDict('order_status').children.forEach((item) => {
            obj.orderStatus[item.dictCode] = item.title
        })

        setDictObj(obj)
    }

    //列表数据
    const loadData = (current, search = {}) => {
        let data = {
            searchItem: { ...search },
            pageNum: current,
            pageSize: 10,
            orders: [
                {
                    column: 'updateTime',
                    rule: 'DESC',
                },
            ],
            startTime: search.startTime ? search.startTime : null,
            endTime: search.endTime ? search.endTime : null,
        }
        factoryThreeGuaranteesOrderList(data).then((res) => {
            setpagination({
                current: current,
                total: res.retData.total,
                pageSize: 10,
            })
            setSelectInfo({ rowInfo: {}, rowIndex: -1 })
            setList(res.retData.list)
        })
    }

    //分页事件 置空当前行信息
    const onTableChange = async (page) => {
        await setpagination({
            ...pagination,
            current: page.current,
        })
        loadData(page.current, searchValue)
    }

    //列表button
    const topButtons = [
       
    ]

    const handleClickThreeGuaranteesBrand = (record) => {
        setOrderThreeGuaranteesId(record.id)
        setDetailVisible(true)
    }
    //table配置
    const columns = [
        {
            title: '工单编号',
            width: 120,
            align: 'center',
            dataIndex: 'orderCode',
        },
        {
            title: '客户名称',
            width: 200,
            align: 'center',
            dataIndex: 'customerName',
        },
        {
            title: '车辆牌照',
            width: 80,
            align: 'center',
            dataIndex: 'vehicleCode',
        },
        {
            title: '工单状态',
            width: 80,
            align: 'center',
            dataIndex: 'orderStatus',
            render: (text) => <>{dictObj.orderStatus[text]}</>,
        },
        {
            title: '三包信息',
            width: 200,
            align: 'center',
            dataIndex: 'threeGuaranteesBrand',
            render: (text, record) => <span style={{color:'blue'}} onClick={() => handleClickThreeGuaranteesBrand(record)}>{dictObj.threeGuaranteesBrand[text]}({record.claimNumber})</span>,
        },
        {
            title: '结算类型',
            width: 80,
            align: 'center',
            dataIndex: 'settlementStatus',
            render: (text) => <>{SettlementStatus[text]}</>,
        },
        {
            title: '发票状态',
            width: 80,
            align: 'center',
            dataIndex: 'invoiceStatus',
            render: (text) => <>{InvoiceStatus[text]}</>,
        },
       
    ]
    //搜索JSON
    const searchList = {
        normalList: [
            {
                type: 'input',
                placeholder: '请输入索赔单号',
                valueName: 'claimNumber',
            },
            {
                type: 'select',
                placeholder: '结算类型',
                valueName: 'settlementStatus',
                optionList: () =>
                    Object.entries(SettlementStatus).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
            {
                type: 'searchSelect',
                placeholder: '请选择客户名称',
                valueName: 'customerId',
                optionName: 'customerName',
                optionId: 'id',
                searchApi: getCustomerListNew,
                searchItemName: 'customerName'
            },
            {
                type: 'input',
                placeholder: '请输入工单编号',
                valueName: 'orderCode',
            },

            {
                type: 'select',
                placeholder: '请选择三包品牌',
                valueName: 'threeGuaranteesBrand',
                optionList: () =>
                    getPcodeDict('106').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
            {
                type: 'select',
                placeholder: '请选择工单类型',
                valueName: 'orderType',
                optionList: () =>
                    getPcodeDict('014').children.map((item) => (
                        <Option value={item.dictCode} key={item.id}>
                            {item.title}
                        </Option>
                    )),
            },
        ],
        hideList: [
            {
                type: 'RangePicker',
                valueName: ['startTime', 'endTime'],
                placeholder: ['开始时间', '结束时间'],
                span: 16,
                xxl: 8,
            },
            {
                type: 'input',
                placeholder: '请输入车辆牌照',
                valueName: 'customerVehicleCode',
            },
            
            {
                type: 'select',
                placeholder: '开票状态',
                valueName: 'invoiceStatus',
                optionList: () =>
                    Object.entries(InvoiceStatus).map((item) => (
                        <Option value={item[0]} key={item[0]}>
                            {item[1]}
                        </Option>
                    )),
            },
           
        ],
    }

    return (
        <>
            {state.activeMenu === 'sanbaoBill' && (
                <>
                    <TopSearch
                        title="三包账单"
                        searchListData={searchList}
                        searchValue={searchValue}
                        setSearchValue={(obj) => setSearchValue(obj)}
                        handleSearch={loadData}
                    />
                    <ListComponent
                        title="三包账单"
                        buttons={topButtons}
                        selectInfo={selectInfo}
                        columns={columns}
                        dataSource={list}
                        pagination={pagination}
                        onTableChange={onTableChange}
                        scroll={{ x: 'max-content' }}
                        setSelectInfo={(obj) => setSelectInfo(obj)}
                    />
                    <Detail visible={detailVisible} setVisible={setDetailVisible} id={orderThreeGuaranteesId} />
                </>
            )}
        </>
    )
}
