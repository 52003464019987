export const DictCodeEnmu = {
    //售价选择
    PRICE_SELECTION: "sale-price-type", 
    // 零售价
    PRICE_SELECTION_RETAIL: 'price-selection.retail',
    // 上架状态
    DISPLAY_STATUS: "display-status",
    // 已上架
    DISPLAY_STATUS_ALREADY: 'display-status.already',
    // 待上架
    DISPLAY_STATUS_WAITING: 'display-status.waiting',
    //收款人
    PAYEE: "payee",
    //计量单位
    MATERIAL_UNIT: "unit",
    //所属机构
    ORG: "org",
    //所属分类
    PRODUCT_TYPE: "product-type",
    //发动机型号
    ENGINE_TYPE: "engine-type",
    //车辆品牌
    VEHICLE_BRAND: "vehicle-brand",
    //商品品牌
    MATERIAL_BRAND: "material-brand",
    //运输方式
    LOGISTIC_MODE: "transport-mode",
 
    //单据类型
    TRADE_TYPE: "trade-type",
    // 销售订单
    TRADE_TYPE_SALE_ORDER: 'trade-type.sale-order',
    // 商城订单
    TRADE_TYPE_MALL: 'ci.sale-order-type.mall',
    // 销售单转接
    SALE_TYPE_TRANSFER: 'sales-type.transfer',
    // 销售退货
    //销售单
    TRADE_TYPE_SALE:'trade-type.sale',
    TRADE_TYPE_SALE_RETURN: 'trade-type.sale-return',
    // 销售订单转接
    SALE_ORDER_TYPE_TRANSFER: 'Sales-order-type.transfer',
    // 结算类型
    SETTLE_TYPE: "settle-type",
    //仓库类型
    WAREHOUSE_TYPE: "warehouse-type",
    //账户类型
    ACCOUNT_TYPE: "account-type",
    //发票类型
    INVOICE_TYPE: "invoice-type",
    //车辆类型
    VEHICLE_TYPE: "vehicle-type",
    //商品类型（商品类型）
    MATERIAL_TYPE: "material-type",
    //客户类型
    CUSTOMER_TYPE: "customer-type",
    //供应商类型
    SUPPLIER_TYPE: "supplier-type",

    //支付方式
    PAY_METHOD: "pay-method",
    // 支付宝
    PAY_METHOD_ALIPAY: 'pay-method.cash.alipay',
    // 微信
    PAY_METHOD_WECHAT: 'pay-method.cash.wechat',
    // 现金
    PAY_METHOD_CASH: 'pay-method.cash.cash',
    //纯现金类
    PAY_METHOD_CASHS: 'pay-method.cash',
    // 额度
    PAY_METHOD_LIMIT: 'pay-method.limit',
    //支付状态
    PAY_STATUS: "pay-status",

    //是否直发(直发类型)
    DIRECT_TYPE: "direct-flag",
    // 订单来源
    ORDER_SOURCE: "order-source",
    // 新增
    SOURCE_ADD: 'order-source.add',
    // app
    SOURCE_APP: 'order-source.app',
    // 小程序
    SOURCE_APPLET: 'order-source.applet',
    //采购类型
    PURCHASE_TEPE: "purchase-type",
    //销售订单类型
    SALE_ORDER_TYPE: "sale-order-type",
    //销售类型
    SALE_TYPE: "sale-type",
    // 正常销售
    SALE_TYPE_NORMAL: 'sales-type.normal-sales', 

    //采购平台状态
    ADVANCED: "advanced-status", 
    //采购平台 已开单
    ADVANCED_CREATE: "advanced.create", 
    //采购平台 已提交
    ADVANCED_SUBMIT: "advanced.submit",
    //采购平台 待转接
    ADVANCED_WAIT_TRANSFER: "advanced.wait.transfer",
    //采购平台 已转接
    ADVANCED_TRANSFER: "advanced.transfer",
    //采购平台 待审核
    ADVANCED_WAIT_AUDIT: "advanced.wait.audit",
    //采购平台 待确认
    ADVANCED_WAIT_CONFIRM: "advanced.wait.confirm",

    //采购计划状态
    PURCHASE_PLAN: "purchase-plan",
    //采购计划 已转接
    PPLAN_TRANSFER: "purchase-plan.transfer",
    //采购计划 已开单
    PPLAN_CREATE: "purchase-plan.create",

    //采购单状态
    PURCHASE: "purchase-status", 
    //采购单 已开单
    PO_CREATE: "purchase.create",
    //采购单 已结算
    PO_SETTLE: "purchase-status.bill",
    //采购单 已记账
    PO_BILL: "purchase.bill",
    //已核销
    PO_WRITE_OFF: "purchase.write-off",
    //采购单 已作废
    PO_CHANGE: "purchase.invalid",
    //采购单 已入库
    PO_COMPLETELY_IO: "purchase.completely-io",
    //采购单 已出库
    PO_COMPLETELY_OUT: "purchase.completely-out",

    //采购单退货状态
    PURCHASE_RETURN: "purchase-return-status",
    //采购单退货 已记账
    PRO_BILL: "purchase-return.bill",
    //采购单退货 已作废
    PRO_CHANGE: "purchase-return.invalid",
    //采购单退货 已开单
    PRO_CREATE: "purchase-return.create",
    //采购单退货 已出库
    PRO_STOCK_IO: "purchase-return.stock-io",
    //采购单退货 已结算
    PRO_SETTLE: "purchase-return.settle",
    //采购单退货 已核销
    PRO_WRITE_OFF: "purchase-return.write-off",

    //销售订单状态
    SALE_ORDER: "sale-order-status",
    // 已转接
    SALE_ORDER_TRANSFER: "sale-order.transfer",
    // 已驳回
    SALE_ORDER_REJECT: "sale-order.reject",
    // 已作废
    SALE_ORDER_CHANGE: 'sale-order.invalid',
    // 已开单
    SALE_ORDER_CREATE: 'sale-order.create',
    // 已取消
    SALE_ORDER_CANCEL: 'sale-order.cancel',
    // 已结算
    SALE_ORDER_SETTLE: 'sale-order.settle',
    // 待审核
    SALE_ORDER_PENDING: 'sale-order.pending',

    //销售单状态
    SALE: "sale-status",
    // 已出库
    SALE_STOCK_IO: "sale.stock-io",
    // 已结算
    SALE_SETTLE: "sale.settle",
    // 已作废
    SALE_CHANGE: "sale.invalid",
    // 已驳回
    SALE_REJECT: "sale.reject",
    // 已开单
    SALE_CREATE: "sale.create",
    // 已取消
    SALE_CANCEL: "sale.cancel",
    // 已撤销
    SALE_REVERSED: "sale.reversed",
    // 待审核
    SALE_PENDING: 'sale.pending',
    // 已签收
    SALE_SIGN: 'sale.sign',

    //销售退货单状态
    SALE_RETURN_STATUS: "sale-return-status",
    // 已验货
    SALE_RETURN_STATUS_EXAMINE: "sale-return-status.examine",
    // 已开单
    SALE_RETURN_STATUS_CREATE: "sale-return-status.create",
    // 已作废
    SALE_RETURN_STATUS_CHANGE: "sale-return-status.change",
    // 退款成功
    SALE_RETURN_STATUS_REFUNDED: "sale-return-status.refunded",
    // 退款中
    SALE_RETURN_STATUS_REFUNDING: "sale-return-status.refunding",
    // 已入库
    SALE_RETURN_STATUS_STOCK_IO: 'sale-return-status.stock-io',
    // 已驳回
    SALE_RETURN_STATUS_REJECT: 'sale-return-status.reject',
    // 已结算
    SALE_RETURN_STATUS_BILL: 'sale-return-status.settle',
    // 已核销
    SALE_RETURN_STATUS_WRITE_OFF: "sale-return-status.write-off",
    // 结算中
    SALE_RETURN_STATUS_PAYING: "sale-return-status.paying",
    

    //盘点单状态
    STOCKTAKING: "stocktaking-status",
    //盘点单 (已完成)
    STOCKTAKING_COMPLETE: "stocktaking.complete",
    //盘点单 (进行中)
    STOCKTAKING_CREATE: "stocktaking.create",
    //盘点单 (待审核)
    STOCKTAKING_AUDIT: "stocktaking.audit",
    //盘点单 (已作废)
    STOCKTAKING_CHANGE: "stocktaking.change",

    //调拨状态
    TRANSFER_STATUS: "transfer-status",
    //调拨状态(已驳回)
    TRANSFER_STATUS_REJECT: "transfer-status.reject",
    //调拨状态(待调入)
    TRANSFER_STATUS_OUT: "transfer-status.out",
    //调拨状态(待调出)
    TRANSFER_STATUS_INIT: "transfer-status.init",
    //调拨状态(已调入)
    TRANSFER_STATUS_IN: "transfer-status.in",

    //结算类型 (挂账)
    SETTLE_TYPE_CREDIT: "settle-type.credit",
    //结算类型 （现结）
    SETTLE_TYPE_CASH: "settle-type.cash",
    //结算类型 （月结）
    SETTLE_TYPE_BALANCE: "settle-type.monthly",
    //结算类型 （大包）
    SETTLE_TYPE_TCO: "settle-type.tco",


    //调拨类型
    TRANSFER_TYPE: "transfer-type",
    //备品调拨
    TRANSFER_TYPE_BP: "transfer-type.bp",
    //其它调拨
    TRANSFER_TYPE_OTHERS: "transfer-type.others",
    //入库状态
    PUR_STOCK_IO_STATUS: "pur-stock—io-status",
    //出库状态
    SALE_STOCK_IO_STATUS: "sale-stock—io-status",

    /****************************下面状态是字典没有录到的，用到的修改编码后提上去 准备弃用********************************** */
    // 已结算
    SALE_RETURN_STATUS_SETTLE: "sale-return-status.settle",

    //客户属性
    CUSTOMER_ATTR: "customer-attr",
    //开户银行类型
    OPENBANK_TYPE: "account-bank",
    //发票状态
    INVOICE_STATUS: "invoice-status",
    //支付方式（现金账户类）
    PAY_METHOD_ACCT: "pay-method.acct",
    //经济实体
    BENTITY: "bentity",
    //核销状态
    WRITE_OFF_STATUS: "write-off-status-test",
    // 调拨销
    SALE_ALLOCATION: "sale-allocation",
    // 已补单
    SALE_REPLENISH_STATUS_REPLENISH: 'sale-replenish-status.replenish',
    //维修类型
    REPAIR_TYPE: "repair-type", //之前 "repair-type",
    //维修退单
    MAINTENANCE_RETURN :'maintenance.return',

    //维修单状态
    MAINTENANCE: "maintenance",
    //服务性质
    SERVICE_TYPE: "service-nature", //之前"service-type",
    //服务地点
    SERVICE_ADDRESS: "service-address",
    //三包单位 
    CLAIMUNIT: "claim-unit",
    //预约状态
    APPOINTMENT: "appointment",

    //门店
    SHOP: "cm-shop",
    //门店类型
    SHOP_TYPE: "shop-type",
    //经济实体
    BE: "cm-business-entity",
    //回访单状态
    VISIT_STATUS: "visit-status",
    //回访方式
    RETURN: "return",

    //账户类型 （商品返利）
    ACC_TYPE_REBATE: "account-type.rebate",
    //账户类型 （油品返利）
    ACC_TYPE_REBATE_OIL: "account-type.rebate-oil",
    //账户类型 （投放）
    ACC_TYPE_MARKET: "account-type.credit-market",
    //账户类型 （商品投放）
    ACC_TYPE_DELIVERY: "account-type.credit-delivery",
    //账户类型 (月结)
    ACC_TYPE_Month: "account-type.credit-month",
    //账户类型 (临时授信)
    ACC_TYPE_Credit: "account-type.credit",
    //账户类型 (现金账户)
    ACC_TYPE_CASH: "account-type.cash",
    //账户类型（结转账户）
    ACC_TYPE_JZ: "account-type.jz",
    //账户类型（备品四方）
    ACC_TYPE_SPARE_AGREEMENT: "account-type.spare-agreement",
    //账户类型（油品四方）
    ACC_TYPE_OILS_AGREEMENT: "account-type.oils-agreement",


    // 采退类型
    PRETURN_TYPE: 'preturn-type',

    //结算方
    SETTLE_PARTY: 'settle-party',
    //结算方（部门内部结算）
    SETTLE_PARTY_INTERNAL: 'settle-party.internal',
    //结算方（客户自费）
    SETTLE_PARTY_SELF_PAY: 'settle-party.self-pay',
    //结算方（大包维修）
    SETTLE_PARTY_DABAOMAINTENANCE: 'settle-party.dabaomaintenance',

}